import { ICapacitorFormData } from 'interfaces/capacitor-form-data.interface';
import { postsLogger } from 'loggers/posts.logger';

import { toBase64 } from './to-base64.util';

export const customFormData = async (
  file: File,
  formDataKey: string,
  formDataType: 'base64File' | 'string',
  isCapacitor: boolean,
): Promise<ICapacitorFormData[] | FormData> => {
  if (isCapacitor) {
    const base64String = await toBase64(file);

    postsLogger.debug({ msg: 'video base64string', base64String });

    const formData: ICapacitorFormData[] = [
      {
        key: formDataKey,
        type: formDataType,
        fileName: base64String?.meta.filename || '',
        contentType: base64String?.meta.type || '',
        value: base64String?.value.split('base64,').pop() || '',
      },
    ];

    return formData;
  }

  const formData = new FormData();
  formData.append(formDataKey, file);

  return formData;
};
