import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { GlobalStatsStore } from 'stores/global-stats/global-stats.store';

import { TYPES } from 'configs/di-types.config';
import * as paths from 'routes/paths.constants';

import { StatsPlayersLeaders } from 'components/stats/stats-leaders/stats-players-leaders/stats-players-leaders.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from '../stats.module.less';

export const StatsPlayersLeadersContainer = observer(() => {
  const globalStatsStore = useInjection<GlobalStatsStore>(TYPES.GlobalStatsStore);

  const navigate = useNavigate();

  const handleShowAllLeaders = useCallback(
    (sortParam: string, direction: string) => {
      navigate({
        pathname: paths.HOME_STATS_PLAYERS_ALL,
        search: `${createSearchParams({ sort: sortParam, direction })}`,
      });
    },
    [navigate],
  );

  if (globalStatsStore.playersStatsLeaders && !globalStatsStore.fetching) {
    return (
      <StatsPlayersLeaders
        leaders={globalStatsStore.playersStatsLeaders}
        onShowAllLeaders={handleShowAllLeaders}
      />
    );
  }

  return (
    <div className={styles.LoaderWrapper}>
      <Loader isShow withBackground />
    </div>
  );
});
