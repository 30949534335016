import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { ITeamInGameInfo } from 'stores/game/interfaces/game.interface';

import { GameEvents, POINTS_EVENTS } from 'components/game/game-event/game-event.config';
import { EventSubTypeType } from 'components/game/game-event/game-event.interface';
import { IReaction } from 'components/posts/post-card/interfaces/reactions.interface';
import {
  ReactionDirectionType,
  Reactions,
} from 'components/reactions/reactions/reactions.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import {
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';

import styles from './game-event.module.less';

interface IGameEventProps {
  eventId: string;
  reactions: {
    positive?: IReaction[];
    negative?: IReaction[];
  };
  onVoteEvent: (messageId: string, reaction: string, type: ReactionDirectionType) => void;
  eventType: keyof typeof GameEvents;
  scoreType: EventSubTypeType;
  team: ITeamInGameInfo;
  isHomeTeamEvent: boolean;
  isTeamEvent: boolean;
  period: number;
  clock: string;
  homeTeamPoints: number;
  visitorsTeamPoints: number;
  content: string;
  date: string;
  reactionsSum: number;
  triggerAuthorisationCheck: () => boolean;
}

export const GameEvent: FC<IGameEventProps> = (props) => {
  const {
    reactions,
    eventId,
    isTeamEvent,
    isHomeTeamEvent,
    eventType,
    scoreType,
    team,
    period,
    clock,
    homeTeamPoints,
    visitorsTeamPoints,
    content,
    reactionsSum,
    date,
    onVoteEvent,
  } = props;

  const visitorsPointsClassName = useMemo(
    () =>
      cn(styles.Points, {
        [styles['Points--isBold']]: isTeamEvent && !isHomeTeamEvent,
      }),
    [isHomeTeamEvent, isTeamEvent],
  );

  const homePointsClassName = useMemo(
    () =>
      cn(styles.Points, {
        [styles['Points--isBold']]: isTeamEvent && isHomeTeamEvent,
      }),
    [isHomeTeamEvent, isTeamEvent],
  );

  const eventClassName = useMemo(
    () =>
      cn(styles.Event, {
        [styles['Event--home']]: isTeamEvent && isHomeTeamEvent,
        [styles['Event--visitors']]: isTeamEvent && !isHomeTeamEvent,
      }),
    [isHomeTeamEvent, isTeamEvent],
  );

  const pointsClassName = useMemo(
    () =>
      cn(styles.PointsLabel, {
        [styles['PointsLabel--simple']]: scoreType === 'simple',
        [styles['PointsLabel--equal']]: scoreType === 'equal',
        [styles['PointsLabel--newLeader']]: scoreType === 'newLeader',
      }),
    [scoreType],
  );

  const handleVote = useCallback(
    (reaction: string, voteType: ReactionDirectionType) => {
      onVoteEvent(eventId, reaction, voteType);
    },
    [onVoteEvent, eventId],
  );

  const timing = useMemo(() => {
    if (eventType === 'gameEnd') {
      return clock;
    }

    if (period === 5) {
      return `OT - ${clock}`;
    }

    if (period > 5) {
      return `${period - 4}OT - ${clock}`;
    }

    return `Q${period} - ${clock}`;
  }, [period, clock, eventType]);

  const eventColorStyle = useMemo(() => {
    if (isTeamEvent) {
      return {
        color: team.secondaryTextColor,
      };
    }

    return {};
  }, [isTeamEvent, team.secondaryTextColor]);

  return (
    <div className={styles.Wrapper}>
      <div className={eventClassName} style={eventColorStyle}>
        <div className={styles.Event__Header}>
          <div className={styles.LeftSide}>
            {isTeamEvent && (
              <div className={styles.LeftSide__Team}>
                <Avatar size={AvatarSize.XS} username={team.name} src={team.smallLogoUrl} />
              </div>
            )}
            <div className={styles.LeftSide__Timing}>{timing}</div>
          </div>
          <div className={styles.RightSide}>
            {POINTS_EVENTS.includes(eventType) ? (
              <div className={pointsClassName}>
                {eventType === 'onePointShot' ? '+1' : eventType === 'twoPointsShot' ? '+2' : '+3'}
              </div>
            ) : (
              <IconFont
                name={GameEvents[eventType] as unknown as IconFontName}
                size={IconFontSize.Big}
              />
            )}
            <div className={styles.RightSide__Score}>
              <div className={visitorsPointsClassName}>{visitorsTeamPoints}</div>
              <div className={styles.Divider}>-</div>
              <div className={homePointsClassName}>{homeTeamPoints}</div>
            </div>
          </div>
        </div>
        <div className={styles.Event__Message}>
          <RichTextPreview
            id={eventId}
            editorState={content}
            editorType={RichTextContentTypes.FullHeight}
          />
        </div>
      </div>
      <div className={styles.Wrapper__Reactions}>
        <Reactions
          reactions={reactions}
          onVoteClick={handleVote}
          reactionsSum={reactionsSum || 0}
          triggerAuthorisationCheck={props.triggerAuthorisationCheck}
        />
        <div className={styles.Time}>{date}</div>
      </div>
    </div>
  );
};
