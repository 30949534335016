import { FC, useEffect, useRef } from 'react';
import { GridOptions } from 'ag-grid-community';

import { IBoxScoresPlayerStatistics } from 'stores/teams-stats/interfaces/players-stats.interface';

import { BoxScoresPlayerStatsTable } from 'components/game/box-score/box-scores-players-stats-table/box-scores-players-stats-table.component';
import { TotalStatsTable } from 'components/game/box-score/total-stats-table/total-stats-table.component';

interface ITeamSectionProps {
  startersConfig: GridOptions;
  benchConfig: GridOptions;
  totalStatsConfig: GridOptions;
  anchorElement: string;
  onRowClick: (event: { data: IBoxScoresPlayerStatistics }) => void;
}

export const TeamSection: FC<ITeamSectionProps> = (props) => {
  const { startersConfig, benchConfig, totalStatsConfig, anchorElement } = props;
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      const section = document.querySelector(`.${anchorElement}`);
      const tableBodies = section?.querySelectorAll('.ag-center-cols-viewport') || [];

      const scrollHandler = (e: Event) => {
        // Type casting e.target to HTMLElement to access scrollLeft
        const { scrollLeft } = e.target as HTMLElement;
        tableBodies.forEach((tableBody) => {
          // eslint-disable-next-line no-param-reassign
          tableBody.scrollLeft = scrollLeft;
        });
      };

      tableBodies.forEach((tableBody) => {
        tableBody.addEventListener('scroll', scrollHandler);
      });

      return () => {
        tableBodies.forEach((tableBody) => {
          tableBody.removeEventListener('scroll', scrollHandler);
        });
      };
    }, 1000);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [anchorElement]);

  return (
    <>
      <BoxScoresPlayerStatsTable tableGridOption={startersConfig} onRowClick={props.onRowClick} />
      <BoxScoresPlayerStatsTable tableGridOption={benchConfig} onRowClick={props.onRowClick} />
      <TotalStatsTable tableGridOption={totalStatsConfig} />
    </>
  );
};
