import { forwardRef, useMemo } from 'react';
import cn from 'classnames';

import styles from './iframe-preview.module.less';

export interface IIframePreviewProps {
  url: string;
  size: IframePreviewSize;
}

export enum IframePreviewSize {
  XS,
  S,
  M,
  FULL,
}

export const IframePreview = forwardRef<Maybe<HTMLIFrameElement>, IIframePreviewProps>(
  (props, ref) => {
    const { size, url } = props;

    const iframeClassNames = useMemo(
      () =>
        cn(styles.Iframe, {
          [styles['Iframe--extra-small']]: size === IframePreviewSize.XS,
          [styles['Iframe--small']]: size === IframePreviewSize.S,
          [styles['Iframe--medium']]: size === IframePreviewSize.M,
          [styles['Iframe--full']]: size === IframePreviewSize.FULL,
        }),
      [size],
    );

    return (
      <iframe
        ref={ref}
        className={iframeClassNames}
        title="Embedded"
        src={url}
        frameBorder="0"
        allowFullScreen
      />
    );
  },
);
