import { memo } from 'react';

import { ICountdownTime } from 'stores/auth/interfaces/countdown-time.interface';
import { ISignUpWithEmailPreserved } from 'stores/auth/interfaces/sign-up-preserved.interface';
import { ISignUpWithPasswordParams } from 'stores/auth/interfaces/sign-up-with-password-params.interface';

import { AuthHeader } from 'components/auth/auth-header/auth-header.component';
import { SignUpWithPassword } from 'components/auth/sign-up/sign-up-with-password/sign-up-with-password.component';
import { ThirdPartyServiceLogin } from 'components/auth/third-party-service-login/third-party-service-login.component';
import {
  ConfirmEmailForm,
  ConfirmEmailFormDataType,
} from 'components/forms/confirm-email/confirm-email-form.component';

interface ISignUpProps {
  processing: boolean;
  countdownTime: Maybe<ICountdownTime>;
  onSetCountdownTime: (time: Maybe<ICountdownTime>) => void;
  onGetCode: (email: string) => void;
  onHeaderButtonClick: () => void;
  onConfirmEmailSubmit: (formData: ConfirmEmailFormDataType) => void;
  onSignUpWithPasswordSubmit: (signUpWithPasswordParams: ISignUpWithPasswordParams) => void;
  withEmailPreserved: Maybe<ISignUpWithEmailPreserved>;
  onAppleSignIn: () => void;
  onGoogleSignIn: () => void;
}

export const SignUp = memo((props: ISignUpProps) => {
  const { processing, countdownTime, withEmailPreserved } = props;

  return (
    <>
      <AuthHeader buttonText="Login" onButtonClick={props.onHeaderButtonClick} />
      <hr />
      {!withEmailPreserved?.confirmed ? (
        <ConfirmEmailForm
          isSignUp
          processing={processing}
          submitText="Confirm"
          preserved={withEmailPreserved}
          countdownTime={countdownTime}
          onSetCountdownTime={props.onSetCountdownTime}
          onGetCode={props.onGetCode}
          onSubmit={props.onConfirmEmailSubmit}
        />
      ) : (
        <SignUpWithPassword
          processing={processing}
          preservedEmail={withEmailPreserved.email}
          onSubmit={props.onSignUpWithPasswordSubmit}
        />
      )}
      <ThirdPartyServiceLogin
        onAppleSignIn={props.onAppleSignIn}
        onGoogleSignIn={props.onGoogleSignIn}
      />
    </>
  );
});
