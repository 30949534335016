import {
  IPlayerSplitsOpponentResponse,
  IPlayerSplitsStatsResponse,
} from 'services/player/interfaces/player-splits.interface';
import {
  IPlayerRankedStatsResponse,
  IPlayerStatsResponse,
} from 'services/player/interfaces/player-stats.interface';

import { roundToSingleDecimal } from 'helpers/round-to-single-decimal.util';
import { capitalizeFirstLetter } from 'helpers/string/capitalize-first-letter.util';

import { SplitsCategory } from '../enums/splits-category.enum';
import { SplitsType } from '../enums/splits-type.enum';
import {
  IPlayerSplits,
  IPlayerStatsAll,
  ISplitsDayRowData,
  ISplitsMonthRowData,
  ISplitsResultRowData,
  ISplitsSplitRowData,
} from '../interfaces/player-profile.interfaces';

import { playerSplitsTotalRowDataAdapter } from './player-splits-total-row-data-adapter.util';

export function playerSplitsAdapter(
  rankedStatsResponse: Maybe<IPlayerRankedStatsResponse>,
  playerSplitsResponse: IPlayerSplitsStatsResponse[],
  playerSplitsOpponentResponse: IPlayerSplitsOpponentResponse[],
): Maybe<IPlayerSplits> {
  const split: ISplitsSplitRowData[] = [];

  const month: ISplitsMonthRowData[] = [];

  const result: ISplitsResultRowData[] = [];

  const day: ISplitsDayRowData[] = [];

  for (let i = 0; i < playerSplitsResponse.length; i += 1) {
    const entry = playerSplitsResponse[i];

    switch (entry.type) {
      case SplitsType.HomeVisitor:
        split.push({
          ...statsAdapter(entry),
          split: entry.category === SplitsCategory.Home ? 'Home' : 'Away',
        });
        break;
      case SplitsType.VsConference:
        split.push({ ...statsAdapter(entry), split: 'Vs. Conference' });
        break;
      case SplitsType.VsDivision:
        split.push({ ...statsAdapter(entry), split: 'Vs. Division' });
        break;
      case SplitsType.Month:
        if (entry.category) {
          month.push({
            ...statsAdapter(entry),
            month: entry.category,
            minutes: Math.round(entry.minutes),
            plusMinus: roundToSingleDecimal(entry.plus_minus),
            trueShootingPercentage: roundToSingleDecimal(entry.tsp),
            fantasyPoints: roundToSingleDecimal(entry.fantasy_points),
          });
        }
        break;
      case SplitsType.WinLoss:
        if (entry.category) {
          result.push({ ...statsAdapter(entry), result: capitalizeFirstLetter(entry.category) });
        }
        break;
      case SplitsType.Day:
        if (entry.category) {
          day.push({ ...statsAdapter(entry), day: entry.category });
        }
        break;
      default:
        break;
    }
  }

  const opponent = playerSplitsOpponentResponse.map(({ opponent_team: opponentTeam, ...rest }) => ({
    ...statsAdapter(rest),
    opponent: {
      id: opponentTeam.id,
      code: opponentTeam.code,
      logo: opponentTeam.medium_logo_url,
      name: opponentTeam.name,
    },
  }));

  const total = rankedStatsResponse ? playerSplitsTotalRowDataAdapter(rankedStatsResponse) : [];

  if (
    !split.length &&
    !month.length &&
    !result.length &&
    !day.length &&
    !opponent.length &&
    !total.length
  ) {
    return null;
  }

  return {
    total,
    split,
    month,
    result,
    day,
    opponent,
  };
}

export function statsAdapter(statsResponse: IPlayerStatsResponse): IPlayerStatsAll {
  const {
    assists,
    blocks,
    def_reb: defensiveReb,
    fga: fieldGoalsAttempted,
    fgm: fieldGoalsMade,
    fgp: fieldGoalsPercentage,
    fta: freeThrowsAttempted,
    ftm: freeThrowsMade,
    ftp: freeThrowsPercentage,
    games_played: gamesPlayed,
    off_reb: offensiveReb,
    personal_fouls: personalFouls,
    points,
    steals,
    tot_reb: totalRebounds,
    tpa: threePointsFieldGoalsAttempted,
    tpm: threePointsFieldGoalsMade,
    tpp: threePointsFieldGoalsPercentage,
    turnovers,
  } = statsResponse;

  return {
    assists: roundToSingleDecimal(assists),
    blocks: roundToSingleDecimal(blocks),
    defensiveReb: roundToSingleDecimal(defensiveReb),
    fieldGoalsAttempted: roundToSingleDecimal(fieldGoalsAttempted),
    fieldGoalsMade: roundToSingleDecimal(fieldGoalsMade),
    fieldGoalsPercentage: roundToSingleDecimal(fieldGoalsPercentage),
    freeThrowsAttempted: roundToSingleDecimal(freeThrowsAttempted),
    freeThrowsMade: roundToSingleDecimal(freeThrowsMade),
    freeThrowsPercentage: roundToSingleDecimal(freeThrowsPercentage),
    gamesPlayed: roundToSingleDecimal(gamesPlayed),
    offensiveReb: roundToSingleDecimal(offensiveReb),
    personalFouls: roundToSingleDecimal(personalFouls),
    points: roundToSingleDecimal(points),
    steals: roundToSingleDecimal(steals),
    threePointsFieldGoalsAttempted: roundToSingleDecimal(threePointsFieldGoalsAttempted),
    threePointsFieldGoalsMade: roundToSingleDecimal(threePointsFieldGoalsMade),
    threePointsFieldGoalsPercentage: roundToSingleDecimal(threePointsFieldGoalsPercentage),
    totalRebounds: roundToSingleDecimal(totalRebounds),
    turnovers: roundToSingleDecimal(turnovers),
  };
}
