import { memo, useMemo } from 'react';
import cn from 'classnames';

import { ILinkAttachment } from 'stores/posts/interfaces/post.interface';

import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { IconFont, IconFontName, IconFontSize } from '../icon-font/icon-font.component';

import { LinkSetItem } from './link-set-item/link-set-item.component';

import styles from './link-set.module.less';

export interface ILinkSetProps {
  attachments: ILinkAttachment[];
  hasOtherAttachments: boolean;
  isLight?: boolean;
}

export const LinkSet = memo((props: ILinkSetProps) => {
  const { attachments, hasOtherAttachments, isLight = false } = props;

  const linksCountText = useMemo(
    () => `${attachments.length} ${attachments.length > 1 ? 'Links' : 'Link'}`,
    [attachments.length],
  );

  const linkSetClasses = useMemo(
    () =>
      cn(styles.LinkSet, {
        [styles['LinkSet--light']]: isLight,
      }),
    [isLight],
  );

  const linkSetItemsClasses = useMemo(() => cn(styles.LinkSet__Items, TOUCH_IGNORE_CLASS), []);

  if (hasOtherAttachments || attachments.length > 1) {
    return (
      <div className={linkSetClasses}>
        <div className={styles.LinkSet__Header}>
          <IconFont name={IconFontName.Link} size={IconFontSize.Small} />
          <span>{linksCountText}</span>
          <IconFont name={IconFontName.ChevronDown} size={IconFontSize.Big} />
        </div>
        <div className={linkSetItemsClasses}>
          {attachments.map((link) => {
            return <LinkSetItem key={link.uuid} link={link} />;
          })}
        </div>
      </div>
    );
  }

  return <LinkSetItem link={attachments[0]} />;
});
