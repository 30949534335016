import { SignInWithApple, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { injectable } from 'inversify';
import { authLogger } from 'loggers/auth.logger';

import { APPLE_CLIENT_ID, APPLE_REDIRECT_URI } from 'configs/environment.config';

@injectable()
export class LoginWithAppleService {
  // TODO investigate this warning: Capacitor WebPlugin "SignInWithApple" config object was deprecated in v3 and will be removed in v4.
  // in scope of ENG-2060
  private readonly options: SignInWithAppleOptions;

  constructor() {
    this.options = {
      clientId: APPLE_CLIENT_ID,
      redirectURI: APPLE_REDIRECT_URI,
      scopes: 'email name',
      state: '1234',
      nonce: 'nonce',
    };
  }

  public async authorize(): Promise<Maybe<string>> {
    authLogger.info({ msg: 'authorize with apple' });

    try {
      const { response } = await SignInWithApple.authorize(this.options);

      authLogger.debug({ msg: 'authorize with apple request response', response });

      return response.identityToken;
    } catch (error) {
      authLogger.error({ msg: 'authorize with apple request failed', error });

      return null;
    }
  }
}
