import { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames';
import { format } from 'date-fns';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IGame } from 'services/statistics/interface/game.interface';

import { IPlayerDetails } from 'stores/player/interfaces/player-profile.interfaces';

import { DATE_FORMAT } from 'configs/date.config';
import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import {
  GAME_ROW_COMPACT_HEIGHT,
  GAME_ROW_HEIGHT,
  STATS_ABBRS,
} from 'components/player-profile/player-profile-games/game-log.config';
import { GameStatsRow } from 'components/player-profile/player-profile-games/game-stats-row/game-stats-row.component';
import { MonthHeaderItem } from 'components/player-profile/player-profile-games/month-header-item/month-header-item.component';
import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { TeamAvatar } from 'components/ui/team-avatar/team-avatar.component';

import { ISplitForOneMonth } from '../player-profile-games.interface';

import styles from './monthly-split.module.less';

interface ISplitsMonthTableProps {
  isCompactView: boolean;
  isOpen: boolean;
  isTotal: boolean;
  isSplitByMonth: boolean;
  isGuideTooltipShown: boolean;
  monthData: ISplitForOneMonth;
  player: Maybe<IPlayerDetails>;
  selectedSeason: ISelectOption;
  onMonthClick: (id: string, month: Maybe<string>) => void;
  onHeaderRowClick: () => void;
  onTeamClick: (teamId: number) => void;
}

export const MonthlySplit = memo((props: ISplitsMonthTableProps) => {
  const {
    isTotal,
    isCompactView,
    isSplitByMonth,
    isOpen,
    isGuideTooltipShown,
    monthData,
    player,
    onMonthClick,
    selectedSeason,
  } = props;

  const { games, total, average } = monthData;

  const navigate = useNavigate();

  const handleMonthClick = useCallback(() => {
    onMonthClick(monthData.id, monthData.month);
  }, [onMonthClick, monthData]);

  const handleRowClick = useCallback(
    (gameId: number) => {
      if (player?.slug) {
        navigate(
          getPath(paths.PLAYER_PERFORMANCE, {
            [paths.PLAYER_SLUG_PARAM]: player.slug,
            [paths.GAME_ID_PARAM]: String(gameId),
          }),
        );
      }
    },
    [navigate, player?.slug],
  );

  const handleStatsRowClick = useCallback(
    (game: IGame) => {
      return () => {
        const playerName = `${player?.firstname} ${player?.lastname}`;

        GoogleAnalyticService.event({
          eventName: 'button_custom_tap',
          eventParams: {
            button_tap_type: ButtonTapsEnum.PlayerProfileGamesLogTapped,
            player: playerName,
            season_timeframe: selectedSeason?.label,
            game_date: format(new Date(game.dateStart), DATE_FORMAT),
          },
        });

        handleRowClick(game.id);
      };
    },
    [handleRowClick, player, selectedSeason],
  );

  const items = useMemo(() => {
    return isTotal ? total : average;
  }, [isTotal, total, average]);

  const splitMonthClasses = useMemo(
    () =>
      cn(styles.SplitMonth, {
        [styles['SplitMonth--opened']]: isOpen,
        [styles['SplitMonth--compact']]: isCompactView,
      }),
    [isOpen, isCompactView],
  );

  const splitGridTemplate = useMemo(() => {
    const rowHeight = isCompactView ? GAME_ROW_COMPACT_HEIGHT : GAME_ROW_HEIGHT;

    return {
      gridTemplateColumns: `repeat(${STATS_ABBRS.length}, max-content)`,
      ...(isOpen && { gridTemplateRows: `repeat(${games.length + 1}, ${rowHeight}rem)` }),
    };
  }, [games.length, isCompactView, isOpen]);

  return (
    <div className={splitMonthClasses}>
      {!!games.length && (
        <>
          <div className={styles.SplitMonth__FirstColumn}>
            <MonthHeaderItem
              isGuideTooltipShown={isGuideTooltipShown}
              isSplitByMonth={isSplitByMonth}
              isCompactView={isCompactView}
              isOpen={isOpen}
              onMonthClick={handleMonthClick}
              onHeaderRowClick={props.onHeaderRowClick}
              monthData={monthData}
            />
            {isOpen &&
              games.map(({ game, isHomePlayer, isWinner }) => {
                return (
                  <TeamAvatar
                    key={game.id}
                    isWinner={isWinner}
                    isHomePlayer={isHomePlayer}
                    game={game}
                    player={player}
                    isCompactView={isCompactView}
                    onRowClick={handleRowClick}
                    onTeamClick={props.onTeamClick}
                  />
                );
              })}
          </div>
          <div
            style={splitGridTemplate}
            className={cn(styles.SplitMonth__SecondColumn, TOUCH_IGNORE_CLASS)}
          >
            <GameStatsRow
              isCompactView={isCompactView}
              forHeader
              onRowClick={props.onHeaderRowClick}
              stats={items}
            />
            {isOpen &&
              games.map((item) => (
                <GameStatsRow
                  isCompactView={isCompactView}
                  key={item.game.id}
                  forHeader={false}
                  onRowClick={handleStatsRowClick(item.game)}
                  stats={item}
                />
              ))}
          </div>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={props.onHeaderRowClick}
            onClick={props.onHeaderRowClick}
            className={styles.SplitMonth__Toggle}
          >
            <div className={styles.Label}>{isTotal ? 'Total' : 'Average'} </div>
            <IconFont name={IconFontName.MarkerExpanded} size={IconFontSize.Small} />
          </div>
        </>
      )}
    </div>
  );
});
