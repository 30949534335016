import { useEffect } from 'react';
import { json } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { TeamsStore } from 'stores/teams/teams.store';

import { TYPES } from 'configs/di-types.config';

export const TeamContainer = observer(() => {
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);

  useEffect(() => {
    if (teamsStore.isIdInvalid) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw json('Not Found', { status: 404 });
    }
  }, [teamsStore.isIdInvalid]);

  return null;
});
