import { RefObject, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { SCROLL_TO_TOP_PARAM_PATHS, SCROLL_TO_TOP_PATHS } from 'configs/scroll-to-top-paths.config';
import { getPaths } from 'helpers/get-paths.util';
import * as paths from 'routes/paths.constants';

export const useScrollToTop = <T extends Element>(elementRef: RefObject<T>) => {
  const { pathname } = useLocation();

  const { teamId } = useParams<{ [paths.TEAM_ID_PARAM]: string }>();
  const { playerSlug } = useParams<{ [paths.PLAYER_SLUG_PARAM]: string }>();

  const scrollPaths = useMemo(() => {
    if (teamId) {
      return getPaths({ teamId }, SCROLL_TO_TOP_PATHS, SCROLL_TO_TOP_PARAM_PATHS);
    }

    if (playerSlug) {
      return getPaths({ playerSlug }, SCROLL_TO_TOP_PATHS, SCROLL_TO_TOP_PARAM_PATHS);
    }

    return SCROLL_TO_TOP_PATHS;
  }, [teamId, playerSlug]);

  useEffect(() => {
    const element = elementRef.current;

    if (scrollPaths.includes(pathname) && element) {
      element.scrollTop = 0;
    }
  }, [elementRef, pathname, scrollPaths, playerSlug]);
};
