import { memo, useCallback, useEffect, useState } from 'react';

import { PollStatusEnum } from 'services/posts/enums/post.enum';

import { useInterval } from 'hooks/use-interval';

import { IPollAnswer, IPollVoteData } from './interfaces/poll.interface';
import { PollItem, PollTheme } from './poll-item/poll-item.component';

import styles from './poll.module.less';

export interface IPollProps {
  theme?: PollTheme;
  votesTotal: number;
  answers: IPollAnswer[];
  answerId: Maybe<string>;
  pollId: string;
  status: PollStatusEnum;
  parentEntityId: string;
  entityId: string;
  expirationTimestamp: number;
  onPollAnswer: (voteData: IPollVoteData) => void;
  onPollExpired: (postId: string, commentId: Maybe<string>) => void;
}

export const Poll = memo((props: IPollProps) => {
  const {
    theme = PollTheme.Post,
    entityId,
    votesTotal,
    answers,
    answerId,
    pollId,
    status,
    parentEntityId,
    expirationTimestamp,
    onPollAnswer,
    onPollExpired,
  } = props;

  const [pollExpired, setPollExpired] = useState(status === PollStatusEnum.Closed);

  const isPollOpen = status === PollStatusEnum.Open;

  const expirationDate = expirationTimestamp;

  const handleAnswerClick = useCallback(
    (id: string) => {
      if (!pollExpired && !answerId) {
        onPollAnswer({ pollId, answerId: id, entityId });
      }
    },
    [answerId, pollId, pollExpired, onPollAnswer, entityId],
  );

  useEffect(() => {
    if (pollExpired && isPollOpen) {
      onPollExpired(parentEntityId, entityId);
    }
  }, [pollExpired, parentEntityId, isPollOpen, onPollExpired, entityId]);

  useInterval(
    () => {
      const currentTime = new Date().getTime();

      if (expirationDate <= currentTime && isPollOpen) {
        setPollExpired(true);
      }
    },
    isPollOpen && !pollExpired ? 1000 : null,
  );

  return (
    <div className={styles.Poll}>
      {answers.map((answer) => (
        <div key={answer.uuid} className={styles.Poll__Wrapper}>
          <PollItem
            theme={theme}
            id={answer.uuid}
            answer={answer.title}
            percentage={answer.percentage || 0}
            votesCount={answer.votesTotal}
            userAnswerId={answerId}
            status={status}
            expired={pollExpired}
            onAnswerClick={handleAnswerClick}
          />
        </div>
      ))}
      <div className={styles.Poll__Total}>
        <span>{votesTotal} answers</span>
      </div>
    </div>
  );
});
