import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

export const getGameStatus = (gameStatus: GameStatus, period: number) => {
  if (GameStatus.Finished === gameStatus) {
    return 'Final';
  }

  if (period <= 4) {
    return `Quarter ${period}`;
  }

  if (period <= 7) {
    return `Overtime ${period - 4}`;
  }

  return '';
};
