import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames';

import { IGame } from 'services/statistics/interface/game.interface';

import { INews } from 'stores/news/interfaces/news.interface';
import { IPlayerStatsMini } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStatsColors } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { IAboutData } from 'containers/team-profile/team-profile-overview/team-profile-overview.container';
import { ITeamStatsRow } from 'containers/team-stats/team-stats.interface';

import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { NewsCard, NewsTheme } from 'components/news-card/news-card.component';
import { Statistics } from 'components/statistics/statistics.component';
import { TEAM_STATS_COL_DEF } from 'components/team-stats/team-stats.config';
import { TeamStatsTable } from 'components/team-stats/team-stats-table/team-stats-table.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { PlayerCard } from 'components/ui/player-card/player-card.component';
import { StatsBox, StatsBoxTheme } from 'components/ui/stats-box/stats-box.component';

import teamProfileStyles from '../team-profile.module.less';
import styles from './team-profile-overview.module.less';

interface ITeamProfileOverviewProps {
  teamId: Maybe<number>;
  aboutTheTeam: IAboutData[];
  topPlayers: Maybe<IPlayerStatsMini[]>;
  teamColors: ITeamsStatsColors;
  recentGames: IGame[];
  latestNews: INews[];
  teamStatsRowData: ITeamStatsRow[];
  onShowRoster: () => void;
  onNewsClick: (url: string) => void;
}

export const TeamProfileOverview: FC<ITeamProfileOverviewProps> = (props) => {
  const {
    teamId,
    aboutTheTeam,
    topPlayers,
    teamColors,
    recentGames,
    latestNews,
    teamStatsRowData,
  } = props;

  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.style.setProperty('--team-primary-color', teamColors.main);
    document.documentElement.style.setProperty('--team-secondary-color', teamColors.text);
  }, [teamColors]);

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(paths.PLAYER_PROFILE, { [paths.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  const handleNavigateScores = useCallback(() => {
    if (teamId) {
      navigate(
        getPath(paths.TEAM_SCORES, {
          [paths.TEAM_ID_PARAM]: teamId.toString(),
        }),
      );
    }
  }, [teamId, navigate]);

  const handleNavigateStats = useCallback(() => {
    if (teamId) {
      navigate(
        getPath(paths.TEAM_STATS, {
          [paths.TEAM_ID_PARAM]: teamId.toString(),
        }),
      );
    }
  }, [teamId, navigate]);

  const handleGameClick = useCallback(
    (gameId: number) => {
      navigate(getPath(paths.ARENA_GAME, { [paths.GAME_ID_PARAM]: String(gameId) }));
    },
    [navigate],
  );

  const topPlayersSectionClasses = useMemo(
    () => cn(styles.TopPlayersSection, TOUCH_IGNORE_CLASS),
    [],
  );

  const recentGamesSectionClasses = useMemo(
    () => cn(styles.RecentGamesSection, TOUCH_IGNORE_CLASS),
    [],
  );

  const classNewsSectionNames = useMemo(() => cn(styles.NewsSection, TOUCH_IGNORE_CLASS), []);

  return (
    <div className={teamProfileStyles.TeamProfile}>
      <div className={teamProfileStyles.TeamProfile__Section}>
        <div className={teamProfileStyles.TeamProfile__Header}>About The Team</div>
        <div className={styles.AboutTheTeamSection}>
          {aboutTheTeam.map(({ content, title, ...rest }) => {
            if (content) {
              return (
                <StatsBox
                  key={title}
                  theme={StatsBoxTheme.Dark}
                  title={title}
                  content={content}
                  {...rest}
                />
              );
            }

            return null;
          })}
        </div>
      </div>
      {!!topPlayers?.length && (
        <div className={teamProfileStyles.TeamProfile__Section}>
          <div className={teamProfileStyles.TeamProfile__HeaderWrapper}>
            <div className={teamProfileStyles.TeamProfile__Header}>Top Team Players</div>
            <Button onClick={props.onShowRoster} theme={ButtonTheme.Text} size={ButtonSize.Small}>
              Show Roster
            </Button>
          </div>
          <div className={topPlayersSectionClasses}>
            {topPlayers &&
              topPlayers.map((player) => (
                <PlayerCard
                  key={player.topPerformerBy}
                  player={player}
                  teamColors={teamColors}
                  onPlayerClick={handlePlayerClick}
                />
              ))}
          </div>
        </div>
      )}
      {!!recentGames.length && (
        <div className={teamProfileStyles.TeamProfile__Section}>
          <div className={teamProfileStyles.TeamProfile__HeaderWrapper}>
            <div className={teamProfileStyles.TeamProfile__Header}>Recent Games</div>
            <Button onClick={handleNavigateScores} theme={ButtonTheme.Text} size={ButtonSize.Small}>
              Show All
            </Button>
          </div>
          <div className={recentGamesSectionClasses}>
            <Statistics games={recentGames} onGameClick={handleGameClick} />
          </div>
        </div>
      )}
      <div className={teamProfileStyles.TeamProfile__Section}>
        <div className={teamProfileStyles.TeamProfile__HeaderWrapper}>
          <div className={teamProfileStyles.TeamProfile__Header}>Stats</div>
          <Button onClick={handleNavigateStats} theme={ButtonTheme.Text} size={ButtonSize.Small}>
            Show All
          </Button>
        </div>
        <div className={styles.StatsSection}>
          <TeamStatsTable
            tableGridOption={{
              rowData: teamStatsRowData,
              columnDefs: TEAM_STATS_COL_DEF,
              gridId: '1',
            }}
            withColors
          />
        </div>
      </div>
      {!!latestNews.length && (
        <div className={teamProfileStyles.TeamProfile__Section}>
          <div className={teamProfileStyles.TeamProfile__HeaderWrapper}>
            <div className={teamProfileStyles.TeamProfile__Header}>Latest News</div>
          </div>
          <div className={classNewsSectionNames}>
            {latestNews.map((newsItem) => (
              <NewsCard
                key={newsItem.uuid}
                theme={NewsTheme.Light}
                newsData={newsItem}
                onContentClick={props.onNewsClick}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
