import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { LayoutStore } from 'stores/layout/layout.store';
import { ScoreboardStore } from 'stores/scoreboard/scoreboard.store';
import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { SelectTeam } from 'components/ui/form-fields/select-team/select-team.component';

export const GamesDetailedSelectTeamContainer = observer(() => {
  const scoreboardStore = useInjection<ScoreboardStore>(TYPES.ScoreboardStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const navigate = useNavigate();

  const getPaths = useCallback((teamId: Maybe<number>) => {
    return {
      home: path.HOME_SCORES,
      team: teamId
        ? getPath(path.TEAM_SCORES, {
            [path.TEAM_ID_PARAM]: teamId.toString(),
          })
        : null,
    };
  }, []);

  const handleTeamChange = useCallback(
    async (teamId: Maybe<number>) => {
      teamsStore.setTeamId(teamId);

      const paths = getPaths(teamId);

      if (paths.team) {
        navigate(paths.team);
      } else {
        navigate(paths.home);
      }
    },
    [getPaths, navigate, teamsStore],
  );

  return (
    <SelectTeam
      id="Teams"
      name="Teams"
      placeholder="All Teams"
      options={teamsStatsStore.getTeamsSelectOptions('All Teams')}
      value={teamsStore.teamId}
      onChange={handleTeamChange}
      rightAligned={isDesktopPlus}
      disabled={teamsStatsStore.fetching || scoreboardStore.fetching}
      setDisabledScroll={layoutStore.setDisabledScroll}
    />
  );
});
