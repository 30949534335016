import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import { CountdownItem } from 'components/game/game-countdown/countdown-item/countdown-item.component';

import styles from './game-countdown.module.less';

interface ICountdownProps {
  targetDate: number;
  label: string;
  forPolls?: boolean;
  gameStatus: Maybe<GameStatus>;
}

export const Countdown: FC<ICountdownProps> = ({
  gameStatus,
  targetDate,
  label,
  forPolls = false,
}) => {
  const [remainingTime, setRemainingTime] = useState<number>(targetDate - Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedRemainingTime = targetDate - Date.now();

      if (updatedRemainingTime <= 0) {
        clearInterval(interval);
      } else {
        setRemainingTime(updatedRemainingTime);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDate]);

  const seconds = remainingTime <= 0 ? 0 : Math.floor((remainingTime / 1000) % 60);
  const minutes = remainingTime <= 0 ? 0 : Math.floor((remainingTime / 1000 / 60) % 60);
  const hours = remainingTime <= 0 ? 0 : Math.floor((remainingTime / 1000 / 60 / 60) % 24);
  const days = remainingTime <= 0 ? 0 : Math.floor((remainingTime / (1000 * 60 * 60 * 24)) % 30);
  const months = remainingTime <= 0 ? 0 : Math.floor(remainingTime / (1000 * 60 * 60 * 24 * 30));

  const labelClasses = useMemo<string>(() => {
    return cn(styles.Countdown__Label, {
      [styles['Countdown__Label--for-polls']]: forPolls,
    });
  }, [forPolls]);

  const formatNumber = useCallback((value: number) => {
    return value < 10 ? `0${value}` : String(value);
  }, []);

  const isTimerExpire = !months && !days && !hours && !minutes && !seconds;

  return (
    <div className={styles.Countdown}>
      {isTimerExpire && gameStatus === GameStatus.Scheduled ? (
        <div className={styles.Countdown__GameWillStart}>The game is expected to start</div>
      ) : (
        <>
          <div className={labelClasses}>{label}</div>
          <div className={styles.Countdown__Items}>
            {!!months && <CountdownItem widthDivider label="MON" value={formatNumber(months)} />}
            {(!!months || !!days) && (
              <CountdownItem widthDivider label="DAY" value={formatNumber(days)} />
            )}
            {(!!days || !!hours) && (
              <CountdownItem widthDivider label="HOUR" value={formatNumber(hours)} />
            )}
            {(!!hours || !!minutes) && (
              <CountdownItem widthDivider label="MIN" value={formatNumber(minutes)} />
            )}
            <CountdownItem label="SEC" value={formatNumber(seconds)} />
          </div>
        </>
      )}
    </div>
  );
};
