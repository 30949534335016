import { IVideoAttachmentResponse } from 'services/posts/interfaces/post-attachments-response.interface';
import { IVideoAttachment } from '../interfaces/post.interface';

export function videoAdapter(videoResponse: IVideoAttachmentResponse): IVideoAttachment {
  return {
    url: videoResponse.url,
    mimeType: videoResponse.mime_type,
    filename: videoResponse.filename,
    dateCreated: videoResponse.date_created,
    type: 'video',
    uuid: videoResponse.uuid,
  };
}
