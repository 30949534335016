import format from 'date-fns/format';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import getTime from 'date-fns/getTime';

import {
  FULL_DAY_WITH_DATE_FORMAT,
  GAME_DATE_FORMAT,
  GAME_DATE_TIME_FORMAT,
  POST_DATE_TIME_FORMAT,
  POST_TIME_ONLY_FORMAT,
  SHORT_DAY_WITH_DATE_FORMAT,
} from 'configs/date.config';
import { ENCODED_DOT_SYMBOL } from 'configs/encoded-symbols.config';

type DataFormatType =
  | 'relativeLong'
  | 'relativeShort'
  | 'timeOnly'
  | 'gameDate'
  | 'gameDateTime'
  | 'shortDay'
  | 'gameLogDate';

export const formatPastDate = (dateIso: string | Date, dateFormat?: DataFormatType): string => {
  let dateTimestamp;
  if (dateIso instanceof Date) {
    dateTimestamp = getTime(dateIso);
  } else {
    dateTimestamp = getTime(new Date(dateIso));
  }
  const isRelativeLong = dateFormat === 'relativeLong';
  const isDayShort = dateFormat === 'shortDay';
  const isRelativeShort = dateFormat === 'relativeShort';
  const isTimeOnly = dateFormat === 'timeOnly';
  const isGameDate = dateFormat === 'gameDate';
  const isGameDateTime = dateFormat === 'gameDateTime';
  const isGameLogDate = dateFormat === 'gameLogDate';

  if (isGameDateTime) {
    return format(dateTimestamp, GAME_DATE_TIME_FORMAT);
  }

  if (isDayShort) {
    return format(dateTimestamp, SHORT_DAY_WITH_DATE_FORMAT);
  }

  if (isGameDate) {
    return format(dateTimestamp, GAME_DATE_FORMAT);
  }

  if (isTimeOnly) {
    return format(dateTimestamp, POST_TIME_ONLY_FORMAT);
  }

  if (isGameLogDate) {
    return format(dateTimestamp, FULL_DAY_WITH_DATE_FORMAT);
  }

  if (isRelativeLong) {
    return `${formatDistanceStrict(dateTimestamp, Date.now())} ago`;
  }

  if (isRelativeShort) {
    const timeDistanceParts = formatDistanceStrict(dateTimestamp, Date.now()).split(' ');
    const timeDistanceValue = timeDistanceParts[0];
    const timeDistanceName = timeDistanceParts[1];

    if (timeDistanceName.includes('month') || timeDistanceName.includes('year')) {
      return `${timeDistanceValue}${timeDistanceName.charAt(0).toUpperCase()}`;
    }

    return `${timeDistanceValue}${timeDistanceName.charAt(0)}`;
  }

  const formattedDate = format(dateTimestamp, POST_DATE_TIME_FORMAT);

  return formattedDate.replace('·', ENCODED_DOT_SYMBOL);
};
