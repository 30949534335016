import { FC, memo, useCallback } from 'react';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './arrows.module.less';

interface IArrowsProps {
  onBack: () => void;
  onForward: () => void;
  isAnyHistoryEntryExist: boolean;
  customColor: Maybe<string>;
}

export const Arrows: FC<IArrowsProps> = memo((props: IArrowsProps) => {
  const { onBack, onForward, isAnyHistoryEntryExist, customColor } = props;

  const handleBack = useCallback(onBack, [onBack]);

  const handleForward = useCallback(onForward, [onForward]);

  return (
    <div className={styles.Arrows}>
      <IconButton
        onClick={handleBack}
        iconName={IconFontName.ChevronLeft}
        theme={IconButtonTheme.Secondary}
        disabled={!isAnyHistoryEntryExist}
        customColor={customColor}
      />
      <IconButton
        onClick={handleForward}
        iconName={IconFontName.ChevronRight}
        theme={IconButtonTheme.Secondary}
        customColor={customColor}
      />
    </div>
  );
});
