import { FC, useMemo } from 'react';
import cn from 'classnames';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './deeplink-small-banner.module.less';

interface IDeeplinkSmallBannerProps {
  isBottomBarHidden: boolean;
  onAppOpenClick: () => void;
}

export const DeeplinkSmallBanner: FC<IDeeplinkSmallBannerProps> = (props) => {
  const { isBottomBarHidden } = props;

  const classDeeplinkSmallBannerNames = useMemo(
    () =>
      cn(styles.DeeplinkSmallBanner, {
        [styles['DeeplinkSmallBanner--bottom-bar-hidden']]: isBottomBarHidden,
      }),
    [isBottomBarHidden],
  );

  return (
    <div className={classDeeplinkSmallBannerNames}>
      <span className={styles.DeeplinkSmallBanner__Text}>
        Discover all functionality in the app
      </span>
      <Button size={ButtonSize.Small} theme={ButtonTheme.Primary} onClick={props.onAppOpenClick}>
        Open
      </Button>
    </div>
  );
};
