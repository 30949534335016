import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import { LayoutEntity } from '../enums/layout-entity.enum';

import { ILayoutEntity } from './layout-entity.interface';

export enum LayoutHeaderLeftActionEnum {
  Menu = 'menu',
  Back = 'back',
  Custom = 'custom',
}

export interface IHeaderLeftValueCustom {
  icon?: IconFontName;
  type: LayoutHeaderLeftActionEnum.Custom;
  callback?: () => void;
  title?: string;
  description?: string;
}

export interface IHeaderLeftValue {
  icon: IconFontName;
  type: LayoutHeaderLeftActionEnum.Back | LayoutHeaderLeftActionEnum.Menu;
}

export interface ILayoutHeaderLeft
  extends ILayoutEntity<IHeaderLeftValue | IHeaderLeftValueCustom> {
  type: LayoutEntity.HeaderLeft;
}
