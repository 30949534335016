import { getPath } from './get-path.util';

export const getPaths = (
  pathParams: Record<string, string>,
  staticPaths: string[],
  paramPaths: string[],
) => {
  const additionalPaths = paramPaths.map((path) => getPath(path, pathParams));
  return [...staticPaths, ...additionalPaths];
};
