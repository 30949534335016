import { memo } from 'react';

import { ItemLineList } from 'components/ui/item-line-list/item-line-list.component';
import { BarActionType } from 'components/bars/bar-action.type';
import { CONTACT_US } from 'configs/item-line-list.config';

interface ISidebarContactUsProps {
  onClick: (barAction: BarActionType) => void;
}

export const SidebarContactUs = memo((props: ISidebarContactUsProps) => {
  return <ItemLineList items={CONTACT_US} onItemClick={props.onClick} />;
});
