import { Device } from '@capacitor/device';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { TPlatformName } from 'types/platform-name.type';

class CrashlyticsService {
  private platform: Maybe<TPlatformName>;

  constructor() {
    this.platform = 'web';

    this.getCurrentPlatformName();
  }

  private get isApplication() {
    return this.platform === 'android' || this.platform === 'ios';
  }

  private async getCurrentPlatformName() {
    const deviceInfo = await Device.getInfo();

    this.platform = deviceInfo.platform;
  }

  public async setUserId(userId: string) {
    if (this.isApplication) {
      await FirebaseCrashlytics.setUserId({ userId });
    }
  }

  public async recordException(message: string) {
    if (this.isApplication) {
      await FirebaseCrashlytics.recordException({
        message,
      });
    }
  }
}

export default new CrashlyticsService();
