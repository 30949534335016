import { ComponentType, FC, useCallback, useMemo, useState } from 'react';
import Select, {
  DropdownIndicatorProps,
  MultiValueGenericProps,
  MultiValueProps,
  MultiValueRemoveProps,
  OptionProps,
} from 'react-select';

import { IFormField } from 'components/ui/form-fields/form-field.interface';

import styles from './search-select.module.less';

export interface IOption {
  label: string;
  value: string | number;
  avatarUrl?: string;
  id: string;
}

export interface ISearchSelectProps extends IFormField {
  options: Array<IOption>;
  title?: string;
  placeholder: string;
  selectedItems: Array<IOption>;
  onSetSelectedItems: (values: Array<IOption>) => void;
  defaultMenuIsOpen: boolean;
  value: string;
  onChange: (values: string) => void;
  isMulti?: boolean;
  noOptionsText: string;
  OptionComponent?: ComponentType<OptionProps<any>>;
  MultiValueComponent?: ComponentType<MultiValueProps<any>>;
  MultiValueContainerComponent?: ComponentType<MultiValueGenericProps>;
  MultiValueRemoveComponent?: ComponentType<MultiValueRemoveProps>;
  DropdownIndicatorComponent?: ComponentType<DropdownIndicatorProps>;
}

export const SearchSelect: FC<ISearchSelectProps> = (props) => {
  const {
    options,
    title,
    placeholder,
    selectedItems,
    onSetSelectedItems,
    defaultMenuIsOpen,
    value,
    isMulti = true,
    noOptionsText,
    OptionComponent,
    MultiValueComponent,
    MultiValueContainerComponent,
    MultiValueRemoveComponent,
    DropdownIndicatorComponent,
  } = props;
  const [menuIsOpen, setMenuIsOpen] = useState(defaultMenuIsOpen);

  const handleChange = useCallback(
    (currentOptions: any) => {
      onSetSelectedItems(currentOptions);
    },
    [onSetSelectedItems],
  );

  const handleMenuClose = useCallback(() => {
    setMenuIsOpen(false);
  }, [setMenuIsOpen]);

  const handleMenuOpen = useCallback(() => {
    setMenuIsOpen(true);
  }, [setMenuIsOpen]);

  const noOptionsMessage = useCallback(() => noOptionsText, [noOptionsText]);

  const components = useMemo(
    () => ({
      ...(OptionComponent && { Option: OptionComponent }),
      ...(MultiValueComponent && { MultiValue: MultiValueComponent }),
      ...(MultiValueContainerComponent && {
        MultiValueContainer: MultiValueContainerComponent,
      }),
      ...(MultiValueRemoveComponent && { MultiValueRemove: MultiValueRemoveComponent }),
      ...(DropdownIndicatorComponent && { DropdownIndicator: DropdownIndicatorComponent }),
    }),
    [
      DropdownIndicatorComponent,
      MultiValueComponent,
      MultiValueContainerComponent,
      MultiValueRemoveComponent,
      OptionComponent,
    ],
  );

  return (
    <div className={styles.SearchWrapper}>
      {title && <div className={styles.SearchWrapper__Title}>{title}</div>}
      <Select
        isClearable
        isSearchable
        isMulti={isMulti}
        className={styles.SearchWrapper__Select}
        classNamePrefix="Multi"
        value={selectedItems}
        components={components}
        closeMenuOnSelect={false}
        noOptionsMessage={noOptionsMessage}
        onChange={handleChange}
        options={options}
        inputValue={value}
        menuIsOpen={menuIsOpen}
        onInputChange={props.onChange}
        placeholder={placeholder}
        onMenuClose={handleMenuClose}
        onMenuOpen={handleMenuOpen}
      />
    </div>
  );
};
