import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IEventParams } from 'services/google-analytic/interfaces/event-payload.interface';

import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { FollowStore } from 'stores/follow/follow.store';
import { IFollow } from 'stores/follow/interfaces/follow.interface';
import { StatisticsStore } from 'stores/statistics/statistics.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { ShortcutBar } from 'components/shortcut-bar/shortcut-bar.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './shortcut-bar.module.less';

export const ShortcutBarContainer = observer(() => {
  const statisticsStore = useInjection<StatisticsStore>(TYPES.StatisticsStore);
  const followStore = useInjection<FollowStore>(TYPES.FollowStore);
  const feedFiltersStore = useInjection<FeedFiltersStore>(TYPES.FeedFiltersStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);

  const navigate = useNavigate();

  const initialExpandState = useMemo(() => {
    return !statisticsStore.entries.length && !!followStore.sortedFollowings.length;
  }, [statisticsStore.entries, followStore.sortedFollowings]);

  const sendGoogleAnalytics = useCallback(
    (buttonTapType: ButtonTapsEnum, additinalParams?: IEventParams) => {
      const eventParams: IEventParams = {
        button_tap_type: buttonTapType,
      };

      if (feedFiltersStore.activeFeed) {
        eventParams.feed = feedFiltersStore.activeFeed;
      }

      if (teamsStatsStore.team) {
        eventParams.team = teamsStatsStore.team.code;
      }

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          ...eventParams,
          ...additinalParams,
        },
      });
    },
    [feedFiltersStore.activeFeed, teamsStatsStore.team],
  );

  const handleFollowedItemClick = useCallback(
    (following: IFollow) => () => {
      const eventParams: IEventParams = {};

      if (following.type === 'team') {
        navigate(
          getPath(paths.TEAM_FEED, {
            [paths.TEAM_ID_PARAM]: following.id.replace(/\D/g, ''),
          }),
        );

        eventParams.entry_team = `${following.city} ${following.label}`;
      }

      if (following.type === 'player') {
        navigate(
          getPath(paths.PLAYER_PROFILE, {
            [paths.PLAYER_SLUG_PARAM]: following.slug,
          }),
        );

        eventParams.entry_player = `${following.firstName} ${following.lastName}`;
      }

      sendGoogleAnalytics(ButtonTapsEnum.ShortcutFavorite, eventParams);
    },
    [sendGoogleAnalytics, navigate],
  );

  const handleFollowClick = useCallback(() => {
    navigate(paths.FOLLOWING);

    sendGoogleAnalytics(ButtonTapsEnum.ShortcutSelectFavorites);
  }, [sendGoogleAnalytics, navigate]);

  const handleGameClick = useCallback(
    (gameId: number, opponents?: string) => {
      navigate(getPath(paths.ARENA_GAME, { [paths.GAME_ID_PARAM]: gameId.toString() }));

      const eventParams: IEventParams = {};

      if (opponents) {
        eventParams.game = opponents;
      }

      sendGoogleAnalytics(ButtonTapsEnum.ShortcutGame, eventParams);
    },
    [sendGoogleAnalytics, navigate],
  );

  useEffect(() => {
    statisticsStore.retrieve();
  }, [statisticsStore]);

  if (statisticsStore.fetching || !followStore.fetched) {
    return (
      <div className={styles.ShortcutBarLoader}>
        <Loader isShow />
      </div>
    );
  }

  return (
    <ShortcutBar
      initialExpandState={initialExpandState}
      favorites={followStore.sortedFollowings}
      firstGameIds={statisticsStore.firstGameIds}
      firstGameTodayId={statisticsStore.firstGameTodayId}
      games={statisticsStore.entries}
      onGameClick={handleGameClick}
      onFollowClick={handleFollowClick}
      onFollowedItemClick={handleFollowedItemClick}
      sendGoogleAnalytics={sendGoogleAnalytics}
    />
  );
});
