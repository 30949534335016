import { inject, injectable } from 'inversify';

import { META_TAGS } from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { TYPES } from 'configs/di-types.config';

import { IMetaTagsResponse } from './interfaces/meta-tags-response.interface';

@injectable()
export class MetaTagsService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public async retrieve(route: string): Promise<IResponse<IMetaTagsResponse>> {
    return this.httpService.request<IMetaTagsResponse>({
      method: 'GET',
      url: META_TAGS,
      params: new URLSearchParams({ route }),
    });
  }
}
