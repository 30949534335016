import { useCallback, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';

import { JoinCommunityModal } from 'components/modals/join-community-modal/join-community-modal.component';

export const JoinCommunityContainer = observer(() => {
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);

  const handleJoin = useCallback(async () => {
    await teamsStore.followTeam();

    teamsStore.setJoinModalVisible(false);
    teamsStore.setTeamToFollow(null);
  }, [teamsStore]);

  const handleModalClose = useCallback(() => {
    teamsStore.setJoinModalVisible(false);
  }, [teamsStore]);

  const teamAvatar = useMemo(
    () => teamsStatsStore.team?.smallLogoUrl || teamsStore.teamToFollow?.thumbnailUrl || '',
    [teamsStatsStore.team, teamsStore.teamToFollow],
  );

  const teamName = useMemo(
    () => teamsStatsStore.team?.name || teamsStore.teamToFollow?.name || '',
    [teamsStatsStore.team, teamsStore.teamToFollow],
  );

  return (
    (teamsStatsStore.team || teamsStore.teamToFollow) && (
      <JoinCommunityModal
        processing={teamsStore.fetching}
        visible={teamsStore.isJoinModalVisible}
        avatarSrc={teamAvatar}
        name={teamName}
        onJoin={handleJoin}
        onClose={handleModalClose}
      />
    )
  );
});
