import { FC, memo } from 'react';
import { TikTokEmbed } from 'react-social-media-embed';

import { EmbedWrapperCard } from 'components/social-card/embed-wrapper-card/embed-wrapper-card.component';

interface ITickTokProps {
  sourceUrl: string;
  width: number;
}

export const Tiktok: FC<ITickTokProps> = memo(({ sourceUrl, width }: ITickTokProps) => {
  return (
    <EmbedWrapperCard>
      <TikTokEmbed url={sourceUrl} width={width} />
    </EmbedWrapperCard>
  );
});
