import { useEffect } from 'react';

export const useHotKey = (
  enabled: boolean,
  code: string,
  modifiers: { shift?: boolean; ctrl?: boolean; alt?: boolean },
  handler: () => void,
) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        enabled &&
        (modifiers.shift ? event.shiftKey : true) &&
        (modifiers.ctrl ? event.ctrlKey : true) &&
        (modifiers.alt ? event.altKey : true) &&
        event.code === code
      ) {
        handler();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [enabled, modifiers, handler, code]);
};
