import { FC, useMemo } from 'react';
import { Outlet, useRouteError } from 'react-router-dom';

import { AuthContainer } from 'containers/auth/auth.container';
import { BookmarkPanelContainer } from 'containers/bookmarks-panel/bookmarks-panel.container';
import { LayoutContainer } from 'containers/layout/layout.container';

import { ApplicationCrash } from './application-crash/application-crash.component';
import { NotFound } from './not-found/not-found.component';

export const ErrorLayout: FC = () => {
  const error = useRouteError();

  const element = useMemo(() => {
    if (typeof error === 'object' && error !== null && 'status' in error) {
      if (error.status === 404) {
        return <NotFound />;
      }
    }

    return null;
  }, [error]);

  if (element) {
    return (
      <>
        <AuthContainer />
        <LayoutContainer>
          <Outlet />
          {element}
        </LayoutContainer>
        <BookmarkPanelContainer />
      </>
    );
  }

  return <ApplicationCrash />;
};
