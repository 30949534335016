export const SLIDER_TRACK_COLOUR = '#3391CC';
export const SLIDER_TRACK_HEIGHT = 4;
export const SLIDER_POINT_ACTIVE_HEIGHT = 10;
export const SLIDER_POINT_ACTIVE_WIDTH = 10;
export const SLIDER_POINT_ACTIVE_MARGIN = -3;
export const SLIDER_POINT_ACTIVE_COLOUR = '#DCE5E5';
export const INITIAL_ZOOM = 0;
export const MIN_ZOOM = -0.5;
export const ZOOM_STEP = 0.5;
export const ZOOM_STEP_FOR_SLIDER = 0.01;
export const MAX_ZOOM = 2;
export const INITIAL_ROTATE_ANGEL = 0;
export const ROTATE_ANGEL_STEP = 90;
export const MAX_ROTATE_ANGEL = 360;
