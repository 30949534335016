import { EventEmitter } from 'eventemitter3';
import { injectable } from 'inversify';

import { IEventsMap } from './interfaces/events-map.interface';

@injectable()
export class EventsService {
  private readonly eventEmitter: EventEmitter;

  constructor() {
    this.eventEmitter = new EventEmitter();
  }

  public emit<T extends keyof IEventsMap>(eventType: T, payload: IEventsMap[T]) {
    this.eventEmitter.emit(eventType, payload);
  }

  public listenMany<T extends keyof IEventsMap>(
    eventType: T,
    handler: (payload: IEventsMap[T]) => void,
  ): void {
    this.eventEmitter.on(eventType, handler);
  }

  public listenOnce<T extends keyof IEventsMap>(
    eventType: T,
    handler: (payload: IEventsMap[T]) => void,
  ): void {
    this.eventEmitter.once(eventType, handler);
  }

  public removeAllListeners(eventType?: keyof IEventsMap): void {
    this.eventEmitter.removeAllListeners(eventType);
  }

  public removeListener<T extends keyof IEventsMap>(
    eventType: T,
    handler: (payload: IEventsMap[T]) => void,
  ): void {
    this.eventEmitter.removeListener(eventType, handler);
  }
}
