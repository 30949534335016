import {
  IPreparedSearchItem,
  ISearchItem,
  SearchResultType,
} from 'stores/search/interfaces/search.interface';
import { ITeam } from 'stores/teams/interfaces/team.interface';

export function prepareSearchItem(searchItem: ISearchItem, teams: ITeam[]): IPreparedSearchItem {
  if (searchItem.options.type === SearchResultType.PLAYER) {
    return { ...searchItem, options: { ...searchItem.options } };
  }

  const foundTeam = teams.find((team) => team.teamId === searchItem.id);

  return {
    ...searchItem,
    options: { ...searchItem.options, members: foundTeam?.followers || '0' },
  };
}
