import { memo, SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';
import { IPostSource } from 'services/posts/interfaces/post-source.interface';

import { IFeeds } from 'stores/posts/interfaces/post.interface';

import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './post-card-feeds.module.less';

const DISPLAY_LIMIT = 3;

export enum PostCardFeedsTheme {
  Collapse,
  List,
}

export interface IPostCardFeedsProps {
  theme?: PostCardFeedsTheme;
  items: IFeeds;
  source?: Maybe<IPostSource>;
  isHeaderPart?: boolean;
  isForSidePanel?: boolean;
  onSourceClick: (event: SyntheticEvent) => void;
  onFeedClick: (type: PostsFeedType, id: string) => void;
}

export const PostCardFeeds = memo((props: IPostCardFeedsProps) => {
  const {
    theme = PostCardFeedsTheme.Collapse,
    isHeaderPart = false,
    isForSidePanel = false,
    items,
    source,
    onFeedClick,
  } = props;

  const scrollableElementRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(false);

  const postCardFeedsClasses = useMemo(
    () =>
      cn(styles.PostCardFeeds, TOUCH_IGNORE_CLASS, {
        [styles['PostCardFeeds--expanded']]: expanded,
        [styles['PostCardFeeds--list-theme']]: theme === PostCardFeedsTheme.List,
        [styles['PostCardFeeds--side-panel-theme']]: isForSidePanel,
        [styles['PostCardFeeds--without-source']]: !source,
      }),
    [expanded, theme, isForSidePanel, source],
  );

  const tagsLength = useMemo(() => items.players.length + items.teams.length, [items]);

  const handleExpandFeeds = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setExpanded((prevState) => !prevState);

    scrollableElementRef.current?.scrollTo(0, 0);
  }, []);

  const handleFeedClick = useCallback(
    (type: PostsFeedType, id: string) => (event: SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();

      onFeedClick(type, id);
    },
    [onFeedClick],
  );

  return (
    <div className={postCardFeedsClasses}>
      <div className={styles.PostCardFeeds__Inner}>
        {(theme === PostCardFeedsTheme.Collapse || !isHeaderPart) && (
          <div ref={scrollableElementRef} className={styles.PostCardFeeds__Group}>
            {items.players.map((item) => (
              <button
                key={item.playerId}
                className={styles.PostCardFeeds__Player}
                onClick={handleFeedClick(item.type, item.slug)}
              >
                <img src={item.smallLogoUrl || ''} alt={`${item.firstname} ${item.lastname}`} />
              </button>
            ))}
            {items.teams.map((item) => (
              <button
                key={item.teamId}
                className={styles.PostCardFeeds__Team}
                onClick={handleFeedClick(item.type, item.teamId.toString())}
              >
                <img src={item.smallLogoUrl || ''} alt={item.name} />
              </button>
            ))}
          </div>
        )}
        {theme === PostCardFeedsTheme.Collapse && tagsLength > DISPLAY_LIMIT && (
          <button className={styles.PostCardFeeds__Toggler} onClick={handleExpandFeeds}>
            {expanded && <IconFont name={IconFontName.ChevronSmallRight} size={IconFontSize.Big} />}
            {!expanded && `+${tagsLength - DISPLAY_LIMIT}`}
          </button>
        )}
        {(theme === PostCardFeedsTheme.Collapse || isHeaderPart) && source && (
          <button className={styles.PostCardFeeds__Source} onClick={props.onSourceClick}>
            <img src={source.favicon} alt={source.channel} />
          </button>
        )}
      </div>
    </div>
  );
});
