import { memo } from 'react';

import { SETTINGS_ACCOUNT } from 'configs/item-line-list.config';

import { BarActionType } from 'components/bars/bar-action.type';
import { ItemLineList } from 'components/ui/item-line-list/item-line-list.component';

import styles from './account-settings.module.less';

interface IAccountSettingsProps {
  onItemClick: (barAction: BarActionType) => void;
}

export const AccountSettings = memo((props: IAccountSettingsProps) => {
  return (
    <div className={styles.AccountSettings}>
      <ItemLineList items={SETTINGS_ACCOUNT} onItemClick={props.onItemClick} />
    </div>
  );
});
