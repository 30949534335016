import { useCallback, useEffect, useMemo, useRef } from 'react';
import { SetURLSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { StorageField } from 'services/storage/enum/storage-field.enum';
import { StorageService } from 'services/storage/storage.service';

import { ApplicationStore } from 'stores/application/application.store';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { GameStore } from 'stores/game/game.store';
import { GameChatStore } from 'stores/game-chat/game-chat.store';
import { GameEventsStore } from 'stores/game-events/game-events.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { MetaTagsStore } from 'stores/meta-tags/meta-tags.store';
import { ShareStore } from 'stores/share/share.store';

import { SCROLL_TOP_ELEMENT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { DEFAULT_SHARE_TITLE } from 'configs/share.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useGaScreenCustomView } from 'hooks/use-ga-screen-custom-view';
import { useMainProvider } from 'hooks/use-main-provider';
import { useNativeShare } from 'hooks/use-native-share';
import { useTeamClick } from 'hooks/use-team-click';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { EmptyState } from 'components/empty-state/empty-state.component';
import { Game } from 'components/game/game.component';
import {
  GAME_TAB_DEFAULT,
  GameDetailsTabs,
  isGameDetailsTabs,
  NO_GAME_MESSAGE,
  NO_GAME_TITLE,
} from 'components/game/game.config';
import { ShareGameModal } from 'components/modals/share-game-modal/share-game-modal.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './game.module.less';

export const GameContainer = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const gameChatStore = useInjection<GameChatStore>(TYPES.GameChatStore);
  const gameChatEventsStore = useInjection<GameEventsStore>(TYPES.GameEventsStore);
  const gameStore = useInjection<GameStore>(TYPES.GameStore);
  const shareStore = useInjection<ShareStore>(TYPES.ShareStore);
  const metaTagsStore = useInjection<MetaTagsStore>(TYPES.MetaTagsStore);
  const feedFiltersStore = useInjection<FeedFiltersStore>(TYPES.FeedFiltersStore);
  const storageService = useInjection<StorageService>(TYPES.StorageService);
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);

  const { isNativeApp } = useMainProvider();
  const { shareByNativeModal } = useNativeShare();

  const setParamsRef = useRef<SetURLSearchParams | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const params = useParams<{
    [paths.GAME_ID_PARAM]: string;
  }>();

  const { gameId } = params;

  const navigate = useNavigate();

  const { googleAnalyticsArenaFeed } = useGaScreenCustomView();

  const handleAppVersionChange = useCallback(async () => {
    const isAppVersionChangeHandled = await storageService.get(
      StorageField.IsAppVersionChangeHandled,
    );

    if (
      isAppVersionChangeHandled &&
      isNativeApp &&
      !applicationStore.hasPushNotificationPermissions
    ) {
      applicationStore.setIsShowCustomNotificationPopup(true);
      storageService.set(StorageField.IsAppVersionChangeHandled, false);
    }
  }, [storageService, applicationStore, isNativeApp]);

  useEffect(() => {
    handleAppVersionChange();
  }, [handleAppVersionChange]);

  useEffect(() => {
    if (!searchParams.get('tab')) {
      setParamsRef.current = setSearchParams;
    }
  }, [setSearchParams, searchParams]);

  const refreshContent = useCallback(async () => {
    await gameStore.fetchByPullToRefresh();
    await gameChatStore.reconnectSocketConnection();
    await gameChatEventsStore.reconnectSocketConnection();

    layoutStore.setPulledRefresher(false);
  }, [gameStore, gameChatStore, layoutStore, gameChatEventsStore]);

  useEffect(() => {
    if (layoutStore.isPulledRefresher) {
      refreshContent();
    }
  }, [layoutStore.isPulledRefresher, refreshContent]);

  useEffect(() => {
    layoutStore.setSidePanelActiveTab(null);
    layoutStore.setRefresherTopPosition(true);

    return () => {
      layoutStore.setRefresherTopPosition(false);
    };
  }, [layoutStore]);

  useEffect(() => {
    const size = layoutStore.sidePanelActiveTab ? '37.7rem' : '2.5rem';
    document.documentElement.style.setProperty('--scroll-top-right', size);
  }, [layoutStore.sidePanelActiveTab]);

  useEffect(() => {
    const id = gameId ? Number(gameId) : null;
    gameStore.setId(id);

    return () => {
      gameStore.setId(null);
    };
  }, [gameId, gameStore]);

  useEffect(() => {
    const topElement = document.getElementById(SCROLL_TOP_ELEMENT)!;

    const timeout = setTimeout(() => {
      topElement.scrollIntoView({ behavior: 'smooth' });
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const activeTab = useMemo(() => {
    const tab = searchParams.get('tab');

    return tab && isGameDetailsTabs(tab) ? tab : GAME_TAB_DEFAULT;
  }, [searchParams]);

  useEffect(() => {
    feedFiltersStore.setActiveFeed(null);
  }, [feedFiltersStore]);

  useEffect(() => {
    if (gameStore.entry?.status && setParamsRef.current) {
      if (gameStore.entry.status === GameStatus.Scheduled && gameChatStore.polls.length) {
        setParamsRef.current({ tab: GameDetailsTabs.POLLS }, { replace: true });
        setParamsRef.current = null;
        return;
      }

      if (gameStore.entry.status === GameStatus.Scheduled && !gameChatStore.polls.length) {
        setParamsRef.current({ tab: GameDetailsTabs.TEAMS }, { replace: true });
        setParamsRef.current = null;
        return;
      }

      if (gameStore.entry.status === GameStatus.Finished) {
        setParamsRef.current({ tab: GameDetailsTabs.PLAYERS }, { replace: true });
        setParamsRef.current = null;
        return;
      }

      if (
        gameStore.entry.status === GameStatus.Live ||
        gameStore.entry.status === GameStatus.Halftime
      ) {
        setParamsRef.current({ tab: GameDetailsTabs.PLAYS }, { replace: true });
        setParamsRef.current = null;
      }
    }
  }, [gameChatStore.polls.length, gameStore.entry?.status]);

  const handleTabChange = useCallback(
    (action: BarActionType) => {
      if (action.type === BarAction.Click && action.payload && isGameDetailsTabs(action.payload)) {
        if (action.payload === GameDetailsTabs.PLAYS) {
          gameChatEventsStore.refresh();
        }
        if (action.payload === GameDetailsTabs.CHAT) {
          gameChatStore.refresh();
        }

        setSearchParams({ tab: action.payload }, { replace: true });
      }
    },
    [setSearchParams, gameChatEventsStore, gameChatStore],
  );

  const handleTeamClick = useTeamClick();

  const handlePlayerClick = useCallback(
    (slug: string) => {
      if (gameStore.entry && gameStore.entry.status !== GameStatus.Scheduled) {
        GoogleAnalyticService.event({
          eventName: 'screen_custom_view',
          eventParams: {
            screen_type: ScreenNamesEnum.PlayerPerformance,
            player: slug,
            entry_point: ScreenNamesEnum.ArenaBoxScore,
          },
        });

        navigate(
          getPath(paths.PLAYER_PERFORMANCE, {
            [paths.PLAYER_SLUG_PARAM]: slug,
            [paths.GAME_ID_PARAM]: gameStore.entry.id.toString(),
          }),
        );
      } else {
        navigate(getPath(paths.PLAYER_PROFILE, { [paths.PLAYER_SLUG_PARAM]: slug }));
      }
    },
    [navigate, gameStore.entry],
  );

  const handleOpenShareModal = useCallback(async () => {
    if (gameStore.entry) {
      await shareStore.fetchShareGame(gameStore.entry.id);

      if (isNativeApp && shareStore.shareData) {
        shareByNativeModal(
          shareStore.shareData.link,
          metaTagsStore.metaTags?.title || DEFAULT_SHARE_TITLE,
        );
      } else {
        shareStore.setIsShareModalVisible(true);
      }
    }
  }, [isNativeApp, metaTagsStore.metaTags, gameStore.entry, shareStore, shareByNativeModal]);

  const handleResetSharing = useCallback(() => {
    shareStore.reset();
  }, [shareStore]);

  const handleCopySharedLink = useCallback(() => {
    shareStore.copyLink();
  }, [shareStore]);

  const handleGoogleAnalyticsArenaFeed = useCallback(
    (screenType: ScreenNamesEnum) => {
      googleAnalyticsArenaFeed(screenType);
    },
    [googleAnalyticsArenaFeed],
  );

  const gameClassNames = useMemo(
    () =>
      cn(styles.GameDetails, {
        [styles['GameDetails--empty']]: gameStore.fetched && !gameStore.entry,
        [styles['GameDetails--smart-banner']]: layoutStore.isDisplayedSmartBanner,
      }),
    [gameStore.entry, gameStore.fetched, layoutStore.isDisplayedSmartBanner],
  );

  return (
    <div className={gameClassNames}>
      <Loader isShow={gameStore.fetching && !gameStore.entry} />
      {gameStore.entry && (
        <Game
          isPollsExist={!!gameChatStore.polls.length}
          game={gameStore.entry}
          activeTab={activeTab}
          onTabClick={handleTabChange}
          onTeamClick={handleTeamClick}
          onPlayerClick={handlePlayerClick}
          onShareClick={handleOpenShareModal}
          onGoogleAnalyticsArenaFeed={handleGoogleAnalyticsArenaFeed}
          setDisabledScroll={layoutStore.setDisabledScroll}
        />
      )}
      {gameStore.fetched && !gameStore.entry && (
        <EmptyState title={NO_GAME_TITLE} message={NO_GAME_MESSAGE} />
      )}
      {!isNativeApp && gameStore.entry && (
        <ShareGameModal
          game={gameStore.entry}
          visible={shareStore.isShareModalVisible}
          onCopyLink={handleCopySharedLink}
          onClose={handleResetSharing}
        />
      )}
    </div>
  );
});
