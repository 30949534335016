import { StandingsTeamAchievementEnum } from 'stores/standings/enums/standings-team-achievement.enum';

import { IGlossaryEntity } from '../ui/glossary/interfaces/glossary-entity.interface';

import { StandingsTeamAchievement } from './standings-team-achievement/standings-team-achievement.component';

const DEFAULT_ENTITIES: IGlossaryEntity[] = [
  { leftNode: 'W:', description: 'Wins' },
  { leftNode: 'L:', description: 'Losses' },
  { leftNode: 'PCT:', description: 'Winning Percentage' },
  { leftNode: 'GB:', description: 'Games Back' },
];

const ACHIEVEMENT_ENTITIES: IGlossaryEntity[] = [
  {
    leftNode: <StandingsTeamAchievement achievement={StandingsTeamAchievementEnum.Star} />,
    description: 'Clinched Best League Record',
  },
  {
    leftNode: <StandingsTeamAchievement achievement={StandingsTeamAchievementEnum.GreenFilled} />,
    description: 'Clinched Conference',
  },
  {
    leftNode: <StandingsTeamAchievement achievement={StandingsTeamAchievementEnum.Green} />,
    description: 'Clinched Division',
  },
  // TODO add when functionality is available
  // {
  //   leftNode: <StandingsTeamAchievement achievement={StandingsTeamAchievementEnum.PurpleFilled} />,
  //   description: 'Clinched Playoff Berth',
  // },
  // {
  //   leftNode: <StandingsTeamAchievement achievement={StandingsTeamAchievementEnum.Purple} />,
  //   description: 'Clinched Playoff - Won a Play-In',
  // },
  // {
  //   leftNode: <StandingsTeamAchievement achievement={StandingsTeamAchievementEnum.Blue} />,
  //   description: 'Currently in Play-In',
  // },
  // {
  //   leftNode: <StandingsTeamAchievement achievement={StandingsTeamAchievementEnum.RedFilled} />,
  //   description: 'Eliminated From Playoff',
  // },
  // {
  //   leftNode: <StandingsTeamAchievement achievement={StandingsTeamAchievementEnum.Red} />,
  //   description: 'Clinched Play-In Berth',
  // },
];

const STANDINGS_TYPE_ENTITIES: IGlossaryEntity[] = [
  { leftNode: 'Home:', description: 'Home Record' },
  { leftNode: 'Away:', description: 'Away Record' },
  { leftNode: 'Div:', description: 'Division Record' },
  { leftNode: 'Conf:', description: 'Conference Record' },
  { leftNode: 'PPG:', description: 'Points Per Game' },
  { leftNode: 'OPPG:', description: 'Opponent Points Per Game' },
  { leftNode: 'Diff:', description: 'Average Point Differential' },
  { leftNode: 'Strk:', description: 'Current Streak' },
  { leftNode: 'L10:', description: 'Record Last 10 Games' },
];

const EXPANDED_TYPE_ENTITIES: IGlossaryEntity[] = [
  {
    leftNode: '3 PT Games:',
    description: 'Record in games decided by 3 points or less',
  },
  {
    leftNode: '10 PT Games:',
    description: 'Record in games decided by 10 points or more',
  },
  {
    leftNode: 'Vs. .500 and Above:',
    description: 'Record against Teams .500 and Above',
  },
  {
    leftNode: 'Vs. Below .500:',
    description: 'Record against Teams below .500',
  },
  // TODO add when functionality is available
  // { leftNode: 'OT:', description: 'Record in overtime games' },
];

const VS_DIVISION_TYPE_ENTITIES: IGlossaryEntity[] = [
  { leftNode: 'East:', description: 'Record vs. Eastern Conference Teams' },
  { leftNode: 'Atl:', description: 'Record vs. Atlantic Division Teams' },
  { leftNode: 'Cen:', description: 'Record vs. Central Division Teams' },
  { leftNode: 'SE:', description: 'Record vs. Southeast Division Teams' },
  { leftNode: 'West:', description: 'Record vs. Western Conference Teams' },
  { leftNode: 'NW:', description: 'Record vs. Northwest Division Teams' },
  { leftNode: 'Pac:', description: 'Record vs. Pacific Division Teams' },
  { leftNode: 'SW:', description: 'Record vs. Southwest Division Teams' },
];

export const STANDINGS_GLOSSARY_STANDING_TYPE_ENTITIES = [
  ...DEFAULT_ENTITIES,
  ...STANDINGS_TYPE_ENTITIES,
  ...ACHIEVEMENT_ENTITIES,
];

export const STANDINGS_GLOSSARY_EXPANDED_TYPE_ENTITIES = [
  ...DEFAULT_ENTITIES,
  ...EXPANDED_TYPE_ENTITIES,
  ...ACHIEVEMENT_ENTITIES,
];

export const STANDINGS_GLOSSARY_VS_DIVISION_TYPE_ENTITIES = [
  ...DEFAULT_ENTITIES,
  ...VS_DIVISION_TYPE_ENTITIES,
  ...ACHIEVEMENT_ENTITIES,
];
