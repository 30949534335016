import { memo, useCallback } from 'react';

import { IPushNotificationSettings } from 'stores/settings/interfaces/push-notification-settings.interface';
import { PushNotificationPayloadType } from 'stores/settings/types/push-notification-payload.type';

import { ENCODED_APOSTROPHE } from 'configs/encoded-symbols.config';
import {
  ARENA_GAME_NOTIF_TEXT_TAGS,
  SOCIALS_AND_NEWS_NOTIF_TEXT_TAGS,
} from 'configs/settings.config';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { Toggle } from 'components/ui/form-fields/toggle/toggle.component';

import styles from './push-notifications.module.less';

interface IPushNotificationsSettingsProps {
  isAllowedPushNotification: boolean;
  settings: IPushNotificationSettings;
  onSettingItemChange: (value: boolean, key?: string) => void;
  onAccessNotificationClick: () => void;
}

export const PushNotificationsSettings = memo((props: IPushNotificationsSettingsProps) => {
  const { isAllowedPushNotification, settings } = props;

  const renderToggleItems = useCallback(
    (mappedItems: Record<string, string>) => {
      const valueItems: PushNotificationPayloadType = { ...settings };

      return Object.keys(mappedItems).map((item: string) => (
        <div key={item} className={styles.SettingsToggles__Item}>
          <span className={styles.SettingsToggles__Text}>{mappedItems[item]}</span>
          <Toggle
            id={item}
            name={item}
            value={valueItems[item]}
            onChange={props.onSettingItemChange}
          />
        </div>
      ));
    },
    [settings, props.onSettingItemChange],
  );

  return (
    <div className={styles.PushNotificationsSettings}>
      {!isAllowedPushNotification && (
        <div className={styles.SettingsCard}>
          <div className={styles.SettingsCard__Content}>
            <h4 className={styles.SettingsCard__Title}>Your push notifications are turned off</h4>
            <p className={styles.SettingsCard__Text}>
              To get notifications on your device, visit your device{ENCODED_APOSTROPHE}s settings
              and turn it on
            </p>
          </div>
          <Button
            theme={ButtonTheme.Primary}
            size={ButtonSize.Small}
            onClick={props.onAccessNotificationClick}
          >
            Turn On
          </Button>
        </div>
      )}
      {isAllowedPushNotification && (
        <>
          <div className={styles.SettingsToggles}>
            <h4 className={styles.SettingsToggles__Title}>Arena Game Events</h4>
            <div className={styles.SettingsToggles__Wrapper}>
              <div className={styles.SettingsToggles__Items}>
                {renderToggleItems(ARENA_GAME_NOTIF_TEXT_TAGS)}
              </div>
            </div>
          </div>
          <div className={styles.SettingsToggles}>
            <h4 className={styles.SettingsToggles__Title}>Social and News</h4>
            <div className={styles.SettingsToggles__Wrapper}>
              <div className={styles.SettingsToggles__Items}>
                {renderToggleItems(SOCIALS_AND_NEWS_NOTIF_TEXT_TAGS)}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
});
