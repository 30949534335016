import { z } from 'zod';

import { FILES_IMAGE_TYPES } from 'validation/validation.constants';

export const avatarAndThumbnailSchema = z.object({
  meta: z.object({
    type: z.string().superRefine((val, ctx) => {
      if (!FILES_IMAGE_TYPES.includes(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'File should be image png or jpg/jpeg',
        });
      }
    }),
  }),
  value: z.string({ required_error: 'base64 is required' }),
});
