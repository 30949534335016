import { FC, useCallback } from 'react';

import { IResetPasswordParams } from 'stores/auth/interfaces/forgot-password.interface';

import {
  PasswordsForm,
  PasswordsFormDataType,
} from 'components/forms/passwords/passwords-form.component';

export interface INewPasswordConformationProps {
  processing: boolean;
  preservedEmail: string;
  onSubmit: (resetPasswordParams: IResetPasswordParams) => void;
}

export const NewPasswordConformation: FC<INewPasswordConformationProps> = (props) => {
  const { processing, preservedEmail, onSubmit } = props;

  const handleSubmit = useCallback(
    (formData: PasswordsFormDataType) => {
      onSubmit({ email: preservedEmail, password: formData.password });
    },
    [onSubmit, preservedEmail],
  );

  return (
    <PasswordsForm processing={processing} submitText="Reset Password" onSubmit={handleSubmit} />
  );
};
