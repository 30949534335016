import { memo, useCallback } from 'react';

import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { WithNotifications } from 'components/ui/with-notification/with-notification.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';

import cn from 'classnames';
import { SelectedHeaderTabType } from 'stores/layout/types/layout-entities.type';
import styles from './header-tabs.module.less';

export interface IHeaderTab {
  iconFontName: IconFontName;
  notificationsCount?: number;
  slug: SelectedHeaderTabType;
  isActive: boolean;
}

interface IHeaderTabs {
  tabs: IHeaderTab[];
  onTabClick: (tabSlug: SelectedHeaderTabType) => void;
}

export const HeaderTabs = memo((props: IHeaderTabs) => {
  const { tabs, onTabClick } = props;

  const handleTabClick = useCallback(
    (tabSlug: SelectedHeaderTabType) => () => {
      onTabClick(tabSlug);
    },
    [onTabClick],
  );

  return (
    <div className={styles.HeaderTabs}>
      {tabs.map((headerTab) => (
        <div
          key={headerTab.slug}
          className={cn(styles.HeaderTabs__Item, {
            [styles['HeaderTabs__Item--active']]: headerTab.isActive,
          })}
        >
          <WithNotifications notificationCount={headerTab.notificationsCount}>
            <IconButton
              iconName={headerTab.iconFontName}
              onClick={handleTabClick(headerTab.slug)}
              theme={IconButtonTheme.Transparent}
              menuActive={headerTab.isActive}
            />
          </WithNotifications>
        </div>
      ))}
    </div>
  );
});
