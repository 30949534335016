import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const ARENA_GAME_NOTIF_TEXT_TAGS = {
  game_start: 'Game started',
  first_quarter_end: '1st quarter finished',
  second_quarter_end: '2nd quarter finished',
  third_quarter_end: '3rd quarter finished',
  fourth_quarter_end: '4th quarter finished',
  overtime_end: 'Overtime finished',
  game_end: 'Game finished',
};

export const SOCIALS_AND_NEWS_NOTIF_TEXT_TAGS = {
  headlines: 'New Headline added',
};

export const SOCIAL_LINKS = [
  { id: 1, icon: IconFontName.Twitter, url: 'https://twitter.com/Digits3Hoops' },
  { id: 2, icon: IconFontName.Insta, url: 'https://www.instagram.com/digits3hoops' },
  {
    id: 3,
    icon: IconFontName.TikTok,
    url: 'https://www.tiktok.com/@digits3hoops',
  },
  {
    id: 4,
    icon: IconFontName.Blog,
    url: 'https://mirror.xyz/0x1fc28B81d33379e7e339aCBf3711578Df98c279e',
  },
  {
    id: 5,
    icon: IconFontName.News,
    url: 'https://lp.constantcontactpages.com/su/cndfRX8/digitsnewsletter',
  },
];
