import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Device } from '@capacitor/device';

import { APP_PROTOCOL } from 'configs/environment.config';

// Incoming Android scheme:
//    digitssports://digitssports.com/home/schedule
//    https://digitssports.com/home/schedule
// Incoming IOS scheme:
//    digitssports://home/schedule
//    https://digitssports.com/home/schedule

const ANDROID_REDIRECTION_DELAY = 100; // ms

export const AppDeepLinks: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutInstanse: NodeJS.Timeout;

    App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
      const deviceInfo = await Device.getInfo();

      if (deviceInfo.platform === 'ios') {
        const isDeeplinkOpening = event.url.includes(`${APP_PROTOCOL}://`);

        if (isDeeplinkOpening) {
          const pathname = event.url.split(`${APP_PROTOCOL}:/`).pop() || '/';
          navigate(pathname);
        } else {
          const pathname = event.url.split('.com').pop() || '/';
          navigate(pathname);
        }

        return;
      }

      if (deviceInfo.platform === 'android') {
        const pathname = event.url.split('.com').pop() || '/';

        timeoutInstanse = setTimeout(() => {
          navigate(pathname);
        }, ANDROID_REDIRECTION_DELAY);
      }
    });

    return () => {
      clearTimeout(timeoutInstanse);
    };
  }, [navigate]);

  return null;
};
