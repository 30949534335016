import { injectable } from 'inversify';
import OneSignal, { NotificationClickEvent } from 'onesignal-cordova-plugin';

import { ONESIGNAL_APP_ID } from 'configs/environment.config';

@injectable()
export class PushNotificationService {
  public initialise() {
    OneSignal.initialize(ONESIGNAL_APP_ID);
  }

  public loginUser(externalId: string) {
    OneSignal.login(externalId);
  }

  public get hasOneSignalSubscription(): boolean {
    return !!OneSignal.User.pushSubscription.token;
  }

  public logoutUser() {
    OneSignal.logout();
  }

  public async requestPermission(): Promise<boolean> {
    const accepted: boolean = await OneSignal.Notifications.requestPermission(true);

    return accepted;
  }

  public get hasPermissions(): boolean {
    return OneSignal.Notifications.hasPermission();
  }

  public handleNotificationClick(callback: (event: NotificationClickEvent) => void) {
    OneSignal.Notifications.addEventListener('click', callback);
  }

  public handlePermissionChange(callback: (event: boolean) => void) {
    OneSignal.Notifications.addEventListener('permissionChange', callback);
  }
}
