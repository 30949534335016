import { IGameDetailedResponse } from 'services/games-detailed/interfaces/game-detailed-response.interface';

import { getFormattedPeriod } from 'stores/game/adapters/get-formatted-period.util';
import { gameLineScoreAdapter } from 'stores/scoreboard/adapters/game-linescore-adapter.util';

import { convertRGBToHEX } from 'helpers/string/rbg-to-hex.utils';

import { IGameDetailed } from '../interfaces/game-detailed.interface';

import { gameDetailedTeamStatAdapter } from './game-detailed-team-stat-adapter';
import { gameDetailedTopPerformAdapter } from './game-detailed-top-perform-adapter';

export function gameDetailedAdapter(gameDetailedResponse: IGameDetailedResponse): IGameDetailed {
  const {
    id,
    date_time_start: dateStart,
    status,
    home_result: home,
    visitors_result: visitors,
    current_period: currentPeriod,
    total_periods: totalPeriods,
    clock_formatted: clockFormatted,
    season,
  } = gameDetailedResponse;

  const isHomeWinnerByScores = home.points > visitors.points;
  const isVisitorsWinnerByScores = home.points < visitors.points;

  return {
    id,
    status,
    formattedPeriod: getFormattedPeriod(status, currentPeriod),
    dateStart,
    periods: {
      current: currentPeriod,
      total: totalPeriods,
    },
    season: {
      type: season.type,
    },
    clockFormatted,
    home: {
      team: {
        id: home.team_info.id,
        color: convertRGBToHEX(home.team_info.primary_color),
        secondaryTextColor: convertRGBToHEX(home.team_info.secondary_text_color),
        avatarSrc: home.team_info.small_logo_url,
        nickname: home.team_info.nickname,
        results: gameDetailedTeamStatAdapter(home.team_stat),
      },
      scores: {
        isWinner: isHomeWinnerByScores,
        total: home.points,
        lineScore: gameLineScoreAdapter(home.linescore, totalPeriods),
      },
      topPlayer: gameDetailedTopPerformAdapter(home.top_perform),
    },
    visitors: {
      team: {
        id: visitors.team_info.id,
        color: convertRGBToHEX(visitors.team_info.primary_color),
        secondaryTextColor: convertRGBToHEX(visitors.team_info.secondary_text_color),
        avatarSrc: visitors.team_info.small_logo_url,
        nickname: visitors.team_info.nickname,
        results: gameDetailedTeamStatAdapter(visitors.team_stat),
      },
      scores: {
        isWinner: isVisitorsWinnerByScores,
        total: visitors.points,
        lineScore: gameLineScoreAdapter(visitors.linescore, totalPeriods),
      },
      topPlayer: gameDetailedTopPerformAdapter(visitors.top_perform),
    },
  };
}
