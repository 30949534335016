import { FC } from 'react';

import { TopPerformerCalculationsMain } from 'components/player-rankings/top-performers-calculations-main/top-performer-calculations-main.component';

import { BaseIonBottomSheet } from '../base-ion-bottom-sheet/base-ion-bottom-sheet.component';

import styles from './performer-calc-ion-bottom-sheet.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

interface IPerformerCalcIonBottomSheetProps {
  visible: boolean;
  onClose: () => void;
}

export const PerformerCalcIonBottomSheet: FC<IPerformerCalcIonBottomSheetProps> = (props) => {
  const { visible } = props;

  return (
    <BaseIonBottomSheet
      visible={visible}
      safeAreaBottom={0}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      initialBreakpoint={INITIAL_BREAKPOINT}
      isAutoHeight
      onClose={props.onClose}
    >
      <div className={styles.PerformerCalcIonBottomSheetWrapper}>
        <TopPerformerCalculationsMain withHeader />
      </div>
    </BaseIonBottomSheet>
  );
};
