import { IPolicyResponse } from 'services/auth/interfaces/policy-response.interface';

import { IPolicy } from '../interfaces/policy.interface';

export function policyAdapter(policyResponse: IPolicyResponse): IPolicy {
  const { content, title, id, date_created: dateCreated, published } = policyResponse;

  return {
    content,
    title,
    id,
    dateCreated,
    published,
  };
}
