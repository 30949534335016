import { useCallback, useMemo } from 'react';
import cn from 'classnames';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './single-checkbox.module.less';

export enum CheckboxSize {
  Small,
  Big,
}
export interface ISingleCheckboxProps {
  id: string;
  label?: string;
  selected: boolean;
  avatar?: {
    name: string;
    src: string;
  };
  onChange: (value: string) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: CheckboxSize;
  disableHover?: boolean;
}

export const SingleCheckbox = (props: ISingleCheckboxProps) => {
  const {
    id,
    label,
    fullWidth,
    selected,
    avatar,
    disabled = false,
    disableHover = false,
    onChange,
    size = CheckboxSize.Small,
  } = props;

  const handleChange = useCallback(() => {
    onChange(id);
  }, [onChange, id]);

  const itemClassNames = useMemo(
    () =>
      cn(styles.ListItem, {
        [styles['ListItem--selected']]: selected,
        [styles['ListItem--disabled-hover']]: disableHover,
        [styles['ListItem--full-width']]: fullWidth,
      }),
    [selected, disableHover, fullWidth],
  );

  const labelClassNames = useMemo(
    () =>
      cn(styles.ListItem__Label, {
        [styles['ListItem__Label--with-avatar']]: !!avatar,
        [styles['ListItem__Label--big']]: CheckboxSize.Big === size,
        [styles['ListItem__Label--with-avatar-big']]: !!avatar && CheckboxSize.Big === size,
        [styles['ListItem__Label--disabled']]: disabled,
      }),
    [avatar, disabled, size],
  );

  const inputClassNames = useMemo(
    () =>
      cn(styles.ListItem__Input, {
        [styles['ListItem__Input--big']]: CheckboxSize.Big === size,
      }),
    [size],
  );

  return (
    <li className={itemClassNames} key={id}>
      <label className={labelClassNames} htmlFor={`custom-checkbox-${label}`}>
        <input
          className={inputClassNames}
          type="checkbox"
          id={`custom-checkbox-${label}`}
          name={label}
          value={label}
          checked={selected}
          onChange={handleChange}
          disabled={disabled}
        />
        {avatar && <Avatar username={avatar.name} size={AvatarSize.XS} src={avatar.src} />}
        {selected && <IconFont name={IconFontName.Confirm} size={IconFontSize.Small} />}
        {label && <span>{label}</span>}
      </label>
    </li>
  );
};
