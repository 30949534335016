import { FC } from 'react';

import styles from './single-point.module.less';

export interface ISinglePointProps {
  label: string;
  value: string | number;
}

export const SinglePoint: FC<ISinglePointProps> = (props: ISinglePointProps) => {
  const { value, label } = props;

  return (
    <div className={styles.Points}>
      <h3 className={styles.Points__Value}>{value}</h3>
      <div className={styles.Points__Label}>{label}</div>
    </div>
  );
};
