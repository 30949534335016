import { FC } from 'react';

import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { TopPerformerCalculationsMain } from 'components/player-rankings/top-performers-calculations-main/top-performer-calculations-main.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './performer-calc-modal.module.less';

interface IPerformerCalcModalProps {
  visible: boolean;
  onClose: () => void;
}

export const PerformerCalcModal: FC<IPerformerCalcModalProps> = (props) => {
  const { visible } = props;

  return (
    <BaseModalComponent
      visible={visible}
      size={ModalWindowSize.M}
      title="Top Performers Calculation"
      closeOnOverlayClick
      onClose={props.onClose}
    >
      <TopPerformerCalculationsMain />
      <div className={styles.Footer}>
        <Button onClick={props.onClose} size={ButtonSize.Small} theme={ButtonTheme.Primary} fluid>
          OK
        </Button>
      </div>
    </BaseModalComponent>
  );
};
