import { Outlet } from 'react-router-dom';

import { AuthContainer } from 'containers/auth/auth.container';
import { BookmarkPanelContainer } from 'containers/bookmarks-panel/bookmarks-panel.container';
import { FeedFiltersContainer } from 'containers/feed-filters/feed-filters.container';
import { GalleryContainer } from 'containers/gallery/gallery.container';
import { HeaderStructureContainer } from 'containers/header-structure/header-structure.container';
import { IntroContainer } from 'containers/intro/intro.container';
import { JoinCommunityContainer } from 'containers/join-community/join-community.container';
import { LayoutContainer } from 'containers/layout/layout.container';
import { LoggerContainer } from 'containers/logger/logger.container';
import { NotificationsContainer } from 'containers/notifications/notifications.container';
import { PushNotificationsContainer } from 'containers/push-notifications/push-notifications.container';

import { AppDeepLinks } from 'routes/app-deep-links.component';

export const RootRoute = () => {
  return (
    <>
      <IntroContainer />
      <PushNotificationsContainer />
      <AppDeepLinks />
      <LoggerContainer />
      <AuthContainer />
      <LayoutContainer>
        <Outlet />
      </LayoutContainer>
      <FeedFiltersContainer />
      <BookmarkPanelContainer />
      <NotificationsContainer />
      <GalleryContainer />
      <JoinCommunityContainer />
      <HeaderStructureContainer />
    </>
  );
};
