import { IGlossaryEntity } from 'components/ui/glossary/interfaces/glossary-entity.interface';

const COMMON_ENTITIES: IGlossaryEntity[] = [
  { leftNode: 'GP:', description: 'Games Played' },
  { leftNode: 'PTS:', description: 'Points Per Game' },
  { leftNode: 'FGM:', description: 'Average Field Goals Made' },
  { leftNode: 'FGA:', description: 'Average Field Goals Attempted' },
  { leftNode: 'FG%:', description: 'Field Goal Percentage' },
  { leftNode: '3PM:', description: 'Average 3-Point Field Goals Made' },
  { leftNode: '3PA:', description: 'Average 3-Point Field Goals Attempted' },
  { leftNode: '3P%:', description: '3-Point Field Goals Percentage' },
  { leftNode: 'FTM:', description: 'Average Free Throws Made' },
  { leftNode: 'FTA:', description: 'Average Free Throws Attempted' },
  { leftNode: 'FT%:', description: 'Free Throw Percentage' },
  { leftNode: 'OR:', description: 'Offensive Rebounds Per Game' },
  { leftNode: 'DR:', description: 'Defensive Rebounds Per Game' },
  { leftNode: 'REB:', description: 'Rebounds Per Game' },
  { leftNode: 'AST:', description: 'Assists Per Game' },
  { leftNode: 'BLK:', description: 'Blocks Per Game' },
  { leftNode: 'STL:', description: 'Steals Per Game' },
  { leftNode: 'PF:', description: 'Fouls Per Game' },
  { leftNode: 'TO:', description: 'Turnovers Per Game' },
];

const TEAM_ENTITIES: IGlossaryEntity[] = [
  { leftNode: 'DIFF:', description: 'Average Point Differential' },
  { leftNode: 'PTA:', description: 'Points Allowed Per Game' },
];
const PLAYER_ENTITIES: IGlossaryEntity[] = [{ leftNode: 'POS:', description: 'Position' }];

export const GLOBAL_STATS_GLOSSARY_TEAM_ENTITIES = [...COMMON_ENTITIES, ...TEAM_ENTITIES];

export const GLOBAL_STATS_GLOSSARY_PLAYER_ENTITIES = [...PLAYER_ENTITIES, ...COMMON_ENTITIES];
