import { AgGridEvent, SortDirection } from 'ag-grid-community';

import { setRankValues } from './set-rank-value.util';

export const handleRankValue = (
  event: AgGridEvent,
  rankDirection?: SortDirection,
  stopIndex: Maybe<number> = null,
) => {
  const tableState = event.columnApi?.getColumnState();
  const sortedColumnState = tableState.find((col) => !!col.sort);

  if (!sortedColumnState) {
    return;
  }

  if (rankDirection) {
    setRankValues(event, rankDirection, stopIndex);
  } else if (sortedColumnState.sort) {
    setRankValues(event, sortedColumnState.sort, stopIndex);
  }
};
