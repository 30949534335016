import { memo, useCallback, useMemo } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { getPreviewImage } from 'stores/gallery/utils/get-preview-image.util';
import { IPlayerDetails } from 'stores/player/interfaces/player-profile.interfaces';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { ProfileInfoSocials } from 'components/profile/profile-info/profile-info-socials/profile-info-socials.component';
import { PLAYERS_PAGE_SIZE } from 'components/stats/global-stats/global-stats-table.config';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IStatsBoxItem } from 'components/ui/stats-box/interfaces/stats-box.interface';
import { StatsBox, StatsBoxTheme } from 'components/ui/stats-box/stats-box.component';

import { preparePlayerStatsBoxItems } from './utils/prepare-player-stats-box-items.util';

import styles from './player-profile-info.module.less';

interface IPlayerProfileInfoProps {
  isFollowFetching: boolean;
  isFollowFetched: boolean;
  isFollowedPlayer: boolean;
  playerDetails: Maybe<IPlayerDetails>;
  onTeamClick: (teamId: number) => void;
  onOpenPreview: (image: IInteractiveMediaAttachment) => void;
  onFollowPlayer: () => void;
}

export const PlayerProfileInfo = memo((props: IPlayerProfileInfoProps) => {
  const {
    isFollowFetching,
    isFollowFetched,
    isFollowedPlayer,
    playerDetails,
    onTeamClick,
    onOpenPreview,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const navigate = useNavigate();

  const avatarSize = useMemo<AvatarSize>(() => {
    if (isDesktopPlus) {
      return AvatarSize.MEGA;
    }

    return AvatarSize.XXL;
  }, [isDesktopPlus]);

  const playerStatsBoxItems = useMemo<IStatsBoxItem[]>(() => {
    if (playerDetails?.stats) {
      return preparePlayerStatsBoxItems(playerDetails.stats);
    }

    return [];
  }, [playerDetails]);

  const handleTeamClick = useCallback(() => {
    if (playerDetails?.team?.id) {
      onTeamClick(playerDetails.team.id);
    }
  }, [onTeamClick, playerDetails?.team?.id]);

  const handleOpenPreview = useCallback(() => {
    if (!playerDetails?.mediumLogoUrl) {
      return;
    }

    const image = getPreviewImage(
      playerDetails.mediumLogoUrl,
      `${playerDetails.firstname.toLowerCase()}-${playerDetails.lastname.toLowerCase()}-logo`,
      playerDetails.smallLogoUrl,
    );

    onOpenPreview(image);
  }, [onOpenPreview, playerDetails]);

  const handleRankClick = useCallback(
    (item: IStatsBoxItem) => () => {
      if (playerDetails?.slug && item.sortByColumn && item.rank?.value) {
        const rank = item.rank.value;
        const page = Math.ceil(rank / PLAYERS_PAGE_SIZE);

        navigate({
          pathname: paths.HOME_STATS_PLAYERS_ALL,
          search: `${createSearchParams({
            sort: item.sortByColumn,
            playerSlug: playerDetails.slug,
            direction: 'desc',
            page: page.toString(),
          })}`,
        });
      }
    },
    [navigate, playerDetails],
  );

  return (
    <div className={styles.PlayerProfileInfo}>
      {playerDetails && (
        <>
          <div className={styles.PlayerProfileInfo__Wrapper}>
            <button className={styles.PlayerProfileInfo__Avatar} onClick={handleOpenPreview}>
              <Avatar
                src={playerDetails.mediumLogoUrl}
                username={playerDetails.firstname}
                size={avatarSize}
              />
            </button>
            <div className={styles.PlayerProfileInfo__Group}>
              <div className={styles.PlayerProfileInfo__Details}>
                <h3 className={styles.PlayerProfileInfo__Name}>
                  {playerDetails.firstname} {playerDetails.lastname}
                </h3>
                {playerDetails.team && (
                  <div className={styles.PlayerProfileInfo__Team}>
                    <button
                      className={styles.PlayerProfileInfo__TeamButton}
                      onClick={handleTeamClick}
                    >
                      <Avatar
                        size={AvatarSize.XS}
                        username={playerDetails.team.code}
                        src={playerDetails.team.smallLogoUrl}
                      />
                      <span className={styles.PlayerProfileInfo__Code}>
                        [{playerDetails.team.code}]
                      </span>
                    </button>
                    {playerDetails.jersey !== null && (
                      <span>
                        <span className={styles.PlayerProfileInfo__Jersey}>
                          #{playerDetails.jersey}{' '}
                        </span>
                        {playerDetails?.stats?.position}
                      </span>
                    )}
                  </div>
                )}
              </div>
              {!isDesktopPlus && !isFollowedPlayer && isFollowFetched && (
                <Button
                  onClick={props.onFollowPlayer}
                  theme={ButtonTheme.Primary}
                  size={ButtonSize.Small}
                  disabled={isFollowFetching}
                >
                  Follow
                </Button>
              )}
            </div>
            <div className={styles.PlayerProfileInfo__Socials}>
              <ProfileInfoSocials
                instagramUrl={playerDetails.instagramUrl}
                twitterUrl={playerDetails.twitterUrl}
                youtubeUrl={playerDetails.youtubeUrl}
                tiktokUrl={playerDetails.tiktokUrl}
              />
            </div>
          </div>
          {!!playerStatsBoxItems.length && (
            <div className={styles.PlayerProfileInfo__Stats}>
              {playerStatsBoxItems.map((item) => (
                <StatsBox
                  key={item.slug}
                  title={isDesktopPlus ? item.title : item.slug}
                  content={item.content}
                  theme={StatsBoxTheme.Transparent}
                  rank={item.rank}
                  onClick={handleRankClick(item)}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
});
