import { ChangeEvent, memo, SyntheticEvent, useCallback, useEffect, useRef } from 'react';

import {
  ConvertImageItemToAttachmentType,
  IUploadImageData,
} from 'services/application/interfaces/upload-image.interface';

import { IHookFormInput } from '../hook-form-input.interface';

import styles from './file-input.module.less';

interface IFileUploadInputProps extends IHookFormInput<IUploadImageData[]> {
  disabled?: boolean;
  multiple?: boolean;
  accept?: string;
  imagesFromCamera?: IUploadImageData[];
  onAddFilesSet?: Maybe<(handler: () => void) => void>;
  onError?: (err: string) => void;
  setLoadingImages?: (value: string[]) => void;
  convertImageItemToAttachment: ConvertImageItemToAttachmentType;
  onClick?: (event: SyntheticEvent) => void;
}

export const FileInput = memo((props: IFileUploadInputProps) => {
  const {
    disabled,
    multiple,
    id,
    name,
    accept,
    imagesFromCamera,
    onBlur,
    onChange,
    onAddFilesSet,
    onError,
    setLoadingImages,
    onClick,
    convertImageItemToAttachment,
  } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleBlur = useCallback(
    (event: SyntheticEvent) => {
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur],
  );

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { files },
      } = event;

      if (onChange) {
        if (files?.length) {
          const filesList: File[] = Array.from(files);

          try {
            const uploadedFiles: IUploadImageData[] = [];

            const loaders: string[] = filesList.map((file) => file.name);

            if (setLoadingImages) {
              setLoadingImages(loaders);
            }

            await Promise.allSettled(
              filesList.map(async (file) => {
                const imageAttachment = await convertImageItemToAttachment(file);

                if (setLoadingImages) {
                  loaders.length -= 1;
                  setLoadingImages(loaders);
                }

                if (imageAttachment) {
                  uploadedFiles.push(imageAttachment);
                  onChange(uploadedFiles);
                }
              }),
            );

            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          } catch (error) {
            if (onError) {
              onError(`${error}`);
            }
          }
        }
      }

      handleBlur(event);
    },
    [handleBlur, onChange, onError, setLoadingImages, convertImageItemToAttachment],
  );

  const handleClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  const handlePhotoCameraClick = useCallback(
    (event: SyntheticEvent) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick],
  );

  useEffect(() => {
    if (onAddFilesSet) {
      onAddFilesSet(handleClick);
    }
  }, [handleClick, onAddFilesSet]);

  useEffect(() => {
    if (imagesFromCamera?.length && onChange) {
      onChange(imagesFromCamera);
    }
    // TODO: onChange is changed every time due to editorData in base-editor.component ENG-1984
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesFromCamera]);

  return (
    <input
      className={styles.FileInput}
      disabled={disabled}
      multiple={multiple}
      accept={accept}
      id={id}
      name={name}
      type="file"
      onBlur={handleBlur}
      onChange={handleChange}
      ref={fileInputRef}
      onClick={handlePhotoCameraClick}
    />
  );
});
