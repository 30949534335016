import { memo, useCallback, useState } from 'react';

import { ISingleCategoryRecord } from 'stores/feed-filters/interfaces/feed-filters.interface';

import { TextTooltip } from 'components/ui/text-tooltip/text-tooltip.component';
import { TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import { MultipleChooseSectionCheckboxes } from './multiple-choose-section-checkboxes.component';

interface IMultipleChooseSectionCheckboxesGroupGroup {
  filters: Maybe<ISingleCategoryRecord[]>;
  onToggleCategory: (categoryId: string) => void;
  onToggleCategoryItem: (categoryId: string, tagId: string) => void;
  onToggleCollapseItemOpen?: (isOpened: boolean) => void;
}

export const MultipleChooseSectionCheckboxesGroup = memo(
  (props: IMultipleChooseSectionCheckboxesGroupGroup) => {
    const { filters, onToggleCollapseItemOpen } = props;

    const [openedSectionId, setOpenedSectionId] = useState('');

    const handleSetOpenedSectionId = useCallback(
      (sectionId: string) => {
        setOpenedSectionId(sectionId);
        onToggleCollapseItemOpen?.(!!sectionId);
      },
      [onToggleCollapseItemOpen],
    );

    if (!filters) {
      return null;
    }

    return (
      <>
        {filters.map((category) =>
          category.disabled ? (
            <TextTooltip
              key={category.uuid}
              eventType={TooltipEventType.hover}
              placement="top"
              text="Not available for this tab"
            >
              <MultipleChooseSectionCheckboxes
                selectedItems={category.tags}
                sectionTitle={category.name}
                isSectionEnabled={category.selected}
                isDisabled={category.disabled}
                sectionIconUrl={category.iconUrl}
                id={category.uuid}
                name={category.uuid}
                openedSectionId={openedSectionId}
                isMultipleChooseSectionOpen={openedSectionId === category.uuid}
                onToggleCategoryItem={props.onToggleCategoryItem}
                onToggleCategory={props.onToggleCategory}
                onSetOpenedSectionId={handleSetOpenedSectionId}
              />
            </TextTooltip>
          ) : (
            <MultipleChooseSectionCheckboxes
              key={category.uuid}
              selectedItems={category.tags}
              sectionTitle={category.name}
              isSectionEnabled={category.selected}
              isDisabled={category.disabled}
              sectionIconUrl={category.iconUrl}
              id={category.uuid}
              name={category.uuid}
              openedSectionId={openedSectionId}
              isMultipleChooseSectionOpen={openedSectionId === category.uuid}
              onToggleCategoryItem={props.onToggleCategoryItem}
              onToggleCategory={props.onToggleCategory}
              onSetOpenedSectionId={handleSetOpenedSectionId}
            />
          ),
        )}
      </>
    );
  },
);
