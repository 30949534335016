import { ILoginFollowings } from 'services/auth/interfaces/login-followings.interface';
import { ISelectedLocalFollowings } from 'services/follow/interfaces/followings-response.interface';

export const formFollowingsStringToNumbers = (followings: ISelectedLocalFollowings) => {
  const formattedFollowings: ILoginFollowings = {
    players: [],
    teams: [],
  };

  followings.players.forEach((player) => {
    const replacedIdPlayer = player.replace('player', '');
    formattedFollowings.players.push(Number(replacedIdPlayer));
  });

  followings.teams.forEach((team) => {
    const replacedIdTeam = team.replace('team', '');
    formattedFollowings.teams.push(Number(replacedIdTeam));
  });

  return formattedFollowings;
};
