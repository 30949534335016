import 'reflect-metadata';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from 'app';
import { Provider } from 'inversify-react';

import { container } from 'configs/inversify.config';

import 'normalize.css';
import './styles/index.less';

const rootNode = document.getElementById('root');

if (rootNode) {
  const root = createRoot(rootNode);

  root.render(
    <StrictMode>
      <Provider container={container}>
        <App />
      </Provider>
    </StrictMode>,
  );
} else {
  throw new Error('Root container does not exist');
}
