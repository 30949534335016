import { memo, useCallback } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './team-cell.module.less';

interface ITeamCellProps extends ICellRendererParams {
  onTeamClick: (teamId: string) => void;
}

export const TeamCell = memo((props: ITeamCellProps) => {
  const { value, onTeamClick } = props;

  const handleTeamClick = useCallback(() => {
    if (value) {
      onTeamClick(value.id);
    }
  }, [onTeamClick, value]);

  if (value) {
    const { logo, code } = value;

    return (
      <button className={styles.Team} onClick={handleTeamClick}>
        <Avatar size={AvatarSize.XS} username={code} src={logo} />
      </button>
    );
  }

  return null;
});
