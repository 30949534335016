import { FC, useCallback, useEffect, useMemo } from 'react';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';

import { IGameWithStats } from 'stores/game/interfaces/game.interface';

import { GameChatContainer } from 'containers/game-chat/game-chat.container';
import { GamePlaysContainer } from 'containers/game-plays/game-plays.container';
import { GamePollsContainer } from 'containers/game-polls/game-polls.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { BarActionType } from 'components/bars/bar-action.type';
import { BoxScore } from 'components/game/box-score/box-score.component';
import { GAME_TABS, GameDetailsTabs } from 'components/game/game.config';
import { GameHeader } from 'components/game/game-header/game-header.component';
import { GameInformation } from 'components/game/game-information/game-information.component';
import { GameScore } from 'components/game/game-score/game-score.component';
import { GameStats } from 'components/game/game-stats/game-stats.component';
import { SwipeNavigationType } from 'components/swipe-navigation/enums/swipe-navigation-type.enum';
import { SwipeNavigation } from 'components/swipe-navigation/swipe-navigation.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { Tabs } from 'components/ui/tabs/tabs.component';

export interface IGameProps {
  game: IGameWithStats;
  isPollsExist: boolean;
  activeTab: GameDetailsTabs;
  onTabClick: (action: BarActionType) => void;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  onShareClick: (gameId: number) => void;
  onGoogleAnalyticsArenaFeed: (screenType: ScreenNamesEnum) => void;
  setDisabledScroll: (isDisabledScroll: boolean) => void;
}

export const Game: FC<IGameProps> = (props) => {
  const {
    activeTab,
    game,
    isPollsExist,
    setDisabledScroll,
    onShareClick,
    onGoogleAnalyticsArenaFeed,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--home-team-primary-color',
      game.home.teamInfo.primaryColor,
    );
    document.documentElement.style.setProperty(
      '--visitors-team-primary-color',
      game.visitors.teamInfo.primaryColor,
    );
    document.documentElement.style.setProperty(
      '--home-team-text-color',
      game.home.teamInfo.textColor,
    );
    document.documentElement.style.setProperty(
      '--visitors-team-text-color',
      game.visitors.teamInfo.textColor,
    );
  }, [game]);

  const homeIsWinning = useMemo(
    () => Number(game.scores.total[0]) > Number(game.scores.total[1]),
    [game.scores.total],
  );

  const isDraw = useMemo(() => game.scores.total[0] === game.scores.total[1], [game.scores.total]);

  useEffect(() => {
    switch (activeTab) {
      case GameDetailsTabs.POLLS: {
        onGoogleAnalyticsArenaFeed(ScreenNamesEnum.ArenaPolls);
        break;
      }
      case GameDetailsTabs.TEAMS: {
        onGoogleAnalyticsArenaFeed(ScreenNamesEnum.ArenaTeamStats);
        break;
      }
      case GameDetailsTabs.CHAT: {
        onGoogleAnalyticsArenaFeed(ScreenNamesEnum.ArenaChat);
        break;
      }
      case GameDetailsTabs.PLAYS: {
        onGoogleAnalyticsArenaFeed(ScreenNamesEnum.ArenaPlays);
        break;
      }
      case GameDetailsTabs.PLAYERS: {
        onGoogleAnalyticsArenaFeed(ScreenNamesEnum.ArenaBoxScore);
        break;
      }
      default: {
        break;
      }
    }
  }, [activeTab, onGoogleAnalyticsArenaFeed]);

  const allowedTabs = useMemo(() => {
    let tabs = GAME_TABS;

    if (!isPollsExist) {
      tabs = tabs.filter((item) => {
        return item.id !== GameDetailsTabs.POLLS;
      });
    }

    return tabs;
  }, [isPollsExist]);

  const handleShareClick = useCallback(() => {
    onShareClick(game.id);
  }, [game.id, onShareClick]);

  const tabsRightCorner = useMemo(() => {
    if (isDesktopPlus) {
      return null;
    }

    return (
      <IconButton
        iconName={IconFontName.Share}
        theme={IconButtonTheme.Secondary}
        onClick={handleShareClick}
      />
    );
  }, [handleShareClick, isDesktopPlus]);

  return (
    <>
      {isDesktopPlus && (
        <GameHeader
          date={game.gameDateStart}
          homeTeam={game.home}
          visitorsTeam={game.visitors}
          onShareClick={handleShareClick}
        />
      )}
      <GameScore
        homeIsWinning={homeIsWinning}
        isDraw={isDraw}
        game={game}
        onTeamClick={props.onTeamClick}
      />
      {isDesktopPlus && <GameInformation game={game} homeIsWinning={homeIsWinning} />}
      <Tabs
        forGame
        fullWidth={!isDesktopPlus}
        activeTab={activeTab}
        tabs={allowedTabs}
        rightCorner={tabsRightCorner}
        onClick={props.onTabClick}
      />
      <SwipeNavigation
        tabs={allowedTabs}
        activeTabClick={activeTab}
        swipeNavType={SwipeNavigationType.SubTabsLevel}
        setDisabledScroll={setDisabledScroll}
        onTabClick={props.onTabClick}
      >
        {activeTab === GameDetailsTabs.POLLS && <GamePollsContainer />}

        {activeTab === GameDetailsTabs.TEAMS && (
          <GameStats gameStatus={game.status} homeTeam={game.home} visitorsTeam={game.visitors} />
        )}

        {activeTab === GameDetailsTabs.CHAT && <GameChatContainer />}

        {activeTab === GameDetailsTabs.PLAYS && <GamePlaysContainer />}

        {activeTab === GameDetailsTabs.PLAYERS && (
          <BoxScore
            homeTeamInfo={game.home}
            visitorsTeamInfo={game.visitors}
            homeTeamTotalStats={game.homeTeamTotalStats}
            visitorsTeamTotalStats={game.visitorsTeamTotalStats}
            homeTeamStartersStats={game.homeTeamStartersStats}
            visitorsTeamStartersStats={game.visitorsTeamStartersStats}
            homeTeamBenchStats={game.homeTeamBenchStats}
            visitorsTeamBenchStats={game.visitorsTeamBenchStats}
            onTeamClick={props.onTeamClick}
            onPlayerClick={props.onPlayerClick}
          />
        )}
      </SwipeNavigation>
    </>
  );
};
