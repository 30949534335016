import { memo } from 'react';

import { AuthHeader } from 'components/auth/auth-header/auth-header.component';
import { ThirdPartyServiceLogin } from 'components/auth/third-party-service-login/third-party-service-login.component';
import {
  LoginByPasswordForm,
  LoginByPasswordFormDataType,
} from 'components/forms/login-by-password/login-by-password-form.component';

export interface ILoginProps {
  onHeaderButtonClick: () => void;
  onLoginByPasswordSubmit: (formData: LoginByPasswordFormDataType) => void;
  onForgotPasswordButtonClick: () => void;
  onAppleSignIn: () => void;
  onGoogleSignIn: () => void;
}

export const Login = memo((props: ILoginProps) => (
  <>
    <AuthHeader buttonText="Sign Up" onButtonClick={props.onHeaderButtonClick} />
    <hr />
    <LoginByPasswordForm
      submitText="Login"
      onSubmit={props.onLoginByPasswordSubmit}
      onForgotPasswordButtonClick={props.onForgotPasswordButtonClick}
    />
    <ThirdPartyServiceLogin
      onAppleSignIn={props.onAppleSignIn}
      onGoogleSignIn={props.onGoogleSignIn}
    />
  </>
));
