import { FC, PropsWithChildren, ReactNode, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import {
  IModalHeaderButtonProps,
  ModalHeaderButtonComponent,
} from 'components/ui/modal-header-button/modal-header-button';
import { Portal, PortalType } from 'components/ui/portal/portal.component';

import styles from './base-modal.module.less';

export enum ModalWindowSize {
  S,
  M,
  L,
}

export interface IModal {
  visible: boolean;
  onClose: () => void;
}

export interface IBaseModalProps extends IModal {
  title: Maybe<string>;
  headerButton?: IModalHeaderButtonProps;
  size: ModalWindowSize;
  children: ReactNode;
  isNeedBackClick?: boolean;
  withHeader?: boolean;
  closeOnOverlayClick?: boolean;
  isFullScreen?: boolean;
  isScrollDisabled?: boolean;
  onBackClick?: () => void;
}

export const BaseModalComponent: FC<IBaseModalProps> = (
  props: PropsWithChildren<IBaseModalProps>,
) => {
  const {
    visible,
    withHeader = true,
    isNeedBackClick,
    closeOnOverlayClick,
    size,
    title,
    headerButton,
    children,
    isFullScreen = false,
    isScrollDisabled = false,
    onClose,
  } = props;

  const handleCloseClick = useCallback(onClose, [onClose]);

  const handleOverlayClick = useCallback(() => {
    if (closeOnOverlayClick) {
      onClose();
    }
  }, [closeOnOverlayClick, onClose]);

  const modalClassNames = useMemo(
    () =>
      cn(styles.BaseModal, TOUCH_IGNORE_CLASS, {
        [styles['BaseModal--fullscreen']]: isFullScreen,
      }),
    [isFullScreen],
  );

  const modalWindowClassNames = useMemo(
    () =>
      cn(styles.BaseModal__Window, {
        [styles['BaseModal__Window--m']]: size === ModalWindowSize.M,
        [styles['BaseModal__Window--l']]: size === ModalWindowSize.L,
        [styles['BaseModal__Window--s']]: size === ModalWindowSize.S,
      }),
    [size],
  );

  const modalContentClassNames = useMemo(
    () =>
      cn(styles.BaseModal__Content, {
        [styles['BaseModal__Content--scroll-disabled']]: isScrollDisabled,
      }),
    [isScrollDisabled],
  );

  const headerClassNames = useMemo(
    () =>
      cn(styles.BaseModal__Header, {
        [styles['BaseModal__Header--transparent']]: !withHeader,
      }),
    [withHeader],
  );

  if (!visible) {
    return null;
  }

  return (
    <Portal type={PortalType.Modal}>
      <div className={modalClassNames}>
        <div
          aria-label="Close"
          className={styles.BaseModal__Tone}
          role="button"
          tabIndex={0}
          onClick={handleOverlayClick}
          onKeyDown={handleOverlayClick}
        />
        <div className={modalWindowClassNames}>
          <div className={headerClassNames}>
            {isNeedBackClick && withHeader && (
              <IconButton
                iconName={IconFontName.ChevronLeft}
                theme={IconButtonTheme.Secondary}
                onClick={props.onBackClick}
              />
            )}
            <h4 className={styles.BaseModal__Header__Title}>{title}</h4>
            {headerButton && <ModalHeaderButtonComponent {...headerButton} />}
            <IconButton
              iconName={IconFontName.Close}
              theme={IconButtonTheme.Secondary}
              onClick={handleCloseClick}
            />
          </div>
          <div className={modalContentClassNames}>{children}</div>
        </div>
      </div>
    </Portal>
  );
};
