import { ITeamsStatsAllByTeamIdResponse } from 'services/team-stats/interfaces/teams-stats-all-response.interface';

import { seasonAdapter } from 'stores/seasons/adapters/season-adapter.util';

import { convertColorToRGB, convertColorToRGBA } from 'helpers/rgb-to-rgba.utils';
import { roundToSingleDecimal } from 'helpers/round-to-single-decimal.util';

import { ITeamStatsExtended } from '../interfaces/teams-stats-all.interface';

import { playerStatsMiniAdapter } from './player-stats-mini.adapter';

export function teamStatsExtendedAdapter(
  teamStatsExtendedResponse: ITeamsStatsAllByTeamIdResponse,
): ITeamStatsExtended {
  const {
    code,
    name,
    nickname,
    id,
    medium_logo_url: mediumLogoUrl,
    small_logo_url: smallLogoUrl,
    banner_url: bannerUrl,
    city,
    season,
    primary_color: color,
    secondary_color: secondaryColor,
    text_color: textColor,
    secondary_text_color: secondaryTextColor,
    instagram_url: instagramUrl,
    twitter_url: twitterUrl,
    youtube_url: youtubeUrl,
    tiktok_url: tiktokUrl,
    top_performers: topPerformers,
    games_played: gamesPlayed,
    games_played_rank: gamesPlayedRank,
    points,
    points_rank: pointsRank,
    fgm,
    fgm_rank: fgmRank,
    fga,
    fga_rank: fgaRank,
    fgp,
    fgp_rank: fgpRank,
    tpm,
    tpm_rank: tpmRank,
    tpa,
    tpa_rank: tpaRank,
    tpp,
    tpp_rank: tppRank,
    ftm,
    ftm_rank: ftmRank,
    fta,
    fta_rank: ftaRank,
    ftp,
    ftp_rank: ftpRank,
    off_reb: offReb,
    off_reb_rank: offRebRank,
    def_reb: defReb,
    def_reb_rank: defRebRank,
    tot_reb: totalRebounds,
    tot_reb_rank: totalReboundsRank,
    assists,
    assists_rank: assistsRank,
    blocks,
    blocks_rank: blocksRank,
    steals,
    steals_rank: stealsRank,
    personal_fouls: personalFouls,
    personal_fouls_rank: personalFoulsRank,
    turnovers,
    turnovers_rank: turnoversRank,
    conference,
    conference_rank: conferenceRank,
    win_total: totalWin,
    loss_total: totalLose,
  } = teamStatsExtendedResponse;

  return {
    code,
    color: convertColorToRGB(color),
    colorWithOpacity: convertColorToRGBA(color, 0.3),
    secondaryColor: convertColorToRGB(secondaryColor),
    textColor: convertColorToRGB(textColor),
    secondaryTextColor: convertColorToRGB(secondaryTextColor),
    name,
    nickname,
    id,
    mediumLogoUrl,
    smallLogoUrl,
    bannerUrl,
    city,
    season: seasonAdapter(season),
    instagramUrl,
    twitterUrl,
    youtubeUrl,
    tiktokUrl,
    topPerformers: {
      assists: topPerformers.assists ? playerStatsMiniAdapter(topPerformers.assists, 'APG') : null,
      points: topPerformers.points ? playerStatsMiniAdapter(topPerformers.points, 'PPG') : null,
      steals: topPerformers.steals ? playerStatsMiniAdapter(topPerformers.steals, 'SPG') : null,
      totalRebounds: topPerformers.tot_reb
        ? playerStatsMiniAdapter(topPerformers.tot_reb, 'RPG')
        : null,
      blocks: topPerformers.blocks ? playerStatsMiniAdapter(topPerformers.blocks, 'BPG') : null,
    },
    gamesPlayed,
    gamesPlayedRank,
    points: roundToSingleDecimal(points),
    pointsRank,
    fieldGoalsMade: roundToSingleDecimal(fgm),
    fieldGoalsMadeRank: fgmRank,
    fieldGoalsAttempted: roundToSingleDecimal(fga),
    fieldGoalsAttemptedRank: fgaRank,
    fieldGoalsPercentage: roundToSingleDecimal(fgp),
    fieldGoalsPercentageRank: fgpRank,
    threePointsFieldGoalsMade: roundToSingleDecimal(tpm),
    threePointsFieldGoalsMadeRank: tpmRank,
    threePointsFieldGoalsAttempted: roundToSingleDecimal(tpa),
    threePointsFieldGoalsAttemptedRank: tpaRank,
    threePointsFieldGoalsPercentage: roundToSingleDecimal(tpp),
    threePointsFieldGoalsPercentageRank: tppRank,
    freeThrowsMade: roundToSingleDecimal(ftm),
    freeThrowsMadeRank: ftmRank,
    freeThrowsAttempted: roundToSingleDecimal(fta),
    freeThrowsAttemptedRank: ftaRank,
    freeThrowsPercentage: roundToSingleDecimal(ftp),
    freeThrowsPercentageRank: ftpRank,
    offensiveReb: roundToSingleDecimal(offReb),
    offensiveRebRank: offRebRank,
    defensiveReb: roundToSingleDecimal(defReb),
    defensiveRebRank: defRebRank,
    totalRebounds: roundToSingleDecimal(totalRebounds),
    totalReboundsRank,
    assists: roundToSingleDecimal(assists),
    assistsRank,
    blocks: roundToSingleDecimal(blocks),
    blocksRank,
    steals: roundToSingleDecimal(steals),
    stealsRank,
    personalFouls: roundToSingleDecimal(personalFouls),
    personalFoulsRank,
    turnovers: roundToSingleDecimal(turnovers),
    turnoversRank,
    conference,
    conferenceRank,
    totalWin,
    totalLose,
  };
}
