import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';
import { SortType } from 'types/sort-type.type';

import { HttpService } from 'services/http/http.service';
import { IRecentGamesSearchParam } from 'services/player/interfaces/player-games.interface';

import { TYPES } from 'configs/di-types.config';

import { TEAM_ID_API_PARAM } from '../http/consts/api-endpoint-params.constants';
import {
  TEAMS_STATS,
  TEAMS_STATS_ALL,
  TEAMS_STATS_ALL_BY_TEAM_ID,
  TEAMS_STATS_BY_TEAM_ID,
  TEAMS_STATS_GAMES_BY_TEAM_ID,
  TEAMS_STATS_LEADERS,
  TEAMS_STATS_ROSTER_BY_TEAM_ID,
} from '../http/consts/api-endpoints.constants';
import { IPlayerStatsMiniResponse } from '../player/interfaces/player-stats.interface';
import { IGameMiniResponse } from '../statistics/interface/game.interface';

import {
  ITeamsStatsAllByTeamIdResponse,
  ITeamsStatsAllResponse,
} from './interfaces/teams-stats-all-response.interface';
import { ITeamsStatsLeadersResponse } from './interfaces/teams-stats-leaders.interfaces';
import { ITeamsStatsResponse } from './interfaces/teams-stats-response.interface';

@injectable()
export class TeamsStatsService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchStatsTeams(): Promise<IResponse<ITeamsStatsResponse[]>> {
    return this.httpService.request({
      method: 'GET',
      url: TEAMS_STATS,
    });
  }

  public fetchTeamStatsByTeamId(teamId: number): Promise<IResponse<ITeamsStatsResponse>> {
    return this.httpService.request({
      method: 'GET',
      url: TEAMS_STATS_BY_TEAM_ID,
      routeParams: { [TEAM_ID_API_PARAM]: teamId.toString() },
    });
  }

  public fetchTeamsStatsAll(
    seasonId?: Maybe<string>,
  ): Promise<IResponse<ITeamsStatsAllResponse[]>> {
    let params;

    if (seasonId) {
      params = new URLSearchParams({ seasonId });
    }

    return this.httpService.request({
      method: 'GET',
      url: TEAMS_STATS_ALL,
      params,
    });
  }

  public fetchTeamsStatsAllByTeamId(
    teamId: number,
    seasonId?: Maybe<string>,
  ): Promise<IResponse<ITeamsStatsAllByTeamIdResponse>> {
    let params;

    if (seasonId) {
      params = new URLSearchParams({ seasonId });
    }

    return this.httpService.request({
      method: 'GET',
      url: TEAMS_STATS_ALL_BY_TEAM_ID,
      routeParams: { [TEAM_ID_API_PARAM]: teamId.toString() },
      params,
    });
  }

  public fetchTeamsStatsLeaders(
    seasonId: Maybe<string>,
  ): Promise<IResponse<ITeamsStatsLeadersResponse>> {
    let params;

    if (seasonId) {
      params = new URLSearchParams({ seasonId });
    }

    return this.httpService.request({
      method: 'GET',
      url: TEAMS_STATS_LEADERS,
      params,
    });
  }

  public fetchTeamsStatsGamesByTeamId(
    teamId: number,
    pagination: IPagination,
    order: SortType,
    statuses: string,
  ): Promise<IResponse<IGameMiniResponse[]>> {
    const { limit, page } = pagination;

    const paramsObj: IRecentGamesSearchParam = {
      limit: String(limit),
      page: String(page),
      statuses,
    };

    if (order) {
      paramsObj.order = order;
    }

    return this.httpService.request({
      method: 'GET',
      url: TEAMS_STATS_GAMES_BY_TEAM_ID,
      routeParams: { [TEAM_ID_API_PARAM]: teamId.toString() },
      params: new URLSearchParams({ ...paramsObj }),
    });
  }

  public fetchTeamsStatsRosterByTeamId(
    teamId: number,
  ): Promise<IResponse<IPlayerStatsMiniResponse[]>> {
    return this.httpService.request({
      method: 'GET',
      url: TEAMS_STATS_ROSTER_BY_TEAM_ID,
      routeParams: {
        [TEAM_ID_API_PARAM]: teamId.toString(),
      },
    });
  }
}
