import { Fragment, memo, useCallback, useMemo, useState } from 'react';

import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';

import { ITopPlayerItem } from 'stores/player/interfaces/top-player-item.interface';
import { ITeam } from 'stores/teams/interfaces/team.interface';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import { BoardItem } from './board-item/board-item.component';
import { BoardSimpleItem } from './board-simple-item/board-simple-item.component';

import styles from './feed-list-board.module.less';

export interface IFeedListBoardProps {
  title: string;
  expandText: string;
  items: ITeam[] | ITopPlayerItem[];
  onClick: (id: string, label?: string) => void;
}

const MIN_ITEMS_LENGTH = 5;

export const FeedListBoard = memo((props: IFeedListBoardProps) => {
  const { title, expandText, items } = props;

  const [isExpand, setIsExpand] = useState(true);

  const handleExpandClick = useCallback(() => {
    setIsExpand(!isExpand);
  }, [setIsExpand, isExpand]);

  const showingItems = useMemo(() => {
    if (!isExpand) {
      const shortenItems = [...items];

      if (shortenItems.length > MIN_ITEMS_LENGTH) {
        shortenItems.length = MIN_ITEMS_LENGTH;
      }

      return shortenItems;
    }

    return items;
  }, [items, isExpand]);

  return (
    <div className={styles.FeedListBoard}>
      <div className={styles.FeedListBoard__Header}>{title}</div>
      <div className={styles.FeedListBoard__Teams}>
        {showingItems.map((item) => (
          <Fragment key={item.type === PostsFeedType.Team ? item.teamId : item.playerId}>
            {item.type === PostsFeedType.Player && (
              <BoardSimpleItem
                avatarUrl={item.avatar}
                firstname={item.firstname}
                lastname={item.lastname}
                slug={item.slug}
                followingsCount={item.followingsCount}
                onClick={props.onClick}
              />
            )}
            {item.type === PostsFeedType.Team && (
              <BoardItem
                key={item.teamId}
                teamId={item.teamId.toString()}
                imageUrl={item.thumbnailUrl}
                name={item.name}
                isJoined={item.joined}
                followers={item.followers}
                onVisitClick={props.onClick}
              />
            )}
          </Fragment>
        ))}
      </div>
      <div className={styles.FeedListBoard__Footer}>
        <Button onClick={handleExpandClick} size={ButtonSize.Big} theme={ButtonTheme.Text}>
          {isExpand ? 'Show less' : expandText}
        </Button>
      </div>
    </div>
  );
});
