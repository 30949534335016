import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import { IGameDetailed } from 'stores/scoreboard/interfaces/game-detailed.interface';

import { GameDetailsTabs } from 'components/game/game.config';

import { ScoreboardTable } from '../scoreboard-table/scoreboard-table.component';

import styles from './scoreboard-day.module.less';

interface IScoreboardDayProps {
  formattedSelectedDate: string;
  games: IGameDetailed[];
  timezone: string;
  onPlayerClick: (slug: string, gameId: string) => void;
  onTeamClick: (teamId: number) => void;
  onGameClick: (gameId: number, tabQuery: Maybe<GameDetailsTabs>) => void;
}

export const ScoreboardDay = memo((props: IScoreboardDayProps) => {
  const { formattedSelectedDate, games, timezone, onGameClick } = props;

  const firstNotFinishedGameId = useMemo(() => {
    const firstGame = games.find((game) => game.status !== GameStatus.Finished);
    if (firstGame) {
      return firstGame.id;
    }

    return null;
  }, [games]);

  const getGameClassNames = useCallback(
    (id: number) =>
      cn(styles.ScoreboardDay__Game, {
        [styles['ScoreboardDay__Game--first-not-finished']]: firstNotFinishedGameId === id,
      }),
    [firstNotFinishedGameId],
  );

  const handleGameClick = useCallback(
    (gameId: number) => (tabQuery: Maybe<GameDetailsTabs>) => {
      onGameClick(gameId, tabQuery);
    },
    [onGameClick],
  );

  return (
    <div className={styles.ScoreboardDay}>
      <div className={styles.ScoreboardDay__Header}>{formattedSelectedDate}</div>
      {!!games.length &&
        games.map((game) => (
          <div key={game.id} className={getGameClassNames(game.id)}>
            <ScoreboardTable
              firstNotFinished={game.id === firstNotFinishedGameId}
              dateStart={game.dateStart}
              gameId={game.id}
              status={game.status}
              home={game.home}
              visitors={game.visitors}
              seasonType={game.season.type}
              timezone={timezone}
              periods={game.periods}
              formattedPeriod={game.formattedPeriod}
              clock={game.clockFormatted}
              onPlayerClick={props.onPlayerClick}
              onTeamClick={props.onTeamClick}
              onGameClick={handleGameClick(game.id)}
            />
          </div>
        ))}
      {!games.length && <div className={styles.NoGames}>No games scheduled</div>}
    </div>
  );
});
