import { ITeamsStatsAllResponse } from 'services/team-stats/interfaces/teams-stats-all-response.interface';

import { seasonAdapter } from 'stores/seasons/adapters/season-adapter.util';

import { convertColorToRGB, convertColorToRGBA } from 'helpers/rgb-to-rgba.utils';
import { roundToSingleDecimal } from 'helpers/round-to-single-decimal.util';

import { ITeamStatsMini } from '../interfaces/teams-stats-all.interface';

export function teamStatsMiniAdapter(
  teamStatsMiniResponse: ITeamsStatsAllResponse,
): ITeamStatsMini {
  const {
    code,
    name,
    nickname,
    id,
    medium_logo_url: mediumLogoUrl,
    small_logo_url: smallLogoUrl,
    banner_url: bannerUrl,
    city,
    season,
    primary_color: color,
    secondary_color: secondaryColor,
    text_color: textColor,
    secondary_text_color: secondaryTextColor,
    games_played: gamesPlayed,
    points,
    fgm,
    fga,
    fgp,
    tpm,
    tpa,
    tpp,
    ftm,
    fta,
    ftp,
    off_reb: offReb,
    def_reb: defReb,
    tot_reb: totalRebounds,
    assists,
    blocks,
    steals,
    personal_fouls: personalFouls,
    turnovers,
    conference,
    diff_points: diffPoints,
    opponent_points: opponentPoints,
  } = teamStatsMiniResponse;

  return {
    code,
    color: convertColorToRGB(color),
    colorWithOpacity: convertColorToRGBA(color, 0.3),
    secondaryColor: convertColorToRGB(secondaryColor),
    textColor: convertColorToRGB(textColor),
    secondaryTextColor: convertColorToRGB(secondaryTextColor),
    name,
    nickname,
    id,
    mediumLogoUrl,
    smallLogoUrl,
    bannerUrl,
    city,
    season: seasonAdapter(season),
    gamesPlayed,
    points: roundToSingleDecimal(points),
    fieldGoalsMade: roundToSingleDecimal(fgm),
    fieldGoalsAttempted: roundToSingleDecimal(fga),
    fieldGoalsPercentage: roundToSingleDecimal(fgp),
    threePointsFieldGoalsMade: roundToSingleDecimal(tpm),
    threePointsFieldGoalsAttempted: roundToSingleDecimal(tpa),
    threePointsFieldGoalsPercentage: roundToSingleDecimal(tpp),
    freeThrowsMade: roundToSingleDecimal(ftm),
    freeThrowsAttempted: roundToSingleDecimal(fta),
    freeThrowsPercentage: roundToSingleDecimal(ftp),
    offensiveReb: roundToSingleDecimal(offReb),
    defensiveReb: roundToSingleDecimal(defReb),
    totalRebounds: roundToSingleDecimal(totalRebounds),
    assists: roundToSingleDecimal(assists),
    blocks: roundToSingleDecimal(blocks),
    steals: roundToSingleDecimal(steals),
    personalFouls: roundToSingleDecimal(personalFouls),
    turnovers: roundToSingleDecimal(turnovers),
    conference,
    opponentPoints: roundToSingleDecimal(opponentPoints),
    diffPoints: roundToSingleDecimal(diffPoints),
  };
}
