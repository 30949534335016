import type { EditorThemeClasses } from 'lexical';

import styles from './basic-theme.module.less';

const theme: EditorThemeClasses = {
  quote: styles.EditorQuote,
  text: {
    bold: styles['EditorText--bold'],
    italic: styles['EditorText--italic'],
    strikethrough: styles['EditorText--strikethrough'],
    underline: styles['EditorText--underline'],
    underlineStrikethrough: styles['EditorText--underline-strikethrough'],
  },
  list: {
    nested: {
      listitem: styles['Editor--nested-list-item'],
    },
  },
};
export default theme;
