import { FC, memo } from 'react';
import { InstagramEmbed } from 'react-social-media-embed';

import { EmbedWrapperCard } from 'components/social-card/embed-wrapper-card/embed-wrapper-card.component';

interface IInstagramProps {
  sourceUrl: string;
  width: number;
}

export const Instagram: FC<IInstagramProps> = memo(({ sourceUrl, width }: IInstagramProps) => {
  return (
    <EmbedWrapperCard>
      <InstagramEmbed url={sourceUrl} width={width} />
    </EmbedWrapperCard>
  );
});
