import {
  IPollAttachmentResponse,
  IPollOptionResponse,
} from 'services/posts/interfaces/post-attachments-response.interface';
import { IPollAttachment, IPollOption } from '../interfaces/post.interface';

export function pollAdapter(pollResponse: IPollAttachmentResponse): IPollAttachment {
  const {
    uuid,
    type,
    votes_total: votesTotal,
    options,
    voted_option_uuid: votedOptionId,
    status,
    date_closed: expirationDate,
    date_created: dateCreated,
  } = pollResponse;

  const pollOptions: IPollOption[] = getPollOptions(options);
  const expirationTimestamp: number = new Date(expirationDate).getTime();

  return {
    uuid,
    type,
    votesTotal,
    options: pollOptions,
    votedOptionId,
    status,
    expirationTimestamp,
    dateCreated,
  };
}

export const getPollOptions = (options: IPollOptionResponse[]): IPollOption[] => {
  return options.map((option) => ({
    uuid: option.uuid,
    title: option.title,
    imageUrl: option.image_url,
    votesTotal: option.votes_total,
    percentage: option.votes_percentage,
    order: option.order,
  }));
};
