import { FC, useMemo } from 'react';
import cn from 'classnames';

import styles from './single-point.module.less';

interface ISinglePointProps {
  isSpecial: boolean;
  label: string;
  value: number | string;
  categoryName: string;
}

enum PlusMinusPointState {
  Negative,
  Positive,
}

export const SinglePoint: FC<ISinglePointProps> = (props: ISinglePointProps) => {
  const { label, value, categoryName, isSpecial } = props;

  const isPlusMinusPoint = useMemo(() => categoryName === 'plusMinus', [categoryName]);

  const plusMinusPointState = useMemo(() => {
    if (typeof value === 'number' && isPlusMinusPoint) {
      if (Math.sign(value) === -1) {
        return PlusMinusPointState.Negative;
      }

      if (Math.sign(value) === 1) {
        return PlusMinusPointState.Positive;
      }
    }

    return null;
  }, [value, isPlusMinusPoint]);

  const pointValue = useMemo(() => {
    if (isPlusMinusPoint && PlusMinusPointState.Positive === plusMinusPointState) {
      return `+${value}`;
    }

    return value;
  }, [value, isPlusMinusPoint, plusMinusPointState]);

  const pointClasses = useMemo<string>(
    () =>
      cn(styles.Point, {
        [styles['Point--special']]: isSpecial,
      }),
    [isSpecial],
  );

  const pointValueClasses = useMemo(
    () =>
      cn(styles.Point__Value, {
        [styles['Point__Value--positive']]:
          isPlusMinusPoint && PlusMinusPointState.Positive === plusMinusPointState,
        [styles['Point__Value--negative']]:
          isPlusMinusPoint && PlusMinusPointState.Negative === plusMinusPointState,
      }),
    [isPlusMinusPoint, plusMinusPointState],
  );

  return (
    <div className={pointClasses}>
      <span className={pointValueClasses}>{pointValue}</span>
      <span className={styles.Point__Label}>{label}</span>
    </div>
  );
};
