import { forwardRef, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { IonContent, IonRefresher } from '@ionic/react';
import cn from 'classnames';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';

import { CUSTOM_SCROLL_EVENT } from 'configs/controls.config';
import {
  LOTTIE_FIRST_FRAMES_PART,
  LOTTIE_MAX_FRAMES,
  LOTTIE_SPEED,
  PARAM_PATHS_WITH_STATIC_HEADER,
  PATHS_WITH_STATIC_HEADER,
  REFRESHER_HEIGHT,
  REFRESHER_MAX_HEIGHT,
  REFRESHER_PULL_MIN,
} from 'configs/refresher.config';
import { getPaths } from 'helpers/get-paths.util';
import * as paths from 'routes/paths.constants';

import animationData from 'assets/lottie/animation.json';

import styles from './refresher.module.less';

interface IRefresherProps {
  isDesktopPlus: boolean;
  isNativeApp: boolean;
  isMobileSidebarOpen: boolean;
  isSidePanelOpen: boolean;
  isHeaderDisabled: boolean;
  isRemovedSafeAreaTop: boolean;
  isHiddenBottomBar: boolean;
  isEnabledRefresher: boolean;
  isDisabledScroll: boolean;
  isPulledRefresher: boolean;
  isRefresherTopPosition: boolean;
  isDisplayedSmartBanner: boolean;
  hasPostId: boolean;
  hasTabs: boolean;
  children: ReactNode;
  setPulledRefresher: (isPulledRefresher: boolean) => void;
  onScrollContainer: (element: HTMLElement) => void;
}

export const Refresher = forwardRef<Maybe<HTMLElement>, IRefresherProps>((props, ref) => {
  const {
    isDesktopPlus,
    isNativeApp,
    isMobileSidebarOpen,
    isSidePanelOpen,
    isHeaderDisabled,
    isRemovedSafeAreaTop,
    isHiddenBottomBar,
    isEnabledRefresher,
    isRefresherTopPosition,
    isDisabledScroll,
    isPulledRefresher,
    isDisplayedSmartBanner,
    hasPostId,
    hasTabs,
    children,
    setPulledRefresher,
    onScrollContainer,
  } = props;

  const params = useParams<{
    [paths.TEAM_ID_PARAM]: string;
    [paths.PLAYER_SLUG_PARAM]: string;
  }>();
  const { pathname } = useLocation();

  const { teamId, playerSlug } = params;

  const [zoomEffect, setZoomEffect] = useState(false);
  const [refreshHeight, setRefreshHeight] = useState(REFRESHER_HEIGHT);
  const [releasedFinger, setReleasedFinger] = useState(false);
  const [triggeredEndRefresh, setTriggeredEndRefresh] = useState(false);

  const ionRefresherElementRef = useRef<Maybe<HTMLIonRefresherElement>>(null);
  const ionContentElementRef = useRef<Maybe<HTMLIonContentElement>>(null);
  const lottieRef = useRef<Maybe<LottieRefCurrentProps>>(null);

  const customScrollEvent = useRef<Maybe<CustomEvent>>(null);

  const staticHeaderPaths = useMemo(() => {
    if (teamId) {
      return getPaths({ teamId }, PATHS_WITH_STATIC_HEADER, PARAM_PATHS_WITH_STATIC_HEADER);
    }

    if (playerSlug) {
      return getPaths({ playerSlug }, PATHS_WITH_STATIC_HEADER, PARAM_PATHS_WITH_STATIC_HEADER);
    }

    return PATHS_WITH_STATIC_HEADER;
  }, [teamId, playerSlug]);

  const hapticsLightImpact = async () => {
    await Haptics.impact({
      style: ImpactStyle.Light,
    });
  };

  const handleStartRefresh = useCallback(() => {
    lottieRef.current?.playSegments([0, LOTTIE_MAX_FRAMES], true);
    lottieRef.current?.goToAndStop(0, true);
    setRefreshHeight(REFRESHER_HEIGHT);
  }, []);

  // This 'any' type due to library restriction
  const handlePullRefresh = useCallback((event: any) => {
    const pullPosition = Math.round(event.target.scrollEl.getBoundingClientRect().top);
    const framePosition = Math.round(
      (pullPosition / REFRESHER_MAX_HEIGHT) * LOTTIE_FIRST_FRAMES_PART,
    );

    if (pullPosition >= REFRESHER_HEIGHT) {
      setRefreshHeight(pullPosition);
    }

    if (lottieRef.current?.animationItem) {
      const { totalFrames } = lottieRef.current.animationItem;

      if (totalFrames > framePosition && framePosition <= LOTTIE_FIRST_FRAMES_PART) {
        lottieRef.current.goToAndStop(framePosition, true);
      }
    }
  }, []);

  const handleEndRefresh = useCallback(() => {
    setTriggeredEndRefresh(true);
    setZoomEffect(true);
    setRefreshHeight(REFRESHER_MAX_HEIGHT);

    lottieRef.current?.playSegments([LOTTIE_FIRST_FRAMES_PART, LOTTIE_MAX_FRAMES]);
    lottieRef.current?.setSpeed(LOTTIE_SPEED);

    if (isNativeApp) {
      hapticsLightImpact();
    }
  }, [isNativeApp]);

  const handleTouchEnd = useCallback(() => {
    if (triggeredEndRefresh) {
      setPulledRefresher(true);
      setReleasedFinger(true);
    }
  }, [setPulledRefresher, triggeredEndRefresh]);

  const isEnabledScrollEvents = useMemo<boolean>(() => {
    return !isDesktopPlus && !staticHeaderPaths.includes(pathname);
  }, [isDesktopPlus, staticHeaderPaths, pathname]);

  // This 'any' type due to library restriction
  const handleIonScroll = useCallback(
    async (event: any) => {
      const scrollElement: HTMLElement = await event.target.scrollEl;

      if (customScrollEvent.current) {
        document.dispatchEvent(customScrollEvent.current);
      }

      if (isEnabledScrollEvents) {
        onScrollContainer(scrollElement);
      }
    },
    [isEnabledScrollEvents, onScrollContainer],
  );

  useEffect(() => {
    if (!isPulledRefresher && releasedFinger) {
      ionRefresherElementRef.current?.complete();
      lottieRef.current?.stop();
      setReleasedFinger(false);
      setTriggeredEndRefresh(false);
      setZoomEffect(false);
    }
  }, [isPulledRefresher, releasedFinger]);

  useEffect(() => {
    (async () => {
      const scrollElement = (await ionContentElementRef.current?.getScrollElement()) ?? null;
      const scrollElementRef = ref;

      if (typeof scrollElementRef === 'function') {
        scrollElementRef(scrollElement);
      } else if (scrollElementRef) {
        scrollElementRef.current = scrollElement;
      }

      customScrollEvent.current = new CustomEvent(CUSTOM_SCROLL_EVENT, {
        detail: {
          target: scrollElement,
        },
      });
    })();
  }, [ref]);

  useEffect(() => {
    if (isDisabledScroll) {
      document.body.classList.add('disabled-scrolling');
    } else {
      document.body.classList.remove('disabled-scrolling');
    }
  }, [isDisabledScroll]);

  const classIonContentNames = useMemo(
    () =>
      cn(styles.IonContent, {
        [styles['IonContent--disabled-header']]: isHeaderDisabled,
        [styles['IonContent--without-safe-area-top']]: isRemovedSafeAreaTop,
        [styles['IonContent--disabled-mobile-scroll']]: isMobileSidebarOpen || isSidePanelOpen,
        [styles['IonContent--strict-disabled-scroll']]: isDisabledScroll,
        [styles['IonContent--disabled-bottom-bar']]: !isDesktopPlus && isHiddenBottomBar,
        [styles['IonContent--has-navigation']]: hasTabs && (!hasPostId || isDesktopPlus),
        [styles['IonContent--has-smart-banner']]: isDisplayedSmartBanner,
      }),
    [
      hasPostId,
      hasTabs,
      isDisplayedSmartBanner,
      isHeaderDisabled,
      isRemovedSafeAreaTop,
      isDesktopPlus,
      isMobileSidebarOpen,
      isSidePanelOpen,
      isHiddenBottomBar,
      isDisabledScroll,
    ],
  );

  const classIonRefresherClass = useMemo(
    () =>
      cn(styles.Refresher, {
        [styles['Refresher--disabled-header']]: isHeaderDisabled,
        [styles['Refresher--disabled-bottom-bar']]: !isDesktopPlus && isHiddenBottomBar,
        [styles['Refresher--has-navigation']]: hasTabs && (!hasPostId || isDesktopPlus),
        [styles['Refresher--top-position']]: isRefresherTopPosition,
      }),
    [
      hasPostId,
      hasTabs,
      isHeaderDisabled,
      isDesktopPlus,
      isHiddenBottomBar,
      isRefresherTopPosition,
    ],
  );

  const classLottieNames = useMemo(
    () =>
      cn(styles.Lottie, {
        [styles['Lottie--bounce-zoom']]: zoomEffect,
      }),
    [zoomEffect],
  );

  const refreshStyles = useMemo(
    () => ({
      height: `${refreshHeight}px`,
    }),
    [refreshHeight],
  );

  return (
    <IonContent
      ref={ionContentElementRef}
      scrollEvents
      className={classIonContentNames}
      onIonScroll={handleIonScroll}
      onTouchEnd={handleTouchEnd}
    >
      {!isDesktopPlus && isEnabledRefresher && isNativeApp && (
        <IonRefresher
          slot="fixed"
          ref={ionRefresherElementRef}
          pullMin={REFRESHER_PULL_MIN}
          onIonStart={handleStartRefresh}
          onIonPull={handlePullRefresh}
          onIonRefresh={handleEndRefresh}
          className={classIonRefresherClass}
          style={refreshStyles}
        >
          <Lottie
            lottieRef={lottieRef}
            animationData={animationData}
            className={classLottieNames}
          />
        </IonRefresher>
      )}
      {children}
    </IonContent>
  );
});
