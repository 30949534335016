import { Dialog } from '@capacitor/dialog';
import { IOSSettings, NativeSettings } from 'capacitor-native-settings';

export const handleIosMicrophoneDenied = async () => {
  const { value } = await Dialog.confirm({
    title: 'Please Allow Access',
    message:
      'Digits needs microphone access so that you can take videos. Please go to your device settings > Privacy > Microphone and set Digits to ON.',
    okButtonTitle: 'Settings',
    cancelButtonTitle: 'Not now',
  });

  if (value) {
    await NativeSettings.openIOS({ option: IOSSettings.App });
  }
};
