import { ITeamsStatsResponse } from 'services/team-stats/interfaces/teams-stats-response.interface';

import { convertColorToRGB, convertColorToRGBA } from 'helpers/rgb-to-rgba.utils';

import { ITeamsStats } from '../interfaces/teams-stats.interface';

export function teamStatsAdapter(teamStats: ITeamsStatsResponse): ITeamsStats {
  return {
    code: teamStats.code,
    color: convertColorToRGB(teamStats.primary_color),
    colorWithOpacity: convertColorToRGBA(teamStats.primary_color, 0.3),
    secondaryColor: convertColorToRGB(teamStats.secondary_color),
    textColor: convertColorToRGB(teamStats.text_color),
    secondaryTextColor: convertColorToRGB(teamStats.secondary_text_color),
    city: teamStats.city,
    name: teamStats.name,
    nickname: teamStats.nickname,
    id: teamStats.id,
    mediumLogoUrl: teamStats.medium_logo_url,
    smallLogoUrl: teamStats.small_logo_url,
    smallThumbnailUrl: teamStats.small_thumbnail_url,
    mediumThumbnailUrl: teamStats.medium_thumbnail_url,
    bannerUrl: teamStats.banner_url,
    instagramUrl: teamStats.instagram_url,
    twitterUrl: teamStats.twitter_url,
    tiktokUrl: teamStats.tiktok_url,
    youtubeUrl: teamStats.youtube_url,
  };
}

export function teamsStatsAdapter(teamsStatsResponse: ITeamsStatsResponse[]): ITeamsStats[] {
  return teamsStatsResponse.map(teamStatsAdapter);
}
