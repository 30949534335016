import { ColDef } from 'ag-grid-community';

import { objectValueFormatter } from 'components/base-table/helpers/object-value-formatter.util';

import { StatsPlayerCell } from './stats-first-cell/stats-player-cell.component';
import { StatsTeamCell } from './stats-first-cell/stats-team-cell.component';

const NAME_COL_WIDTH = 136;
const DEFAULT_COL_WIDTH = 50;
export const PLAYERS_PAGE_SIZE = 30;

export const TEAMS_STATS_NAME_COL_DEF: ColDef = {
  field: 'name',
  headerName: 'Name',
  pinned: 'left',
  cellRenderer: StatsTeamCell,
  headerClass: 'ag-global-stats-header-name',
  width: NAME_COL_WIDTH,
  valueFormatter: objectValueFormatter,
};

export const PLAYERS_STATS_NAME_COL_DEF: ColDef = {
  field: 'name',
  headerName: 'Name',
  pinned: 'left',
  cellRenderer: StatsPlayerCell,
  headerClass: 'ag-global-stats-header-name',
  width: NAME_COL_WIDTH,
  valueFormatter: objectValueFormatter,
};

const COMMON_COL_DEF: ColDef[] = [
  {
    headerTooltip: 'Games Played',
    field: 'gamesPlayed',
    headerName: 'GP',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Points',
    field: 'points',
    headerName: 'PTS',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Field Goals Made',
    field: 'fieldGoalsMade',
    headerName: 'FGM',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Field Goals Attempted',
    field: 'fieldGoalsAttempted',
    headerName: 'FGA',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Field Goal Percentage',
    field: 'fieldGoalsPercentage',
    headerName: 'FG%',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: '3-Point Field Goals Made',
    field: 'threePointsFieldGoalsMade',
    headerName: '3PM',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: '3-Point Field Goals Attempted',
    field: 'threePointsFieldGoalsAttempted',
    headerName: '3PA',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: '3-Point Field Goal Percentage',
    field: 'threePointsFieldGoalsPercentage',
    headerName: '3P%',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Free Throws Made',
    field: 'freeThrowsMade',
    headerName: 'FTM',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Free Throws Attempted',
    field: 'freeThrowsAttempted',
    headerName: 'FTA',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Free Throw Percentage',
    field: 'freeThrowsPercentage',
    headerName: 'FT%',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Offensive Rebounds',
    field: 'offensiveReb',
    headerName: 'OR',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Defensive Rebounds',
    field: 'defensiveReb',
    headerName: 'DR',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Rebounds',
    field: 'totalRebounds',
    headerName: 'REB',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Assists',
    field: 'assists',
    headerName: 'AST',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Blocks',
    field: 'blocks',
    headerName: 'BLK',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Steals',
    field: 'steals',
    headerName: 'STL',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Personal Fouls',
    field: 'personalFouls',
    headerName: 'PF',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Turnovers',
    field: 'turnovers',
    headerName: 'TO',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
];

export const TEAM_STATS_ALL_COL_DEF: ColDef[] = [
  ...COMMON_COL_DEF,
  {
    headerTooltip: 'Points Differential',
    field: 'pointsDifferential',
    headerName: 'DIFF',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
  {
    headerTooltip: 'Points Allowed',
    field: 'pointsAllowed',
    headerName: 'PTA',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
    sortable: true,
  },
];

export const PLAYERS_STATS_ALL_COL_DEF: ColDef[] = [
  {
    headerTooltip: 'Position',
    field: 'position',
    headerName: 'POS',
    width: DEFAULT_COL_WIDTH,
    minWidth: DEFAULT_COL_WIDTH,
  },
  ...COMMON_COL_DEF,
];
