import { memo, useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import cn from 'classnames';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { VectorIconName } from 'components/ui/vector-icon/vector-icon.component';

import styles from './player-name.module.less';

export const PlayerName = memo((props: ICellRendererParams) => {
  const { column, value, node, data } = props;
  const { avatarUrl, fullName, position } = value;
  const { isLeader } = data;

  const plusMinusStyles = useMemo(
    () =>
      cn(styles.PlusMinus, {
        [styles['PlusMinus--positive']]: node.data?.plusMinus > 0,
        [styles['PlusMinus--negative']]: node.data?.plusMinus < 0,
      }),
    [node.data?.plusMinus],
  );

  if (node.data.gameMissReason && column?.getColId() === 'minutes') {
    return <div className={styles.Reason}>{node.data?.gameMissReason}</div>;
  }

  if (column?.getColId() === 'plusMinus') {
    const plusMinusValue = node.data?.plusMinus;

    if (node.data.gameMissReason) {
      return null;
    }

    return (
      <div className={plusMinusStyles}>
        {plusMinusValue <= 0 ? String(plusMinusValue) : `+${plusMinusValue}`}
      </div>
    );
  }

  if (column?.getColId() === 'player') {
    return (
      <div className={styles.Player}>
        <Avatar
          vectorIconName={isLeader ? VectorIconName.Fire : null}
          size={AvatarSize.S}
          username={fullName}
          src={avatarUrl}
        />
        <div className={styles.Player__Name}>{fullName}</div>
        <span className={styles.Player__Position}>{position}</span>
      </div>
    );
  }

  if (node.data?.gameMissReason) {
    return null;
  }

  return value;
});
