import {
  FC,
  memo,
  PropsWithChildren,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import cn from 'classnames';
import { v4 } from 'uuid';

import { IButton } from 'components/ui/button/button.interface';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './button.module.less';

export enum ButtonTheme {
  Primary = 'primary',
  PrimaryAlert = 'primary-alert',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Text = 'text',
  TextSecondary = 'text-secondary',
  TextAlert = 'text-alert',
}

export enum ButtonSize {
  Big = 'big',
  Small = 'small',
  SmallSecondary = 'smallSecondary',
}

export interface IButtonProps extends IButton {
  size: ButtonSize;
  theme: ButtonTheme;
  children: ReactNode;
  iconName?: Maybe<IconFontName>;
  selected?: boolean;
  isRandomId?: boolean;
  name?: string;
}

export const Button: FC<IButtonProps> = memo((props: PropsWithChildren<IButtonProps>) => {
  const {
    disabled = false,
    theme,
    size,
    type = 'button',
    onClick = null,
    children,
    iconName = null,
    fluid = false,
    isRandomId = false,
    name,
    selected = false,
  } = props;

  const buttonIdRef = useRef(v4());

  const btnClassNames = useMemo(
    () =>
      cn(styles.Button, {
        [styles['Button--fluid']]: fluid,
        [styles['Button--primary']]: theme === ButtonTheme.Primary,
        [styles['Button--primary-alert']]: theme === ButtonTheme.PrimaryAlert,
        [styles['Button--secondary']]: theme === ButtonTheme.Secondary,
        [styles['Button--tertiary']]: theme === ButtonTheme.Tertiary,
        [styles['Button--text']]: theme === ButtonTheme.Text,
        [styles['Button--text-secondary']]: theme === ButtonTheme.TextSecondary,
        [styles['Button--text-alert']]: theme === ButtonTheme.TextAlert,
        [styles['Button--selected']]: selected,
        [styles['Button--size-big']]: size === ButtonSize.Big,
        [styles['Button--size-small']]: size === ButtonSize.Small,
        [styles['Button--size-smallSecondary']]: size === ButtonSize.SmallSecondary,
      }),
    [fluid, theme, selected, size],
  );

  const iconSize = useMemo<IconFontSize>(
    () =>
      [ButtonTheme.Tertiary, ButtonTheme.Text].includes(theme)
        ? IconFontSize.Small
        : IconFontSize.Big,
    [theme],
  );

  const handleClick = useCallback(
    (e: SyntheticEvent) => {
      if (onClick) {
        onClick(e);
      }
    },
    [onClick],
  );

  return (
    <button
      id={isRandomId ? buttonIdRef.current : undefined}
      type={type}
      onClick={handleClick}
      disabled={disabled}
      className={btnClassNames}
      name={name}
    >
      {!!iconName && <IconFont name={iconName} size={iconSize} />}
      {children}
    </button>
  );
});
