import { FC } from 'react';

import { PlayerProfileStatsContainer } from 'containers/player-profile/player-profile-stats/player-profile-stats.container';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const PlayerProfileStatsRoute: FC = () => {
  return (
    <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
      <Column mobile={1} desktop={{ start: 1, end: 3 }}>
        <PlayerProfileStatsContainer />
      </Column>
    </Row>
  );
};
