import { memo, useMemo } from 'react';

import { StandingsMode } from 'stores/standings/enums/standings-mode.enum';
import { StandingsType } from 'stores/standings/enums/standings-type.enum';
import { IStandingsFormattedRowData } from 'stores/standings/interfaces/standings-formatted-row-data.interface';
import {
  ALWAYS_VISIBLE_COL_DEF,
  DEFAULT_TEAM_COL_DEF,
  EXPANDED_TYPE_COL_DEF,
  STANDINGS_TYPE_COL_DEF,
  TEAM_COL_DESKTOP_WIDTH,
  TEAM_COL_MOBILE_WIDTH,
  VS_DIVISION_TYPE_COL_DEF,
} from 'stores/standings/standings.config';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { RANK_COL_DEF } from '../base-table/tables.configs';

import { StandingsTable } from './standings-table/standings-table.component';

interface IStandingsProps {
  formattedData: IStandingsFormattedRowData;
  mode: StandingsMode;
  type: StandingsType;
  onTeamClick: (teamId: number) => void;
}

export const Standings = memo((props: IStandingsProps) => {
  const { formattedData, mode, type } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const colDef = useMemo(() => {
    let standingsTypeColDef = STANDINGS_TYPE_COL_DEF;

    if (type === StandingsType.Expanded) {
      standingsTypeColDef = EXPANDED_TYPE_COL_DEF;
    }

    if (type === StandingsType.VsDivision) {
      standingsTypeColDef = VS_DIVISION_TYPE_COL_DEF;
    }

    return [
      RANK_COL_DEF,
      {
        ...DEFAULT_TEAM_COL_DEF,
        cellRendererParams: {
          mode,
          onTeamClick: props.onTeamClick,
        },
        width: isDesktopPlus ? TEAM_COL_DESKTOP_WIDTH : TEAM_COL_MOBILE_WIDTH,
      },
      ...ALWAYS_VISIBLE_COL_DEF,
      ...standingsTypeColDef,
    ];
  }, [isDesktopPlus, mode, props.onTeamClick, type]);

  if (mode === StandingsMode.League) {
    return (
      <div>
        <StandingsTable
          mode={mode}
          tablesGirdOptions={[
            {
              rowData: formattedData.league,
              columnDefs: colDef,
              gridId: '1',
            },
          ]}
          type={type}
          tableHeader="National Basketball Association"
        />
      </div>
    );
  }

  if (mode === StandingsMode.Conference) {
    return (
      <div>
        <StandingsTable
          mode={mode}
          tablesGirdOptions={[
            {
              rowData: formattedData.east,
              columnDefs: colDef,
              gridId: '1',
            },
          ]}
          type={type}
          tableHeader="Eastern Conference"
        />
        <StandingsTable
          mode={mode}
          tablesGirdOptions={[
            {
              rowData: formattedData.west,
              columnDefs: colDef,
              gridId: '2',
            },
          ]}
          type={type}
          tableHeader="Western Conference"
        />
      </div>
    );
  }

  return (
    <div>
      <StandingsTable
        mode={mode}
        tablesGirdOptions={[
          {
            rowData: formattedData.atlantic,
            columnDefs: colDef,
            gridId: '1',
            alignedGrids: [],
          },
          {
            rowData: formattedData.central,
            columnDefs: colDef,
            gridId: '2',
            alignedGrids: [],
          },
          {
            rowData: formattedData.southeast,
            columnDefs: colDef,
            gridId: '3',
            alignedGrids: [],
          },
        ]}
        type={type}
        tableHeader="Eastern Conference"
      />
      <StandingsTable
        mode={mode}
        tablesGirdOptions={[
          {
            rowData: formattedData.northwest,
            columnDefs: colDef,
            gridId: '4',
            alignedGrids: [],
          },
          {
            rowData: formattedData.pacific,
            columnDefs: colDef,
            gridId: '5',
            alignedGrids: [],
          },
          {
            rowData: formattedData.southwest,
            columnDefs: colDef,
            gridId: '6',
            alignedGrids: [],
          },
        ]}
        type={type}
        tableHeader="Western Conference"
      />
    </div>
  );
});
