import { POST_ID_API_PARAM } from 'services/http/consts/api-endpoint-params.constants';
import { POSTS_NEWS_IMAGE_PREVIEW } from 'services/http/consts/api-endpoints.constants';
import { INewsResponse } from 'services/news/interfaces/news-response.interface';

import { formatPastDate } from 'helpers/format/format-past-date.util';
import { getPath } from 'helpers/get-path.util';

import { INews } from '../interfaces/news.interface';

export function newsAdapter(newsResponse: INewsResponse): INews {
  const {
    uuid,
    title,
    source_url: newsUrl,
    timestamp: publishedAt,
    source: { name: sourceName, favicon_url: sourceFaviconUrl },
  } = newsResponse;

  return {
    formattedDates: {
      timeOnly: formatPastDate(publishedAt, 'timeOnly'),
      relativeLong: formatPastDate(publishedAt, 'relativeLong'),
      relativeShort: formatPastDate(publishedAt, 'relativeShort'),
      full: formatPastDate(publishedAt),
    },
    uuid,
    title,
    newsUrl,
    previewImageUrl: getPath(POSTS_NEWS_IMAGE_PREVIEW, {
      [POST_ID_API_PARAM]: uuid,
    }),
    sourceName,
    sourceFaviconUrl,
  };
}
