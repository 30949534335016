import { FC, useMemo } from 'react';
import cn from 'classnames';

import { GameStatsSlider } from 'components/game/game-stats/game-stats-slider/game-stats-slider.component';
import { GameStatsRowItem } from 'components/game/game-stats-row/components/game-stats-row-item.component';

import styles from './game-stats-row.module.less';

interface IGameProps {
  title: string;
  homeValue: number;
  visitorsValue: number;
  isPrimary: boolean;
  homeColor: string;
  visitorsColor: string;
  secondaryHomeValue?: number;
  secondaryVisitorsValue?: number;
  homePercent?: number;
  visitorsPercent?: number;
}

export const GameStatsRow: FC<IGameProps> = (props) => {
  const {
    title,
    homeValue,
    visitorsValue,
    isPrimary,
    homeColor,
    visitorsColor,
    secondaryHomeValue,
    secondaryVisitorsValue,
    homePercent,
    visitorsPercent,
  } = props;

  const titleClassNames = useMemo(
    () =>
      cn(styles.GameStatsRow__Title, {
        [styles['GameStatsRow__Title--primary']]: isPrimary,
      }),
    [isPrimary],
  );

  return (
    <div className={styles.Wrapper}>
      <div className={styles.GameStatsRow}>
        <GameStatsRowItem
          primaryValue={visitorsValue}
          isVisitor
          isPrimary={isPrimary}
          secondaryValue={secondaryVisitorsValue}
          percent={visitorsPercent}
        />
        <div className={titleClassNames}>{title}</div>
        <GameStatsRowItem
          primaryValue={homeValue}
          isVisitor={false}
          isPrimary={isPrimary}
          secondaryValue={secondaryHomeValue}
          percent={homePercent}
        />
      </div>
      <GameStatsSlider
        homeColor={homeColor}
        homeValue={homePercent || homeValue}
        visitorsColor={visitorsColor}
        visitorsValue={visitorsPercent || visitorsValue}
      />
    </div>
  );
};
