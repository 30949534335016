import cn from 'classnames';

import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './select-team-mobile.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

interface ISelectTeamMobileProps {
  visible: boolean;
  options: ITeamOption[];
  value: Maybe<number>;
  onChange: (option: ITeamOption) => void;
  onClose: () => void;
}

export const SelectTeamMobile = (props: ISelectTeamMobileProps) => {
  const { options, value, visible } = props;

  return (
    <BaseIonBottomSheet
      visible={visible}
      safeAreaBottom={0}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      initialBreakpoint={INITIAL_BREAKPOINT}
      isAutoHeight
      onClose={props.onClose}
    >
      <div className={styles.SelectTeam}>
        <ul className={styles.SelectTeam__List}>
          {options.map((option) => (
            <li
              key={option.value}
              className={cn(
                styles.SelectTeam__Item,
                option.value === value ? styles['SelectTeam__Item--active'] : '',
              )}
              role="tab"
              tabIndex={0}
              onClick={() => props.onChange(option)}
              onKeyDown={() => props.onChange(option)}
            >
              <Avatar src={option.logo} size={AvatarSize.S} username={option.code} />
              {option.label}
            </li>
          ))}
        </ul>
      </div>
    </BaseIonBottomSheet>
  );
};
