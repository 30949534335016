import { FC } from 'react';

import { FeedActionsContainer } from 'containers/feed-actions/feed-actions.container';
import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { HeaderFeedContainer } from 'containers/posts/header-feed/header-feed.container';
import { PostsFeedContainer } from 'containers/posts/posts-feed/posts-feed-container';
import { ScrollTopContainer } from 'containers/scroll-top/scroll-top.container';
import { TeamStatisticsContainer } from 'containers/team/team-statistics/team-statistics.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useEnableRefresher } from 'hooks/use-enable-refresher';
import { useResponsive } from 'hooks/use-responsive';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const TeamFeedRoute: FC = () => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  useEnableRefresher();

  return (
    <PageWrapperContainer>
      <TeamStatisticsContainer />
      <Row layout={RowLayout.MainContent} withSpacing={false}>
        <Column mobile={1}>
          <HeaderFeedContainer />
          <PostsFeedContainer />
        </Column>
        {isDesktopPlus && (
          <Column mobile={2}>
            <FeedActionsContainer />
            <ScrollTopContainer />
          </Column>
        )}
      </Row>
    </PageWrapperContainer>
  );
};
