import { AgGridEvent, IRowNode, SortDirection } from 'ag-grid-community';

const setRankValueDescending =
  (stopIndex: Maybe<number> = null) =>
  (rowNode: IRowNode, index: number) => {
    if (stopIndex !== null && stopIndex < index) {
      rowNode.setDataValue('rank', null);
      return;
    }

    rowNode.setDataValue('rank', index + 1);
  };

const setRankValueAscending =
  (rowsCount: number, stopIndex: Maybe<number> = null) =>
  (rowNode: IRowNode, index: number) => {
    if (stopIndex !== null && rowsCount - stopIndex - 1 > index) {
      rowNode.setDataValue('rank', null);
      return;
    }

    rowNode.setDataValue('rank', rowsCount - index);
  };

export const setRankValues = (
  event: AgGridEvent,
  direction: SortDirection,
  stopIndex: Maybe<number> = null,
) => {
  if (direction === 'desc') {
    event.api.forEachNodeAfterFilterAndSort(setRankValueDescending(stopIndex));
  }

  if (direction === 'asc') {
    const rowCount = event.api.getModel().getRowCount();
    event.api.forEachNodeAfterFilterAndSort(setRankValueAscending(rowCount, stopIndex));
  }
};
