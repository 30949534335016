import { FC, memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IBarItem } from 'components/bars/bar-item.interface';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';

import styles from './bottom-bar-item.module.less';

interface IBottomBarItemProps extends Pick<IBarItem, 'name' | 'icon'> {
  isActive: boolean;
  isDisabled?: boolean;
  username?: string;
  isAuthorised: boolean;
  avatarUrl?: Maybe<string>;
  onClick: () => void;
}

export const BottomBarItem: FC<IBottomBarItemProps> = memo((props: IBottomBarItemProps) => {
  const {
    isActive,
    icon,
    name,
    username = '',
    avatarUrl,
    isAuthorised,
    isDisabled,
    onClick,
  } = props;

  const isProfile = useMemo<boolean>(
    () => icon.default === 'profile' && isAuthorised,
    [icon, isAuthorised],
  );

  const itemClassNames = useMemo(
    () =>
      cn(styles.Item, {
        [styles['Item--active']]: isActive,
        [styles['Item--avatar']]: isProfile,
      }),
    [isActive, isProfile],
  );

  const handleClick = useCallback(() => {
    if (isDisabled) {
      return;
    }

    onClick();
  }, [onClick, isDisabled]);

  return (
    <li
      role="tab"
      tabIndex={0}
      className={itemClassNames}
      onClick={handleClick}
      onKeyDown={handleClick}
    >
      {isProfile ? (
        <Avatar size={AvatarSize.XS} src={avatarUrl} username={username} />
      ) : (
        <IconButton
          disabled={isDisabled}
          iconName={isActive ? icon.active : icon.default}
          theme={IconButtonTheme.Transparent}
        />
      )}
      <span>{name}</span>
    </li>
  );
});
