import { inject } from 'inversify';
import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { StandingsService } from 'services/standings/standings.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';

import { TYPES } from 'configs/di-types.config';

import {
  STANDINGS_GLOSSARY_EXPANDED_TYPE_ENTITIES,
  STANDINGS_GLOSSARY_STANDING_TYPE_ENTITIES,
  STANDINGS_GLOSSARY_VS_DIVISION_TYPE_ENTITIES,
} from 'components/standings/standings-glossary.config';
import { IGlossaryEntity } from 'components/ui/glossary/interfaces/glossary-entity.interface';

import { SeasonsStore } from '../seasons/seasons.store';

import { standingsAdapter } from './adapters/standings-adapter.util';
import { StandingsConference } from './enums/standings-conference.enum';
import { StandingsDivision } from './enums/standings-division.enum';
import { StandingsMode } from './enums/standings-mode.enum';
import { StandingsType } from './enums/standings-type.enum';
import { IStandingsFormattedRowData } from './interfaces/standings-formatted-row-data.interface';
import { IStandingsRowData } from './interfaces/standings-row-data.interface';

export class StandingsStore extends ApiConnectedStore {
  private readonly standingsService: StandingsService;

  private readonly seasonsStore: SeasonsStore;

  public mode: StandingsMode;

  public type: StandingsType;

  public seasonId: Maybe<string>;

  public entries: IStandingsRowData[];

  constructor(
    @inject<StandingsService>(TYPES.StandingsService)
    standingsService: StandingsService,
    @inject<SeasonsStore>(TYPES.SeasonsStore)
    seasonsStore: SeasonsStore,
  ) {
    super();

    this.standingsService = standingsService;

    this.seasonsStore = seasonsStore;

    this.mode = StandingsMode.Conference;

    this.type = StandingsType.Standings;

    this.seasonId = null;

    this.entries = [];

    makeObservable(this, {
      mode: observable,
      type: observable,
      seasonId: observable,
      entries: observable,

      formattedRowData: computed,
      glossaryEntities: computed,

      setMode: action.bound,
      setType: action.bound,
      setSeasonId: action.bound,
      setEntries: action.bound,
    });

    reaction(() => this.seasonId, this.handleFilterChange);
    reaction(() => this.mode, this.handleFilterChange);
    autorun(() => this.setSeasonId(this.seasonsStore.currentSeasonOption?.value || null));
  }

  private handleFilterChange = async () => {
    await this.retrieve();
  };

  public setMode(mode: StandingsMode) {
    this.mode = mode;
  }

  public setType(type: StandingsType) {
    this.type = type;
  }

  public setSeasonId(seasonId: Maybe<string>) {
    this.seasonId = seasonId;
  }

  public setEntries(entities: IStandingsRowData[]) {
    this.entries = entities;
  }

  public get formattedRowData(): IStandingsFormattedRowData {
    const divisions: { [key in StandingsDivision]: IStandingsRowData[] } = {
      [StandingsDivision.Atlantic]: [],
      [StandingsDivision.Central]: [],
      [StandingsDivision.Northwest]: [],
      [StandingsDivision.Pacific]: [],
      [StandingsDivision.Southeast]: [],
      [StandingsDivision.Southwest]: [],
    };

    const conferences: { [key in StandingsConference]: IStandingsRowData[] } = {
      [StandingsConference.East]: [],
      [StandingsConference.West]: [],
    };

    this.entries.forEach((entry) => {
      const { conference, division } = entry.team;
      conferences[conference].push(entry);
      divisions[division].push(entry);
    });

    return {
      league: this.entries,
      east: conferences[StandingsConference.East],
      west: conferences[StandingsConference.West],
      atlantic: divisions[StandingsDivision.Atlantic],
      central: divisions[StandingsDivision.Central],
      northwest: divisions[StandingsDivision.Northwest],
      pacific: divisions[StandingsDivision.Pacific],
      southeast: divisions[StandingsDivision.Southeast],
      southwest: divisions[StandingsDivision.Southwest],
    };
  }

  public get glossaryEntities(): IGlossaryEntity[] {
    if (this.type === StandingsType.Standings) {
      return STANDINGS_GLOSSARY_STANDING_TYPE_ENTITIES;
    }

    if (this.type === StandingsType.Expanded) {
      return STANDINGS_GLOSSARY_EXPANDED_TYPE_ENTITIES;
    }

    return STANDINGS_GLOSSARY_VS_DIVISION_TYPE_ENTITIES;
  }

  public async retrieve() {
    this.resetErrors();
    this.setFetched(false);

    if (this.seasonId) {
      const standingsResponse = await this.standingsService.retrieve(this.seasonId, this.mode);

      if (standingsResponse.success) {
        this.setEntries(standingsResponse.data.map(standingsAdapter));
      } else {
        this.setErrors(standingsResponse.errors);
      }
    } else {
      this.setSeasonId(this.seasonsStore.currentSeasonOption?.value || null);
    }

    this.setFetched(true);
  }
}
