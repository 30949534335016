import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IGameContent } from 'stores/content-cards/interfaces/content-cards.interface';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import mentionIcon from 'assets/images/svg/mention.svg';

import { PostsTheme } from '../post-card/enums/posts-theme.enum';

import { PlayerBoard } from './player-board/player-board.component';
import { Scoreboard } from './scoreboard/scoreboard.component';

import styles from './game-content.module.less';

export interface IGameContentProps {
  isPosted?: boolean;
  theme?: PostsTheme;
  contentData: Omit<IGameContent, 'type'>;
  onClick?: (gameId: string, teamsName: string, gameDate: string) => void;
}

export const GameContent = memo((props: IGameContentProps) => {
  const { isPosted = false, theme = PostsTheme.Regular, contentData, onClick } = props;

  const {
    gameId,
    gameDate,
    gameStatus,
    isFinishedGame,
    homeTeamCode,
    homeTeamLogoUrl,
    homeTeamScore,
    homeTeamPlayers,
    visitorsTeamCode,
    visitorsTeamLogoUrl,
    visitorsTeamScore,
    visitorsTeamPlayers,
  } = contentData;

  const gameContentClasses = useMemo(
    () =>
      cn(styles.GameContent, {
        [styles['GameContent--is-posted']]: isPosted,
      }),
    [isPosted],
  );

  const contentSubtitle = useMemo(() => {
    if (isPosted) {
      return 'Top Scoring Game - Starting lineup';
    }

    return 'Starting lineup';
  }, [isPosted]);

  const handleGameContentClick = useCallback(() => {
    onClick?.(String(gameId), `${visitorsTeamCode} vs ${homeTeamCode}`, gameDate);
  }, [gameId, visitorsTeamCode, homeTeamCode, gameDate, onClick]);

  return (
    <div role="presentation" className={gameContentClasses} onClick={handleGameContentClick}>
      <div className={styles.GameContent__Header}>
        <div className={styles.GameContentTeam}>
          <div className={styles.GameContentTeam__Avatar}>
            <Avatar src={visitorsTeamLogoUrl} username={visitorsTeamCode} size={AvatarSize.L} />
          </div>
          <h4 className={styles.GameContentTeam__Name}>{visitorsTeamCode}</h4>
        </div>
        <Scoreboard
          isFinishedGame={isFinishedGame}
          gameStatus={gameStatus}
          homeTeamScore={homeTeamScore}
          visitorsTeamScore={visitorsTeamScore}
        />
        <div className={styles.GameContentTeam}>
          <h4 className={styles.GameContentTeam__Name}>{homeTeamCode}</h4>
          <div className={styles.GameContentTeam__Avatar}>
            <Avatar src={homeTeamLogoUrl} username={homeTeamCode} size={AvatarSize.L} />
            <div className={styles.GameContentTeam__Icon}>
              <img src={mentionIcon} alt={homeTeamCode} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.GameContent__Row}>
        <span className={styles.GameContent__Text}>{contentSubtitle}</span>
        <span className={styles.GameContent__Date}>{gameDate}</span>
        {!isPosted && <span className={styles.GameContent__SeeMore}>See More</span>}
      </div>
      <div className={styles.GameContent__Content}>
        <div className={styles.GameContent__Items}>
          {visitorsTeamPlayers.map((playerData) => (
            <PlayerBoard
              key={playerData.playerId}
              isPosted={isPosted}
              theme={theme}
              playerData={playerData}
            />
          ))}
        </div>
        <div className={styles.GameContent__Items}>
          {homeTeamPlayers.map((playerData) => (
            <PlayerBoard
              key={playerData.playerId}
              isPosted={isPosted}
              theme={theme}
              playerData={playerData}
            />
          ))}
        </div>
      </div>
    </div>
  );
});
