import { inject, injectable } from 'inversify';
import { action, autorun, makeObservable, observable, reaction } from 'mobx';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { ISplitsInGameResponse } from 'services/player/interfaces/player-splits.interface';
import { PlayerService } from 'services/player/player.service';

import { PlayerStore } from 'stores/player/player.store';
import { singleSplitAdapter } from 'stores/splits-games/adapters/single-split-adapter.util';
import { splitsSingleMonthsAdapter } from 'stores/splits-games/adapters/splits-months-adapter.util';
import { ISplitsInGameAdapter } from 'stores/splits-games/interfaces/splits-in-game-adapter.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';

import {
  ISingleSplitItem,
  ISplitForOneMonth,
} from 'components/player-profile/player-profile-games/player-profile-games.interface';

import { EntriesStore } from '../entries/entries.store';

const SPLITS_IN_GAME_LIMIT = 3;

@injectable()
export class SplitsGamesStore extends EntriesStore<ISingleSplitItem, ISplitsInGameResponse> {
  private readonly playerService: PlayerService;

  private readonly playerStore: PlayerStore;

  private readonly teamsStatsStore: TeamsStatsStore;

  public splitsMonths: ISplitForOneMonth[];

  public selectedTeamId: Maybe<number>;

  public splitSize: Maybe<number>;

  public isFetchingSplits: boolean;

  constructor(
    @inject(TYPES.PlayerService) playerService: PlayerService,
    @inject(TYPES.TeamsStatsStore) teamsStatsStore: TeamsStatsStore,
    @inject(TYPES.PlayerStore) playerStore: PlayerStore,
  ) {
    super(SPLITS_IN_GAME_LIMIT, false);

    this.playerService = playerService;

    this.teamsStatsStore = teamsStatsStore;

    this.playerStore = playerStore;

    this.splitsMonths = [];

    this.isFetchingSplits = false;

    this.selectedTeamId = null;

    this.splitSize = null;

    makeObservable(this, {
      splitSize: observable,
      splitsMonths: observable,
      selectedTeamId: observable,
      isFetchingSplits: observable,

      setIsFetchingSplits: action.bound,
      setSplitsMonths: action.bound,
      setSplitSize: action.bound,
      setSelectedTeamId: action.bound,
      fetchSplitsMonths: action.bound,
    });

    reaction(
      () => [
        this.playerStore.playerSlug,
        this.selectedTeamId,
        this.splitSize,
        this.playerStore.seasonOption?.value,
      ],
      this.fetchSplitsMonths,
    );

    reaction(() => this.playerStore.playerSlug, this.handlePlayerChange);

    reaction(
      () => [this.selectedTeamId, this.playerStore.playerDetails],
      this.handleTeamChangedChange,
    );

    reaction(() => this.playerStore.seasonOption?.value, this.handleSeasonChange);

    autorun(() => this.prepareRecentSplits());
  }

  private handlePlayerChange = async () => {
    await this.playerStore.retrievePlayerSplits();
    await this.refresh();
  };

  private handleTeamChangedChange = async () => {
    const playerName = `${this.playerStore.playerDetails?.firstname} ${this.playerStore.playerDetails?.lastname}`;
    const teamName = this.teamsStatsStore.findTeamById(this.selectedTeamId);

    if (teamName) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PlayerProfileGamesFilterTeam,
          player: playerName,
          ...(teamName?.code && { team_filtered: teamName.code }),
        },
      });
    }
  };

  private handleSeasonChange = async () => {
    await this.playerStore.retrievePlayerSplits();
    await this.refresh();
  };

  private prepareRecentSplits = async () => {
    await this.initialise();
  };

  public async fetchNext(): Promise<void> {
    if (this.playerStore.playerSlug) {
      await this.retrieveNext(
        this.playerService.fetchSplitsInGame(
          this.playerStore.playerSlug,
          this.playerStore.seasonOption?.value || null,
          this.pagination,
        ),
        <ISplitsInGameAdapter>singleSplitAdapter,
      );
    }
  }

  public async fetchSplitsMonths(): Promise<void> {
    if (this.playerStore.playerSlug) {
      this.setIsFetchingSplits(true);

      const response = await this.playerService.fetchSplitsMonths(
        this.playerStore.playerSlug,
        this.playerStore.seasonOption?.value || null,
        this.selectedTeamId,
        this.splitSize,
      );

      if (response.success) {
        const data = response.data.map(splitsSingleMonthsAdapter);

        this.setSplitsMonths(data);
      } else {
        this.setErrors(response.errors);
      }

      this.setIsFetchingSplits(false);
    }
  }

  public setSplitsMonths(splits: ISplitForOneMonth[]) {
    this.splitsMonths = splits;
  }

  public setSelectedTeamId(teamId: Maybe<number>) {
    this.selectedTeamId = teamId;
  }

  public setSplitSize(limit: Maybe<number>) {
    this.splitSize = limit;
  }

  public setIsFetchingSplits(value: boolean) {
    this.isFetchingSplits = value;
  }

  async initialise() {
    await super.initialise();

    if (this.fetched) {
      return;
    }

    await this.fetchNext();
  }
}
