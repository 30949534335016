import { IFollow, ISearchableFollow } from 'stores/follow/interfaces/follow.interface';

export const filterFollowings = (items: IFollow[], searchString: string): IFollow[] => {
  if (!searchString) {
    return items;
  }

  return items.filter((obj) => {
    const localObj: ISearchableFollow = {
      label: obj.label,
      firstName: obj.firstName,
      lastName: obj.lastName,
      city: obj.city,
    };
    return ['label', 'firstName', 'lastName', 'city'].some((key) =>
      localObj[key].toLowerCase().includes(searchString.toLowerCase()),
    );
  });
};
