const DEFAULT_DEBOUNCE_DELAY = 100;

export const debounce = (
  callback: (...args: any[]) => void,
  delay = DEFAULT_DEBOUNCE_DELAY,
): (() => void) => {
  let timeout: Maybe<number>;

  return () => {
    if (timeout) {
      window.clearTimeout(timeout);
    }
    timeout = window.setTimeout(() => {
      timeout = null;
      callback();
    }, delay);
  };
};
