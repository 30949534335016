import { inject, injectable } from 'inversify';

import {
  GAME_ID_API_PARAM,
  TEAM_ID_API_PARAM,
} from 'services/http/consts/api-endpoint-params.constants';
import {
  GAMES_FOR_ARENA,
  GAMES_STATISTICS_MINI,
  TEAM_GAME_STATISTICS_MINI,
} from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { TYPES } from 'configs/di-types.config';

import { IGameMiniResponse } from './interface/game.interface';

@injectable()
export class StatisticsService {
  private readonly httpService: HttpService;

  constructor(@inject<HttpService>(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public retrieve(timezone: string): Promise<IResponse<IGameMiniResponse[]>> {
    return this.httpService.request<IGameMiniResponse[]>({
      method: 'GET',
      url: GAMES_STATISTICS_MINI,
      params: new URLSearchParams({ timezone }),
    });
  }

  public retrieveByGameId(gameId: number): Promise<IResponse<IGameMiniResponse[]>> {
    return this.httpService.request<IGameMiniResponse[]>({
      method: 'GET',
      url: GAMES_FOR_ARENA,
      routeParams: { [GAME_ID_API_PARAM]: String(gameId) },
    });
  }

  public retrieveForTeam(
    teamId: number,
    timezone: string,
  ): Promise<IResponse<IGameMiniResponse[]>> {
    return this.httpService.request<IGameMiniResponse[]>({
      method: 'GET',
      url: TEAM_GAME_STATISTICS_MINI,
      params: new URLSearchParams({ timezone }),
      routeParams: { [TEAM_ID_API_PARAM]: teamId.toString() },
    });
  }
}
