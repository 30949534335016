import { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IFormField } from 'components/ui/form-fields/form-field.interface';
import { HelperText, HelperTextTheme } from 'components/ui/helper-text/helper-text.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFont, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Label } from 'components/ui/label/label.component';

import styles from './field-wrapper.module.less';

interface IFieldRenderParams {
  isInvalid: boolean;
  withLeftIcon: boolean;
  withRightIcon: boolean;
  withTextCount: boolean;
  withMaxLengthCount: boolean;
}

interface IFieldWrapperProps
  extends Pick<
    IFormField,
    | 'error'
    | 'helperTextContent'
    | 'helperTextTheme'
    | 'id'
    | 'label'
    | 'leftIconName'
    | 'textCount'
    | 'maxLengthCount'
    | 'rightIconProps'
  > {
  aside?: ReactNode;
  children: (params: IFieldRenderParams) => ReactNode;
}

export const FieldWrapper: FC<IFieldWrapperProps> = memo((props: IFieldWrapperProps) => {
  const {
    error,
    helperTextContent,
    helperTextTheme,
    id,
    label,
    leftIconName,
    rightIconProps,
    textCount,
    maxLengthCount,
    aside,
    children,
  } = props;

  const isInvalid = useMemo<boolean>(() => !!error, [error]);

  const fieldClassNames = useMemo<string>(
    () =>
      cn(styles.FieldWrapper__Field, {
        [styles['FieldWrapper__Field--invalid']]: isInvalid,
      }),
    [isInvalid],
  );

  const fieldRightIconClassNames = useMemo(
    () =>
      cn(styles.FieldWrapper__Field__RightIcon, {
        [styles['FieldWrapper__Field__RightIcon--size-big']]: !rightIconProps?.size,
        [styles['FieldWrapper__Field__RightIcon--size-extra-small']]:
          rightIconProps?.size === IconFontSize.ExtraSmall,
      }),
    [rightIconProps?.size],
  );

  const handleRightIconClick = useCallback(() => {
    rightIconProps?.onClick?.();
  }, [rightIconProps]);

  const helperText = useMemo<ReactNode>(() => {
    if (error) {
      return <HelperText theme={HelperTextTheme.Error}>{error}</HelperText>;
    }

    if (helperTextContent) {
      return <HelperText theme={helperTextTheme}>{helperTextContent}</HelperText>;
    }

    return null;
  }, [error, helperTextContent, helperTextTheme]);

  const textCounter = useMemo<ReactNode>(() => {
    if (textCount) {
      return (
        <div>
          {textCount.count}/{textCount.maxCount}
        </div>
      );
    }

    return null;
  }, [textCount]);

  const rightIcon = useMemo<ReactNode>(() => {
    if (rightIconProps?.name) {
      return (
        <IconButton
          iconName={rightIconProps.name}
          theme={IconButtonTheme.Transparent}
          onClick={handleRightIconClick}
          iconSize={rightIconProps.size}
        />
      );
    }

    return null;
  }, [handleRightIconClick, rightIconProps]);

  return (
    <div className={styles.FieldWrapper}>
      {label && (
        <div className={styles.FieldWrapper__Label}>
          <Label htmlFor={id}>{label}</Label>
        </div>
      )}
      <div className={styles.FieldWrapper__ComplexField}>
        <div className={fieldClassNames}>
          {leftIconName && (
            <div className={styles.FieldWrapper__Field__LeftIcon}>
              <IconFont name={leftIconName} size={IconFontSize.Big} />
            </div>
          )}
          {rightIcon && <div className={fieldRightIconClassNames}>{rightIcon}</div>}
          {textCounter && !rightIcon && (
            <div className={styles.FieldWrapper__Field__TextCounter}>{textCounter}</div>
          )}
          {children({
            isInvalid,
            withLeftIcon: !!leftIconName,
            withRightIcon: !!rightIcon,
            withTextCount: !!textCount && !rightIcon,
            withMaxLengthCount: !!maxLengthCount,
          })}
        </div>
        {aside && <div className={styles.FieldWrapper__ComplexField__Aside}>{aside}</div>}
        {helperText && <div className={styles.FieldWrapper__HelperText}>{helperText}</div>}
      </div>
    </div>
  );
});
