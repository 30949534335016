import { useMemo } from 'react';
import cn from 'classnames';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFont, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { IWelcomeListItem, IWelcomeProps } from 'components/welcome-popup/welcome-popup.interface';

import styles from './welcome-content.module.less';

export const WelcomeContent = (props: IWelcomeProps) => {
  const introWindowHeaderClasses = useMemo<string>(
    () =>
      cn(styles.WelcomeContent__Header, {
        [styles['WelcomeContent__Header--with-background']]: !!props.background,
      }),
    [props.background],
  );

  const welcomeContentHeaderStyles = useMemo(
    () => (props.background ? { backgroundImage: `url(${props.background})` } : {}),
    [props.background],
  );

  return (
    <div className={styles.WelcomeContent}>
      <div className={introWindowHeaderClasses} style={welcomeContentHeaderStyles}>
        <img
          className={styles.WelcomeContent__Thumbnail}
          src={props.thumbnail}
          alt="Welcome thumbnail"
        />
      </div>
      <div className={styles.WelcomeContent__Main}>
        <h3 className={styles.WelcomeContent__Title}>{props.title}</h3>
        <p className={styles.WelcomeContent__Text}>{props.description}</p>
        <ul className={styles.WelcomeContent__List}>
          {props.listItems.map((item: IWelcomeListItem) => (
            <li className={styles.WelcomeContent__Item} key={item.id}>
              <div className={styles.WelcomeContent__Icon}>
                <IconFont dataId={item.iconId} name={item.iconId} size={IconFontSize.Big} />
              </div>
              <div className={styles.WelcomeContent__Text}>
                <div className={styles.WelcomeContent__ItemTitle}>{item.title}</div>
                {item.text}
              </div>
            </li>
          ))}
        </ul>
        <Button fluid theme={ButtonTheme.Primary} size={ButtonSize.Big} onClick={props.onAccept}>
          {props.accepButtonName}
        </Button>
      </div>
    </div>
  );
};
