import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useInterval } from 'hooks/use-interval';
import { IconButton, IconButtonTheme } from '../icon-button/icon-button.component';
import { IconFontName } from '../icon-font/icon-font.component';

import styles from './countdown-timer.module.less';

interface ICountdownTimerState {
  minutes: number;
  seconds: number;
}

export interface ICountdownTimerProps {
  onExpiration: () => void;
  onSetCountdownTime: (time: Maybe<ICountdownTimerState>) => void;
  countdownEndTimestamp: number;
}

export const CountdownTimer = memo((props: ICountdownTimerProps) => {
  const { onExpiration, onSetCountdownTime, countdownEndTimestamp } = props;

  const countdownTimeRef = useRef<Maybe<ICountdownTimerState>>(null);

  const [isCountdownFinished, setIsCountdownFinished] = useState<boolean>(false);

  const [timeLeft, setTimeLeft] = useState<ICountdownTimerState>({
    minutes: Math.floor(Math.floor(countdownEndTimestamp / 1000) / 60),
    seconds: Math.floor(countdownEndTimestamp / 1000) % 60,
  });

  const time = useMemo(() => {
    return new Date(Date.now() + countdownEndTimestamp).getTime();
  }, [countdownEndTimestamp]);

  useInterval(
    () => {
      const currentTime = new Date().getTime();
      const timeDifference = Math.max(0, time - currentTime);
      const timeDifferenceInSeconds = Math.ceil(timeDifference / 1000);

      const minutes = Math.floor(timeDifferenceInSeconds / 60);
      const seconds = timeDifferenceInSeconds % 60;

      setTimeLeft({
        minutes,
        seconds,
      });

      countdownTimeRef.current = {
        minutes,
        seconds,
      };

      if (timeDifference === 0) {
        countdownTimeRef.current = null;
        setIsCountdownFinished(true);
        onSetCountdownTime(null);
      }
    },
    isCountdownFinished ? null : 1000,
  );

  useEffect(() => {
    if (isCountdownFinished) {
      onExpiration();
    }
  }, [isCountdownFinished, onExpiration]);

  useEffect(() => {
    return () => {
      const currentTime = countdownTimeRef.current;

      if (currentTime) {
        onSetCountdownTime({
          minutes: currentTime.minutes,
          seconds: currentTime.seconds,
        });
      }
    };
  }, [onSetCountdownTime]);

  const formattedTime = useMemo(
    () =>
      `${String(timeLeft?.minutes).padStart(2, '0')}:${String(timeLeft?.seconds).padStart(2, '0')}`,
    [timeLeft],
  );

  return (
    <div className={styles.CountdownTimer}>
      <IconButton iconName={IconFontName.Timer} theme={IconButtonTheme.Transparent} />
      {formattedTime}
    </div>
  );
});
