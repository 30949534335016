import { useCallback, useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ContainerNamesEnum } from 'services/google-analytic/enums/container-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { PermissionPopups } from 'services/local-database/config';

import { AuthStore } from 'stores/auth/auth.store';
import { GameStore } from 'stores/game/game.store';
import { LayoutStore } from 'stores/layout/layout.store';

import { ARENA_GAME_ITEM_FORMAT } from 'configs/date.config';
import { TYPES } from 'configs/di-types.config';

import { WelcomePopup } from 'components/welcome-popup/welcome-popup.component';

import arenaThumbnail from 'assets/images/arena-thumbnail.webp';

import { WELCOME_POPUP_ARENA_LIST } from './welcome-popup.config';

export const WelcomePopupArenaContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const gameStore = useInjection<GameStore>(TYPES.GameStore);

  const isWelcomePopupShown = useMemo(() => {
    if (!layoutStore.isFirstTimeIntroShown) {
      return true;
    }
    return authStore.isAuthorised
      ? authStore.userMe?.profile.arenaFirstVisited
      : layoutStore.isWelcomeArenaShown;
  }, [
    authStore.isAuthorised,
    authStore.userMe?.profile.arenaFirstVisited,
    layoutStore.isWelcomeArenaShown,
    layoutStore.isFirstTimeIntroShown,
  ]);

  const handleWelcomePopupAccept = useCallback(async () => {
    if (authStore.isAuthorised) {
      await authStore.updateUserMe({ arenaFirstVisited: true });
    }

    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.IntroPromptArenaEnter,
      },
    });
    layoutStore.setWelcomePopupShown(PermissionPopups.WELCOME_ARENA_SHOWN);
  }, [authStore, layoutStore]);

  const handleWelcomePopupClose = useCallback(async () => {
    if (authStore.isAuthorised) {
      await authStore.updateUserMe({ arenaFirstVisited: true });
    }
    layoutStore.setWelcomePopupShown(PermissionPopups.WELCOME_ARENA_SHOWN);
  }, [authStore, layoutStore]);

  const gameDetails = useMemo(() => {
    if (gameStore.entry) {
      return {
        game: `${gameStore.entry?.home.teamInfo.code}vs${gameStore.entry?.visitors.teamInfo.code}`,
        date: format(new Date(gameStore.entry?.gameDateStart), ARENA_GAME_ITEM_FORMAT),
        firstGame: gameStore.isFirstGameInSameDay.toString(),
      };
    }

    return null;
  }, [gameStore?.entry, gameStore.isFirstGameInSameDay]);

  useEffect(() => {
    if (!isWelcomePopupShown && gameDetails) {
      GoogleAnalyticService.event({
        eventName: 'container_custom_view',
        eventParams: {
          container_type: ContainerNamesEnum.IntroPromptArena,
          game: gameDetails.game,
          date: gameDetails.date,
          first_game: gameDetails.firstGame,
        },
      });
    }
  }, [gameDetails, gameStore.entry, isWelcomePopupShown]);

  useEffect(() => {
    layoutStore.setAnyWelcomePopupOpened(!isWelcomePopupShown);
    return () => layoutStore.setAnyWelcomePopupOpened(false);
  }, [isWelcomePopupShown, layoutStore]);

  useEffect(() => {
    if (authStore.isAuthorised && authStore.userMe?.profile.mainFeedFirstVisited) {
      layoutStore.setWelcomePopupShown(PermissionPopups.WELCOME_ARENA_SHOWN);
    }
  }, [authStore.isAuthorised, authStore.userMe?.profile.mainFeedFirstVisited, layoutStore]);

  if (!isWelcomePopupShown) {
    return (
      <WelcomePopup
        onAccept={handleWelcomePopupAccept}
        onClose={handleWelcomePopupClose}
        thumbnail={arenaThumbnail}
        title="Experience the Digital Arena"
        description="The destination for fans to keep up with games, chat and interact with live plays."
        accepButtonName="Enter Arena"
        listItems={WELCOME_POPUP_ARENA_LIST}
      />
    );
  }

  return null;
});
