import { FC, PropsWithChildren, ReactNode, SyntheticEvent } from 'react';

interface IFormProps {
  children: ReactNode;
  onSubmit?: (event: SyntheticEvent) => void;
  className?: string;
}

export const Form: FC<IFormProps> = (props: PropsWithChildren<IFormProps>) => {
  const { children, className } = props;

  return (
    <form className={className} noValidate onSubmit={props.onSubmit}>
      {children}
    </form>
  );
};
