import { inject, injectable } from 'inversify';
import { comparer, reaction } from 'mobx';

import { PlayerService } from 'services/player/player.service';
import { IPostResponse } from 'services/posts/interfaces/posts-response.interface';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { PlayerStore } from 'stores/player/player.store';
import { publicationAdapter } from 'stores/posts/adapters/publication-adapter.util';
import { IPost, IPostAdapter } from 'stores/posts/interfaces/post.interface';
import { IPostsResponse } from 'stores/posts/interfaces/post-response.inerface';

import { TYPES } from 'configs/di-types.config';

import { PAGINATION_LIMIT } from './feeds.config';

@injectable()
export class PlayerFeedStore extends AdvancedEntriesStore<IPost, IPostResponse, IPostsResponse> {
  private readonly playerService: PlayerService;

  private readonly playerStore: PlayerStore;

  private readonly feedFiltersStore: FeedFiltersStore;

  private readonly layoutStore: LayoutStore;

  constructor(
    @inject(TYPES.PlayerService) playerService: PlayerService,
    @inject(TYPES.PlayerStore) playerStore: PlayerStore,
    @inject(TYPES.FeedFiltersStore) feedFiltersStore: FeedFiltersStore,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
  ) {
    super(PAGINATION_LIMIT);

    this.playerService = playerService;

    this.playerStore = playerStore;

    this.feedFiltersStore = feedFiltersStore;

    this.layoutStore = layoutStore;

    reaction(
      () => JSON.stringify(this.feedFiltersStore.activeFeed),
      () => this.resetToFetch(),
      {
        equals: comparer.shallow,
      },
    );

    reaction(
      () => JSON.stringify(this.playerStore.playerDetails),
      () => this.forceFetchToRefresh(),
      {
        equals: comparer.shallow,
      },
    );
  }

  private resetToFetch() {
    this.reset();
    this.forceFetchToRefresh();
  }

  public async fetchNext() {
    if (this.playerStore.playerDetails && this.feedFiltersStore.activeFeed === FeedTypes.Player) {
      await this.retrieveNext(
        this.fetchPlayerFeedPosts(this.playerStore.playerDetails.id),
        <IPostAdapter>publicationAdapter,
      );
    }
  }

  public async fetchPlayerFeedPosts(playerId: number): Promise<IResponse<IPostsResponse>> {
    return this.playerService.fetchPlayerPosts(this.pagination, playerId.toString());
  }

  public async forceFetchToRefresh() {
    if (this.playerStore.playerDetails && this.feedFiltersStore.activeFeed === FeedTypes.Player) {
      await this.refresh();

      if (this.playerStore.playerDetails) {
        await this.forceRefresh(
          this.fetchPlayerFeedPosts(this.playerStore.playerDetails.id),
          <IPostAdapter>publicationAdapter,
          this.layoutStore.setPulledRefresher,
        );
      }
    }
  }

  public async refresh() {
    this.setFetched(false);
    this.setFetching(false);

    this.setIsLastPage(false);
    this.setCurrentPage(1);

    await this.initialise();
  }
}
