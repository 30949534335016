import { useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';

import styles from './video.module.less';

export interface ISlideDotProps {
  url: string;
  type: string;
  isAutoplay?: boolean;
  isMuted?: boolean;
  size: VideoSize;
}

export enum VideoSize {
  S,
  M,
  L,
  FULL,
}
export const Video = (props: ISlideDotProps) => {
  const { size, url, type, isMuted = true, isAutoplay = false } = props;

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const options = {
      rootMargin: '0px',
      threshold: [0.25, 0.75],
    };

    const handlePlay = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (!videoRef?.current) return;

        if (entry.isIntersecting && videoRef.current.readyState === 4) {
          videoRef.current.play();
        } else {
          if (videoRef.current.paused) return;

          videoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handlePlay, options);

    if (videoRef?.current) {
      observer.observe(videoRef?.current);
    }
  }, []);

  const videoClassNames = useMemo(
    () =>
      cn(styles.Video, {
        [styles['Video--small']]: size === VideoSize.S,
        [styles['Video--medium']]: size === VideoSize.M,
        [styles['Video--large']]: size === VideoSize.L,
        [styles['Video--full']]: size === VideoSize.FULL,
      }),
    [size],
  );

  return (
    <video
      // because of need using unknown-property
      /* eslint-disable-next-line react/no-unknown-property */
      webkit-playsinline={1}
      playsInline
      ref={videoRef}
      muted={isMuted}
      autoPlay={isAutoplay}
      controls
      preload="metadata"
      className={videoClassNames}
      src={`${url}#t=0.01`}
      loop
    >
      <source type={type} />
      <track src={url} kind="captions" />
    </video>
  );
};
