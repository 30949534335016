import { inject } from 'inversify';
import { action, makeObservable, observable } from 'mobx';

import { SettingsService } from 'services/settings/settings.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';

import { TYPES } from 'configs/di-types.config';

import { SettingsScreenType } from './enums/settings-screen-type.enum';
import { IPushNotificationSettings } from './interfaces/push-notification-settings.interface';
import { PushNotificationPayloadType } from './types/push-notification-payload.type';
import { hasMissedNotifTags } from './utils/has-missed-notif-tags.util';

export class SettingsStore extends ApiConnectedStore {
  private readonly settingsService: SettingsService;

  public pushNotificationSettings: IPushNotificationSettings;

  public screenType: SettingsScreenType;

  constructor(@inject<SettingsService>(TYPES.SettingsService) settingsService: SettingsService) {
    super();

    this.settingsService = settingsService;

    this.pushNotificationSettings = null!;

    this.screenType = SettingsScreenType.Settings;

    makeObservable(this, {
      pushNotificationSettings: observable,
      screenType: observable,

      setPushNotificationSettings: action.bound,
      setScreenType: action.bound,
    });
  }

  public async retriewePushNotificationSettings() {
    this.resetErrors();

    const response = await this.settingsService.fetchPushNotificationSettings();

    if (!response.success) {
      this.setErrors(response.errors);

      return;
    }

    if (!response.data) {
      this.sendToCreatePushNotificationSettings();

      return;
    }

    if (hasMissedNotifTags(response.data)) {
      this.sendToCreatePushNotificationSettings();

      return;
    }

    this.setPushNotificationSettings(response.data);
  }

  public async sendPushNotificationSettings(key: string, value: boolean) {
    this.resetErrors();
    this.setFetching(true);

    const payload: PushNotificationPayloadType = {};

    payload[key] = value;

    const response = await this.settingsService.sendPushNotificationSettings(payload);

    if (response.success) {
      this.setPushNotificationSettings(response.data);
    } else {
      this.setErrors(response.errors);
    }

    this.setFetching(false);
  }

  public async sendToCreatePushNotificationSettings() {
    this.resetErrors();

    const response = await this.settingsService.sendToCreatePushNotificationSettings();

    if (response.success) {
      this.setPushNotificationSettings(response.data);
    } else {
      this.setErrors(response.errors);
    }
  }

  public setPushNotificationSettings(pushNotificationSettings: IPushNotificationSettings) {
    this.pushNotificationSettings = pushNotificationSettings;
  }

  public setScreenType(screenType: SettingsScreenType) {
    this.screenType = screenType;
  }
}
