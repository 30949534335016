import { injectable } from 'inversify';

import { BaseEntriesStore } from 'stores/base-entries/base-entries.store';

@injectable()
export abstract class EntriesStore<Y, F> extends BaseEntriesStore<Y, F[]> {
  public async refresh(): Promise<void> {
    this.reset();

    await this.initialise();
  }

  public async retrieveNext(
    res: Promise<IResponse<F[]>>,
    adapter: <T, U>(res: T) => U,
  ): Promise<void> {
    if (this.isLastPage || this.fetching) {
      return;
    }

    const currentInstanceId = this.instanceId;

    this.setFetching(true);

    const response: IResponse<F[]> = await res;

    if (currentInstanceId !== this.instanceId) {
      return;
    }

    if (response.success) {
      if (!adapter) {
        return;
      }

      const { data: entries } = response;

      if (entries.length >= this.paginationLimit) {
        this.setCurrentPage(this.currentPage + 1);
      } else {
        this.setIsLastPage(true);
      }

      this.setEntries([...this.entries, ...(<Array<Y>>entries.map(adapter).filter(Boolean))]);
    } else {
      this.setErrors(response.errors);
    }

    this.setFetching(false);
    this.setFetched(true);
  }
}
