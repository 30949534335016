import { ListItemNode } from '@lexical/list';
import { $createParagraphNode, $createTextNode } from 'lexical';

export const replaceListItemNode = (node: ListItemNode, selectionFormat: number) => {
  const paragraphNode = $createParagraphNode();

  const text = $createTextNode(node.getTextContent());

  text.setFormat(selectionFormat);

  paragraphNode.append(text);

  node.replace(paragraphNode);

  paragraphNode.select();
};
