import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import cn from 'classnames';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import { PreviewConfig } from 'emoji-picker-react/dist/config/config';
import { $createTextNode, $getSelection } from 'lexical';

import {
  ConvertImageItemToAttachmentType,
  IUploadImageData,
} from 'services/application/interfaces/upload-image.interface';

import { FILES_IMAGE_TYPES, FILES_VIDEO_TYPES } from 'validation/validation.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { EditorAttachmentsType } from 'components/editor/components/base-editor/base-editor.component';
import { GifPicker } from 'components/modals/gif-picker/gif-picker.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IContextMenuGroup } from 'components/ui/context-menu/interfaces/context-menu-group.interface';
import { IContextMenuItem } from 'components/ui/context-menu/interfaces/context-menu-item.interface';
import { ContextMenuTooltip } from 'components/ui/context-menu-tooltip/context-menu-tooltip.component';
import { PostImageInput } from 'components/ui/form-fields/post-image-input/post-image-input.component';
import { PostVideoInput } from 'components/ui/form-fields/post-video-input/post-video-input.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Tooltip, TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import './emoji-picker-override.css';
import styles from './toolbar.module.less';

export interface ToolbarPluginProps {
  isSendDisabled: boolean;
  attachments: Maybe<EditorAttachmentsType>;
  isSendHide?: boolean;
  forGameChat: boolean;
  isVideoLoading: boolean;
  isLoadingImages: boolean;
  isGifPickerOpen: boolean;
  isDesktopPlus?: boolean;
  onPollClick: (toggleFlag: boolean) => void;
  onImageLoad: (fileData: IUploadImageData[]) => void;
  onUploadVideo?: (video: File) => void;
  onGifSelect: (gif: string) => void;
  setLoadingImages: (value: string[]) => void;
  setIsGifPickerOpen: (value: boolean) => void;
  onVideoError?: (error: string) => void;
  convertImageItemToAttachment: ConvertImageItemToAttachmentType;
}

export const BottomToolbarPlugin = (props: ToolbarPluginProps) => {
  const {
    forGameChat,
    isGifPickerOpen,
    isSendHide,
    isSendDisabled,
    isDesktopPlus,
    attachments,
    isLoadingImages,
    isVideoLoading,
    setIsGifPickerOpen,
    onPollClick,
  } = props;

  const [editor] = useLexicalComposerContext();

  const toolbarRef = useRef<HTMLDivElement>(null);

  const [isPollHidden, setIsPollHidden] = useState(true);
  const [isVideoInputOpen, setIsVideoInputOpen] = useState(false);
  const [shouldCloseEmojiPicker, setShouldCloseEmojiPicker] = useState(false);
  const [isVideoPermissionsRequested, setIsVideoPermissionsRequested] = useState(false);

  useEffect(() => {
    setIsVideoPermissionsRequested(false);
  }, []);

  const handleEmojiClick = useCallback(
    (emojiData: EmojiClickData) => {
      setShouldCloseEmojiPicker(true);

      editor.focus(() => {
        editor.update(() => {
          const textNode = $createTextNode(emojiData.emoji);
          const selection = $getSelection();

          if (selection) {
            selection.insertNodes([textNode]);
          }
        });
      });
    },
    [editor],
  );

  const handleShowPoll = useCallback(() => {
    setIsPollHidden(false);
    onPollClick(false);
  }, [onPollClick]);

  const handleToggleShowGifPicker = useCallback(() => {
    setIsGifPickerOpen(!isGifPickerOpen);
  }, [isGifPickerOpen, setIsGifPickerOpen]);

  const handleRemovePoll = useCallback(() => {
    setIsPollHidden(true);
    onPollClick(true);
  }, [onPollClick]);

  const emojiPreviewConfig: Partial<PreviewConfig> = {
    showPreview: false,
  };

  const toolbarClasses = useMemo(() => cn(styles.Toolbar, styles['Toolbar--bottom']), []);

  const handleAddVideo = useCallback(() => {
    setIsVideoInputOpen(true);
  }, [setIsVideoInputOpen]);

  const handleListClick = useCallback(
    (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click) {
        if (barAction.payload === 'video') {
          handleAddVideo();
        }

        if (barAction.payload === 'poll') {
          handleShowPoll();
        }

        if (barAction.payload === 'gif') {
          handleToggleShowGifPicker();
        }
      }
    },
    [handleAddVideo, handleShowPoll, handleToggleShowGifPicker],
  );

  const attachmentsMenuItems = useMemo((): IContextMenuGroup[] => {
    const commonItems: IContextMenuItem[] = [
      {
        icon: IconFontName.Gif,
        text: 'Add Gif',
        actionType: { type: BarAction.Click, payload: 'gif' },
        isDisabled: Boolean(
          Number(attachments?.gifs?.length) + Number(attachments?.images?.length) > 8 ||
            attachments?.poll,
        ),
      },
      {
        icon: IconFontName.Poll,
        text: 'Poll',
        actionType: { type: BarAction.Click, payload: 'poll' },
        isDisabled: Boolean(
          attachments?.poll ||
            attachments?.images?.length ||
            attachments?.gifs?.length ||
            attachments?.videos?.length ||
            isVideoLoading,
        ),
      },
    ];

    if (forGameChat) {
      return [{ id: 1, group: commonItems }];
    }

    return [
      {
        id: 1,
        group: [
          {
            icon: IconFontName.VideoCamera,
            text: 'Upload Video',
            actionType: { type: BarAction.Click, payload: 'video' },
            isDisabled: Boolean(
              attachments?.videos?.length ||
                Number(attachments?.gifs?.length) + Number(attachments?.images?.length) > 8 ||
                attachments?.poll ||
                isVideoLoading,
            ),
          },
          ...commonItems,
        ],
      },
    ];
  }, [attachments, forGameChat, isVideoLoading]);

  const contextMenuPlacement = useMemo(() => {
    if (isDesktopPlus) {
      return 'top';
    }

    return 'top-start';
  }, [isDesktopPlus]);

  return (
    <div ref={toolbarRef} className={toolbarClasses}>
      <ContextMenuTooltip
        toggleOnClick
        tooltipProps={{
          placement: contextMenuPlacement,
          tooltipOffset: 12,
          closeOnContentClick: true,
        }}
        groups={attachmentsMenuItems}
        onItemClick={handleListClick}
      >
        <IconButton theme={IconButtonTheme.Secondary} iconName={IconFontName.Add} />
      </ContextMenuTooltip>
      {!forGameChat && (
        <PostImageInput
          convertImageItemToAttachment={props.convertImageItemToAttachment}
          accept={FILES_IMAGE_TYPES.join(', ')}
          onImageLoad={props.onImageLoad}
          id="attachImage"
          name="attachImage"
          isDisabled={!isPollHidden || isLoadingImages}
          setLoadingImages={props.setLoadingImages}
        />
      )}
      <Tooltip
        toggleOnClick
        tooltipPortalRoot={toolbarRef.current}
        eventType={TooltipEventType.click}
        isNeedCloseTooltip={shouldCloseEmojiPicker}
        setIsNeedCloseTooltip={setShouldCloseEmojiPicker}
        tooltipOffset={12}
        tooltipContent={
          <EmojiPicker
            theme={Theme.DARK}
            previewConfig={emojiPreviewConfig}
            onEmojiClick={handleEmojiClick}
          />
        }
      >
        <IconButton theme={IconButtonTheme.Secondary} iconName={IconFontName.Reaction} />
      </Tooltip>
      {!isPollHidden && (
        <div className={styles.Toolbar__RemovePoll}>
          <Button theme={ButtonTheme.TextAlert} size={ButtonSize.Small} onClick={handleRemovePoll}>
            Remove Poll
          </Button>
        </div>
      )}
      {!isSendHide && (
        <div className={styles.Toolbar__SubmitButton}>
          <Button
            disabled={isSendDisabled}
            size={ButtonSize.Small}
            theme={ButtonTheme.Primary}
            type="submit"
          >
            <IconFont name={IconFontName.Send} size={IconFontSize.Small} />
          </Button>
        </div>
      )}
      {isGifPickerOpen && (
        <GifPicker
          onGifSelect={props.onGifSelect}
          onClose={handleToggleShowGifPicker}
          visible={isGifPickerOpen}
        />
      )}
      <div className={styles.Video}>
        <PostVideoInput
          isVideoInputOpen={isVideoInputOpen}
          setIsVideoInputOpen={setIsVideoInputOpen}
          accept={FILES_VIDEO_TYPES.join(', ')}
          onVideoLoad={props.onUploadVideo}
          id="attachVideo"
          name="attachVideo"
          onError={props.onVideoError}
          isVideoPermissionsRequested={isVideoPermissionsRequested}
          setIsVideoPermissionsRequested={setIsVideoPermissionsRequested}
        />
      </div>
    </div>
  );
};
