import { ITeamsStatsLeaderResponse } from 'services/team-stats/interfaces/teams-stats-leaders.interfaces';

import { convertColorToRGB } from 'helpers/rgb-to-rgba.utils';
import { roundToSingleDecimal } from 'helpers/round-to-single-decimal.util';

import { IColumnChartItem } from 'components/ui/column-charts/column-charts.component';

type TeamLeaderBy = Pick<
  ITeamsStatsLeaderResponse,
  'blocks' | 'diff_points' | 'fgp' | 'opponent_points' | 'points' | 'tot_reb'
>;

export function teamLeaderAdapter(
  leadersResponse: ITeamsStatsLeaderResponse[],
  leaderBy: keyof TeamLeaderBy,
): IColumnChartItem[] {
  return leadersResponse.map((leader) => {
    const { name, primary_color: color, id, small_logo_url: smallLogoUrl } = leader;

    return {
      avatarUrl: smallLogoUrl,
      color: convertColorToRGB(color),
      name,
      id: String(id),
      value: roundToSingleDecimal(leader[leaderBy]),
    };
  });
}
