import { IPlayerRankedStatsResponse } from 'services/player/interfaces/player-stats.interface';

import { ISplitsTotalRowData } from '../interfaces/player-profile.interfaces';

export function playerSplitsTotalRowDataAdapter(
  playerRankedStats: IPlayerRankedStatsResponse,
): ISplitsTotalRowData[] {
  return [
    {
      total: 'All Splits',
      gamesPlayed: playerRankedStats.games_played,
      points: playerRankedStats.points,
      steals: playerRankedStats.steals,
      totalRebounds: playerRankedStats.tot_reb,
      assists: playerRankedStats.assists,
      offensiveReb: playerRankedStats.off_reb,
      defensiveReb: playerRankedStats.def_reb,
      blocks: playerRankedStats.blocks,
      personalFouls: playerRankedStats.personal_fouls,
      turnovers: playerRankedStats.turnovers,
      fieldGoalsMade: playerRankedStats.fgm,
      fieldGoalsAttempted: playerRankedStats.fga,
      fieldGoalsPercentage: playerRankedStats.fgp,
      threePointsFieldGoalsMade: playerRankedStats.tpm,
      threePointsFieldGoalsAttempted: playerRankedStats.tpa,
      threePointsFieldGoalsPercentage: playerRankedStats.tpp,
      freeThrowsMade: playerRankedStats.ftm,
      freeThrowsAttempted: playerRankedStats.fta,
      freeThrowsPercentage: playerRankedStats.ftp,
    },
  ];
}
