import { useMemo } from 'react';

import { IReaction } from 'components/posts/post-card/interfaces/reactions.interface';

const DISPLAY_LIMITATION = 2;

export const usePopularReactions = (reactions: {
  positive?: IReaction[];
  negative?: IReaction[];
}) => {
  const combinedReactions = useMemo(() => {
    const fullReactions: IReaction[] = [];

    if (reactions.positive) {
      fullReactions.push(...reactions.positive);
    }

    if (reactions.negative) {
      fullReactions.push(...reactions.negative);
    }

    return fullReactions;
  }, [reactions.positive, reactions.negative]);

  const sortedReactions = useMemo(
    () =>
      combinedReactions.sort(
        (acc, next) =>
          (next.isSelected ? 1 : 0) - (acc.isSelected ? 1 : 0) || acc.count + next.count,
      ),
    [combinedReactions],
  );

  const slicedReactions = useMemo(
    () => sortedReactions.slice(0, DISPLAY_LIMITATION),
    [sortedReactions],
  );

  return slicedReactions;
};
