import { SyntheticEvent } from 'react';

import { BaseBottomSheet } from 'components/bottom-sheet/base-bottom-sheet/base-bottom-sheet.component';

import styles from './photo-camera-bottom-sheet.module.less';

interface IPhotoCameraBottomSheetProps {
  visible: boolean;
  onSelectFromLibrary: (event: SyntheticEvent) => void;
  onTakePicture: (event: SyntheticEvent) => void;
  onClose: () => void;
}

export const PhotoCameraBottomSheet = (props: IPhotoCameraBottomSheetProps) => {
  const { visible } = props;

  return (
    <BaseBottomSheet visible={visible} hasBackdrop onClose={props.onClose}>
      <span className={styles.Title}>Photo</span>
      <button className={styles.Button} onClick={props.onSelectFromLibrary}>
        From Photos
      </button>
      <button className={styles.Button} onClick={props.onTakePicture}>
        Take Picture
      </button>
    </BaseBottomSheet>
  );
};
