import logger, { Debugger } from 'debug';

export enum LoggingLevels {
  debug = 'debug',
  info = 'info',
  error = 'error',
  warning = 'warning',
}
// 'any' statement is using for debugging payload
// because there are no restrictions for log parameters
export interface IDebugPayload extends Record<string, any> {
  msg: string;
}

export type DebugNamespaceType = Record<LoggingLevels, (payload: IDebugPayload) => void>;

const ROOT_NAMESPACE = 'DIGITS_WEB';

function logFormatted({ msg, ...rest }: IDebugPayload, dbg: Debugger) {
  if (Object.keys(rest).length) {
    dbg(msg, rest);
  } else {
    dbg(msg);
  }
}

export function getLoggers(namespace: string): DebugNamespaceType {
  const debugLogger = logger(`${ROOT_NAMESPACE}:${LoggingLevels.debug}:${namespace}`);
  const errorLogger = logger(`${ROOT_NAMESPACE}:${LoggingLevels.error}:${namespace}`);
  const infoLogger = logger(`${ROOT_NAMESPACE}:${LoggingLevels.info}:${namespace}`);
  const warningLogger = logger(`${ROOT_NAMESPACE}:${LoggingLevels.warning}:${namespace}`);

  return {
    [LoggingLevels.debug]: (payload: IDebugPayload) => {
      logFormatted(payload, debugLogger);
    },
    [LoggingLevels.error]: (payload: IDebugPayload) => {
      logFormatted(payload, errorLogger);
    },
    [LoggingLevels.info]: (payload: IDebugPayload) => {
      logFormatted(payload, infoLogger);
    },
    [LoggingLevels.warning]: (payload: IDebugPayload) => {
      logFormatted(payload, warningLogger);
    },
  };
}
