import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { PlayerRankingsCategoryEnum } from 'services/player-rankings/interfaces/player-rankings-response.interface';

import { IPlayerRankingsItem } from 'stores/player-rankings/interfaces/player-rankings.interface';

import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { RankingPlayerItem } from 'components/player-rankings/rankings-player-item/rankings-player-item.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './player-rankings-category.module.less';

const INFO_ICON_BUTTON_COLOR = '#687C85';

export interface IPlayerRankingsCategoryProps {
  players: IPlayerRankingsItem[];
  title: string;
  categorySlug: PlayerRankingsCategoryEnum;
  isHintEnable: boolean;
  forDetails?: boolean;
  onHintClick?: () => void;
  onSeeMoreClick?: (categoryKey: string) => void;
  onPlayerClick: (slug: string, gameId: Maybe<string>) => void;
}

export const PlayerRankingsCategory = memo((props: IPlayerRankingsCategoryProps) => {
  const { players, isHintEnable, onSeeMoreClick, categorySlug, title, forDetails = false } = props;

  const handleSeeMoreClick = useCallback(() => {
    onSeeMoreClick?.(categorySlug);
  }, [onSeeMoreClick, categorySlug]);

  const playerRankingsCategoryItemsClasses = useMemo(
    () => cn(styles.PlayerRankingsCategory__Items, TOUCH_IGNORE_CLASS),
    [],
  );

  return (
    <div className={styles.PlayerRankingsCategory}>
      <div className={styles.PlayerRankingsCategory__Header}>
        <div className={styles.PlayerRankingsCategory__Title}>{title}</div>
        {isHintEnable && (
          <IconButton
            theme={IconButtonTheme.Transparent}
            iconName={IconFontName.Info}
            onClick={props.onHintClick}
            customColor={INFO_ICON_BUTTON_COLOR}
            iconSize={IconFontSize.Small}
          />
        )}
        {onSeeMoreClick && (
          <button className={styles.PlayerRankingsCategory__SeeMore} onClick={handleSeeMoreClick}>
            <span className={styles.PlayerRankingsCategory__SeeMoreLabel}>See more</span>
          </button>
        )}
      </div>
      <div className={playerRankingsCategoryItemsClasses}>
        {players.map((player) => (
          <RankingPlayerItem
            key={player.playerInfo.slug}
            forDetails={forDetails}
            player={player}
            categorySlug={categorySlug}
            onPlayerClick={props.onPlayerClick}
          />
        ))}
      </div>
    </div>
  );
});
