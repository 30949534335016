import { memo } from 'react';

interface ISplitsGameTableHeaderProps {
  displayName: string;
}

export const SplitsGameTableHeader = memo((props: ISplitsGameTableHeaderProps) => {
  return (
    <div className="ag-cell-label-container">
      <div className="ag-header-cell-label">{props.displayName}</div>
    </div>
  );
});
