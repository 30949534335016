import { memo, useMemo } from 'react';
import cn from 'classnames';

import { GuideTooltipPositions } from './enums/guide-tooltip-positions.enum';

import styles from './guide-tooltip.module.less';

interface IGuideTooltipProps {
  isShown: boolean;
  text: string;
  position: GuideTooltipPositions;
  children: JSX.Element;
}

export const GuideTooltip = memo((props: IGuideTooltipProps) => {
  const { isShown, text, position, children } = props;

  const guideTooltipClasses = useMemo(
    () =>
      cn(styles.GuideTooltip, {
        [styles['GuideTooltip--position-left']]: position === GuideTooltipPositions.Left,
        [styles['GuideTooltip--position-right']]: position === GuideTooltipPositions.Right,
        [styles['GuideTooltip--position-center']]: position === GuideTooltipPositions.Center,
      }),
    [position],
  );

  if (isShown) {
    return (
      <div className={guideTooltipClasses}>
        {children}
        <div className={styles.GuideTooltip__Content}>{text}</div>
      </div>
    );
  }

  return children;
});
