import { FC } from 'react';
import { MenuListProps } from 'react-select';
import { IPlayerOption } from 'containers/profile/profile-info/interfaces/player-option.interface';

import styles from './player-menu-list.module.less';

interface IPlayerMenuList extends MenuListProps<Maybe<IPlayerOption>, false> {}

export const PlayerMenuList: FC<IPlayerMenuList> = ({ children }) => {
  return <div className={styles.MenuList}>{children}</div>;
};
