import { memo, useMemo } from 'react';
import cn from 'classnames';

import { ScoreboardLineScoreHeader } from 'components/scoreboard/scoreboard-linescore/scoreboard-line-score-header/scoreboard-line-score-header.component';

import verticalRowsTableGridStyles from '../vertical-rows-table-grid.module.less';
import styles from './vertical-rows-table-header.module.less';

interface IVerticalRowsTableHeaderProps {
  gameStatusOrTime: string;
  total: Maybe<number>;
}

export const VerticalRowsTableHeader = memo((props: IVerticalRowsTableHeaderProps) => {
  const { gameStatusOrTime, total } = props;

  const cellUnderlinedClassNames = useMemo<string>(
    () =>
      cn(styles.VerticalRowsTableHeader__Cell, [
        styles['VerticalRowsTableHeader__Cell--underlined'],
      ]),
    [],
  );

  return (
    <div
      className={cn(
        styles.VerticalRowsTableHeader,
        verticalRowsTableGridStyles.VerticalRowsTableGrid,
      )}
      role="rowheader"
    >
      <div className={styles.VerticalRowsTableHeader__Cell}>{gameStatusOrTime}</div>

      <div className={cellUnderlinedClassNames}>
        <ScoreboardLineScoreHeader total={total} />
      </div>

      <div className={cn(styles.VerticalRowsTableHeader__Cell, styles.TopPerformer)}>
        Top performers
      </div>
    </div>
  );
});
