import {
  GoogleAuth,
  GoogleAuthPluginOptions,
  InitOptions,
} from '@codetrix-studio/capacitor-google-auth';
import { injectable } from 'inversify';
import { authLogger } from 'loggers/auth.logger';

import { GOOGLE_CLIENT_ID } from 'configs/environment.config';

@injectable()
export class LoginWithGoogleService {
  // TODO investigate this warning: Your client application uses libraries for user authentication or authorization that are deprecated. See the [Migration Guide](https://developers.google.com/identity/gsi/web/guides/gis-migration) for more information.
  // in scope of ENG-2060
  private readonly options: GoogleAuthPluginOptions | InitOptions;

  constructor() {
    this.options = {
      clientId: GOOGLE_CLIENT_ID,
      scopes: ['profile', 'email'],
      forceCodeForRefreshToken: true,
      grantOfflineAccess: true,
    };
  }

  public initialize() {
    authLogger.info({ msg: 'initialise loginWithGoogleService' });

    GoogleAuth.initialize(this.options);
  }

  public async signIn(): Promise<Maybe<string>> {
    authLogger.info({ msg: 'sign in with google' });

    try {
      const googleUser = await GoogleAuth.signIn();

      authLogger.debug({ msg: 'sign in with google request response', googleUser });

      return googleUser.authentication.idToken;
    } catch (error) {
      authLogger.error({ msg: 'sign in with google request failed', error });

      return null;
    }
  }

  public async signOut() {
    await GoogleAuth.signOut();
  }
}
