import { FC, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { IFollowPayload } from 'services/follow/interfaces/followings-response.interface';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';

import { AuthStore } from 'stores/auth/auth.store';
import { AuthMode } from 'stores/auth/enums/auth-mode.enum';
import { FollowStore } from 'stores/follow/follow.store';
import { LayoutStore } from 'stores/layout/layout.store';

import { IntoStateNavigation } from 'containers/intro/enums/intro-state-navigation.enum';

import { TYPES } from 'configs/di-types.config';

import { useGaScreenCustomView } from 'hooks/use-ga-screen-custom-view';

import { Followings } from 'components/followings/followings.component';

export const FollowingsContainer: FC = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const followStore = useInjection<FollowStore>(TYPES.FollowStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);

  const location = useLocation();

  const { googleAnalyticsHomeFeed } = useGaScreenCustomView();

  const isFirstTimeOpened = useMemo(
    () => location.state === IntoStateNavigation.FirstTimeOpened,
    [location.state],
  );

  const handleGoogleAnalyticsHomeFeed = useCallback(
    (screenType: ScreenNamesEnum) => {
      googleAnalyticsHomeFeed(screenType);
    },
    [googleAnalyticsHomeFeed],
  );

  const handleFollowClick = useCallback(
    async (payload: IFollowPayload) => {
      if (authStore.isAuthorised) {
        const response = await followStore.subscribeFollow(payload);
        return response;
      }

      followStore.setFollowingsIntoSessions(payload);

      return true;
    },
    [authStore.isAuthorised, followStore],
  );

  const handleOpenSignUpModal = useCallback(() => {
    authStore.setAuthMode(AuthMode.SignUp);
  }, [authStore]);

  return (
    <Followings
      isLoading={followStore.fetching || !followStore.follow.length}
      initialFollowings={followStore.follow}
      isFirstTimeOpened={isFirstTimeOpened}
      isDisplayedSmartBanner={layoutStore.isDisplayedSmartBanner}
      onFollowClick={handleFollowClick}
      onOpenSignUpModal={handleOpenSignUpModal}
      onGoogleAnalyticsHomeFeed={handleGoogleAnalyticsHomeFeed}
    />
  );
});
