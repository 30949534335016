import { FC } from 'react';

import { PlayerProfileSplitsContainer } from 'containers/player-profile/player-profile-splits/player-profile-splits.container';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const PlayerProfileSplitsRoute: FC = () => {
  return (
    <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
      <Column mobile={1} desktop={{ start: 1, end: 3 }}>
        <PlayerProfileSplitsContainer />
      </Column>
    </Row>
  );
};
