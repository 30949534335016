import { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';

import { OpenPreviewType } from 'stores/gallery/types/gallery.type';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { useIframeError } from 'hooks/use-iframe-error';

import {
  IframePreview,
  IframePreviewSize,
} from 'components/iframe-preview/iframe-preview.component';
import { IAuthor } from 'components/modals/share-modal/share-modal.component';
import { GifSize, SingleGif } from 'components/single-gif/single-gif.component';
import {
  Video,
  VideoSize,
} from 'components/ui/images-carousel-preview/components/video/video.component';

import { SlideArrow } from './components/slide-arrow/slide-arrow.component';
import { SlideDot } from './components/slide-dot/slide-dot.component';

import styles from './images-carousel-preview.module.less';

export interface IImagesCarouselPreviewProps {
  slides: IInteractiveMediaAttachment[];
  team: Maybe<ITeamsStats>;
  author: IAuthor;
  date: string;
  onImageClick?: OpenPreviewType;
}

export const ImagesCarouselPreview = (props: IImagesCarouselPreviewProps) => {
  const { slides, author, onImageClick, team, date } = props;

  const [iframeRef, hasIframeError] = useIframeError();
  const [emblaRef, emblaApi] = useEmblaCarousel({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const handleScrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const handleScrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;

    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  const handleSingleImageClick = useCallback(
    (index: number) => {
      return () => {
        if (onImageClick) {
          onImageClick(author, slides, date, index, team);
        }
      };
    },
    [onImageClick, author, slides, date, team],
  );

  const renderOneAttachment = useCallback(() => {
    if (slides.length && slides[0]?.type === 'image') {
      return (
        <div className={styles.ImagesCarouselPreview__Container}>
          <button
            className={styles.ImagesCarouselPreview__ButtonAbsolute}
            onClick={handleSingleImageClick(0)}
          >
            <img className={styles.ImagesCarouselPreview__Image} src={slides[0].url} alt="attach" />
          </button>
        </div>
      );
    }

    if (slides.length && slides[0]?.type === 'gif') {
      return (
        <div className={styles.ImagesCarouselPreview__Container}>
          <button
            className={styles.ImagesCarouselPreview__Button}
            onClick={handleSingleImageClick(0)}
          >
            <SingleGif id={slides[0]?.url} size={GifSize.L} />
          </button>
        </div>
      );
    }

    if (slides.length && slides[0]?.type === 'iframe' && !hasIframeError) {
      return (
        <div className={styles.ImagesCarouselPreview__Container}>
          <button
            className={styles.ImagesCarouselPreview__SlideVideo}
            onClick={handleSingleImageClick(0)}
          >
            <IframePreview ref={iframeRef} url={slides[0].url} size={IframePreviewSize.FULL} />
          </button>
        </div>
      );
    }

    if (slides.length && slides[0]?.type === 'video') {
      return (
        <div className={styles.ImagesCarouselPreview__Container}>
          <button
            className={styles.ImagesCarouselPreview__SlideVideo}
            onClick={handleSingleImageClick(0)}
          >
            <Video
              size={VideoSize.FULL}
              isMuted
              isAutoplay
              type={slides[0]?.mimeType || ''}
              url={slides[0].url}
            />
          </button>
        </div>
      );
    }

    return null;
  }, [slides, hasIframeError, handleSingleImageClick, iframeRef]);

  const renderMultipleAttachments = useCallback(() => {
    return (
      <>
        <div className={styles.ImagesCarouselPreview__Slider}>
          <div className={styles.ImagesCarouselPreview__Viewport} ref={emblaRef}>
            <div className={styles.ImagesCarouselPreview__Container}>
              {slides.map(({ url, type }, index) => {
                if (type === 'video') {
                  return (
                    <button
                      onClick={handleSingleImageClick(index)}
                      // in this case index is good solution because attachments cant be changed
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      className={styles.ImagesCarouselPreview__Slide}
                    >
                      <div className={styles.ImagesCarouselPreview__SlideVideo}>
                        <Video
                          url={url}
                          type={slides[index]?.mimeType || ''}
                          size={VideoSize.FULL}
                        />
                      </div>
                    </button>
                  );
                }

                if (type === 'gif') {
                  return (
                    <button
                      onClick={handleSingleImageClick(index)}
                      // in this case index is good solution because attachments cant be changed
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      className={styles.ImagesCarouselPreview__Slide}
                    >
                      <SingleGif id={url} size={GifSize.L} />
                    </button>
                  );
                }

                if (type === 'iframe' && !hasIframeError) {
                  return (
                    <button
                      onClick={handleSingleImageClick(index)}
                      // in this case index is good solution because attachments cant be changed
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      className={styles.ImagesCarouselPreview__Slide}
                    >
                      <IframePreview ref={iframeRef} url={url} size={IframePreviewSize.FULL} />
                    </button>
                  );
                }

                return (
                  <button
                    onClick={handleSingleImageClick(index)}
                    // in this case index is good solution because attachments cant be changed
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                    className={styles.ImagesCarouselPreview__SlideAbsolute}
                  >
                    <img
                      className={styles.ImagesCarouselPreview__Image}
                      src={url}
                      alt={`Slide ${index}`}
                    />
                  </button>
                );
              })}
            </div>
          </div>

          <div className={styles.ImagesCarouselPreview__Arrows}>
            <SlideArrow direction="prev" onClick={handleScrollPrev} isEnabled={prevBtnEnabled} />
            <SlideArrow direction="next" onClick={handleScrollNext} isEnabled={nextBtnEnabled} />
          </div>
        </div>

        <div className={styles.ImagesCarouselPreview__Dots}>
          {scrollSnaps.map((_, index) => (
            <SlideDot key={_} isSelected={index === selectedIndex} />
          ))}
        </div>
      </>
    );
  }, [
    emblaRef,
    handleScrollNext,
    handleScrollPrev,
    handleSingleImageClick,
    hasIframeError,
    iframeRef,
    nextBtnEnabled,
    prevBtnEnabled,
    scrollSnaps,
    selectedIndex,
    slides,
  ]);

  const classNames = useMemo(() => cn(styles.ImagesCarouselPreview, TOUCH_IGNORE_CLASS), []);

  return (
    <div className={classNames}>
      {slides.length === 1 ? renderOneAttachment() : renderMultipleAttachments()}
    </div>
  );
};
