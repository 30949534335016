export const getPath = (route: string, pathParams?: Record<string, string>): string => {
  if (pathParams) {
    return Object.entries(pathParams).reduce(
      (res, [key, value]) => res.replaceAll(`:${key}`, value),
      route,
    );
  }

  return route;
};
