import { FC, memo, useCallback } from 'react';

import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';

import styles from './author-card-details.module.less';

type SocialIconsType =
  | IconFontName.Insta
  | IconFontName.Youtube
  | IconFontName.Twitter
  | IconFontName.TikTok
  | IconFontName.Discord
  | IconFontName.Facebook
  | IconFontName.Telegram
  | IconFontName.Google;

interface IUserSocial {
  socialIcon: SocialIconsType;
  path: string;
}

export interface IAuthorCardDetailsProps {
  username: string;
  realname?: string;
  team: Maybe<ITeamsStats>;
  player: Maybe<IPlayerStats>;
  userStatus?: string;
  userSocials?: IUserSocial[];
  onSocialClick?: (path: string) => void;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
}

export const AuthorCardDetails: FC<IAuthorCardDetailsProps> = memo(
  (props: IAuthorCardDetailsProps) => {
    const { username, realname, team, player, userStatus, userSocials, onSocialClick } = props;

    const handleSocialClick = useCallback(
      (path: string) => {
        return () => {
          onSocialClick?.(path);
        };
      },
      [onSocialClick],
    );

    return (
      <div className={styles.AuthorCardDetails}>
        <div className={styles.AuthorCardDetails__Names}>
          <h4>{username}</h4>
          <span>{realname}</span>
        </div>
        <TeamPlayerLabel
          team={team}
          player={player}
          onTeamClick={props.onTeamClick}
          onPlayerClick={props.onPlayerClick}
        />
        {userStatus && <div className={styles.AuthorCardDetails__Status}>{userStatus}</div>}
        <div className={styles.AuthorCardDetails__Socials}>
          {userSocials?.map((social) => (
            <IconButton
              key={social.socialIcon}
              iconName={social.socialIcon}
              theme={IconButtonTheme.Transparent}
              onClick={handleSocialClick(social.path)}
            />
          ))}
        </div>
      </div>
    );
  },
);
