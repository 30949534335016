import { FC, useMemo } from 'react';

import { IPlayerSplits } from 'stores/player/interfaces/player-profile.interfaces';

import { EmptyState } from 'components/empty-state/empty-state.component';
import { PlayerProfileSplitsAll } from 'components/player-profile/player-profile-splits/player-profile-splits-all/player-profile-splits-all.component';
import { Select } from 'components/ui/form-fields/select/select.component';
import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';

import playerProfileStyles from '../player-profile.module.less';
import styles from './player-profile-splits.module.less';

interface IPlayerProfileSplitsProps {
  seasonOptions: ISelectOption[];
  selectedSeason: ISelectOption;
  playerSplits: Maybe<IPlayerSplits>;
  hasTeamColor: boolean;
  onSeasonChange: (season: string) => void;
  onTeamClick: (teamId: number) => void;
}

export const PlayerProfileSplits: FC<IPlayerProfileSplitsProps> = (props) => {
  const { seasonOptions, selectedSeason, playerSplits, hasTeamColor } = props;

  const title = useMemo(() => {
    const seasonPart = selectedSeason.label.split(' ')[0];

    return `${seasonPart} Splits`;
  }, [selectedSeason.label]);

  return (
    <div className={playerProfileStyles.PlayerProfile}>
      <div className={playerProfileStyles.PlayerProfile__Section}>
        <div className={styles.Filter}>
          <Select
            name="season"
            id="season"
            options={seasonOptions}
            value={selectedSeason.value}
            onChange={props.onSeasonChange}
          />
        </div>
      </div>

      <div className={playerProfileStyles.PlayerProfile__Section}>
        <div className={playerProfileStyles.PlayerProfile__Header}>{title}</div>
      </div>

      {!playerSplits ? (
        <EmptyState
          title="No Data for the Selected Season"
          message="Unfortunately, the player does not have any data for this season"
        />
      ) : (
        <PlayerProfileSplitsAll
          totalRowData={playerSplits.total}
          splitRowData={playerSplits.split}
          monthRowData={playerSplits.month}
          resultRowData={playerSplits.result}
          dayRowData={playerSplits.day}
          opponentRowData={playerSplits.opponent}
          hasTeamColor={hasTeamColor}
          onTeamClick={props.onTeamClick}
        />
      )}
    </div>
  );
};
