import { FC, useCallback, useMemo } from 'react';
import { OnChangeValue } from 'react-select';
import {
  ICheckboxItem,
  MultipleChooseCheckboxes,
} from 'components/ui/form-fields/multiple-choose-checkboxes/multiple-choose-checkboxes.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

interface ICustomMenuProps {
  manualOptions: ICheckboxItem[];
  autoOptions: ICheckboxItem[];
  manualOptionsLimit: number;
  withActionButtons?: boolean;
  onApplyClick?: (value: string[]) => void;
  onItemClick: (selectedOptions: OnChangeValue<ICheckboxItem, true>) => void;
}

export const CustomMenu: FC<ICustomMenuProps> = (props: ICustomMenuProps) => {
  const {
    manualOptionsLimit,
    onApplyClick,
    onItemClick,
    manualOptions,
    autoOptions,
    withActionButtons,
  } = props;

  const isManualOptionsDisabled = useMemo(() => {
    if (manualOptionsLimit) {
      const selectedOptions = manualOptions.filter((tag) => tag.selected);

      return selectedOptions.length >= manualOptionsLimit;
    }

    return false;
  }, [manualOptions, manualOptionsLimit]);

  const handleSelectManualClick = useCallback(
    (items: ICheckboxItem[]) => {
      const selectedOptions = items.filter((item) => item.selected);
      const selectedAutoOptions = autoOptions.filter((autoTag) => autoTag.selected);
      onItemClick([...selectedAutoOptions, ...selectedOptions]);
    },
    [autoOptions, onItemClick],
  );

  const handleSelectAutoClick = useCallback(
    (items: ICheckboxItem[]) => {
      const selectedOptions = items.filter((item) => item.selected);
      const selectedManualOptions = manualOptions.filter((manualTag) => manualTag.selected);
      onItemClick([...selectedManualOptions, ...selectedOptions]);
    },
    [manualOptions, onItemClick],
  );

  const handleClearSelection = useCallback(() => {
    onItemClick([]);
  }, [onItemClick]);

  const allOptions = useMemo(
    () => [...manualOptions, ...autoOptions],
    [autoOptions, manualOptions],
  );

  const handleApplyClick = useCallback(() => {
    if (onApplyClick) {
      const selectedValues = allOptions.filter((item) => item.selected).map((item) => item.value);
      onApplyClick(selectedValues);
    }
  }, [allOptions, onApplyClick]);

  const clearEnabled = useMemo(
    () => allOptions.some((tag: ICheckboxItem) => tag.selected),
    [allOptions],
  );

  return (
    <>
      <MultipleChooseCheckboxes
        id="manual_tags"
        name="manual_tags"
        onSelectClick={handleSelectManualClick}
        selectedItems={manualOptions}
        title="Manual Tags"
        shouldForceUpdate
        disabled={isManualOptionsDisabled}
      />
      {autoOptions.length > 0 && (
        <>
          <hr />
          <MultipleChooseCheckboxes
            id="auto_tags"
            name="auto_tags"
            onSelectClick={handleSelectAutoClick}
            selectedItems={autoOptions}
            title="Auto Tags"
            shouldForceUpdate
          />
        </>
      )}
      {withActionButtons && (
        <div className="CheckboxSelect__menu__bottom">
          <hr />
          <div className="CheckboxSelect__menu__btns">
            <Button
              onClick={handleClearSelection}
              theme={ButtonTheme.TextSecondary}
              size={ButtonSize.Small}
              disabled={!clearEnabled}
            >
              Clear Selection
            </Button>
            <Button
              onClick={handleApplyClick}
              theme={ButtonTheme.Secondary}
              size={ButtonSize.Small}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
