export const streakColumnComparator = (valA: string, valB: string) => {
  // The data in the column is a streak of wins and losses in format of "W2", "L1", "W10", "L3", and so on. Using the default sorting of the library the data will be sorted alphabetically, which will be incorrect.
  const aIsWin = valA.startsWith('W');
  const bIsWin = valB.startsWith('W');

  if (aIsWin && !bIsWin) {
    return 1;
  }

  if (!aIsWin && bIsWin) {
    return -1;
  }

  const aCount = parseInt(valA.slice(1), 10);
  const bCount = parseInt(valB.slice(1), 10);

  return aCount - bCount;
};
