import { ITeamStatsExtended } from 'stores/teams-stats/interfaces/teams-stats-all.interface';

import { IStatsBoxItem } from 'components/ui/stats-box/interfaces/stats-box.interface';
import { BOX_STATS_LIMITS } from 'components/ui/stats-box/stats-box.config';

export const prepareTeamStatsBoxItems = (stats: ITeamStatsExtended): IStatsBoxItem[] => {
  return [
    {
      title: 'Points Per Game',
      content: stats.points.toFixed(1),
      slug: 'PPG',
      sortByColumn: 'points',
      rank: { value: stats.pointsRank, limits: BOX_STATS_LIMITS.team },
    },
    {
      title: 'Rebounds Per Game',
      content: stats.totalRebounds.toFixed(1),
      slug: 'RPG',
      sortByColumn: 'totalRebounds',
      rank: { value: stats.totalReboundsRank, limits: BOX_STATS_LIMITS.team },
    },
    {
      title: 'Assists Per Game',
      content: stats.assists.toFixed(1),
      slug: 'APG',
      sortByColumn: 'assists',
      rank: { value: stats.assistsRank, limits: BOX_STATS_LIMITS.team },
    },
    {
      title: 'Steals Per Game',
      content: stats.steals.toFixed(1),
      slug: 'SPG',
      sortByColumn: 'steals',
      rank: { value: stats.stealsRank, limits: BOX_STATS_LIMITS.team },
    },
    {
      title: 'Blocks Per Game',
      content: stats.blocks.toFixed(1),
      slug: 'BPG',
      sortByColumn: 'blocks',
      rank: { value: stats.blocksRank, limits: BOX_STATS_LIMITS.team },
    },
  ];
};
