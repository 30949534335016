import { ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { LayoutStore } from 'stores/layout/layout.store';

import { SCROLL_TOP_ELEMENT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';

import styles from './page-wrapper.module.less';

interface IPageWrapperContainerProps {
  children: ReactNode;
}

export const PageWrapperContainer = observer((props: IPageWrapperContainerProps) => {
  const { children } = props;

  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);

  const pageWrapperClasses = useMemo(
    () =>
      cn(styles.PageWrapper, {
        [styles['PageWrapper--shifted']]: layoutStore.isSidePanelOpen,
      }),
    [layoutStore.isSidePanelOpen],
  );

  return (
    <div className={pageWrapperClasses} id={SCROLL_TOP_ELEMENT}>
      {children}
    </div>
  );
});
