import { useMemo } from 'react';
import cn from 'classnames';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';

import { ISelectOption } from '../select/select-option.interface';

import styles from './select-season-mobile.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

interface ISelectSeasonMobileProps {
  visible: boolean;
  options: ISelectOption[];
  value: string;
  onChange: (option: ISelectOption) => void;
  onClose: () => void;
}

export const SelectSeasonMobile = (props: ISelectSeasonMobileProps) => {
  const { options, value, visible } = props;

  const getFilteredSeasons = useMemo(() => {
    const yearLabelsArray: string[] = options.map(
      (item: ISelectOption) => item.label.split(' ')[0],
    );
    const unqiueYearLabelsArray: string[] = [...new Set(yearLabelsArray)];
    const seasonsByYear: ISelectOption[][] = [];

    unqiueYearLabelsArray.forEach((year: string) => {
      const filteredOptions = options.filter(
        (option: ISelectOption) => year === option.label.split(' ')[0],
      );
      seasonsByYear.push(filteredOptions);
    });
    return seasonsByYear;
  }, [options]);

  return (
    <BaseIonBottomSheet
      visible={visible}
      safeAreaBottom={0}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      initialBreakpoint={INITIAL_BREAKPOINT}
      isAutoHeight
      onClose={props.onClose}
    >
      <div className={styles.SelectSeason}>
        {getFilteredSeasons.map((season: ISelectOption[], index: number) => (
          // since this is arrays there are no uniqe values
          // eslint-disable-next-line react/no-array-index-key
          <ul key={index} className={styles.SelectSeason__List}>
            {season?.map((option) => (
              <li
                key={option.value}
                className={cn(
                  styles.SelectSeason__Item,
                  option.value === value ? styles['SelectSeason__Item--active'] : '',
                )}
                role="tab"
                tabIndex={0}
                onClick={() => props.onChange(option)}
                onKeyDown={() => props.onChange(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        ))}
      </div>
    </BaseIonBottomSheet>
  );
};
