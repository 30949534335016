import { memo, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IconFont, IconFontName, IconFontSize } from '../icon-font/icon-font.component';

import styles from './icon-badge.module.less';

export enum IconBadgeTheme {
  Default = 'default',
}

export interface IIconBadgeProps {
  theme?: IconBadgeTheme;
  iconName: IconFontName;
  iconSize: IconFontSize;
  content: string;
  onClick?: (event: SyntheticEvent) => void;
}

export const IconBadge = memo((props: IIconBadgeProps) => {
  const { theme = IconBadgeTheme.Default, iconName, iconSize, content, onClick } = props;

  const badgeClassNames = useMemo(
    () =>
      cn(styles.IconBadge, {
        [styles['IconBadge--theme-default']]: theme === IconBadgeTheme.Default,
        [styles['IconBadge--clickable']]: !!onClick,
      }),
    [onClick, theme],
  );

  const handleClick = useCallback((event: SyntheticEvent) => onClick?.(event), [onClick]);

  return (
    <div role="presentation" className={badgeClassNames} onClick={handleClick}>
      <IconFont name={iconName} size={iconSize} />
      {content}
    </div>
  );
});
