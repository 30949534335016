interface IGestureConfig {
  label: string;
  code: string;
}

export enum CodeGesture {
  'ToggleLogger',
  'ClearLogger',
}

export const GESTURES_MAP: Record<CodeGesture, IGestureConfig> = {
  [CodeGesture.ToggleLogger]: {
    label: 'Toggle Logger',
    code: '12369',
  },
  [CodeGesture.ClearLogger]: {
    label: 'Clear Logger',
    code: '32147',
  },
};
