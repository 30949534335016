export const DEFAULT_PLAYERS_PAGINATION = {
  limit: 1000,
  page: 1,
};

export const DEFAULT_TOP_PLAYERS_PAGINATION = {
  limit: 100,
  page: 1,
};

export const PLAYERS_POSITIONS = ['ALL', 'PG', 'SG', 'SF', 'PF', 'C'];
