import { action, makeObservable, observable } from 'mobx';

import { BaseEntriesStore } from 'stores/base-entries/base-entries.store';

export abstract class AdvancedEntriesStore<
  Y,
  F,
  K extends { items: F[]; count: number },
> extends BaseEntriesStore<Y, K> {
  public meta: Maybe<Omit<K, 'items'>>;

  protected constructor(paginationLimit: number, hasUuid?: boolean) {
    super(paginationLimit, hasUuid);

    this.meta = null;

    makeObservable(this, { meta: observable, setMeta: action.bound });
  }

  public async retrieveNext(
    res: Promise<IResponse<K>>,
    adapter: <T>(res: T) => Maybe<Y>,
  ): Promise<void> {
    if (this.isLastPage || this.fetching) {
      this.setMeta(null);

      return;
    }

    const currentInstanceId = this.instanceId;

    this.setFetched(false);
    this.setFetching(true);

    const response: IResponse<K> = await res;

    if (currentInstanceId !== this.instanceId) {
      this.setMeta(null);

      return;
    }

    let meta: Maybe<Omit<K, 'items'>> = null;

    if (response.success) {
      if (!adapter) {
        return;
      }

      const { items: entries = [], ...rest } = response.data;

      meta = rest;

      if (entries.length >= this.paginationLimit) {
        this.setCurrentPage(this.currentPage + 1);
      } else {
        this.setIsLastPage(true);
      }

      const newEntries: Y[] = [];

      entries.map(adapter).forEach((maybeEntry) => {
        if (maybeEntry) {
          newEntries.push(maybeEntry);
        }
      });

      this.setEntries([...this.entries, ...newEntries]);

      if (entries.length >= response.data.count) {
        this.setIsLastPage(true);
      }
    } else {
      meta = null;

      this.setErrors(response.errors);
    }

    this.setFetching(false);
    this.setFetched(true);

    this.setMeta(meta);
  }

  public async forceRefresh(
    res: Promise<IResponse<K>>,
    adapter: <T, U>(res: T) => U,
    setPulledRefresher: (isPulledRefresher: boolean) => void,
  ): Promise<void> {
    if (this.fetching) {
      this.setMeta(null);
      setPulledRefresher(false);

      return;
    }

    const currentInstanceId = this.instanceId;

    this.setFetched(false);
    this.setFetching(true);
    this.setIsLastPage(false);

    const response: IResponse<K> = await res;

    if (currentInstanceId !== this.instanceId) {
      this.setMeta(null);
      this.setFetching(false);
      setPulledRefresher(false);

      return;
    }

    let meta: Maybe<Omit<K, 'items'>> = null;

    if (response.success) {
      if (!adapter) {
        this.setFetching(false);
        setPulledRefresher(false);
        return;
      }

      const { items: entries = [], ...rest } = response.data;

      meta = rest;

      this.setCurrentPage(2);

      if (entries.length >= response.data.count) {
        this.setIsLastPage(true);
      }

      this.setEntries([...(entries.map(adapter).filter(Boolean) as Array<Y>)]);
    } else {
      meta = null;

      this.setErrors(response.errors);
    }

    this.setFetched(true);
    this.setFetching(false);
    this.setMeta(meta);

    setPulledRefresher(false);
  }

  public setMeta(meta: Maybe<Omit<K, 'items'>>) {
    this.meta = meta;
  }
}
