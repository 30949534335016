import { FC, memo } from 'react';

import styles from './streamable-video.module.less';

interface IStreamableVideoProps {
  sourceUrl: string;
}

export const StreamableVideo: FC<IStreamableVideoProps> = memo(
  ({ sourceUrl }: IStreamableVideoProps) => {
    return (
      <div className={styles.Video}>
        <iframe
          title="Streamable video"
          className={styles.Video__Iframe}
          src={sourceUrl}
          allowFullScreen
          frameBorder="0"
        />
      </div>
    );
  },
);
