import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Form } from 'components/forms/form.component';
import { IForm } from 'components/forms/form.interface';
import { SearchInput } from 'components/ui/form-fields/search-input/search-input.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export interface ISearchFormData {
  searchPhrase: string;
}

interface ISearchFormProps extends Omit<IForm<ISearchFormData>, 'submitText'> {
  classNames?: string;
  rightIconName: IconFontName;
  placeholder?: string;
  isNeedForceInputFocus?: boolean;
  setIsNeedForceInputFocus?: (value: boolean) => void;
  disableFocusByDefault?: boolean;
}

export const SearchForm: FC<ISearchFormProps> = (props) => {
  const {
    isNeedForceInputFocus,
    setIsNeedForceInputFocus,
    initialValues,
    onSubmit,
    onBlur,
    classNames,
    value,
    rightIconName,
    placeholder = 'Search',
    onRightIconClick,
    disableFocusByDefault = true,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const triggerInputFocus = useCallback(() => {
    if (!isDesktopPlus) {
      inputRef.current?.focus();
    }
  }, [isDesktopPlus]);

  useEffect(() => {
    if (isNeedForceInputFocus) {
      triggerInputFocus();
      setIsNeedForceInputFocus?.(false);
    }
  }, [isNeedForceInputFocus, setIsNeedForceInputFocus, triggerInputFocus]);

  const localSubmit = useCallback(
    (data: ISearchFormData) => {
      onSubmit(data);
      onBlur?.();
      inputRef.current?.blur();
    },
    [onBlur, onSubmit],
  );

  const { control, handleSubmit } = useForm<ISearchFormData>({
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (!disableFocusByDefault) {
      triggerInputFocus();
    }
  }, [triggerInputFocus, disableFocusByDefault]);

  const rightIconProps = useMemo(() => {
    if (value?.searchPhrase.length) {
      return {
        name: rightIconName,
        onClick: onRightIconClick,
      };
    }

    return {
      onClick: onRightIconClick,
    };
  }, [onRightIconClick, rightIconName, value?.searchPhrase.length]);

  return (
    <Form className={classNames} onSubmit={handleSubmit(localSubmit)}>
      <Controller
        name="searchPhrase"
        control={control}
        render={({ field }) =>
          props.onChange ? (
            <SearchInput
              {...field}
              ref={inputRef}
              value={value?.searchPhrase || ''}
              id="searchPhrase"
              placeholder={placeholder}
              rightIconProps={rightIconProps}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              onChange={props.onChange}
            />
          ) : (
            <SearchInput
              {...field}
              id="searchPhrase"
              placeholder="Search"
              rightIconProps={{
                onClick: props.onRightIconClick,
              }}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
            />
          )
        }
      />
    </Form>
  );
};
