import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';

import {
  ARROW_WIDTH,
  CSS_VAR_BACKDROP_OPACITY,
  CSS_VAR_BACKDROP_VISIBILITY,
  CSS_VAR_CONTAINER,
  CSS_VAR_NAV_ARROW_DURATION,
  CSS_VAR_SIDEBAR_DURATION,
  CSS_VAR_TRANDING_PANEL,
  CSS_VAR_TRANDING_SIDEBAR_DURATION,
  MAX_BACKDROP_OPACITY,
  MAX_LEFT_SIDEBAR_WIDTH,
  SUBTABS_NAVIGATION_CLASS,
  SWIPE_HANDLE_IMPULSE,
  SWIPE_IMPULSE,
  TOUCH_IGNORE_CLASS,
} from 'configs/swipe-navigation.config';

import { BackdropType } from 'components/swipe-navigation/enums/backdrop-type.enum';
import { SidebarState } from 'components/swipe-navigation/enums/sidebar-state.enum';
import { SwipeDirection } from 'components/swipe-navigation/enums/swipe-direction.enum';
import { TouchEvents } from 'components/swipe-navigation/enums/touch-events.enum';

export const useSwipeNavigation = (
  containerRef: RefObject<HTMLDivElement>,
  isEnabledSwipeNavigation: boolean = false,
  isEnabledLeftArrow: boolean = false,
  isEnabledRightArrow: boolean = false,
  isEnabledLeftSidebar: boolean = false,
  isLeftSidebarOpen: boolean = false,
  isRightSidebarOpen: boolean = false,
  leftSidebarWidth: number = 0,
  rightSidebarWidth: number = 0,
) => {
  const [touchPosition, setTouchPosition] = useState(0);
  const [touchNameEvent, setTouchNameEvent] = useState<TouchEvents>(TouchEvents.NotDefined);

  const [startPosition, setStartPosition] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(0);

  const [navDirection, setNavDirection] = useState<SwipeDirection>(SwipeDirection.NotDefined);
  const [swipeDirection, setSwipeDirection] = useState<SwipeDirection>(SwipeDirection.NotDefined);
  const [sidebarState, setSidebarState] = useState<SidebarState>(SidebarState.NotDefined);
  const [isStartedTouch, setIsStartedTouch] = useState(false);

  const detectSwipeDirection = useCallback(
    (clientX: number) => {
      if (SwipeDirection.NotDefined === swipeDirection && clientX > currentPosition) {
        setSwipeDirection(SwipeDirection.SwipeLeft);
      }

      if (SwipeDirection.NotDefined === swipeDirection && clientX < currentPosition) {
        setSwipeDirection(SwipeDirection.SwipeRight);
      }
    },
    [swipeDirection, currentPosition],
  );

  const detectNavigateDirection = useCallback(() => {
    const position = currentPosition - startPosition;
    const fullLengthStep = SWIPE_HANDLE_IMPULSE + ARROW_WIDTH;

    if (
      SwipeDirection.SwipeLeft === swipeDirection &&
      position > fullLengthStep &&
      isEnabledLeftArrow
    ) {
      setNavDirection(SwipeDirection.SwipeLeft);
    }

    if (
      SwipeDirection.SwipeRight === swipeDirection &&
      position < -fullLengthStep &&
      isEnabledRightArrow
    ) {
      setNavDirection(SwipeDirection.SwipeRight);
    }
  }, [isEnabledLeftArrow, isEnabledRightArrow, currentPosition, startPosition, swipeDirection]);

  const detectSidebarState = useCallback(() => {
    if (SwipeDirection.SwipeLeft === swipeDirection && isRightSidebarOpen) {
      if (currentPosition - startPosition - SWIPE_IMPULSE > SWIPE_IMPULSE) {
        setSidebarState(SidebarState.RightSidebarClosed);
      } else {
        document.documentElement.style.setProperty(CSS_VAR_TRANDING_PANEL, '0');
      }

      return;
    }

    if (SwipeDirection.SwipeLeft === swipeDirection && isEnabledLeftSidebar && !isLeftSidebarOpen) {
      if (currentPosition - startPosition >= SWIPE_HANDLE_IMPULSE) {
        setSidebarState(SidebarState.LeftSidebarOpened);
      } else {
        document.documentElement.style.removeProperty(CSS_VAR_CONTAINER);
        document.documentElement.style.removeProperty(CSS_VAR_BACKDROP_VISIBILITY);
      }
    }

    if (SwipeDirection.SwipeRight === swipeDirection && isEnabledLeftSidebar && isLeftSidebarOpen) {
      if (
        currentPosition <= leftSidebarWidth - SWIPE_HANDLE_IMPULSE &&
        startPosition > leftSidebarWidth
      ) {
        setSidebarState(SidebarState.LeftSidebarClosed);
      } else {
        document.documentElement.style.setProperty(CSS_VAR_CONTAINER, MAX_LEFT_SIDEBAR_WIDTH);
      }
    }
  }, [
    currentPosition,
    startPosition,
    swipeDirection,
    leftSidebarWidth,
    isLeftSidebarOpen,
    isRightSidebarOpen,
    isEnabledLeftSidebar,
  ]);

  useEffect(() => {
    const container = containerRef.current;

    const isIgnoredTouchEvent = (event: TouchEvent) => {
      const target = event.target as HTMLElement;
      const ignoreTarget = target.closest(`.${TOUCH_IGNORE_CLASS}`);
      const subTabsTarget = target.closest(`.${SUBTABS_NAVIGATION_CLASS}`);
      const hasSubTabsClass = container?.classList.contains(SUBTABS_NAVIGATION_CLASS);

      return (subTabsTarget && !hasSubTabsClass) || !!ignoreTarget;
    };

    const handleTouchStart = (event: TouchEvent) => {
      if (isIgnoredTouchEvent(event)) return;

      setTouchNameEvent(TouchEvents.TouchStart);
      setTouchPosition(event.touches[0].clientX);
    };

    const handleTouchMove = (event: TouchEvent) => {
      if (isIgnoredTouchEvent(event)) return;

      setTouchNameEvent(TouchEvents.TouchMove);
      setTouchPosition(event.touches[0].clientX);
    };

    const handleTouchEnd = (event: TouchEvent) => {
      if (isIgnoredTouchEvent(event)) return;

      setTouchNameEvent(TouchEvents.TouchEnd);
    };

    if (isEnabledSwipeNavigation) {
      container?.addEventListener('touchstart', handleTouchStart, { passive: false });
      container?.addEventListener('touchmove', handleTouchMove, { passive: false });
      container?.addEventListener('touchend', handleTouchEnd, { passive: false });
    }

    return () => {
      if (isEnabledSwipeNavigation) {
        container?.removeEventListener('touchstart', handleTouchStart);
        container?.removeEventListener('touchmove', handleTouchMove);
        container?.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [containerRef, isEnabledSwipeNavigation]);

  useEffect(() => {
    if (TouchEvents.TouchStart === touchNameEvent) {
      setStartPosition(touchPosition);
      setCurrentPosition(touchPosition);
      setSidebarState(SidebarState.NotDefined);

      document.documentElement.style.setProperty(CSS_VAR_NAV_ARROW_DURATION, '0ms');
      document.documentElement.style.setProperty(CSS_VAR_SIDEBAR_DURATION, '0ms');
      document.documentElement.style.setProperty(CSS_VAR_TRANDING_SIDEBAR_DURATION, '0ms');

      return;
    }

    if (TouchEvents.TouchMove === touchNameEvent) {
      detectSwipeDirection(touchPosition);
      setCurrentPosition(touchPosition);

      return;
    }

    if (TouchEvents.TouchEnd === touchNameEvent) {
      detectSidebarState();
      detectNavigateDirection();
      setSwipeDirection(SwipeDirection.NotDefined);

      setIsStartedTouch(false);

      document.documentElement.style.removeProperty(CSS_VAR_NAV_ARROW_DURATION);
      document.documentElement.style.removeProperty(CSS_VAR_SIDEBAR_DURATION);
      document.documentElement.style.removeProperty(CSS_VAR_TRANDING_SIDEBAR_DURATION);
    }
  }, [
    touchPosition,
    touchNameEvent,
    detectSwipeDirection,
    detectSidebarState,
    detectNavigateDirection,
  ]);

  const navArrowLeftPosition = useMemo<number>(() => {
    const position = ARROW_WIDTH - (currentPosition - (startPosition + SWIPE_IMPULSE));

    if (-position <= -ARROW_WIDTH) {
      return -ARROW_WIDTH;
    }

    if (position >= 0) {
      setIsStartedTouch(true);
      return Math.round(-position);
    }

    return 0;
  }, [currentPosition, startPosition]);

  const navArrowRightPosition = useMemo<number>(() => {
    const position = ARROW_WIDTH - (startPosition - currentPosition - SWIPE_IMPULSE);

    if (position >= ARROW_WIDTH) {
      return ARROW_WIDTH;
    }

    if (position >= 0) {
      setIsStartedTouch(true);
      return Math.round(position);
    }

    return 0;
  }, [currentPosition, startPosition]);

  const leftSidebarPosition = useMemo<Maybe<number>>(() => {
    if (SwipeDirection.SwipeLeft === swipeDirection && !isLeftSidebarOpen) {
      const position = currentPosition - startPosition - SWIPE_IMPULSE;

      if (position >= leftSidebarWidth) {
        return leftSidebarWidth;
      }

      if (position >= 0) {
        setIsStartedTouch(true);
        return Math.round(position);
      }
    }

    if (SwipeDirection.SwipeRight === swipeDirection && isLeftSidebarOpen) {
      const position = leftSidebarWidth + SWIPE_IMPULSE - (startPosition - currentPosition);

      if (leftSidebarWidth <= position || startPosition <= leftSidebarWidth) {
        return leftSidebarWidth;
      }

      if (position >= 0) {
        setIsStartedTouch(true);
        return Math.round(position);
      }
    }

    return null;
  }, [startPosition, currentPosition, leftSidebarWidth, swipeDirection, isLeftSidebarOpen]);

  const rightSidebarPosition = useMemo<Maybe<number>>(() => {
    if (SwipeDirection.SwipeLeft === swipeDirection && isRightSidebarOpen) {
      const position = currentPosition - startPosition - SWIPE_IMPULSE;

      if (position >= rightSidebarWidth) {
        return rightSidebarWidth;
      }

      if (position >= 0) {
        setIsStartedTouch(true);
        return Math.round(position);
      }
    }

    return null;
  }, [startPosition, currentPosition, rightSidebarWidth, swipeDirection, isRightSidebarOpen]);

  const handleBackdropOpacity = useCallback(
    (position: number, sidebarWidth: number, backdropType: BackdropType) => {
      let percentScreen: number = 0;

      if (backdropType === BackdropType.SidebarLeft) {
        percentScreen = (position * 100) / sidebarWidth;
      }

      if (backdropType === BackdropType.SidebarRight) {
        percentScreen = ((sidebarWidth - position) * 100) / sidebarWidth;
      }

      const percentOpacity = Math.floor(MAX_BACKDROP_OPACITY * percentScreen) / 100;

      if (percentOpacity <= 0) {
        return 0;
      }

      if (percentOpacity >= MAX_BACKDROP_OPACITY) {
        return MAX_BACKDROP_OPACITY;
      }

      return percentOpacity;
    },
    [],
  );

  useEffect(() => {
    if (isEnabledLeftSidebar && !isRightSidebarOpen && leftSidebarPosition !== null) {
      const backdropOpacity = handleBackdropOpacity(
        leftSidebarPosition,
        leftSidebarWidth,
        BackdropType.SidebarLeft,
      );

      document.documentElement.style.setProperty(CSS_VAR_BACKDROP_VISIBILITY, 'visible');
      document.documentElement.style.setProperty(CSS_VAR_CONTAINER, `${leftSidebarPosition}px`);
      document.documentElement.style.setProperty(CSS_VAR_BACKDROP_OPACITY, `${backdropOpacity}`);
    }
  }, [
    isEnabledLeftSidebar,
    isRightSidebarOpen,
    leftSidebarPosition,
    leftSidebarWidth,
    handleBackdropOpacity,
  ]);

  useEffect(() => {
    if (isRightSidebarOpen && rightSidebarPosition !== null) {
      const backdropOpacity = handleBackdropOpacity(
        rightSidebarPosition,
        rightSidebarWidth,
        BackdropType.SidebarRight,
      );

      document.documentElement.style.setProperty(
        CSS_VAR_TRANDING_PANEL,
        `${rightSidebarPosition}px`,
      );
      document.documentElement.style.setProperty(CSS_VAR_BACKDROP_OPACITY, `${backdropOpacity}`);
    }
  }, [isRightSidebarOpen, rightSidebarWidth, rightSidebarPosition, handleBackdropOpacity]);

  return {
    isStartedTouch,
    navDirection,
    sidebarState,
    setNavDirection,
    navArrowLeftPosition,
    navArrowRightPosition,
    swipeDirection,
  };
};
