export enum IoMessage {
  Connect = 'connect',
  ConnectError = 'connect_error',
  Close = 'close',
  Disconnect = 'disconnect',
  Error = 'error',
  ReconnectError = 'reconnect_error',

  GameStatisticsDetailedUpdated = 'game-statistics:detailed-updated-v2',
  GameStatisticsMiniUpdated = 'game-statistics:mini-updated-v2',

  Register = 'user:register',
}
