import { forwardRef } from 'react';

import { Input } from 'components/ui/form-fields/input/input.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import { IHookFormInput } from '../hook-form-input.interface';

import styles from './search-input.module.less';

export const SearchInput = forwardRef<HTMLInputElement, IHookFormInput>(
  (props: IHookFormInput, ref) => {
    const {
      readOnly,
      id,
      name,
      disabled = false,
      placeholder = '',
      value = '',
      rightIconProps,
    } = props;

    return (
      <div className={styles.SearchInput}>
        <Input
          {...{ readOnly, id, name, disabled, placeholder, ref, value }}
          type="search"
          enterKeyHint="search"
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          rightIconProps={{
            name: rightIconProps?.name,
            size: IconFontSize.Small,
            onClick: rightIconProps?.onClick,
          }}
        />
        <IconFont name={IconFontName.Search} size={IconFontSize.Small} />
      </div>
    );
  },
);
