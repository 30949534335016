import { memo, ReactNode, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { SelectedHeaderTabType } from 'stores/layout/types/layout-entities.type';

import { LEFT_SIDEBAR_ELEMENT, RIGHT_SIDEBAR_ELEMENT } from 'configs/controls.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { SUBTABS_NAVIGATION_CLASS } from 'configs/swipe-navigation.config';
import { HOME_FEED } from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';
import { useSwipeNavigation } from 'hooks/use-swipe-navigation';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { ITab } from 'components/ui/tabs/tabs.component';

import { SidebarState } from './enums/sidebar-state.enum';
import { SwipeDirection } from './enums/swipe-direction.enum';
import { SwipeNavigationType } from './enums/swipe-navigation-type.enum';

import styles from './swipe-navigation.module.less';

interface ISwipeNavigationProps {
  tabs: Maybe<ITab[]>;
  children: ReactNode;
  isDisabledSwipeNavigation?: boolean;
  isAllowedSwipeNavBack?: boolean;
  isLeftSidebarOpen?: boolean;
  isRightSidebarOpen?: boolean;
  activeTabClick?: string;
  swipeNavType: SwipeNavigationType;
  onTabClick?: (action: BarActionType) => void;
  setLeftSidebarOpen?: (isLeftSidebarOpen: boolean) => void;
  setRightSidebarOpen?: (tabName: SelectedHeaderTabType) => void;
  setDisabledScroll?: (isDisabledScroll: boolean) => void;
}

export const SwipeNavigation = memo((props: ISwipeNavigationProps) => {
  const {
    tabs,
    children,
    isDisabledSwipeNavigation = false,
    isAllowedSwipeNavBack = false,
    isLeftSidebarOpen = false,
    isRightSidebarOpen = false,
    activeTabClick,
    swipeNavType,
    onTabClick,
    setLeftSidebarOpen,
    setRightSidebarOpen,
    setDisabledScroll,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { isNativeApp } = useMainProvider();
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [isEnabledLeftSidebar, setIsEnabledLeftSidebar] = useState(false);
  const [isEnabledLeftArrow, setIsEnabledLeftArrow] = useState(false);
  const [isEnabledRightArrow, setIsEnabledRightArrow] = useState(false);

  const [leftSidebarWidth, setLeftSidebarWidth] = useState(0);
  const [rightSidebarWidth, setRightSidebarWidth] = useState(0);

  const isEnabledSwipeNavigation = useMemo(() => {
    return !isDesktopPlus && isNativeApp;
  }, [isDesktopPlus, isNativeApp]);

  const {
    navArrowLeftPosition,
    navArrowRightPosition,
    swipeDirection,
    navDirection,
    isStartedTouch,
    sidebarState,
    setNavDirection,
  } = useSwipeNavigation(
    containerRef,
    isEnabledSwipeNavigation,
    isEnabledLeftArrow,
    isEnabledRightArrow,
    isEnabledLeftSidebar,
    isLeftSidebarOpen,
    isRightSidebarOpen,
    leftSidebarWidth,
    rightSidebarWidth,
  );

  const activeTabIndex = useMemo<Maybe<number>>(() => {
    const activeSearchTab = tabs?.find((tab) => {
      if (BarAction.Link === tab.action.type) {
        return location.pathname.includes(tab.action.path);
      }

      if (BarAction.Click === tab.action.type) {
        return tab.action.payload === activeTabClick;
      }

      return null;
    });

    if (tabs && activeSearchTab) {
      const tabIndex = tabs.indexOf(activeSearchTab);

      if (tabIndex === -1) {
        return null;
      }

      return tabIndex;
    }

    return null;
  }, [tabs, location, activeTabClick]);

  useEffect(() => {
    if (SwipeDirection.SwipeLeft === navDirection && isAllowedSwipeNavBack) {
      if (location.key === 'default') {
        navigate(HOME_FEED);
      } else {
        navigate(-1);
      }
    }
  }, [isAllowedSwipeNavBack, navDirection, location, navigate]);

  useEffect(() => {
    if (SwipeDirection.SwipeLeft === navDirection && activeTabIndex !== null) {
      const prevTab = tabs && tabs[activeTabIndex - 1];

      if (prevTab && BarAction.Link === prevTab.action.type) {
        navigate(prevTab.action.path);
      }

      if (prevTab && BarAction.Click === prevTab.action.type) {
        onTabClick?.(prevTab.action);
      }
    }

    if (SwipeDirection.SwipeRight === navDirection && activeTabIndex !== null) {
      const nextTab = tabs && tabs[activeTabIndex + 1];

      if (nextTab && BarAction.Link === nextTab.action.type && !isLeftSidebarOpen) {
        navigate(nextTab.action.path);
      }

      if (nextTab && BarAction.Click === nextTab.action.type) {
        onTabClick?.(nextTab.action);
      }
    }

    setNavDirection(SwipeDirection.NotDefined);
  }, [
    isLeftSidebarOpen,
    tabs,
    activeTabIndex,
    navDirection,
    navigate,
    setNavDirection,
    onTabClick,
  ]);

  useEffect(() => {
    if (!isEnabledSwipeNavigation) {
      return;
    }

    if (isRightSidebarOpen) {
      setIsEnabledRightArrow(false);
      setIsEnabledLeftArrow(false);
      return;
    }

    if (!tabs || isAllowedSwipeNavBack) {
      setIsEnabledLeftArrow(isAllowedSwipeNavBack);
      setIsEnabledRightArrow(false);
      setIsEnabledLeftSidebar(false);
      return;
    }

    const isNotFirstPage = activeTabIndex !== 0 && !isLeftSidebarOpen;
    const isNotLastPage = activeTabIndex !== tabs.length - 1 && !isLeftSidebarOpen;

    setIsEnabledLeftArrow(isNotFirstPage);
    setIsEnabledRightArrow(isNotLastPage);

    if (swipeNavType === SwipeNavigationType.TopLevel) {
      const isFirstPage = activeTabIndex === 0;
      setIsEnabledLeftSidebar(isFirstPage || isLeftSidebarOpen);
    }
  }, [
    tabs,
    activeTabIndex,
    isLeftSidebarOpen,
    isRightSidebarOpen,
    swipeNavType,
    isAllowedSwipeNavBack,
    isEnabledSwipeNavigation,
  ]);

  useEffect(() => {
    if (SidebarState.LeftSidebarOpened === sidebarState) {
      setLeftSidebarOpen?.(true);
    }

    if (SidebarState.LeftSidebarClosed === sidebarState) {
      setLeftSidebarOpen?.(false);
    }

    if (SidebarState.RightSidebarClosed === sidebarState) {
      setRightSidebarOpen?.(null);
    }
  }, [sidebarState, setLeftSidebarOpen, setRightSidebarOpen]);

  useEffect(() => {
    setDisabledScroll?.(isStartedTouch);
  }, [isStartedTouch, setDisabledScroll]);

  useLayoutEffect(() => {
    const leftSidebar = document.getElementById(LEFT_SIDEBAR_ELEMENT);
    const rightSidebar = document.getElementById(RIGHT_SIDEBAR_ELEMENT);

    if (leftSidebar && isEnabledSwipeNavigation) {
      setLeftSidebarWidth(leftSidebar.clientWidth);
    }

    if (rightSidebar && isEnabledSwipeNavigation) {
      setRightSidebarWidth(rightSidebar.clientWidth);
    }
  }, [isEnabledSwipeNavigation]);

  const navigationLeftArrowStyles = useMemo(() => {
    if (SwipeDirection.SwipeLeft === swipeDirection && isEnabledLeftArrow) {
      return {
        transform: `translateX(${navArrowLeftPosition}px) translateZ(0px)`,
      };
    }

    return undefined;
  }, [isEnabledLeftArrow, swipeDirection, navArrowLeftPosition]);

  const navigationRightArrowStyles = useMemo(() => {
    if (SwipeDirection.SwipeRight === swipeDirection && isEnabledRightArrow) {
      return {
        transform: `translateX(${navArrowRightPosition}px) translateZ(0px)`,
      };
    }

    return undefined;
  }, [isEnabledRightArrow, swipeDirection, navArrowRightPosition]);

  const classSwipeNavigationClass = useMemo(
    () =>
      cn(styles.SwipeNavigation, {
        [styles['SwipeNavigation--disabled']]: isDisabledSwipeNavigation,
        [SUBTABS_NAVIGATION_CLASS]: swipeNavType === SwipeNavigationType.SubTabsLevel,
      }),
    [isDisabledSwipeNavigation, swipeNavType],
  );

  return (
    <div className={classSwipeNavigationClass} ref={containerRef}>
      {isEnabledLeftArrow && (
        <div className={styles.SwipeNavigation__LeftArrow} style={navigationLeftArrowStyles}>
          <IconFont name={IconFontName.ArrowLeft} size={IconFontSize.Big} />
        </div>
      )}
      {children}
      {isEnabledRightArrow && (
        <div className={styles.SwipeNavigation__RightArrow} style={navigationRightArrowStyles}>
          <IconFont name={IconFontName.ArrowRight} size={IconFontSize.Big} />
        </div>
      )}
    </div>
  );
});
