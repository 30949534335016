import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const GameEvents = {
  onePointShot: '',
  twoPointsShot: '',
  threePointsShot: '',
  miss: IconFontName.EventMiss,
  turnover: IconFontName.EventTurnover,
  quarterEnd: IconFontName.EventQuarterEnd,
  gameEnd: IconFontName.EventGameEnd,
  gameStart: IconFontName.EventGameStart,
  foul: IconFontName.EventFoul,
  timeout: IconFontName.EventTimeout,
  gainPossession: IconFontName.EventGainPosession,
  rebound: IconFontName.EventRebound,
};

export const POINTS_EVENTS = ['onePointShot', 'twoPointsShot', 'threePointsShot'];
