import { useCallback, useEffect, useState } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { ISingleCategoryRecord } from 'stores/feed-filters/interfaces/feed-filters.interface';
import { TeamsStore } from 'stores/teams/teams.store';

import { SCROLL_TOP_ELEMENT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { FiltersIonBottomSheet } from 'components/bottom-sheet/filters-ion-bottom-sheet/filters-ion-bottom-sheet.component';
import { FeedFiltersModal } from 'components/modals/feed-filters-modal/feed-filters-modal.component';

export const FeedFiltersContainer = observer(() => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const feedFiltersStore = useInjection<FeedFiltersStore>(TYPES.FeedFiltersStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);

  const [localFilters, setLocalFilters] = useState<ISingleCategoryRecord[]>([]);

  useEffect(() => {
    if (
      feedFiltersStore.allTeamsFilters.length &&
      feedFiltersStore.currentFilterKey &&
      feedFiltersStore.isFilterOpen
    ) {
      const filters = feedFiltersStore.allTeamsFilters.find(
        (item) => item.key === feedFiltersStore.currentFilterKey,
      );

      if (filters) {
        setLocalFilters(filters.items);
      } else {
        setLocalFilters([]);
      }
    }
  }, [
    feedFiltersStore.allTeamsFilters,
    feedFiltersStore.currentFilterKey,
    feedFiltersStore.isFilterOpen,
  ]);

  const handleApplyFilters = useCallback(
    (newFilters: ISingleCategoryRecord[]) => {
      feedFiltersStore.applyNewFilters(newFilters);
    },
    [feedFiltersStore],
  );

  const handleCloseFilters = useCallback(() => {
    if (localFilters) {
      handleApplyFilters(localFilters);
    }

    feedFiltersStore.setIsFilterOpen(false);
  }, [feedFiltersStore, handleApplyFilters, localFilters]);

  const handleCloseModal = useCallback(() => {
    feedFiltersStore.setIsFilterOpen(false);
  }, [feedFiltersStore]);

  const handleToggleCategory = useCallback(
    (categoryId: string) => {
      if (localFilters) {
        const newFilters = localFilters.map((category) => {
          if (category.uuid === categoryId) {
            return {
              ...category,
              selected: !category.selected,
            };
          }

          return category;
        });

        setLocalFilters(newFilters);
      }
    },
    [setLocalFilters, localFilters],
  );

  const handleResetFilters = useCallback(() => {
    if (localFilters) {
      const newFilters = localFilters.map((category) => {
        const newCategoryItems = category.tags.map((item) => {
          if (category.disabled) {
            return { ...item, selected: false };
          }

          return {
            ...item,
            selected: true,
          };
        });

        if (category.disabled) {
          return { ...category, tag: newCategoryItems, selected: false };
        }

        return {
          ...category,
          tags: newCategoryItems,
          selected: true,
        };
      });

      setLocalFilters(newFilters);
    }
  }, [setLocalFilters, localFilters]);

  const handleToggleCategoryItem = useCallback(
    (categoryId: string, tagId: string) => {
      if (localFilters) {
        const newFilters = localFilters.map((category) => {
          if (category.uuid === categoryId) {
            const currentCategory = {
              ...category,
            };

            const newCategoryItems = currentCategory.tags.map((item) => {
              if (item.uuid === tagId) {
                return {
                  ...item,
                  selected: !item.selected,
                };
              }

              return item;
            });

            return {
              ...category,
              tags: newCategoryItems,
            };
          }

          return category;
        });

        setLocalFilters(newFilters);
      }
    },
    [setLocalFilters, localFilters],
  );

  const handleChangeFeeds = useCallback(
    (value: FeedTypes) => {
      feedFiltersStore.setActiveFeed(value);

      const topElement = document.getElementById(SCROLL_TOP_ELEMENT);
      topElement?.scrollIntoView();
    },
    [feedFiltersStore],
  );

  if (isDesktopPlus && feedFiltersStore.isFilterOpen) {
    return (
      <FeedFiltersModal
        isShowFeedsToggle={!teamsStore.teamId}
        onResetFilters={handleResetFilters}
        filters={localFilters}
        onToggleCategoryItem={handleToggleCategoryItem}
        onToggleCategory={handleToggleCategory}
        onApplyFilters={handleCloseFilters}
        visible={feedFiltersStore.isFilterOpen}
        onClose={handleCloseModal}
        onChangeFeeds={handleChangeFeeds}
      />
    );
  }

  return (
    <FiltersIonBottomSheet
      visible={feedFiltersStore.isFilterOpen}
      hasTeamId={!!teamsStore.teamId}
      filters={localFilters}
      onCloseFilters={handleCloseFilters}
      onResetFilters={handleResetFilters}
      onChangeFeeds={handleChangeFeeds}
      onToggleCategory={handleToggleCategory}
      onToggleCategoryItem={handleToggleCategoryItem}
    />
  );
});
