import { IAboutData } from 'containers/team-profile/team-profile-overview/team-profile-overview.container';

import { IPlayerDetails } from '../interfaces/player-profile.interfaces';

export function playerAboutAdapter(
  playerDetails: IPlayerDetails,
  isDesktopPlus: boolean,
): IAboutData[] {
  const {
    heightFt,
    heightMeters,
    weightLbs,
    weightKg,
    nationality,
    college,
    age,
    birthDateLong,
    birthDateShort,
    draft,
    experienceYears,
  } = playerDetails;

  return [
    {
      title: 'Height',
      content: !!heightFt && !!heightMeters ? `${heightFt}" (${heightMeters}m)` : null,
    },
    {
      title: 'Weight',
      content:
        !!weightLbs && !!weightKg ? `${weightLbs.toFixed(0)} lbs (${weightKg.toFixed(0)}kg)` : null,
    },
    {
      title: 'Country',
      content: nationality,
    },
    {
      title: 'College',
      content: college,
    },
    {
      title: 'Age',
      content: age ? `${age} years` : null,
    },
    {
      title: 'Birthday',
      content: isDesktopPlus ? birthDateLong : birthDateShort,
    },
    {
      title: 'Draft',
      content: draft,
    },
    {
      title: 'Experience',
      content: experienceYears ? `${experienceYears} years` : null,
    },
  ];
}
