import { ISeasonResponse } from 'services/seasons/interfaces/season-response.interface';

import { ISeason } from '../interfaces/season.interface';

export const seasonAdapter = (seasonResponse: ISeasonResponse): ISeason => {
  const { id, label, is_default: isDefault, key, type } = seasonResponse;

  return {
    id,
    label,
    isDefault,
    key,
    type,
  };
};
