import { base64ToFile } from 'helpers/base64-to-file.util';

const OFFSET_STEP = 2;
const MARKER_SOI = 0xffd8;
const MARKER_APP1 = 0xffe1;
const MARKER_BYTES_IMAGE = 0xff00;
const EXIF_HEADER_MAGIC = 0x45786966;
const TAG_ORIENTATION = 0x0112;
const APP1_HEADER_SIZE = 8;
const BYTE_ORDER_LITTLE_ENDIAN = 0x4949;
const MAX_BYTES_TO_READ = 64 * 1024;

export const getOrientationFromBase64 = (
  base64String: string,
  format: string,
  callback: (orientation: number) => void,
) => {
  const file = base64ToFile(base64String, format);

  const reader = new FileReader();

  reader.onload = (event: ProgressEvent<FileReader>) => {
    const view = new DataView(<ArrayBuffer>event.target?.result);

    if (view.getUint16(0, false) !== MARKER_SOI) {
      return callback(-OFFSET_STEP);
    }

    const length = view.byteLength;

    let offset = OFFSET_STEP;

    while (offset < length) {
      const marker = view.getUint16(offset, false);

      offset += OFFSET_STEP;

      if (marker === MARKER_APP1) {
        const definedOffset = offset + OFFSET_STEP;

        if (view.getUint32(definedOffset, false) !== EXIF_HEADER_MAGIC) {
          return callback(-1);
        }

        const little = view.getUint16((offset += 6), false) === BYTE_ORDER_LITTLE_ENDIAN;
        offset += view.getUint32(offset + 4, little);

        const tags = view.getUint16(offset, little);
        offset += OFFSET_STEP;

        for (let i = 0; i < tags; i += 1) {
          if (view.getUint16(offset + i * APP1_HEADER_SIZE, little) === TAG_ORIENTATION) {
            return callback(view.getUint16(offset + i * APP1_HEADER_SIZE + 8, little));
          }
        }
      } else if ((marker || MARKER_BYTES_IMAGE) !== MARKER_BYTES_IMAGE) {
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }

    return callback(-1);
  };

  reader.readAsArrayBuffer(file.slice(0, MAX_BYTES_TO_READ));
};
