import { useCallback } from 'react';

import { isPositionsTypeValue } from 'stores/player/utils/is-positions-type-value';
import { PositionsType } from 'stores/teams-stats/types/positions-type.type';

import { PLAYER_POSITION_FILTER_OPTIONS } from 'components/team-stats/team-stats.config';
import { Select } from 'components/ui/form-fields/select/select.component';
import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';

import styles from './team-stats-filters.module.less';

interface ITeamStatsFiltersProps {
  seasonsOptions: ISelectOption[];
  seasonId: Maybe<string>;
  position: PositionsType;
  onPositionChange: (type: PositionsType) => void;
  onSeasonIdChange: (seasonId: string) => void;
}

export const TeamStatsFilters = (props: ITeamStatsFiltersProps) => {
  const { seasonsOptions, seasonId, position, onPositionChange } = props;

  const handlePositionChange = useCallback(
    (value: string) => {
      if (isPositionsTypeValue(value)) {
        onPositionChange(value);
      }
    },
    [onPositionChange],
  );

  return (
    <div className={styles.TeamStatsFilters}>
      <Select
        id="team_stats_season"
        name="TeamStatsSeason"
        options={seasonsOptions}
        value={seasonId || ''}
        onChange={props.onSeasonIdChange}
      />
      <Select
        id="player_position"
        name="PlayerPosition"
        options={PLAYER_POSITION_FILTER_OPTIONS}
        value={position}
        onChange={handlePositionChange}
      />
    </div>
  );
};
