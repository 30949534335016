import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';

import { NotificationsStore } from '../notifications/notifications.store';

@injectable()
export class ApiConnectedStore extends NotificationsStore {
  public fetched: boolean;

  public fetching: boolean;

  constructor() {
    super();

    this.fetched = false;

    this.fetching = false;

    makeObservable(this, {
      fetched: observable,
      fetching: observable,

      setFetched: action.bound,
      setFetching: action.bound,
    });
  }

  public setFetched(fetched: boolean): void {
    this.fetched = fetched;
  }

  public setFetching(fetching: boolean): void {
    this.fetching = fetching;
  }

  public reset() {
    super.reset();

    this.setFetched(false);
    this.setFetching(false);
  }
}
