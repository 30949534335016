// @ts-nocheck
// /TS No Check is used to pass custom props to custom components, according to this example from react-select documentation https://codesandbox.io/s/xxl6n9?module=/example.tsx

import { FC, useMemo } from 'react';
import { components, ValueContainerProps } from 'react-select';

import { ICheckboxItem } from 'components/ui/form-fields/multiple-choose-checkboxes/multiple-choose-checkboxes.component';
import { useResponsive } from 'hooks/use-responsive';
import { MIN_TABLET_WIDTH } from 'configs/responsive.configs';

import styles from './checkbox-select-value-container.module.less';

interface ICheckboxSelectValueContainerProps extends ValueContainerProps<ICheckboxItem, true> {}

export const CheckboxSelectValueContainer: FC<ICheckboxSelectValueContainerProps> = ({
  children,
  selectProps,
  ...rest
}: ICheckboxSelectValueContainerProps) => {
  const { optionsCount } = selectProps;
  const [isTabletPlus] = useResponsive([MIN_TABLET_WIDTH]);

  const countText = useMemo(
    () => (isTabletPlus ? `+${optionsCount}` : optionsCount),
    [isTabletPlus, optionsCount],
  );

  return (
    <components.ValueContainer selectProps={selectProps} {...rest}>
      <div className={styles.ValueContainer}>{children}</div>
      {optionsCount > 0 && <div className={styles.Count}>{countText}</div>}
    </components.ValueContainer>
  );
};
