import { FC, memo } from 'react';

import {
  RichTextContentTypes,
  RichTextPreview,
} from '../ui/rich-text-preview/rich-text-preview.component';

interface IPolicyProps {
  content: string;
}

export const Policy: FC<IPolicyProps> = memo((props: IPolicyProps) => {
  const { content } = props;

  return (
    <RichTextPreview
      id="policy"
      editorState={content}
      editorType={RichTextContentTypes.FullHeight}
    />
  );
});
