export const BOX_STATS_LIMITS = {
  player: {
    low: 25,
    high: 200,
  },
  team: {
    low: 10,
    high: 20,
  },
};
