import cn from 'classnames';
import { FC, memo, PropsWithChildren, ReactNode, useMemo } from 'react';

import styles from './row.module.less';

export enum RowLayout {
  MainContent,
  MainContentExtended,
  Regular,
  FullWidth,
}

interface IRowProps {
  layout?: RowLayout;
  withSpacing?: boolean;
  children: ReactNode;
}

export const Row: FC<IRowProps> = memo((props: PropsWithChildren<IRowProps>) => {
  const { layout = RowLayout.Regular, withSpacing = true, children } = props;

  const classNames = useMemo<string>(
    () =>
      cn(styles.Row, {
        [styles['Row--main-content']]: layout === RowLayout.MainContent,
        [styles['Row--main-content-extended']]: layout === RowLayout.MainContentExtended,
        [styles['Row--regular']]: layout === RowLayout.Regular,
        [styles['Row--no-spacing']]: !withSpacing,
      }),
    [layout, withSpacing],
  );

  return (
    <div className={styles.RowWrapper}>
      <div className={classNames}>{children}</div>
    </div>
  );
});
