import { FC } from 'react';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { PlayerRankingsContainer } from 'containers/player-rankings/player-rankings.container';
import { WelcomePopupRankingsContainer } from 'containers/welcome-popup/welcome-popup-rankings.container';

import { useEnableRefresher } from 'hooks/use-enable-refresher';
import { useMobileLayoutStructure } from 'hooks/use-mobile-layout-structure';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const PlayerRankingsRoute: FC = () => {
  useMobileLayoutStructure({
    isHiddenMobileSidebar: true,
  });

  useEnableRefresher();

  return (
    <PageWrapperContainer>
      <WelcomePopupRankingsContainer />
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <PlayerRankingsContainer />
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
