import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import {
  ISearchPlayerItem,
  ISearchTeamItem,
  SearchResultType,
} from 'stores/search/interfaces/search.interface';

import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useTeamClick } from './use-team-click';

export const useSearchItemClick = () => {
  const handleTeamClick = useTeamClick(paths.TEAM);
  const navigate = useNavigate();

  const handleItemClick = useCallback(
    (options: ISearchTeamItem | ISearchPlayerItem) => {
      if (options.type === SearchResultType.TEAM) {
        handleTeamClick(options.teamId);
      } else {
        navigate(
          getPath(paths.PLAYER_PROFILE, {
            [paths.PLAYER_SLUG_PARAM]: options.playerSlug,
          }),
        );
      }
    },
    [handleTeamClick, navigate],
  );

  return handleItemClick;
};
