import { useEffect, useRef } from 'react';
import { useInjection } from 'inversify-react';

import { LayoutStore } from 'stores/layout/layout.store';
import { LayoutEntitiesType } from 'stores/layout/types/layout-entities.type';

import { TYPES } from 'configs/di-types.config';
import { getCount } from 'helpers/get-count.util';

export const useLayoutEntity = (entity: LayoutEntitiesType) => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const idRef = useRef(getCount());

  useEffect(() => {
    const currentIdRef = idRef.current;

    layoutStore.addRecord(entity, currentIdRef);

    return () => {
      layoutStore.removeRecord(currentIdRef);
    };
  }, [entity, layoutStore]);
};
