import { memo, useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { v4 } from 'uuid';

import { EditorTheme } from 'components/editor/components/base-editor/base-editor.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import { IPollAnswer, IPollCreateData } from './interfaces/poll.interface';
import { PollAnswerControl } from './poll-option-input/poll-option-input.component';

import styles from './poll-form.module.less';

const MAX_ANSWERS_AMOUNT = 4;

const pollInitOptions: IPollAnswer[] = [
  {
    id: v4(),
    title: '',
    order: 0,
  },
  {
    id: v4(),
    title: '',
    order: 1,
  },
];

export interface IPollFormProps {
  theme: EditorTheme;
  onSetAttachedPoll: (pollData: IPollCreateData) => void;
}

export const PollForm = memo((props: IPollFormProps) => {
  const { theme, onSetAttachedPoll } = props;

  const [pollAnswers, setPollAnswers] = useState<IPollAnswer[]>(pollInitOptions);

  const pollFormClasses = useMemo(
    () =>
      cn(styles.PollForm, {
        [styles['PollForm--bottom-sheet-theme']]: theme === EditorTheme.BottomSheet,
      }),
    [theme],
  );

  const handleAddOption = useCallback(() => {
    setPollAnswers((currentPollState) => {
      const order =
        currentPollState.reduce((prev, cur) => (cur.order > prev.order ? cur : prev)).order + 1;

      return [...currentPollState, { id: v4(), title: '', order }];
    });
  }, []);

  const handleAnswerTextChange = useCallback((answer: IPollAnswer) => {
    setPollAnswers((currentState) => {
      const updatedAnswers = currentState.map((option) =>
        option.id === answer.id ? answer : option,
      );

      return updatedAnswers;
    });
  }, []);

  const handleRemove = useCallback(
    (id: string) => {
      const updatedState = pollAnswers
        .filter((answer) => answer.id !== id)
        .map((answer, index) => ({ ...answer, order: index }));

      setPollAnswers(updatedState);
      onSetAttachedPoll({ type: 'quiz', options: updatedState });
    },
    [pollAnswers, onSetAttachedPoll],
  );

  const handlePollUpdate = useCallback(() => {
    onSetAttachedPoll({ type: 'quiz', options: pollAnswers });
  }, [pollAnswers, onSetAttachedPoll]);

  const answersLimitMessage = useMemo(() => {
    if (MAX_ANSWERS_AMOUNT <= pollAnswers.length) {
      return 'You reached the limit of answers';
    }

    return `You can add ${MAX_ANSWERS_AMOUNT - pollAnswers.length} more answers`;
  }, [pollAnswers]);

  const isAddButtonDisabled = useMemo(
    () => MAX_ANSWERS_AMOUNT <= pollAnswers.length,
    [pollAnswers],
  );

  return (
    <div className={pollFormClasses} onBlur={handlePollUpdate}>
      <div className={styles.PollForm__Header}>
        <span className={styles.PollForm__HeaderLabel}>Length:</span>
        <span className={styles.PollForm__HeaderValue}>1 day</span>
      </div>
      {pollAnswers.map((answer) => (
        <PollAnswerControl
          key={answer.id}
          answer={answer}
          isRemoveButtonActive={pollAnswers.length > 2}
          onAnswerRemove={handleRemove}
          onAnswerTextChange={handleAnswerTextChange}
        />
      ))}
      <div className={styles.PollForm__Footer}>
        <div className={styles.PollForm__Button}>
          <Button
            theme={ButtonTheme.Secondary}
            size={ButtonSize.SmallSecondary}
            iconName={IconFontName.Add}
            onClick={handleAddOption}
            disabled={isAddButtonDisabled}
          >
            Add an Answer
          </Button>
        </div>
        <div className={styles.PollForm__Tip}>
          <span>{answersLimitMessage}</span>
        </div>
      </div>
    </div>
  );
});
