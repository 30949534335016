import {
  ARENA_GAME_NOTIF_TEXT_TAGS,
  SOCIALS_AND_NEWS_NOTIF_TEXT_TAGS,
} from 'configs/settings.config';

import { IPushNotificationSettings } from '../interfaces/push-notification-settings.interface';

export function hasMissedNotifTags(response: IPushNotificationSettings): boolean {
  const tags = {
    ...ARENA_GAME_NOTIF_TEXT_TAGS,
    ...SOCIALS_AND_NEWS_NOTIF_TEXT_TAGS,
  };

  const responseTags = Object.keys(response);
  const mappedTags = Object.keys(tags);

  return mappedTags.some((key) => !responseTags.includes(key));
}
