import { inject, injectable } from 'inversify';

import { PUSH_NOTIFICATION_SETTINGS } from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { PushNotificationPayloadType } from 'stores/settings/types/push-notification-payload.type';

import { TYPES } from 'configs/di-types.config';

import { IPushNotificationSettingsResponse } from './interfaces/push-notification-settings-response.interface';

@injectable()
export class SettingsService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchPushNotificationSettings(): Promise<
    IResponse<Maybe<IPushNotificationSettingsResponse>>
  > {
    return this.httpService.request<Maybe<IPushNotificationSettingsResponse>>({
      method: 'GET',
      url: PUSH_NOTIFICATION_SETTINGS,
    });
  }

  public sendPushNotificationSettings(payload: PushNotificationPayloadType) {
    return this.httpService.request<IPushNotificationSettingsResponse, PushNotificationPayloadType>(
      {
        method: 'POST',
        url: PUSH_NOTIFICATION_SETTINGS,
        body: payload,
      },
    );
  }

  public sendToCreatePushNotificationSettings() {
    return this.httpService.request<IPushNotificationSettingsResponse>({
      method: 'POST',
      url: PUSH_NOTIFICATION_SETTINGS,
    });
  }
}
