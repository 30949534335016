import { FC, useMemo } from 'react';
import { components, SingleValueProps } from 'react-select';

import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';

import styles from './team-single-value.module.less';

export interface ITeamSingleValue extends SingleValueProps<ITeamOption | null, false> {}

export const TeamSingleValue: FC<ITeamSingleValue> = ({ children, ...props }: ITeamSingleValue) => {
  const { data } = props;

  const textStyle = useMemo(
    () => ({ color: data?.secondaryTextColor }),
    [data?.secondaryTextColor],
  );

  return (
    <components.SingleValue {...props}>
      <div className={styles.TeamSingleValue}>
        {data?.logo && (
          <img src={data?.logo} className={styles.TeamSingleValue__Img} alt={data?.code} />
        )}
        {data?.code && (
          <span style={textStyle} className={styles.TeamSingleValue__Text}>
            {data?.code}
          </span>
        )}
      </div>
    </components.SingleValue>
  );
};
