export const DEFAULT_COUNTRY = 'us';
export const EXCLUDED_COUNTRIES = ['by', 'ru'];
export const CODE_INPUT_TYPE = 'number';
export const CODE_INPUT_FIELDS = 4;
export const CODE_INPUT_INPUT_MODE = 'numeric';
export const TITLE_INPUT_MAX_LENGTH = 300;
export const POLL_INPUT_MAX_LENGTH = 25;
export const ACCEPT_FILES = 'image/png, image/jpeg, image/jpg';
export const STATISTICS_ELEMENT_ID = 'statistics';
export const SCROLL_TOP_ELEMENT = 'PageWrapper';
export const LEFT_SIDEBAR_ELEMENT = 'SidebarDrawerElement';
export const RIGHT_SIDEBAR_ELEMENT = 'TrendingPanelElement';
export const SCROLL_BUTTON_APPEARANCE_DISTANCE = 200;
export const MAX_ORIGINAL_VIDEO_SIZE = 100;
export const VIDEO_SIZE_EXCEEDED_MESSAGE =
  'Video size exceeds 100MB limit, please upload a smaller video';
export const NULL_TEAM_OPTION_COLOR = 'rgba(40, 44, 48, 1)';
export const NULL_TEAM_OPTION_TEXT_COLOR = 'rgb(220, 229, 229, 1)';
export const NULL_PLAYER_OPTION_COLOR = 'rgba(13, 14, 16, 1)';
export const SELECTED_COLOR = '#3391cc';
export const DEFAULT_GREY_ICON_COLOR = '#dce5e5';

export const CROP_SCALE_STEP = 0.1;
export const CROP_MIN_SCALE = 1;
export const CROP_MAX_SCALE = 5;
export const CROP_SLIDER_STEP = 0.01;
export const CROP_INITIAL_SCALE = 1;

export const REVIEW_PROMPT_VERSION_COUNT = 4;
export const REVIEW_PROMPT_SHOWN_ID = 'prompt:shown';

export const CUSTOM_SCROLL_EVENT = 'ion-custom-scroll';
export const BLACK_COLOR = '#0d0e10';

export const REFRESH_TOKEN_INTERVAL_MS = 10 * 1000; // 10 seconds
export const MINIMUM_TIME_TO_EXPIRE_S = 20; // 20 seconds
