import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';

export enum PortalType {
  Debug,
  Modal,
  Sidebar,
  Tooltip,
  CropModal,
  BottomSheet,
  IntroPopup,
}

const PORTAL_ELEMENTS_MAP: Record<PortalType, Maybe<HTMLElement>> = {
  [PortalType.Debug]: document.getElementById('debug'),
  [PortalType.Modal]: document.getElementById('modal'),
  [PortalType.Sidebar]: document.getElementById('sidebar'),
  [PortalType.Tooltip]: document.getElementById('tooltip'),
  [PortalType.CropModal]: document.getElementById('crop-modal'),
  [PortalType.BottomSheet]: document.getElementById('bottom-sheet'),
  [PortalType.IntroPopup]: document.getElementById('intro-popup'),
};

interface IPortalProps {
  type: PortalType;
  children: ReactNode;
}

export const Portal: FC<IPortalProps> = (props: PropsWithChildren<IPortalProps>) => {
  const { children, type } = props;

  const element = useMemo<Maybe<HTMLElement>>(() => PORTAL_ELEMENTS_MAP[type] || null, [type]);

  if (!element) {
    return null;
  }

  return createPortal(children, element);
};
