import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';

import { GalleryStore } from 'stores/gallery/gallery.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as paths from 'routes/paths.constants';

import { useGaScreenCustomView } from 'hooks/use-ga-screen-custom-view';
import { useResponsive } from 'hooks/use-responsive';
import { useToggleIgnoredTouchClass } from 'hooks/use-toggle-ignored-touch-class';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { GalleryTheme } from 'components/modals/gallery-modal/gallery-modal.component';
import { SwipeNavigationType } from 'components/swipe-navigation/enums/swipe-navigation-type.enum';
import { SwipeNavigation } from 'components/swipe-navigation/swipe-navigation.component';
import { TeamProfileInfo } from 'components/team-profile/team-profile-info/team-profile-info.component';
import { Tabs } from 'components/ui/tabs/tabs.component';

import { TEAM_PROFILE_TABS } from '../team-profile.config';
import { TeamProfileOverviewContainer } from '../team-profile-overview/team-profile-overview.container';
import { TeamProfileRosterContainer } from '../team-profile-roster/team-profile-roster.container';

import styles from './team-profile-info.module.less';

export const TeamProfileInfoContainer = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);

  const { teamId } = teamsStore;

  const { teamsStats } = teamsStatsStore;

  const navigate = useNavigate();

  const { googleAnalyticsTeamFeed } = useGaScreenCustomView();

  const tabsWrapperRef = useRef<Maybe<HTMLDivElement>>(null);

  const [activeTab, setActiveTab] = useState('overview');

  const loading = useMemo(
    () => teamsStore.fetching || !teamsStats,
    [teamsStats, teamsStore.fetching],
  );

  useToggleIgnoredTouchClass(loading);

  const handleTabActionClick = useCallback((barAction: BarActionType<string>) => {
    if (barAction.type === BarAction.Click && barAction.payload) {
      setActiveTab(barAction.payload);
    }
  }, []);

  const handleShowRoster = useCallback(() => {
    setActiveTab('roster');
  }, []);

  const handleRankButtonClick = useCallback(() => {
    navigate(paths.HOME_STANDINGS);
  }, [navigate]);

  const handleOpenPreview = useCallback(
    (image: IInteractiveMediaAttachment) => {
      const watermarkEntity = teamsStats
        ? {
            name: teamsStats.nickname,
            logoUrl: teamsStats.smallLogoUrl,
          }
        : null;

      galleryStore.setGalleryAttachments([image]);
      galleryStore.setTheme(GalleryTheme.Preview);
      galleryStore.setWatermarkEntity(watermarkEntity);
      galleryStore.setIsGalleryOpen(true);
    },
    [galleryStore, teamsStats],
  );

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  useEffect(() => {
    if (teamId) {
      googleAnalyticsTeamFeed(ScreenNamesEnum.TeamProfileProfile);
    }
  }, [teamId, googleAnalyticsTeamFeed]);

  if (loading) {
    return null;
  }

  return (
    <div className={styles.TeamProfileWrapper}>
      <TeamProfileInfo
        teamsStats={teamsStats}
        onRankButtonClick={handleRankButtonClick}
        onOpenPreview={handleOpenPreview}
      />
      <div ref={tabsWrapperRef}>
        <Tabs
          fullWidth={!isDesktopPlus}
          activeTab={activeTab}
          onClick={handleTabActionClick}
          tabs={TEAM_PROFILE_TABS}
        />
      </div>
      <SwipeNavigation
        tabs={TEAM_PROFILE_TABS}
        activeTabClick={activeTab}
        swipeNavType={SwipeNavigationType.SubTabsLevel}
        setDisabledScroll={layoutStore.setDisabledScroll}
        onTabClick={handleTabActionClick}
      >
        {activeTab === 'overview' && (
          <TeamProfileOverviewContainer teamId={teamId} onShowRoster={handleShowRoster} />
        )}
        {activeTab === 'roster' && <TeamProfileRosterContainer />}
      </SwipeNavigation>
    </div>
  );
});
