import { FC, useMemo } from 'react';
import { components, DropdownIndicatorProps } from 'react-select';

import { useToggleIgnoredTouchClass } from 'hooks/use-toggle-ignored-touch-class';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IPlayerOption } from 'containers/profile/profile-info/interfaces/player-option.interface';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

interface IPlayerDropDownIndicatorProps
  extends DropdownIndicatorProps<Maybe<IPlayerOption>, false> {}

export const PlayerDropdownIndicator: FC<IPlayerDropDownIndicatorProps> = (
  props: IPlayerDropDownIndicatorProps,
) => {
  const { selectProps, getValue } = props;

  useToggleIgnoredTouchClass(selectProps.menuIsOpen);

  const memoizedArrowColor = useMemo(() => {
    const color = getValue()[0]?.fontColor;
    if (color) {
      return color;
    }
    return null;
  }, [getValue]);

  return (
    <components.DropdownIndicator {...props}>
      <IconButton
        customColor={memoizedArrowColor}
        iconName={selectProps?.menuIsOpen ? IconFontName.ChevronUp : IconFontName.ChevronDown}
        theme={IconButtonTheme.Transparent}
      />
    </components.DropdownIndicator>
  );
};
