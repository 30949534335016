import { IComment } from 'stores/comments/interfaces/comments.interface';

export const findAndReplaceObjectByKey = (
  array: IComment[],
  key: keyof IComment,
  targetValue: any,
  replacementValue: IComment,
): IComment[] => {
  return array.map((obj) => {
    if (obj[key] === targetValue) {
      return { ...replacementValue };
    }
    if (typeof obj === 'object' && obj !== null) {
      const newComments = findAndReplaceObjectByKey(
        obj.comments as IComment[],
        key,
        targetValue,
        replacementValue,
      );
      return {
        ...obj,
        comments: newComments,
      };
    }
    return obj;
  });
};

// Recursive function to remove an item by key and value
export function removeObjectsInCommentsByKey(
  arr: IComment[],
  key: keyof IComment,
  value: any,
): IComment[] {
  return arr
    .map((item) => {
      const currentItem = item;
      if (currentItem.comments && Array.isArray(currentItem.comments)) {
        // Recursively process nested comments and remove objects with matching key and value
        const itemsLength = currentItem.comments.length;
        const newItems = removeObjectsInCommentsByKey(currentItem.comments, key, value);
        if (newItems.length < itemsLength) {
          currentItem.commentsAmount--;
        }
        currentItem.comments = newItems;
      }

      return currentItem;
    })
    .filter((item) => {
      if (item[key] === value) {
        // Skip the object if the key matches the value
        return false;
      }
      // Include the object if it has non-empty comments or if the key doesn't match the value
      if (item.comments) {
        return item.comments?.length > 0 || item[key] !== value;
      }

      return false;
    });
}

export const findObjectInArrayByKey = (
  array: IComment[],
  key: keyof IComment,
  value: any,
): IComment | null => {
  // eslint-disable-next-line no-restricted-syntax
  for (const obj of array) {
    if (obj[key] === value) {
      return obj;
    }

    if (typeof obj === 'object' && obj !== null) {
      const result = findObjectInArrayByKey(obj.comments as IComment[], key, value);
      if (result) {
        return result;
      }
    }
  }
  return null;
};
