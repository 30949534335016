import { memo } from 'react';
import cn from 'classnames';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { SeasonType } from 'services/seasons/enums/season-type.enum';

import { IGameDetailedScores } from 'stores/scoreboard/interfaces/game-detailed-scores.interface';
import { IGameDetailedTeam } from 'stores/scoreboard/interfaces/game-detailed-team.interface';
import { IGameDetailedTopPlayer } from 'stores/scoreboard/interfaces/game-detailed-top-player.interface';
import { GameQuarter } from 'stores/scoreboard/types/game-quarter.type';

import { ScoreboardLineScoreRow } from 'components/scoreboard/scoreboard-linescore/scoreboard-line-score-row/scoreboard-line-score-row.component';
import { ScoreboardPlayer } from 'components/scoreboard/scoreboard-player/scoreboard-player.component';
import {
  ScoreboardTeam,
  ScoreboardTeamOrder,
} from 'components/scoreboard/scoreboard-team/scoreboard-team.component';

import verticalRowsTableGridStyles from '../vertical-rows-table-grid.module.less';
import styles from './vertical-rows-table-row.module.less';

interface IVerticalRowsTableRowProps {
  currentQuarter: GameQuarter;
  isHome: boolean;
  status: GameStatus;
  scores: IGameDetailedScores;
  team: IGameDetailedTeam;
  seasonType: SeasonType;
  topPlayer: Maybe<IGameDetailedTopPlayer>;
  onPlayerClick: (slug: string) => void;
  onTeamClick: (teamId: number) => void;
}

export const VerticalRowsTableRow = memo((props: IVerticalRowsTableRowProps) => {
  const { currentQuarter, isHome, status, scores, team, topPlayer, seasonType } = props;

  return (
    <div
      className={cn(styles.VerticalRowsTableRow, verticalRowsTableGridStyles.VerticalRowsTableGrid)}
      role="row"
    >
      <div role="cell">
        <ScoreboardTeam
          isHome={isHome}
          status={status}
          teamOrder={ScoreboardTeamOrder.Vertical}
          onTeamClick={props.onTeamClick}
          seasonType={seasonType}
          {...team}
        />
      </div>

      <div role="cell">
        <ScoreboardLineScoreRow {...scores} currentQuarter={currentQuarter} />
      </div>

      <div role="cell">
        {topPlayer ? (
          <ScoreboardPlayer {...topPlayer} onPlayerClick={props.onPlayerClick} />
        ) : (
          'No information'
        )}
      </div>
    </div>
  );
});
