import {
  IPlayerCareerRecordResponse,
  IPlayerCareerResponse,
} from 'services/player/interfaces/player-career.interface';

import { seasonAdapter } from 'stores/seasons/adapters/season-adapter.util';

import { roundToSingleDecimal } from 'helpers/round-to-single-decimal.util';

import { IPlayerCareer, IPlayerCareerRecord } from '../interfaces/player-profile.interfaces';

export function playerCareerAdapter(playerCareerResponse: IPlayerCareerResponse): IPlayerCareer {
  const {
    id,
    firstname,
    lastname,
    records,
    slug,
    assists,
    blocks,
    def_reb: defensiveReb,
    fga: fieldGoalsAttempted,
    fgm: fieldGoalsMade,
    fgp: fieldGoalsPercentage,
    fta: freeThrowsAttempted,
    ftm: freeThrowsMade,
    ftp: freeThrowsPercentage,
    games_played: gamesPlayed,
    off_reb: offensiveReb,
    personal_fouls: personalFouls,
    points,
    steals,
    tot_reb: totalRebounds,
    tpa: threePointsFieldGoalsAttempted,
    tpm: threePointsFieldGoalsMade,
    tpp: threePointsFieldGoalsPercentage,
    turnovers,
  } = playerCareerResponse;

  return {
    id,
    firstname,
    lastname,
    slug,
    assists: roundToSingleDecimal(assists),
    blocks: roundToSingleDecimal(blocks),
    defensiveReb: roundToSingleDecimal(defensiveReb),
    fieldGoalsAttempted: roundToSingleDecimal(fieldGoalsAttempted),
    fieldGoalsMade: roundToSingleDecimal(fieldGoalsMade),
    fieldGoalsPercentage: roundToSingleDecimal(fieldGoalsPercentage),
    freeThrowsAttempted: roundToSingleDecimal(freeThrowsAttempted),
    freeThrowsMade: roundToSingleDecimal(freeThrowsMade),
    freeThrowsPercentage: roundToSingleDecimal(freeThrowsPercentage),
    gamesPlayed: roundToSingleDecimal(gamesPlayed),
    offensiveReb: roundToSingleDecimal(offensiveReb),
    personalFouls: roundToSingleDecimal(personalFouls),
    points: roundToSingleDecimal(points),
    steals: roundToSingleDecimal(steals),
    threePointsFieldGoalsAttempted: roundToSingleDecimal(threePointsFieldGoalsAttempted),
    threePointsFieldGoalsMade: roundToSingleDecimal(threePointsFieldGoalsMade),
    threePointsFieldGoalsPercentage: roundToSingleDecimal(threePointsFieldGoalsPercentage),
    totalRebounds: roundToSingleDecimal(totalRebounds),
    turnovers: roundToSingleDecimal(turnovers),
    records: records.map(playerCareerRecordAdapter),
  };
}

export function playerCareerRecordAdapter(
  playerCareerRecordResponse: IPlayerCareerRecordResponse,
): IPlayerCareerRecord {
  const {
    assists,
    blocks,
    def_reb: defensiveReb,
    fga: fieldGoalsAttempted,
    fgm: fieldGoalsMade,
    fgp: fieldGoalsPercentage,
    fta: freeThrowsAttempted,
    ftm: freeThrowsMade,
    ftp: freeThrowsPercentage,
    games_played: gamesPlayed,
    off_reb: offensiveReb,
    personal_fouls: personalFouls,
    points,
    steals,
    tot_reb: totalRebounds,
    tpa: threePointsFieldGoalsAttempted,
    tpm: threePointsFieldGoalsMade,
    tpp: threePointsFieldGoalsPercentage,
    turnovers,
    position,
    season,
    team,
  } = playerCareerRecordResponse;

  return {
    assists: roundToSingleDecimal(assists),
    blocks: roundToSingleDecimal(blocks),
    defensiveReb: roundToSingleDecimal(defensiveReb),
    fieldGoalsAttempted: roundToSingleDecimal(fieldGoalsAttempted),
    fieldGoalsMade: roundToSingleDecimal(fieldGoalsMade),
    fieldGoalsPercentage: roundToSingleDecimal(fieldGoalsPercentage),
    freeThrowsAttempted: roundToSingleDecimal(freeThrowsAttempted),
    freeThrowsMade: roundToSingleDecimal(freeThrowsMade),
    freeThrowsPercentage: roundToSingleDecimal(freeThrowsPercentage),
    gamesPlayed: roundToSingleDecimal(gamesPlayed),
    offensiveReb: roundToSingleDecimal(offensiveReb),
    personalFouls: roundToSingleDecimal(personalFouls),
    points: roundToSingleDecimal(points),
    steals: roundToSingleDecimal(steals),
    threePointsFieldGoalsAttempted: roundToSingleDecimal(threePointsFieldGoalsAttempted),
    threePointsFieldGoalsMade: roundToSingleDecimal(threePointsFieldGoalsMade),
    threePointsFieldGoalsPercentage: roundToSingleDecimal(threePointsFieldGoalsPercentage),
    totalRebounds: roundToSingleDecimal(totalRebounds),
    turnovers: roundToSingleDecimal(turnovers),
    position,
    season: {
      ...seasonAdapter(season),
      label: `${season.label.split(' ')[0]} ${season.type}`,
    },
    team: {
      code: team.code,
      id: team.id,
      logo: team.medium_logo_url,
      name: team.name,
    },
  };
}
