export const setImageOrientation = (
  base64String: string,
  format: string,
  orientation: number,
  callback: (base64String: string) => void,
) => {
  const img = new Image();

  const dataURL = `data:image/${format};base64,${base64String}`;

  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (orientation < 9 && orientation > 4) {
      canvas.width = img.height;
      canvas.height = img.width;
    } else {
      canvas.width = img.width;
      canvas.height = img.height;
    }

    if (ctx) {
      switch (orientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, img.width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, img.width, img.height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, img.height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, img.height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, img.height, img.width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, img.width);
          break;
        default:
          break;
      }

      ctx.drawImage(img, 0, 0, img.width, img.height);
      ctx.drawImage(img, 0, 0, img.width, img.height);
    }

    const canvasDataURL = canvas.toDataURL('image/jpeg');
    const canvasBase64String = canvasDataURL.replace(/^data:image\/?[A-z]*;base64,/, '');

    callback(canvasBase64String);
  };

  img.src = dataURL;
};
