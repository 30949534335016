import { FC, memo } from 'react';

import { BarActionType } from 'components/bars/bar-action.type';

import { IContextMenuGroup } from './interfaces/context-menu-group.interface';
import { ContextMenuGroup } from './context-menu-group/context-menu-group.component';

import styles from './context-menu.module.less';

export interface IContextMenuProps {
  selectedItem?: string;
  groups: IContextMenuGroup[];
  onItemClick: (barAction: BarActionType) => void;
}

export const ContextMenu: FC<IContextMenuProps> = memo((props: IContextMenuProps) => {
  const { selectedItem, groups } = props;

  return (
    <ul className={styles.ContextMenu}>
      {groups.map(({ id, group }, index) => (
        <li key={id}>
          <ContextMenuGroup
            selectedItem={selectedItem}
            onItemClick={props.onItemClick}
            group={group}
          />
          {index !== groups.length - 1 && <hr />}
        </li>
      ))}
    </ul>
  );
});
