import { FC, useMemo } from 'react';
import { ColDef } from 'ag-grid-community';

import {
  ISplitsDayRowData,
  ISplitsMonthRowData,
  ISplitsOpponentRowData,
  ISplitsResultRowData,
  ISplitsSplitRowData,
  ISplitsTotalRowData,
} from 'stores/player/interfaces/player-profile.interfaces';

import {
  DAY_COL_DEF,
  DEFAULT_PLAYER_SPLITS_COLUMNS,
  MONTH_COL_DEF,
  OPPONENT_COL_DEF,
  RESULT_COL_DEF,
  SPLIT_COL_DEF,
  TOTAL_COL_DEF,
} from 'components/player-profile/player-profile-tables/player-profile-tables.config';
import { PlayerStatsTable } from 'components/team-stats/players-stats-table/players-stats-table.component';

import styles from './player-profile-splits-all.module.less';

interface IPlayerProfileSplitsAllProps {
  totalRowData: ISplitsTotalRowData[];
  splitRowData: ISplitsSplitRowData[];
  monthRowData: ISplitsMonthRowData[];
  resultRowData: ISplitsResultRowData[];
  dayRowData: ISplitsDayRowData[];
  opponentRowData: ISplitsOpponentRowData[];
  hasTeamColor: boolean;
  onTeamClick: (teamId: number) => void;
}

export const PlayerProfileSplitsAll: FC<IPlayerProfileSplitsAllProps> = (props) => {
  const {
    totalRowData,
    splitRowData,
    monthRowData,
    resultRowData,
    dayRowData,
    opponentRowData,
    hasTeamColor,
    onTeamClick,
  } = props;

  const opponentColumnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        ...OPPONENT_COL_DEF,
        cellRendererParams: {
          onTeamClick,
        },
      },
      ...DEFAULT_PLAYER_SPLITS_COLUMNS,
    ];
  }, [onTeamClick]);

  return (
    <div className={styles.AllTablesWrapper}>
      <PlayerStatsTable
        tableGridOption={{
          columnDefs: [TOTAL_COL_DEF, ...DEFAULT_PLAYER_SPLITS_COLUMNS],
          rowData: totalRowData,
          gridId: '1',
        }}
        withColors={hasTeamColor}
      />
      <PlayerStatsTable
        tableGridOption={{
          columnDefs: [SPLIT_COL_DEF, ...DEFAULT_PLAYER_SPLITS_COLUMNS],
          rowData: splitRowData,
          gridId: '2',
        }}
        withColors={hasTeamColor}
      />
      <PlayerStatsTable
        tableGridOption={{
          columnDefs: [MONTH_COL_DEF, ...DEFAULT_PLAYER_SPLITS_COLUMNS],
          rowData: monthRowData,
          gridId: '3',
        }}
        withColors={hasTeamColor}
      />
      <PlayerStatsTable
        tableGridOption={{
          columnDefs: [RESULT_COL_DEF, ...DEFAULT_PLAYER_SPLITS_COLUMNS],
          rowData: resultRowData,
          gridId: '4',
        }}
        withColors={hasTeamColor}
      />
      <PlayerStatsTable
        tableGridOption={{
          columnDefs: [DAY_COL_DEF, ...DEFAULT_PLAYER_SPLITS_COLUMNS],
          rowData: dayRowData,
          gridId: '5',
        }}
        withColors={hasTeamColor}
      />
      <PlayerStatsTable
        tableGridOption={{
          columnDefs: opponentColumnDefs,
          rowData: opponentRowData,
          gridId: '6',
        }}
        withColors={hasTeamColor}
      />
    </div>
  );
};
