import { IBookmarkedUsersResponse } from 'services/bookmarks/interfaces/bookmarks-response.interface';

import { IBookmarkUser } from 'stores/bookmarks/interfaces/bookmarks.interface';
import { BookmarksResponseType, BookmarksType } from 'stores/bookmarks/types/bookmarks.type';
import { IComment } from 'stores/comments/interfaces/comments.interface';
import { publicationAdapter } from 'stores/posts/adapters/publication-adapter.util';
import { IPost } from 'stores/posts/interfaces/post.interface';

import { formatPastDate } from 'helpers/format/format-past-date.util';

import { BookmarkPostType } from '../enums/bookmark-post-type.enum';

export function bookmarksAdapter(bookmarksResponseItem: BookmarksResponseType): BookmarksType {
  if (bookmarksResponseItem.type === BookmarkPostType.Post) {
    return {
      ...bookmarksResponseItem,
      type: BookmarkPostType.Post,
      post: <IPost>publicationAdapter(bookmarksResponseItem.post),
      bookmarkDate: {
        timeOnly: formatPastDate(bookmarksResponseItem.date_created, 'timeOnly'),
        relativeLong: formatPastDate(bookmarksResponseItem.date_created, 'relativeLong'),
        relativeShort: formatPastDate(bookmarksResponseItem.date_created, 'relativeShort'),
        full: formatPastDate(bookmarksResponseItem.date_created),
      },
    };
  }

  return {
    ...bookmarksResponseItem,
    type: BookmarkPostType.PostComment,
    comment: <IComment>publicationAdapter(bookmarksResponseItem.post_comment),
    postTitle: bookmarksResponseItem.post_comment.post_title,
    postId: bookmarksResponseItem.post_comment.post_uuid,
    bookmarkDate: {
      timeOnly: formatPastDate(bookmarksResponseItem.date_created, 'timeOnly'),
      relativeLong: formatPastDate(bookmarksResponseItem.date_created, 'relativeLong'),
      relativeShort: formatPastDate(bookmarksResponseItem.date_created, 'relativeShort'),
      full: formatPastDate(bookmarksResponseItem.date_created),
    },
  };
}

export function bookmarksUsersAdapter(users: IBookmarkedUsersResponse): Array<IBookmarkUser> {
  const result: Array<IBookmarkUser> = [];
  users.forEach((user) => {
    const formattedUser = {
      label: user.name,
      value: user.uuid,
      avatarUrl: user.avatar_url,
      id: user.uuid,
    };
    result.push(formattedUser);
  });

  return result;
}
