import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { LayoutStore } from 'stores/layout/layout.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { APP_PROTOCOL, PACKAGE_APP_NAME } from 'configs/environment.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { isIosDevice } from 'helpers/detect-device-browser.util';
import { isMobileDevice } from 'helpers/detect-mobile-device.util';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { DeeplinkBannerBottomSheet } from 'components/bottom-sheet/deeplink-banner-bottom-sheet/deeplink-banner-bottom-sheet.component';
import { DeeplinkSmallBanner } from 'components/ui/deeplink-small-banner/deeplink-small-banner.component';

export const DeeplinkBannerContainer = observer(() => {
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);

  const { isNativeApp } = useMainProvider();
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [isDisplayedBottomSheet, setIsDisplayedBottomSheet] = useState(false);

  const handleAppOpenClick = useCallback(async () => {
    const pathname = window.location.pathname.substring(1);

    let href = '';

    if (isIosDevice()) {
      href = `${APP_PROTOCOL}://${pathname}`;
    } else {
      href = `intent://digitssports.com/${pathname}#Intent;scheme=${APP_PROTOCOL};package=${PACKAGE_APP_NAME};end`;
    }

    window.open(href, '_self');
  }, []);

  const handleClose = useCallback(() => {
    layoutStore.saveDeeplinkBannerSession();
    setIsDisplayedBottomSheet(false);
  }, [layoutStore]);

  const isDisplayedSmartBanner = useMemo(
    () => !isNativeApp && !isDesktopPlus && isMobileDevice() && !isIosDevice(),
    [isNativeApp, isDesktopPlus],
  );

  useEffect(() => {
    setIsDisplayedBottomSheet(
      !layoutStore.isStoredSessionSmartBanner &&
        !layoutStore.isForceCollapsedSmartBanner &&
        !layoutStore.isAnyWelcomePopupOpened,
    );
  }, [
    layoutStore.isStoredSessionSmartBanner,
    layoutStore.isForceCollapsedSmartBanner,
    layoutStore.isAnyWelcomePopupOpened,
  ]);

  useEffect(() => {
    layoutStore.setDisplayedSmartBanner(isDisplayedSmartBanner);
  }, [isDisplayedSmartBanner, layoutStore]);

  if (!isDisplayedSmartBanner) {
    return null;
  }

  return (
    <>
      <DeeplinkBannerBottomSheet
        visible={isDisplayedBottomSheet}
        isBottomBarHidden={layoutStore.isHiddenBottomBar}
        isAnySidebarOpen={layoutStore.isSidePanelOpen || layoutStore.isMobileSidebarOpen}
        avatarSrc={teamsStatsStore.team?.bannerUrl || null}
        onClose={handleClose}
        onOpenApp={handleAppOpenClick}
      />
      {!isDisplayedBottomSheet && (
        <DeeplinkSmallBanner
          isBottomBarHidden={layoutStore.isHiddenBottomBar}
          onAppOpenClick={handleAppOpenClick}
        />
      )}
    </>
  );
});
