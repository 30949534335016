import { useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AuthStore } from 'stores/auth/auth.store';
import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutHeaderLeftActionEnum } from 'stores/layout/interfaces/layout-header-left.interface';

import styles from 'containers/privacy-policy/policy.module.less';

import { TYPES } from 'configs/di-types.config';

import { useLayoutEntity } from 'hooks/use-layout-entity';
import { usePolicyBackClick } from 'hooks/use-policy-back-click';

import { Policy } from 'components/policy/policy.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';

export const TermsOfUseContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const handleBackClick = usePolicyBackClick();

  useLayoutEntity({
    type: LayoutEntity.HeaderLeft,
    value: {
      icon: IconFontName.ChevronLeft,
      type: LayoutHeaderLeftActionEnum.Custom,
      callback: handleBackClick,
    },
  });

  useEffect(() => {
    authStore.setAuthMode(null);

    authStore.retrieveTermsOfUse();
  }, [authStore]);

  if (authStore.fetching) {
    return (
      <div className={styles.LoaderWrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  if (!authStore.termsOfUse?.content) {
    return null;
  }

  return <Policy content={authStore.termsOfUse.content} />;
});
