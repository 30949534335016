import { ITeamStatsRow } from 'containers/team-stats/team-stats.interface';

import { IPlayerDetails } from '../interfaces/player-profile.interfaces';

export function playerStatsRowDataAdapter(playerDetails: IPlayerDetails): ITeamStatsRow[] {
  const { stats, team } = playerDetails;

  if (!stats) {
    return [];
  }

  const firstRow = {
    type: 'Player',
    teamPrimaryColor: team?.color || '',
    teamTextColor: team?.textColor || '',
    gamesPlayed: stats.gamesPlayed,
    points: stats.points,
    fieldGoalsMade: stats.fieldGoalsMade,
    fieldGoalsAttempted: stats.fieldGoalsAttempted,
    fieldGoalsPercentage: stats.fieldGoalsPercentage,
    threePointsFieldGoalsMade: stats.threePointsFieldGoalsMade,
    threePointsFieldGoalsAttempted: stats.threePointsFieldGoalsAttempted,
    threePointsFieldGoalsPercentage: stats.threePointsFieldGoalsPercentage,
    freeThrowsMade: stats.freeThrowsMade,
    freeThrowsAttempted: stats.freeThrowsAttempted,
    freeThrowsPercentage: stats.freeThrowsPercentage,
    offensiveReb: stats.offensiveReb,
    defensiveReb: stats.defensiveReb,
    totalRebounds: stats.totalRebounds,
    assists: stats.assists,
    blocks: stats.blocks,
    steals: stats.steals,
    personalFouls: stats.personalFouls,
    turnovers: stats.turnovers,
  };

  const secondRow = {
    type: 'Player Rank',
    teamPrimaryColor: team?.color || '',
    teamTextColor: team?.textColor || '',
    gamesPlayed: null,
    points: stats.pointsRank,
    fieldGoalsMade: stats.fieldGoalsMadeRank,
    fieldGoalsAttempted: stats.fieldGoalsAttemptedRank,
    fieldGoalsPercentage: stats.fieldGoalsPercentageRank,
    threePointsFieldGoalsMade: stats.threePointsFieldGoalsMadeRank,
    threePointsFieldGoalsAttempted: stats.threePointsFieldGoalsAttemptedRank,
    threePointsFieldGoalsPercentage: stats.threePointsFieldGoalsPercentageRank,
    freeThrowsMade: stats.freeThrowsMadeRank,
    freeThrowsAttempted: stats.freeThrowsAttemptedRank,
    freeThrowsPercentage: stats.freeThrowsPercentageRank,
    offensiveReb: stats.offensiveRebRank,
    defensiveReb: stats.defensiveRebRank,
    totalRebounds: stats.totalReboundsRank,
    assists: stats.assistsRank,
    blocks: stats.blocksRank,
    steals: stats.stealsRank,
    personalFouls: stats.personalFoulsRank,
    turnovers: stats.turnoversRank,
  };

  return [firstRow, secondRow];
}
