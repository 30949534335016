import { memo, useMemo } from 'react';
import cn from 'classnames';
import format from 'date-fns/format';

import { IGame } from 'services/statistics/interface/game.interface';

import { STATISTICS_ELEMENT_ID } from 'configs/controls.config';
import { DAY_FORMAT } from 'configs/date.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { useResponsive } from 'hooks/use-responsive';

import { StatisticsDayItem } from './statistics-day-item/statistics-day-item.component';
import { StatisticsItem } from './statistics-item/statistics-item.component';
import { StatisticsType } from './statistics-type.enum';

import styles from './statistics.module.less';

export interface IStatisticsProps {
  expanded?: boolean;
  type?: StatisticsType;
  games: IGame[];
  firstGameIds?: number[];
  firstGameTodayId?: Maybe<number>;
  selectedGameId?: Maybe<number>;
  onGameClick: (gameId: number, opponents?: string) => void;
}

export const Statistics = memo((props: IStatisticsProps) => {
  const {
    type = StatisticsType.Default,
    expanded = false,
    games,
    firstGameIds,
    firstGameTodayId,
    selectedGameId,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const isUserWindows = useMemo(() => navigator.userAgent.indexOf('Windows') !== -1, []);

  const currentDateAndDay = useMemo(
    () => ({
      date: format(new Date(), DAY_FORMAT),
      day: format(new Date(), 'E'),
    }),
    [],
  );

  const statisticsClassNames = useMemo(
    () =>
      cn(styles.Statistics, TOUCH_IGNORE_CLASS, {
        [styles['Statistics--scrollbar-windows']]: isUserWindows && isDesktopPlus,
        [styles['Statistics--for-arena']]: type === StatisticsType.Arena,
        [styles['Statistics--for-shortcut']]: type === StatisticsType.ShortcutBar && !expanded,
        [styles['Statistics--for-expanded-shortcut']]:
          type === StatisticsType.ShortcutBar && expanded,
      }),
    [isDesktopPlus, isUserWindows, expanded, type],
  );

  const items = useMemo(() => {
    if (!games.length && type !== StatisticsType.Arena) {
      return (
        <div className={styles.StatisticsEmptyState}>
          <div className={styles.StatisticsEmptyState__List}>
            <StatisticsDayItem date={currentDateAndDay.date} day={currentDateAndDay.day} />
            <span className={styles.StatisticsEmptyState__Message}>No matches for this date</span>
          </div>
        </div>
      );
    }

    return games.map((game) => {
      return (
        <StatisticsItem
          key={game.id}
          isFirstGame={firstGameIds ? firstGameIds.includes(game.id) : false}
          isFirstGameToday={game.id === firstGameTodayId}
          isSelectedArenaGame={game.id === selectedGameId}
          expanded={expanded}
          game={game}
          type={type}
          onGameClick={props.onGameClick}
        />
      );
    });
  }, [
    currentDateAndDay,
    firstGameIds,
    firstGameTodayId,
    games,
    selectedGameId,
    type,
    expanded,
    props.onGameClick,
  ]);

  return (
    <div id={STATISTICS_ELEMENT_ID} className={statisticsClassNames}>
      {items}
    </div>
  );
});
