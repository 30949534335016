import { memo, useCallback } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './stats-first-cell.module.less';

interface IStatsPlayerCellProps extends ICellRendererParams {
  onPlayerClick: (playerSlug: string) => void;
}

export const StatsPlayerCell = memo((props: IStatsPlayerCellProps) => {
  const { value, onPlayerClick } = props;

  const handlePlayerClick = useCallback(() => {
    if (value?.slug) {
      onPlayerClick(value.slug);
    }
  }, [onPlayerClick, value?.slug]);

  if (!value) {
    return null;
  }

  return (
    <button className={styles.StatsCell} onClick={handlePlayerClick}>
      <Avatar size={AvatarSize.XS} username={value.name} src={value.smallLogoUrl} />
      <div className={styles.StatsCell__Name}>{value.name}</div>
    </button>
  );
});
