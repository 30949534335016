import { Clipboard } from '@capacitor/clipboard';
import { inject, injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';

import { GameService } from 'services/game/game.service';
import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IEventParams } from 'services/google-analytic/interfaces/event-payload.interface';
import { PlayerRankingsService } from 'services/player-rankings/player-rankings.service';
import { PostsService } from 'services/posts/posts.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { GameStore } from 'stores/game/game.store';
import { PlayerStore } from 'stores/player/player.store';
import { IShareGame } from 'stores/share/interfaces/share-data.interface';
import {
  ISharePlayerPerformanceResponse,
  ISharePlayersRankingsResponse,
} from 'stores/share/interfaces/share-response.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';

import { shareAdapter } from './adapters/share-adapter.util';
import { SharedType } from './enums/share-type.enum';
import { ShareData } from './types/share-data.type';
import { ShareResponse } from './types/share-response.type';

@injectable()
export class ShareStore extends ApiConnectedStore {
  private readonly postsService: PostsService;

  private readonly gameService: GameService;

  private readonly playerRankingsService: PlayerRankingsService;

  private readonly gameStore: GameStore;

  private readonly teamsStatsStore: TeamsStatsStore;

  private readonly feedFiltersStore: FeedFiltersStore;

  private readonly playerStore: PlayerStore;

  public isShareModalVisible;

  public shareData: Maybe<ShareData>;

  constructor(
    @inject(TYPES.PostsService) httpService: PostsService,
    @inject(TYPES.GameStore) gameStore: GameStore,
    @inject(TYPES.GameService) gameService: GameService,
    @inject(TYPES.TeamsStatsStore) teamsStatsStore: TeamsStatsStore,
    @inject(TYPES.PlayerRankingsService) playerRankingsService: PlayerRankingsService,
    @inject(TYPES.FeedFiltersStore) feedFiltersStore: FeedFiltersStore,
    @inject(TYPES.PlayerStore) playerStore: PlayerStore,
  ) {
    super();

    this.gameStore = gameStore;

    this.postsService = httpService;

    this.gameService = gameService;

    this.teamsStatsStore = teamsStatsStore;

    this.playerRankingsService = playerRankingsService;

    this.feedFiltersStore = feedFiltersStore;

    this.playerStore = playerStore;

    this.isShareModalVisible = false;

    this.shareData = null;

    makeObservable(this, {
      isShareModalVisible: observable,
      shareData: observable,

      fetchSharePlayerPerformance: action.bound,
      fetchSharePlayersRankingsDetails: action.bound,
      setIsShareModalVisible: action.bound,
      setShareData: action.bound,
    });
  }

  private async processShareResponse(response: IResponse<ShareResponse>) {
    if (response.success) {
      this.setShareData(shareAdapter(response.data));
    } else {
      this.setErrors(response.errors);
    }

    this.setFetched(true);
  }

  public async fetchSharePublicationData(
    postId: string,
    commentId?: string,
    teamId?: Maybe<number>,
    playerId?: number,
  ): Promise<void> {
    this.setErrors([]);
    this.setFetched(false);

    if (teamId && commentId) {
      const response = await this.postsService.shareTeamPostComment({
        teamId,
        commentId,
        postId,
      });

      this.processShareResponse(response);

      return;
    }

    if (teamId) {
      const response = await this.postsService.shareTeamPost({ teamId, postId });

      this.processShareResponse(response);

      return;
    }

    if (playerId && commentId) {
      const response = await this.postsService.sharePlayerPostComment({
        postId,
        commentId,
        playerId,
      });

      this.processShareResponse(response);

      return;
    }

    if (playerId) {
      const response = await this.postsService.sharePlayerPost({
        postId,
        playerId,
      });

      this.processShareResponse(response);

      return;
    }

    if (commentId) {
      const response = await this.postsService.sharePostComment({ commentId, postId });

      this.processShareResponse(response);

      return;
    }

    const response = await this.postsService.sharePost({ id: postId });

    this.processShareResponse(response);
  }

  public async fetchShareGame(gameId: number) {
    this.setErrors([]);
    this.setFetched(false);

    const response: IResponse<IShareGame> = await this.gameService.shareGame(gameId);

    if (response.success) {
      this.setShareData(response.data);
    } else {
      this.setErrors(response.errors);
    }

    this.setFetched(true);
  }

  public async fetchSharePlayerPerformance(gameId: string, slug: string) {
    this.setErrors([]);
    this.setFetched(false);

    const response: IResponse<ISharePlayerPerformanceResponse> =
      await this.gameService.sharePlayerPerformance(gameId, slug);

    if (response.success) {
      this.setShareData(shareAdapter(response.data));
    } else {
      this.setErrors(response.errors);
    }

    this.setFetched(true);
  }

  public async fetchSharePlayersRankings() {
    this.setErrors([]);
    this.setFetched(false);

    const response: IResponse<ISharePlayersRankingsResponse> =
      await this.playerRankingsService.sharePlayerRankings();

    if (response.success) {
      this.setShareData(shareAdapter(response.data));
    } else {
      this.setErrors(response.errors);
    }

    this.setFetched(true);
  }

  public async fetchSharePlayersRankingsDetails(category: string) {
    this.setErrors([]);
    this.setFetched(false);

    const response: IResponse<ISharePlayersRankingsResponse> =
      await this.playerRankingsService.sharePlayerRankingsDetails(category);

    if (response.success) {
      this.setShareData(shareAdapter(response.data));
    } else {
      this.setErrors(response.errors);
    }

    this.setFetched(true);
  }

  public setShareData = (shareData: Maybe<ShareData>) => {
    this.shareData = shareData;
  };

  public setIsShareModalVisible(isShareModalVisible: boolean) {
    this.isShareModalVisible = isShareModalVisible;
  }

  private async writeToClipboard(content: string) {
    await Clipboard.write({
      string: content,
    });
  }

  private googleAnalyticsShareLink() {
    if (SharedType.Game === this.shareData?.type && this.gameStore.entry) {
      const gameHomeName = this.gameStore.entry.home.teamInfo.name;
      const gameVisitorName = this.gameStore.entry.visitors.teamInfo.name;
      const gameTimeStart = this.gameStore.entry.gameDateTimeStart;

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.ArenaShare,
          game: `${gameHomeName} vs ${gameVisitorName}`,
          date: gameTimeStart,
          first_game: `${this.gameStore.isFirstGameInSameDay}`,
        },
      });
    }

    if (SharedType.Comment === this.shareData?.type) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.CommentShare,
        },
      });
    }

    if (SharedType.Post === this.shareData?.type) {
      const eventParams: IEventParams = {};

      const { activeFeed } = this.feedFiltersStore;

      const player = this.playerStore.playerDetails;

      if (FeedTypes.Team === activeFeed) {
        eventParams.button_tap_type = ButtonTapsEnum.TeamProfileCopyLink;
        eventParams.team = this.teamsStatsStore.team?.name;
      }

      if (FeedTypes.Player === activeFeed && player) {
        eventParams.button_tap_type = ButtonTapsEnum.PlayerPostFeedCopyLink;
        eventParams.player = `${player.firstname} ${player.lastname}`;
      }

      if (activeFeed && FeedTypes.Team !== activeFeed && FeedTypes.Player !== activeFeed) {
        eventParams.button_tap_type = ButtonTapsEnum.HomeFeedCopyLink;
        eventParams.feed = activeFeed;
      }

      if (eventParams.button_tap_type) {
        GoogleAnalyticService.event({
          eventName: 'button_custom_tap',
          eventParams,
        });
      }
    }
  }

  public async copyLink() {
    if (this.shareData) {
      await this.writeToClipboard(this.shareData.link);
      this.googleAnalyticsShareLink();
    }
  }

  public reset() {
    super.reset();

    this.setIsShareModalVisible(false);
    this.setShareData(null);
  }
}
