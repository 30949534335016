import { ColDef } from 'ag-grid-community';

import { StatsMode } from 'stores/global-stats/enums/global-stats.enums';

import { PlayerNameCell } from 'components/team-stats/player-name-cell/player-name-cell.component';
import { RankedStatsCell } from 'components/team-stats/team-rank/team-rank.component';

import { objectValueFormatter } from '../base-table/helpers/object-value-formatter.util';

const TEAM_STATS_COLUMN_WIDTH = 47;
const PLAYERS_STATS_COLUMN_WIDTH = 48;
const TEAM_STATS_FIRST_COLUMN_WIDTH = 88;
const PLAYERS_STATS_FIRST_COLUMN_WIDTH = 170;
const MAX_UP_RANK = 10;
const MIN_DOWN_RANK = 21;

const cellRendererParams = {
  maxUpRank: MAX_UP_RANK,
  minDownRank: MIN_DOWN_RANK,
  role: StatsMode.TEAMS,
};

export const FIRST_PLAYER_STATS_COL_DEF: ColDef = {
  headerTooltip: 'Name',
  field: 'player',
  pinned: 'left',
  headerName: 'Name',
  headerClass: 'ag-players-stats-header-name',
  cellClass: 'ag-players-stats-left',
  cellRenderer: PlayerNameCell,
  width: PLAYERS_STATS_FIRST_COLUMN_WIDTH,
  valueFormatter: objectValueFormatter,
};

export const TEAM_STATS_COL_DEF: ColDef[] = [
  {
    headerTooltip: 'Type',
    field: 'type',
    headerName: 'Type',
    pinned: 'left',
    headerClass: 'ag-team-stats-header-name',
    cellClass: 'ag-team-stats-left',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    width: TEAM_STATS_FIRST_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Games Played',
    field: 'gamesPlayed',
    headerName: 'GP',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Points',
    field: 'points',
    headerName: 'PTS',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Field Goals Made',
    field: 'fieldGoalsMade',
    headerName: 'FGM',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Field Goals Attempted',
    field: 'fieldGoalsAttempted',
    headerName: 'FGA',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Field Goal Percentage',
    field: 'fieldGoalsPercentage',
    headerName: 'FG%',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: '3-Point Field Goals Made',
    field: 'threePointsFieldGoalsMade',
    headerName: '3PM',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: '3-Point Field Goals Attempted',
    field: 'threePointsFieldGoalsAttempted',
    headerName: '3PA',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: '3-Point Field Goal Percentage',
    field: 'threePointsFieldGoalsPercentage',
    headerName: '3P%',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Free Throws Made',
    field: 'freeThrowsMade',
    headerName: 'FTM',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Free Throws Attempted',
    field: 'freeThrowsAttempted',
    headerName: 'FTA',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Free Throw Percentage',
    field: 'freeThrowsPercentage',
    headerName: 'FT%',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Offensive Rebounds',
    field: 'offensiveReb',
    headerName: 'OR',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Defensive Rebounds',
    field: 'defensiveReb',
    headerName: 'DR',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Rebounds',
    field: 'totalRebounds',
    headerName: 'REB',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Assists',
    field: 'assists',
    headerName: 'AST',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Blocks',
    field: 'blocks',
    headerName: 'BLK',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Steals',
    field: 'steals',
    headerName: 'STL',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Personal Fouls',
    field: 'personalFouls',
    headerName: 'PF',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Turnovers',
    field: 'turnovers',
    headerName: 'TO',
    cellRenderer: RankedStatsCell,
    cellRendererParams,
    minWidth: TEAM_STATS_COLUMN_WIDTH,
  },
];

export const PLAYERS_STATS_COL_DEF: ColDef[] = [
  {
    headerTooltip: 'Position',
    field: 'position',
    headerName: 'POS',
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Games Played',
    field: 'gamesPlayed',
    headerName: 'GP',
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Points',
    field: 'points',
    headerName: 'PTS',
    sortable: true,
    sort: 'desc',
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Rebounds',
    field: 'totalRebounds',
    headerName: 'REB',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Offensive Rebounds',
    field: 'offensiveReb',
    headerName: 'OR',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Defensive Rebounds',
    field: 'defensiveReb',
    headerName: 'DR',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Assists',
    field: 'assists',
    headerName: 'AST',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Blocks',
    field: 'blocks',
    headerName: 'BLK',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Steals',
    field: 'steals',
    headerName: 'STL',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Personal Fouls',
    field: 'personalFouls',
    headerName: 'PF',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Turnovers',
    field: 'turnovers',
    headerName: 'TO',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Field Goals Made',
    field: 'fieldGoalsMade',
    headerName: 'FGM',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Field Goals Attempted',
    field: 'fieldGoalsAttempted',
    headerName: 'FGA',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Field Goal Percentage',
    field: 'fieldGoalsPercentage',
    headerName: 'FG%',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: '3-Point Field Goals Made',
    field: 'threePointsFieldGoalsMade',
    headerName: '3PM',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: '3-Point Field Goals Attempted',
    field: 'threePointsFieldGoalsAttempted',
    headerName: '3PA',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: '3-Point Field Goal Percentage',
    field: 'threePointsFieldGoalsPercentage',
    headerName: '3P%',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Free Throws Made',
    field: 'freeThrowsMade',
    headerName: 'FTM',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Free Throws Attempted',
    field: 'freeThrowsAttempted',
    headerName: 'FTA',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Free Throw Percentage',
    field: 'freeThrowsPercentage',
    headerName: 'FT%',
    sortable: true,
    cellRenderer: PlayerNameCell,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH,
  },
];

export const PLAYER_POSITION_FILTER_OPTIONS = [
  { label: 'All positions', value: 'ALL' },
  { label: 'Point Guard', value: 'PG' },
  { label: 'Shooting Guard', value: 'SG' },
  { label: 'Small Forward', value: 'SF' },
  { label: 'Power Forward', value: 'PF' },
  { label: 'Center', value: 'C' },
];
