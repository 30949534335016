import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { GalleryStore } from 'stores/gallery/gallery.store';
import { getPreviewImage } from 'stores/gallery/utils/get-preview-image.util';
import { PlayerStore } from 'stores/player/player.store';
import { PlayerPerformanceStore } from 'stores/player-performance/player-performance.store';

import { TYPES } from 'configs/di-types.config';

import { GalleryTheme } from 'components/modals/gallery-modal/gallery-modal.component';
import { Loader } from 'components/ui/loader/loader.component';

import defaultThumbnailUrl from 'assets/images/svg/player-without-team-background.svg';

import styles from './player-profile-thumbnail.module.less';

interface IPlayerProfileThumbnailContainerProps {
  isSmall?: boolean;
  isForProfile?: boolean;
}

export const PlayerProfileThumbnailContainer = observer(
  (props: IPlayerProfileThumbnailContainerProps) => {
    const { isSmall = false, isForProfile = false } = props;

    const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);
    const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
    const playerPerformanceStore = useInjection<PlayerPerformanceStore>(
      TYPES.PlayerPerformanceStore,
    );

    const backgroundImage = useMemo(() => {
      let thumbnailUrl = playerPerformanceStore.entry?.team?.bannerUrl || defaultThumbnailUrl;

      if (isForProfile) {
        thumbnailUrl = playerStore.playerDetails?.team?.bannerUrl || defaultThumbnailUrl;
      }

      return {
        backgroundImage: `url(${thumbnailUrl})`,
      };
    }, [playerPerformanceStore.entry, isForProfile, playerStore.playerDetails]);

    const playerProfileThumbnailClasses = useMemo<string>(
      () =>
        cn(styles.PlayerProfileThumbnail, {
          [styles['PlayerProfileThumbnail--small']]: isSmall,
        }),
      [isSmall],
    );

    const handleOpenPlayerPerformancePreview = useCallback(() => {
      const playerPerformance = playerPerformanceStore.entry;

      if (!playerPerformance?.playerInfo) {
        return;
      }

      const bannerUrl = playerPerformance.team.bannerUrl || defaultThumbnailUrl;
      const filename = `${playerPerformance.playerInfo.firstname.toLowerCase()}-${playerPerformance.playerInfo.lastname.toLowerCase()}-thumbnail`;

      const image = getPreviewImage(bannerUrl, filename);

      const watermarkEntity = {
        name: `${playerPerformance.playerInfo.firstname} ${playerPerformance.playerInfo.lastname}`,
        logoUrl: playerPerformance.playerInfo.smallLogoUrl,
      };

      galleryStore.setGalleryAttachments([image]);
      galleryStore.setTheme(GalleryTheme.Preview);
      galleryStore.setWatermarkEntity(watermarkEntity);
      galleryStore.setIsGalleryOpen(true);
    }, [galleryStore, playerPerformanceStore.entry]);

    const handleOpenPlayerProfilePreview = useCallback(() => {
      const playerDetail = playerStore.playerDetails;

      if (!playerDetail) {
        return;
      }

      const bannerUrl = playerDetail.team?.bannerUrl || defaultThumbnailUrl;
      const filename = `${playerDetail.firstname.toLowerCase()}-${playerDetail.lastname.toLowerCase()}-thumbnail`;

      const image = getPreviewImage(bannerUrl, filename);

      const watermarkEntity = {
        name: `${playerDetail.firstname} ${playerDetail.lastname}`,
        logoUrl: playerDetail.smallLogoUrl,
      };

      galleryStore.setGalleryAttachments([image]);
      galleryStore.setTheme(GalleryTheme.Preview);
      galleryStore.setWatermarkEntity(watermarkEntity);
      galleryStore.setIsGalleryOpen(true);
    }, [galleryStore, playerStore.playerDetails]);

    const handleThumbnailClick = useCallback(() => {
      if (isForProfile) {
        handleOpenPlayerProfilePreview();
      } else {
        handleOpenPlayerPerformancePreview();
      }
    }, [isForProfile, handleOpenPlayerProfilePreview, handleOpenPlayerPerformancePreview]);

    const isLoading = useMemo(() => {
      return !!(
        (isForProfile && playerStore.playerDetails) ||
        (!isForProfile && playerPerformanceStore.entry)
      );
    }, [playerPerformanceStore.entry, isForProfile, playerStore.playerDetails]);

    if (!isLoading) {
      return (
        <div className={styles.LoaderWrapper}>
          <Loader isShow withBackground />
        </div>
      );
    }

    return (
      <div
        role="presentation"
        className={playerProfileThumbnailClasses}
        style={backgroundImage}
        onClick={handleThumbnailClick}
      />
    );
  },
);
