import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Browser } from '@capacitor/browser';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { POSTS_AMOUNT_BEFORE_PRE_LOAD } from 'stores/entries/constants/entries.contstants';
import { HeadlinesStore } from 'stores/headlines/headlines.store';
import { LayoutStore } from 'stores/layout/layout.store';

import { SCROLL_TOP_ELEMENT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';

import { useInfiniteScroll } from 'hooks/use-infinite-scroll';
import { useMainProvider } from 'hooks/use-main-provider';

import { EmptyState } from 'components/empty-state/empty-state.component';
import { Headline } from 'components/headline/headline.component';
import { Loader } from 'components/ui/loader/loader.component';

import { EMPTY_STATE_MESSAGE, EMPTY_STATE_TITLE } from './headlines-feed.config';

import styles from './headlines-feed.module.less';

export const HeadlinesFeedContainer = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const headlinesStore = useInjection<HeadlinesStore>(TYPES.HeadlinesStore);

  const location = useLocation();

  const { isNativeApp } = useMainProvider();

  const setIntersectionObserver = useInfiniteScroll(
    headlinesStore.fetched && !headlinesStore.isLastPage,
    () => {
      headlinesStore.fetchNext();
    },
  );

  const isInitialLoading = useMemo(
    () => !headlinesStore.entries.length && headlinesStore.fetching,
    [headlinesStore.entries, headlinesStore.fetching],
  );

  const isDeafLoading = useMemo(
    () => !!headlinesStore.entries.length && headlinesStore.fetching,
    [headlinesStore.entries, headlinesStore.fetching],
  );

  const isEmptyFeed = useMemo(
    () => !headlinesStore.entries.length && headlinesStore.fetched,
    [headlinesStore.entries, headlinesStore.fetched],
  );

  const handleHeadlineClick = useCallback(
    (url: string, id: number) => () => {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.Headline,
          headline_id: id,
        },
      });

      if (isNativeApp) {
        Browser.open({ url });

        return;
      }

      window.open(url, '_blank');
    },
    [isNativeApp],
  );

  useEffect(() => {
    GoogleAnalyticService.event({
      eventName: 'screen_custom_view',
      eventParams: {
        screen_type: ScreenNamesEnum.HomeFeedHeadlines,
      },
    });

    headlinesStore.fetchHeadlines();
  }, [headlinesStore]);

  useEffect(() => {
    if (layoutStore.isPulledRefresher) {
      headlinesStore.fetchHeadlines();
    }
  }, [layoutStore.isPulledRefresher, headlinesStore]);

  useEffect(() => {
    if (isNativeApp && location.state) {
      Browser.open({ url: location.state });
    }
  }, [location, isNativeApp]);

  useEffect(() => {
    const topElement = document.getElementById(SCROLL_TOP_ELEMENT);

    topElement?.scrollIntoView();
  });

  return (
    <div className={styles.HeadlinesFeed}>
      <Loader isShow={isInitialLoading} />
      <Loader isShow={isDeafLoading} isFeedRefresh />
      {headlinesStore.entries.map((headline, index: number) => (
        <div
          key={headline.id}
          className={styles.HeadlinesFeed__Item}
          ref={
            headlinesStore.entries.length < index + POSTS_AMOUNT_BEFORE_PRE_LOAD
              ? setIntersectionObserver
              : undefined
          }
        >
          <Headline
            key={headline.id}
            id={headline.id}
            url={headline.url}
            title={headline.title}
            iconUrl={headline.iconUrl}
            onClick={handleHeadlineClick}
          />
        </div>
      ))}
      <Loader isShow={isDeafLoading} isLocal />
      {isEmptyFeed && (
        <div className={styles.HeadlinesFeed__EmptyState}>
          <EmptyState title={EMPTY_STATE_TITLE} message={EMPTY_STATE_MESSAGE} />
        </div>
      )}
    </div>
  );
});
