import { useEffect, ReactElement, useRef, FC } from 'react';
import 'rc-tooltip/assets/bootstrap.css';
import Slider from 'rc-slider';
import type { SliderProps } from 'rc-slider';
import raf from 'rc-util/lib/raf';
import Tooltip from 'rc-tooltip';

export interface IHandleTooltipProps {
  value: number;
  children: ReactElement;
  visible: boolean;
  tipFormatter?: (value: number) => string;
}

const HandleTooltip: FC<IHandleTooltipProps> = (props) => {
  const { value, children, visible, tipFormatter = () => '', ...restProps } = props;

  // Any because library doesn't provide special type for this
  const tooltipRef = useRef<any>();
  const rafRef = useRef<number | null>(null);

  function cancelKeepAlign() {
    raf.cancel(rafRef.current!);
  }

  function keepAlign() {
    rafRef.current = raf(() => {
      tooltipRef.current?.forceAlign();
    });
  }

  useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible]);

  return (
    <Tooltip
      placement="top"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{ minHeight: 'auto' }}
      ref={tooltipRef}
      visible={visible}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

export const SliderWithTooltip = ({
  tipFormatter,
  ...props
}: SliderProps & { tipFormatter?: (value: number) => string }) => {
  const tipHandleRender: SliderProps['handleRender'] = (node, onHandleProps) => {
    return (
      <HandleTooltip
        value={onHandleProps.value}
        visible={onHandleProps.dragging}
        tipFormatter={tipFormatter}
      >
        {node}
      </HandleTooltip>
    );
  };

  return <Slider {...props} handleRender={tipHandleRender} />;
};
