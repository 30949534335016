import { Dialog } from '@capacitor/dialog';
import { IOSSettings, NativeSettings } from 'capacitor-native-settings';

export const handleIosCameraDenied = async () => {
  const { value } = await Dialog.confirm({
    title: 'Please Allow Access',
    message:
      'Digits needs camera access so that you can take photos or videos. Please go to your device settings > Privacy > Camera and set Digits to ON.',
    okButtonTitle: 'Settings',
    cancelButtonTitle: 'Not now',
  });

  if (value) {
    await NativeSettings.openIOS({ option: IOSSettings.App });
  }
};
