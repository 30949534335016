import { FC, useMemo } from 'react';

import styles from './game-stats-slider.module.less';

interface IGameStatsSliderProps {
  homeColor: string;
  homeValue: number;
  visitorsColor: string;
  visitorsValue: number;
}

export const GameStatsSlider: FC<IGameStatsSliderProps> = (props) => {
  const { homeColor, homeValue, visitorsColor, visitorsValue } = props;

  const [leftSide, rightSide] = useMemo(() => {
    const sum = homeValue + visitorsValue;
    if (!sum) {
      return [50, 50];
    }

    const leftSidePercent = (visitorsValue / sum) * 100;
    const rightSidePercent = (homeValue / sum) * 100;

    return [leftSidePercent, rightSidePercent];
  }, [homeValue, visitorsValue]);

  const leftSliderStyles = useMemo(() => {
    return {
      width: `${leftSide}%`,
      backgroundColor: visitorsColor,
      border: `0.1rem solid ${visitorsColor}`,
      borderTopLeftRadius: '0.5rem',
      borderBottomLeftRadius: '0.5rem',
    };
  }, [leftSide, visitorsColor]);

  const rightSliderStyles = useMemo(() => {
    return {
      width: `${rightSide}%`,
      backgroundColor: homeColor,
      border: `0.1rem solid ${homeColor}`,
      borderTopRightRadius: '0.5rem',
      borderBottomRightRadius: '0.5rem',
    };
  }, [rightSide, homeColor]);

  return (
    <div className={styles.Slider}>
      <div className={styles.Slider__Section} style={leftSliderStyles} />
      {!!leftSide && !!rightSide && <div className={styles.Slider__Divider} />}
      <div className={styles.Slider__Section} style={rightSliderStyles} />
    </div>
  );
};
