import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import cn from 'classnames';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IGame } from 'services/statistics/interface/game.interface';

import { IPlayerDetails } from 'stores/player/interfaces/player-profile.interfaces';
import { IPlayerStatsMini } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStatsColors } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { IAboutData } from 'containers/team-profile/team-profile-overview/team-profile-overview.container';
import { ITeamStatsRow } from 'containers/team-stats/team-stats.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';
import { PLAYER_SLUG_PARAM } from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { RecentSplits } from 'components/player-profile/player-profile-games/recent-splits/recent-splits.component';
import { Statistics } from 'components/statistics/statistics.component';
import { TeamStatsTable } from 'components/team-stats/team-stats-table/team-stats-table.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import {
  PlayerCard,
  PlayerCardStatsThemeEnum,
} from 'components/ui/player-card/player-card.component';
import { StatsBox, StatsBoxTheme } from 'components/ui/stats-box/stats-box.component';

import { ISingleSplitItem } from '../player-profile-games/player-profile-games.interface';
import { PLAYER_RANKED_STATS_COL_DEF } from '../player-profile-tables/player-profile-tables.config';

import playerProfileStyles from '../player-profile.module.less';
import styles from './player-profile-overview.module.less';

interface IPlayerProfileOverviewProps {
  currentTeamId: Maybe<number>;
  player: Maybe<IPlayerDetails>;
  aboutThePlayer: IAboutData[];
  teamColors?: ITeamsStatsColors;
  recentGames: IGame[];
  recentSplits: ISingleSplitItem[];
  teammates: IPlayerStatsMini[];
  playerStatsRowData: ITeamStatsRow[];
  onTeamClick: (teamId: number) => void;
}

export const PlayerProfileOverview: FC<IPlayerProfileOverviewProps> = (props) => {
  const {
    player,
    currentTeamId,
    aboutThePlayer,
    teamColors = null,
    recentGames,
    recentSplits,
    teammates,
    playerStatsRowData,
  } = props;

  const [isExtantPlayerInfo, setIsExtantPlayerInfo] = useState(false);

  const navigate = useNavigate();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const params = useParams<{ [PLAYER_SLUG_PARAM]: string }>();

  const playerSlug = params[PLAYER_SLUG_PARAM];

  const recentGamesSectionClasses = useMemo(
    () => cn(styles.RecentGamesSection, TOUCH_IGNORE_CLASS),
    [],
  );

  const aboutThePlayerSectionClasses = useMemo(
    () =>
      cn(styles.AboutThePlayerSection, {
        [styles['AboutThePlayerSection--extant']]: isExtantPlayerInfo,
      }),
    [isExtantPlayerInfo],
  );

  const sendShowAllActionToGa = useCallback(() => {
    if (player) {
      const { firstname, lastname } = player;

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PlayerProfileProfileSeeAll,
          player: `${firstname} ${lastname}`,
        },
      });
    }
  }, [player]);

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(paths.PLAYER_PROFILE, { [paths.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  const handleNavigateScores = useCallback(() => {
    if (currentTeamId) {
      sendShowAllActionToGa();

      navigate(
        getPath(paths.TEAM_SCORES, {
          [paths.TEAM_ID_PARAM]: currentTeamId.toString(),
        }),
      );
    }
  }, [currentTeamId, sendShowAllActionToGa, navigate]);

  const handleNavigateToStats = useCallback(() => {
    if (player) {
      sendShowAllActionToGa();

      navigate(getPath(paths.PLAYER_PROFILE_STATS, { [paths.PLAYER_SLUG_PARAM]: player.slug }));
    }
  }, [player, sendShowAllActionToGa, navigate]);

  const handleNavigateToGames = useCallback(() => {
    if (player) {
      sendShowAllActionToGa();

      navigate(getPath(paths.PLAYER_PROFILE_GAMES, { [paths.PLAYER_SLUG_PARAM]: player.slug }));
    }
  }, [player, sendShowAllActionToGa, navigate]);

  const handleGameClick = useCallback(
    (gameId: number) => {
      if (playerSlug) {
        GoogleAnalyticService.event({
          eventName: 'screen_custom_view',
          eventParams: {
            screen_type: ScreenNamesEnum.PlayerPerformance,
            player: playerSlug,
            entry_point: ScreenNamesEnum.PlayerProfileProfile,
          },
        });

        navigate(
          getPath(paths.PLAYER_PERFORMANCE, {
            [paths.PLAYER_SLUG_PARAM]: playerSlug,
            [paths.GAME_ID_PARAM]: gameId.toString(),
          }),
        );
      }
    },
    [navigate, playerSlug],
  );

  const handlePersonalInfoShowMoreClick = useCallback(() => {
    setIsExtantPlayerInfo(true);

    if (player) {
      const { firstname, lastname } = player;

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PlayerProfileProfileAboutPlayerShowMore,
          player: `${firstname} ${lastname}`,
        },
      });
    }
  }, [player]);

  return (
    <div className={playerProfileStyles.PlayerProfile}>
      <div className={playerProfileStyles.PlayerProfile__Section}>
        <div className={playerProfileStyles.PlayerProfile__Header}>About Player</div>
        <div className={aboutThePlayerSectionClasses}>
          {aboutThePlayer.map(({ content, title, ...rest }) => (
            <StatsBox
              key={title}
              theme={StatsBoxTheme.Team}
              title={title}
              content={content}
              {...rest}
            />
          ))}
          {!isExtantPlayerInfo && !isDesktopPlus && (
            <Button
              onClick={handlePersonalInfoShowMoreClick}
              theme={ButtonTheme.Text}
              size={ButtonSize.Small}
            >
              Show {aboutThePlayer.length - 2} more
            </Button>
          )}
        </div>
      </div>
      {!!recentGames.length && (
        <div className={playerProfileStyles.PlayerProfile__Section}>
          <div className={playerProfileStyles.PlayerProfile__HeaderWrapper}>
            <div className={playerProfileStyles.PlayerProfile__Header}>Recent Games</div>
            <Button onClick={handleNavigateScores} theme={ButtonTheme.Text} size={ButtonSize.Small}>
              Show All
            </Button>
          </div>
          <div className={recentGamesSectionClasses}>
            <Statistics games={recentGames} onGameClick={handleGameClick} />
          </div>
        </div>
      )}
      {!!recentSplits.length && (
        <div className={playerProfileStyles.PlayerProfile__Section}>
          <div className={playerProfileStyles.PlayerProfile__HeaderWrapper}>
            <div className={playerProfileStyles.PlayerProfile__Header}>Recent Game Logs</div>
            <Button
              onClick={handleNavigateToGames}
              theme={ButtonTheme.Text}
              size={ButtonSize.Small}
            >
              Show All
            </Button>
          </div>
          <RecentSplits games={recentSplits} player={player} onTeamClick={props.onTeamClick} />
        </div>
      )}
      {!!playerStatsRowData.length && (
        <div className={playerProfileStyles.PlayerProfile__Section}>
          <div className={playerProfileStyles.PlayerProfile__HeaderWrapper}>
            <div className={playerProfileStyles.PlayerProfile__Header}>Stats</div>
            <Button
              onClick={handleNavigateToStats}
              theme={ButtonTheme.Text}
              size={ButtonSize.Small}
            >
              Show All
            </Button>
          </div>
          <div className={styles.StatsSection}>
            <TeamStatsTable
              tableGridOption={{
                rowData: playerStatsRowData,
                columnDefs: PLAYER_RANKED_STATS_COL_DEF,
                gridId: '1',
              }}
              withColors={!!teamColors?.main}
            />
          </div>
        </div>
      )}
      {!!teammates.length && (
        <div className={playerProfileStyles.PlayerProfile__Section}>
          <div className={playerProfileStyles.PlayerProfile__Header}>Other Team Players</div>
          <div className={styles.TeammatesSection}>
            {teammates.map((teammate) => (
              <PlayerCard
                key={teammate.id}
                player={teammate}
                teamColors={teamColors}
                onPlayerClick={handlePlayerClick}
                theme={PlayerCardStatsThemeEnum.TwoRows}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
