import { FC } from 'react';

import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutHeaderLeftActionEnum } from 'stores/layout/interfaces/layout-header-left.interface';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { PlayerPerformanceContainer } from 'containers/player-performance/player-performance.container';
import { PlayerProfileThumbnailContainer } from 'containers/player-profile/player-profile-thumbnail/player-profile-thumbnail.container';

import { useEnableRefresher } from 'hooks/use-enable-refresher';
import { useLayoutEntity } from 'hooks/use-layout-entity';

import { Row, RowLayout } from 'components/ui/grid/row/row.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const PlayerPerformanceRoute: FC = () => {
  useLayoutEntity({ type: LayoutEntity.PageTitle, value: 'Performance' });

  useLayoutEntity({
    type: LayoutEntity.HeaderLeft,
    value: { icon: IconFontName.ChevronLeft, type: LayoutHeaderLeftActionEnum.Back },
  });

  useEnableRefresher();

  return (
    <PageWrapperContainer>
      <PlayerProfileThumbnailContainer isSmall />
      <Row layout={RowLayout.Regular}>
        <PlayerPerformanceContainer />
      </Row>
    </PageWrapperContainer>
  );
};
