import { memo, useCallback, useEffect, useRef } from 'react';
import { GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { BaseTableGrid } from 'components/base-table/base-table.component';

import './ag-theme-total-stats.less';

interface IPlayerStatsTableProps {
  tableGridOption: GridOptions;
}

export const TotalStatsTable = memo((props: IPlayerStatsTableProps) => {
  const { tableGridOption } = props;
  const agGridRef = useRef<AgGridReact>(null);

  const updateColumnDefinitions = useCallback((columnDefs: any) => {
    if (agGridRef?.current?.api) {
      agGridRef.current.api?.setColumnDefs(columnDefs);
    }
  }, []);

  useEffect(() => {
    if (agGridRef.current && tableGridOption.rowData) {
      agGridRef.current?.api?.setRowData(tableGridOption.rowData);
    }
  }, [tableGridOption.rowData]);

  useEffect(() => {
    updateColumnDefinitions(tableGridOption?.columnDefs);
  }, [updateColumnDefinitions, tableGridOption]);

  return (
    <div className="ag-theme-total-stats">
      <BaseTableGrid hideHeader ref={agGridRef} gridOptions={tableGridOption} />
    </div>
  );
});
