import { FC, useCallback, useMemo, useState, SyntheticEvent } from 'react';
import cn from 'classnames';
import Slider from 'rc-slider';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import 'rc-slider/assets/index.css';

import { IModal } from 'components/modals/base-modal/base-modal.component';
import { Portal, PortalType } from 'components/ui/portal/portal.component';
import { useResponsive } from 'hooks/use-responsive';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import {
  CROP_INITIAL_SCALE,
  CROP_MAX_SCALE,
  CROP_MIN_SCALE,
  CROP_SCALE_STEP,
  CROP_SLIDER_STEP,
} from 'configs/controls.config';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IFileData, toBase64 } from 'helpers/to-base64.util';
import { ImageCropModalHeader } from 'components/modals/image-crop-modal/components/image-crop-modal-header/image-crop-modal-header.component';
import { IconButton } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import {
  sliderPointActiveColour,
  sliderPointActiveHeight,
  sliderPointActiveMargin,
  sliderPointActiveWidth,
  sliderTrackColour,
  sliderTrackHeight,
} from 'components/modals/image-crop-modal/image-crop-modal.config';
import getCroppedImg from './crop-image.utils';

import styles from './image-crop-modal.module.less';

export interface IImageCropModalProps extends IModal {
  fileUrl: string;
  isCropCircle: boolean;
  onClose: () => void;
  onSaveAvatar?: (image: IFileData) => void;
}

const defaultCroppedArea = {
  width: 0,
  height: 0,
  x: 0,
  y: 0,
};

export const ImageCropModal: FC<IImageCropModalProps> = (props: IImageCropModalProps) => {
  const { visible, onClose, fileUrl, onSaveAvatar, isCropCircle } = props;
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [scale, setScale] = useState(CROP_INITIAL_SCALE);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(defaultCroppedArea);

  const handleCropComplete = useCallback((_croppedArea: Area, croppedAreaPixelsParam: Area) => {
    setCroppedAreaPixels(croppedAreaPixelsParam);
  }, []);

  const wrapperClassNames = useMemo<string>(
    () =>
      cn(styles.ModalWrapper, {
        [styles['ModalWrapper--mobile']]: !isDesktopPlus,
      }),
    [isDesktopPlus],
  );

  const titleText = useMemo(() => {
    if (isCropCircle) {
      return 'Crop your photo';
    }
    return 'Crop your background image';
  }, [isCropCircle]);

  const cropShape = useMemo(() => {
    if (isCropCircle) {
      return 'round';
    }
    return 'rect';
  }, [isCropCircle]);

  const aspectRatio = useMemo(() => {
    if (isCropCircle) {
      return 1;
    }
    return 16 / 9;
  }, [isCropCircle]);

  const handleOnSave = useCallback(async () => {
    const localImage = await getCroppedImg(fileUrl, croppedAreaPixels);

    if (localImage) {
      const base64File = await toBase64(localImage as Blob);
      if (base64File && onSaveAvatar) {
        onSaveAvatar(base64File);
      }
    }

    onClose();
  }, [onSaveAvatar, onClose, fileUrl, croppedAreaPixels]);

  const handleScaleChange = useCallback((value: number | number[]) => {
    // using as due to library types restriction
    setScale(value as number);
  }, []);

  const handleZoomOut = useCallback(() => {
    if (scale - CROP_SCALE_STEP >= CROP_MIN_SCALE) {
      setScale(scale - CROP_SCALE_STEP);
    }
  }, [scale]);

  const handleZoomIn = useCallback(() => {
    if (scale + CROP_SCALE_STEP <= CROP_MAX_SCALE) {
      setScale(scale + CROP_SCALE_STEP);
    }
  }, [scale]);

  const handleSetCrop = useCallback(
    (location: Point) => {
      setCrop(location);
    },
    [setCrop],
  );

  const handleZoomChange = useCallback(
    (zoom: number) => {
      setScale(zoom);
    },
    [setScale],
  );

  const handleWrapperClick = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
  }, []);

  return visible && fileUrl ? (
    <Portal type={PortalType.CropModal}>
      <div
        tabIndex={0}
        role="button"
        onClick={handleWrapperClick}
        onKeyDown={handleWrapperClick}
        className={wrapperClassNames}
      >
        {isDesktopPlus && <ImageCropModalHeader text={titleText} onButtonClick={props.onClose} />}
        <div className={styles.ModalWrapper__Image}>
          <Cropper
            classes={styles.Cropper}
            image={fileUrl}
            crop={crop}
            zoom={scale}
            showGrid={false}
            minZoom={CROP_MIN_SCALE}
            maxZoom={CROP_MAX_SCALE}
            aspect={aspectRatio}
            cropShape={cropShape}
            onCropChange={handleSetCrop}
            onCropComplete={handleCropComplete}
            onZoomChange={handleZoomChange}
          />
        </div>
        {isDesktopPlus && (
          <div className={styles.ModalWrapper__Slider}>
            <IconButton iconName={IconFontName.Minus} onClick={handleZoomOut} />
            <Slider
              className={styles.RootSlider}
              trackStyle={{
                backgroundColor: sliderTrackColour,
                height: sliderTrackHeight,
              }}
              handleStyle={{
                height: sliderPointActiveHeight,
                width: sliderPointActiveWidth,
                marginTop: sliderPointActiveMargin,
                backgroundColor: sliderPointActiveColour,
                border: 'none',
              }}
              value={scale}
              min={CROP_MIN_SCALE}
              max={CROP_MAX_SCALE}
              step={CROP_SLIDER_STEP}
              onChange={handleScaleChange}
            />
            <IconButton iconName={IconFontName.Add} onClick={handleZoomIn} />
          </div>
        )}
        <div className={styles.ModalWrapper__Buttons}>
          <Button
            fluid
            theme={ButtonTheme.Secondary}
            size={ButtonSize.Small}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button fluid theme={ButtonTheme.Primary} size={ButtonSize.Small} onClick={handleOnSave}>
            Apply
          </Button>
        </div>
      </div>
    </Portal>
  ) : null;
};
