import { FC } from 'react';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { TeamStatsContainer } from 'containers/team-stats/team-stats.container';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const TeamStatsRoute: FC = () => {
  return (
    <PageWrapperContainer>
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <TeamStatsContainer />
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
