import { FC, memo, useCallback, useMemo } from 'react';
import { Browser } from '@capacitor/browser';
import cn from 'classnames';

import { INews } from 'stores/news/interfaces/news.interface';

import { useMainProvider } from 'hooks/use-main-provider';

import styles from './news-card.module.less';

export enum NewsTheme {
  Dark,
  Light,
}

export interface INewsCardProps {
  newsData: INews;
  theme?: NewsTheme;
  onContentClick: (url: string) => void;
}

export const NewsCard: FC<INewsCardProps> = memo((props: INewsCardProps) => {
  const { theme = NewsTheme.Dark, newsData, onContentClick } = props;

  const { isNativeApp } = useMainProvider();

  const { title, newsUrl, formattedDates, sourceName, sourceFaviconUrl, previewImageUrl } =
    newsData;

  const handleContentClick = useCallback(() => {
    if (isNativeApp) {
      Browser.open({ url: newsUrl });
      return;
    }
    onContentClick(newsUrl);
  }, [newsUrl, isNativeApp, onContentClick]);

  const cardClassNames = useMemo(
    () => cn(styles.NewsCard, { [styles['NewsCard--light']]: theme === NewsTheme.Light }),
    [theme],
  );

  return (
    <div className={cardClassNames}>
      <div className={styles.NewsCard__Header}>
        <div className={styles.NewsCardHeader__SourceName}>{sourceName}</div>
        <img
          className={styles.NewsCardHeader__SourceLogo}
          src={sourceFaviconUrl}
          alt={sourceName}
        />
      </div>
      <div
        role="button"
        tabIndex={0}
        aria-label="Content"
        className={styles.NewsCard__Content}
        onClick={handleContentClick}
        onKeyDown={handleContentClick}
      >
        <div className={styles.NewsCardContent__Title}>{title}</div>
        {previewImageUrl && (
          <img className={styles.NewsCardContent__Image} src={previewImageUrl} alt={title} />
        )}
        <p className={styles.NewsCardContent__Time}>{formattedDates.relativeLong}</p>
      </div>
    </div>
  );
});
