import { memo, useMemo } from 'react';
import cn from 'classnames';

import { IGame } from 'services/statistics/interface/game.interface';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './shortcut-mini-games.module.less';

interface IShortcutMiniGamesProps {
  expanded: boolean;
  games: IGame[];
}

export const ShortcutMiniGames = memo((props: IShortcutMiniGamesProps) => {
  const { expanded, games } = props;

  const shortcutMiniGamesClasses = useMemo(
    () =>
      cn(styles.ShortcutMiniGames, {
        [styles['ShortcutMiniGames--visible']]: expanded,
      }),
    [expanded],
  );

  return (
    <div className={shortcutMiniGamesClasses}>
      {games.map((game) => (
        <div key={game.id} className={styles.ShortcutMiniGames__Group}>
          <div className={styles.ShortcutMiniGames__Item}>
            <Avatar
              src={game.teams.visitors.smallLogoUrl}
              username={game.teams.visitors.name}
              size={AvatarSize.XS}
            />
          </div>
          <div className={styles.ShortcutMiniGames__Item}>
            <Avatar
              src={game.teams.home.smallLogoUrl}
              username={game.teams.home.name}
              size={AvatarSize.XS}
            />
          </div>
        </div>
      ))}
      {!games.length && (
        <div className={styles.ShortcutMiniGames__Empty}>
          No <span>Games</span>
        </div>
      )}
    </div>
  );
});
