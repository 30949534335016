import { FC, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { addDays, compareAsc, format, subDays } from 'date-fns';

import { DAY_FORMAT } from 'configs/date.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { CalendarTooltip } from '../calendar-tooltip/calendar-tooltip.component';
import { IconButton, IconButtonTheme } from '../icon-button/icon-button.component';
import { IconFontName } from '../icon-font/icon-font.component';
import { TabsSwitcher } from '../tabs-switcher/tabs-switcher.component';
import { TooltipEventType } from '../tooltip/tooltip.component';

import styles from './week-bar.module.less';

interface IWeekItem {
  id: string;
  element: string;
}

export interface IWeekBarProps {
  activeDate: Date;
  disabled: boolean;
  onDateClick: (date: Maybe<Date>) => void;
}

const ANIMATION_TIME = 150;

export const WeekBar: FC<IWeekBarProps> = (props: IWeekBarProps) => {
  const { activeDate, onDateClick, disabled } = props;
  const [visibleWeek, setVisibleWeek] = useState({ prev: false, curr: true, next: false });
  const [skipTransition, setSkipTransition] = useState(false);
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const visibleDays = useMemo(() => {
    if (isDesktopPlus) {
      return { nextToActive: 3, total: 7 };
    }
    return { nextToActive: 1, total: 3 };
  }, [isDesktopPlus]);

  const items = useMemo(() => {
    const dates = [];
    for (let i = 0; i < visibleDays.nextToActive; i += 1) {
      const after = addDays(activeDate, i + 1);
      const before = subDays(activeDate, i + 1);
      dates.push(after, before);
      if (i === visibleDays.nextToActive - 1) {
        dates.push(activeDate);
        dates.sort(compareAsc);
      }
    }

    return dates.map((date) => {
      const formatted = format(date, DAY_FORMAT);
      return { id: date.toISOString(), element: formatted };
    });
  }, [activeDate, visibleDays.nextToActive]);

  const getPrevItems = useCallback(
    (visibleItems: IWeekItem[]) =>
      visibleItems.map((item) => {
        const formattedDate = format(
          subDays(new Date(item.element), visibleDays.total),
          DAY_FORMAT,
        );
        return { id: formattedDate, element: formattedDate };
      }),
    [visibleDays.total],
  );

  const getNextItems = useCallback(
    (visibleItems: IWeekItem[]) =>
      visibleItems.map((item) => {
        const formattedDate = format(
          addDays(new Date(item.element), visibleDays.total),
          DAY_FORMAT,
        );
        return { id: formattedDate, element: formattedDate };
      }),
    [visibleDays.total],
  );

  const handleItemClick = useCallback(
    (date: string) => {
      if (disabled) {
        return;
      }

      onDateClick(new Date(date));
    },
    [onDateClick, disabled],
  );

  const handleCalendarChange = useCallback(
    (date: Maybe<Date>) => {
      if (disabled) {
        return;
      }

      onDateClick(date);
    },
    [onDateClick, disabled],
  );

  const weekTabsClassNames = useMemo(
    () =>
      classNames(styles.WeekBar__Tabs, {
        [styles['WeekBar__Tabs--skip-transition']]: skipTransition,
        [styles['WeekBar__Tabs--prev']]: visibleWeek.prev,
        [styles['WeekBar__Tabs--curr']]: visibleWeek.curr,
        [styles['WeekBar__Tabs--next']]: visibleWeek.next,
      }),
    [visibleWeek.prev, visibleWeek.curr, visibleWeek.next, skipTransition],
  );

  const handleLeftClick = useCallback(() => {
    if (disabled) {
      return;
    }

    setSkipTransition(false);
    setVisibleWeek({ prev: true, curr: false, next: false });

    setTimeout(() => {
      onDateClick(subDays(activeDate, visibleDays.total));
      setVisibleWeek({ prev: false, curr: true, next: false });
      setSkipTransition(true);
    }, ANIMATION_TIME);
  }, [disabled, activeDate, onDateClick, visibleDays.total]);

  const handleRightClick = useCallback(() => {
    if (disabled) {
      return;
    }

    setSkipTransition(false);
    setVisibleWeek({ prev: false, curr: false, next: true });

    setTimeout(() => {
      onDateClick(addDays(activeDate, visibleDays.total));
      setVisibleWeek({ prev: false, curr: true, next: false });
      setSkipTransition(true);
    }, ANIMATION_TIME);
  }, [disabled, activeDate, onDateClick, visibleDays.total]);

  return (
    <div className={styles.WeekBar}>
      <IconButton
        disabled={disabled}
        onClick={handleLeftClick}
        iconName={IconFontName.ChevronLeft}
        theme={IconButtonTheme.Transparent}
      />
      <div className={weekTabsClassNames}>
        <TabsSwitcher
          disabled={disabled}
          activeId={activeDate.toISOString()}
          items={getPrevItems(items)}
          onItemClick={handleItemClick}
        />
        <TabsSwitcher
          disabled={disabled}
          activeId={activeDate.toISOString()}
          items={items}
          onItemClick={handleItemClick}
        />
        <TabsSwitcher
          disabled={disabled}
          activeId={activeDate.toISOString()}
          items={getNextItems(items)}
          onItemClick={handleItemClick}
        />
      </div>
      <IconButton
        onClick={handleRightClick}
        disabled={disabled}
        iconName={IconFontName.ChevronRight}
        theme={IconButtonTheme.Transparent}
      />
      <CalendarTooltip
        eventType={TooltipEventType.click}
        calendarValue={activeDate}
        onChangeCalendar={handleCalendarChange}
      >
        <IconButton
          disabled={disabled}
          iconName={IconFontName.Calendar}
          theme={IconButtonTheme.Transparent}
        />
      </CalendarTooltip>
    </div>
  );
};
