import { useCallback, useEffect, useMemo } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { ScoreboardStore } from 'stores/scoreboard/scoreboard.store';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useGaScreenCustomView } from 'hooks/use-ga-screen-custom-view';
import { useTeamClick } from 'hooks/use-team-click';
import { useToggleIgnoredTouchClass } from 'hooks/use-toggle-ignored-touch-class';

import { GameDetailsTabs } from 'components/game/game.config';
import { Scoreboard } from 'components/scoreboard/scoreboard.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './scoreboard.module.less';

export const ScoreboardContainer = observer(() => {
  const scoreboardStore = useInjection<ScoreboardStore>(TYPES.ScoreboardStore);

  const params = useParams<{
    [paths.TEAM_ID_PARAM]: string;
  }>();

  const { teamId } = params;

  const navigate = useNavigate();

  const { googleAnalyticsHomeFeed, googleAnalyticsTeamFeed } = useGaScreenCustomView();

  useToggleIgnoredTouchClass(!scoreboardStore.fetched);

  const handlePlayerClick = useCallback(
    (slug: string, gameId: string) => {
      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.PlayerPerformance,
          player: slug,
          entry_point: ScreenNamesEnum.HomeScores,
        },
      });

      navigate(
        getPath(paths.PLAYER_PERFORMANCE, {
          [paths.PLAYER_SLUG_PARAM]: slug,
          [paths.GAME_ID_PARAM]: gameId,
        }),
      );
    },
    [navigate],
  );

  const handleTeamClick = useTeamClick();

  const handleGameClick = useCallback(
    (gameId: number, tabQuery: Maybe<GameDetailsTabs>) => {
      const path = getPath(paths.ARENA_GAME, { [paths.GAME_ID_PARAM]: String(gameId) });

      if (tabQuery) {
        navigate({
          pathname: path,
          search: `${createSearchParams({ tab: tabQuery })}`,
        });
      } else {
        navigate(path);
      }
    },
    [navigate],
  );

  const loading = useMemo(() => {
    if (!teamId) {
      return scoreboardStore.fetching;
    }

    return scoreboardStore.fetching;
  }, [teamId, scoreboardStore.fetching]);

  useEffect(() => {
    if (teamId) {
      googleAnalyticsTeamFeed(ScreenNamesEnum.TeamProfileScores);
      return;
    }

    googleAnalyticsHomeFeed(ScreenNamesEnum.HomeScores);
  }, [teamId, googleAnalyticsHomeFeed, googleAnalyticsTeamFeed]);

  if (loading) {
    return (
      <div className={styles.Wrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    <Scoreboard
      timezone={scoreboardStore.timezone}
      formattedDates={scoreboardStore.formattedDates}
      games={scoreboardStore.entries}
      onPlayerClick={handlePlayerClick}
      onTeamClick={handleTeamClick}
      onGameClick={handleGameClick}
    />
  );
});
