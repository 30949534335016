import { memo } from 'react';

import arenaEmptyImage from 'assets/images/empty-arena-min.svg';

import styles from './game-empty.module.less';

export const GameEmpty = memo(() => {
  return (
    <div className={styles.GameEmpty}>
      <div className={styles.GameEmpty__Image}>
        <img src={arenaEmptyImage} alt="Arena empty" />
      </div>
      <h3 className={styles.GameEmpty__Title}>The Season is Over</h3>
      <p className={styles.GameEmpty__Text}>No games for today</p>
    </div>
  );
});
