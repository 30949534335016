import { Device } from '@capacitor/device';
import { AFInit, AppsFlyer } from 'appsflyer-capacitor-plugin';
import { appsFlyerLogger } from 'loggers/apps-flyer.logger';
import { TPlatformName } from 'types/platform-name.type';

import { APPLE_ID, APPS_FLYER_DEV_KEY, CAPACITOR_ENV } from 'configs/environment.config';

class AppsFlyerService {
  private platform: Maybe<TPlatformName>;

  private config: AFInit;

  constructor() {
    this.platform = 'web';

    this.config = {
      appID: '',
      devKey: APPS_FLYER_DEV_KEY,
      isDebug: true,
      minTimeBetweenSessions: 6,
      registerOnDeepLink: true,
      registerConversionListener: true,
      registerOnAppOpenAttribution: false,
      deepLinkTimeout: 4000,
    };
  }

  private get isApplication() {
    return this.platform === 'android' || this.platform === 'ios';
  }

  private get isProducation() {
    return CAPACITOR_ENV === 'prod';
  }

  private async androidInitialisation() {
    const response = await AppsFlyer.initSDK(this.config);

    appsFlyerLogger.info({ msg: `Response AppsFlyer initialisation (android): ${response.res}` });
  }

  private async iosInitialisation() {
    const response = await AppsFlyer.initSDK({
      ...this.config,

      appID: APPLE_ID,
      waitForATTUserAuthorization: 10,
      useReceiptValidationSandbox: true,
      useUninstallSandbox: true,
    });

    appsFlyerLogger.info({ msg: `Response AppsFlyer initialisation (ios): ${response.res}` });
  }

  public async setCustomerUserId(userId: string) {
    if (this.isApplication && this.isProducation) {
      await AppsFlyer.setCustomerUserId({ cuid: userId });

      appsFlyerLogger.info({ msg: 'Customer user id was sent' });
    }
  }

  public async initialisation() {
    const deviceInfo = await Device.getInfo();

    this.platform = deviceInfo.platform;

    if (this.platform === 'android' && this.isProducation) {
      await this.androidInitialisation();
    }

    if (this.platform === 'ios' && this.isProducation) {
      await this.iosInitialisation();
    }
  }
}

export default new AppsFlyerService();
