import { FC } from 'react';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';

import { useMobileLayoutStructure } from 'hooks/use-mobile-layout-structure';

import { GameEmpty } from 'components/game/game-empty/game-empty.component';
import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const EmptyArenaRoute: FC = () => {
  useMobileLayoutStructure({
    isHiddenHeader: true,
  });

  return (
    <PageWrapperContainer>
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <GameEmpty />
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
