import { useCallback, useEffect, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { FollowStore } from 'stores/follow/follow.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { PlayerStore } from 'stores/player/player.store';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { SplitsGamesStore } from 'stores/splits-games/splits-games.store';
import { ITeamsStatsColors } from 'stores/teams-stats/interfaces/teams-stats.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';
import { useTeamClick } from 'hooks/use-team-click';

import { GalleryTheme } from 'components/modals/gallery-modal/gallery-modal.component';
import { PlayerProfileInfo } from 'components/player-profile/player-profile-info/player-profile-info.component';
import { PlayerProfileOverview } from 'components/player-profile/player-profile-overview/player-profile-overview.component';

export const PlayerProfileOverviewContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const splitsGamesStore = useInjection<SplitsGamesStore>(TYPES.SplitsGamesStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);
  const followStore = useInjection<FollowStore>(TYPES.FollowStore);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const teamColors = useMemo<ITeamsStatsColors>(
    () => ({
      main: playerStore.playerDetails?.team?.color || null,
      text: playerStore.playerDetails?.team?.textColor || null,
    }),
    [playerStore.playerDetails],
  );

  const handleTeamClick = useTeamClick();

  const handleOpenPreview = useCallback(
    (image: IInteractiveMediaAttachment) => {
      const watermarkEntity = playerStore.playerDetails
        ? {
            name: `${playerStore.playerDetails.firstname} ${playerStore.playerDetails.lastname}`,
            logoUrl: playerStore.playerDetails.smallLogoUrl,
          }
        : null;

      galleryStore.setGalleryAttachments([image]);
      galleryStore.setTheme(GalleryTheme.Preview);
      galleryStore.setWatermarkEntity(watermarkEntity);
      galleryStore.setIsGalleryOpen(true);
    },
    [galleryStore, playerStore.playerDetails],
  );

  const handleFollowPlayer = useCallback(() => {
    if (playerStore.playerDetails) {
      const { firstname, lastname } = playerStore.playerDetails;

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PlayerProfileFollow,
          player: `${firstname} ${lastname}`,
        },
      });
    }

    if (authStore.triggerAuthorisationCheck()) {
      playerStore.followPlayer();
    }
  }, [playerStore, authStore]);

  useEffect(() => {
    if (playerStore.playerDetails?.team) {
      teamsStatsStore.retrieveRecentGames(playerStore.playerDetails.team.id);
    }
  }, [playerStore.playerDetails?.team, splitsGamesStore, teamsStatsStore]);

  useEffect(() => {
    if (playerStore.playerDetails) {
      const { firstname, lastname } = playerStore.playerDetails;

      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.PlayerProfileProfile,
          player: `${firstname} ${lastname}`,
        },
      });
    }
  }, [playerStore.playerDetails]);

  if (!playerStore.playerDetails) {
    return null;
  }

  return (
    <>
      <PlayerProfileInfo
        isFollowFetching={followStore.fetching}
        isFollowFetched={!!followStore.follow.length}
        isFollowedPlayer={playerStore.isFollowedPlayer}
        playerDetails={playerStore.playerDetails}
        onTeamClick={handleTeamClick}
        onOpenPreview={handleOpenPreview}
        onFollowPlayer={handleFollowPlayer}
      />
      <PlayerProfileOverview
        player={playerStore.playerDetails}
        currentTeamId={playerStore.currentTeamId}
        aboutThePlayer={playerStore.getAboutThePlayer(isDesktopPlus)}
        teamColors={teamColors}
        recentGames={teamsStatsStore.teamsGamesDetail}
        recentSplits={splitsGamesStore.entries}
        teammates={playerStore.teammates}
        playerStatsRowData={playerStore.playerStatsRowData}
        onTeamClick={handleTeamClick}
      />
    </>
  );
});
