import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AuthStore } from 'stores/auth/auth.store';

import { TYPES } from 'configs/di-types.config';
import { HOME_FEED } from 'routes/paths.constants';

interface IPrivateRouteProps {
  children: JSX.Element;
}

export const PrivateRoute = observer((props: PropsWithChildren<IPrivateRouteProps>) => {
  const { children } = props;
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const navigate = useNavigate();

  useEffect(() => {
    const timeoutInstance = setTimeout(() => {
      if (!authStore.isAuthorised) {
        navigate(HOME_FEED);
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutInstance);
    };
  }, [authStore.isAuthorised, navigate]);

  return children;
});
