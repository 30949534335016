import { ReactNode, useCallback } from 'react';

import { BookmarksContainer } from 'containers/bookmarks/bookmarks.container';

interface IProfileMainSectionProps {
  activeTab: ReactNode;
}

export const ProfileMainSection = (props: IProfileMainSectionProps) => {
  const { activeTab } = props;

  const renderComponent = useCallback(() => {
    if (activeTab === 'bookmarks') {
      return <BookmarksContainer />;
    }

    return null;
  }, [activeTab]);

  return <div>{renderComponent()}</div>;
};
