import { inject, injectable } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { GameChatService } from 'services/game-chat/game-chat.service';
import {
  IGameChatMessageResponse,
  IGameChatMessagesResponse,
  IGameChatMessageVoteDataPayload,
  IGetGameChatMessagePayload,
} from 'services/game-chat/interfaces/game-chat-messages.interface';
import { ICommentCreatePayload } from 'services/posts/interfaces/create-comment-payload.interface';
import { ReportsService } from 'services/reports/reports.service';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { GameStore } from 'stores/game/game.store';
import { findAndReplaceGameChatItem } from 'stores/game/helpers/games-chat-item.helpers';
import { gameChatMessageAdapter } from 'stores/game-chat/adapters/game-chat-message-adapter.util';
import {
  IGameChatMessage,
  IGameChatMessageAdapter,
} from 'stores/game-chat/interfaces/game-chat-messages.interface';
import { IoStore } from 'stores/io/io.store';
import { pollAdapter } from 'stores/posts/adapters/poll-adapter.util';
import { prepareReactions } from 'stores/posts/adapters/publication-adapter.util';

import { TYPES } from 'configs/di-types.config';

import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

export const COMMENTS_PAGINATION_LIMIT = 5;

@injectable()
export class GameChatStore extends AdvancedEntriesStore<
  IGameChatMessage,
  IGameChatMessageResponse,
  IGameChatMessagesResponse
> {
  private readonly gameChatMessagesService: GameChatService;

  private readonly gameStore: GameStore;

  private readonly ioStore: IoStore;

  private readonly reportsService: ReportsService;

  public processingCreateComment: boolean;

  public scrollCommentId: Maybe<string>;

  public lastJoinedRoomId: Maybe<number>;

  public polls: IGameChatMessage[];

  public isSubmittedContentReport: boolean;

  constructor(
    @inject(TYPES.GameStore) gameStore: GameStore,
    @inject(TYPES.IoStore) ioStore: IoStore,
    @inject(TYPES.GameChatService) gameChatMessagesService: GameChatService,
    @inject(TYPES.ReportsService) reportsService: ReportsService,
  ) {
    super(COMMENTS_PAGINATION_LIMIT);

    this.gameStore = gameStore;

    this.ioStore = ioStore;

    this.gameChatMessagesService = gameChatMessagesService;

    this.reportsService = reportsService;

    this.processingCreateComment = false;

    this.lastJoinedRoomId = null;

    this.polls = [];

    this.scrollCommentId = null;

    this.isSubmittedContentReport = false;

    makeObservable(this, {
      polls: observable,
      processingCreateComment: observable,
      scrollCommentId: observable,
      lastJoinedRoomId: observable,
      isSubmittedContentReport: observable,

      resetScrollCommentId: action.bound,
      setScrollCommentId: action.bound,
      setProcessingCreateComment: action.bound,
      setPolls: action.bound,
      handleNewChatMessage: action.bound,
      retrieveGameChat: action.bound,
      setLastJoinedRoomId: action.bound,
      leaveCurrentRoom: action.bound,
      setSubmittedContentReport: action.bound,
    });

    reaction(() => [this.gameStore.id, this.ioStore.socket], this.retrieveGameChat);
  }

  public async reconnectSocketConnection() {
    await this.ioStore.handleCreateSocket();
  }

  public async retrieveGameChat(): Promise<void> {
    if (this.lastJoinedRoomId) {
      this.leaveCurrentRoom();
    }

    if (this.gameStore.id) {
      await this.initialiseGameChat(this.gameStore.id);

      this.setLastJoinedRoomId(this.gameStore.id);
      const roomName = `games:id:${this.gameStore.id}:chat`;
      this.ioStore.socket.emit('join', { room: roomName });
      this.ioStore.socket.on(roomName, this.handleNewChatMessage);
    } else {
      this.reset();
      this.setPolls([]);
    }
  }

  public async refresh() {
    await super.refresh();
    await this.retrieveGameChat();
  }

  public leaveCurrentRoom() {
    const roomName = `games:id:${this.lastJoinedRoomId}:chat`;
    this.ioStore.socket.emit('leave', { room: roomName });
  }

  public resetScrollCommentId() {
    this.setScrollCommentId(null);
  }

  public setPolls(polls: IGameChatMessage[]) {
    this.polls = polls;
  }

  public setScrollCommentId(commentId: Maybe<string>) {
    this.scrollCommentId = commentId;
  }

  public setLastJoinedRoomId(id: Maybe<number>) {
    this.lastJoinedRoomId = id;
  }

  public setProcessingCreateComment(value: boolean) {
    this.processingCreateComment = value;
  }

  public setSubmittedContentReport(isSubmittedContentReport: boolean) {
    this.isSubmittedContentReport = isSubmittedContentReport;
  }

  public removeGameChatItemFromEntries(chatItemId: string) {
    const newEntries = this.entries.filter((chatItem) => chatItem.uuid !== chatItemId);

    this.setEntries(newEntries);
  }

  public async sendGameChatItemReport(
    gameChatItemId: string,
    gameId: number,
    reportReasonId: number,
  ) {
    this.setSubmittedContentReport(false);

    const response = await this.reportsService.sendGameChatItemReport(
      gameChatItemId,
      gameId,
      reportReasonId,
    );

    if (!response.success) {
      this.setErrors(response.errors);
    }

    this.setSubmittedContentReport(true);

    return response.success;
  }

  async initialiseGameChat(gameId: Maybe<number>) {
    this.reset();
    await super.initialise();
    if (gameId) {
      await this.fetchNextGameChatMessages(gameId);
      await this.fetchPolls(gameId);
    }
  }

  public async fetchNextGameChatMessages(gamedId: number): Promise<void> {
    await this.retrieveNext(
      this.gameChatMessagesService.fetchAllGameMessages(this.pagination, gamedId, 'comment'),
      <IGameChatMessageAdapter>gameChatMessageAdapter,
    );
  }

  public async handleNewChatMessage(message: string): Promise<void> {
    const parsedEvent: IGameChatMessageResponse = JSON.parse(message);

    if (
      parsedEvent.item_type === 'game_chat_comment' &&
      parsedEvent.game_id === this.gameStore.id
    ) {
      const newItem = gameChatMessageAdapter(parsedEvent);

      if (newItem) {
        this.setEntries([newItem, ...this.entries]);
      }
    }
  }

  public async fetchPolls(gameId: number): Promise<void> {
    const response = await this.gameChatMessagesService.fetchPolls({ gameId });

    if (response.success) {
      const newPolls = response.data.items.map((item) => gameChatMessageAdapter(item));
      this.setPolls(newPolls);
    } else {
      this.setErrors(response.errors);
    }
  }

  public async fetchSingleGameChatMessage(payload: IGetGameChatMessagePayload): Promise<void> {
    const response = await this.gameChatMessagesService.fetchSingleGameChatMessage(payload);

    if (response.success) {
      const newComment = gameChatMessageAdapter(response.data);

      if (newComment && newComment.itemType === 'game_chat_comment') {
        const newComments = findAndReplaceGameChatItem(this.entries, payload.messageId, newComment);
        this.setEntries(newComments);
      }
    } else {
      this.setErrors(response.errors);
    }
  }

  public async gameChatMessagePollVote(payload: IPollVoteData): Promise<void> {
    const pollResponse = await this.gameChatMessagesService.gameChatMessagePollVote(payload);

    if (pollResponse.success) {
      const newPoll = pollAdapter(pollResponse.data);

      const [currentComment] = this.entries.filter((item) => item.uuid === payload.entityId);

      if (currentComment && currentComment.itemType === 'game_chat_comment') {
        currentComment.attachments = {
          ...currentComment.attachments,
          poll: newPoll,
        };

        const newComments = findAndReplaceGameChatItem(
          this.entries,
          payload.entityId,
          currentComment,
        );
        this.setEntries(newComments);
      }
    } else {
      this.setErrors(pollResponse.errors);
    }
  }

  public async createGameChatMessage(
    gameId: number,
    comment: ICommentCreatePayload,
  ): Promise<Maybe<boolean>> {
    this.setProcessingCreateComment(true);

    const response = await this.gameChatMessagesService.createGameChatMessage({
      gameId,
      ...comment,
    });
    if (response.success) {
      const newComment = gameChatMessageAdapter(response.data);
      this.setEntries([newComment, ...this.entries]);
      this.setScrollCommentId(response.data.uuid);
      this.setProcessingCreateComment(false);
      return true;
    }

    if (response.code === 500 || response.code === 504) {
      const message = 'We were unable to create your comment at this time. Please try again later';

      const error: INotificationMessage = {
        message,
      };
      this.setErrors([error]);
    } else {
      this.setErrors(response.errors);
    }

    this.setProcessingCreateComment(false);

    return null;
  }

  public async deleteGameChatMessage(gameId: number, messageId: string): Promise<void> {
    const response = await this.gameChatMessagesService.deleteGameChatMessage({
      gameId,
      messageId,
    });
    if (response.success) {
      const newComments = [...this.entries.filter((item) => item.uuid !== messageId)];
      this.setEntries(newComments);
    } else if (response.code === 500 || response.code === 504) {
      const message = 'We were unable to delete your comment at this time. Please try again later';

      const error: INotificationMessage = {
        message,
      };
      this.setErrors([error]);
    } else {
      this.setErrors(response.errors);
    }
    this.setProcessingCreateComment(false);
  }

  public async gameChatMessageVoteUp(payload: IGameChatMessageVoteDataPayload): Promise<void> {
    const response = await this.gameChatMessagesService.gameChatMessageVoteUp(payload);

    if (response.success) {
      const [currentComment] = this.entries.filter((item) => item.uuid === payload.messageId);

      if (response.success && currentComment) {
        currentComment.votesTotal = response.data.votes_total;
        currentComment.reactionsTotal = prepareReactions(response.data.reactions_total);
        const newComments = findAndReplaceGameChatItem(
          this.entries,
          payload.messageId,
          currentComment,
        );
        this.setEntries(newComments);
      }
    } else {
      this.setErrors(response.errors);
    }
  }

  public async gameChatMessageVoteDown(payload: IGameChatMessageVoteDataPayload): Promise<void> {
    const response = await this.gameChatMessagesService.gameChatMessageVoteDown(payload);

    if (response.success) {
      const [currentComment] = this.entries.filter((item) => item.uuid === payload.messageId);

      if (currentComment) {
        currentComment.votesTotal = response.data.votes_total;
        currentComment.reactionsTotal = prepareReactions(response.data.reactions_total);
        const newComments = findAndReplaceGameChatItem(
          this.entries,
          payload.messageId,
          currentComment,
        );
        this.setEntries(newComments);
      }
    } else {
      this.setErrors(response.errors);
    }
  }

  public async gamePollVote(payload: IPollVoteData): Promise<void> {
    const pollResponse = await this.gameChatMessagesService.gameChatMessagePollVote(payload);

    if (pollResponse.success) {
      const newPoll = pollAdapter(pollResponse.data);

      const [currentComment] = this.polls.filter((item) => item.uuid === payload.entityId);

      if (currentComment) {
        currentComment.attachments = {
          ...currentComment.attachments,
          poll: newPoll,
        };

        const newComments = findAndReplaceGameChatItem(
          this.polls,
          payload.entityId,
          currentComment,
        );
        this.setPolls(newComments as IGameChatMessage[]);
      }
    } else {
      this.setErrors(pollResponse.errors);
    }
  }

  public async gamePollVoteUp(payload: IGameChatMessageVoteDataPayload): Promise<void> {
    const response = await this.gameChatMessagesService.gameChatMessageVoteUp(payload);

    if (response.success) {
      const [currentComment] = this.polls.filter((item) => item.uuid === payload.messageId);

      if (response.success && currentComment) {
        currentComment.votesTotal = response.data.votes_total;
        currentComment.reactionsTotal = prepareReactions(response.data.reactions_total);
        const newComments = findAndReplaceGameChatItem(
          this.polls,
          payload.messageId,
          currentComment,
        );
        this.setPolls(newComments as IGameChatMessage[]);
      }
    } else {
      this.setErrors(response.errors);
    }
  }

  public async gamePollVoteDown(payload: IGameChatMessageVoteDataPayload): Promise<void> {
    const response = await this.gameChatMessagesService.gameChatMessageVoteDown(payload);

    if (response.success) {
      const [currentComment] = this.polls.filter((item) => item.uuid === payload.messageId);

      if (currentComment) {
        currentComment.votesTotal = response.data.votes_total;
        currentComment.reactionsTotal = prepareReactions(response.data.reactions_total);
        const newComments = findAndReplaceGameChatItem(
          this.polls,
          payload.messageId,
          currentComment,
        );
        this.setPolls(newComments as IGameChatMessage[]);
      }
    } else {
      this.setErrors(response.errors);
    }
  }
}
