import { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';

import {
  GLOBAL_STATS_EMPTY_STATE_MESSAGE,
  GLOBAL_STATS_EMPTY_STATE_TITLE,
} from 'stores/global-stats/global-stats.config';
import { IPlayersStatsLeaders } from 'stores/global-stats/interfaces/players-stats-leaders.interface';

import * as paths from 'routes/paths.constants';

import { EmptyState } from 'components/empty-state/empty-state.component';
import { StatsLeadersCard } from 'components/ui/stats-leaders-card/stats-leaders-card.component';

import styles from '../stats-leaders.module.less';

interface IStatsPlayersLeadersProps {
  leaders: IPlayersStatsLeaders;
  onShowAllLeaders: (sortParam: string, direction: string) => void;
}

export const StatsPlayersLeaders = memo((props: IStatsPlayersLeadersProps) => {
  const { leaders } = props;
  const navigate = useNavigate();

  const allEmpty = useMemo(() => {
    const values = Object.values(leaders);

    return values.every((value) => !value.length);
  }, [leaders]);

  const handleRowClick = useCallback(
    (sort: string) => (playerSlug: string) => {
      if (sort && playerSlug)
        navigate({
          pathname: paths.HOME_STATS_PLAYERS_ALL,
          search: `${createSearchParams({ sort, playerSlug, direction: 'desc' })}`,
        });
    },
    [navigate],
  );

  if (allEmpty) {
    return (
      <div className={styles.StatsLeaders__EmptyState}>
        <EmptyState
          title={GLOBAL_STATS_EMPTY_STATE_TITLE}
          message={GLOBAL_STATS_EMPTY_STATE_MESSAGE}
        />
      </div>
    );
  }

  return (
    <div className={styles.StatsLeaders}>
      <div className={styles.StatsLeaders__Section}>
        <div className={styles.StatsLeaders__Header}>Offensive Leaders</div>
        <StatsLeadersCard
          items={leaders.points}
          leaderByLong="Points"
          leaderByAbbreviation="PTS"
          leaderField="points"
          onShowAllLeaders={props.onShowAllLeaders}
          onRowClick={handleRowClick('points')}
        />
        <StatsLeadersCard
          items={leaders.assists}
          leaderByLong="Assists"
          leaderByAbbreviation="AST"
          leaderField="assists"
          onShowAllLeaders={props.onShowAllLeaders}
          onRowClick={handleRowClick('assists')}
        />
        <StatsLeadersCard
          items={leaders.threePointsMade}
          leaderByLong="3-Points Made"
          leaderByAbbreviation="3PM"
          leaderField="threePointsFieldGoalsMade"
          onShowAllLeaders={props.onShowAllLeaders}
          onRowClick={handleRowClick('threePointsFieldGoalsMade')}
        />
      </div>
      <div className={styles.StatsLeaders__Section}>
        <div className={styles.StatsLeaders__Header}>Defensive Leaders</div>
        <StatsLeadersCard
          items={leaders.rebounds}
          leaderByLong="Rebounds"
          leaderByAbbreviation="REB"
          leaderField="totalRebounds"
          onShowAllLeaders={props.onShowAllLeaders}
          onRowClick={handleRowClick('totalRebounds')}
        />
        <StatsLeadersCard
          items={leaders.blocks}
          leaderByLong="Blocks"
          leaderByAbbreviation="BLK"
          leaderField="blocks"
          onShowAllLeaders={props.onShowAllLeaders}
          onRowClick={handleRowClick('blocks')}
        />
        <StatsLeadersCard
          items={leaders.steals}
          leaderByLong="Steals"
          leaderByAbbreviation="STL"
          leaderField="steals"
          onShowAllLeaders={props.onShowAllLeaders}
          onRowClick={handleRowClick('steals')}
        />
      </div>
    </div>
  );
});
