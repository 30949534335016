import { FC, useCallback, useMemo, useState } from 'react';

import { GuideStorageKeys } from 'stores/player/enums/guide-storage-keys.enum';
import { IPlayerDetails } from 'stores/player/interfaces/player-profile.interfaces';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { GAMES_LIMIT_FILTER_OPTIONS } from 'containers/player-profile/player-profile.config';
import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { EmptyState } from 'components/empty-state/empty-state.component';
import {
  DropdownButton,
  DropdownButtonTheme,
} from 'components/ui/dropdown-button/dropdown-button.component';
import { Select } from 'components/ui/form-fields/select/select.component';
import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';
import { SelectGameMobile } from 'components/ui/form-fields/select-game-mobile/select-game-mobile.component';
import { SelectSeasonMobile } from 'components/ui/form-fields/select-season-mobile/select-season-mobile.component';
import { SelectTeam } from 'components/ui/form-fields/select-team/select-team.component';
import { SelectTeamMobile } from 'components/ui/form-fields/select-team-mobile/select-team-mobile.component';

import { PlayerProfileGamesFeed } from './player-profile-games-feed/player-profile-games-feed.component';
import { ISplitForOneMonth } from './player-profile-games.interface';

import baseStyles from '../player-profile.module.less';
import styles from './player-profile-games.module.less';

interface IPlayerProfileGamesProps {
  isGamesGuideTooltipShown: boolean;
  seasonOptions: ISelectOption[];
  splitsMonths: ISplitForOneMonth[];
  selectedSeason: ISelectOption;
  player: Maybe<IPlayerDetails>;
  fetchingSplitGames: boolean;
  selectedTeamInfo: Maybe<ITeamsStats>;
  splitSize: Maybe<number>;
  selectedTeamId: Maybe<number>;
  teamsOptions: ITeamOption[];
  onSeasonChange: (season: string) => void;
  onTeamClick: (teamId: number) => void;
  onChangeSelectedTeamId: (teamId: Maybe<number>) => void;
  onChangeSplitSize: (limit: Maybe<number>) => void;
  onChangeGamesGuideTooltip: (key: GuideStorageKeys) => void;
  setDisabledScroll: (isDisabledScroll: boolean) => void;
}

export const PlayerProfileGames: FC<IPlayerProfileGamesProps> = (props) => {
  const {
    isGamesGuideTooltipShown,
    seasonOptions,
    selectedSeason,
    fetchingSplitGames,
    selectedTeamId,
    splitSize,
    splitsMonths,
    player,
    selectedTeamInfo,
    teamsOptions,
    onSeasonChange,
    onChangeSplitSize,
    setDisabledScroll,
    onChangeSelectedTeamId,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [isOpenSeasonsFilter, setIsOpenSeasonsFilter] = useState(false);
  const [isOpenTeamsFilter, setIsOpenTeamsFilter] = useState(false);
  const [isOpenGamesFilter, setIsOpenGamesFilter] = useState(false);

  const handleToggleSeasonsFilter = useCallback(() => {
    setIsOpenSeasonsFilter(!isOpenSeasonsFilter);
  }, [isOpenSeasonsFilter]);

  const handleToggleTeamsFilter = useCallback(() => {
    setIsOpenTeamsFilter(!isOpenTeamsFilter);
  }, [isOpenTeamsFilter]);

  const handleToggleGamesFilter = useCallback(() => {
    setIsOpenGamesFilter(!isOpenGamesFilter);
  }, [isOpenGamesFilter]);

  const handleCloseGameFilter = useCallback(() => {
    setIsOpenGamesFilter(false);
  }, []);

  const handleCloseTeamFilter = useCallback(() => {
    setIsOpenTeamsFilter(false);
  }, []);

  const handleTeamMobileChange = useCallback(
    (option: ITeamOption) => {
      onChangeSelectedTeamId(option.value);
      handleToggleTeamsFilter();
    },
    [handleToggleTeamsFilter, onChangeSelectedTeamId],
  );

  const handleSeasonMobileChange = useCallback(
    (option: ISelectOption) => {
      onSeasonChange(option.value);
      handleToggleSeasonsFilter();
    },
    [handleToggleSeasonsFilter, onSeasonChange],
  );

  const handleGameMobileChange = useCallback(
    (option: Maybe<number>) => {
      if (option === 0) {
        onChangeSplitSize(null);
      } else {
        onChangeSplitSize(option);
      }
      handleToggleGamesFilter();
    },
    [handleToggleGamesFilter, onChangeSplitSize],
  );

  const customTeamDropdownStyles = useMemo(() => {
    if (selectedTeamInfo) {
      return {
        backgroundColor: selectedTeamInfo.color,
        color: selectedTeamInfo.textColor,
      };
    }

    return undefined;
  }, [selectedTeamInfo]);

  return (
    <div className={baseStyles.PlayerProfile}>
      <div className={baseStyles.PlayerProfile__Section}>
        <div className={styles.Filters}>
          {isDesktopPlus ? (
            <>
              <Select
                name="season"
                id="season"
                options={seasonOptions}
                value={selectedSeason.value}
                onChange={props.onSeasonChange}
              />
              <div className={styles.Filters__RightSide}>
                <SelectTeam
                  id="Teams"
                  name="Teams"
                  placeholder="NBA"
                  options={teamsOptions}
                  value={selectedTeamId}
                  onChange={props.onChangeSelectedTeamId}
                  setDisabledScroll={setDisabledScroll}
                />
                <SelectTeam
                  id="Games"
                  name="Games"
                  placeholder="All"
                  forGames
                  options={GAMES_LIMIT_FILTER_OPTIONS}
                  value={splitSize}
                  onChange={props.onChangeSplitSize}
                  setDisabledScroll={setDisabledScroll}
                />
              </div>
            </>
          ) : (
            <>
              <div className={styles.Filters__Seasons}>
                <DropdownButton label={selectedSeason.label} onClick={handleToggleSeasonsFilter} />
              </div>
              <SelectSeasonMobile
                visible={isOpenSeasonsFilter}
                options={seasonOptions}
                value={selectedSeason.value}
                onChange={handleSeasonMobileChange}
                onClose={handleToggleSeasonsFilter}
              />
              <div className={styles.Filters__Teams}>
                <DropdownButton
                  customStyles={customTeamDropdownStyles}
                  label={selectedTeamInfo ? selectedTeamInfo.code : 'NBA'}
                  theme={DropdownButtonTheme.TeamBadge}
                  onClick={handleToggleTeamsFilter}
                />
              </div>
              <SelectTeamMobile
                options={teamsOptions}
                value={selectedTeamId}
                visible={isOpenTeamsFilter}
                onChange={handleTeamMobileChange}
                onClose={handleCloseTeamFilter}
              />
              <div className={styles.Filters__Games}>
                <DropdownButton
                  label={splitSize ? splitSize.toString() : 'All'}
                  theme={DropdownButtonTheme.TeamBadge}
                  onClick={handleToggleGamesFilter}
                />
              </div>
              <SelectGameMobile
                visible={isOpenGamesFilter}
                options={GAMES_LIMIT_FILTER_OPTIONS}
                value={splitSize}
                onChange={handleGameMobileChange}
                onClose={handleCloseGameFilter}
              />
            </>
          )}
        </div>
      </div>
      {!splitsMonths.length && !fetchingSplitGames ? (
        <EmptyState
          isBlackBackground
          title="No games for the selected conditions"
          message="Try selecting another filter parameters"
        />
      ) : (
        <PlayerProfileGamesFeed
          isGamesGuideTooltipShown={isGamesGuideTooltipShown}
          player={player}
          selectedSeason={selectedSeason}
          splitSize={splitSize}
          splitsMonths={splitsMonths}
          fetching={fetchingSplitGames}
          onTeamClick={props.onTeamClick}
          onChangeGamesGuideTooltip={props.onChangeGamesGuideTooltip}
        />
      )}
    </div>
  );
};
