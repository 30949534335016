import { memo } from 'react';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { IReaction } from 'components/posts/post-card/interfaces/reactions.interface';
import { ReactionsWithBottomSheet } from 'components/reactions/reactions-with-bottom-sheet/reactions-with-bottom-sheet.component';
import { ReactionsWithTooltip } from 'components/reactions/reactions-with-tooltip/reactions-with-tooltip.component';

export enum ReactionTheme {
  Regular,
  Smaller,
}

export type ReactionDirectionType = 'up' | 'down';

interface IReactionsProps {
  theme?: ReactionTheme;
  reactionsSum: number;
  reactions: {
    positive?: IReaction[];
    negative?: IReaction[];
  };
  triggerAuthorisationCheck?: () => boolean;
  onPickerClick?: () => void;
  onVoteClick: (reaction: string, type: ReactionDirectionType) => void;
}

export const Reactions = memo((props: IReactionsProps) => {
  const { reactions, reactionsSum, theme = ReactionTheme.Regular } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  if (isDesktopPlus) {
    return (
      <ReactionsWithTooltip
        reactions={reactions}
        reactionsSum={reactionsSum}
        onVoteClick={props.onVoteClick}
        onPickerClick={props.onPickerClick}
        triggerAuthorisationCheck={props.triggerAuthorisationCheck}
        theme={theme}
      />
    );
  }

  return (
    <ReactionsWithBottomSheet
      reactions={reactions}
      reactionsSum={reactionsSum}
      onPickerClick={props.onPickerClick}
      onVoteClick={props.onVoteClick}
      triggerAuthorisationCheck={props.triggerAuthorisationCheck}
      theme={theme}
    />
  );
});
