import { FC } from 'react';

import { FeedActionsContainer } from 'containers/feed-actions/feed-actions.container';
import { HeadlinesFeedContainer } from 'containers/headlines-feed/headlines-feed.container';
import { HeaderFeedContainer } from 'containers/posts/header-feed/header-feed.container';
import { ScrollTopContainer } from 'containers/scroll-top/scroll-top.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useEnableRefresher } from 'hooks/use-enable-refresher';
import { useResponsive } from 'hooks/use-responsive';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const HeadlinesFeedRoute: FC = () => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  useEnableRefresher();

  return (
    <Row layout={RowLayout.MainContent} withSpacing={false}>
      <Column mobile={1}>
        <HeaderFeedContainer />
        <HeadlinesFeedContainer />
      </Column>
      {isDesktopPlus && (
        <Column mobile={2}>
          <FeedActionsContainer />
          <ScrollTopContainer />
        </Column>
      )}
    </Row>
  );
};
