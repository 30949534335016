import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { IReportReason } from 'services/reports/interfaces/report-reason.interface';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { ReportList } from 'components/reports/report-list/report-list.component';
import { ReportSubmittion } from 'components/reports/report-submittion/report-submittion.component';
import { ReportSuccess } from 'components/reports/report-success/report-success.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { IItemLineGroup } from 'components/ui/item-line-list/interfaces/item-line-group.interface';

import styles from './report-main.module.less';

enum ReportState {
  ReportListState,
  ReportSubmittionState,
  ReportSuccessState,
}

interface IReportsMainProps {
  isModalReport?: boolean;
  isModalBackButtonClicked?: boolean;
  reasons: IReportReason[];
  onSendReport?: (reasonId: number) => Promise<boolean>;
  onSetModalTitle?: (title: string) => void;
  onCloseBottomSheet: () => void;
  onSetModalBackButtonShown?: (isBackButtonShown: boolean) => void;
  onResetModalBackButtonClickedState?: () => void;
}

export const ReportsMain = memo((props: IReportsMainProps) => {
  const {
    isModalReport = false,
    isModalBackButtonClicked = false,
    reasons,
    onSendReport,
    onSetModalTitle,
    onCloseBottomSheet,
    onSetModalBackButtonShown,
    onResetModalBackButtonClickedState,
  } = props;

  const [reportState, setReportState] = useState<ReportState>(ReportState.ReportListState);
  const [currentReason, setCurrentReason] = useState<Maybe<IReportReason>>(null);

  const reasonItems: IItemLineGroup[] = useMemo(() => {
    return reasons.map((reason) => ({
      id: reason.id,
      item: {
        text: reason.name,
        actionType: { type: BarAction.Click, payload: `${reason.id}` },
      },
    }));
  }, [reasons]);

  const reportTitle = useMemo(() => {
    if (ReportState.ReportSuccessState === reportState) {
      return 'Thanks for letting us know';
    }

    return 'Report';
  }, [reportState]);

  const isReportSubmittionState = useMemo(
    () => ReportState.ReportSubmittionState === reportState,
    [reportState],
  );

  const handleReportItemClick = useCallback(
    (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click) {
        const filteredReason = reasons.find((reason) => reason.id === Number(barAction.payload));

        if (filteredReason) {
          setCurrentReason(filteredReason);
        }
      }

      setReportState(ReportState.ReportSubmittionState);
    },
    [reasons],
  );

  const handleBackClick = useCallback(() => {
    setReportState(ReportState.ReportListState);
  }, []);

  const handleReportSubmit = useCallback(async () => {
    if (currentReason) {
      const isSuccessResponse = await onSendReport?.(currentReason.id);

      if (isSuccessResponse) {
        setReportState(ReportState.ReportSuccessState);
      } else {
        onCloseBottomSheet();
      }
    }
  }, [onCloseBottomSheet, onSendReport, currentReason]);

  useEffect(() => {
    if (isModalBackButtonClicked) {
      setReportState(ReportState.ReportListState);
      onResetModalBackButtonClickedState?.();
    }
  }, [isModalBackButtonClicked, onResetModalBackButtonClickedState]);

  useEffect(() => {
    onSetModalTitle?.(reportTitle);
  }, [reportTitle, onSetModalTitle]);

  useEffect(() => {
    onSetModalBackButtonShown?.(isReportSubmittionState);
  }, [isReportSubmittionState, onSetModalBackButtonShown]);

  return (
    <div className={styles.Reports}>
      {!isModalReport && (
        <div className={styles.Reports__Header}>
          {isReportSubmittionState && (
            <div className={styles.Reports__BackButton}>
              <IconButton
                theme={IconButtonTheme.Transparent}
                iconName={IconFontName.ChevronLeft}
                onClick={handleBackClick}
              />
            </div>
          )}
          <h4 className={styles.Reports__Title}>{reportTitle}</h4>
        </div>
      )}
      <div className={styles.Reports__Container}>
        {ReportState.ReportListState === reportState && (
          <ReportList reasons={reasonItems} onReportItemClick={handleReportItemClick} />
        )}
        {isReportSubmittionState && currentReason && (
          <ReportSubmittion
            isModalReport={isModalReport}
            reason={currentReason}
            onReportSubmit={handleReportSubmit}
          />
        )}
        {ReportState.ReportSuccessState === reportState && (
          <ReportSuccess
            isModalReport={isModalReport}
            title={currentReason?.name || ''}
            onCloseBottomSheet={props.onCloseBottomSheet}
          />
        )}
      </div>
    </div>
  );
});
