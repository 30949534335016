import {
  IPlayerInGameInfo,
  ITeamInfoResponse,
} from 'services/game/interfaces/game-response.interface';
import { IGameMiniResponse } from 'services/statistics/interface/game.interface';

export interface IPlayerRankingsAdapter {
  <RankingsResponseType, RankingsType>(res: RankingsResponseType): RankingsType;
}

export enum PlayerRankingsCategoryEnum {
  performers = 'performers',
  points = 'points',
  rebounds = 'rebounds',
  assists = 'assists',
  blocks = 'blocks',
  steals = 'steals',
}

export interface IPlayerRankingsItemResponse {
  player_info: IPlayerInGameInfo;
  team: ITeamInfoResponse;
  position: string;
  fantasy_points: number;
  game_id?: string;
  points: number;
  is_live: boolean;
  tot_reb: number;
  assists: number;
  blocks: number;
  steals: number;
  fantasy_points_rank: number;
  points_rank: number;
  tot_reb_rank: number;
  assists_rank: number;
  blocks_rank: number;
  steals_rank: number;
}

export interface IPlayerRankingsResponse {
  date: string;
  [PlayerRankingsCategoryEnum.performers]: IPlayerRankingsItemResponse[];
  [PlayerRankingsCategoryEnum.points]: IPlayerRankingsItemResponse[];
  [PlayerRankingsCategoryEnum.rebounds]: IPlayerRankingsItemResponse[];
  [PlayerRankingsCategoryEnum.assists]: IPlayerRankingsItemResponse[];
  [PlayerRankingsCategoryEnum.blocks]: IPlayerRankingsItemResponse[];
  [PlayerRankingsCategoryEnum.steals]: IPlayerRankingsItemResponse[];
}

export interface ICategoryPlayerRankingsItemResponse {
  player_info: IPlayerInGameInfo;
  team: ITeamInfoResponse;
  game: IGameMiniResponse;
  position: string;
  minutes: number;
  is_live: boolean;
  fantasy_points: number;
  points: number;
  tot_reb: number;
  assists: number;
  blocks: number;
  steals: number;
  plus_minus: number;
  tsp: number;
  fgm: number;
  fga: number;
  fgp: number;
  tpa: number;
  tpm: number;
  tpp: number;
  ftm: number;
  fta: number;
  ftp: number;
  off_reb: number;
  def_reb: number;
  turnovers: number;
  personal_fouls: number;
  fantasy_points_rank: number;
  points_rank: number;
  tot_reb_rank: number;
  assists_rank: number;
  blocks_rank: number;
  steals_rank: number;
}

export interface ICategoryPlayerRankingsItemsResponse {
  items: ICategoryPlayerRankingsItemResponse[];
  count: number;
  limit: number;
  page: number;
  meta: {
    date: string;
  };
}
