import { memo, useMemo } from 'react';
import cn from 'classnames';

import { EditorTheme } from '../base-editor/base-editor.component';

import styles from './placeholder.module.less';

interface IPlaceholderProps {
  theme: EditorTheme;
  text: string;
  isToolBarVisible?: boolean;
}

export const Placeholder = memo((props: IPlaceholderProps) => {
  const { theme, text, isToolBarVisible } = props;

  const placeholderClasses = useMemo(
    () =>
      cn(styles.Placeholder, {
        [styles['Placeholder--active']]: isToolBarVisible,
        [styles['Placeholder--bottom-sheet-theme']]: theme === EditorTheme.BottomSheet,
      }),
    [theme, isToolBarVisible],
  );

  return <div className={placeholderClasses}>{text}</div>;
});
