import { FC, useEffect } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { avatarAndThumbnailSchema } from 'validation/schemas/avatar-and-thumbnail.schema';

import { DEFAULT_VALIDATION_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { ProfileThumbnailInput } from 'components/ui/form-fields/profile-thumbnail-input/profile-thumbnail-input.component';

const thumbnailFormSchema = z
  .object({
    thumbnail: avatarAndThumbnailSchema,
  })
  .strict();

export type ProfileThumbnailFormDataType = z.infer<typeof thumbnailFormSchema>;

export interface IProfileThumbnailFormProps
  extends Omit<IForm<ProfileThumbnailFormDataType>, 'submitText'> {
  onSubmit: (base64Data: FieldValues) => void;
  accept: string;
  thumbnailUrl: Maybe<string>;
  thumbnailNicknameUrl: Maybe<string>;
  thumbnailLogoUrl: Maybe<string>;
  isCropModalVisible: boolean;
  onSetCropModal: (isOpen: boolean) => void;
  forEditModal?: boolean;
}

const fieldName = 'thumbnail';

export const ProfileThumbnailForm: FC<IProfileThumbnailFormProps> = (props) => {
  const {
    onSubmit,
    accept,
    thumbnailUrl,
    thumbnailNicknameUrl,
    thumbnailLogoUrl,
    forEditModal = false,
  } = props;

  const {
    watch,
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    mode: DEFAULT_VALIDATION_MODE,
    resolver: zodResolver(thumbnailFormSchema),
  });

  const thumbnailData = watch(fieldName);

  // TODO logic here should be rewritten ENG-1337
  useEffect(() => {
    if (thumbnailData && isDirty && isValid) {
      handleSubmit(onSubmit)();
    }
  }, [onSubmit, thumbnailData, handleSubmit, isDirty, isValid]);

  return (
    <form noValidate>
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { ref, ...restField } }) => (
          <ProfileThumbnailInput
            {...restField}
            thumbnailURL={thumbnailUrl}
            thumbnailLogoUrl={thumbnailLogoUrl}
            thumbnailNicknameUrl={thumbnailNicknameUrl}
            id={fieldName}
            name={fieldName}
            accept={accept}
            isCropModalVisible={props.isCropModalVisible}
            onSetCropModal={props.onSetCropModal}
            forEditModal={forEditModal}
          />
        )}
      />
    </form>
  );
};
