import { IPlayerInGameStatistics } from 'services/game/interfaces/game-response.interface';

import { teamInfoAdapter } from 'stores/game/adapters/game-adapter.util';
import {
  IPlayerPerformance,
  IPlayerPerformanceResponse,
  IPlayerPeriodPerformance,
} from 'stores/player-performance/interfaces/player-performance.interface';
import { singleGameMiniAdapter } from 'stores/statistics/adapters/single-game-mini-adapter.util';

export function playerPerformanceAdapterUtil(
  performanceResponse: IPlayerPerformanceResponse,
): IPlayerPerformance {
  const {
    periods,
    fgm,
    ftp,
    fga,
    fgp,
    tpm,
    tpp,
    tpa,
    ftm,
    fta,
    tsp,
    fantasy_points: fantasyPoints,
    minutes,
    assists,
    blocks,
    steals,
    turnovers,
    points,
    position,
    personal_fouls: personalFouls,
    off_reb: offensiveReb,
    def_reb: defensiveReb,
    tot_reb: totalRebounds,
    plus_minus: plusMinus,
    is_top_performer: isTopPerformer,
    is_leader: isLeader,
    player_info: playerInfo,
    team,
    game,
  } = performanceResponse;

  const {
    firstname,
    lastname,
    slug,
    small_logo_url: smallLogoUrl,
    medium_logo_url: mediumLogoUrl,
    jersey,
    emoji_url: emojiUrl,
  } = playerInfo;

  return {
    game: singleGameMiniAdapter(game),
    team: teamInfoAdapter(team),
    playerInfo: {
      position,
      firstname,
      lastname,
      slug,
      smallLogoUrl,
      mediumLogoUrl,
      jersey,
      emojiUrl,
    },
    periods: preparePeriods(periods),
    isTopPerformer,
    isLeader,
    totalStats: {
      trueShootingPercentage: tsp,
      minutes: Math.trunc(minutes),
      fieldGoals: `${fgm}/${fga}`,
      fieldGoalsPercentage: fgp,
      fantasyPoints,
      threePointsFieldGoalsPercentage: tpp,
      threePoints: `${tpm}/${tpa}`,
      freeThrows: `${ftm}/${fta}`,
      freeThrowsPercentage: ftp,
      offensiveReb,
      defensiveReb,
      turnovers,
      personalFouls,
      plusMinus: plusMinus > 0 ? `+${plusMinus}` : plusMinus,
      points,
      blocks,
      totalRebounds,
      assists,
      steals,
    },
  };
}

const preparePeriods = (periodsResponse: IPlayerInGameStatistics[]): IPlayerPeriodPerformance[] => {
  return periodsResponse.map((item) => {
    const {
      minutes,
      fantasy_points: fantasyPoints,
      plus_minus: plusMinus,
      points,
      fgm,
      tpp,
      ftp,
      fgp,
      fga,
      tpm,
      tpa,
      ftm,
      fta,
      tsp,
      off_reb: offensiveReb,
      def_reb: defensiveReb,
      tot_reb: totalRebounds,
      assists,
      blocks,
      steals,
      personal_fouls: personalFouls,
      turnovers,
      not_playing_reason: notPlayingReason,
    } = item;

    return {
      minutes: Math.trunc(minutes),
      fieldGoals: `${fgm}-${fga}`,
      threePoints: `${tpm}-${tpa}`,
      freeThrows: `${ftm}-${fta}`,
      fieldGoalsPercentage: fgp,
      freeThrowsPercentage: ftp,
      threePointsFieldGoalsPercentage: tpp,
      offensiveReb,
      defensiveReb,
      turnovers,
      personalFouls,
      plusMinus,
      points,
      gameMissReason: notPlayingReason,
      blocks,
      totalRebounds,
      assists,
      steals,
      fantasyPoints,
      trueShootingPercentage: tsp,
    };
  });
};
