import { memo, useCallback, useState } from 'react';

import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { ISingleCategoryRecord } from 'stores/feed-filters/interfaces/feed-filters.interface';

import { useRetrieveSafeAreas } from 'hooks/use-retrieve-safe-areas';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import { FeedFilters } from 'components/feed-filter/feed-filter.component';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 0.6;
const MAX_BREAKPOINT = 0.95;

interface IFiltersIonBottomSheetProps {
  visible: boolean;
  hasTeamId: boolean;
  filters: ISingleCategoryRecord[];
  onCloseFilters: () => void;
  onResetFilters: () => void;
  onChangeFeeds: (value: FeedTypes) => void;
  onToggleCategory: (categoryId: string) => void;
  onToggleCategoryItem: (categoryId: string, tagId: string) => void;
}

export const FiltersIonBottomSheet = memo((props: IFiltersIonBottomSheetProps) => {
  const { visible, hasTeamId, filters } = props;

  const [selectedBreakpoint, setSelectedBreakpoint] = useState<Maybe<number>>(null);

  const safeAreaSizes = useRetrieveSafeAreas();

  const handleToggleCollapseItemOpen = useCallback((isOpened: boolean) => {
    setSelectedBreakpoint(isOpened ? MAX_BREAKPOINT : INITIAL_BREAKPOINT);
  }, []);

  if (!safeAreaSizes) {
    return null;
  }

  return (
    <BaseIonBottomSheet
      visible={visible}
      onClose={props.onCloseFilters}
      initialBreakpoint={INITIAL_BREAKPOINT}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT, MAX_BREAKPOINT]}
      selectedBreakpoint={selectedBreakpoint}
      safeAreaBottom={safeAreaSizes.bottom}
    >
      <FeedFilters
        isShowFeedsToggle={!hasTeamId}
        filters={filters}
        onChangeFeeds={props.onChangeFeeds}
        onResetFilters={props.onResetFilters}
        onToggleCategory={props.onToggleCategory}
        onToggleCategoryItem={props.onToggleCategoryItem}
        onToggleCollapseItemOpen={handleToggleCollapseItemOpen}
      />
    </BaseIonBottomSheet>
  );
});
