import { inject } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { MetaTagsService } from 'services/meta-tags/meta-tags.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { metaTagsAdapter } from 'stores/meta-tags/adapters/meta-tags.adapter';

import { TYPES } from 'configs/di-types.config';

import { IMetaTags } from './interfaces/meta-tags.interface';

export class MetaTagsStore extends ApiConnectedStore {
  private readonly metaTagsService: MetaTagsService;

  public route: string;

  public metaTags: Maybe<IMetaTags>;

  constructor(
    @inject<MetaTagsService>(TYPES.MetaTagsService)
    metaTagsService: MetaTagsService,
  ) {
    super();

    this.metaTagsService = metaTagsService;

    this.route = '';

    this.metaTags = null;

    makeObservable(this, {
      route: observable,
      metaTags: observable,

      setRoute: action.bound,
      setMetaTags: action.bound,
    });
    reaction(() => this.route, this.handleRouteChange);
  }

  private handleRouteChange = () => {
    this.retrieve();
  };

  public setRoute(route: string) {
    this.route = route;
  }

  public setMetaTags(metaTags: IMetaTags) {
    this.metaTags = metaTags;
  }

  public async retrieve() {
    this.resetErrors();
    this.setFetched(false);

    const metaTagsResponse = await this.metaTagsService.retrieve(this.route);

    if (metaTagsResponse.success) {
      this.setMetaTags(metaTagsAdapter(metaTagsResponse.data));
    } else {
      this.setErrors(metaTagsResponse.errors);
    }

    this.setFetched(true);
  }
}
