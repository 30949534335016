import { inject, injectable } from 'inversify';
import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { PermissionPopups } from 'services/local-database/config';
import { LocalDatabaseService } from 'services/local-database/local-database.service';
import { StorageField } from 'services/storage/enum/storage-field.enum';
import { StorageService } from 'services/storage/storage.service';

import { AuthStore } from 'stores/auth/auth.store';

import { TYPES } from 'configs/di-types.config';

import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import { LayoutEntity } from './enums/layout-entity.enum';
import { ILayoutEntityRecord } from './interfaces/layout-entity-record.interface';
import { ILayoutHeaderCenter } from './interfaces/layout-header-center.interface';
import {
  ILayoutHeaderLeft,
  LayoutHeaderLeftActionEnum,
} from './interfaces/layout-header-left.interface';
import { ILayoutHeaderRight } from './interfaces/layout-header-right.interface';
import { ILayoutPageHeader } from './interfaces/layout-page-header.interface';
import { ILayoutPageTitle } from './interfaces/layout-page-title.interface';
import { ILayoutTabs } from './interfaces/layout-tabs.interface';
import { LayoutEntitiesType, SelectedHeaderTabType } from './types/layout-entities.type';

@injectable()
export class LayoutStore {
  private readonly authStore: AuthStore;

  private readonly storageService: StorageService;

  private readonly localDatabaseService: LocalDatabaseService;

  public isCropAvatarModalOpen: boolean;

  public isCropBackgroundModalOpen: boolean;

  public isMobileSidebarOpen: boolean;

  public records: ILayoutEntityRecord[];

  public sidePanelActiveTab: SelectedHeaderTabType;

  public isScrollToTopEvent: boolean;

  public isHiddenBottomBar: boolean;

  public isHiddenHeaderMobile: boolean;

  public isRemovedSafeAreaTop: boolean;

  public isHiddenMobileSidebar: boolean;

  public isAllowedSwipeNavBack: boolean;

  public isPulledRefresher: boolean;

  public isEnabledRefresher: boolean;

  public isRefresherTopPosition: boolean;

  public isDisabledScroll: boolean;

  public isStoredSessionSmartBanner: boolean;

  public isForceCollapsedSmartBanner: boolean;

  public isDisplayedSmartBanner: boolean;

  public isFixedSliderToggle: boolean;

  public isHiddenSliderToggle: boolean;

  public isFirstTimeIntroShown: boolean;

  public isWelcomePlayerProfileShown: boolean;

  public isWelcomeRankingsShown: boolean;

  public isWelcomeTeamShown: boolean;

  public isWelcomeFeedShown: boolean;

  public isWelcomeArenaShown: boolean;

  public isAnyWelcomePopupOpened: boolean;

  constructor(
    @inject(TYPES.AuthStore) authStore: AuthStore,
    @inject(TYPES.StorageService) storageService: StorageService,
    @inject(TYPES.LocalDatabaseService) localDatabaseService: LocalDatabaseService,
  ) {
    this.storageService = storageService;

    this.localDatabaseService = localDatabaseService;

    this.isMobileSidebarOpen = false;

    this.isCropAvatarModalOpen = false;

    this.isCropBackgroundModalOpen = false;

    this.authStore = authStore;

    this.records = [];

    this.sidePanelActiveTab = null;

    this.isScrollToTopEvent = false;

    this.isHiddenBottomBar = false;

    this.isHiddenHeaderMobile = false;

    this.isRemovedSafeAreaTop = false;

    this.isHiddenMobileSidebar = false;

    this.isAllowedSwipeNavBack = false;

    this.isPulledRefresher = false;

    this.isEnabledRefresher = false;

    this.isRefresherTopPosition = false;

    this.isDisabledScroll = false;

    this.isStoredSessionSmartBanner = false;

    this.isForceCollapsedSmartBanner = false;

    this.isDisplayedSmartBanner = false;

    this.isFixedSliderToggle = false;

    this.isHiddenSliderToggle = false;

    this.isFirstTimeIntroShown = true;

    this.isWelcomePlayerProfileShown = true;

    this.isWelcomeTeamShown = true;

    this.isWelcomeArenaShown = true;

    this.isWelcomeFeedShown = true;

    this.isWelcomeRankingsShown = true;

    this.isAnyWelcomePopupOpened = false;

    makeObservable(this, {
      isMobileSidebarOpen: observable,
      isScrollToTopEvent: observable,
      isHiddenBottomBar: observable,
      isHiddenMobileSidebar: observable,
      isCropAvatarModalOpen: observable,
      isCropBackgroundModalOpen: observable,
      isPulledRefresher: observable,
      isEnabledRefresher: observable,
      isRefresherTopPosition: observable,
      isDisabledScroll: observable,
      records: observable,
      sidePanelActiveTab: observable,
      isAllowedSwipeNavBack: observable,
      isStoredSessionSmartBanner: observable,
      isForceCollapsedSmartBanner: observable,
      isDisplayedSmartBanner: observable,
      isFixedSliderToggle: observable,
      isHiddenSliderToggle: observable,
      isFirstTimeIntroShown: observable,
      isWelcomePlayerProfileShown: observable,
      isWelcomeArenaShown: observable,
      isWelcomeRankingsShown: observable,
      isWelcomeTeamShown: observable,
      isWelcomeFeedShown: observable,
      isRemovedSafeAreaTop: observable,
      isAnyWelcomePopupOpened: observable,

      isSidePanelOpen: computed,
      headerCenter: computed,
      headerRight: computed,
      headerLeft: computed,
      pageHeader: computed,
      pageTitle: computed,
      sortedRecords: computed,

      addRecord: action.bound,
      removeRecord: action.bound,
      setSidePanelActiveTab: action.bound,
      setMobileSidebarOpen: action.bound,
      setIsScrollToTopEvent: action.bound,
      setHiddenBottomBar: action.bound,
      setHiddenMobileSidebar: action.bound,
      setIsCropAvatarModalOpen: action.bound,
      setIsCropBackgroundModalOpen: action.bound,
      setAllowedSwipeNavBack: action.bound,
      setPulledRefresher: action.bound,
      setEnabledRefresher: action.bound,
      setRefresherTopPosition: action.bound,
      setDisabledScroll: action.bound,
      setStoredSessionSmartBanner: action.bound,
      setForceCollapsedSmartBanner: action.bound,
      setDisplayedSmartBanner: action.bound,
      setFixedSliderToggle: action.bound,
      setHiddenSliderToggle: action.bound,
      setFirstTimeIntroShown: action.bound,
      setWelcomePlayerProfileShown: action.bound,
      setWelcomeArenaShown: action.bound,
      setWelcomeRankingsShown: action.bound,
      setWelcomeFeedShown: action.bound,
      setWelcomeTeamShown: action.bound,
      setRemovedSafeAreaTop: action.bound,
      setWelcomePopupShown: action.bound,
      setAnyWelcomePopupOpened: action.bound,
    });

    reaction(() => this.authStore.authMode, this.handleAuthModeChange);

    autorun(async () => {
      await this.handleGetShownFollowingsState();
      await this.handleGetWelcomePopups();
      await this.handleDeeplinkBannerSession();
    });
  }

  private async handleGetShownFollowingsState() {
    const state = await this.storageService.get(StorageField.FirstTimeIntroShown);

    this.setFirstTimeIntroShown(!!state);
  }

  private async handleGetWelcomePopups() {
    const isWelcomeArenaShown = await this.localDatabaseService.getPopupsByKey(
      PermissionPopups.WELCOME_ARENA_SHOWN,
    );
    const isWelcomeRankingsShown = await this.localDatabaseService.getPopupsByKey(
      PermissionPopups.WELCOME_RANKINGS_SHOWN,
    );
    const isWelcomePlayerProfileShown = await this.localDatabaseService.getPopupsByKey(
      PermissionPopups.WELCOME_PLAYER_PROFILE_SHOWN,
    );
    const isWelcomeFeedShown = await this.localDatabaseService.getPopupsByKey(
      PermissionPopups.WELCOME_FEED_SHOWN,
    );
    const isWelcomeTeamShown = await this.localDatabaseService.getPopupsByKey(
      PermissionPopups.WELCOME_TEAM_SHOWN,
    );

    this.setWelcomeArenaShown(isWelcomeArenaShown);
    this.setWelcomeRankingsShown(isWelcomeRankingsShown);
    this.setWelcomePlayerProfileShown(isWelcomePlayerProfileShown);
    this.setWelcomeFeedShown(isWelcomeFeedShown);
    this.setWelcomeTeamShown(isWelcomeTeamShown);
  }

  private handleAuthModeChange = () => {
    this.setHiddenBottomBar(this.authStore.authMode !== null);
  };

  private async handleDeeplinkBannerSession() {
    const deeplinkBannerSession = await this.storageService.get(
      StorageField.DeeplinkBanner,
      true,
      true,
    );

    this.setStoredSessionSmartBanner(!!deeplinkBannerSession);
  }

  public get isSidePanelOpen(): boolean {
    return !!this.sidePanelActiveTab;
  }

  public get headerCenter(): Maybe<ILayoutHeaderCenter['value']> {
    const suitableRecord = this.sortedRecords.find(
      (record) => record.entity.type === LayoutEntity.HeaderCenter,
    );

    return suitableRecord?.entity.type === LayoutEntity.HeaderCenter
      ? suitableRecord.entity.value
      : null;
  }

  public get headerLeft(): ILayoutHeaderLeft['value'] {
    const suitableRecord = this.sortedRecords.find(
      (record) => record.entity.type === LayoutEntity.HeaderLeft,
    );

    return suitableRecord?.entity.type === LayoutEntity.HeaderLeft
      ? suitableRecord.entity.value
      : { icon: IconFontName.DigitsMenu, type: LayoutHeaderLeftActionEnum.Menu };
  }

  public get headerRight(): Maybe<ILayoutHeaderRight['value']> {
    const suitableRecord = this.sortedRecords.find(
      (record) => record.entity.type === LayoutEntity.HeaderRight,
    );

    return suitableRecord?.entity.type === LayoutEntity.HeaderRight
      ? suitableRecord.entity.value
      : null;
  }

  public get pageHeader(): Maybe<ILayoutPageHeader['value']> {
    const suitableRecord = this.sortedRecords.find(
      (record) => record.entity.type === LayoutEntity.PageHeader,
    );

    return suitableRecord?.entity.type === LayoutEntity.PageHeader
      ? suitableRecord.entity.value
      : null;
  }

  public get pageTitle(): Maybe<ILayoutPageTitle['value']> {
    const suitableRecord = this.sortedRecords.find(
      (record) => record.entity.type === LayoutEntity.PageTitle,
    );

    return suitableRecord?.entity.type === LayoutEntity.PageTitle
      ? suitableRecord.entity.value
      : null;
  }

  public get tabs(): Maybe<ILayoutTabs['value']> {
    const suitableRecord = this.sortedRecords.find(
      (record) => record.entity.type === LayoutEntity.Tabs,
    );

    return suitableRecord?.entity.type === LayoutEntity.Tabs ? suitableRecord.entity.value : null;
  }

  public get sortedRecords(): ILayoutEntityRecord[] {
    return this.records.slice().sort((recordA, recordB) => recordB.id - recordA.id);
  }

  public setFirstTimeIntroPopUpShown() {
    this.storageService.set<boolean>(StorageField.FirstTimeIntroShown, true);
    this.setFirstTimeIntroShown(true);
  }

  public setWelcomePopupShown(key: PermissionPopups) {
    this.localDatabaseService.updatePopupsTableByKey(key, true);

    switch (key) {
      case PermissionPopups.WELCOME_ARENA_SHOWN:
        this.setWelcomeArenaShown(true);
        break;
      case PermissionPopups.WELCOME_TEAM_SHOWN:
        this.setWelcomeTeamShown(true);
        break;
      case PermissionPopups.WELCOME_FEED_SHOWN:
        this.setWelcomeFeedShown(true);
        break;
      case PermissionPopups.WELCOME_PLAYER_PROFILE_SHOWN:
        this.setWelcomePlayerProfileShown(true);
        break;
      case PermissionPopups.WELCOME_RANKINGS_SHOWN:
        this.setWelcomeRankingsShown(true);
        break;
      default:
        break;
    }
  }

  public saveDeeplinkBannerSession() {
    this.storageService.set(StorageField.DeeplinkBanner, 'banner', true);
    this.setStoredSessionSmartBanner(true);
  }

  public setSidePanelActiveTab(tabName: SelectedHeaderTabType) {
    this.sidePanelActiveTab = tabName;
  }

  public addRecord(entity: LayoutEntitiesType, id: number) {
    this.records.push({ id, entity });
  }

  public removeRecord(id: number) {
    this.records = this.records.filter((record) => record.id !== id);
  }

  public setMobileSidebarOpen(isMobileSidebarOpen: boolean) {
    this.isMobileSidebarOpen = isMobileSidebarOpen;
  }

  public setIsScrollToTopEvent(flag: boolean) {
    this.isScrollToTopEvent = flag;
  }

  public setHiddenBottomBar(isHiddenBottomBar: boolean) {
    this.isHiddenBottomBar = isHiddenBottomBar;
  }

  public setIsHiddenHeaderMobile(isHiddenHeaderMobile: boolean) {
    this.isHiddenHeaderMobile = isHiddenHeaderMobile;
  }

  public setIsCropAvatarModalOpen(isCropModalOpen: boolean) {
    this.isCropAvatarModalOpen = isCropModalOpen;
  }

  public setIsCropBackgroundModalOpen(isCropModalOpen: boolean) {
    this.isCropBackgroundModalOpen = isCropModalOpen;
  }

  public setAllowedSwipeNavBack(isAllowedSwipeNavBack: boolean) {
    this.isAllowedSwipeNavBack = isAllowedSwipeNavBack;
  }

  public setPulledRefresher(isPulledRefresher: boolean) {
    this.isPulledRefresher = isPulledRefresher;
  }

  public setEnabledRefresher(isEnabledRefresher: boolean) {
    this.isEnabledRefresher = isEnabledRefresher;
  }

  public setRefresherTopPosition(isRefresherTopPosition: boolean) {
    this.isRefresherTopPosition = isRefresherTopPosition;
  }

  public setDisabledScroll(isDisabledScroll: boolean) {
    this.isDisabledScroll = isDisabledScroll;
  }

  public setStoredSessionSmartBanner(isStoredSessionSmartBanner: boolean) {
    this.isStoredSessionSmartBanner = isStoredSessionSmartBanner;
  }

  public setForceCollapsedSmartBanner(isForceCollapsedSmartBanner: boolean) {
    this.isForceCollapsedSmartBanner = isForceCollapsedSmartBanner;
  }

  public setDisplayedSmartBanner(isDisplayedSmartBanner: boolean) {
    this.isDisplayedSmartBanner = isDisplayedSmartBanner;
  }

  public setFixedSliderToggle(isFixedSliderToggle: boolean) {
    this.isFixedSliderToggle = isFixedSliderToggle;
  }

  public setHiddenSliderToggle(isHiddenSliderToggle: boolean) {
    this.isHiddenSliderToggle = isHiddenSliderToggle;
  }

  public setHiddenMobileSidebar(isHiddenMobileSidebar: boolean) {
    this.isHiddenMobileSidebar = isHiddenMobileSidebar;
  }

  public setFirstTimeIntroShown(isFirstTimeIntroShown: boolean) {
    this.isFirstTimeIntroShown = isFirstTimeIntroShown;
  }

  public setWelcomePlayerProfileShown(isWelcomePlayerProfileShown: boolean) {
    this.isWelcomePlayerProfileShown = isWelcomePlayerProfileShown;
  }

  public setWelcomeArenaShown(isWelcomeArenaShown: boolean) {
    this.isWelcomeArenaShown = isWelcomeArenaShown;
  }

  public setWelcomeFeedShown(isWelcomeFeedShown: boolean) {
    this.isWelcomeFeedShown = isWelcomeFeedShown;
  }

  public setWelcomeTeamShown(isWelcomeTeamShown: boolean) {
    this.isWelcomeTeamShown = isWelcomeTeamShown;
  }

  public setWelcomeRankingsShown(isWelcomeRankingsShown: boolean) {
    this.isWelcomeRankingsShown = isWelcomeRankingsShown;
  }

  public setRemovedSafeAreaTop(isRemovedSafeAreaTop: boolean) {
    this.isRemovedSafeAreaTop = isRemovedSafeAreaTop;
  }

  public setAnyWelcomePopupOpened(isAnyWelcomePopupOpened: boolean) {
    this.isAnyWelcomePopupOpened = isAnyWelcomePopupOpened;
  }
}
