import { FC, memo, PropsWithChildren, ReactNode } from 'react';

import styles from './label.module.less';

export interface LabelProps {
  htmlFor: string;
  children?: ReactNode;
}

export const Label: FC<LabelProps> = memo((props: PropsWithChildren<LabelProps>) => {
  const { htmlFor, children } = props;

  return (
    <label className={styles.Label} htmlFor={htmlFor}>
      {children}
    </label>
  );
});
