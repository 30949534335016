import { IPlayerTeamInfoResponse } from 'services/player/interfaces/player.interface';

import { convertColorToRGB } from 'helpers/rgb-to-rgba.utils';

import { IPlayerTeamInfo } from '../interfaces/player-profile.interfaces';

export function playerDetailsTeamAdapter(
  playerDetailsTeamResponse: IPlayerTeamInfoResponse,
): IPlayerTeamInfo {
  const {
    banner_url: bannerUrl,
    code,
    id,
    medium_logo_url: mediumLogoUrl,
    medium_thumbnail_url: mediumThumbnailUrl,
    name,
    primary_color: color,
    small_logo_url: smallLogoUrl,
    small_thumbnail_url: smallThumbnailUrl,
    text_color: textColor,
    secondary_text_color: secondaryTextColor,
  } = playerDetailsTeamResponse;

  return {
    color: convertColorToRGB(color),
    id,
    mediumLogoUrl,
    smallLogoUrl,
    bannerUrl,
    code,
    mediumThumbnailUrl,
    name,
    smallThumbnailUrl,
    textColor: convertColorToRGB(textColor),
    secondaryTextColor: convertColorToRGB(secondaryTextColor),
  };
}
