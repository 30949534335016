import { FC, useEffect, useState, useMemo } from 'react';
import { IPlayerOption } from 'containers/profile/profile-info/interfaces/player-option.interface';
import { components, ControlProps } from 'react-select';
import styles from './player-control.module.less';

interface IPlayerControlProps extends ControlProps<Maybe<IPlayerOption>, false> {}

export const PlayerControl: FC<IPlayerControlProps> = ({
  children,
  ...props
}: IPlayerControlProps) => {
  const { getValue } = props;
  const [valueColor, setValueColor] = useState<string | null>(null);

  useEffect(() => {
    if (props.hasValue) {
      const value = props.getValue();
      if (value && value[0]?.color) {
        setValueColor(value[0]?.color);
      }
    }
  }, [props]);

  const memoizedTeamColor = useMemo(() => {
    if (valueColor) {
      return {
        background: valueColor,
        caretColor: getValue()[0]?.fontColor,
        color: getValue()[0]?.fontColor,
      };
    }
    return {};
  }, [valueColor, getValue]);
  return (
    <components.Control className={styles.PlayerControl} {...props}>
      <div className={styles.PlayerControl__InnerWrapper} style={memoizedTeamColor}>
        {children}
      </div>
    </components.Control>
  );
};
