import { FC } from 'react';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { ScrollTopContainer } from 'containers/scroll-top/scroll-top.container';
import { TeamProfileInfoContainer } from 'containers/team-profile/team-profile-info/team-profile-info.container';
import { TeamProfileThumbnailContainer } from 'containers/team-profile/team-profile-thumbnail/team-profile-thumbnail.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Row, RowLayout } from 'components/ui/grid/row/row.component';

import styles from './team-profile.module.less';

export const TeamProfileRoute: FC = () => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  return (
    <PageWrapperContainer>
      <TeamProfileThumbnailContainer />
      <Row layout={RowLayout.Regular}>
        <TeamProfileInfoContainer />
        {isDesktopPlus && (
          <div className={styles.ScrollTopWrapper}>
            <ScrollTopContainer />
          </div>
        )}
      </Row>
    </PageWrapperContainer>
  );
};
