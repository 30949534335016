import { SortDirection } from 'ag-grid-community';

import { IColumnChartItem } from 'components/ui/column-charts/column-charts.component';

import { ITeamGlobalStatsRowData } from '../interfaces/global-stats-row-data.interfaces';
import { TeamsGlobalStatsSortType } from '../types/global-stats-sort.types';

export const getSortedTeamItems = (
  sort: TeamsGlobalStatsSortType,
  teamsGlobalStatsRowData: ITeamGlobalStatsRowData[],
  sortDirection: SortDirection = 'desc',
): IColumnChartItem[] => {
  return teamsGlobalStatsRowData
    .map((rowData) => ({
      avatarUrl: rowData.name.smallLogoUrl,
      color: rowData.name.color,
      id: String(rowData.name.id),
      name: rowData.name.nickname,
      value: rowData[sort],
    }))
    .sort((currentItem, nextItem) =>
      sortDirection === 'desc'
        ? nextItem.value - currentItem.value
        : currentItem.value - nextItem.value,
    );
};
