import { useEffect } from 'react';

import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

export const useToggleIgnoredTouchClass = (isIgnored: boolean) => {
  useEffect(() => {
    if (isIgnored) {
      document.body.classList.add(TOUCH_IGNORE_CLASS);
      return;
    }

    document.body.classList.remove(TOUCH_IGNORE_CLASS);
  }, [isIgnored]);
};
