import { FC, useMemo } from 'react';
import { components, DropdownIndicatorProps } from 'react-select';

import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';

import { useToggleIgnoredTouchClass } from 'hooks/use-toggle-ignored-touch-class';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

interface ITeamDropDownIndicatorProps extends DropdownIndicatorProps<ITeamOption | null, false> {}

export const TeamDropdownIndicator: FC<ITeamDropDownIndicatorProps> = (
  props: ITeamDropDownIndicatorProps,
) => {
  const { selectProps, getValue } = props;

  useToggleIgnoredTouchClass(selectProps.menuIsOpen);

  const memoizedArrowColor = useMemo(() => {
    const team = getValue();

    return team[0]?.secondaryTextColor;
  }, [getValue]);

  return (
    <components.DropdownIndicator {...props}>
      <IconButton
        customColor={memoizedArrowColor}
        iconName={selectProps?.menuIsOpen ? IconFontName.ChevronUp : IconFontName.ChevronDown}
        theme={IconButtonTheme.Transparent}
      />
    </components.DropdownIndicator>
  );
};
