import { useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { PlayerStore } from 'stores/player/player.store';

import { PostsFeedContainer } from 'containers/posts/posts-feed/posts-feed-container';

import { TYPES } from 'configs/di-types.config';

export const PlayerProfileFeedContainer = observer(() => {
  const feedFiltersStore = useInjection<FeedFiltersStore>(TYPES.FeedFiltersStore);
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);

  useEffect(() => {
    feedFiltersStore.setActiveFeed(FeedTypes.Player);

    return () => {
      feedFiltersStore.setActiveFeed(null);
    };
  }, [feedFiltersStore, playerStore.playerDetails]);

  useEffect(() => {
    if (playerStore.playerDetails) {
      const { firstname, lastname } = playerStore.playerDetails;

      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.PlayerProfileFeed,
          player: `${firstname} ${lastname}`,
        },
      });
    }
  }, [playerStore.playerDetails]);

  return <PostsFeedContainer />;
});
