import { ColDef } from 'ag-grid-community';

import { objectValueFormatter } from 'components/base-table/helpers/object-value-formatter.util';
import { PlayerName } from 'components/game/box-score/player-name/player-name.component';

const PLAYERS_STATS_COLUMN_WIDTH_EXPAND = 50;
const PLAYERS_STATS_FIRST_COLUMN_WIDTH = 164;

export const TOTAL_STATS_TABLE_ROW_HEIGHT = 32;
export const PLAYERS_STATS_TABLE_ROW_HEIGHT = 48;

export const BOX_SCORES_TABLE_FIRST_STARTERS_COLUMN: ColDef = {
  headerTooltip: 'Starters',
  field: 'player',
  pinned: 'left',
  headerName: 'Starters',
  headerClass: 'ag-players-stats-header-name',
  cellClass: 'ag-players-stats-left',
  cellRenderer: PlayerName,
  width: PLAYERS_STATS_FIRST_COLUMN_WIDTH,
  minWidth: PLAYERS_STATS_FIRST_COLUMN_WIDTH,
  valueFormatter: objectValueFormatter,
};

export const BOX_SCORES_TABLE_FIRST_BENCH_COLUMN: ColDef = {
  headerTooltip: 'Bench',
  field: 'player',
  pinned: 'left',
  headerName: 'Bench',
  headerClass: 'ag-players-stats-header-name',
  cellClass: 'ag-players-stats-left',
  cellRenderer: PlayerName,
  width: PLAYERS_STATS_FIRST_COLUMN_WIDTH,
  minWidth: PLAYERS_STATS_FIRST_COLUMN_WIDTH,
  valueFormatter: objectValueFormatter,
};

export const BOX_SCORES_TABLE_MAX_COL_DEF: ColDef[] = [
  {
    headerTooltip: 'Minutes',
    field: 'minutes',
    headerName: 'MIN',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    headerTooltip: 'Points',
    field: 'points',
    headerName: 'PTS',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    headerTooltip: 'Rebounds',
    field: 'totalRebounds',
    headerName: 'REB',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    headerTooltip: 'Assists',
    field: 'assists',
    headerName: 'AST',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    headerTooltip: 'Blocks',
    field: 'blocks',
    headerName: 'BLK',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    headerTooltip: 'Steals',
    field: 'steals',
    headerName: 'STL',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    headerTooltip: '+/-',
    field: 'plusMinus',
    headerName: '+/-',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    headerTooltip: 'Field Goal',
    field: 'fieldGoals',
    headerName: 'FG',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    headerTooltip: 'Three-Pointers',
    field: 'threePoints',
    headerName: '3PT',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    headerTooltip: 'Free Throws',
    field: 'freeThrows',
    headerName: 'FT',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    headerTooltip: 'Offensive Rebounds',
    field: 'offensiveReb',
    headerName: 'OREB',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    headerTooltip: 'Defensive Rebounds',
    field: 'defensiveReb',
    headerName: 'DREB',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    headerTooltip: 'Turnovers',
    field: 'turnovers',
    headerName: 'TO',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    headerTooltip: 'Personal Fouls',
    field: 'personalFouls',
    headerName: 'PF',
    cellRenderer: PlayerName,
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
];

export const BOX_SCORES_TOTAL_STATS_TABLE_MAX_COL_DEF: ColDef[] = [
  {
    field: 'player',
    pinned: 'left',
    headerClass: 'ag-players-stats-header-name',
    cellClass: 'ag-players-stats-left',
    width: PLAYERS_STATS_FIRST_COLUMN_WIDTH,
    minWidth: PLAYERS_STATS_FIRST_COLUMN_WIDTH,
    valueFormatter: objectValueFormatter,
  },
  {
    field: 'minutes',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    field: 'points',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    field: 'totalRebounds',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    field: 'assists',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    field: 'blocks',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    field: 'steals',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    field: 'plusMinus',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    field: 'fieldGoals',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    field: 'threePoints',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    field: 'freeThrows',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    field: 'offensiveReb',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    field: 'defensiveReb',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    field: 'turnovers',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
  {
    field: 'personalFouls',
    minWidth: PLAYERS_STATS_COLUMN_WIDTH_EXPAND,
  },
];
