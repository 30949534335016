import { inject, injectable } from 'inversify';
import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { IPlayerResponse } from 'services/player/interfaces/player.interface';
import {
  IPlayerStatsExtendedResponse,
  IPlayerStatsMiniResponse,
} from 'services/player/interfaces/player-stats.interface';
import { PlayerService } from 'services/player/player.service';
import { IGame, IGameMiniResponse } from 'services/statistics/interface/game.interface';
import { ITeamsStatsResponse } from 'services/team-stats/interfaces/teams-stats-response.interface';
import { TeamsStatsService } from 'services/team-stats/team-stats.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { IoMessage } from 'stores/io/enums/io-message.enum';
import { IoStore } from 'stores/io/io.store';
import { SeasonsStore } from 'stores/seasons/seasons.store';
import { gamesAdapter } from 'stores/statistics/adapters/games-adapter.util';
import { singleGameMiniAdapter } from 'stores/statistics/adapters/single-game-mini-adapter.util';

import { IPlayerOption } from 'containers/profile/profile-info/interfaces/player-option.interface';
import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';
import { ITeamStatsRow } from 'containers/team-stats/team-stats.interface';

import {
  NULL_PLAYER_OPTION_COLOR,
  NULL_TEAM_OPTION_COLOR,
  NULL_TEAM_OPTION_TEXT_COLOR,
} from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { findElementById } from 'helpers/find-element-by-id.util';

import NBALogo from 'assets/images/svg/nba-default-image.svg';

import { playerStatsAllAdapter } from './adapters/player-stats-all.adapter';
import { playersStatsAdapter } from './adapters/players-stats.adapter';
import { teamStatsExtendedAdapter } from './adapters/team-stats-extended.adapter';
import { teamsStatsAdapter, teamStatsAdapter } from './adapters/teams-stats.adapter';
import { teamsStatsRoster } from './adapters/teams-stats-roster.adapter';
import { PlayerFontColor } from './enums/player-font-color.enum';
import {
  IPlayerStats,
  IPlayerStatsExtended,
  IPlayerStatsMini,
} from './interfaces/players-stats.interface';
import { ITeamsStats } from './interfaces/teams-stats.interface';
import { ITeamStatsExtended } from './interfaces/teams-stats-all.interface';
import { PositionsType } from './types/positions-type.type';
import { RECENT_GAMES_AMOUNT, RECENT_GAMES_STATUSES } from './teams-stats.config';

@injectable()
export class TeamsStatsStore extends ApiConnectedStore {
  private readonly ioStore: IoStore;

  private readonly teamsStatsService: TeamsStatsService;

  private readonly playerService: PlayerService;

  private readonly seasonsStore: SeasonsStore;

  public team: Maybe<ITeamsStats>;

  public teams: ITeamsStats[];

  public players: IPlayerStats[];

  public playersStats: IPlayerStatsExtended[];

  public teamsStats: Maybe<ITeamStatsExtended>;

  public teamsRoster: IPlayerStatsMini[];

  public teamsGamesDetail: IGame[];

  public seasonId: Maybe<string>;

  public position: PositionsType;

  public fetchingStatsByTeamId: boolean;

  constructor(
    @inject(TYPES.IoStore) ioStore: IoStore,
    @inject(TYPES.TeamsStatsService) teamsStatsService: TeamsStatsService,
    @inject(TYPES.PlayerService) playerService: PlayerService,
    @inject(TYPES.SeasonsStore) seasonsStore: SeasonsStore,
  ) {
    super();

    this.ioStore = ioStore;

    this.playerService = playerService;

    this.teamsStatsService = teamsStatsService;

    this.seasonsStore = seasonsStore;

    this.team = null;

    this.teams = [];

    this.teamsStats = null;

    this.players = [];

    this.playersStats = [];

    this.teamsRoster = [];

    this.teamsGamesDetail = [];

    this.seasonId = null;

    this.position = 'ALL';

    this.fetchingStatsByTeamId = true;

    makeObservable(this, {
      team: observable,
      teams: observable,
      players: observable,
      teamsRoster: observable,
      teamsGamesDetail: observable,
      seasonId: observable,
      position: observable,
      playersStats: observable,
      teamsStats: observable,
      fetchingStatsByTeamId: observable,

      teamsThumbnails: computed,
      playersSelectOptions: computed,
      formattedTeamStatsRowData: computed,

      setTeam: action.bound,
      setTeams: action.bound,
      setPlayers: action.bound,
      setTeamsRoster: action.bound,
      setTeamsGamesDetail: action.bound,
      setSeasonId: action.bound,
      setPosition: action.bound,
      setPlayersStats: action.bound,
      setTeamsStats: action.bound,
      setFetchingStatsByTeamId: action.bound,
    });

    reaction(() => this.ioStore.socket, this.updateSubscriptions);

    autorun(() => this.initialise());

    autorun(() => this.syncCurrentSeasonId);
  }

  private syncCurrentSeasonId = () => {
    this.setSeasonId(this.seasonsStore.currentSeasonOption?.value || null);
  };

  private updateSubscriptions = () => {
    this.ioStore.socket.on(
      IoMessage.GameStatisticsMiniUpdated,
      this.handleGameStatisticsMiniUpdated,
    );
  };

  private handleGameStatisticsMiniUpdated = (gameResponse: IGameMiniResponse) => {
    const newGame = singleGameMiniAdapter(gameResponse);

    this.setTeamsGamesDetail(
      this.teamsGamesDetail.map((game) => {
        if (game.id === newGame.id) {
          return newGame;
        }

        return game;
      }),
    );
  };

  public setTeam(team: Maybe<ITeamsStats>) {
    this.team = team;
  }

  public setTeamsStats(teamsStats: Maybe<ITeamStatsExtended>) {
    this.teamsStats = teamsStats;
  }

  public setPlayersStats(playersStats: IPlayerStatsExtended[]) {
    this.playersStats = playersStats;
  }

  public setSeasonId(seasonId: Maybe<string>) {
    this.seasonId = seasonId;
  }

  public setPosition(position: PositionsType) {
    this.position = position;
  }

  public setTeams(teams: ITeamsStatsResponse[]) {
    this.teams = teamsStatsAdapter(teams);
  }

  public setPlayers(players: IPlayerResponse[]) {
    this.players = playersStatsAdapter(players);
  }

  public setTeamsRoster(teamsRoster: IPlayerStatsMini[]) {
    this.teamsRoster = teamsRoster;
  }

  public setTeamsGamesDetail(teamsGamesDetail: IGame[]) {
    this.teamsGamesDetail = teamsGamesDetail;
  }

  public setFetchingStatsByTeamId(fetchingStatsByTeamId: boolean) {
    this.fetchingStatsByTeamId = fetchingStatsByTeamId;
  }

  public get teamsThumbnails() {
    return this.teams.map((team) => team.mediumThumbnailUrl);
  }

  public get playersSelectOptions(): IPlayerOption[] {
    const nullPlayerOption = {
      color: NULL_PLAYER_OPTION_COLOR,
      fontColor: PlayerFontColor.Light,
      logo: null,
      value: null,
      label: 'Player',
      mediumLogoUrl: '',
      smallLogoUrl: '',
      firstname: '',
      lastname: '',
    };

    const mappedTeamOptions = this.players.map((player) => ({
      color: player.color,
      fontColor: player.fontColor,
      logo: player.smallLogoUrl,
      label: `${player.firstname} ${player.lastname}`,
      mediumLogoUrl: player.mediumLogoUrl,
      smallLogoUrl: player.smallLogoUrl,
      firstname: player.firstname,
      lastname: player.lastname,
      value: player.id,
    }));

    return [nullPlayerOption, ...mappedTeamOptions];
  }

  public get formattedTeamStatsRowData(): ITeamStatsRow[] {
    if (this.teamsStats) {
      const firstRow = {
        type: 'Team',
        teamPrimaryColor: this.teamsStats.color,
        teamTextColor: this.teamsStats.textColor,
        gamesPlayed: this.teamsStats.gamesPlayed,
        points: this.teamsStats.points,
        fieldGoalsMade: this.teamsStats.fieldGoalsMade,
        fieldGoalsAttempted: this.teamsStats.fieldGoalsAttempted,
        fieldGoalsPercentage: this.teamsStats.fieldGoalsPercentage,
        threePointsFieldGoalsMade: this.teamsStats.threePointsFieldGoalsMade,
        threePointsFieldGoalsAttempted: this.teamsStats.threePointsFieldGoalsAttempted,
        threePointsFieldGoalsPercentage: this.teamsStats.threePointsFieldGoalsPercentage,
        freeThrowsMade: this.teamsStats.freeThrowsMade,
        freeThrowsAttempted: this.teamsStats.freeThrowsAttempted,
        freeThrowsPercentage: this.teamsStats.freeThrowsPercentage,
        offensiveReb: this.teamsStats.offensiveReb,
        defensiveReb: this.teamsStats.defensiveReb,
        totalRebounds: this.teamsStats.totalRebounds,
        assists: this.teamsStats.assists,
        blocks: this.teamsStats.blocks,
        steals: this.teamsStats.steals,
        personalFouls: this.teamsStats.personalFouls,
        turnovers: this.teamsStats.turnovers,
      };

      const secondRow = {
        type: 'Team Rank',
        teamPrimaryColor: this.teamsStats.color,
        teamTextColor: this.teamsStats.secondaryColor,
        gamesPlayed: null,
        points: this.teamsStats.pointsRank,
        fieldGoalsMade: this.teamsStats.fieldGoalsMadeRank,
        fieldGoalsAttempted: this.teamsStats.fieldGoalsAttemptedRank,
        fieldGoalsPercentage: this.teamsStats.fieldGoalsPercentageRank,
        threePointsFieldGoalsMade: this.teamsStats.threePointsFieldGoalsMadeRank,
        threePointsFieldGoalsAttempted: this.teamsStats.threePointsFieldGoalsAttemptedRank,
        threePointsFieldGoalsPercentage: this.teamsStats.threePointsFieldGoalsPercentageRank,
        freeThrowsMade: this.teamsStats.freeThrowsMadeRank,
        freeThrowsAttempted: this.teamsStats.freeThrowsAttemptedRank,
        freeThrowsPercentage: this.teamsStats.freeThrowsPercentageRank,
        offensiveReb: this.teamsStats.offensiveRebRank,
        defensiveReb: this.teamsStats.defensiveRebRank,
        totalRebounds: this.teamsStats.totalReboundsRank,
        assists: this.teamsStats.assistsRank,
        blocks: this.teamsStats.blocksRank,
        steals: this.teamsStats.stealsRank,
        personalFouls: this.teamsStats.personalFoulsRank,
        turnovers: this.teamsStats.turnoversRank,
      };

      return [firstRow, secondRow];
    }

    return [];
  }

  public getTeamsSelectOptions(nullOptionLabel: string): ITeamOption[] {
    const nullTeamOption = {
      color: NULL_TEAM_OPTION_COLOR,
      secondaryTextColor: NULL_TEAM_OPTION_TEXT_COLOR,
      logo: null,
      value: null,
      code: nullOptionLabel,
      label: nullOptionLabel,
      mediumLogoUrl: '',
      smallLogoUrl: '',
      nickname: '',
      name: nullOptionLabel,
    };

    const mappedTeamOptions = this.teams.map((team) => ({
      label: `[${team.code}] ${team.name}`,
      code: team.code,
      name: team.name,
      color: team.color,
      secondaryTextColor: team.secondaryTextColor,
      logo: team.smallLogoUrl,
      nickname: team.nickname,
      mediumLogoUrl: team.mediumLogoUrl,
      smallLogoUrl: team.smallLogoUrl,
      value: team.id,
    }));

    return [nullTeamOption, ...mappedTeamOptions];
  }

  public getTeamsSelectOptionsForGames(): ITeamOption[] {
    const nullTeamOption = {
      color: NULL_TEAM_OPTION_COLOR,
      secondaryTextColor: NULL_TEAM_OPTION_TEXT_COLOR,
      logo: NBALogo,
      value: null,
      code: 'NBA',
      label: 'NBA - All games',
      mediumLogoUrl: '',
      smallLogoUrl: '',
      nickname: '',
      name: 'NBA',
    };

    const mappedTeamOptions = this.teams.map((team) => ({
      label: `[${team.code}] ${team.name}`,
      code: team.code,
      name: team.name,
      color: team.color,
      secondaryTextColor: team.secondaryTextColor,
      logo: team.smallLogoUrl,
      nickname: team.nickname,
      mediumLogoUrl: team.mediumLogoUrl,
      smallLogoUrl: team.smallLogoUrl,
      value: team.id,
    }));

    return [nullTeamOption, ...mappedTeamOptions];
  }

  public findTeamById(teamId: Maybe<number>): Maybe<ITeamsStats> {
    return findElementById(this.teams, teamId);
  }

  public findPlayerById(playerId: Maybe<number>): Maybe<IPlayerStats> {
    return findElementById(this.players, playerId);
  }

  public async fetchTeamStats(teamId: number) {
    this.setFetching(true);
    this.resetErrors();
    this.setTeam(null);

    const response = await this.teamsStatsService.fetchTeamStatsByTeamId(teamId);

    if (response.success) {
      this.setTeam(teamStatsAdapter(response.data));
    } else {
      this.setErrors(response.errors);
      this.setTeam(null);
    }

    this.setFetching(false);
  }

  public async fetchTeamsStatsByTeamId(teamId: number): Promise<void> {
    this.setFetching(true);
    this.resetErrors();

    const response = await this.teamsStatsService.fetchTeamsStatsAllByTeamId(teamId, this.seasonId);

    if (response.success) {
      this.setTeamsStats(teamStatsExtendedAdapter(response.data));
    } else {
      this.setErrors(response.errors);
      this.setTeamsStats(null);
    }

    this.setFetching(false);
  }

  public async fetchTeamRosterByTeamId(teamId: number): Promise<void> {
    this.setFetching(true);
    this.resetErrors();

    const response: IResponse<IPlayerStatsMiniResponse[]> =
      await this.teamsStatsService.fetchTeamsStatsRosterByTeamId(teamId);

    if (response.success) {
      const teamsRoster = response.data.map((item) => teamsStatsRoster(item, null));

      this.setTeamsRoster(teamsRoster);
    } else {
      this.setErrors(response.errors);
    }

    this.setFetching(false);
  }

  public async retrieveRecentGames(teamId: number): Promise<void> {
    this.setFetching(true);
    this.resetErrors();

    const response = await this.teamsStatsService.fetchTeamsStatsGamesByTeamId(
      teamId,
      { page: 1, limit: RECENT_GAMES_AMOUNT },
      'DESC',
      RECENT_GAMES_STATUSES.join(','),
    );

    if (response.success) {
      this.setTeamsGamesDetail(gamesAdapter(response.data));
      this.updateSubscriptions();
    } else {
      this.setErrors(response.errors);
    }

    this.setFetching(false);
  }

  public async fetchPlayersStatsAll(teamId: number): Promise<void> {
    this.resetErrors();
    this.setPlayersStats([]);

    const response: IResponse<IPlayerStatsExtendedResponse[]> =
      await this.playerService.fetchPlayersStats(
        teamId,
        this.seasonId,
        this.position === 'ALL' ? null : this.position,
      );

    if (response.success) {
      this.setPlayersStats(response.data.map(playerStatsAllAdapter));
    } else {
      this.setErrors(response.errors);
    }
  }

  public async fetchTeamsStats(): Promise<void> {
    this.resetErrors();
    this.setTeamsStats(null);

    const response: IResponse<ITeamsStatsResponse[]> =
      await this.teamsStatsService.fetchStatsTeams();

    if (response.success) {
      this.setTeams(response.data);
    } else {
      this.setErrors(response.errors);
    }
  }

  public async fetchPlayersStats(): Promise<void> {
    this.resetErrors();

    const response: IResponse<IPlayerResponse[]> = await this.playerService.fetchPlayers();

    if (response.success) {
      this.setPlayers(response.data);
    } else {
      this.setErrors(response.errors);
    }
  }

  public async initialise() {
    this.setFetching(true);

    await this.fetchPlayersStats();
    await this.fetchTeamsStats();

    this.setFetching(false);
  }

  public async fetchFilteredStatsByTeamId(teamId: number) {
    this.setFetchingStatsByTeamId(true);

    await this.fetchTeamsStatsByTeamId(teamId);
    await this.fetchPlayersStatsAll(teamId);

    this.setFetchingStatsByTeamId(false);
  }

  public resetTeamStats = () => {
    this.setSeasonId(this.seasonsStore.currentSeasonOption?.value || null);
    this.setPosition('ALL');
  };

  public reset() {
    this.setTeam(null);
  }
}
