import { FC } from 'react';
import { components, MultiValueRemoveProps } from 'react-select';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import styles from './custom-multi-value-remove.module.less';

export const CustomMultiValueRemove: FC<MultiValueRemoveProps> = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <div className={styles.Wrapper}>
        <IconButton iconName={IconFontName.Close} theme={IconButtonTheme.Transparent} />
      </div>
    </components.MultiValueRemove>
  );
};
