import { memo, useCallback, useMemo } from 'react';

import { OpenPreviewType } from 'stores/gallery/types/gallery.type';
import { IAttachments } from 'stores/posts/interfaces/post.interface';
import { PlayerFontColor } from 'stores/teams-stats/enums/player-font-color.enum';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Attachments } from 'components/attachments/attachments.component';
import { IPostCardUserInfo } from 'components/posts/post-card/interfaces/post-info.interface';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';
import {
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';

import styles from '../comment.module.less';

export interface ICommentData {
  comment: string;
  createAt: string;
}

export interface ICommentWithoutInteractionsProps {
  onOpenPreview: OpenPreviewType;
  team: Maybe<ITeamsStats>;
  id: string;
  postId: string;
  commentAuthor: IPostCardUserInfo;
  commentData: ICommentData;
  attachments: IAttachments;
  onPollVote: (voteData: IPollVoteData) => void;
  onPollExpired: (postId: string, commentId: Maybe<string>) => void;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
}

export const CommentWithoutInteractions = memo((props: ICommentWithoutInteractionsProps) => {
  const { id, postId, commentAuthor, commentData, team, onPollVote, attachments } = props;

  const { favoritePlayer, favoriteTeam } = commentAuthor;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handlePollVote = useCallback(
    (pollData: IPollVoteData) => {
      onPollVote({ ...pollData, entityId: id });
    },
    [onPollVote, id],
  );

  const overridePlayer: Maybe<IPlayerStats> = useMemo(() => {
    if (favoritePlayer) {
      return {
        ...favoritePlayer,
        fontColor: PlayerFontColor.Light,
      };
    }

    return null;
  }, [favoritePlayer]);

  return (
    <div className={styles.Comment}>
      <div className={styles.Comment__UserAvatar}>
        <Avatar
          size={AvatarSize.M}
          username={commentAuthor.username}
          src={commentAuthor.smallAvatarUrl}
        />
      </div>
      <div className={styles.Comment__Main}>
        <div className={styles.Comment__Body}>
          <div className={styles.Comment__UserInfo}>
            <div className={styles.LeftSide}>
              <div className={styles.TopSection}>
                <div className={styles.TopSection__UserNick}>{commentAuthor.username}</div>
                {!!commentAuthor.name && isDesktopPlus && (
                  <div className={styles.TopSection__UserName}>{commentAuthor.name}</div>
                )}
              </div>
              <TeamPlayerLabel
                isNeedLowOpacity
                team={favoriteTeam}
                player={overridePlayer}
                onTeamClick={props.onTeamClick}
                onPlayerClick={props.onPlayerClick}
              />
            </div>
          </div>
          <div className={styles.Comment__Content}>
            {commentData.comment && (
              <RichTextPreview
                id={id}
                editorState={commentData.comment}
                editorType={RichTextContentTypes.WithReadMore}
              />
            )}
            <Attachments
              team={team}
              isForBookmarks={false}
              author={commentAuthor}
              attachments={attachments}
              isSmall
              date={commentData.createAt}
              onPollVote={handlePollVote}
              onPollExpired={props.onPollExpired}
              parentEntityId={postId}
              onOpenPreview={props.onOpenPreview}
              entityId={id}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
