export const DEFAULT_MESSAGE = 'Something went wrong';

export function parseResponseError(possibleError: unknown): INotificationMessage[] {
  if (possibleError && typeof possibleError === 'object') {
    const { message: coercedMessage } = { message: DEFAULT_MESSAGE, ...possibleError };

    const errors: INotificationMessage[] = [];

    if (Array.isArray(coercedMessage)) {
      errors.push(...coercedMessage.map((message: string) => ({ message })));
    } else {
      errors.push({
        message: coercedMessage,
      });
    }

    return errors;
  }

  return [
    {
      message: DEFAULT_MESSAGE,
    },
  ];
}
