import { memo, useMemo } from 'react';
import cn from 'classnames';

import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';

import { useIframeError } from 'hooks/use-iframe-error';

import {
  IframePreview,
  IframePreviewSize,
} from 'components/iframe-preview/iframe-preview.component';
import { GifSize, SingleGif } from 'components/single-gif/single-gif.component';
import { VideoPreview, VideoPreviewSize } from 'components/video-preview/video-preview.component';

import { AttachmentsImageItem } from '../attachments-image-item/attachment-image-item.component';
import { IconFont, IconFontName, IconFontSize } from '../icon-font/icon-font.component';

import styles from './media-set.module.less';

export interface IMediaSetProps {
  attachments: IInteractiveMediaAttachment[];
  hasOtherAttachments: boolean;
  onClick: (index: number) => () => void;
  isLight?: boolean;
}

export const MediaSet = memo((props: IMediaSetProps) => {
  const { attachments, hasOtherAttachments, isLight = false } = props;

  const [iframeRef, hasIframeError] = useIframeError();

  const mediaSetClasses = useMemo(
    () =>
      cn(styles.MediaSet, {
        [styles['MediaSet--light']]: isLight,
      }),
    [isLight],
  );

  return (
    <div className={mediaSetClasses}>
      <div className={styles.MediaSet__Header}>
        <IconFont name={IconFontName.MediaFiles} size={IconFontSize.Small} />
        <span>{attachments.length} Media</span>
        <IconFont name={IconFontName.ChevronDown} size={IconFontSize.Big} />
      </div>
      <div className={styles.MediaSet__Items}>
        {attachments.map((attach, index) => {
          if (index > 2 && hasOtherAttachments) {
            return null;
          }

          if (index === 2 && attachments.length > 3 && hasOtherAttachments) {
            return (
              <div key={attach.url} className={styles.MediaSetMore}>
                <button onClick={props.onClick(index)} className={styles.MediaSetMore__Remaining}>
                  +{attachments.length - index}
                </button>

                <button onClick={props.onClick(index)}>
                  {attach.type === 'image' && (
                    <AttachmentsImageItem
                      imageUrl={attach.mediumPreviewUrl}
                      mimeType={attach.mimeType}
                      alt={`Slide ${index}`}
                    />
                  )}
                  {attach.type === 'video' && (
                    <div className={styles.MediaSetMore__Video}>
                      <VideoPreview size={VideoPreviewSize.S} type={attach.type} url={attach.url} />
                    </div>
                  )}
                  {attach.type === 'gif' && (
                    <SingleGif isNeedGifIcon size={GifSize.S} id={attach.url} />
                  )}
                  {attach.type === 'iframe' && !hasIframeError && (
                    <IframePreview ref={iframeRef} url={attach.url} size={IframePreviewSize.S} />
                  )}
                </button>
              </div>
            );
          }

          return (
            <button key={attach.url} onClick={props.onClick(index)}>
              {attach.type === 'image' && (
                <AttachmentsImageItem
                  imageUrl={attach.mediumPreviewUrl}
                  mimeType={attach.mimeType}
                  alt={`Slide ${index}`}
                />
              )}
              {attach.type === 'video' && (
                <div className={styles.MediaSetMore__Video}>
                  <VideoPreview size={VideoPreviewSize.S} type={attach.type} url={attach.url} />
                </div>
              )}
              {attach.type === 'gif' && (
                <SingleGif isNeedGifIcon size={GifSize.S} id={attach.url} />
              )}
              {attach.type === 'iframe' && (
                <IframePreview ref={iframeRef} url={attach.url} size={IframePreviewSize.S} />
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
});
