import * as paths from 'routes/paths.constants';

export const PATHS_WITH_STATIC_HEADER = [
  paths.HOME_STANDINGS,
  paths.HOME_STATS_PLAYERS_ALL,
  paths.HOME_STATS_TEAMS_ALL,
];
export const PARAM_PATHS_WITH_STATIC_HEADER = [paths.TEAM_STATS, paths.PLAYER_PROFILE];

export const LOTTIE_MAX_FRAMES = 120;
export const LOTTIE_FIRST_FRAMES_PART = 60;
export const LOTTIE_SPEED = 1.2;

export const REFRESHER_PULL_MIN = 100;
export const REFRESHER_MAX_HEIGHT = 100;
export const REFRESHER_HEIGHT = 40;
