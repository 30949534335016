import { useEffect } from 'react';
import { useInjection } from 'inversify-react';

import { LayoutStore } from 'stores/layout/layout.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from './use-responsive';

interface IMobileLayoutStructureProps {
  isHiddenBottomBar?: boolean;
  isHiddenHeader?: boolean;
  isRemovedSafeAreaTop?: boolean;
  isHiddenMobileSidebar?: boolean;
}

export const useMobileLayoutStructure = (props: IMobileLayoutStructureProps) => {
  const {
    isHiddenBottomBar = false,
    isHiddenHeader = false,
    isRemovedSafeAreaTop = false,
    isHiddenMobileSidebar = false,
  } = props;

  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  useEffect(() => {
    if (isHiddenBottomBar && !isDesktopPlus) {
      layoutStore.setHiddenBottomBar(true);
    }

    if (isHiddenHeader && !isDesktopPlus) {
      layoutStore.setIsHiddenHeaderMobile(true);
    }

    if (isHiddenMobileSidebar && !isDesktopPlus) {
      layoutStore.setHiddenMobileSidebar(true);
    }

    if (isRemovedSafeAreaTop && !isDesktopPlus) {
      layoutStore.setRemovedSafeAreaTop(true);
    }

    return () => {
      if (isHiddenBottomBar && !isDesktopPlus) {
        layoutStore.setHiddenBottomBar(false);
      }

      if (isHiddenHeader && !isDesktopPlus) {
        layoutStore.setIsHiddenHeaderMobile(false);
      }

      if (isHiddenMobileSidebar && !isDesktopPlus) {
        layoutStore.setHiddenMobileSidebar(false);
      }

      if (isRemovedSafeAreaTop && !isDesktopPlus) {
        layoutStore.setRemovedSafeAreaTop(false);
      }
    };
  }, [
    layoutStore,
    isDesktopPlus,
    isHiddenBottomBar,
    isHiddenHeader,
    isRemovedSafeAreaTop,
    isHiddenMobileSidebar,
  ]);
};
