import { FC, useCallback, useState } from 'react';

import { IReportReason } from 'services/reports/interfaces/report-reason.interface';

import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { ReportsMain } from 'components/reports/report-main/report-main.component';

import styles from './reports-modal.module.less';

interface IReportsIonBottomSheetProps {
  visible: boolean;
  reasons: IReportReason[];
  onClose: () => void;
  onSendReport?: (reasonId: number) => Promise<boolean>;
}

export const ReportsModal: FC<IReportsIonBottomSheetProps> = (props) => {
  const { visible, reasons } = props;

  const [modalTitle, setModalTitle] = useState('');
  const [backButtonShown, setBackButtonShown] = useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);

  const handleBackButtonClick = useCallback(() => {
    setBackButtonClicked(true);
  }, []);

  const handleResetBackButtonClickedState = useCallback(() => {
    setBackButtonClicked(false);
  }, []);

  const handleSetBackButtonShown = useCallback((isBackButtonShown: boolean) => {
    setBackButtonShown(isBackButtonShown);
  }, []);

  const handleSetModalTitle = useCallback((title: string) => {
    setModalTitle(title);
  }, []);

  return (
    <BaseModalComponent
      visible={visible}
      size={ModalWindowSize.M}
      title={modalTitle}
      isNeedBackClick={backButtonShown}
      closeOnOverlayClick
      onClose={props.onClose}
      onBackClick={handleBackButtonClick}
    >
      <div className={styles.ReportsModalContainer}>
        <ReportsMain
          isModalReport
          isModalBackButtonClicked={backButtonClicked}
          reasons={reasons}
          onSendReport={props.onSendReport}
          onCloseBottomSheet={props.onClose}
          onSetModalTitle={handleSetModalTitle}
          onSetModalBackButtonShown={handleSetBackButtonShown}
          onResetModalBackButtonClickedState={handleResetBackButtonClickedState}
        />
      </div>
    </BaseModalComponent>
  );
};
