import { FC, memo, ReactNode, useCallback } from 'react';

import { SwitcherItem } from './switcher-item/switcher-item.component';

import styles from './tabs-switcher.module.less';

interface ISwitcherItem {
  element: ReactNode;
  id: string;
}

export interface ITabsSwitcherProps {
  items: ISwitcherItem[];
  activeId: string;
  disabled?: boolean;
  onItemClick: (id: string) => void;
}

export const TabsSwitcher: FC<ITabsSwitcherProps> = memo((props: ITabsSwitcherProps) => {
  const { items, disabled, onItemClick, activeId } = props;

  const handleItemClick = useCallback(
    (id: string) => {
      onItemClick(id);
    },
    [onItemClick],
  );

  return (
    <ul className={styles.SwitcherList}>
      {items.map(({ id, element }) => (
        <SwitcherItem
          id={id}
          disabled={disabled}
          active={id === activeId}
          onClick={handleItemClick}
          key={id}
          element={element}
        />
      ))}
    </ul>
  );
});
