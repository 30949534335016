import { FC, SyntheticEvent, useCallback } from 'react';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './recent-search-item.module.less';

export interface IRecentSearchItemProps {
  item: IRecentSearchItem;
  onClick: (value: string) => void;
  onDeleteClick: (value: number) => void;
}

export interface IRecentSearchItem {
  id: number;
  phrase: string;
}
export const RecentSearchItem: FC<IRecentSearchItemProps> = (props) => {
  const { item, onClick, onDeleteClick } = props;

  const handleItemClick = useCallback(() => {
    onClick(item.phrase);
  }, [onClick, item]);

  const handleDeleteClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      onDeleteClick(item.id);
    },
    [onDeleteClick, item],
  );

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={handleItemClick}
      onClick={handleItemClick}
      className={styles.RecentSearchItem}
    >
      <div className={styles.RecentSearchItem__Label}>
        <IconFont name={IconFontName.Time} size={IconFontSize.Small} />
        <div className={styles.Text}>{item.phrase}</div>
      </div>
      <IconButton
        iconSize={IconFontSize.Small}
        onClick={handleDeleteClick}
        theme={IconButtonTheme.Transparent}
        iconName={IconFontName.Close}
      />
    </div>
  );
};
