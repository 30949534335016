import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { ProfileInfoContainer } from 'containers/profile/profile-info/profile-info.container';
import { ProfileThumbnailContainer } from 'containers/profile/profile-thumbnail/profile-thumbnail.container';
import { ScrollTopContainer } from 'containers/scroll-top/scroll-top.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useLayoutEntity } from 'hooks/use-layout-entity';
import { useMobileLayoutStructure } from 'hooks/use-mobile-layout-structure';
import { useResponsive } from 'hooks/use-responsive';

import { Row, RowLayout } from 'components/ui/grid/row/row.component';

import style from './profile.module.less';

export const ProfileRoute = () => {
  useLayoutEntity({ type: LayoutEntity.PageTitle, value: 'Profile' });

  useMobileLayoutStructure({
    isHiddenBottomBar: false,
    isHiddenHeader: true,
    isRemovedSafeAreaTop: true,
  });

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  return (
    <PageWrapperContainer>
      <ProfileThumbnailContainer />
      <Row layout={RowLayout.Regular}>
        <ProfileInfoContainer />
        {isDesktopPlus && (
          <div className={style.ScrollTopWrapper}>
            <ScrollTopContainer />
          </div>
        )}
      </Row>
    </PageWrapperContainer>
  );
};
