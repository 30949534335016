import { useCallback } from 'react';
import { useInjection } from 'inversify-react';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { FollowStore } from 'stores/follow/follow.store';

import { TYPES } from 'configs/di-types.config';

export const useGaButtonCustomTap = () => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const followStore = useInjection<FollowStore>(TYPES.FollowStore);

  const googleAnalyticsButtonTap = useCallback(
    (buttonTapType: ButtonTapsEnum) => {
      const numTeamsFollow = followStore.followingTeams.length;
      const numPlayersFollow = followStore.followingPlayers.length;
      const teamsFollow = followStore.teamsFollowAbbrevs.toString().slice(0, 100);
      const playersFollow = followStore.playersFollowAbbrevs.toString().slice(0, 100);

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: buttonTapType,
          num_teams_follow: authStore.isAuthorised ? numTeamsFollow : '',
          num_players_follow: authStore.isAuthorised ? numPlayersFollow : '',
          teams_follow: authStore.isAuthorised ? teamsFollow : '',
          players_follow: authStore.isAuthorised ? playersFollow : '',
        },
      });
    },
    [authStore.isAuthorised, followStore],
  );

  return {
    googleAnalyticsButtonTap,
  };
};
