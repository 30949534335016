import { Placement, OffsetOptions } from '@floating-ui/core';
import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import cn from 'classnames';

import { Tooltip, TooltipEventType } from '../tooltip/tooltip.component';

import styles from './text-tooltip.module.less';

export interface ITextTooltipProps {
  children: ReactNode;
  text: string;
  eventType: TooltipEventType;
  placement?: Placement;
  tooltipOffset?: OffsetOptions;
  tooltipDisappearTime?: number;
}

export const TextTooltip: FC<ITextTooltipProps> = (props: PropsWithChildren<ITextTooltipProps>) => {
  const {
    children,
    eventType,
    text,
    placement = 'bottom',
    tooltipOffset,
    tooltipDisappearTime,
  } = props;

  const classNames = useMemo(() => {
    return cn(styles.TextContent, {
      [styles['TextContent--bottom']]: placement === 'bottom',
      [styles['TextContent--top']]: placement === 'top',
      [styles['TextContent--left']]: placement === 'left',
      [styles['TextContent--right']]: placement === 'right',
    });
  }, [placement]);

  return (
    <Tooltip
      tooltipDisappearTime={tooltipDisappearTime}
      placement={placement}
      eventType={eventType}
      tooltipOffset={tooltipOffset}
      tooltipContent={<div className={classNames}>{text}</div>}
    >
      {children}
    </Tooltip>
  );
};
