import { FC, memo, useCallback } from 'react';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './auth-header.module.less';

interface IAuthHeaderProps {
  buttonText: string;
  onButtonClick: () => void;
}

export const AuthHeader: FC<IAuthHeaderProps> = memo((props: IAuthHeaderProps) => {
  const { buttonText, onButtonClick } = props;

  const handleButtonClick = useCallback(onButtonClick, [onButtonClick]);

  return (
    <div className={styles.AuthHeader}>
      <div className={styles.AuthHeader__Button}>
        <Button theme={ButtonTheme.Text} size={ButtonSize.Big} onClick={handleButtonClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
});
