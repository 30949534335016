import { memo } from 'react';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import defaultThumbnailUrl from 'assets/images/svg/user-default-background.svg';

import styles from './author-card-header.module.less';

export interface IAuthorCardHeaderProps {
  username: string;
  avatarSrc: Maybe<string>;
  thumbnailURL: Maybe<string>;
  thumbnailLogoURL: Maybe<string>;
  thumbnailNicknameURL: Maybe<string>;
}

const DEFAULT_THUMBNAIL_URL = defaultThumbnailUrl;

export const AuthorCardHeader = memo((props: IAuthorCardHeaderProps) => {
  const { username, avatarSrc, thumbnailURL, thumbnailLogoURL, thumbnailNicknameURL } = props;

  return (
    <div className={styles.AuthorCardHeader}>
      <div className={styles.AuthorCardHeaderThumbnail}>
        <img
          src={thumbnailURL || DEFAULT_THUMBNAIL_URL}
          alt="thumbnail background"
          className={styles.AuthorCardHeaderThumbnail__Background}
        />
        {thumbnailNicknameURL && (
          <img
            src={thumbnailNicknameURL}
            alt="thumbnail nickname"
            className={styles.AuthorCardHeaderThumbnail__Nickname}
          />
        )}
        {thumbnailLogoURL && (
          <img
            src={thumbnailLogoURL}
            alt="thumbnail logo"
            className={styles.AuthorCardHeaderThumbnail__Logo}
          />
        )}
      </div>
      <div className={styles.AuthorCardHeader__Avatar}>
        <Avatar username={username} size={AvatarSize.XXL} src={avatarSrc} />
      </div>
    </div>
  );
});
