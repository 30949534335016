import { memo, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IHookFormInput } from '../hook-form-input.interface';

import styles from './toggle.module.less';

export interface IToggleProps extends IHookFormInput<boolean> {
  label?: string;
}

export const Toggle = memo((props: IToggleProps) => {
  const { id, name, value, label, onChange } = props;

  const handleOnChange = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
  }, []);

  const handleWrapperClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      if (onChange) {
        onChange(!value, name);
      }
    },
    [onChange, value, name],
  );

  const leftClassNames = useMemo(() => cn(styles.Toggle__Input, styles['Toggle__Input--left']), []);

  const rightClassNames = useMemo(
    () => cn(styles.Toggle__Input, styles['Toggle__Input--right']),
    [],
  );

  return (
    <div className={styles.ToggleWrapper} role="presentation" onClick={handleWrapperClick}>
      {!!label && <div className={styles.ToggleWrapper__Label}>{label}</div>}
      <div className={styles.Toggle}>
        <label htmlFor={`${id}-left`} className={styles.Toggle__Button}>
          <input
            id={`${id}-left`}
            className={leftClassNames}
            name={name}
            type="radio"
            checked={!value}
            onClick={handleOnChange}
            onChange={handleOnChange}
          />
        </label>
        <label htmlFor={`${id}-right`} className={styles.Toggle__Button}>
          <input
            id={`${id}-right`}
            className={rightClassNames}
            name={name}
            type="radio"
            checked={value}
            onClick={handleOnChange}
            onChange={handleOnChange}
          />
        </label>
      </div>
    </div>
  );
});
