import { memo, useMemo } from 'react';
import cn from 'classnames';

import scoreboardTableGridStyles from '../scoreboard-table-grid.module.less';
import styles from './scoreboard-table-header.module.less';

interface IScoreboardTableHeaderProps {
  timezone: string;
}

export const ScoreboardTableHeader = memo((props: IScoreboardTableHeaderProps) => {
  const { timezone } = props;

  const classNames = useMemo<string>(
    () => cn(styles.ScoreboardTableHeader, scoreboardTableGridStyles.ScoreboardTableGrid),
    [],
  );

  const timeClassNames = useMemo<string>(
    () =>
      cn(styles.ScoreboardTableHeader__Cell, scoreboardTableGridStyles.ScoreboardTableGrid__Time),
    [],
  );

  return (
    <div className={classNames} role="rowheader">
      <div className={styles.ScoreboardTableHeader__Cell}>Matchup</div>

      <div className={timeClassNames}>
        <div className={styles.ScoreboardTableHeader__Time}>Time ({timezone})</div>
      </div>
    </div>
  );
});
