import { FC, useMemo } from 'react';
import loss from 'assets/images/svg/loss.svg';
import win from 'assets/images/svg/win.svg';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import { IPlayerPerformance } from 'stores/player-performance/interfaces/player-performance.interface';

import { ENCODED_BULLET } from 'configs/encoded-symbols.config';
import { formatPastDate } from 'helpers/format/format-past-date.util';

import {
  BaseModalComponent,
  IModal,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { PerformanceItem } from 'components/performance-stats/performance-item/performance-item.component';
import { sharePerformanceStatsConfig } from 'components/performance-stats/performance-stats.config';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import styles from './share-palyer-performance-modal.module.less';

export interface IShareGameModalProps extends IModal {
  playerPerformance: IPlayerPerformance;
  onCopyLink: () => void;
}

export const SharePlayerPerformanceModal: FC<IShareGameModalProps> = (
  props: IShareGameModalProps,
) => {
  const { visible, playerPerformance } = props;

  const { playerInfo, totalStats, game, team } = playerPerformance;

  const teamLabel = useMemo(() => {
    if (team.id === game.teams.visitors.id) {
      return game.teams.home.code;
    }

    return game.teams.visitors.code;
  }, [team.id, game.teams]);

  const isHomeTeamPlayer = useMemo(() => {
    return team.id === game.teams.home.id;
  }, [team.id, game.teams]);

  const isPlayerWin = useMemo(() => {
    if (team.id === game.teams.visitors.id) {
      return game.teams.visitors.score.points > game.teams.home.score.points;
    }

    return game.teams.visitors.score.points < game.teams.home.score.points;
  }, [team.id, game.teams]);

  const dateString = useMemo(() => {
    return formatPastDate(game.dateStart, 'shortDay');
  }, [game.dateStart]);

  return (
    <BaseModalComponent
      headerButton={{
        icon: IconFontName.Link,
        text: 'Copy Link',
        onClick: props.onCopyLink,
        tooltip: {
          eventType: TooltipEventType.click,
          placement: 'top',
          text: 'Link Copied',
          disappearTime: 500,
        },
      }}
      size={ModalWindowSize.M}
      visible={visible}
      closeOnOverlayClick
      title="Share"
      onClose={props.onClose}
    >
      <div className={styles.ModalContentWrapper}>
        <div className={styles.ModalContent}>
          <div className={styles.Section}>
            <div className={styles.Section__Avatar}>
              <Avatar
                src={playerInfo.mediumLogoUrl}
                username={playerInfo.firstname}
                size={AvatarSize.L}
              />
              {game.status === GameStatus.Finished && (
                <img src={isPlayerWin ? win : loss} alt="result" />
              )}
            </div>
            <div className={styles.Section__Stats}>
              <div className={styles.GameInfo}>
                <div>{isHomeTeamPlayer ? '@' : 'vs'}</div>
                <div className={styles.GameInfo__Opponent}>{teamLabel}</div>
                <div className={styles.GameInfo__Dot}>{ENCODED_BULLET}</div>
                <div className={styles.GameInfo__Score}>
                  {`${game.teams.visitors.score.points} - ${game.teams.home.score.points}`}
                </div>
                <div className={styles.GameInfo__Dot}>{ENCODED_BULLET}</div>
                <div className={styles.GameInfo__Date}>{dateString}</div>
              </div>
              <div className={styles.PlayerInfo}>
                {sharePerformanceStatsConfig.map((item) => {
                  return (
                    <PerformanceItem
                      key={item.key}
                      isPlusMinus={item.key === 'plusMinus'}
                      label={item.label}
                      value={totalStats[item.key]}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModalComponent>
  );
};
