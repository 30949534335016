import { memo } from 'react';

import styles from './team-pentagon.module.less';

interface ITeamPentagonProps {
  color: string;
}

export const TeamPentagon = memo((props: ITeamPentagonProps) => {
  const { color } = props;

  return (
    <svg
      className={styles.TeamPentagon}
      width="94"
      height="56"
      viewBox="0 0 94 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M-3 56V16V-24L71.4889 -24L92.2051 12.0108C93.6259 14.4805 93.6259 17.5195 92.2051 19.9892L71.4889 56H-3Z"
        fill={`url(#gradient-${color})`}
      />
      <defs>
        <linearGradient
          id={`gradient-${color}`}
          x1="-3"
          y1="7.42858"
          x2="94"
          y2="7.42858"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} stopOpacity="0.3" />
          <stop offset="1" stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  );
});
