import { memo, useEffect, useMemo } from 'react';

import { ITeam } from 'stores/teams/interfaces/team.interface';
import { IPlayerStatsExtended } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStatsColors } from 'stores/teams-stats/interfaces/teams-stats.interface';
import { ITopPerformers } from 'stores/teams-stats/interfaces/teams-stats-all.interface';
import { PositionsType } from 'stores/teams-stats/types/positions-type.type';

import { ITeamStatsRow } from 'containers/team-stats/team-stats.interface';

import { PlayerStatsTable } from 'components/team-stats/players-stats-table/players-stats-table.component';
import {
  FIRST_PLAYER_STATS_COL_DEF,
  PLAYERS_STATS_COL_DEF,
  TEAM_STATS_COL_DEF,
} from 'components/team-stats/team-stats.config';
import { TeamStatsFilters } from 'components/team-stats/team-stats-filters/team-stats-filters.component';
import { TeamStatsTable } from 'components/team-stats/team-stats-table/team-stats-table.component';
import { TeamStatsTopPerformers } from 'components/team-stats/team-stats-top-performers/team-stats-top-performers.component';
import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';
import { Glossary } from 'components/ui/glossary/glossary.component';

import { RANK_COL_DEF } from '../base-table/tables.configs';
import { GLOBAL_STATS_GLOSSARY_PLAYER_ENTITIES } from '../stats/global-stats/global-stats-glossary.config';

import styles from './team-stats.module.less';

interface ITeamStatsProps {
  teamStats: ITeamStatsRow[];
  teamColors: ITeamsStatsColors;
  topPerformers: Maybe<ITopPerformers>;
  playersStats: Maybe<IPlayerStatsExtended[]>;
  team: Maybe<ITeam>;
  position: PositionsType;
  seasonsOptions: ISelectOption[];
  seasonId: Maybe<string>;
  onPositionChange: (type: PositionsType) => void;
  onSeasonIdChange: (seasonId: string) => void;
  onPlayerClick: (slug: string) => void;
}

export const TeamStats = memo((props: ITeamStatsProps) => {
  const {
    teamStats,
    teamColors,
    playersStats,
    team,
    seasonId,
    seasonsOptions,
    position,
    topPerformers,
  } = props;

  useEffect(() => {
    document.documentElement.style.setProperty('--team-primary-color', teamColors.main);
    document.documentElement.style.setProperty('--team-secondary-color', teamColors.text);
  }, [teamColors]);

  const playersColDef = useMemo(() => {
    return [
      RANK_COL_DEF,
      {
        ...FIRST_PLAYER_STATS_COL_DEF,
        cellRendererParams: { onPlayerClick: props.onPlayerClick },
      },
      ...PLAYERS_STATS_COL_DEF,
    ];
  }, [props.onPlayerClick]);

  return (
    <div className={styles.TeamStats}>
      <h3 className={styles.Heading}>{team?.marketingName} Players Stats</h3>
      <div className={styles.Filters}>
        <TeamStatsFilters
          onSeasonIdChange={props.onSeasonIdChange}
          onPositionChange={props.onPositionChange}
          seasonId={seasonId}
          seasonsOptions={seasonsOptions}
          position={position}
        />
      </div>
      {topPerformers && <div className={styles.SectionHeader}>Team Leaders</div>}
      <TeamStatsTopPerformers topPerformers={topPerformers} onPlayerClick={props.onPlayerClick} />
      <div className={styles.SectionHeader}>Total</div>
      <TeamStatsTable
        tableGridOption={{
          rowData: teamStats,
          columnDefs: TEAM_STATS_COL_DEF,
          gridId: '1',
        }}
        withColors
      />
      <div className={styles.SectionHeader}>Player Stats - All Splits</div>
      <PlayerStatsTable
        tableGridOption={{
          rowData: playersStats,
          columnDefs: playersColDef,
          gridId: '2',
        }}
        withColors
      />
      <Glossary entities={GLOBAL_STATS_GLOSSARY_PLAYER_ENTITIES} />
    </div>
  );
});
