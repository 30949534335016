import { useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, json, useNavigate, useParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { LayoutStore } from 'stores/layout/layout.store';
import { PlayerPerformanceStore } from 'stores/player-performance/player-performance.store';
import { ShareStore } from 'stores/share/share.store';

import { convertPeriodToString } from 'containers/player-performance/player-performance.helper';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';
import { PLAYER_SLUG_PARAM } from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';

import { GameDetailsTabs } from 'components/game/game.config';
import { SharePlayerPerformanceModal } from 'components/modals/share-player-performance-modal/share-player-performacne-modal.component';
import { PerformanceStats } from 'components/performance-stats/performance-stats.component';
import {
  IPeriodTab,
  PerformanceTabs,
} from 'components/performance-tabs/performance-tabs.component';
import { PlayerProfileInfo } from 'components/player-performance/player-profile-info/player-profile-info.component';

import styles from './player-performance.module.less';

const DEFAULT_TABS_COUNT = 5;

export const PlayerPerformanceContainer = observer(() => {
  const shareStore = useInjection<ShareStore>(TYPES.ShareStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const playerPerformanceStore = useInjection<PlayerPerformanceStore>(TYPES.PlayerPerformanceStore);

  const params = useParams<{ [PLAYER_SLUG_PARAM]: string; [paths.GAME_ID_PARAM]: string }>();

  const { gameId, playerSlug } = params;

  const navigate = useNavigate();
  const { isNativeApp } = useMainProvider();

  const [selectedPeriod, setSelectedPeriod] = useState<number>(0);

  useEffect(() => {
    if (layoutStore.isPulledRefresher) {
      playerPerformanceStore.fetchByPullToRefresh();
    }
  }, [playerPerformanceStore, layoutStore.isPulledRefresher]);

  const tabs = useMemo<IPeriodTab[]>(() => {
    const currentPeriods = playerPerformanceStore.entry?.periods?.length || 0;
    let tabsCount = DEFAULT_TABS_COUNT;
    if (currentPeriods > DEFAULT_TABS_COUNT - 1) {
      tabsCount = currentPeriods + 1;
    }

    return [...Array(tabsCount)].map((_, index) => {
      if (!index) {
        return {
          isActive: !selectedPeriod,
          isDisable: false,
          label: convertPeriodToString(index),
          id: index,
        };
      }

      return {
        isActive: selectedPeriod === index,
        isDisable: index >= currentPeriods + 1,
        label: convertPeriodToString(index),
        id: index,
      };
    });
  }, [playerPerformanceStore.entry?.periods, selectedPeriod]);

  useEffect(() => {
    playerPerformanceStore.setGameId(gameId || null);

    return () => {
      playerPerformanceStore.setGameId(null);
    };
  }, [gameId, playerPerformanceStore]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--team-primary-color',
      playerPerformanceStore.entry?.team?.primaryColor || null,
    );
    document.documentElement.style.setProperty(
      '--team-secondary-color',
      playerPerformanceStore.entry?.team?.textColor || null,
    );
    document.documentElement.style.setProperty(
      '--team-secondary-text-color',
      playerPerformanceStore.entry?.team?.secondaryTextColor || null,
    );
  }, [playerPerformanceStore.entry?.team]);

  const handleCopySharedLink = useCallback(() => {
    shareStore.copyLink();
  }, [shareStore]);

  const handleResetSharing = useCallback(() => {
    shareStore.reset();
  }, [shareStore]);

  useEffect(() => {
    if (playerSlug) {
      playerPerformanceStore.setPlayerSlug(playerSlug);
    }

    return () => {
      playerPerformanceStore.reset();
    };
  }, [playerSlug, playerPerformanceStore]);

  useEffect(() => {
    if (playerPerformanceStore.entry?.game?.status === GameStatus.Scheduled) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw json('Not Found', { status: 404 });
    }
  }, [playerPerformanceStore.entry?.game?.status]);

  const handleOpenArena = useCallback(() => {
    if (gameId) {
      const arenaGamePath = getPath(paths.ARENA_GAME, {
        [paths.GAME_ID_PARAM]: gameId,
      });

      navigate({
        pathname: arenaGamePath,
        search: `${createSearchParams({ tab: GameDetailsTabs.PLAYS })}`,
      });
    }
  }, [gameId, navigate]);

  const handleSharePlayerPerformance = useCallback(() => {
    if (gameId && playerSlug) {
      shareStore.fetchSharePlayerPerformance(gameId, playerSlug);
      shareStore.setIsShareModalVisible(true);

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PlayerPerformanceShare,
          player: playerSlug,
        },
      });
    }
  }, [shareStore, playerSlug, gameId]);

  return (
    <div className={styles.PlayerProfileWrapper}>
      {playerPerformanceStore.entry && (
        <>
          <PlayerProfileInfo
            onOpenArena={handleOpenArena}
            onSharePlayerPerformance={handleSharePlayerPerformance}
            playerPerformance={playerPerformanceStore.entry}
          />
          <PerformanceTabs tabs={tabs} setCurrentTab={setSelectedPeriod} />
          <PerformanceStats
            selectedPeriod={selectedPeriod}
            periodsStats={playerPerformanceStore.entry.periods}
            totalInGameStats={playerPerformanceStore.entry.totalStats}
          />
          {!isNativeApp && (
            <SharePlayerPerformanceModal
              playerPerformance={playerPerformanceStore.entry}
              visible={shareStore.isShareModalVisible}
              onCopyLink={handleCopySharedLink}
              onClose={handleResetSharing}
            />
          )}
        </>
      )}
    </div>
  );
});
