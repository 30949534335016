// @ts-nocheck
// /TS No Check is used to pass custom props to custom components, according to this example from react-select documentation https://codesandbox.io/s/xxl6n9?module=/example.tsx

import { FC, useCallback } from 'react';
import { components, MenuProps, OnChangeValue } from 'react-select';

import { ICheckboxItem } from 'components/ui/form-fields/multiple-choose-checkboxes/multiple-choose-checkboxes.component';
import { CustomMenu } from '../custom-menu/custom-menu.component';

interface ICheckboxSelectMenuProps extends MenuProps<ICheckboxItem, true> {}

export const CheckboxSelectMenu: FC<ICheckboxSelectMenuProps> = ({
  selectProps,
  ...rest
}: ICheckboxSelectMenuProps) => {
  const { autoOptions, manualOptions, maxOptions, onApplyClick, onChange, withActionButtons } =
    selectProps;

  const handleApply = useCallback(
    (value: string[]) => {
      onApplyClick(value);
    },
    [onApplyClick],
  );

  const handleItemClick = useCallback(
    (selectedOptions: OnChangeValue<ICheckboxItem, true>) => {
      onChange(selectedOptions);
    },
    [onChange],
  );

  return (
    <components.Menu selectProps={selectProps} {...rest}>
      <CustomMenu
        autoOptions={autoOptions}
        manualOptionsLimit={maxOptions}
        manualOptions={manualOptions}
        onApplyClick={handleApply}
        onItemClick={handleItemClick}
        withActionButtons={withActionButtons}
      />
    </components.Menu>
  );
};
