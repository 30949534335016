import { memo, PropsWithChildren, ReactElement } from 'react';

import styles from './with-notification.module.less';

interface IWithNotificationsProps {
  notificationCount?: Maybe<number>;
  children: ReactElement;
}

export const WithNotifications = memo((props: PropsWithChildren<IWithNotificationsProps>) => {
  const { notificationCount, children } = props;

  if (!notificationCount) {
    return children;
  }

  return (
    <div className={styles.WithNotifications} data-numnotifications={notificationCount}>
      {children}
    </div>
  );
});
