import { IHeadlineResponse } from 'services/headlines/interfaces/headlines-response.interface';

import { IHeadline } from '../interfaces/headline.interface';

export function headlineAdapter(headline: IHeadlineResponse): IHeadline {
  return {
    id: headline.id,
    url: headline.url,
    title: headline.title,
    iconUrl: headline.icon_url,
  };
}
