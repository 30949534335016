import { IGame, IGameMiniResponse } from 'services/statistics/interface/game.interface';

import { getFormattedPeriod } from 'stores/game/adapters/get-formatted-period.util';

import { convertRGBToHEX } from 'helpers/string/rbg-to-hex.utils';

export const singleGameMiniAdapter = (game: IGameMiniResponse): IGame => {
  const {
    id,
    status,
    clock_formatted: clockFormatted,
    current_period: currentPeriod,
    date_time_end: dateEnd,
    date_time_start: dateStart,
    duration_formatted: durationFormatted,
    home_result: home,
    total_periods: totalPeriods,
    visitors_result: visitors,
  } = game;

  return {
    id,
    status,
    formattedPeriod: getFormattedPeriod(status, currentPeriod),
    periods: {
      current: currentPeriod,
      total: totalPeriods,
    },
    clockFormatted,
    dateEnd,
    dateStart,
    durationFormatted,
    teams: {
      home: {
        id: home.team_info.id,
        code: home.team_info.code,
        color: convertRGBToHEX(home.team_info.primary_color),
        secondaryTextColor: convertRGBToHEX(home.team_info.secondary_text_color),
        mediumLogoUrl: home.team_info.medium_logo_url,
        name: home.team_info.name,
        nickname: home.team_info.nickname,
        score: {
          loss: home.loss,
          points: home.points,
          win: home.win,
        },
        smallLogoUrl: home.team_info.small_logo_url,
        totalWin: home.team_stat?.win_total || 0,
        totalLoss: home.team_stat?.loss_total || 0,
      },
      visitors: {
        id: visitors.team_info.id,
        code: visitors.team_info.code,
        color: convertRGBToHEX(visitors.team_info.primary_color),
        secondaryTextColor: convertRGBToHEX(visitors.team_info.secondary_text_color),
        mediumLogoUrl: visitors.team_info.medium_logo_url,
        name: visitors.team_info.name,
        nickname: visitors.team_info.nickname,
        score: {
          loss: visitors.loss,
          points: visitors.points,
          win: visitors.win,
        },
        smallLogoUrl: visitors.team_info.small_logo_url,
        totalWin: home.team_stat?.win_total || 0,
        totalLoss: home.team_stat?.loss_total || 0,
      },
    },
  };
};
