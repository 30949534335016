import { useCallback, useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AuthStore } from 'stores/auth/auth.store';
import { FollowStore } from 'stores/follow/follow.store';
import { LayoutStore } from 'stores/layout/layout.store';

import { TYPES } from 'configs/di-types.config';

import { IntroPopup } from 'components/intro-popup/intro-popup.component';

export const IntroContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const followStore = useInjection<FollowStore>(TYPES.FollowStore);

  const handleSetFirstTimeIntroPopUpShown = useCallback(() => {
    layoutStore.setFirstTimeIntroPopUpShown();
  }, [layoutStore]);

  useEffect(() => {
    if (authStore.isAuthorisedBySession && !layoutStore.isFirstTimeIntroShown) {
      layoutStore.setFirstTimeIntroPopUpShown();
    }

    if (authStore.isAuthorisedBySession) {
      followStore.removeFollowingsFromSessions();
    }
  }, [
    authStore.isAuthorisedBySession,
    layoutStore.isFirstTimeIntroShown,
    layoutStore,
    followStore,
  ]);

  if (authStore.isAuthorisedBySession || layoutStore.isFirstTimeIntroShown) {
    return null;
  }

  return <IntroPopup onSetFirstTimeIntroPopUpShown={handleSetFirstTimeIntroPopUpShown} />;
});
