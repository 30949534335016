import { memo, useMemo } from 'react';
import { ColDef, SortDirection } from 'ag-grid-community';
import cn from 'classnames';

import { ITeamGlobalStatsRowData } from 'stores/global-stats/interfaces/global-stats-row-data.interfaces';
import { isSortDirection } from 'stores/global-stats/utils/is-sort-direction.util';

import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { RANK_COL_DEF } from 'components/base-table/tables.configs';
import {
  ColumnCharts,
  IColumnChartItem,
} from 'components/ui/column-charts/column-charts.component';

import { GlobalStatsTable } from './global-stats-table/global-stats-table.component';
import { TEAM_STATS_ALL_COL_DEF, TEAMS_STATS_NAME_COL_DEF } from './global-stats-table.config';

import styles from './global-stats.module.less';

interface IGlobalStatsTeamsProps {
  sortedField: Maybe<string>;
  sortDirection: Maybe<string>;
  sortedItems: IColumnChartItem[];
  teamsStatsRowData: ITeamGlobalStatsRowData[];
  onSortChange: (sort: string, direction: string) => void;
  onTeamClick: (teamId: number) => void;
}

export const GlobalStatsTeams = memo((props: IGlobalStatsTeamsProps) => {
  const { sortedField, sortDirection, sortedItems, teamsStatsRowData, onTeamClick } = props;

  const teamsColumnDefs = useMemo<ColDef[]>(() => {
    const defaultSortDirection: SortDirection = 'desc';

    const nameColDef = {
      ...TEAMS_STATS_NAME_COL_DEF,
      cellRendererParams: {
        onTeamClick,
      },
    };

    if (sortedField && isSortDirection(sortDirection)) {
      return [
        RANK_COL_DEF,
        nameColDef,
        ...TEAM_STATS_ALL_COL_DEF.map((colDef) =>
          colDef.field === sortedField
            ? { ...colDef, sort: sortDirection }
            : { ...colDef, sort: null },
        ),
      ];
    }

    return [
      RANK_COL_DEF,
      nameColDef,
      ...TEAM_STATS_ALL_COL_DEF.map((colDef) =>
        colDef.field === 'points'
          ? { ...colDef, sort: defaultSortDirection }
          : { ...colDef, sort: null },
      ),
    ];
  }, [onTeamClick, sortDirection, sortedField]);

  const classGlobalStatsColumnsNames = useMemo<string>(
    () => cn(styles.GlobalStats__Columns, TOUCH_IGNORE_CLASS),
    [],
  );

  return (
    <div className={styles.GlobalStats}>
      <div className={classGlobalStatsColumnsNames}>
        <ColumnCharts sortedItems={sortedItems} />
      </div>

      {!!teamsStatsRowData?.length && (
        <GlobalStatsTable
          tableGridOption={{
            rowData: teamsStatsRowData,
            columnDefs: teamsColumnDefs,
            gridId: '1',
          }}
          onSortChange={props.onSortChange}
        />
      )}
    </div>
  );
});
