import { memo } from 'react';

import { ICountdownTime } from 'stores/auth/interfaces/countdown-time.interface';
import {
  IForgotPasswordPreserved,
  IResetPasswordParams,
} from 'stores/auth/interfaces/forgot-password.interface';

import { NewPasswordConformation } from 'components/auth/new-password-confirmation/new-password-confirmation';
import {
  ConfirmEmailForm,
  ConfirmEmailFormDataType,
} from 'components/forms/confirm-email/confirm-email-form.component';

interface IForgotPasswordProps {
  processing: boolean;
  countdownTime: Maybe<ICountdownTime>;
  onGetCode: (email: string) => void;
  onSetCountdownTime: (time: Maybe<ICountdownTime>) => void;
  onResetPasswordVerification: (formData: ConfirmEmailFormDataType) => void;
  onResetPasswordSubmit: (resetPasswordParams: IResetPasswordParams) => void;
  withEmailPreserved: Maybe<IForgotPasswordPreserved>;
}

export const ForgotPassword = memo((props: IForgotPasswordProps) => {
  const { processing, withEmailPreserved, countdownTime } = props;

  return !withEmailPreserved?.confirmed ? (
    <ConfirmEmailForm
      processing={processing}
      submitText="Reset Password"
      onGetCode={props.onGetCode}
      onSubmit={props.onResetPasswordVerification}
      onSetCountdownTime={props.onSetCountdownTime}
      preserved={withEmailPreserved}
      countdownTime={countdownTime}
    />
  ) : (
    <NewPasswordConformation
      processing={processing}
      preservedEmail={withEmailPreserved.email}
      onSubmit={props.onResetPasswordSubmit}
    />
  );
});
