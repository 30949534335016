export const REACTION_HIDDEN_TIME = 300;

export const WINDOW_HEIGHT = window.innerHeight;
export const INACTIVE_TOP_AREA = 100;
export const NEGATIVE_LIMIT_POSITION = -104;
export const NEGATIVE_LIMIT_POSITION_WITHOUT_NAVIGATION = -51;
export const POSITIVE_LIMIT_POSITION = 52;
export const CONTENT_HEIGHT_LIMIT = window.outerHeight + window.outerHeight / 2;

export const CSS_VAR_TRANSFORM_MOBILE_HEADER = '--transform-mobile-header';
export const CSS_VAR_TRANSFORM_MOBILE_BOTTOM_BAR = '--transform-mobile-bottom-bar';
export const CSS_VAR_SCROLL_SMOOTH_DURATION = '--scroll-smooth-duration';
export const CSS_VAR_TRANSFORM_MOBILE_FIXED_ELEMENT = '--transform-mobile-fixed-element';
export const CSS_VAR_OPACITY_MOBILE_HEADER = '--opacity-mobile-header';
