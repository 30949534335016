import { FC } from 'react';
import { components, MultiValueProps } from 'react-select';
import styles from './custom-multi-value.module.less';

export const CustomMultiValue: FC<MultiValueProps> = ({ children, ...props }) => {
  return (
    <components.MultiValue {...props}>
      <div className={styles.Value}>{children}</div>
    </components.MultiValue>
  );
};
