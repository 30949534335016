type ElementWithId = Record<'id', Maybe<number>>;

export const findElementById = <T extends ElementWithId>(
  array: T[],
  id: Maybe<number>,
): Maybe<T> => {
  if (id) {
    return array.find((element) => element.id === id) || null;
  }

  return null;
};
