import { BaseBottomSheet } from 'components/bottom-sheet/base-bottom-sheet/base-bottom-sheet.component';
import { MediaPermission } from 'components/media-permission/media-permission.component';

interface IMediaPermissionBottomSheetProps {
  visible: boolean;
  title: string;
  message: string;
  primaryButtonText: string;
  imageUrl: string;
  onClose: () => void;
  onApprove: () => void;
}

export const MediaPermissionBottomSheet = (props: IMediaPermissionBottomSheetProps) => {
  const { visible, title, message, imageUrl, primaryButtonText } = props;

  return (
    <BaseBottomSheet hasBackdrop visible={visible}>
      <MediaPermission
        title={title}
        primaryButtonText={primaryButtonText}
        message={message}
        imageUrl={imageUrl}
        onApprove={props.onApprove}
        onClose={props.onClose}
      />
    </BaseBottomSheet>
  );
};
