import { FC } from 'react';

import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutHeaderLeftActionEnum } from 'stores/layout/interfaces/layout-header-left.interface';

import { GlobalStatsPlayersContainer } from 'containers/stats/global-stats/global-stats-players.container';

import { useLayoutEntity } from 'hooks/use-layout-entity';

import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const HomeGlobalStatsPlayersRoute: FC = () => {
  useLayoutEntity({
    type: LayoutEntity.HeaderLeft,
    value: { icon: IconFontName.ChevronLeft, type: LayoutHeaderLeftActionEnum.Back },
  });

  return <GlobalStatsPlayersContainer />;
};
