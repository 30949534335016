import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { Portal, PortalType } from 'components/ui/portal/portal.component';

import { WelcomeContent } from './welcome-content/welcome-content.component';
import { IWelcomeProps } from './welcome-popup.interface';

import styles from './welcome-popup.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

export const WelcomePopup = (props: IWelcomeProps) => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  if (isDesktopPlus) {
    return (
      <Portal type={PortalType.Modal}>
        <div className={styles.WelcomePopup}>
          <div className={styles.WelcomePopup__Dialog}>
            <div className={styles.WelcomePopup__Header}>
              <IconButton
                iconName={IconFontName.Close}
                theme={IconButtonTheme.Secondary}
                onClick={props.onClose}
              />
            </div>
            <div className={styles.WelcomePopup__Content}>
              <WelcomeContent
                onAccept={props.onAccept}
                onClose={props.onClose}
                thumbnail={props.thumbnail}
                title={props.title}
                description={props.description}
                listItems={props.listItems}
                accepButtonName={props.accepButtonName}
                background={props.background}
              />
            </div>
          </div>
        </div>
      </Portal>
    );
  }

  return (
    <BaseIonBottomSheet
      visible
      safeAreaBottom={0}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      initialBreakpoint={INITIAL_BREAKPOINT}
      isAutoHeight
      isOverflowVisible
      isCustomSwipeLine
      onClose={props.onClose}
    >
      <WelcomeContent
        onAccept={props.onAccept}
        onClose={props.onClose}
        thumbnail={props.thumbnail}
        title={props.title}
        description={props.description}
        listItems={props.listItems}
        accepButtonName={props.accepButtonName}
        background={props.background}
      />
    </BaseIonBottomSheet>
  );
};
