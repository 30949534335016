import { FC } from 'react';
import { components, MultiValueProps } from 'react-select';

import { ICheckboxItem } from 'components/ui/form-fields/multiple-choose-checkboxes/multiple-choose-checkboxes.component';

import styles from './checkbox-select-multi-value.module.less';

interface ICheckboxSelectMultiValueProps extends MultiValueProps<ICheckboxItem, true> {}

export const CheckboxSelectMultiValue: FC<ICheckboxSelectMultiValueProps> = ({
  children,
  ...props
}: ICheckboxSelectMultiValueProps) => {
  return (
    <components.MultiValue {...props}>
      <span className={styles.Value}>{children}</span>
    </components.MultiValue>
  );
};
