import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { LayoutStore } from 'stores/layout/layout.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { Tabs } from 'components/ui/tabs/tabs.component';

export const NavTabsContainer = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const location = useLocation();

  const activeTab = useMemo<Maybe<string>>(
    () => layoutStore.tabs?.find((tab) => location.pathname.includes(tab.action.path))?.id || null,
    [layoutStore.tabs, location.pathname],
  );

  const navigate = useNavigate();

  const handleItemClick = useCallback(
    (action: BarActionType) => {
      if (action.type === BarAction.Link) {
        navigate(action.path);
      }
    },
    [navigate],
  );

  if (!layoutStore.tabs?.length) {
    return null;
  }

  return (
    <Tabs
      fullWidth={!isDesktopPlus}
      activeTab={activeTab}
      onClick={handleItemClick}
      tabs={layoutStore.tabs}
      isPulledRefresher={layoutStore.isPulledRefresher}
    />
  );
});
