import { memo, useCallback } from 'react';
import {
  Calendar as ReactCalendar,
  NavigationLabelFunc,
  TileArgs,
  TileContentFunc,
} from 'react-calendar';
import format from 'date-fns/format';

import { SHORT_WEEKDAY_FORMAT } from 'configs/date.config';
import { ENCODED_DASH_SYMBOL } from 'configs/encoded-symbols.config';

import { DateRange, NavigationLabelArgs } from 'components/ui/calendar/calendat.interface';

import { Button, ButtonSize, ButtonTheme } from '../button/button.component';
import { IconFont, IconFontName, IconFontSize } from '../icon-font/icon-font.component';

import styles from './calendar.module.less';

export interface ICalendarProps {
  value: DateRange;
  onChange: (value: Maybe<Date>) => void;
}

export const Calendar = memo((props: ICalendarProps) => {
  const { value, onChange } = props;

  const handleCalendarChange = useCallback(
    (date: DateRange) => onChange(date as Maybe<Date>),
    [onChange],
  );

  const formatShortWeekday = useCallback(
    (locale: string | undefined, date: Date) => format(date, SHORT_WEEKDAY_FORMAT),
    [],
  );

  const handleTodayClick = useCallback(() => {
    onChange(new Date());
  }, [onChange]);

  const tileContent: TileContentFunc = useCallback((params: TileArgs): Maybe<JSX.Element> => {
    const startOfDecade = params.date.getFullYear(); // eg 2001, 2011, etc
    const endOfDecade = startOfDecade + 9; // eg 2010, 2020, etc

    if (params.view === 'century') {
      return (
        <span>
          {startOfDecade} {ENCODED_DASH_SYMBOL} {endOfDecade}
        </span>
      );
    }

    return null;
  }, []);

  const navigationLabel: NavigationLabelFunc = useCallback(
    (params: NavigationLabelArgs): string => {
      return params.label.replace('–', ENCODED_DASH_SYMBOL);
    },
    [],
  );

  return (
    <div className={styles.CalendarWrapper}>
      <ReactCalendar
        tileClassName={styles.Tile}
        nextLabel={<IconFont name={IconFontName.ChevronRight} size={IconFontSize.Big} />}
        next2Label={<IconFont name={IconFontName.MoveRight} size={IconFontSize.Big} />}
        prevLabel={<IconFont name={IconFontName.ChevronLeft} size={IconFontSize.Big} />}
        prev2Label={<IconFont name={IconFontName.MoveLeft} size={IconFontSize.Big} />}
        formatShortWeekday={formatShortWeekday}
        value={value}
        onChange={handleCalendarChange}
        tileContent={tileContent}
        navigationLabel={navigationLabel}
      />
      <Button onClick={handleTodayClick} theme={ButtonTheme.Text} fluid size={ButtonSize.Big}>
        Today
      </Button>
    </div>
  );
});
