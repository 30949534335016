import { FC, useState, useEffect, useMemo } from 'react';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { SelectTeam } from 'components/ui/form-fields/select-team/select-team.component';
import { SelectPlayer } from 'components/ui/form-fields/select-player/select-player.component';
import { DEFAULT_VALIDATION_MODE } from 'components/forms/form.config';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';
import { IPlayerOption } from 'containers/profile/profile-info/interfaces/player-option.interface';
import { IInitialTeamAndPlayer } from 'components/profile/profile-info/interfaces/initial-team-and-player.interface';

import styles from './select-team-and-player-form.module.less';

export interface ISelectTeamAndPlayerProps {
  teamsOptions: ITeamOption[];
  playersOptions: IPlayerOption[];
  initialValues?: IInitialTeamAndPlayer;
  initialFavPlayerColor: Maybe<string>;
  onSubmit: (data: FieldValues) => void;
  setDisabledScroll: (isDisabledScroll: boolean) => void;
}

const teamFieldName = 'teamId';
const playerFieldName = 'playerId';

export const SelectTeamAndPlayer: FC<ISelectTeamAndPlayerProps> = ({
  teamsOptions,
  playersOptions,
  initialValues,
  initialFavPlayerColor,
  onSubmit,
  setDisabledScroll,
}: ISelectTeamAndPlayerProps) => {
  const [formBackgroundColor, setFormBackgroundColor] = useState<string | null>(null);

  const memoizedBackground = useMemo(() => {
    if (formBackgroundColor) {
      return { background: formBackgroundColor };
    }
    return {};
  }, [formBackgroundColor]);

  const {
    watch,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: initialValues,
    mode: DEFAULT_VALIDATION_MODE,
  });

  const playerData = watch(playerFieldName);

  watch(teamFieldName);

  useEffect(() => {
    if (playerData) {
      if (playersOptions) {
        const currentPlayer = playersOptions?.find((player) => player.value === playerData);
        if (currentPlayer) {
          setFormBackgroundColor(currentPlayer?.color);
        }
      }
    } else {
      setFormBackgroundColor(initialFavPlayerColor);
    }
  }, [playerData, playersOptions, initialFavPlayerColor]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} className={styles.SelectTeamAndPlayer}>
      <p className={styles.SelectTeamAndPlayer__Title}>Select your Favorite</p>
      <div className={styles.SelectTeamAndPlayer__DropDowns} style={memoizedBackground}>
        <Controller
          name={teamFieldName}
          control={control}
          render={({ field: { ref, ...restField } }) => (
            <SelectTeam
              {...restField}
              id={teamFieldName}
              name={teamFieldName}
              placeholder="Team"
              options={teamsOptions}
              setDisabledScroll={setDisabledScroll}
            />
          )}
        />

        <Controller
          name={playerFieldName}
          control={control}
          render={({ field: { ref, ...restField } }) => (
            <SelectPlayer
              {...restField}
              placeholder="Player"
              id={playerFieldName}
              name={playerFieldName}
              options={playersOptions}
              setDisabledScroll={setDisabledScroll}
            />
          )}
        />
      </div>
      <p className={styles.SelectTeamAndPlayer__Text}>You can change it later in Settings</p>
      <Button
        type="submit"
        disabled={!isDirty}
        size={ButtonSize.SmallSecondary}
        theme={ButtonTheme.Secondary}
      >
        Confirm
      </Button>
    </form>
  );
};
