import { FC } from 'react';

import styles from './countdown-item.module.less';

interface ICountdownItemProps {
  label: string;
  value: string;
  widthDivider?: boolean;
}

export const CountdownItem: FC<ICountdownItemProps> = ({ label, value, widthDivider = false }) => {
  return (
    <>
      <div className={styles.Item}>
        <div className={styles.Item__Value}>{value}</div>
        <div className={styles.Item__Label}>{label}</div>
      </div>
      {widthDivider && <div className={styles.Divider}>:</div>}
    </>
  );
};
