import { memo, useCallback, useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

import {
  IStandingsRowData,
  IStandingsTeam,
} from 'stores/standings/interfaces/standings-row-data.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import { StandingsTeamAchievement } from '../standings-team-achievement/standings-team-achievement.component';

import styles from './standings-team.module.less';

interface IStandingsTeamProps extends ICellRendererParams<IStandingsRowData, IStandingsTeam> {
  onTeamClick: (teamId: number) => void;
}

export const StandingsTeam = memo((props: IStandingsTeamProps) => {
  const { value, onTeamClick } = props;

  const achievement = value?.achievement;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const name = useMemo(() => {
    if (isDesktopPlus) {
      return `${value?.nickname} [${value?.code}]`;
    }

    return value?.code;
  }, [isDesktopPlus, value?.code, value?.nickname]);

  const handleTeamClick = useCallback(() => {
    if (value?.id) {
      onTeamClick(value.id);
    }
  }, [onTeamClick, value?.id]);

  return (
    <button className={styles.StandingsTeam} onClick={handleTeamClick}>
      <Avatar size={AvatarSize.XS} username={value?.name || ''} src={value?.smallLogoUrl} />
      <div className={styles.StandingsTeam__Data}>
        <div className={styles.Name}>{name}</div>
        {achievement && <StandingsTeamAchievement achievement={achievement} />}
      </div>
    </button>
  );
});
