export const DEFAULT_TOTAL_STATS = [
  {
    player: 'Team',
    minutes: null,
    plusMinus: null,
    fieldGoals: null,
    threePoints: null,
    freeThrows: null,
    turnovers: null,
    offensiveReb: null,
    defensiveReb: null,
    totalRebounds: null,
    assists: null,
    blocks: null,
    points: null,
    steals: null,
    personalFouls: null,
  },
  {
    player: '',
    fieldGoals: null,
    threePoints: null,
    freeThrows: null,
    turnovers: null,
    minutes: null,
    offensiveReb: null,
    defensiveReb: null,
    totalRebounds: null,
    assists: null,
    blocks: null,
    plusMinus: null,
    points: null,
    steals: null,
    personalFouls: null,
  },
];
