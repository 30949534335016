import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { ConvertImageItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';
import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';

import { IPostTag } from 'stores/posts/interfaces/post-tag.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { editorSchemaPost } from 'validation/schemas/editor.schema';
import { postTagsSchema } from 'validation/schemas/post-tags.schema';
import { postTitleSchema } from 'validation/schemas/post-title.schema';

import { EditorDataType } from 'components/editor/components/base-editor/base-editor.component';
import { Editor } from 'components/editor/editor.component';
import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE, DEFAULT_VALIDATION_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { CheckboxSelect } from 'components/ui/form-fields/checkboxes-select/checkbox-select.component';
import { Select } from 'components/ui/form-fields/select/select.component';
import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';
import { TitleTextArea } from 'components/ui/form-fields/title-text-area/title-text-area.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './post-create-form.module.less';

const postCreateFromSchema = z.object({
  title: postTitleSchema,
  editor: editorSchemaPost.optional(),
  feedToPublish: z.string().optional(),
  postTags: postTagsSchema.optional(),
});

const MAX_TAGS_LIMIT = 3;

export type PostCreateFormDataType = z.infer<typeof postCreateFromSchema>;

interface IPostCreateFormProps extends IForm<PostCreateFormDataType> {
  topToolbarRight: ReactNode;
  isFullHeight?: boolean;
  isPostVideoLoading: boolean;
  postVideo: Maybe<IVideoResponse>;
  teamsList: ISelectOption[];
  currentFeedId: string;
  tagsList: IPostTag[];
  onUploadVideo: (video: File) => void;
  onRemoveVideo: () => void;
  onFormStateChange: (flag: boolean) => void;
  onVideoError?: (error: string) => void;
  convertImageItemToAttachment: ConvertImageItemToAttachmentType;
}

export const PostCreateForm = (props: IPostCreateFormProps) => {
  const {
    processing,
    initialValues,
    submitText,
    postVideo,
    isFullHeight = false,
    teamsList,
    currentFeedId,
    isPostVideoLoading,
    tagsList,
    onSubmit,
    onFormStateChange,
  } = props;

  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const titleFieldRef = useRef<HTMLTextAreaElement>(null);

  const [loadingImages, setLoadingImages] = useState<string[]>([]);
  const [selectedFeedId, setSelectedFeedId] = useState(currentFeedId);
  const [selectedTags, setSelectedTags] = useState<string[]>();

  const { isNativeApp } = useMainProvider();
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const { control, formState, handleSubmit } = useForm<PostCreateFormDataType>({
    defaultValues: initialValues,
    mode: DEFAULT_VALIDATION_MODE,
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(postCreateFromSchema),
  });

  const { isValid, errors } = formState;

  const handleEditorChange = useCallback(
    // Any type picked from Hook Form types
    (onChange: (...event: any[]) => void) => (value: EditorDataType) => onChange(value),
    [],
  );

  const handleFeedSelection = useCallback(
    (onChange: (...event: any[]) => void) => (value: string) => {
      setSelectedFeedId(value);

      return onChange(value);
    },
    [],
  );

  const handleTagsSelection = useCallback(
    (onChange: (...event: any[]) => void) => (values: string[]) => {
      setSelectedTags(values);

      return onChange(values);
    },
    [],
  );

  const handleEditorFocus = useCallback(() => {
    let timeoutInstanse: NodeJS.Timeout;

    if (isNativeApp) {
      timeoutInstanse = setTimeout(() => {
        editorWrapperRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    }

    return () => {
      if (isNativeApp) {
        clearTimeout(timeoutInstanse);
      }
    };
  }, [isNativeApp]);

  const manualTags: IPostTag[] = useMemo(
    () => tagsList.filter((tag) => !tag.isAutotag),
    [tagsList],
  );

  useEffect(() => {
    onFormStateChange(formState.isDirty);
  }, [formState.isDirty, onFormStateChange]);

  useEffect(() => {
    if (!isDesktopPlus) {
      titleFieldRef.current?.focus();
    }
  }, [isDesktopPlus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.PostCreateForm}>
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <TitleTextArea
              {...field}
              ref={titleFieldRef}
              placeholder="Create Title"
              id="title"
              name="title"
              label="Title"
              rows={1}
              error={errors.title?.message}
            />
          )}
        />
        <div ref={editorWrapperRef} className={styles.PostCreateForm__Editor}>
          <div className={styles.PostCreateForm__EditorLabel}>Message</div>
          <Controller
            name="editor"
            control={control}
            render={({ field }) => (
              <Editor
                convertImageItemToAttachment={props.convertImageItemToAttachment}
                isFullHeight={isFullHeight}
                id="editor"
                name="editor"
                isExpand
                isSendHide
                loadingImages={loadingImages}
                onChange={handleEditorChange(field.onChange)}
                value={field.value}
                topToolbarRight={props.topToolbarRight}
                isNeedForceExpand
                postVideo={postVideo}
                onUploadVideo={props.onUploadVideo}
                onEditorFocus={handleEditorFocus}
                onRemoveVideo={props.onRemoveVideo}
                setLoadingImages={setLoadingImages}
                isVideoLoading={isPostVideoLoading}
                onVideoError={props.onVideoError}
                // useful for debug editor in future
                // isTreeViewEnabled
              />
            )}
          />
        </div>
        <div className={styles.PostCreateForm__Settings}>
          <div className={styles.PostCreateForm__Selectbox}>
            <Controller
              name="feedToPublish"
              control={control}
              render={({ field }) => (
                <Select
                  label="Publish to"
                  id="feed-selector"
                  name="feed-selector"
                  options={teamsList}
                  value={selectedFeedId || currentFeedId}
                  onChange={handleFeedSelection(field.onChange)}
                />
              )}
            />
          </div>
          <div className={styles.PostCreateForm__Selectbox}>
            <Controller
              name="postTags"
              control={control}
              render={({ field }) => (
                <CheckboxSelect
                  id="tags"
                  name="tags"
                  label="Select Topic"
                  value={selectedTags}
                  maxOptions={MAX_TAGS_LIMIT}
                  placeholder="Select Tags"
                  onChange={handleTagsSelection(field.onChange)}
                  options={manualTags}
                  withActionButtons
                />
              )}
            />
          </div>
        </div>
        <div className={styles.PostCreateForm__Footer}>
          <div className={styles.PostCreateForm__CreateBtn}>
            <Button
              type="submit"
              size={ButtonSize.Small}
              theme={ButtonTheme.Primary}
              disabled={!isValid || processing}
              iconName={IconFontName.SendFilled}
            >
              {submitText}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};
