export const CSS_VAR_CONTAINER = '--transform-container';
export const CSS_VAR_TRANDING_PANEL = '--transform-trending-panel';

export const CSS_VAR_TRANDING_SIDEBAR_DURATION = '--trending-sidebar-smooth-duration';
export const CSS_VAR_SIDEBAR_DURATION = '--sidebar-smooth-duration';
export const CSS_VAR_NAV_ARROW_DURATION = '--nav-arrow-smooth-duration';

export const CSS_VAR_BACKDROP_OPACITY = '--mobile-backdrop-opacity';
export const CSS_VAR_BACKDROP_VISIBILITY = '--mobile-backdrop-visibility';

export const MAX_BACKDROP_OPACITY = 0.8;

export const SWIPE_HANDLE_IMPULSE = 60;
export const SWIPE_IMPULSE = 40;
export const ARROW_WIDTH = 64;

export const MAX_LEFT_SIDEBAR_WIDTH = '80%';

export const TOUCH_IGNORE_CLASS = 'touch-ignore';
export const SUBTABS_NAVIGATION_CLASS = 'subtabs-swipe-navigation';
