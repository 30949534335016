import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AuthStore } from 'stores/auth/auth.store';
import { FollowStore } from 'stores/follow/follow.store';
import { SearchResultType } from 'stores/search/interfaces/search.interface';
import { SearchScreen, SearchStore } from 'stores/search/search.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';
import { useSearchItemClick } from 'hooks/use-search-item-click';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { SearchFilters } from 'components/global-search/global-search.config';
import { GlobalSearchHeaderDesktop } from 'components/global-search/global-search-header-desktop/global-search-header-desktop.component';
import { GlobalSearchHeaderMobile } from 'components/global-search/global-search-header-mobile/global-search-header-mobile.component';
import { RecentSearch } from 'components/global-search/recent-search/recent-search.component';
import { SearchResult } from 'components/global-search/search-results/search-results.component';
import { SuggestionList } from 'components/global-search/suggestion-list/suggestion-list.component';

import styles from './global-search.module.less';

export const GlobalSearchContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const searchStore = useInjection<SearchStore>(TYPES.SearchStore);
  const followStore = useInjection<FollowStore>(TYPES.FollowStore);

  const navigate = useNavigate();
  const location = useLocation();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handleSearchItemClick = useSearchItemClick();

  const handleBackClick = useCallback(() => {
    searchStore.setFilter(SearchFilters.ALL);
    searchStore.setQuery({ searchPhrase: '' });

    if (searchStore.screen === SearchScreen.SearchResults) {
      searchStore.setIsNeedForceInputFocus(true);
    }

    if (location.key === 'default') {
      navigate(paths.HOME);
    } else {
      navigate(-1);
    }
  }, [searchStore, location, navigate]);

  const handleFocus = useCallback(() => {
    if (searchStore.screen === SearchScreen.SearchResults) {
      searchStore.setScreen(SearchScreen.Suggestion);
      searchStore.setFilter(SearchFilters.ALL);
    }
  }, [searchStore]);

  const handleRecentItemClick = useCallback(
    (text: string) => {
      searchStore.setFetching(true);
      searchStore.setScreen(SearchScreen.SearchResults);
      searchStore.setQuery({ searchPhrase: text });
    },
    [searchStore],
  );

  const handleLoadMore = useCallback(async () => {
    await searchStore.fetchNext();
  }, [searchStore]);

  const handleFollowClick = useCallback(
    async (resultId: number, type: SearchResultType, entityId: number) => {
      if (authStore.triggerAuthorisationCheck?.()) {
        await searchStore.followTeamOrPlayer(resultId, type, entityId);
        await followStore.loadFollowings();
      }
    },
    [authStore, searchStore, followStore],
  );

  const handleDeleteRecentItemClick = useCallback(
    (id: number) => {
      searchStore.deleteRecentItem(id.toString());
    },
    [searchStore],
  );

  const handleFilterChange = useCallback(
    (action: BarActionType) => {
      if (action.type === BarAction.Click && action.payload) {
        searchStore.setFilter(action.payload as SearchFilters);
      }
    },
    [searchStore],
  );

  return (
    <div className={styles.SearchContainer}>
      {isDesktopPlus ? (
        <GlobalSearchHeaderDesktop
          searchValue={searchStore.query.searchPhrase}
          onBackButtonClick={handleBackClick}
        />
      ) : (
        <GlobalSearchHeaderMobile onFocus={handleFocus} onBackButtonClick={handleBackClick} />
      )}

      {searchStore.screen === SearchScreen.RecentHistory && !isDesktopPlus && (
        <RecentSearch
          items={searchStore.recentSearch}
          onItemClick={handleRecentItemClick}
          onDeleteItem={handleDeleteRecentItemClick}
        />
      )}

      {searchStore.screen === SearchScreen.Suggestion && !isDesktopPlus && (
        <SuggestionList
          loading={searchStore.fetching}
          fetched={searchStore.fetched}
          searchValue={searchStore.query.searchPhrase}
          items={searchStore.preparedSearchItems}
          isBlackBackground
          onItemClick={handleSearchItemClick}
        />
      )}

      {searchStore.screen === SearchScreen.SearchResults && (
        <SearchResult
          hasMore={!searchStore.isLastPage}
          loading={searchStore.fetching}
          fetched={searchStore.fetched}
          onLoadMore={handleLoadMore}
          searchValue={searchStore.query.searchPhrase}
          items={searchStore.preparedSearchItems}
          filter={searchStore.filter}
          onFollowClick={handleFollowClick}
          onChangeFilter={handleFilterChange}
          allCount={searchStore?.meta?.meta?.counters?.total || 0}
          teamsCount={searchStore.meta?.meta?.counters?.TEAM || 0}
          playersCount={searchStore.meta?.meta?.counters?.PLAYER || 0}
          onItemClick={handleSearchItemClick}
        />
      )}
    </div>
  );
});
