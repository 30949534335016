import {
  ISingleCategoryStructureItemResponse,
  ISingleTagStructureItemResponse,
} from 'services/feed-filters/interfaces/feed-filters-response.interface';

import {
  ISingleCategoryStructureItem,
  ISingleTagStructureItem,
} from 'stores/feed-filters/interfaces/feed-filters.interface';

export function allFiltersAdapter(
  response: ISingleCategoryStructureItemResponse[],
): ISingleCategoryStructureItem[] {
  return response.map(categoryAdapter);
}

export function categoryAdapter(
  response: ISingleCategoryStructureItemResponse,
): ISingleCategoryStructureItem {
  const { uuid, name, discussion_feed: discussionFeed, icon_url: iconUrl, tags } = response;

  return {
    uuid,
    name,
    iconUrl,
    discussionFeed,
    tags: tags.map(singleFilterAdapter),
  };
}

export function singleFilterAdapter(
  response: ISingleTagStructureItemResponse,
): ISingleTagStructureItem {
  const { name, uuid, icon_url: iconUrl } = response;

  return {
    uuid,
    name,
    iconUrl,
  };
}
