import { useCallback, useState } from 'react';

import { StandingsMode } from 'stores/standings/enums/standings-mode.enum';
import { StandingsType } from 'stores/standings/enums/standings-type.enum';
import {
  STANDINGS_MODE_FILTER_OPTIONS,
  STANDINGS_TYPE_FILTER_OPTIONS,
} from 'stores/standings/standings.config';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { BaseSidePanel } from 'components/side-panels/base-side-panel/base-side-panel.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { Select } from 'components/ui/form-fields/select/select.component';
import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { TabsSwitcher } from 'components/ui/tabs-switcher/tabs-switcher.component';

import styles from './standings-filters.module.less';

interface IStandingsFiltersProps {
  seasonsOptions: ISelectOption[];
  seasonId: string;
  type: StandingsType;
  mode: StandingsMode;
  onTypeChange: (type: string) => void;
  onModeChange: (mode: string) => void;
  onSeasonIdChange: (seasonId: string) => void;
  onResetClick: () => void;
}

export const StandingsFilters = (props: IStandingsFiltersProps) => {
  const { seasonsOptions, seasonId, type, mode, onSeasonIdChange, onResetClick } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const [notAppliedSeasonFilter, setNotAppliedSeasonFilter] = useState(seasonId);

  const handleSeasonChange = useCallback(
    (value: string) => {
      if (isDesktopPlus) {
        onSeasonIdChange(value);
      } else {
        setNotAppliedSeasonFilter(value);
      }
    },
    [isDesktopPlus, onSeasonIdChange],
  );

  const handleFilterButtonClick = useCallback(() => {
    setMobileFiltersOpen(true);
  }, []);

  const handleCloseFilters = useCallback(() => {
    setMobileFiltersOpen(false);
    setNotAppliedSeasonFilter(seasonId);
  }, [seasonId]);

  const handleResetButtonClick = useCallback(() => {
    onResetClick();
    setMobileFiltersOpen(false);
    if (seasonsOptions.length) {
      setNotAppliedSeasonFilter(seasonsOptions[0].value);
    }
  }, [onResetClick, seasonsOptions]);

  const handleApplyClick = useCallback(() => {
    onSeasonIdChange(notAppliedSeasonFilter);
    setMobileFiltersOpen(false);
  }, [notAppliedSeasonFilter, onSeasonIdChange]);

  return (
    <div className={styles.StandingsFilters}>
      <Select
        id="standings_type"
        name="StandingsType"
        options={STANDINGS_TYPE_FILTER_OPTIONS}
        value={type}
        onChange={props.onTypeChange}
      />
      {isDesktopPlus && (
        <div className={styles.StandingsFilters__Season}>
          <Select
            id="standings_season"
            name="StandingsSeason"
            options={seasonsOptions}
            value={seasonId}
            onChange={handleSeasonChange}
          />
        </div>
      )}
      {!isDesktopPlus && (
        <IconButton
          iconName={IconFontName.Filter}
          theme={IconButtonTheme.Secondary}
          onClick={handleFilterButtonClick}
        />
      )}
      <div className={styles.StandingsFilters__Switcher}>
        <TabsSwitcher
          activeId={mode}
          items={STANDINGS_MODE_FILTER_OPTIONS}
          onItemClick={props.onModeChange}
        />
      </div>
      <BaseSidePanel isOpen={mobileFiltersOpen} isFullScreen>
        <div>
          <div className={styles.Mobile__Header}>
            <h4 className={styles.MobileHeader__Title}>Filter</h4>
            <IconButton
              iconName={IconFontName.Close}
              theme={IconButtonTheme.Secondary}
              onClick={handleCloseFilters}
            />
          </div>
          <div className={styles.Mobile__Content}>
            <div className={styles.MobileContent__Type}>Season</div>
            <Select
              id="standings_season"
              name="StandingsSeason"
              options={seasonsOptions}
              value={notAppliedSeasonFilter}
              onChange={handleSeasonChange}
            />
          </div>
        </div>
        <div className={styles.Buttons}>
          <Button
            fluid
            theme={ButtonTheme.Secondary}
            size={ButtonSize.SmallSecondary}
            onClick={handleResetButtonClick}
          >
            Reset
          </Button>
          <Button
            fluid
            theme={ButtonTheme.Primary}
            size={ButtonSize.SmallSecondary}
            onClick={handleApplyClick}
          >
            Apply
          </Button>
        </div>
      </BaseSidePanel>
    </div>
  );
};
