import { FC } from 'react';

import { FollowingsContainer } from 'containers/followings/followings.container';
import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';

import { useForceCollapseSmartBanner } from 'hooks/use-force-collapse-smart-banner';
import { useMobileLayoutStructure } from 'hooks/use-mobile-layout-structure';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const FollowingRoute: FC = () => {
  useMobileLayoutStructure({
    isHiddenBottomBar: true,
    isHiddenHeader: true,
  });

  useForceCollapseSmartBanner();

  return (
    <PageWrapperContainer>
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <FollowingsContainer />
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
