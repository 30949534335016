import { FC, useCallback, useMemo } from 'react';

import { isSafari } from 'helpers/detect-device-browser.util';

import { useMainProvider } from 'hooks/use-main-provider';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import {
  VectorIcon,
  VectorIconName,
  VectorIconSize,
} from 'components/ui/vector-icon/vector-icon.component';

import styles from './third-party-service-login.module.less';

interface IThirdPartyServiceLoginProps {
  onAppleSignIn: () => void;
  onGoogleSignIn: () => void;
}

export const ThirdPartyServiceLogin: FC<IThirdPartyServiceLoginProps> = (props) => {
  const { onAppleSignIn, onGoogleSignIn } = props;

  const { isNativeApp, isNativeAndroidApp } = useMainProvider();

  const isAvailableAppleButton = useMemo(() => {
    const isSafariBrowser = !isNativeApp && isSafari();
    const isIOSPlatform = isNativeApp && !isNativeAndroidApp;

    return isSafariBrowser || isIOSPlatform;
  }, [isNativeApp, isNativeAndroidApp]);

  const handleAppleClick = useCallback(() => {
    onAppleSignIn();
  }, [onAppleSignIn]);

  const handleGoogleClick = useCallback(() => {
    onGoogleSignIn();
  }, [onGoogleSignIn]);

  return (
    <div className={styles.ThirdPartyServiceLogin}>
      <span className={styles.ThirdPartyServiceLogin__Separator}>or continue with</span>
      <div className={styles.ThirdPartyServiceLogin__Wrapper}>
        {isAvailableAppleButton && (
          <Button
            theme={ButtonTheme.Tertiary}
            size={ButtonSize.Big}
            iconName={IconFontName.Apple}
            onClick={handleAppleClick}
          >
            Continue with Apple
          </Button>
        )}
        <Button theme={ButtonTheme.Tertiary} size={ButtonSize.Big} onClick={handleGoogleClick}>
          <VectorIcon size={VectorIconSize.M} name={VectorIconName.Google} />
          Continue with Google
        </Button>
      </div>
    </div>
  );
};
