import { memo, useCallback, useEffect, useMemo } from 'react';
import { ColDef, SortDirection } from 'ag-grid-community';
import cn from 'classnames';

import { IPlayerGlobalStatsRowData } from 'stores/global-stats/interfaces/global-stats-row-data.interfaces';
import { isSortDirection } from 'stores/global-stats/utils/is-sort-direction.util';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { RANK_COL_DEF } from 'components/base-table/tables.configs';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import {
  ColumnCharts,
  IColumnChartItem,
} from 'components/ui/column-charts/column-charts.component';

import { GlobalStatsTable } from './global-stats-table/global-stats-table.component';
import {
  PLAYERS_PAGE_SIZE,
  PLAYERS_STATS_ALL_COL_DEF,
  PLAYERS_STATS_NAME_COL_DEF,
} from './global-stats-table.config';

import styles from './global-stats.module.less';

interface IGlobalStatsPlayersProps {
  sortedField: Maybe<string>;
  playerSlug: Maybe<string>;
  sortDirection: Maybe<string>;
  sortedItems: IColumnChartItem[];
  playersStatsRowData: IPlayerGlobalStatsRowData[];
  selectedTeam: Maybe<ITeamsStats>;
  onSortChange: (sort: string, direction: string) => void;
  onPlayerClick: (slug: string) => void;
  onTeamClick: (teamId: number) => void;
}

export const GlobalStatsPlayers = memo((props: IGlobalStatsPlayersProps) => {
  const {
    playerSlug,
    sortedField,
    sortDirection,
    sortedItems,
    playersStatsRowData,
    selectedTeam,
    onPlayerClick,
    onTeamClick,
  } = props;

  const playersColumnDefs = useMemo<ColDef[]>(() => {
    const defaultSortDirection: SortDirection = 'desc';

    const nameColDef = {
      ...PLAYERS_STATS_NAME_COL_DEF,
      cellRendererParams: {
        onPlayerClick,
      },
    };

    if (sortedField && isSortDirection(sortDirection)) {
      return [
        RANK_COL_DEF,
        nameColDef,
        ...PLAYERS_STATS_ALL_COL_DEF.map((colDef) =>
          colDef.field === sortedField
            ? { ...colDef, sort: sortDirection }
            : { ...colDef, sort: null },
        ),
      ];
    }

    return [
      RANK_COL_DEF,
      nameColDef,
      ...PLAYERS_STATS_ALL_COL_DEF.map((colDef) =>
        colDef.field === 'points'
          ? { ...colDef, sort: defaultSortDirection }
          : { ...colDef, sort: null },
      ),
    ];
  }, [onPlayerClick, sortDirection, sortedField]);

  const classGlobalStatsColumnsNames = useMemo<string>(
    () => cn(styles.GlobalStats__Columns, TOUCH_IGNORE_CLASS),
    [],
  );

  const highlightRowOptions = useMemo(() => {
    if (playerSlug) {
      return {
        field: 'name.slug',
        value: playerSlug,
      };
    }

    return null;
  }, [playerSlug]);

  const handleTeamClick = useCallback(() => {
    if (selectedTeam) {
      onTeamClick(selectedTeam.id);
    }
  }, [onTeamClick, selectedTeam]);

  useEffect(() => {
    if (selectedTeam) {
      document.documentElement.style.setProperty('--selected-team-color', selectedTeam.color);
      document.documentElement.style.setProperty(
        '--selected-team-text-color',
        selectedTeam.textColor,
      );
    }
  }, [selectedTeam]);

  return (
    <div className={styles.GlobalStats}>
      <div className={classGlobalStatsColumnsNames}>
        <ColumnCharts sortedItems={sortedItems} />
      </div>

      <div className={styles.GlobalStats__Table}>
        {!!selectedTeam && (
          <button className={styles.GlobalStats__TeamRow} onClick={handleTeamClick}>
            <Avatar
              src={selectedTeam.mediumLogoUrl}
              username={selectedTeam.name}
              size={AvatarSize.S}
            />
            <h4 className={styles.GlobalStats__TeamName}>{selectedTeam.name}</h4>
          </button>
        )}
        {!!playersStatsRowData?.length && (
          <GlobalStatsTable
            tableGridOption={{
              rowData: playersStatsRowData,
              columnDefs: playersColumnDefs,
              pagination: true,
              paginationPageSize: PLAYERS_PAGE_SIZE,
              gridId: '2',
            }}
            highlightRowOptions={highlightRowOptions}
            autoScrollColumn={sortedField || ''}
            paginationWithUrlParam
            withColor={!!selectedTeam}
            onSortChange={props.onSortChange}
          />
        )}
      </div>
    </div>
  );
});
