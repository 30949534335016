export enum ContainerNamesEnum {
  SkipFollowTeamPlayers = 'skip_follow_team_players',
  PermissionsNotifications = 'permissions_notifications',
  PermissionsCameraAccess = 'permissions_camera_access',
  PermissionsPhotoLibrary = 'permissions_photo_library',

  IntroPromptTeam = 'intro_prompt_team',
  IntroPromptHome = 'intro_prompt_home',
  IntroPromptRankings = 'intro_prompt_rankings',
  IntroPromptArena = 'intro_prompt_arena',
  IntroPromptPlayerProfile = 'intro_prompt_player_profile',
}
