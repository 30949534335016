import { FC } from 'react';
import { components, OptionProps } from 'react-select';
import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import styles from './team-option.module.less';

export const TeamOption: FC<OptionProps<ITeamOption | null, false>> = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div className={styles.Team}>
        {data?.logo && <Avatar src={data.logo} size={AvatarSize.XS} username={data.code} />}
        {data?.label && <div className={styles.Team__Name}>{data?.label}</div>}
      </div>
    </components.Option>
  );
};
