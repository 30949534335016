import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';

import { FeedListBoard } from 'components/feed-list-board/feed-list-board.component';

export const JoinTeamContainer = observer(() => {
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);

  const navigate = useNavigate();

  const handleJoinTeam = useCallback(
    (teamId: string) => {
      if (teamId !== teamsStore.teamId?.toString()) {
        teamsStatsStore.setTeam(null);

        navigate(
          getPath(path.TEAM, {
            [path.TEAM_ID_PARAM]: teamId,
          }),
        );
      }
    },
    [navigate, teamsStatsStore, teamsStore],
  );

  return (
    <FeedListBoard
      title="View Teams"
      expandText="See all teams"
      items={teamsStore.teams}
      onClick={handleJoinTeam}
    />
  );
});
