interface IWithUuid {
  uuid: string;
}

const isEntryWithUuid = (entry: unknown): entry is IWithUuid =>
  typeof entry === 'object' && entry !== null && 'uuid' in entry;

export const filterShown = <T>(entries: T[]): T[] => {
  const uniqueEntriesUuidObj: Record<string, string> = {};
  const filteredEntries: T[] = [];

  entries.forEach((entry) => {
    if (isEntryWithUuid(entry)) {
      if (!uniqueEntriesUuidObj[entry.uuid]) {
        uniqueEntriesUuidObj[entry.uuid] = entry.uuid;
        filteredEntries.push(entry);
      }
    }
  });

  return filteredEntries;
};
