import { inject, injectable } from 'inversify';

import { ISingleCategoryStructureItemResponse } from 'services/feed-filters/interfaces/feed-filters-response.interface';
import { HttpService } from 'services/http/http.service';

import { TYPES } from 'configs/di-types.config';

import { POST_TAGS_CATEGORIES } from '../http/consts/api-endpoints.constants';

@injectable()
export class FeedFiltersService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public loadCategories(): Promise<IResponse<ISingleCategoryStructureItemResponse[]>> {
    return this.httpService.request<ISingleCategoryStructureItemResponse[]>({
      url: POST_TAGS_CATEGORIES,
      method: 'GET',
    });
  }
}
