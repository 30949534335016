const MIN_FLEX_VALUE = 0.25;
const MAX_FLEX_VALUE = 1;

// the function is used to handle cases where the values are close and we want to provide bigger visual separation.
export const getColumnChartFlex = (value: number, maxValue: number, minValue: number): number => {
  if (value === 0 && minValue >= 0) {
    return 0;
  }

  if (maxValue === minValue) {
    return MAX_FLEX_VALUE;
  }

  return (
    MIN_FLEX_VALUE +
    ((value - minValue) / (maxValue - minValue)) * (MAX_FLEX_VALUE - MIN_FLEX_VALUE)
  );
};
