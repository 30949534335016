import { inject, injectable } from 'inversify';

import { STATISTICS_STANDINGS } from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { StandingsMode } from 'stores/standings/enums/standings-mode.enum';

import { TYPES } from 'configs/di-types.config';

import { IStandingsResponse } from './interfaces/standings-response.interface';

@injectable()
export class StandingsService {
  private readonly httpService: HttpService;

  constructor(@inject<HttpService>(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public retrieve(seasonId: string, mode: StandingsMode): Promise<IResponse<IStandingsResponse[]>> {
    return this.httpService.request<IStandingsResponse[]>({
      method: 'GET',
      url: STATISTICS_STANDINGS,
      params: new URLSearchParams({ seasonId, mode }),
    });
  }
}
