import { FC, memo, useMemo } from 'react';
import { TwitterEmbed } from 'react-social-media-embed';

import { EmbedWrapperCard } from 'components/social-card/embed-wrapper-card/embed-wrapper-card.component';

interface ITwitterProps {
  sourceUrl: string;
  width: number;
}

const options = { theme: 'dark' };

export const Twitter: FC<ITwitterProps> = memo(({ sourceUrl, width }: ITwitterProps) => {
  const tweetId = useMemo(() => {
    const splitUrl = sourceUrl.split('/');
    const tweetIdIndex = splitUrl.length - 1;
    return splitUrl[tweetIdIndex];
  }, [sourceUrl]);

  return (
    <EmbedWrapperCard>
      <TwitterEmbed url={sourceUrl} twitterTweetEmbedProps={{ tweetId, options }} width={width} />
    </EmbedWrapperCard>
  );
});
