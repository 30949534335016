import { FC, memo, useCallback } from 'react';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './forgot-password-section.module.less';

interface IForgotPasswordSectionProps {
  buttonText: string;
  onButtonClick: () => void;
}

export const ForgotPasswordSection: FC<IForgotPasswordSectionProps> = memo(
  (props: IForgotPasswordSectionProps) => {
    const { buttonText, onButtonClick } = props;

    const handleButtonClick = useCallback(onButtonClick, [onButtonClick]);

    return (
      <div className={styles.ForgotPasswordSection}>
        <div className={styles.ForgotPasswordSection__Button}>
          <Button theme={ButtonTheme.Text} size={ButtonSize.Big} onClick={handleButtonClick}>
            {buttonText}
          </Button>
        </div>
      </div>
    );
  },
);
