import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './media-permission.module.less';

interface IMediaPermissionProps {
  onClose: () => void;
  title: string;
  message: string;
  primaryButtonText: string;
  imageUrl: string;
  onApprove: () => void;
}

export const MediaPermission = (props: IMediaPermissionProps) => {
  const { title, message, imageUrl, primaryButtonText } = props;

  return (
    <div className={styles.MediaPermission}>
      <div className={styles.MediaPermission__Image}>
        <img src={imageUrl} alt="customImage" />
      </div>
      <div className={styles.MediaPermission__Title}>{title}</div>
      <div className={styles.MediaPermission__Message}>{message}</div>
      <div className={styles.MediaPermission__Buttons}>
        <Button onClick={props.onApprove} fluid size={ButtonSize.Big} theme={ButtonTheme.Primary}>
          {primaryButtonText}
        </Button>
        <Button
          onClick={props.onClose}
          fluid
          size={ButtonSize.SmallSecondary}
          theme={ButtonTheme.Text}
        >
          Not now
        </Button>
      </div>
    </div>
  );
};
