import { useCallback, useEffect, useState } from 'react';

import { SingleCheckbox } from 'components/ui/form-fields/single-checkbox/single-checkbox.component';

import { IHookFormInput } from '../hook-form-input.interface';

import styles from './multiple-choose-checkboxes.module.less';

export interface IMultipleChooseCheckboxesProps extends IHookFormInput {
  selectedItems: ICheckboxItem[];
  onSelectClick: (value: ICheckboxItem[]) => void;
  title: string;
  shouldForceUpdate?: boolean;
}

export interface ICheckboxItem {
  id: string;
  label: string;
  selected: boolean;
  value: string;
  avatar?: {
    src: string;
    name: string;
  };
}

export const MultipleChooseCheckboxes = (props: IMultipleChooseCheckboxesProps) => {
  const { title, selectedItems, disabled = false, shouldForceUpdate, onSelectClick } = props;

  const [checkedState, setCheckedState] = useState(selectedItems);

  const handleChange = useCallback(
    (id: string) => {
      const updatedCheckedState = checkedState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            selected: !item.selected,
          };
        }
        return item;
      });
      setCheckedState(updatedCheckedState);
      onSelectClick(updatedCheckedState);
    },
    [checkedState, setCheckedState, onSelectClick],
  );

  const handleForceUpdate = useCallback(() => {
    setCheckedState(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (shouldForceUpdate) {
      handleForceUpdate();
    }
  }, [handleForceUpdate, shouldForceUpdate]);

  return (
    <div className={styles.MultiCheckbox}>
      <div className={styles.MultiCheckbox__Title}>{title}</div>
      <ul className={styles.MultiCheckbox__List}>
        {checkedState.map(({ id, label, selected, avatar }) => {
          return (
            <SingleCheckbox
              key={id}
              id={id}
              label={label}
              avatar={avatar}
              onChange={handleChange}
              selected={selected}
              disabled={disabled && !selected}
            />
          );
        })}
      </ul>
    </div>
  );
};
