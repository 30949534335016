import { useCallback, useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { AuthMode } from 'stores/auth/enums/auth-mode.enum';
import { ICountdownTime } from 'stores/auth/interfaces/countdown-time.interface';
import { ISignUpWithPasswordParams } from 'stores/auth/interfaces/sign-up-with-password-params.interface';

import { TYPES } from 'configs/di-types.config';

import { SignUp } from 'components/auth/sign-up/sign-up.component';
import { ConfirmEmailFormDataType } from 'components/forms/confirm-email/confirm-email-form.component';

export const SignUpContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const handleGetSignUpWithEmailCode = useCallback(
    (email: string) => {
      authStore.requestSignUpVerification({ email });

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.SignUpGetCode,
        },
      });
    },
    [authStore],
  );

  const handleSignUpHeaderButtonClick = useCallback(() => {
    authStore.setAuthMode(AuthMode.Login);

    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.SignUpLogin,
      },
    });
  }, [authStore]);

  const handleSignUpConfirmEmailSubmit = useCallback(
    ({ email, verificationCode }: ConfirmEmailFormDataType) => {
      authStore.confirmSignUpVerification({ email, code: verificationCode });
    },
    [authStore],
  );

  const handleSignUpWithPasswordSubmit = useCallback(
    (params: ISignUpWithPasswordParams) => {
      authStore.signUp(params);

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.SignUpCreateAccount,
        },
      });
    },
    [authStore],
  );

  const handleSetCountdownTimeSignUp = useCallback(
    (time: Maybe<ICountdownTime>) => {
      authStore.storeCountdownTimeSignUp(time);
    },
    [authStore],
  );

  const handleGoogleSignIn = useCallback(() => {
    authStore.googleSignIn();
  }, [authStore]);

  const handleAppleSignIn = useCallback(() => {
    authStore.appleSignIn();
  }, [authStore]);

  useEffect(() => {
    GoogleAnalyticService.event({
      eventName: 'screen_custom_view',
      eventParams: {
        screen_type: ScreenNamesEnum.SignUp,
      },
    });
  }, []);

  useEffect(() => {
    if (authStore.signUpWithEmailPreserved?.confirmed) {
      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.SignUpSubmitPassword,
        },
      });
    }
  }, [authStore.signUpWithEmailPreserved]);

  return (
    <SignUp
      processing={authStore.fetching}
      countdownTime={authStore.countdownTimeSignUp}
      withEmailPreserved={authStore.signUpWithEmailPreserved}
      onGetCode={handleGetSignUpWithEmailCode}
      onHeaderButtonClick={handleSignUpHeaderButtonClick}
      onConfirmEmailSubmit={handleSignUpConfirmEmailSubmit}
      onSignUpWithPasswordSubmit={handleSignUpWithPasswordSubmit}
      onSetCountdownTime={handleSetCountdownTimeSignUp}
      onAppleSignIn={handleAppleSignIn}
      onGoogleSignIn={handleGoogleSignIn}
    />
  );
});
