import { useCallback, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { GalleryStore } from 'stores/gallery/gallery.store';
import { getPreviewImage } from 'stores/gallery/utils/get-preview-image.util';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';

import { GalleryTheme } from 'components/modals/gallery-modal/gallery-modal.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './team-profile-thumbnail.module.less';

export const TeamProfileThumbnailContainer = observer(() => {
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);

  const backgroundImage = useMemo(() => {
    const thumbnailUrl = teamsStatsStore.teamsStats?.bannerUrl;

    if (thumbnailUrl) {
      return {
        backgroundImage: `url(${thumbnailUrl})`,
      };
    }

    return {};
  }, [teamsStatsStore.teamsStats]);

  const handleOpenPreview = useCallback(() => {
    if (!teamsStatsStore.teamsStats?.bannerUrl) {
      return;
    }

    const image = getPreviewImage(
      teamsStatsStore.teamsStats.bannerUrl,
      `${teamsStatsStore.teamsStats.nickname.toLowerCase()}-thumbnail`,
    );

    const watermarkEntity = {
      name: teamsStatsStore.teamsStats.nickname,
      logoUrl: teamsStatsStore.teamsStats.smallLogoUrl,
    };

    galleryStore.setGalleryAttachments([image]);
    galleryStore.setTheme(GalleryTheme.Preview);
    galleryStore.setWatermarkEntity(watermarkEntity);
    galleryStore.setIsGalleryOpen(true);
  }, [teamsStatsStore.teamsStats, galleryStore]);

  if (!teamsStatsStore.teamsStats) {
    return (
      <div className={styles.LoaderWrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    <div
      role="presentation"
      className={styles.TeamProfileThumbnail}
      style={backgroundImage}
      onClick={handleOpenPreview}
    />
  );
});
