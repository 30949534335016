import { memo, useMemo } from 'react';

import { IReaction } from 'components/posts/post-card/interfaces/reactions.interface';
import {
  ReactionDirectionType,
  ReactionTheme,
} from 'components/reactions/reactions/reactions.component';
import { ReactionsList } from 'components/reactions/reactions-list/reactions-list.component';
import { ReactionsPicker } from 'components/reactions/reactions-picker/reactions-picker.component';
import { Tooltip, TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import styles from './reactions-with-tooltip.module.less';

export interface IReactionsWithTooltipProps {
  theme: ReactionTheme;
  reactionsSum: number;
  reactions: {
    positive?: IReaction[];
    negative?: IReaction[];
  };
  triggerAuthorisationCheck?: () => boolean;
  onPickerClick?: () => void;
  onVoteClick: (reaction: string, type: ReactionDirectionType) => void;
}

export const ReactionsWithTooltip = memo((props: IReactionsWithTooltipProps) => {
  const { reactions, reactionsSum, theme = ReactionTheme.Regular } = props;

  const tooltipContent = useMemo(
    () => (
      <div className={styles.ReactionsWithTooltip}>
        <ReactionsList reactions={reactions} onVoteClick={props.onVoteClick} />
      </div>
    ),
    [reactions, props.onVoteClick],
  );

  return (
    <Tooltip
      eventType={TooltipEventType.click}
      placement="bottom-start"
      tooltipOffset={8}
      tooltipContent={tooltipContent}
      closeOnContentClick
      withoutShadowEffect
      allowOpeningCheck={props.triggerAuthorisationCheck}
      onPickerClick={props.onPickerClick}
    >
      <ReactionsPicker reactions={reactions} reactionsSum={reactionsSum} theme={theme} />
    </Tooltip>
  );
});
