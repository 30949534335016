import { memo } from 'react';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import { BaseIonBottomSheet } from '../base-ion-bottom-sheet/base-ion-bottom-sheet.component';

import styles from './confirmation-ion-bottom-sheet.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

interface IConfirmationIonBottomSheetProps {
  visible: boolean;
  title: string;
  content: string;
  secondaryButtonText: Maybe<string>;
  primaryButtonText: Maybe<string>;
  onClose: () => void;
  onSuccessCallback: () => void;
  onRejectCallback?: () => void;
}

export const ConfirmationIonBottomSheet = memo((props: IConfirmationIonBottomSheetProps) => {
  const { visible, title, content, secondaryButtonText, primaryButtonText } = props;

  return (
    <BaseIonBottomSheet
      visible={visible}
      safeAreaBottom={0}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      initialBreakpoint={INITIAL_BREAKPOINT}
      isAutoHeight
      onClose={props.onClose}
    >
      <div className={styles.ConfirmationBottomSheet}>
        <div className={styles.ConfirmationBottomSheet__Header}>
          <h4 className={styles.ConfirmationBottomSheet__Title}>{title}</h4>
        </div>
        <p className={styles.ConfirmationBottomSheet__Text}>{content}</p>
        <div className={styles.ConfirmationBottomSheet__Footer}>
          {secondaryButtonText && (
            <Button
              onClick={props.onRejectCallback}
              size={ButtonSize.Small}
              theme={ButtonTheme.Secondary}
              fluid
            >
              {secondaryButtonText}
            </Button>
          )}
          {primaryButtonText && (
            <Button
              onClick={props.onSuccessCallback}
              size={ButtonSize.Small}
              theme={ButtonTheme.Primary}
              fluid
            >
              {primaryButtonText}
            </Button>
          )}
        </div>
      </div>
    </BaseIonBottomSheet>
  );
});
