import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';

import { AuthStore } from 'stores/auth/auth.store';
import { AuthMode } from 'stores/auth/enums/auth-mode.enum';
import { SettingsScreenType } from 'stores/settings/enums/settings-screen-type.enum';
import { SettingsStore } from 'stores/settings/settings.store';

import { PolicyNavigationTrigger } from 'containers/privacy-policy/enums/policy-navigation-trigger.enum';

import { TYPES } from 'configs/di-types.config';

export const usePolicyBackClick = () => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const settingsStore = useInjection<SettingsStore>(TYPES.SettingsStore);

  const location = useLocation();
  const navigate = useNavigate();

  const handleBackClick = useCallback(() => {
    if (location.state) {
      navigate(-1);

      if (location.state.trigger === PolicyNavigationTrigger.Settings) {
        settingsStore.setScreenType(SettingsScreenType.Privacy);
      }

      if (location.state.trigger === PolicyNavigationTrigger.Auth) {
        authStore.setAuthMode(AuthMode.SignUp);
      }
    }
  }, [authStore, location.state, navigate, settingsStore]);

  return handleBackClick;
};
