import { IAttachmentsCountResponse } from 'services/posts/interfaces/posts-response.interface';

import { IAttachmentsCount } from 'stores/posts/interfaces/post.interface';

export const shareAttachmentsAdapter = (
  attachmentsResponse: IAttachmentsCountResponse,
): IAttachmentsCount => {
  return {
    filesCount: attachmentsResponse.files_count,
    mediaCount: attachmentsResponse.media_count,
  };
};
