import { createImage } from 'components/modals/image-crop-modal/crop-image.utils';

import logo from 'assets/images/svg/water-mark-logo.svg';

import { GalleryTheme } from './gallery-modal.component';

const drawRoundedImage = (
  ctx: CanvasRenderingContext2D,
  image: HTMLImageElement,
  x: number,
  y: number,
  sWidth: number,
  sHeight: number,
) => {
  const width = sWidth || image.naturalWidth;
  const height = sHeight || image.naturalHeight;
  const radius = { topLeft: 10, topRight: 10, bottomLeft: 10, bottomRight: 10 };
  ctx.beginPath();
  ctx.arc(x + radius.topLeft, y + radius.topLeft, radius.topLeft, Math.PI, Math.PI + Math.PI / 2);
  ctx.lineTo(x + width - radius.topRight, y);
  ctx.arc(
    x + width - radius.topRight,
    y + radius.topRight,
    radius.topRight,
    Math.PI + Math.PI / 2,
    Math.PI * 2,
  );
  ctx.lineTo(x + width, y + height - radius.bottomRight);
  ctx.arc(
    x + width - radius.bottomRight,
    y + height - radius.bottomRight,
    radius.bottomRight,
    Math.PI * 2,
    Math.PI / 2,
  );
  ctx.lineTo(x + radius.bottomLeft, y + height);
  ctx.arc(
    x + radius.bottomLeft,
    y + height - radius.bottomLeft,
    radius.bottomLeft,
    Math.PI / 2,
    Math.PI,
  );
  ctx.closePath();
  ctx.save();
  ctx.clip();
  ctx.drawImage(image, x, y, sWidth, sHeight);
  ctx.restore();
};

const drawCustomText = (
  ctx: CanvasRenderingContext2D,
  text: string,
  prevX: number,
  additionalSpaceAfter: number,
  font: string,
  color: string,
) => {
  ctx.font = font;
  const textWidth = ctx.measureText(text).width;
  ctx.fillStyle = color;
  ctx.textBaseline = 'middle';
  ctx.fillText(text, prevX, 20);
  return textWidth + prevX + additionalSpaceAfter;
};

const isWebpFormat = (url: string) => {
  const pattern = /\.webp(?:\?.*|)$/i;
  return pattern.test(url);
};

export const isGifFormat = (url: string) => {
  const regex = /\.gif$/i; // Match .gif at the end of the string (case-insensitive)
  return regex.test(url);
};

export const watermarkImageWithText = async (
  originalImage: HTMLImageElement,
  watermarkName: string,
  authorName: string,
  watermarkLogoUrl: string,
  theme: GalleryTheme,
) => {
  const isPreview = theme === GalleryTheme.Preview;
  const simpleTextFont = `400 12px IBM Plex Sans`;
  const boldTextFont = `700 12px IBM Plex Sans`;
  const minSupportImageWidth = 400;
  const logoWidth = 20;
  const logoHeight = 20;
  const textColor1 = '#97A5AA';
  const textColor2 = '#687C85';
  const digitsColor = '#3391CC';
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const canvasWidth = originalImage.width;
  const canvasHeight = originalImage.height;

  const isWebpExtension = isWebpFormat(originalImage.src);

  canvas.width = canvasWidth;
  canvas.height = canvasHeight + 40;

  if (context) {
    context.lineWidth = 40;
    context.strokeStyle = '#0D0E10';
    context.strokeRect(0, 0, canvas.width, 40);
    context.drawImage(originalImage, 0, 40, canvasWidth, canvasHeight);

    const partOneEnd = isPreview
      ? 40
      : drawCustomText(context, 'Posted in', 15, 30, simpleTextFont, textColor2);

    const partTwoEnd = drawCustomText(
      context,
      watermarkName,
      partOneEnd,
      5,
      boldTextFont,
      textColor1,
    );

    if (canvasWidth > minSupportImageWidth) {
      const partThreeEnd = isPreview
        ? partTwoEnd
        : drawCustomText(context, 'by', partTwoEnd, 5, simpleTextFont, textColor2);

      const partFourEnd = isPreview
        ? partThreeEnd
        : drawCustomText(context, authorName, partThreeEnd, 5, boldTextFont, textColor1);

      const partFiveEnd = drawCustomText(context, 'on', partFourEnd, 2, simpleTextFont, textColor2);

      drawCustomText(context, 'Digits', partFiveEnd, 0, boldTextFont, digitsColor);
    }

    const watermarkLogo = await createImage(watermarkLogoUrl);
    drawRoundedImage(context, watermarkLogo, partOneEnd - 25, 8, logoWidth, logoHeight);

    const digitsLogo = await createImage(logo);

    context.drawImage(
      digitsLogo,
      canvasWidth - digitsLogo.width - 10,
      canvasHeight - 10,
      digitsLogo.width,
      digitsLogo.height,
    );
  }

  const dataURLType = isWebpExtension ? 'image/png' : '';

  return canvas.toDataURL(dataURLType);
};
