import { useCallback, useEffect, useState } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { LayoutStore } from 'stores/layout/layout.store';

import {
  CUSTOM_SCROLL_EVENT,
  SCROLL_BUTTON_APPEARANCE_DISTANCE,
  SCROLL_TOP_ELEMENT,
} from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './scroll-top.module.less';

export const ScrollTopContainer = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);

  const [showScrollTopButton, setShowScrollTopButton] = useState<boolean>(false);

  const handleScrollToTop = useCallback(() => {
    layoutStore.setIsScrollToTopEvent(true);
  }, [layoutStore]);

  useEffect(() => {
    if (layoutStore.isScrollToTopEvent) {
      const topElement = document.getElementById(SCROLL_TOP_ELEMENT)!;

      topElement.scrollIntoView({ behavior: 'smooth' });
      layoutStore.setIsScrollToTopEvent(false);
    }
  }, [layoutStore.isScrollToTopEvent, layoutStore]);

  useEffect(() => {
    const listener = (event: Event) => {
      const detailCustomEvent = (event as CustomEvent).detail;

      const isButtonVisible =
        detailCustomEvent.target.scrollTop >= SCROLL_BUTTON_APPEARANCE_DISTANCE;

      setShowScrollTopButton(isButtonVisible || false);
    };

    document.addEventListener(CUSTOM_SCROLL_EVENT, listener);

    return () => {
      document.removeEventListener(CUSTOM_SCROLL_EVENT, listener);
    };
  }, []);

  if (!showScrollTopButton) {
    return null;
  }

  return (
    <div className={styles.ScrollTopButton}>
      <Button theme={ButtonTheme.Primary} size={ButtonSize.Small} onClick={handleScrollToTop}>
        Back to Top
      </Button>
    </div>
  );
});
