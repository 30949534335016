import { z } from 'zod';

import { MAX_NAME_LENGTH, MIN_NAME_LENGTH } from 'validation/validation.constants';

export const realNameSchema = z
  .union([
    z
      .string()
      .max(MAX_NAME_LENGTH, `Max name length is ${MAX_NAME_LENGTH}`)
      .min(MIN_NAME_LENGTH, `Min name length is ${MIN_NAME_LENGTH}`),
    z.string().length(0, `Min name length is ${MIN_NAME_LENGTH}`),
  ])
  .optional();

export const userNameSchema = z
  .string({ required_error: 'Username is required' })
  .min(MIN_NAME_LENGTH, `Min name length is ${MIN_NAME_LENGTH}`)
  .max(MAX_NAME_LENGTH, `Max name length is ${MAX_NAME_LENGTH}`);
