import { FC } from 'react';
import { components, MultiValueGenericProps } from 'react-select';

import { ICheckboxItem } from 'components/ui/form-fields/multiple-choose-checkboxes/multiple-choose-checkboxes.component';

import styles from './checkbox-select-multi-value-container.module.less';

interface ICheckboxSelectMultiValueGenericProps
  extends MultiValueGenericProps<ICheckboxItem, true> {}

export const CheckboxSelectMultiValueContainer: FC<ICheckboxSelectMultiValueGenericProps> = ({
  children,
  ...props
}: ICheckboxSelectMultiValueGenericProps) => {
  return (
    <components.MultiValueContainer {...props}>
      <div className={styles.MultiValueContainer}>{children}</div>
    </components.MultiValueContainer>
  );
};
