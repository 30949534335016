import { ITopPlayerResponse } from 'services/player/interfaces/top-players.interface';

import { ITopPlayerItem } from '../interfaces/top-player-item.interface';

export function topPlayersAdapter(topPlayersResponse: ITopPlayerResponse[]): ITopPlayerItem[] {
  const topPlayers = topPlayersResponse.map((player) => ({
    type: player.data.type,
    playerId: player.data.player_id,
    firstname: player.data.firstname,
    lastname: player.data.lastname,
    slug: player.data.slug,
    avatar: player.data.small_logo_url,
    followingsCount: player.followings_count,
  }));

  return topPlayers;
}
