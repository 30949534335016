import { memo, useMemo } from 'react';
import cn from 'classnames';

import { StatsMode } from 'stores/global-stats/enums/global-stats.enums';
import { STATS_SWITCHER_OPTIONS } from 'stores/global-stats/global-stats.config';
import { IGlobalStatsPlayersFilters } from 'stores/global-stats/interfaces/global-stats-players-filters.interface';

import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { Select } from 'components/ui/form-fields/select/select.component';
import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { TabsSwitcher } from 'components/ui/tabs-switcher/tabs-switcher.component';

import { StatsPlayersFilters } from '../stats-players-filters/stats-players-filters.component';

import styles from './stats-header.module.less';

interface IStatsHeaderProps {
  isPlayersAllRoute: boolean;
  title: string;
  withTitleButton: boolean;
  activeSwitcherOption: StatsMode;
  seasonId: string;
  seasonsOptions: ISelectOption[];
  playersFilters: IGlobalStatsPlayersFilters;
  teamsOptions: ITeamOption[];
  onSwitcherClick: (option: string) => void;
  onSeasonIdChange: (season: string) => void;
  onTitleButtonClick: () => void;
  onPlayersFiltersChange: (filters: IGlobalStatsPlayersFilters) => void;
  setDisabledScroll: (isDisabledScroll: boolean) => void;
  onResetClick: () => void;
}

export const StatsHeader = memo((props: IStatsHeaderProps) => {
  const {
    isPlayersAllRoute,
    title,
    withTitleButton,
    activeSwitcherOption,
    seasonId,
    seasonsOptions,
    playersFilters,
    teamsOptions,
    setDisabledScroll,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const selectsClassNames = useMemo(
    () =>
      cn(styles.StatsHeader__Selects, {
        [styles['StatsHeader__Selects--multiple']]: isPlayersAllRoute,
      }),
    [isPlayersAllRoute],
  );

  return (
    <div className={styles.StatsHeader}>
      {isDesktopPlus && (
        <div className={styles.StatsHeader__Title}>
          {withTitleButton && (
            <Button
              onClick={props.onTitleButtonClick}
              theme={ButtonTheme.Tertiary}
              size={ButtonSize.Small}
              iconName={IconFontName.ChevronLeft}
            >
              Top Leaders
            </Button>
          )}
          <h3>{title}</h3>
        </div>
      )}
      <div className={styles.StatsHeader__Filters}>
        <div className={selectsClassNames}>
          <Select
            id="stats_season"
            name="StatsSeason"
            options={seasonsOptions}
            value={seasonId}
            onChange={props.onSeasonIdChange}
          />

          {isPlayersAllRoute && (
            <StatsPlayersFilters
              playersFilters={playersFilters}
              teamsOptions={teamsOptions}
              onPlayersFiltersChange={props.onPlayersFiltersChange}
              setDisabledScroll={setDisabledScroll}
              onResetClick={props.onResetClick}
            />
          )}
        </div>

        <div className={styles.StatsHeader__Switcher}>
          <TabsSwitcher
            activeId={activeSwitcherOption}
            items={STATS_SWITCHER_OPTIONS}
            onItemClick={props.onSwitcherClick}
          />
        </div>
      </div>
    </div>
  );
});
