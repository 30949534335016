import { z } from 'zod';

import {
  MAX_POLL_ANSWERS_LENGTH,
  MAX_POLL_TITLE_LENGTH,
  MIN_ATTACHMENTS_LENGTH,
  MIN_CONTENT_LENGTH,
  MIN_POLL_ANSWERS_LENGTH,
  MIN_POLL_TITLE_LENGTH,
} from 'validation/validation.constants';

const attachedImagesSchema = z.array(
  z.object({
    url: z.string(),
    uuid: z.string(),
    hash: z.string(),
    mimeType: z.string(),
    filename: z.string(),
  }),
);

const pollOptionSchema = z.object({
  title: z
    .string()
    .min(MIN_POLL_TITLE_LENGTH, `Min answer length is ${MIN_POLL_TITLE_LENGTH}`)
    .max(MAX_POLL_TITLE_LENGTH, `Max answer length is ${MAX_POLL_TITLE_LENGTH}`),
  order: z.number(),
  id: z.string(),
});

const attachedPollSchema = z.object({
  type: z.string(),
  options: pollOptionSchema
    .array()
    .min(MIN_POLL_ANSWERS_LENGTH, `Min answers amount is ${MIN_POLL_ANSWERS_LENGTH}`)
    .max(MAX_POLL_ANSWERS_LENGTH, `Max answers amount is ${MAX_POLL_ANSWERS_LENGTH}`),
});

const attachedVideoSchema = z.string().array().length(1, { message: 'Cannot be empty' });

const attachedGifSchema = z.string().array().min(1, `Cannot be empty`);

const contentSchema = z.object({
  value: z.string(),
  length: z.number(),
});

const contentWithMinLength = z.object({
  value: z.string(),
  length: z.number().min(MIN_CONTENT_LENGTH, `Min content length is ${MIN_CONTENT_LENGTH}`),
});

export const editorSchemaPost = z.object({
  content: contentSchema.optional(),
  attachments: z
    .union([
      // no need in attachments
      z.object({
        images: attachedImagesSchema.optional(),
        poll: attachedPollSchema.optional(),
        gifs: attachedGifSchema.optional(),
        videos: attachedVideoSchema.optional(),
      }),
      // need one video
      z.object({
        images: attachedImagesSchema.optional(),
        poll: attachedPollSchema.optional(),
        gifs: attachedGifSchema.optional(),
        videos: attachedVideoSchema,
      }),
      // need one poll
      z.object({
        images: attachedImagesSchema.optional(),
        poll: attachedPollSchema,
        gifs: attachedGifSchema.optional(),
        videos: attachedVideoSchema.optional(),
      }),
      // need min 1 image
      z.object({
        images: attachedImagesSchema.nonempty(
          `Min attachments length is ${MIN_ATTACHMENTS_LENGTH}`,
        ),
        poll: attachedPollSchema.optional(),
        gifs: attachedGifSchema.optional(),
        videos: attachedVideoSchema.optional(),
      }),
      // need min 1 gif
      z.object({
        images: attachedImagesSchema.optional(),
        poll: attachedPollSchema.optional(),
        gifs: attachedGifSchema,
        videos: attachedVideoSchema.optional(),
      }),
    ])
    .nullable()
    .optional(),
});

export const gameChatEditorSchemaComment = z.union([
  z.object({
    content: contentSchema.optional(),
    attachments: z.object({
      poll: attachedPollSchema.optional(),
      gifs: attachedGifSchema.optional(),
    }),
  }),
  z.object({
    content: contentWithMinLength,
    attachments: z
      .object({
        poll: attachedPollSchema.optional(),
        gifs: attachedGifSchema.optional(),
      })
      .nullable()
      .optional(),
  }),
  z.object({
    content: contentSchema.optional(),
    attachments: z.object({
      poll: attachedPollSchema.optional(),
      gifs: attachedGifSchema,
    }),
  }),
]);

export const editorSchemaComment = z.union([
  z.object({
    content: contentSchema.optional(),
    attachments: z.object({
      images: attachedImagesSchema.nonempty(`Min attachments length is ${MIN_ATTACHMENTS_LENGTH}`),
      poll: attachedPollSchema.optional(),
      videos: attachedVideoSchema.optional(),
      gifs: attachedGifSchema.optional(),
    }),
  }),
  z.object({
    content: contentSchema.optional(),
    attachments: z.object({
      images: attachedImagesSchema.optional(),
      poll: attachedPollSchema.optional(),
      videos: attachedVideoSchema.nonempty(`Min attachments length is ${MIN_ATTACHMENTS_LENGTH}`),
      gifs: attachedGifSchema.optional(),
    }),
  }),
  z.object({
    content: contentWithMinLength,
    attachments: z
      .object({
        images: attachedImagesSchema.optional(),
        poll: attachedPollSchema.optional(),
        videos: attachedVideoSchema.optional(),
        gifs: attachedGifSchema.optional(),
      })
      .nullable()
      .optional(),
  }),
  z.object({
    content: contentSchema.optional(),
    attachments: z.object({
      images: attachedImagesSchema.optional(),
      poll: attachedPollSchema.optional(),
      videos: attachedVideoSchema.optional(),
      gifs: attachedGifSchema,
    }),
  }),
]);
