import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import cn from 'classnames';

import { RIGHT_SIDEBAR_ELEMENT } from 'configs/controls.config';

import { Portal, PortalType } from 'components/ui/portal/portal.component';

import styles from './base-side-panel.module.less';

interface IBaseSidePanelProps {
  isOpen: boolean;
  isFullScreen?: boolean;
  children: ReactNode;
}

export const BaseSidePanel: FC<IBaseSidePanelProps> = (
  props: PropsWithChildren<IBaseSidePanelProps>,
) => {
  const { isOpen, isFullScreen = false, children } = props;

  const classNames = useMemo<string>(
    () =>
      cn(styles.BaseSidePanel, {
        [styles['BaseSidePanel--open']]: isOpen,
        [styles['BaseSidePanel--full-screen']]: isFullScreen,
      }),
    [isOpen, isFullScreen],
  );

  return (
    <Portal type={PortalType.Sidebar}>
      <div className={classNames}>
        <div className={styles.BaseSidePanel__Main} id={RIGHT_SIDEBAR_ELEMENT}>
          {children}
        </div>
      </div>
    </Portal>
  );
};
