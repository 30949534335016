import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { IContactUsRequestPayload } from 'services/auth/interfaces/contact-us-request-payload.interface';

import { AuthStore } from 'stores/auth/auth.store';

import { TYPES } from 'configs/di-types.config';
import * as paths from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';
import { useMobileLayoutStructure } from 'hooks/use-mobile-layout-structure';

import { ContactUs } from 'components/contact-us/contact-us.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';

export const ContactUsContainer = observer(() => {
  const navigation = useNavigate();
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const { isNativeApp } = useMainProvider();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  useMobileLayoutStructure({
    isHiddenBottomBar: true,
    isHiddenHeader: true,
  });

  const handleNavigation = useCallback(() => {
    if (authStore.isAuthorised && isNativeApp) {
      navigation(paths.SETTINGS);
      return;
    }

    if (authStore.isAuthorised && !isNativeApp) {
      navigation(paths.PROFILE);
      return;
    }

    navigation(paths.HOME_FEED);
  }, [authStore.isAuthorised, isNativeApp, navigation]);

  const handleBackClick = useCallback(() => {
    setConfirmationModalOpen(isFormDirty);

    if (isFormDirty && !confirmationModalOpen) return;

    handleNavigation();
  }, [confirmationModalOpen, handleNavigation, isFormDirty]);

  const handleSubmit = useCallback(
    async (contactUsRequestPayload: IContactUsRequestPayload) => {
      const contactUsResponse = await authStore.contactUsRequest(contactUsRequestPayload);

      if (!contactUsResponse) {
        return;
      }

      if (isNativeApp && authStore.isAuthorised) {
        navigation(paths.SETTINGS);
        return;
      }

      navigation(paths.HOME_FEED);
    },
    [authStore, isNativeApp, navigation],
  );

  const handleConfirmationModalClose = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);

  const handleFormStateChange = useCallback((flag: boolean) => {
    setIsFormDirty(flag);
  }, []);

  return (
    <>
      <ContactUs
        onBackClick={handleBackClick}
        onSubmit={handleSubmit}
        onFormStateChange={handleFormStateChange}
      />
      <ConfirmationModal
        content="You have unsaved data. Are you sure you want to leave this page?"
        onSuccessCallback={handleBackClick}
        onClose={handleConfirmationModalClose}
        title="Close Contact Us"
        visible={confirmationModalOpen}
        primaryButtonText="Leave"
        secondaryButtonText="Cancel"
      />
    </>
  );
});
