import { memo } from 'react';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { ConfirmationIonBottomSheet } from 'components/bottom-sheet/confirmation-ion-bottom-sheet/confirmaiton-ion-bottom-sheet.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';

interface IConfirmationPopupProps {
  visible: boolean;
  title: string;
  content: string;
  secondaryButtonText: Maybe<string>;
  primaryButtonText: Maybe<string>;
  onClose: () => void;
  onSuccessCallback: () => void;
  onRejectCallback?: () => void;
}

export const ConfirmationPopup = memo((props: IConfirmationPopupProps) => {
  const { visible, title, content, secondaryButtonText, primaryButtonText } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  if (isDesktopPlus) {
    return (
      <ConfirmationModal
        visible={visible}
        title={title}
        content={content}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        onClose={props.onClose}
        onSuccessCallback={props.onSuccessCallback}
        onRejectCallback={props.onRejectCallback}
      />
    );
  }

  return (
    <ConfirmationIonBottomSheet
      visible={visible}
      title={title}
      content={content}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      onClose={props.onClose}
      onSuccessCallback={props.onSuccessCallback}
      onRejectCallback={props.onRejectCallback}
    />
  );
});
