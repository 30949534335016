import { memo, useMemo } from 'react';
import cn from 'classnames';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { IGameTeam } from 'services/statistics/interface/game-team.interface';

import { Avatar, AvatarSize, IconAvatarSize } from 'components/ui/avatar/avatar.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './statistics-team.module.less';

interface IStatisticsTeamProps {
  team: IGameTeam;
  gameStatus: GameStatus;
  isWinning: boolean;
  isHomeGame?: boolean;
}

export const StatisticsTeam = memo((props: IStatisticsTeamProps) => {
  const { team, gameStatus, isWinning, isHomeGame = false } = props;

  const statisticsTeamClasses = useMemo(
    () =>
      cn(styles.StatisticsTeam, {
        [styles['StatisticsTeam--winning']]: isWinning,
        [styles['StatisticsTeam--scheduled']]: GameStatus.Scheduled === gameStatus,
      }),
    [isWinning, gameStatus],
  );

  const statisticsTeamStyles = useMemo(() => {
    if (GameStatus.Live === gameStatus || GameStatus.Halftime === gameStatus) {
      return {
        backgroundImage: `linear-gradient(90deg, ${team.color}, rgba(87, 39, 131, 0))`,
      };
    }

    return undefined;
  }, [team, gameStatus]);

  const homeGameIcon = useMemo(() => (isHomeGame ? IconFontName.Mention : undefined), [isHomeGame]);

  return (
    <div className={statisticsTeamClasses} style={statisticsTeamStyles}>
      <div className={styles.StatisticsTeam__Items}>
        <Avatar
          src={team.smallLogoUrl}
          username={team.code}
          size={AvatarSize.XS}
          iconSize={IconAvatarSize.S}
          iconFontName={homeGameIcon}
        />
        <span className={styles.StatisticsTeam__TeamCode}>{team.code}</span>
        <div className={styles.StatisticsTeam__Points}>
          {gameStatus === GameStatus.Scheduled ? (
            <span>
              {team.totalWin}-{team.totalLoss}
            </span>
          ) : (
            team.score.points
          )}
        </div>
      </div>
    </div>
  );
});
