import { memo, useCallback, useMemo, useRef } from 'react';
import { GridOptions, GridReadyEvent, SortChangedEvent, SortDirection } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import cn from 'classnames';

import { BaseTableGrid } from 'components/base-table/base-table.component';
import { handleRankValue } from 'components/base-table/helpers/handle-rank-value.util';
import { IHighlightRowOptions } from 'components/base-table/interfaces/highlight-row-options.interface';

import './ag-global-stats.less';

interface IGlobalStatsTableProps {
  withColor?: boolean;
  tableGridOption: GridOptions;
  paginationWithUrlParam?: boolean;
  onSortChange: (sort: string, direction: string) => void;
  highlightRowOptions?: Maybe<IHighlightRowOptions>;
  autoScrollColumn?: string;
}

export const GlobalStatsTable = memo((props: IGlobalStatsTableProps) => {
  const {
    tableGridOption,
    highlightRowOptions,
    autoScrollColumn,
    withColor = false,
    paginationWithUrlParam,
    onSortChange,
  } = props;

  const gridRef = useRef<AgGridReact>(null);

  const handleSortChanged = useCallback(
    (event: SortChangedEvent) => {
      const sortedTableState = event.columnApi?.getColumnState();
      event.api?.refreshHeader();
      event.api?.refreshCells();

      const sortedColumn = sortedTableState.find((col) => !!col.sort);
      let rankDirection: SortDirection = null;

      if (sortedColumn?.colId === 'pointsAllowed') {
        rankDirection = sortedColumn.sort === 'asc' ? 'desc' : 'asc';
      }

      if (sortedColumn && sortedColumn.sort) {
        onSortChange(sortedColumn.colId, sortedColumn.sort);
      }

      gridRef.current?.columnApi?.applyColumnState({ state: sortedTableState });

      handleRankValue(event, rankDirection);
      event.api.paginationGoToFirstPage();
    },
    [onSortChange],
  );

  const handleGridReady = useCallback((event: GridReadyEvent) => {
    const sortedTableState = event.columnApi.getColumnState();
    const sortedColumnState = sortedTableState.find((col) => !!col.sort);
    let rankDirection: SortDirection = null;

    if (sortedColumnState?.colId === 'pointsAllowed') {
      rankDirection = sortedColumnState.sort === 'asc' ? 'desc' : 'asc';
    }

    handleRankValue(event, rankDirection);
  }, []);

  const classNames = useMemo(
    () => cn('ag-theme-global-stats', { 'ag-theme-global-stats--with-color': withColor }),
    [withColor],
  );

  return (
    <div key={tableGridOption.gridId} className={classNames}>
      <BaseTableGrid
        ref={gridRef}
        gridOptions={tableGridOption}
        paginationWithUrlParam={paginationWithUrlParam}
        onSortChanged={handleSortChanged}
        onGridReady={handleGridReady}
        highlightRowOptions={highlightRowOptions}
        autoScrollColumn={autoScrollColumn}
      />
    </div>
  );
});
