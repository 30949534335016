import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionClick } from 'components/bars/bar-action.type';
import { ITab } from 'components/ui/tabs/tabs.component';

export const TEAM_PROFILE_TABS: ITab<BarActionClick<string>>[] = [
  {
    id: 'overview',
    title: 'Overview',
    action: {
      type: BarAction.Click,
      payload: 'overview',
    },
  },
  {
    id: 'roster',
    title: 'Roster',
    action: {
      type: BarAction.Click,
      payload: 'roster',
    },
  },
];
