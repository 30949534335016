import { memo, useEffect } from 'react';

import { ContainerNamesEnum } from 'services/google-analytic/enums/container-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { NotificationPermissionBottomSheet } from 'components/bottom-sheet/notification-permission-bottom-sheet/notification-permission-bottom-sheet.component';
import { NotificationPermissionModal } from 'components/modals/notification-permission-modal/notification-permission-modal.component';

interface INotificationPermissionPopupProps {
  visible: boolean;
  onClose: () => void;
  onAllowNotifications: () => void;
}

export const NotificationPermissionPopup = memo((props: INotificationPermissionPopupProps) => {
  const { visible } = props;

  useEffect(() => {
    if (visible) {
      GoogleAnalyticService.event({
        eventName: 'container_custom_view',
        eventParams: {
          container_type: ContainerNamesEnum.PermissionsNotifications,
        },
      });
    }
  }, [visible]);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  if (isDesktopPlus) {
    return (
      <NotificationPermissionModal
        visible={visible}
        onAllowNotifications={props.onAllowNotifications}
        onClose={props.onClose}
      />
    );
  }

  if (visible) {
    return (
      <NotificationPermissionBottomSheet
        visible={visible}
        onAllowNotifications={props.onAllowNotifications}
        onClose={props.onClose}
      />
    );
  }

  return null;
});
