import { FC } from 'react';

import { IPlayerStatsMini } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStatsColors } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { PlayerCard } from 'components/ui/player-card/player-card.component';

import teamProfileStyles from '../team-profile.module.less';
import styles from './team-profile-roster.module.less';

interface ITeamProfileRosterProps {
  players: IPlayerStatsMini[];
  teamColors?: ITeamsStatsColors;
  onPlayerClick: (slug: string) => void;
}

export const TeamProfileRoster: FC<ITeamProfileRosterProps> = (props) => {
  const { players, teamColors = null } = props;

  return (
    <div className={teamProfileStyles.TeamProfile}>
      <div className={teamProfileStyles.TeamProfile__Section}>
        <div className={teamProfileStyles.TeamProfile__Header}>Roster</div>
        <div className={styles.RosterSection}>
          {players.map((player) => (
            <PlayerCard
              key={player.id}
              player={player}
              teamColors={teamColors}
              onPlayerClick={props.onPlayerClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
