import { useCallback, useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { ICountdownTime } from 'stores/auth/interfaces/countdown-time.interface';
import { IResetPasswordParams } from 'stores/auth/interfaces/forgot-password.interface';

import { TYPES } from 'configs/di-types.config';

import { ForgotPassword } from 'components/auth/forgot-password/forgot-password.component';
import { ConfirmEmailFormDataType } from 'components/forms/confirm-email/confirm-email-form.component';

export const ForgotPasswordContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const handleForgotPasswordCode = useCallback(
    (email: string) => {
      authStore.requestForgotPasswordVerification({ email });
    },
    [authStore],
  );

  const handleResetPasswordVerification = useCallback(
    ({ email, verificationCode }: ConfirmEmailFormDataType) => {
      authStore.confirmResetPasswordVerification({ email, code: verificationCode });

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.LoginForgotPasswordGetCode,
        },
      });
    },
    [authStore],
  );

  const handleResetPassword = useCallback(
    (params: IResetPasswordParams) => {
      authStore.setNewPassword(params);
    },
    [authStore],
  );

  const handleSetCountdownTimeForgotPassword = useCallback(
    (time: Maybe<ICountdownTime>) => {
      authStore.storeCountdownTimeForgotPassword(time);
    },
    [authStore],
  );

  useEffect(() => {
    GoogleAnalyticService.event({
      eventName: 'screen_custom_view',
      eventParams: {
        screen_type: ScreenNamesEnum.LoginForgotPassword,
      },
    });
  }, []);

  return (
    <ForgotPassword
      processing={authStore.fetching}
      countdownTime={authStore.countdownTimeForgotPassword}
      withEmailPreserved={authStore.forgotPasswordPreserved}
      onGetCode={handleForgotPasswordCode}
      onResetPasswordVerification={handleResetPasswordVerification}
      onResetPasswordSubmit={handleResetPassword}
      onSetCountdownTime={handleSetCountdownTimeForgotPassword}
    />
  );
});
