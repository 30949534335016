export type ColorResponseType = [number, number, number];

export const convertColorToRGBA = (color: ColorResponseType, opacity: number): string => {
  if (!color) {
    return '';
  }

  if (Array.isArray(color)) {
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacity})`;
  }

  return color;
};

export const convertColorToRGB = (color: ColorResponseType): string => {
  if (!color) {
    return '';
  }

  if (Array.isArray(color)) {
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  }

  return color;
};
