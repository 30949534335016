export const isIosDevice = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const isSafari = () => {
  const isAppleOC = navigator.vendor.match(/apple/i);
  const hasSafariCriOS = navigator.userAgent.match(/crios/i);
  const hasSafariFxiOS = navigator.userAgent.match(/fxios/i);
  const hasSafariOpera = navigator.userAgent.match(/fxios/i);

  const isSafariBrowser = isAppleOC && !hasSafariCriOS && !hasSafariFxiOS && !hasSafariOpera;

  return isSafariBrowser;
};
