import { FC, useMemo } from 'react';
import cn from 'classnames';

import styles from './bookmark-footer.module.less';

export enum BookmarkFooterType {
  Post,
  Comment,
  Team,
}

export enum BookmarkFooterTheme {
  Post,
  Comment,
}

interface IBookmarkFooterProps {
  theme: BookmarkFooterTheme;
  time: string;
  title: string;
  type: BookmarkFooterType;
}

export const BookmarkFooter: FC<IBookmarkFooterProps> = (props) => {
  const { theme, time, title, type } = props;

  const footerTitle = useMemo(() => {
    if (type === BookmarkFooterType.Post) {
      return 'Home - feed';
    }

    if (type === BookmarkFooterType.Comment) {
      return `Post - ${title}`;
    }

    if (type === BookmarkFooterType.Team) {
      return title;
    }

    return '';
  }, [title, type]);

  const bookmarksFooterClasses = useMemo(
    () =>
      cn(styles.BookmarksFooter, {
        [styles['BookmarksFooter--comment-theme']]: theme === BookmarkFooterTheme.Comment,
      }),
    [theme],
  );

  return (
    <div className={bookmarksFooterClasses}>
      <span className={styles.BookmarksFooter__Time}>Saved {time} from </span>
      <span className={styles.BookmarksFooter__Title}>{footerTitle}</span>
    </div>
  );
};
