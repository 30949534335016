import { IGameDetailedTopPerformerResponse } from 'services/games-detailed/interfaces/game-detailed-response.interface';

import { IGameDetailedTopPlayer } from '../interfaces/game-detailed-top-player.interface';

export function gameDetailedTopPerformAdapter(
  topPerformerResponse: Maybe<IGameDetailedTopPerformerResponse>,
): Maybe<IGameDetailedTopPlayer> {
  if (!topPerformerResponse) {
    return null;
  }

  const {
    player,
    is_leader: isLeader,
    position,
    points,
    total_rebounds: reb,
    steals,
    assists,
    blocks,
  } = topPerformerResponse;

  return {
    slug: player.slug,
    isLeader,
    firstName: player.firstname,
    lastName: player.lastname,
    position,
    pts: points,
    reb,
    stl: steals,
    ast: assists,
    blk: blocks,
    avatarSrc: player.small_logo_url,
  };
}
