import { FC, useCallback, useEffect, useState } from 'react';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IEventParams } from 'services/google-analytic/interfaces/event-payload.interface';

import { GuideStorageKeys } from 'stores/player/enums/guide-storage-keys.enum';
import { IPlayerDetails } from 'stores/player/interfaces/player-profile.interfaces';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { MonthlySplit } from 'components/player-profile/player-profile-games/monthly-split/monthly-split.component';
import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';
import { Loader } from 'components/ui/loader/loader.component';

import { ISplitForOneMonth } from '../player-profile-games.interface';

import styles from './player-profile-games-feed.module.less';

const SHOWING_COMPACT_VIEW_LIMIT = 10;
const OPEN_GROUPS_COUNT_MOBILE = 1;
const OPEN_GROUPS_COUNT_DESKTOP = 2;

interface IPlayerProfileGamesFeedProps {
  isGamesGuideTooltipShown: boolean;
  splitsMonths: ISplitForOneMonth[];
  selectedSeason: ISelectOption;
  player: Maybe<IPlayerDetails>;
  fetching: boolean;
  splitSize: Maybe<number>;
  onTeamClick: (teamId: number) => void;
  onChangeGamesGuideTooltip: (key: GuideStorageKeys) => void;
}

const prepareToggleInitialState = (splitsMonths: ISplitForOneMonth[], openGroupsCount: number) => {
  if (!splitsMonths.length) {
    return {};
  }

  return splitsMonths.reduce<Record<string, boolean>>((acc, curr, currentIndex) => {
    const { id } = curr;

    acc[id] = currentIndex < openGroupsCount;

    return acc;
  }, {});
};

export const PlayerProfileGamesFeed: FC<IPlayerProfileGamesFeedProps> = (props) => {
  const {
    isGamesGuideTooltipShown,
    splitsMonths,
    splitSize,
    selectedSeason,
    fetching,
    player,
    onChangeGamesGuideTooltip,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [isTotal, setIsTotal] = useState(false);
  const [toggle, setToggle] = useState(
    prepareToggleInitialState(splitsMonths, OPEN_GROUPS_COUNT_MOBILE),
  );

  const handleGAGamesRowOpen = useCallback(
    (id: string, month: Maybe<string>) => {
      const params: IEventParams = {};

      if (player) {
        params.player = `${player.firstname} ${player.lastname}`;
      }

      if (selectedSeason) {
        params.season_timeframe = selectedSeason.label;
      }

      if (month) {
        params.month = month;
      }

      if (splitSize) {
        params.num_games_filtered = splitSize;
      }

      const isOpenToggle = !toggle[id];

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: isOpenToggle
            ? ButtonTapsEnum.PlayerProfileGamesOpenMonth
            : ButtonTapsEnum.PlayerProfileGamesCloseMonth,
          ...params,
        },
      });
    },
    [player, selectedSeason, toggle, splitSize],
  );

  const handleMonthClick = useCallback(
    (id: string, month: Maybe<string>) => {
      onChangeGamesGuideTooltip(GuideStorageKeys.PlayerProfileGames);

      setToggle((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));

      handleGAGamesRowOpen(id, month);
    },
    [onChangeGamesGuideTooltip, handleGAGamesRowOpen],
  );

  const handleToggleTotal = useCallback(() => {
    setIsTotal(!isTotal);
  }, [isTotal]);

  useEffect(() => {
    if (isDesktopPlus) {
      setToggle(prepareToggleInitialState(splitsMonths, OPEN_GROUPS_COUNT_DESKTOP));
    } else {
      setToggle(prepareToggleInitialState(splitsMonths, OPEN_GROUPS_COUNT_MOBILE));
    }
  }, [isDesktopPlus, splitsMonths]);

  return (
    <div className={styles.SplitsGamesFeed}>
      {splitsMonths.map((item, index) => (
        <MonthlySplit
          key={item.id}
          isGuideTooltipShown={index === 0 && isGamesGuideTooltipShown}
          selectedSeason={selectedSeason}
          isSplitByMonth={!splitSize}
          isCompactView={Number(splitSize) > SHOWING_COMPACT_VIEW_LIMIT}
          isTotal={isTotal}
          player={player}
          monthData={item}
          isOpen={Boolean(toggle[item.id])}
          onMonthClick={handleMonthClick}
          onHeaderRowClick={handleToggleTotal}
          onTeamClick={props.onTeamClick}
        />
      ))}
      {fetching && (
        <div className={styles.SplitsLoaderWrapper}>
          <Loader isShow={fetching} />
        </div>
      )}
    </div>
  );
};
