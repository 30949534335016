import { PlayerRankingsCategoryEnum } from 'services/player-rankings/interfaces/player-rankings-response.interface';

export const getRankByCategory = <T extends { fantasyPointsRank: number }>(
  stats: T,
  categorySlug: PlayerRankingsCategoryEnum,
): number => {
  if (categorySlug === PlayerRankingsCategoryEnum.performers) {
    return stats.fantasyPointsRank;
  }

  const key = `${categorySlug}Rank` as keyof T;

  return stats[key] as number;
};
