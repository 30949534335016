import { inject, injectable } from 'inversify';

import {
  IGetAllBookmarksBodyParams,
  IGetAllBookmarksPayload,
} from 'services/bookmarks/interfaces/bookmarks-payload.interface';
import { IBookmarkedUsersResponse } from 'services/bookmarks/interfaces/bookmarks-response.interface';
import {
  GET_ALL_BOOKMARKED_USERS,
  GET_ALL_BOOKMARKS,
} from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { BookmarksResponseType } from 'stores/bookmarks/types/bookmarks.type';

import { TYPES } from 'configs/di-types.config';

@injectable()
export class BookmarksService {
  private readonly httpService: HttpService;

  constructor(@inject<HttpService>(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public async getAllBookmarks(
    payload: IGetAllBookmarksPayload,
  ): Promise<IResponse<BookmarksResponseType[]>> {
    const { limit, page, types, search, ownerUuids, order } = payload;

    const body = {
      ...(types.length && { types }),
      ...(ownerUuids.length && { owner_uuids: ownerUuids }),
      search,
    };

    return this.httpService.request<BookmarksResponseType[], IGetAllBookmarksBodyParams>({
      method: 'POST',
      url: GET_ALL_BOOKMARKS,
      headers: {
        'X-Version': '4',
      },
      params: new URLSearchParams({
        limit: String(limit),
        page: String(page),
        order,
      }),
      body,
    });
  }

  public async getAllBookmarkedUsers(): Promise<IResponse<IBookmarkedUsersResponse>> {
    return this.httpService.request<IBookmarkedUsersResponse, null>({
      method: 'GET',
      url: GET_ALL_BOOKMARKED_USERS,
    });
  }
}
