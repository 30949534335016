import { IFollow } from 'stores/follow/interfaces/follow.interface';

export const toggleFollowItemInArray = (items: IFollow[], id: string) => {
  return items.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        isFollow: !item.isFollow,
      };
    }

    return item;
  });
};
