import React, { useEffect, useRef, useState } from 'react';

export const useScrollTo = <T extends Element>(): [
  React.RefObject<T>,
  (value: ((prevState: boolean) => boolean) | boolean) => void,
] => {
  const ref = useRef<T>(null);
  const [shouldScrollTo, setShouldScrollTo] = useState(false);

  useEffect(() => {
    if (ref.current && shouldScrollTo) {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'auto', block: 'center' });
      }

      setShouldScrollTo(false);
    }
  }, [shouldScrollTo]);

  return [ref, setShouldScrollTo];
};
