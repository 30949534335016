import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import type { Klass, LexicalNode } from 'lexical';

import { TargetNode } from './target-node';
// For now feature emoji suggestions feature is disabled
// import { EmojiNode } from './emoji.node';

const NodeList: Array<Klass<LexicalNode>> = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  CodeHighlightNode,
  AutoLinkNode,
  LinkNode,
  TargetNode,
  // For now feature emoji suggestions feature is disabled
  // EmojiNode,
  HorizontalRuleNode,
];

export default NodeList;
