import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import { IPlayerContent } from 'stores/content-cards/interfaces/content-cards.interface';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import {
  VectorIcon,
  VectorIconName,
  VectorIconSize,
} from 'components/ui/vector-icon/vector-icon.component';

import { PostsTheme } from '../post-card/enums/posts-theme.enum';

import styles from './player-content.module.less';

export interface IPlayerContentProps {
  isPosted?: boolean;
  theme?: PostsTheme;
  contentData: Omit<IPlayerContent, 'type' | 'jersey' | 'position'>;
  onClick?: (slug: string, gameId: string, playerName: string, gameDate: string) => void;
}

export const PlayerContent = memo((props: IPlayerContentProps) => {
  const { isPosted = false, theme = PostsTheme.Regular, contentData, onClick } = props;

  const {
    slug,
    gameDate,
    gameId,
    gameStatus,
    avatarUrl,
    isLeader,
    isWinningTeam,
    fullName,
    assistants,
    blocks,
    steals,
    plusMinus,
    points,
    rebounds,
    primaryColor,
    textColor,
  } = contentData;

  const refRlayerContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const wrapperElement = refRlayerContent.current;

    if (wrapperElement) {
      wrapperElement.style.setProperty('--player-content-primary-color', primaryColor);
      wrapperElement.style.setProperty('--player-content-text-color', textColor);
    }
  }, [primaryColor, textColor]);

  const playerStatistic = useMemo(
    () => [
      { label: 'PTS', value: points },
      { label: 'REB', value: rebounds },
      { label: 'AST', value: assistants },
      { label: 'BLK', value: blocks },
      { label: 'STL', value: steals },
      { label: '+/-', value: plusMinus },
    ],
    [points, rebounds, assistants, blocks, steals, plusMinus],
  );

  const playerContentClasses = useMemo(
    () =>
      cn(styles.PlayerContent, {
        [styles['PlayerContent--is-posted']]: isPosted,
        [styles['PlayerContent--detail-theme']]: theme === PostsTheme.Detail,
      }),
    [isPosted, theme],
  );

  const winLoseIconName = useMemo(() => {
    if (gameStatus !== GameStatus.Finished) {
      return null;
    }

    const isBooleanWinningTeam = typeof isWinningTeam === 'boolean';

    if (isBooleanWinningTeam && isWinningTeam) {
      return VectorIconName.Win;
    }

    if (isBooleanWinningTeam && !isWinningTeam) {
      return VectorIconName.Lose;
    }

    return null;
  }, [gameStatus, isWinningTeam]);

  const handlePlayerContentClick = useCallback(() => {
    onClick?.(slug, String(gameId), fullName, gameDate);
  }, [slug, gameId, fullName, gameDate, onClick]);

  return (
    <div
      role="presentation"
      ref={refRlayerContent}
      className={playerContentClasses}
      onClick={handlePlayerContentClick}
    >
      <div className={styles.PlayerContent__Header}>
        <div className={styles.PlayerContentAvatar}>
          <Avatar src={avatarUrl} username={fullName} size={AvatarSize.XL} />
          {winLoseIconName && (
            <div className={styles.PlayerContentAvatar__Icon}>
              <VectorIcon size={VectorIconSize.M} name={winLoseIconName} />
            </div>
          )}
        </div>
        {!isPosted && <span className={styles.PlayerContent__More}>See More</span>}
      </div>
      <div className={styles.PlayerContent__Content}>
        <div className={styles.PlayerContentName}>
          <span className={styles.PlayerContentName__Name}>{fullName}</span>
          {isLeader && <VectorIcon size={VectorIconSize.XS} name={VectorIconName.Fire} />}
          <span className={styles.PlayerContentName__Date}>{gameDate}</span>
        </div>
        <div className={styles.PlayerContent__Points}>
          {playerStatistic.map((statistic) => (
            <div key={statistic.label} className={styles.PlayerContentPoint}>
              <span className={styles.PlayerContentPoint__Value}>{statistic.value}</span>
              <span className={styles.PlayerContentPoint__Label}>{statistic.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
