import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { NEGATIVE_REACTIONS, POSITIVE_REACTIONS } from 'configs/reactions.config';

import { useSelectedReaction } from 'hooks/use-selected-reaction';

import { IReaction } from 'components/posts/post-card/interfaces/reactions.interface';
import { ReactionDirectionType } from 'components/reactions/reactions/reactions.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './reactions-list.module.less';

interface IReactionsListProps {
  reactions: {
    positive?: IReaction[];
    negative?: IReaction[];
  };
  onVoteClick: (reaction: string, type: ReactionDirectionType) => void;
  onClosePopupReactions?: () => void;
}

export const ReactionsList = memo((props: IReactionsListProps) => {
  const { reactions, onVoteClick, onClosePopupReactions } = props;

  const { positiveSelected, negativeSelected } = useSelectedReaction(reactions);

  const classReactionsListPositiveArrowNames = useMemo(
    () =>
      cn(styles.ReactionsList__Arrow, {
        [styles['ReactionsList__Arrow--selected']]: !!positiveSelected,
      }),
    [positiveSelected],
  );

  const classReactionsListNegativeArrowNames = useMemo(
    () =>
      cn(styles.ReactionsList__Arrow, {
        [styles['ReactionsList__Arrow--selected']]: !!negativeSelected,
      }),
    [negativeSelected],
  );

  const setReactionsListItemClassNames = useCallback(
    (isSelected: boolean) =>
      cn(styles.ReactionsList__Item, {
        [styles['ReactionsList__Item--selected']]: isSelected,
      }),
    [],
  );

  const handleReactionVoteClick = useCallback(
    (reaction: string, type: ReactionDirectionType) => () => {
      onVoteClick(reaction, type);
      onClosePopupReactions?.();
    },
    [onVoteClick, onClosePopupReactions],
  );

  return (
    <div className={styles.ReactionsList}>
      <div className={styles.ReactionsList__List}>
        <div className={styles.ReactionsList__Row}>
          <div className={classReactionsListPositiveArrowNames}>
            <IconButton
              iconName={positiveSelected ? IconFontName.UpvoteFilled : IconFontName.Upvote}
              theme={IconButtonTheme.Secondary}
            />
          </div>
          {POSITIVE_REACTIONS.map((reaction) => (
            <button
              key={reaction.unicode}
              className={setReactionsListItemClassNames(
                reaction.unicode === positiveSelected?.unicode,
              )}
              onClick={handleReactionVoteClick(reaction.unicode, 'up')}
            >
              <img src={reaction.htmlValue} alt={`${reaction.unicode} emoji`} />
            </button>
          ))}
        </div>
        <div className={styles.ReactionsList__Row}>
          <div className={classReactionsListNegativeArrowNames}>
            <IconButton
              iconName={negativeSelected ? IconFontName.DownvoteFilled : IconFontName.Downvote}
              theme={IconButtonTheme.Secondary}
            />
          </div>
          {NEGATIVE_REACTIONS.map((reaction) => (
            <button
              key={reaction.unicode}
              className={setReactionsListItemClassNames(
                reaction.unicode === negativeSelected?.unicode,
              )}
              onClick={handleReactionVoteClick(reaction.unicode, 'down')}
            >
              <img src={reaction.htmlValue} alt={`${reaction.unicode} emoji`} />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
});
