import { IPlayerStatsExtended } from 'stores/teams-stats/interfaces/players-stats.interface';

import { IPlayerGlobalStatsRowData } from '../interfaces/global-stats-row-data.interfaces';

export function playerGlobalStatsRowDataAdapter(
  playerGlobalStats: IPlayerStatsExtended,
): IPlayerGlobalStatsRowData {
  const {
    id,
    slug,
    firstname,
    lastname,
    smallLogoUrl,
    color,
    position,
    assists,
    blocks,
    defensiveReb,
    fieldGoalsAttempted,
    fieldGoalsMade,
    fieldGoalsPercentage,
    freeThrowsAttempted,
    freeThrowsMade,
    freeThrowsPercentage,
    gamesPlayed,
    offensiveReb,
    personalFouls,
    points,
    totalRebounds,
    steals,
    threePointsFieldGoalsAttempted,
    threePointsFieldGoalsMade,
    threePointsFieldGoalsPercentage,
    turnovers,
  } = playerGlobalStats;

  return {
    name: {
      id,
      slug,
      name: `${firstname} ${lastname}`,
      smallLogoUrl,
      color,
    },
    position,
    assists,
    blocks,
    defensiveReb,
    fieldGoalsAttempted,
    fieldGoalsMade,
    fieldGoalsPercentage,
    freeThrowsAttempted,
    freeThrowsMade,
    freeThrowsPercentage,
    gamesPlayed,
    offensiveReb,
    personalFouls,
    points,
    totalRebounds,
    steals,
    threePointsFieldGoalsAttempted,
    threePointsFieldGoalsMade,
    threePointsFieldGoalsPercentage,
    turnovers,
  };
}
