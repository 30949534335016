import { forwardRef } from 'react';

import styles from './statistics-day-item.module.less';

interface IStatisticsDayItemProps {
  date: string;
  day: string;
}

export const StatisticsDayItem = forwardRef<HTMLDivElement, IStatisticsDayItemProps>(
  (props, ref) => {
    const { date, day } = props;

    return (
      <div ref={ref} className={styles.StatisticsDayItem}>
        <span className={styles.StatisticsDayItem__Day}>{day}</span>
        <span className={styles.StatisticsDayItem__Date}>{date}</span>
      </div>
    );
  },
);
