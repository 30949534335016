import { memo, useRef } from 'react';
import { SearchContextManager } from '@giphy/react-components';

import { GIPHY_KEY } from 'configs/environment.config';

import { useOnClickOutside } from 'hooks/use-on-click-outside';

import { IModal } from 'components/modals/base-modal/base-modal.component';
import { CustomGifs } from 'components/modals/gif-picker/components/custom-gifs/custom-gifs.component';

import styles from './gif-picker.module.less';

export interface IGifPickerModalProps extends IModal {
  onClose: () => void;
  onGifSelect: (gif: string) => void;
}

export const GifPicker = memo((props: IGifPickerModalProps) => {
  const { visible, onClose } = props;

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, onClose);

  if (!visible) {
    return null;
  }

  return (
    <div ref={ref} className={styles.GifPicker}>
      <SearchContextManager apiKey={GIPHY_KEY}>
        <CustomGifs onGifSelect={props.onGifSelect} />
      </SearchContextManager>
      <div className={styles.GifPicker__Footer} />
    </div>
  );
});
