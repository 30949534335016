import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { TeamsStore } from 'stores/teams/teams.store';
import { ITeamsStatsColors } from 'stores/teams-stats/interfaces/teams-stats.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { TeamProfileRoster } from 'components/team-profile/team-profile-roster/team-profile-roster.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './team-profile-roster.module.less';

export const TeamProfileRosterContainer = observer(() => {
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);

  const { teamsStats } = teamsStatsStore;

  const navigate = useNavigate();

  const teamColors = useMemo<ITeamsStatsColors>(
    () => ({
      main: teamsStats?.color || null,
      text: teamsStats?.textColor || null,
    }),
    [teamsStats],
  );

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(
        getPath(paths.PLAYER_PROFILE, {
          [paths.PLAYER_SLUG_PARAM]: slug,
        }),
      );
    },
    [navigate],
  );

  useEffect(() => {
    if (teamsStore.teamId) {
      teamsStatsStore.fetchTeamRosterByTeamId(teamsStore.teamId);
    }
  }, [teamsStore.teamId, teamsStatsStore]);

  if (!teamsStatsStore.teamsRoster.length || teamsStatsStore.fetching) {
    return (
      <div className={styles.LoaderWrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    <TeamProfileRoster
      players={teamsStatsStore.teamsRoster}
      teamColors={teamColors}
      onPlayerClick={handlePlayerClick}
    />
  );
});
