import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import { HEADLINES_FEED } from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { TYPES } from 'configs/di-types.config';

import { IHeadlinesResponse } from './interfaces/headlines-response.interface';

@injectable()
export class HeadlinesService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchHeadlines(pagination: IPagination): Promise<IResponse<IHeadlinesResponse>> {
    const { limit, page } = pagination;

    const searchParams = {
      limit: limit.toString(),
      page: page.toString(),
    };

    return this.httpService.request({
      method: 'GET',
      url: HEADLINES_FEED,
      params: new URLSearchParams(searchParams),
    });
  }
}
