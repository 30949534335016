import { FC, memo, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';
import { IPostSource } from 'services/posts/interfaces/post-source.interface';

import { IFeeds } from 'stores/posts/interfaces/post.interface';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import {
  BOOKMARK_POST_CARD_GROUPS,
  MY_POST_MENU_GROUPS,
  POST_REPORT_MENU_GROUPS,
} from 'configs/context-menu-groups.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { BarActionType } from 'components/bars/bar-action.type';
import { IFormattedDates } from 'components/posts/post-card/interfaces/post-info.interface';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { ContextMenuTooltip } from 'components/ui/context-menu-tooltip/context-menu-tooltip.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';

import { PostsTheme } from '../enums/posts-theme.enum';
import { PostCardFeeds, PostCardFeedsTheme } from '../post-card-feeds/post-card-feeds.component';

import styles from './post-card-header.module.less';

interface IPostCardHeaderProps {
  theme: PostsTheme;
  feedsTheme: PostCardFeedsTheme;
  userSourceAvatar: Maybe<string>;
  userSourceUsername: string;
  createdDates: IFormattedDates;
  isMyPost: boolean;
  isForSidePanel?: boolean;
  isNeedCloseTooltip?: boolean;
  favoriteTeam: Maybe<ITeamsStats>;
  favoritePlayer: Maybe<IPlayerStats>;
  source?: Maybe<IPostSource>;
  feeds: IFeeds;
  setIsNeedCloseTooltip?: (value: boolean) => void;
  onContextMenuClick: (barAction: BarActionType) => void;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  onSourceClick: (event: SyntheticEvent) => void;
  onFeedClick: (type: PostsFeedType, id: string) => void;
}

export const PostCardHeader: FC<IPostCardHeaderProps> = memo((props: IPostCardHeaderProps) => {
  const {
    theme,
    feedsTheme,
    isMyPost,
    userSourceAvatar,
    userSourceUsername,
    createdDates,
    isForSidePanel,
    isNeedCloseTooltip,
    favoriteTeam,
    favoritePlayer,
    source,
    feeds,
    setIsNeedCloseTooltip,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const bookmarkDate = useMemo(() => {
    if ((theme === PostsTheme.Bookmark && isForSidePanel) || !isDesktopPlus) {
      return createdDates.relativeShort;
    }

    return createdDates.relativeLong;
  }, [createdDates.relativeLong, createdDates.relativeShort, theme, isDesktopPlus, isForSidePanel]);

  const avatarSize = useMemo(() => {
    if (theme === PostsTheme.Bookmark) {
      return AvatarSize.S;
    }

    return AvatarSize.M;
  }, [theme]);

  const contextMenuGroups = useMemo(() => {
    if (theme !== PostsTheme.Bookmark) {
      return isMyPost ? MY_POST_MENU_GROUPS : POST_REPORT_MENU_GROUPS;
    }

    return BOOKMARK_POST_CARD_GROUPS;
  }, [theme, isMyPost]);

  const feedsLength = useMemo(() => feeds.players.length + feeds.teams.length, [feeds]);

  const postCardHeaderClasses = useMemo(
    () =>
      cn(styles.PostCardHeader, {
        [styles[`PostCardHeader--detail-theme`]]: theme === PostsTheme.Detail,
        [styles[`PostCardHeader--bookmark-theme`]]: theme === PostsTheme.Bookmark,
        [styles[`PostCardHeader--player-feed-theme`]]: feeds.feed?.type === PostsFeedType.Player,
        [styles[`PostCardHeader--side-panel-theme`]]: isForSidePanel,
        [styles[`PostCardHeader--has-team-or-player`]]: favoriteTeam || favoritePlayer,
      }),
    [isForSidePanel, theme, feeds, favoriteTeam, favoritePlayer],
  );

  const postCardHeaderInfoUsernameClasses = useMemo(
    () =>
      cn(styles.PostCardHeaderInfo__Username, {
        [styles['PostCardHeaderInfo__Username--narrow']]: feedsLength > 3,
        [styles['PostCardHeaderInfo__Username--medium']]: feedsLength === 3,
        [styles['PostCardHeaderInfo__Username--wide']]: feedsLength === 2,
        [styles['PostCardHeaderInfo__Username--extra-wide']]: feedsLength === 1,
      }),
    [feedsLength],
  );

  const handleContextMenuAnchorClick = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
  }, []);

  return (
    <div className={postCardHeaderClasses}>
      {(theme === PostsTheme.Detail || isForSidePanel) && (
        <Avatar src={userSourceAvatar} size={avatarSize} username={userSourceUsername} />
      )}
      <div className={styles.PostCardHeader__Wrapper}>
        <div className={styles.PostCardHeaderInfo}>
          <div className={styles.PostCardHeaderInfo__Top}>
            <span className={postCardHeaderInfoUsernameClasses}>{userSourceUsername}</span>
            <span className={styles.PostCardHeaderInfo__Date}>{bookmarkDate}</span>
            <PostCardFeeds
              isHeaderPart
              isForSidePanel={isForSidePanel}
              theme={feedsTheme}
              items={feeds}
              source={source}
              onSourceClick={props.onSourceClick}
              onFeedClick={props.onFeedClick}
            />
          </div>
          {(favoriteTeam || favoritePlayer) && (
            <div className={styles.PostCardHeaderInfo__Bottom}>
              <TeamPlayerLabel
                team={favoriteTeam}
                player={favoritePlayer}
                onTeamClick={props.onTeamClick}
                onPlayerClick={props.onPlayerClick}
              />
            </div>
          )}
        </div>
        {contextMenuGroups && (
          <div className={styles.PostCardHeader__Tooltip}>
            <ContextMenuTooltip
              toggleOnClick
              groups={contextMenuGroups}
              tooltipProps={{ tooltipOffset: 8, placement: 'bottom-end' }}
              isNeedCloseTooltip={isNeedCloseTooltip}
              setIsNeedCloseTooltip={setIsNeedCloseTooltip}
              onItemClick={props.onContextMenuClick}
            >
              <IconButton
                iconName={IconFontName.More}
                theme={IconButtonTheme.Secondary}
                iconSize={IconFontSize.Big}
                onClick={handleContextMenuAnchorClick}
              />
            </ContextMenuTooltip>
          </div>
        )}
      </div>
    </div>
  );
});
