import {
  ISingleCategoryRecord,
  ISingleCategoryStructureItem,
  ISingleTagRecord,
  ISingleTagStructureItem,
} from 'stores/feed-filters/interfaces/feed-filters.interface';

import { HEADLINES_FEED_FILTERS_KEY } from '../feed-filters.config';

export const createFiltersForCategory = (
  newStructure: ISingleCategoryStructureItem[],
  oldFilters: ISingleCategoryRecord[],
  key: string,
): ISingleCategoryRecord[] => {
  const newFilters: ISingleCategoryRecord[] = [];

  newStructure.forEach((item) => {
    let isCategoryWasEnabled = true;
    let isCategoryDisabled = false;
    let oldTagsValues: ISingleTagRecord[] = [];

    if (oldFilters.length) {
      const oldFilterValue = oldFilters.find((oldFilter) => oldFilter.uuid === item.uuid);
      if (oldFilterValue) {
        isCategoryWasEnabled = oldFilterValue.selected;
        oldTagsValues = oldFilterValue.tags;
      }
    }

    if (key === HEADLINES_FEED_FILTERS_KEY) {
      isCategoryDisabled = !item.discussionFeed;
    }

    if (isCategoryDisabled) {
      isCategoryWasEnabled = false;
    }

    const category = {
      uuid: item.uuid,
      name: item.name,
      iconUrl: item.iconUrl,
      selected: isCategoryWasEnabled,
      disabled: isCategoryDisabled,
      tags: createCategoryTags(item.tags, oldTagsValues),
    };
    newFilters.push(category);
  });

  return newFilters;
};

export const createCategoryTags = (
  newTagsStructure: ISingleTagStructureItem[],
  oldTags: ISingleTagRecord[],
): ISingleTagRecord[] => {
  const newTags: ISingleTagRecord[] = [];

  newTagsStructure.forEach((item) => {
    let isTagWasSelected = true;

    if (oldTags.length) {
      const oldTagValue = oldTags.find((oldFilter) => oldFilter.uuid === item.uuid);
      if (oldTagValue) {
        isTagWasSelected = oldTagValue.selected;
      }
    }

    const tag = {
      uuid: item.uuid,
      name: item.name,
      iconUrl: item.iconUrl,
      selected: isTagWasSelected,
    };
    newTags.push(tag);
  });

  return newTags;
};
