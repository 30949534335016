import { FC, memo, useCallback } from 'react';

import { POLL_INPUT_MAX_LENGTH } from 'configs/controls.config';

import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import { Input } from '../../input/input.component';
import { IPollAnswer } from '../interfaces/poll.interface';

import styles from './poll-option-input.module.less';

interface IPollAnswerControlProps {
  answer: IPollAnswer;
  isRemoveButtonActive: boolean;
  onAnswerRemove: (id: string) => void;
  onAnswerTextChange: (answer: IPollAnswer) => void;
}

export const PollAnswerControl: FC<IPollAnswerControlProps> = memo(
  (props: IPollAnswerControlProps) => {
    const { answer, isRemoveButtonActive, onAnswerRemove, onAnswerTextChange } = props;
    const { id } = answer;

    const handleRemoveAnswer = useCallback(() => {
      onAnswerRemove(id);
    }, [onAnswerRemove, id]);

    const handleTextChange = useCallback(
      (value: string) => {
        onAnswerTextChange({ ...answer, title: value });
      },
      [onAnswerTextChange, answer],
    );

    return (
      <div className={styles.PollOptionInput}>
        <div className={styles.PollOptionInput__InputBox}>
          <Input
            id={`pollAnswer__${answer.id}`}
            type="text"
            name="pollAnswer"
            placeholder="Option"
            onChange={handleTextChange}
            value={answer.title}
            rightIconProps={{
              name: isRemoveButtonActive ? IconFontName.Close2 : null,
              onClick: handleRemoveAnswer,
            }}
            maxLengthCount={POLL_INPUT_MAX_LENGTH}
          />
        </div>
      </div>
    );
  },
);
