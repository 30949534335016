import { IPlayersLeadersResponse } from 'services/player/interfaces/player-leaders.interface';

import { IPlayersStatsLeaders } from '../interfaces/players-stats-leaders.interface';

import { playerLeaderAdapter } from './player-leader-adapter.util';

export function playersStatsLeadersAdapter(
  playersStatsLeadersResponse: IPlayersLeadersResponse,
): IPlayersStatsLeaders {
  const { assists, points, blocks, steals, rebounds, tpm } = playersStatsLeadersResponse;

  return {
    blocks: playerLeaderAdapter(blocks, 'blocks'),
    assists: playerLeaderAdapter(assists, 'assists'),
    points: playerLeaderAdapter(points, 'points'),
    steals: playerLeaderAdapter(steals, 'steals'),
    rebounds: playerLeaderAdapter(rebounds, 'tot_reb'),
    threePointsMade: playerLeaderAdapter(tpm, 'tpm'),
  };
}
