import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Keyboard } from '@capacitor/keyboard';
import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import { z } from 'zod';

import { ConvertImageItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';
import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';

import { useKeyboardTrigger } from 'hooks/use-keyboard-trigger';
import { useMainProvider } from 'hooks/use-main-provider';

import { editorSchemaComment } from 'validation/schemas/editor.schema';

import {
  EditorDataType,
  EditorTheme,
} from 'components/editor/components/base-editor/base-editor.component';
import { Editor } from 'components/editor/editor.component';
import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { Loader } from 'components/ui/loader/loader.component';

import { BaseIonBottomSheet } from '../base-ion-bottom-sheet/base-ion-bottom-sheet.component';

import styles from './editor-bottom-sheet.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

const commentCreateSchema = z
  .object({
    editor: editorSchemaComment,
  })
  .strict();

export type CommentCreateDataType = z.infer<typeof commentCreateSchema>;

export interface ICommentEditorBottomSheetProps extends IForm<CommentCreateDataType> {
  visible: boolean;
  isVideoLoading: boolean;
  postVideo: Maybe<IVideoResponse>;
  withLoader?: boolean;
  onUploadVideo: (video: File) => void;
  onRemoveVideo: () => void;
  onVideoError?: (error: string) => void;
  convertImageItemToAttachment: ConvertImageItemToAttachmentType;
  onCloseBottomSheet: () => void;
}

export const CommentEditorBottomSheet = memo((props: ICommentEditorBottomSheetProps) => {
  const {
    visible,
    processing,
    initialValues,
    postVideo,
    isVideoLoading,
    withLoader = true,
    onSubmit,
    onCloseBottomSheet,
  } = props;

  const { isNativeApp, isNativeAndroidApp } = useMainProvider();
  const { isKeyboardOpen } = useKeyboardTrigger(isNativeApp);

  const [loadingImages, setLoadingImages] = useState<string[]>([]);

  const { control, formState, handleSubmit, reset } = useForm<CommentCreateDataType>({
    defaultValues: initialValues,
    mode: 'onSubmit',
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(commentCreateSchema),
  });

  const { errors, isValid } = formState;

  const editorBottomSheetClasses = useMemo<string>(
    () =>
      cn(styles.EditorBottomSheet, {
        [styles['EditorBottomSheet--processing']]: processing,
        [styles['EditorBottomSheet--keyboard']]: isKeyboardOpen,
      }),
    [processing, isKeyboardOpen],
  );

  const handleEditorChange = useCallback(
    // Any type picked from Hook Form types
    (onChange: (...event: any[]) => void) => (value: EditorDataType) => onChange(value),
    [],
  );

  const handleBottomSheetClose = useCallback(() => {
    onCloseBottomSheet();

    // setTimeout is used to clear the editor's content after the bottom sheet animation
    setTimeout(() => {
      reset();
    });
  }, [reset, onCloseBottomSheet]);

  useEffect(() => {
    if (isNativeApp && !isNativeAndroidApp) {
      Keyboard.setAccessoryBarVisible({ isVisible: !visible });
    }
  }, [visible, isNativeApp, isNativeAndroidApp]);

  return (
    <BaseIonBottomSheet
      visible={visible}
      isAutoHeight
      safeAreaBottom={0}
      initialBreakpoint={INITIAL_BREAKPOINT}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      onClose={handleBottomSheetClose}
    >
      <Form className={editorBottomSheetClasses} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="editor"
          control={control}
          render={({ field }) => (
            <Editor
              placeholder="Add comment"
              id="editor"
              name="editor"
              theme={EditorTheme.BottomSheet}
              isExpand
              isActive={visible}
              disabled={!isValid || processing}
              value={field.value}
              error={errors.editor?.content?.length?.message}
              postVideo={postVideo}
              loadingImages={loadingImages}
              isVideoLoading={isVideoLoading}
              convertImageItemToAttachment={props.convertImageItemToAttachment}
              setLoadingImages={setLoadingImages}
              onUploadVideo={props.onUploadVideo}
              onRemoveVideo={props.onRemoveVideo}
              onVideoError={props.onVideoError}
              onChange={handleEditorChange(field.onChange)}
            />
          )}
        />
      </Form>
      {withLoader && <Loader isShow={!!processing} />}
    </BaseIonBottomSheet>
  );
});
