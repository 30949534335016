import { memo, useCallback, useState } from 'react';

import { ReactionsIonBottomSheet } from 'components/bottom-sheet/reactions-ion-bottom-sheet/reactions-ion-bottom-sheet.component';
import { IReaction } from 'components/posts/post-card/interfaces/reactions.interface';
import {
  ReactionDirectionType,
  ReactionTheme,
} from 'components/reactions/reactions/reactions.component';
import { ReactionsPicker } from 'components/reactions/reactions-picker/reactions-picker.component';

interface IReactionsWithBottomSheetProps {
  theme: ReactionTheme;
  reactionsSum: number;
  reactions: {
    positive?: IReaction[];
    negative?: IReaction[];
  };
  triggerAuthorisationCheck?: () => boolean;
  onPickerClick?: () => void;
  onVoteClick: (reaction: string, type: ReactionDirectionType) => void;
}

export const ReactionsWithBottomSheet = memo((props: IReactionsWithBottomSheetProps) => {
  const { reactions, reactionsSum, theme, triggerAuthorisationCheck, onPickerClick } = props;

  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);

  const handleBottomSheetClose = useCallback(() => {
    setIsBottomSheetVisible(false);
  }, []);

  const handleBottomSheetOpen = useCallback(() => {
    onPickerClick?.();

    if (triggerAuthorisationCheck?.()) {
      setIsBottomSheetVisible(true);
    }
  }, [triggerAuthorisationCheck, onPickerClick]);

  return (
    <>
      <ReactionsPicker
        theme={theme}
        reactions={reactions}
        reactionsSum={reactionsSum}
        onOpenPopupReactions={handleBottomSheetOpen}
      />
      <ReactionsIonBottomSheet
        visible={isBottomSheetVisible}
        onVoteClick={props.onVoteClick}
        onClose={handleBottomSheetClose}
        reactions={reactions}
      />
    </>
  );
});
