export const convertPeriodToString = (value: number): string => {
  if (!value) {
    return 'All';
  }

  if (value === 5) {
    return `OT`;
  }

  if (value > 5) {
    return `${value - 4}OT`;
  }

  return `Q${value}`;
};
