import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { IUpdateUserParams } from 'stores/auth/interfaces/update-user.params.interface';
import { BookmarksStore } from 'stores/bookmarks/bookmarks.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { PROFILE_TABS } from 'containers/profile/profile.config';

import { TYPES } from 'configs/di-types.config';
import * as paths from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { EditProfileFormFieldsType } from 'components/forms/edit-profile/edit-profile-form.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { EditProfileModal } from 'components/modals/edit-profile-modal/edit-profile-modal.component';
import { GalleryTheme } from 'components/modals/gallery-modal/gallery-modal.component';
import { ProfileInfo } from 'components/profile/profile-info/profile-info.component';
import { ProfileMainSection } from 'components/profile/profile-main-section/profile-main-section';
import { Tabs } from 'components/ui/tabs/tabs.component';

import styles from './profile-info.module.less';

export const ProfileInfoContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const bookmarksStore = useInjection<BookmarksStore>(TYPES.BookmarksStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);

  const navigate = useNavigate();

  const handleTabActionClick = useCallback(async (barAction: BarActionType<string>) => {
    if (barAction.type === BarAction.Click && barAction.payload) {
      setActiveTab(barAction.payload);
    }
  }, []);

  const [activeTab, setActiveTab] = useState('bookmarks');
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [focusField, setFocusField] = useState<Maybe<EditProfileFormFieldsType>>(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const userData = authStore.userMe;
  const { teamsThumbnails, players, playersSelectOptions } = teamsStatsStore;

  const handleUpdateUser = useCallback(
    async (updateUserParams: IUpdateUserParams, closeEditModal = true) => {
      if (authStore.fetching) {
        return;
      }

      if (closeEditModal) {
        setEditModalVisible(false);
      }

      if (
        updateUserParams.favoriteTeamId &&
        userData?.profile.thumbnailUrl &&
        !teamsThumbnails.includes(userData.profile.thumbnailUrl)
      ) {
        authStore.setShouldApplyFavoriteTeamBackground(false);

        if (updateUserParams.favoriteTeamId === userData?.profile?.favoriteTeamId) {
          setConfirmationModalOpen(false);
        } else {
          setConfirmationModalOpen(true);
        }
      }

      await authStore.updateUserMe(updateUserParams);
      await bookmarksStore.refresh();
    },
    [authStore, bookmarksStore, teamsThumbnails, userData?.profile],
  );

  const favTeamId = authStore.userMe?.profile.favoriteTeamId || null;

  const favPlayer =
    players.find((player) => authStore.userMe?.profile.favoritePlayerId === player.id) || null;

  const initialUserName = userData?.username ? userData.username : '';

  const initialRealName = userData?.profile.realName ? userData?.profile.realName : '';

  const handleShowModal = useCallback((field?: EditProfileFormFieldsType) => {
    setEditModalVisible(true);
    if (field) {
      setFocusField(field);
    } else {
      setFocusField(null);
    }
  }, []);

  const handleClose = useCallback(() => {
    setEditModalVisible(false);
  }, []);

  const handleCropAvatarModalOpen = useCallback(
    (isOpen: boolean) => {
      layoutStore.setIsCropBackgroundModalOpen(false);
      layoutStore.setIsCropAvatarModalOpen(isOpen);
    },
    [layoutStore],
  );

  const handleCropBackgroundModalOpen = useCallback(
    (isOpen: boolean) => {
      layoutStore.setIsCropAvatarModalOpen(false);
      layoutStore.setIsCropBackgroundModalOpen(isOpen);
    },
    [layoutStore],
  );

  const handleContextItemClick = useCallback(
    async (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click && barAction.payload === 'logout') {
        GoogleAnalyticService.event({
          eventName: 'button_custom_tap',
          eventParams: {
            button_tap_type: ButtonTapsEnum.AccountProfileSettingsLogout,
          },
        });
        authStore.logout();
      }
      if (barAction.type === BarAction.Link && barAction.target) {
        window.open(barAction.path, barAction.target);
      } else if (barAction.type === BarAction.Link) {
        navigate(barAction.path);
      }
    },
    [authStore, navigate],
  );

  const handleCloseConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);

  const handleConfirmationSuccess = useCallback(() => {
    authStore.setShouldApplyFavoriteTeamBackground(true);
    setConfirmationModalOpen(false);
    authStore.updateUserMe({ favoriteTeamId: favTeamId });
  }, [authStore, favTeamId]);

  const handleRedirectToSettings = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.AccountProfileSettings,
      },
    });

    navigate(paths.SETTINGS);
  }, [navigate]);

  const handleOpenPreview = useCallback(
    (image: IInteractiveMediaAttachment) => {
      const watermarkEntity = userData?.profile.smallAvatarUrl
        ? {
            name: userData.username,
            logoUrl: userData.profile.smallAvatarUrl,
          }
        : null;

      galleryStore.setGalleryAttachments([image]);
      galleryStore.setTheme(GalleryTheme.Preview);
      galleryStore.setWatermarkEntity(watermarkEntity);
      galleryStore.setIsGalleryOpen(true);
    },
    [galleryStore, userData],
  );

  useEffect(() => {
    GoogleAnalyticService.event({
      eventName: 'screen_custom_view',
      eventParams: {
        screen_type: ScreenNamesEnum.UserProfile,
      },
    });
  }, []);

  useEffect(() => {
    authStore.retrieveUserMe();
  }, [authStore]);

  return (
    <div className={styles.ProfileWrapper}>
      {!authStore.fetching && (
        <ProfileInfo
          isCropModalVisible={layoutStore.isCropAvatarModalOpen}
          onSetCropModal={handleCropAvatarModalOpen}
          userData={userData}
          onUpdateUser={handleUpdateUser}
          teamsOptions={teamsStatsStore.getTeamsSelectOptions('Team')}
          playersOptions={playersSelectOptions}
          favoriteTeamId={favTeamId}
          favoritePlayer={favPlayer}
          onShowEditModal={handleShowModal}
          onContextItemClick={handleContextItemClick}
          setDisabledScroll={layoutStore.setDisabledScroll}
          onRedirectToSettings={handleRedirectToSettings}
          onOpenPreview={handleOpenPreview}
        />
      )}
      <div className={styles.ProfileWrapper__Tabs}>
        <Tabs forProfile activeTab={activeTab} onClick={handleTabActionClick} tabs={PROFILE_TABS} />
      </div>
      <ProfileMainSection activeTab={activeTab} />
      <EditProfileModal
        loading={authStore.fetching}
        userData={userData}
        isCropAvatarModalVisible={layoutStore.isCropAvatarModalOpen}
        isCropBackgroundModalVisible={layoutStore.isCropBackgroundModalOpen}
        onSetAvatarCropModal={handleCropAvatarModalOpen}
        onSetBackgroundCropModal={handleCropBackgroundModalOpen}
        onClose={handleClose}
        visible={editModalVisible}
        initialValues={{ username: initialUserName, realname: initialRealName }}
        onSubmit={handleUpdateUser}
        focusField={focusField}
      />
      <ConfirmationModal
        onClose={handleCloseConfirmationModal}
        visible={confirmationModalOpen}
        onSuccessCallback={handleConfirmationSuccess}
        title="Update Background"
        content="Would you like to change your custom background to the team's related one?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
      />
    </div>
  );
});
