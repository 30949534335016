import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { debounce } from 'helpers/debounce.util';

import { Instagram } from 'components/social-card/instagram.component';
import { StreamableVideo } from 'components/social-card/streamable-video/streamable-video.component';
import { Tiktok } from 'components/social-card/tiktok.component';
import { Twitter } from 'components/social-card/twitter.component';
import { Youtube } from 'components/social-card/youtube.component';

import styles from './embedded-attachment.module.less';

interface IEmbeddedAttachmentProps {
  sourceUrl: string;
  embeddedType: Maybe<string>;
}

const DEBOUNCE_DELAY = 100;
const MAX_TIKTOK_WIDTH = 320;

export const EmbeddedAttachment: FC<IEmbeddedAttachmentProps> = (props) => {
  const { sourceUrl, embeddedType } = props;

  const elementRef = useRef<HTMLDivElement>(null);

  const [embeddedWidth, setEmbeddedWidth] = useState(0);

  const tiktokWidth = useMemo(() => {
    if (embeddedWidth >= MAX_TIKTOK_WIDTH) {
      return MAX_TIKTOK_WIDTH;
    }

    return embeddedWidth;
  }, [embeddedWidth]);

  useEffect(() => {
    if (elementRef.current) {
      setEmbeddedWidth(elementRef.current.clientWidth);
    }

    const handleResize = debounce(() => {
      if (elementRef.current) {
        setEmbeddedWidth(elementRef.current.clientWidth);
      }
    }, DEBOUNCE_DELAY);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div ref={elementRef} className={styles.Attachment}>
      {embeddedType === 'twitter' && <Twitter width={embeddedWidth} sourceUrl={sourceUrl} />}
      {embeddedType === 'instagram' && <Instagram width={embeddedWidth} sourceUrl={sourceUrl} />}
      {embeddedType === 'tiktok' && <Tiktok width={tiktokWidth} sourceUrl={sourceUrl} />}
      {embeddedType === 'youtube' && <Youtube width={embeddedWidth} sourceUrl={sourceUrl} />}
      {embeddedType === 'streamable' && <StreamableVideo sourceUrl={sourceUrl} />}
    </div>
  );
};
