import { FC, memo, PropsWithChildren, useMemo } from 'react';
import cn from 'classnames';

import styles from './icon-font.module.less';

export enum IconFontName {
  Activity = 'activity',
  AddImage = 'add-image',
  AddLink = 'add-link',
  AddPoll = 'add-poll',
  AddReaction = 'add-reaction',
  AddUser = 'add-user',
  Add = 'add',
  Add2 = 'add2',
  Apple = 'apple',
  Arena = 'arena',
  ArenaFilled = 'arena-filled',
  Applications = 'applications',
  Archive = 'archive',
  ArrowDown = 'arrow-down',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  ArrowUp = 'arrow-up',
  Article = 'article',
  Attach = 'attach',
  Auction = 'auction',
  BasketballField = 'basketball-field',
  Block = 'block',
  Blog = 'blog',
  Bold = 'bold',
  Ball = 'ball',
  BookmarkFilled = 'bookmark-filled',
  Bookmark = 'bookmark',
  Broadcast = 'broadcast',
  Calendar = 'calendar',
  CallStart = 'call-start',
  CallStop = 'call-stop',
  Camera = 'camera',
  Chart = 'chart',
  ChatFilled = 'chat-filled',
  Chat = 'chat',
  ChevronDown = 'chevron-down',
  ChevronBigDown = 'chevron-big-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronSmallRight = 'chevron-small-right',
  ChevronUp = 'chevron-up',
  ChevronBigUp = 'chevron-big-up',
  Chip = 'chip',
  Clear = 'clear',
  CloseFilled = 'close-filled',
  Close = 'close',
  Close2 = 'close2',
  Code = 'code',
  Comment = 'comment',
  Confirm = 'confirm',
  Copy = 'copy',
  Dao = 'dao',
  Discord = 'discord',
  Document = 'document',
  Download = 'download',
  DownvoteFilled = 'downvote-filled',
  Downvote = 'downvote',
  DigitsMenu = 'digits-menu',
  Drag = 'drag',
  Edit = 'edit',
  Error = 'error',
  ExploreFilled = 'explore-filled',
  Explore = 'explore',
  ExternalLink = 'external-link',
  EyeFilled = 'eye-filled',
  EyeOff = 'eye-off',
  Eye = 'eye',
  Facebook = 'facebook',
  Feedback = 'feedback',
  Feedback2 = 'feedback2',
  Feedback3 = 'feedback3',
  Filter = 'filter',
  Formatting = 'formatting',
  Framescreen = 'framescreen',
  Fullscreen = 'fullscreen',
  Gif = 'gif',
  GoToMessage = 'go-to-message',
  Google = 'google',
  GroupChat = 'group-chat',
  Hand = 'hand',
  Hash = 'hash',
  Headphones = 'headphones',
  HeatMap = 'heat-map',
  Help = 'help',
  HomeFilled = 'home-filled',
  Home = 'home',
  Image = 'image',
  Info = 'info',
  Insta = 'insta',
  Italic = 'italic',
  JoinDaoUser = 'join-dao-user',
  LikeFiled = 'like-filled',
  Like = 'like',
  Link = 'link',
  Link2 = 'link2',
  ListAll = 'list-all',
  ListNumbered = 'list-numbered',
  List = 'list',
  Location = 'location',
  Lock = 'lock',
  Logout = 'logout',
  Mail = 'mail',
  ManageUsers = 'manage-users',
  MarkerCollapsed = 'marker-collapsed',
  MarkerExpanded = 'marker-expanded',
  MarkerUp = 'marker-up',
  MediaFiles = 'media-files',
  Mention = 'mention',
  Menu = 'menu',
  MessageRequest = 'message-request',
  MicOff = 'mic-off',
  Mic = 'mic',
  MiddleSection = 'middle-section',
  Minus = 'minus',
  MissedCall = 'missed-call',
  More = 'more',
  More2 = 'more2',
  MoveLeft = 'move-left',
  MoveRight = 'move-right',
  Music = 'music',
  NewProject = 'new-project',
  News = 'news',
  NewTask = 'new-task',
  NftSuccess = 'nft-success',
  Nft = 'nft',
  NotificationsSettings = 'notifications-settings',
  NotificationsFilled = 'notifications-filled',
  NotificationsOff = 'notifications-off',
  Notifications = 'notifications',
  Pause = 'pause',
  Pin = 'pin',
  Play = 'play',
  PlaySmall = 'play-small',
  Poll = 'poll',
  Post = 'post',
  ProfileFilled = 'profile-filled',
  ProfileBlocked = 'profile-blocked',
  Profile = 'profile',
  ProjectClosed = 'project-closed',
  Promo = 'promo',
  Proposal = 'proposal',
  Quote = 'quote',
  Rating = 'rating',
  RatingFilled = 'rating-filled',
  Reaction = 'reaction',
  Reddit = 'reddit',
  Remove = 'remove',
  Repeat = 'repeat',
  Report = 'report',
  ReportUser = 'report-user',
  Request = 'request',
  RightPanel = 'right-panel',
  Role = 'role',
  Rotate = 'rotate',
  Screen = 'screen',
  Search = 'search',
  Search2 = 'search2',
  SendFilled = 'send-filled',
  Send = 'send',
  Settings = 'settings',
  ShareScreen = 'share-screen',
  Share = 'share',
  Shop = 'shop',
  Smile = 'smile',
  SocialFeed = 'social-feed',
  Sorting = 'sorting',
  SoundOff = 'sound-off',
  Sound = 'sound',
  Speech = 'speech',
  Spoiler = 'spoiler',
  StarFilled = 'star-filled',
  Star = 'star',
  Sticker = 'sticker',
  Stop = 'stop',
  Strikethrough = 'strikethrough',
  Telegram = 'telegram',
  Thread = 'thread',
  TikTok = 'tik-tok',
  Time = 'time',
  Timer = 'timer',
  Treasure = 'treasure',
  Trending = 'trending',
  Twitter = 'twitter',
  Unpin = 'unpin',
  UpvoteFilled = 'upvote-filled',
  Upvote = 'upvote',
  UserAccepted = 'user-accepted',
  Users = 'users',
  VideoCameraOff = 'video-camera-off',
  VideoCamera = 'video-camera',
  ViewCards = 'view-cards',
  Wallet = 'wallet',
  Whatsup = 'whatsup',
  Window = 'window',
  WriteMessage = 'write-message',
  Youtube = 'youtube',
  EventSteal = 'event-steal',
  EventBlock = 'event-block',
  EventMiss = 'event-miss',
  EventTurnover = 'event-turnover',
  EventNewPlayer = 'event-new-player',
  EventAssists = 'event-assist',
  EventGameStart = 'event-game-start',
  EventGameEnd = 'event-game-end',
  EventQuarterStart = 'event-quarter-start',
  EventQuarterEnd = 'event-quarter-end',
  EventFoul = 'event-foul',
  EventTimeout = 'event-timeout',
  EventInjury = 'event-injury',
  EventGainPosession = 'event-gain-posession',
  EventRebound = 'event-rebound',
}

export enum IconFontSize {
  ExtraSmall,
  Small,
  Big,
  ExtraBig,
}

export interface IconFontProps {
  name: IconFontName;
  size: IconFontSize;
  dataId?: string;
}

export const IconFont: FC<IconFontProps> = memo((props: PropsWithChildren<IconFontProps>) => {
  const { name, size, dataId } = props;

  const classNames = useMemo(
    () =>
      cn(styles.IconFont, `icon-${name}`, {
        [styles['IconFont--size-big']]: size === IconFontSize.Big,
        [styles['IconFont--size-small']]: size === IconFontSize.Small,
        [styles['IconFont--size-extra-small']]: size === IconFontSize.ExtraSmall,
        [styles['IconFont--size-extraBig']]: size === IconFontSize.ExtraBig,
      }),
    [name, size],
  );

  return <i data-id={dataId} className={classNames} />;
});
