import { IIframeAttachmentResponse } from 'services/posts/interfaces/post-attachments-response.interface';

import { IIframeAttachment } from '../interfaces/post.interface';

export function iframeAdapter(iframeResponse: IIframeAttachmentResponse): IIframeAttachment {
  return {
    dateCreated: iframeResponse.date_created,
    type: 'iframe',
    url: iframeResponse.url,
    filename: '',
    mimeType: '',
  };
}
