import { inject } from 'inversify';
import { action, autorun, computed, makeObservable, observable } from 'mobx';

import { ISeasonResponse } from 'services/seasons/interfaces/season-response.interface';
import { SeasonsService } from 'services/seasons/seasons.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { seasonAdapter } from 'stores/seasons/adapters/season-adapter.util';
import { seasonOptionAdapter } from 'stores/seasons/adapters/season-option-adapter.util';

import { TYPES } from 'configs/di-types.config';

import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';

import { ISeason } from './interfaces/season.interface';

export class SeasonsStore extends ApiConnectedStore {
  private readonly seasonsService: SeasonsService;

  public seasons: ISeason[];

  constructor(
    @inject<SeasonsService>(TYPES.SeasonsService)
    seasonsService: SeasonsService,
  ) {
    super();

    this.seasonsService = seasonsService;

    this.seasons = [];

    makeObservable(this, {
      seasons: observable,

      currentSeasonOption: computed,
      seasonsSelectOptions: computed,

      setSeasons: action.bound,
    });

    autorun(() => this.retrieve());
  }

  public setSeasons(seasons: ISeason[]) {
    this.seasons = seasons;
  }

  public get seasonsSelectOptions(): ISelectOption[] {
    return this.seasons.map(seasonOptionAdapter);
  }

  public get currentSeasonOption(): Maybe<ISelectOption> {
    const defaultSeason = this.seasons.find((season) => season.isDefault);

    if (defaultSeason) {
      return seasonOptionAdapter(defaultSeason);
    }

    return null;
  }

  public async retrieve() {
    this.resetErrors();
    this.setFetched(false);

    const seasonsResponse: IResponse<ISeasonResponse[]> = await this.seasonsService.fetchSeasons();

    if (seasonsResponse.success) {
      this.setSeasons(seasonsResponse.data.map(seasonAdapter));
    } else {
      this.setErrors(seasonsResponse.errors);
    }

    this.setFetched(true);
  }
}
