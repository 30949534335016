import { useMemo } from 'react';
import cn from 'classnames';

import { ITopPerformers } from 'stores/teams-stats/interfaces/teams-stats-all.interface';

import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { PerformerCard } from 'components/team-stats/team-stats-top-performers/components/performer-card/performer-card.component';

import styles from './team-stats-top-performers.module.less';

export interface ITopPerformersProps {
  topPerformers: Maybe<ITopPerformers>;
  onPlayerClick: (slug: string) => void;
}

export const TeamStatsTopPerformers = (props: ITopPerformersProps) => {
  const { topPerformers } = props;

  const classNames = useMemo(() => cn(styles.TeamStatsTopPerformers, TOUCH_IGNORE_CLASS), []);

  if (!topPerformers) {
    return null;
  }

  const { points, totalRebounds, assists, steals } = topPerformers;

  return (
    <div className={classNames}>
      {points && (
        <PerformerCard
          name={`${points.firstname[0]}. ${points.lastname}`}
          label="Points"
          points={points.points}
          url={points.smallLogoUrl}
          position={points.position}
          slug={points.slug}
          onPlayerClick={props.onPlayerClick}
        />
      )}
      {totalRebounds && (
        <PerformerCard
          name={`${totalRebounds.firstname[0]}. ${totalRebounds.lastname}`}
          label="Rebounds"
          points={totalRebounds.totalRebounds}
          url={totalRebounds.smallLogoUrl}
          position={totalRebounds.position}
          slug={totalRebounds.slug}
          onPlayerClick={props.onPlayerClick}
        />
      )}
      {assists && (
        <PerformerCard
          name={`${assists.firstname[0]}. ${assists.lastname}`}
          label="Assists"
          points={assists.assists}
          url={assists.smallLogoUrl}
          position={assists.position}
          slug={assists.slug}
          onPlayerClick={props.onPlayerClick}
        />
      )}
      {steals && (
        <PerformerCard
          name={`${steals.firstname[0]}. ${steals.lastname}`}
          label="Steals"
          points={steals.steals}
          url={steals.smallLogoUrl}
          position={steals.position}
          isLast
          slug={steals.slug}
          onPlayerClick={props.onPlayerClick}
        />
      )}
    </div>
  );
};
