import { FC } from 'react';

import { MediaPermission } from 'components/media-permission/media-permission.component';
import {
  BaseModalComponent,
  IModal,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';

import styles from './media-permission-modal.module.less';

interface IMediaPermissionModalProps extends IModal {
  visible: boolean;
  title: string;
  message: string;
  primaryButtonText: string;
  imageUrl: string;
  onClose: () => void;
  onApprove: () => void;
}

export const MediaPermissionModal: FC<IMediaPermissionModalProps> = (props) => {
  const { visible, title, message, imageUrl, primaryButtonText } = props;

  return (
    <BaseModalComponent
      visible={visible}
      size={ModalWindowSize.M}
      title=""
      withHeader={false}
      isScrollDisabled
      onClose={props.onClose}
    >
      <div className={styles.ModalContent}>
        <MediaPermission
          title={title}
          primaryButtonText={primaryButtonText}
          message={message}
          imageUrl={imageUrl}
          onApprove={props.onApprove}
          onClose={props.onClose}
        />
      </div>
    </BaseModalComponent>
  );
};
