export const findAndReplaceGameChatItem = <T extends { uuid: string }>(
  array: T[],
  searchValue: string,
  replacement: T,
): T[] => {
  const newArray = [...array];

  const index = newArray.findIndex((item) => item.uuid === searchValue);

  if (index !== -1) {
    newArray[index] = { ...newArray[index], ...replacement };
  }

  return newArray;
};
