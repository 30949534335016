import { memo, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { formatShortName } from 'helpers/format/format-short-name.util';

import { useResponsive } from 'hooks/use-responsive';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { VectorIconName, VectorIconSize } from 'components/ui/vector-icon/vector-icon.component';

import styles from './scoreboard-player.module.less';

interface IScoreboardPlayerProps {
  slug: string;
  isLeader: boolean;
  avatarSrc?: Maybe<string>;
  firstName: string;
  lastName: string;
  position: Maybe<string>;
  pts: Maybe<number>;
  reb: Maybe<number>;
  stl: Maybe<number>;
  ast: Maybe<number>;
  blk: Maybe<number>;
  onPlayerClick: (slug: string) => void;
}

export const ScoreboardPlayer = memo((props: IScoreboardPlayerProps) => {
  const {
    slug,
    isLeader,
    avatarSrc,
    firstName,
    lastName,
    position,
    pts,
    reb,
    stl,
    ast,
    blk,
    onPlayerClick,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const shortName = useMemo<string>(
    () => formatShortName(firstName, lastName),
    [firstName, lastName],
  );

  const infoClassNames = useMemo(() => cn(styles.ScoreboardPlayer__Info, styles.PlayerInfo), []);

  const entryNameClassNames = useMemo(
    () => cn(styles.PlayerEntry, styles['PlayerEntry--name']),
    [],
  );

  const thirdMetric = useMemo(() => {
    let hightestMetricObj = {
      value: 0,
      abbr: '',
    };

    if (ast && ast > hightestMetricObj.value) {
      hightestMetricObj = {
        value: ast,
        abbr: 'AST',
      };
    }

    if (stl && stl > hightestMetricObj.value) {
      hightestMetricObj = {
        value: stl,
        abbr: 'STL',
      };
    }

    if (blk && blk > hightestMetricObj.value) {
      hightestMetricObj = {
        value: blk,
        abbr: 'BLK',
      };
    }

    return hightestMetricObj;
  }, [ast, blk, stl]);

  const handlePlayerClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      onPlayerClick(slug);
    },
    [onPlayerClick, slug],
  );

  const avatarSize = useMemo(() => (isDesktopPlus ? AvatarSize.S : AvatarSize.XS), [isDesktopPlus]);

  const avatarIconSize = useMemo(
    () => (isDesktopPlus ? VectorIconSize.M : VectorIconSize.XS),
    [isDesktopPlus],
  );

  return (
    <button className={styles.ScoreboardPlayer} onClick={handlePlayerClick}>
      <div className={styles.ScoreboardPlayer__Avatar}>
        <Avatar
          vectorIconName={isLeader ? VectorIconName.Fire : null}
          vectorIconSize={avatarIconSize}
          size={avatarSize}
          src={avatarSrc}
          username={lastName}
        />
      </div>

      {isDesktopPlus && (
        <div className={infoClassNames}>
          <div className={styles.PlayerInfo__Row}>
            <span className={entryNameClassNames}>
              <span className={styles.PlayerEntry__Value}>{shortName}</span>
              <span className={styles.PlayerEntry__Abbr}>- {position}</span>
            </span>
          </div>

          <div className={styles.PlayerInfo__Row}>
            {!!pts && (
              <span className={styles.PlayerEntry}>
                <span className={styles.PlayerEntry__Value}>{pts}</span>
                <span className={styles.PlayerEntry__Abbr}>PTS</span>
              </span>
            )}

            {!!reb && (
              <span className={styles.PlayerEntry}>
                <span className={styles.PlayerEntry__Value}>{reb}</span>
                <span className={styles.PlayerEntry__Abbr}>REB</span>
              </span>
            )}

            {!!thirdMetric.value && (
              <span className={styles.PlayerEntry}>
                <span className={styles.PlayerEntry__Value}>{thirdMetric.value}</span>
                <span className={styles.PlayerEntry__Abbr}>{thirdMetric.abbr}</span>
              </span>
            )}
          </div>
        </div>
      )}
    </button>
  );
});
