import { useEffect, useRef, useState } from 'react';

// MARGIN_VALUE | BLOCKQUOTE_PADDING_VALUE | LINE_HEIGHT values related to internal and
// external paddings/margins and line height are hardcoded.
// Changing the styles in the editor will lead to incorrect functionality of this feature.

const MARGIN_VALUE = 8;
const BLOCKQUOTE_PADDING_VALUE = 16;
const LINE_HEIGHT = 22;

export const useRichTextCollapse = (richTextLines: number, isEnabled: boolean) => {
  const ref = useRef<HTMLDivElement>(null);

  const [maxHeight, setMaxHeight] = useState(0);
  const [enabledCollapseButton, setEnabledCollapseButton] = useState(false);

  useEffect(() => {
    if (ref.current && isEnabled) {
      const parentElement = ref.current.children[0];
      const childElements = parentElement.children;

      let mapLines = 0;
      let marginsCount = 0;
      let paddingsCount = 0;

      for (let i = 0; i < childElements.length; i += 1) {
        const itemElement = childElements.item(i);

        if (itemElement) {
          const lines = itemElement.clientHeight / LINE_HEIGHT;

          mapLines += lines;

          if (richTextLines > mapLines) {
            marginsCount += 1;

            if (itemElement.tagName === 'BLOCKQUOTE') {
              paddingsCount += 1;
            }
          } else {
            break;
          }
        }
      }

      const result =
        marginsCount * MARGIN_VALUE +
        richTextLines * LINE_HEIGHT +
        paddingsCount * BLOCKQUOTE_PADDING_VALUE;

      setMaxHeight(result);
    }
  }, [ref, richTextLines, isEnabled]);

  useEffect(() => {
    if (ref.current && isEnabled) {
      const entireHeight = ref.current.children[0].clientHeight;

      setEnabledCollapseButton(maxHeight < entireHeight);
    }
  }, [maxHeight, isEnabled]);

  return { ref, maxHeight, enabledCollapseButton };
};
