import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import {
  ICommentResponse,
  ICommentsResponse,
  ICommentsSearchParams,
  IDeleteCommentPayload,
  IGetCommentPayload,
  IPostCommentBookmarkPayload,
  IPostCommentVoteDataPayload,
  IPostCommentVoteResponse,
  ISharePlayerPostCommentPayload,
  ISharePostCommentPayload,
  IShareTeamPostCommentPayload,
} from 'services/comments/interfaces/comments.interface';
import {
  COMMENT_ID_API_PARAM,
  PLAYER_ID_API_PARAM,
  POST_ID_API_PARAM,
} from 'services/http/consts/api-endpoint-params.constants';
import { HttpService } from 'services/http/http.service';
import { ITogglePostBookmarkPayload } from 'services/posts/interfaces/create-post-payload.interface';
import { IPollAttachmentResponse } from 'services/posts/interfaces/post-attachments-response.interface';
import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';
import { IPublicationPayload } from 'services/posts/interfaces/publication-payload.interface';

import { VideoUploadPayloadType } from 'stores/posts/interfaces/post.interface';
import { ShareResponse } from 'stores/share/types/share-response.type';

import { TYPES } from 'configs/di-types.config';
import {
  POLL_ID_PARAM,
  POLL_OPTION_ID_PARAM,
  POST_COMMENT_ID_PARAM,
  POST_ID_PARAM,
  TEAM_ID_PARAM,
} from 'routes/paths.constants';

import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

import {
  ADD_POST_COMMENT_TO_BOOKMARK,
  PLAYER_POSTS_COMMENT,
  PLAYER_POSTS_COMMENT_BOOKMARK,
  PLAYER_POSTS_COMMENT_REPLIES,
  PLAYER_POSTS_COMMENT_SHARE,
  PLAYER_POSTS_COMMENT_VOTE_DOWN,
  PLAYER_POSTS_COMMENT_VOTE_UP,
  PLAYER_POSTS_COMMENTS,
  POST_COMMENT_REPLIES,
  POST_COMMENT_VOTE_DOWN,
  POST_COMMENT_VOTE_UP,
  POST_COMMENTS,
  POST_POLL_VOTE,
  SHARE_POST_COMMENT,
  SINGLE_COMMENT,
  SINGLE_TEAM_COMMENT,
  TEAM_POST_COMMENT_REPLIES,
  TEAM_POST_COMMENT_VOTE_DOWN,
  TEAM_POST_COMMENT_VOTE_UP,
  TEAM_POST_COMMENTS,
  TEAM_POSTS_COMMENT_BOOKMARK,
  TEAM_POSTS_COMMENT_SHARE,
  VIDEOS_UPLOAD,
} from '../http/consts/api-endpoints.constants';

@injectable()
export class CommentsService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public async fetchComments(
    pagination: IPagination,
    postId: string,
    teamId: Maybe<number>,
    playerId: Maybe<number>,
    commentId: Maybe<string>,
    sort: string,
  ): Promise<IResponse<ICommentsResponse>> {
    const { limit, page } = pagination;

    const paramsObject: ICommentsSearchParams = {
      limit: limit.toString(),
      page: page.toString(),
      sort,
    };

    const routeParams = {
      [POST_ID_PARAM]: postId,
      [POST_COMMENT_ID_PARAM]: commentId || '',
      [TEAM_ID_PARAM]: teamId?.toString() || '',
      [PLAYER_ID_API_PARAM]: playerId?.toString() || '',
    };

    let url;

    if (playerId && commentId) {
      url = PLAYER_POSTS_COMMENT_REPLIES;
    } else if (playerId && !commentId) {
      url = PLAYER_POSTS_COMMENTS;
    } else if (teamId && commentId) {
      url = TEAM_POST_COMMENT_REPLIES;
    } else if (teamId && !commentId) {
      url = TEAM_POST_COMMENTS;
    } else if (!teamId && commentId) {
      url = POST_COMMENT_REPLIES;
    } else {
      url = POST_COMMENTS;
    }

    return this.httpService.request<ICommentsResponse>({
      method: 'GET',
      url,
      headers: {
        'X-Version': '3',
      },
      params: new URLSearchParams({ ...paramsObject }),
      routeParams,
    });
  }

  public async createComment(
    postId: string,
    payload: IPublicationPayload,
    commentId: Maybe<string>,
    teamId: Maybe<number>,
    playerId: Maybe<number>,
  ): Promise<IResponse<ICommentResponse>> {
    let url;

    if (playerId && commentId) {
      url = PLAYER_POSTS_COMMENT_REPLIES;
    } else if (playerId && !commentId) {
      url = PLAYER_POSTS_COMMENTS;
    } else if (teamId && commentId) {
      url = TEAM_POST_COMMENT_REPLIES;
    } else if (teamId && !commentId) {
      url = TEAM_POST_COMMENTS;
    } else if (!teamId && commentId) {
      url = POST_COMMENT_REPLIES;
    } else {
      url = POST_COMMENTS;
    }

    return this.httpService.request<ICommentResponse, IPublicationPayload>({
      method: 'POST',
      headers: {
        'X-Version': '2',
      },
      url,
      routeParams: {
        [POST_ID_PARAM]: postId,
        [POST_COMMENT_ID_PARAM]: commentId || '',
        [TEAM_ID_PARAM]: teamId?.toString() || '',
        [PLAYER_ID_API_PARAM]: playerId?.toString() || '',
      },
      body: payload,
    });
  }

  public async commentPollVote(
    payload: IPollVoteData,
  ): Promise<IResponse<IPollAttachmentResponse>> {
    return this.httpService.request<IPollAttachmentResponse, IPollVoteData>({
      method: 'POST',
      url: POST_POLL_VOTE,
      routeParams: { [POLL_ID_PARAM]: payload.pollId, [POLL_OPTION_ID_PARAM]: payload.answerId },
    });
  }

  public async deleteComment(payload: IDeleteCommentPayload): Promise<IResponse<ICommentResponse>> {
    if (payload.playerId) {
      return this.httpService.request<ICommentResponse, IDeleteCommentPayload>({
        method: 'DELETE',
        url: PLAYER_POSTS_COMMENT,
        routeParams: {
          [POST_ID_PARAM]: payload.postId,
          [POST_COMMENT_ID_PARAM]: payload.commentId,
          [PLAYER_ID_API_PARAM]: payload.playerId.toString(),
        },
      });
    }

    if (payload.teamId) {
      return this.httpService.request<ICommentResponse, IDeleteCommentPayload>({
        method: 'DELETE',
        url: SINGLE_TEAM_COMMENT,
        routeParams: {
          [POST_ID_PARAM]: payload.postId,
          [POST_COMMENT_ID_PARAM]: payload.commentId,
          [TEAM_ID_PARAM]: payload.teamId.toString(),
        },
      });
    }

    return this.httpService.request<ICommentResponse, IGetCommentPayload>({
      method: 'DELETE',
      url: SINGLE_COMMENT,
      routeParams: {
        [POST_ID_PARAM]: payload.postId,
        [POST_COMMENT_ID_PARAM]: payload.commentId,
      },
    });
  }

  public async fetchSingleComment(
    payload: IGetCommentPayload,
  ): Promise<IResponse<ICommentResponse>> {
    if (payload.playerId) {
      return this.httpService.request<ICommentResponse, IGetCommentPayload>({
        method: 'GET',
        url: PLAYER_POSTS_COMMENT,
        routeParams: {
          [POST_ID_PARAM]: payload.postId,
          [POST_COMMENT_ID_PARAM]: payload.commentId,
          [PLAYER_ID_API_PARAM]: payload.playerId.toString(),
        },
      });
    }

    if (payload.teamId) {
      return this.httpService.request<ICommentResponse, IGetCommentPayload>({
        method: 'GET',
        url: SINGLE_TEAM_COMMENT,
        routeParams: {
          [POST_ID_PARAM]: payload.postId,
          [POST_COMMENT_ID_PARAM]: payload.commentId,
          [TEAM_ID_PARAM]: payload.teamId.toString(),
        },
      });
    }
    if (payload.teamId) {
      return this.httpService.request<ICommentResponse, IGetCommentPayload>({
        method: 'GET',
        url: SINGLE_TEAM_COMMENT,
        routeParams: {
          [POST_ID_PARAM]: payload.postId,
          [POST_COMMENT_ID_PARAM]: payload.commentId,
          [TEAM_ID_PARAM]: payload.teamId.toString(),
        },
      });
    }

    return this.httpService.request<ICommentResponse, IGetCommentPayload>({
      method: 'GET',
      url: SINGLE_COMMENT,
      routeParams: {
        [POST_ID_PARAM]: payload.postId,
        [POST_COMMENT_ID_PARAM]: payload.commentId,
      },
    });
  }

  public async togglePostCommentBookmark(
    payload: IPostCommentBookmarkPayload,
  ): Promise<IResponse<ICommentResponse>> {
    if (payload.playerId) {
      return this.httpService.request<ICommentResponse, IPostCommentBookmarkPayload>({
        method: 'POST',
        url: PLAYER_POSTS_COMMENT_BOOKMARK,
        routeParams: {
          [POST_ID_PARAM]: payload.postId,
          [PLAYER_ID_API_PARAM]: payload.playerId.toString(),
          [COMMENT_ID_API_PARAM]: payload.commentId,
        },
      });
    }

    if (payload.teamId) {
      return this.httpService.request<ICommentResponse, IPostCommentBookmarkPayload>({
        method: 'POST',
        url: TEAM_POSTS_COMMENT_BOOKMARK,
        routeParams: {
          [POST_ID_PARAM]: payload.postId,
          [TEAM_ID_PARAM]: payload.teamId.toString(),
          [COMMENT_ID_API_PARAM]: payload.commentId,
        },
      });
    }

    return this.httpService.request<ICommentResponse, ITogglePostBookmarkPayload>({
      method: 'POST',
      url: ADD_POST_COMMENT_TO_BOOKMARK,
      routeParams: {
        [POST_ID_API_PARAM]: payload.postId,
        [COMMENT_ID_API_PARAM]: payload.commentId,
      },
    });
  }

  public async sharePostComment(
    payload: ISharePostCommentPayload,
  ): Promise<IResponse<ShareResponse>> {
    return this.httpService.request<ShareResponse, ISharePostCommentPayload>({
      method: 'POST',
      url: SHARE_POST_COMMENT,
      routeParams: { [POST_ID_PARAM]: payload.postId, [COMMENT_ID_API_PARAM]: payload.commentId },
    });
  }

  public async shareTeamPostComment(
    payload: IShareTeamPostCommentPayload,
  ): Promise<IResponse<ShareResponse>> {
    return this.httpService.request<ShareResponse, IShareTeamPostCommentPayload>({
      method: 'POST',
      url: TEAM_POSTS_COMMENT_SHARE,
      routeParams: {
        [POST_ID_PARAM]: payload.postId,
        [COMMENT_ID_API_PARAM]: payload.commentId,
        [TEAM_ID_PARAM]: payload.teamId.toString(),
      },
    });
  }

  public async sharePlayerPostComment(
    payload: ISharePlayerPostCommentPayload,
  ): Promise<IResponse<ShareResponse>> {
    return this.httpService.request<ShareResponse, ISharePlayerPostCommentPayload>({
      method: 'POST',
      url: PLAYER_POSTS_COMMENT_SHARE,
      routeParams: {
        [POST_ID_PARAM]: payload.postId,
        [COMMENT_ID_API_PARAM]: payload.commentId,
        [PLAYER_ID_API_PARAM]: payload.playerId.toString(),
      },
    });
  }

  public async postCommentVoteUp(
    postId: string,
    commentId: string,
    payload: IPostCommentVoteDataPayload,
  ): Promise<IResponse<IPostCommentVoteResponse>> {
    return this.httpService.request<IPostCommentVoteResponse, IPostCommentVoteDataPayload>({
      method: 'POST',
      url: POST_COMMENT_VOTE_UP,
      body: payload,
      routeParams: {
        [POST_ID_PARAM]: postId,
        [COMMENT_ID_API_PARAM]: commentId,
      },
    });
  }

  public async postCommentVoteDown(
    postId: string,
    commentId: string,
    payload: IPostCommentVoteDataPayload,
  ): Promise<IResponse<IPostCommentVoteResponse>> {
    return this.httpService.request<IPostCommentVoteResponse, IPostCommentVoteDataPayload>({
      method: 'POST',
      url: POST_COMMENT_VOTE_DOWN,
      body: payload,
      routeParams: {
        [POST_ID_PARAM]: postId,
        [COMMENT_ID_API_PARAM]: commentId,
      },
    });
  }

  public async teamPostCommentVoteUp(
    teamId: number,
    postId: string,
    commentId: string,
    payload: IPostCommentVoteDataPayload,
  ): Promise<IResponse<IPostCommentVoteResponse>> {
    return this.httpService.request<IPostCommentVoteResponse, IPostCommentVoteDataPayload>({
      method: 'POST',
      url: TEAM_POST_COMMENT_VOTE_UP,
      body: payload,
      routeParams: {
        [TEAM_ID_PARAM]: teamId.toString(),
        [POST_ID_PARAM]: postId,
        [COMMENT_ID_API_PARAM]: commentId,
      },
    });
  }

  public async teamPostCommentVoteDown(
    teamId: number,
    postId: string,
    commentId: string,
    payload: IPostCommentVoteDataPayload,
  ): Promise<IResponse<IPostCommentVoteResponse>> {
    return this.httpService.request<IPostCommentVoteResponse, IPostCommentVoteDataPayload>({
      method: 'POST',
      url: TEAM_POST_COMMENT_VOTE_DOWN,
      body: payload,
      routeParams: {
        [TEAM_ID_PARAM]: teamId.toString(),
        [POST_ID_PARAM]: postId,
        [COMMENT_ID_API_PARAM]: commentId,
      },
    });
  }

  public async playerPostCommentVoteUp(
    playerId: number,
    postId: string,
    commentId: string,
    payload: IPostCommentVoteDataPayload,
  ): Promise<IResponse<IPostCommentVoteResponse>> {
    return this.httpService.request<IPostCommentVoteResponse, IPostCommentVoteDataPayload>({
      method: 'POST',
      url: PLAYER_POSTS_COMMENT_VOTE_UP,
      body: payload,
      routeParams: {
        [PLAYER_ID_API_PARAM]: playerId.toString(),
        [POST_ID_PARAM]: postId,
        [COMMENT_ID_API_PARAM]: commentId,
      },
    });
  }

  public async playerPostCommentVoteDown(
    playerId: number,
    postId: string,
    commentId: string,
    payload: IPostCommentVoteDataPayload,
  ): Promise<IResponse<IPostCommentVoteResponse>> {
    return this.httpService.request<IPostCommentVoteResponse, IPostCommentVoteDataPayload>({
      method: 'POST',
      url: PLAYER_POSTS_COMMENT_VOTE_DOWN,
      body: payload,
      routeParams: {
        [PLAYER_ID_API_PARAM]: playerId.toString(),
        [POST_ID_PARAM]: postId,
        [COMMENT_ID_API_PARAM]: commentId,
      },
    });
  }

  public async uploadVideo(payload: VideoUploadPayloadType): Promise<IResponse<IVideoResponse>> {
    return this.httpService.request<IVideoResponse, VideoUploadPayloadType>({
      method: 'POST',
      url: VIDEOS_UPLOAD,
      body: payload,
      isMultipart: true,
    });
  }
}
