import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Browser } from '@capacitor/browser';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { IHeadline } from 'stores/headlines/interfaces/headline.interface';

import * as paths from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';

import { Headline } from 'components/headline/headline.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './shortcut-headlines.module.less';

interface IShortcutHeadlinesProps {
  headlines: IHeadline[];
}

export const ShortcutHeadlines = memo((props: IShortcutHeadlinesProps) => {
  const { headlines } = props;

  const navigate = useNavigate();

  const { isNativeApp } = useMainProvider();

  const handleHeadlineClick = useCallback(
    (url: string, id: number) => () => {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.ShortcutHeadline,
          headline_id: id,
        },
      });

      if (isNativeApp) {
        Browser.open({ url });

        return;
      }

      window.open(url, '_blank');
    },
    [isNativeApp],
  );

  const handleHeadlinesNavigate = useCallback(() => {
    navigate(paths.HOME_HEADLINES);
  }, [navigate]);

  return (
    <div className={styles.ShortcutHeadlines}>
      <div className={styles.ShortcutHeadlines__Header}>
        <span className={styles.ShortcutHeadlines__Title}>Latest Headlines</span>
        <Button size={ButtonSize.Big} theme={ButtonTheme.Text} onClick={handleHeadlinesNavigate}>
          See All
        </Button>
      </div>
      <div className={styles.ShortcutHeadlines__Body}>
        {headlines.map((headline) => (
          <Headline
            key={headline.id}
            id={headline.id}
            url={headline.url}
            title={headline.title}
            iconUrl={headline.iconUrl}
            onClick={handleHeadlineClick}
          />
        ))}
      </div>
    </div>
  );
});
