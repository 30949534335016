import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import { RANKING_CATEGORY_ID_API_PARAM } from 'services/http/consts/api-endpoint-params.constants';
import { HttpService } from 'services/http/http.service';

import { ISharePlayersRankingsResponse } from 'stores/share/interfaces/share-response.interface';

import { TYPES } from 'configs/di-types.config';

import {
  PLAYERS_RANKINGS_ALL,
  PLAYERS_RANKINGS_BY_CATEGORY,
  SHARE_PLAYERS_RANKINGS,
  SHARE_PLAYERS_RANKINGS_DETAILS,
} from '../http/consts/api-endpoints.constants';

import {
  ICategoryPlayerRankingsItemsResponse,
  IPlayerRankingsResponse,
} from './interfaces/player-rankings-response.interface';

@injectable()
export class PlayerRankingsService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchPlayerRankings(): Promise<IResponse<IPlayerRankingsResponse>> {
    return this.httpService.request<IPlayerRankingsResponse>({
      url: PLAYERS_RANKINGS_ALL,
      method: 'GET',
    });
  }

  public sharePlayerRankings(): Promise<IResponse<ISharePlayersRankingsResponse>> {
    return this.httpService.request<ISharePlayersRankingsResponse>({
      url: SHARE_PLAYERS_RANKINGS,
      method: 'POST',
    });
  }

  public sharePlayerRankingsDetails(
    category: string,
  ): Promise<IResponse<ISharePlayersRankingsResponse>> {
    return this.httpService.request<ISharePlayersRankingsResponse>({
      url: SHARE_PLAYERS_RANKINGS_DETAILS,
      method: 'POST',
      routeParams: { [RANKING_CATEGORY_ID_API_PARAM]: category },
    });
  }

  public fetchPlayerRankingsByCategory(
    pagination: IPagination,
    filter: string,
  ): Promise<IResponse<ICategoryPlayerRankingsItemsResponse>> {
    const { limit, page } = pagination;

    const paramsObject = {
      limit: String(limit),
      page: String(page),
      category: filter,
    };

    return this.httpService.request<ICategoryPlayerRankingsItemsResponse>({
      method: 'GET',
      url: PLAYERS_RANKINGS_BY_CATEGORY,
      params: new URLSearchParams({ ...paramsObject }),
    });
  }
}
