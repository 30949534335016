import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import styles from './range-input.module.less';

interface IRangeInputProps {
  min?: number;
  max: number;
  value: Maybe<number>;
  step: number;
  onUpdate: (value: number) => void;
  customMinLabel?: string;
}

export const RangeInput = (props: IRangeInputProps) => {
  const { min = 0, max, value, step, onUpdate, customMinLabel = '' } = props;

  const [sliderValue, setSliderValue] = useState(value);
  const [showBubble, setShowBubble] = useState(false);

  const handleUpdateRange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setShowBubble(true);
      setSliderValue(+event.target.value);
      onUpdate(+event.target.value);
    },
    [onUpdate],
  );

  const getProgressPosition = useMemo(() => {
    return sliderValue === min || !sliderValue ? 0 : ((sliderValue - min) * 100) / (max - min);
  }, [max, min, sliderValue]);

  const getBubbleOffest = useMemo(() => {
    return Math.round((20 * getProgressPosition) / 100) - 20 / 2;
  }, [getProgressPosition]);

  return (
    <div className={styles.Slider}>
      {showBubble && (
        <div
          className={styles.Slider__Bubble}
          style={{ left: `calc(${getProgressPosition}% - ${getBubbleOffest}px)` }}
        >
          <span className={styles.Slider__Value}>
            {sliderValue === min && customMinLabel ? customMinLabel : sliderValue}
          </span>
        </div>
      )}
      <input
        type="range"
        name="slider"
        className={styles.Slider__Input}
        min={min}
        max={max}
        value={sliderValue || 0}
        step={step}
        onChange={handleUpdateRange}
        style={{
          background: `linear-gradient(to right, #3391CC 0%, #3391CC ${getProgressPosition}%, #444F57 ${getProgressPosition}%, #444F57 100%)`,
        }}
      />
    </div>
  );
};
