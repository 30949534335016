import { FC, useMemo } from 'react';

import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { SettingsContainer } from 'containers/settings/settings.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useLayoutEntity } from 'hooks/use-layout-entity';
import { useMobileLayoutStructure } from 'hooks/use-mobile-layout-structure';
import { useResponsive } from 'hooks/use-responsive';

import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const SettingsRoute: FC = () => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const rowLayout = useMemo(
    () => (isDesktopPlus ? RowLayout.FullWidth : RowLayout.MainContent),
    [isDesktopPlus],
  );

  useLayoutEntity({ type: LayoutEntity.PageTitle, value: 'Settings' });

  useMobileLayoutStructure({
    isHiddenBottomBar: true,
    isHiddenHeader: true,
  });

  return (
    <PageWrapperContainer>
      <Row layout={rowLayout}>
        <SettingsContainer />
      </Row>
    </PageWrapperContainer>
  );
};
