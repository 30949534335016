import { memo, useMemo } from 'react';
import cn from 'classnames';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './report-success.module.less';

interface IReportSuccessProps {
  title: string;
  isModalReport: boolean;
  onCloseBottomSheet: () => void;
}

export const ReportSuccess = memo((props: IReportSuccessProps) => {
  const { isModalReport, title } = props;

  const reportSuccessClasses = useMemo(
    () =>
      cn(styles.ReportSuccess, {
        [styles['ReportSuccess--modal-theme']]: isModalReport,
      }),
    [isModalReport],
  );

  const doneButtonSize = useMemo(() => {
    if (isModalReport) {
      return ButtonSize.Small;
    }

    return ButtonSize.Big;
  }, [isModalReport]);

  return (
    <div className={reportSuccessClasses}>
      <div className={styles.ReportSuccess__Icon}>
        <IconFont name={IconFontName.Confirm} size={IconFontSize.Big} />
      </div>
      <div className={styles.ReportSuccess__Title}>{title}</div>
      <p className={styles.ReportSuccess__Text}>
        We&apos;ll use this information to improve our processes. We may also use it to help us find
        and remove more spam
      </p>
      <div className={styles.ReportSuccess__Footer}>
        <Button
          size={doneButtonSize}
          theme={ButtonTheme.Primary}
          onClick={props.onCloseBottomSheet}
        >
          Done
        </Button>
      </div>
    </div>
  );
});
