import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const WELCOME_POPUP_ARENA_LIST = [
  {
    id: 0,
    title: 'Keep up with games',
    text: 'Stay up to date with every live game.',
    iconId: IconFontName.Ball,
  },
  {
    id: 1,
    title: 'Play-by-Play',
    text: 'Never miss a play again with our interactive feed.',
    iconId: IconFontName.Arena,
  },
  {
    id: 2,
    title: 'Fan Area',
    text: 'The go to location to meet other fans, every game day.',
    iconId: IconFontName.Users,
  },
];

export const WELCOME_POPUP_TEAM_LIST = [
  {
    id: 0,
    title: 'Find others with similar interests',
    text: 'Teams are for fans, players and teams to engage.',
    iconId: IconFontName.Users,
  },
  {
    id: 1,
    title: 'Post in a team',
    text: 'Create a post to share your thoughts with the team!',
    iconId: IconFontName.Chat,
  },
  {
    id: 2,
    title: 'Fun & secure',
    text: 'Safety and Privacy are our top priorities.',
    iconId: IconFontName.Like,
  },
];

export const WELCOME_POPUP_FEED_LIST = [
  {
    id: 0,
    title: 'Create Posts',
    text: 'Easy content creation for fans to express themselves.',
    iconId: IconFontName.Chat,
  },
  {
    id: 1,
    title: 'Keep up with News',
    text: 'One simple location for all your NBA news needs.',
    iconId: IconFontName.News,
  },
  {
    id: 2,
    title: 'Instant Social & Podcasts Updates',
    text: 'Never miss a viral social media post or podcast clip ever again.',
    iconId: IconFontName.Users,
  },
];

export const WELCOME_POPUP_PLAYER_PROFILE_LIST = [
  {
    id: 0,
    title: 'Meet Fans with Similar Interests',
    text: 'Talk to people who care about the same topics you do.',
    iconId: IconFontName.Users,
  },
  {
    id: 1,
    title: 'Engage with the Team',
    text: 'React, Comment and Interact with other team members.',
    iconId: IconFontName.Post,
  },
  {
    id: 2,
    title: 'News, Social, Podcasts and Stats',
    text: 'Receive live updates on your favorite player.',
    iconId: IconFontName.News,
  },
];

export const WELCOME_POPUP_RANKINGS_LIST = [
  {
    id: 0,
    title: 'Leaderboards',
    text: 'See where players rank for a given stat on any given day or time period.',
    iconId: IconFontName.Rating,
  },
  {
    id: 1,
    title: 'Most Important Stats',
    text: 'Showcasing only the stats fans care about the most.',
    iconId: IconFontName.Activity,
  },
  {
    id: 2,
    title: 'Every Player',
    text: 'All players who play can will be ranked based on their performances.',
    iconId: IconFontName.Ball,
  },
];
