import { Share } from '@capacitor/share';

export const useNativeShare = () => {
  const shareByNativeModal = async (url: string, title: string) => {
    const canShare = await Share.canShare();

    if (canShare.value) {
      await Share.share({
        url,
        title,
        dialogTitle: title,
      });
    }
  };

  return { shareByNativeModal };
};
