import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE, DEFAULT_VALIDATION_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import formsStyles from 'components/forms/forms.module.less';
import { SubmitSection } from 'components/forms/submit-section/submit-section.component';
import { PasswordInput } from 'components/ui/form-fields/password-input/password-input.component';

import { passwordSchema } from 'validation/schemas/password.schema';

const passwordsFormSchema = z
  .object({
    password: passwordSchema,
    confirmPassword: passwordSchema,
  })
  .strict()
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        path: ['confirmPassword'],
        message: 'The passwords did not match',
      });
    }
  });

export type PasswordsFormDataType = z.infer<typeof passwordsFormSchema>;

interface IPasswordFormProps extends IForm<PasswordsFormDataType> {}

export const PasswordsForm: FC<IPasswordFormProps> = (props) => {
  const { processing, submitText, initialValues, onSubmit } = props;

  const { control, formState, handleSubmit } = useForm<PasswordsFormDataType>({
    defaultValues: initialValues,
    mode: DEFAULT_VALIDATION_MODE,
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(passwordsFormSchema),
  });

  const { isValid, errors } = formState;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={formsStyles.FormContent}>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <PasswordInput
              {...field}
              withBadge
              error={errors.password?.message}
              id="password"
              label="Password"
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <PasswordInput
              {...field}
              withBadge
              withMeter={false}
              error={errors.confirmPassword?.message}
              id="confirmPassword"
              label="Confirm password"
            />
          )}
        />
      </div>
      <hr />
      <SubmitSection disabled={processing} isFormValid={isValid} buttonText={submitText} />
    </Form>
  );
};
