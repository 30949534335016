import { memo, ReactNode, useCallback, useMemo } from 'react';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import { IHeader } from '../header.interface';

import styles from './header-mobile.module.less';

interface IHeaderMobileProps extends IHeader {
  onLeftItemClick: () => void;
  onScrollTopClick: () => void;
  center: Maybe<ReactNode>;
  rightCorner: ReactNode;
  leftCornerIcon: Maybe<IconFontName>;
  isEnabledScrollToTop: boolean;
  backgroundColor: Maybe<string>;
  textColor: Maybe<string>;
  leftSideTitle: Maybe<string>;
  leftSideDescription: Maybe<string>;
}

export const HeaderMobile = memo((props: IHeaderMobileProps) => {
  const {
    title,
    leftSideTitle,
    leftSideDescription,
    rightCorner,
    center,
    leftCornerIcon,
    isEnabledScrollToTop,
    onScrollTopClick,
    backgroundColor,
    textColor,
  } = props;

  const handleScrollTopClick = useCallback(() => {
    if (isEnabledScrollToTop) {
      onScrollTopClick();
    }
  }, [isEnabledScrollToTop, onScrollTopClick]);

  const backgroundColorStyles = useMemo(() => {
    if (backgroundColor) {
      return {
        background: backgroundColor,
      };
    }

    return {};
  }, [backgroundColor]);

  return (
    <header style={backgroundColorStyles} className={styles.HeaderMobile}>
      <div className={styles.HeaderMobile__LeftItem}>
        {leftCornerIcon && (
          <IconButton
            menuActive
            iconName={leftCornerIcon}
            theme={IconButtonTheme.Transparent}
            onClick={props.onLeftItemClick}
            customColor={textColor}
          />
        )}
        {(!!leftSideTitle || !!leftSideDescription) && (
          <div className={styles.CustomSection}>
            {leftSideTitle && <div className={styles.CustomSection__Title}>{leftSideTitle}</div>}
            {leftSideDescription && (
              <div className={styles.CustomSection__Description}>{leftSideDescription}</div>
            )}
          </div>
        )}
      </div>
      {!!center && center}
      {!!title && !center && (
        <button onClick={handleScrollTopClick} className={styles.HeaderMobile__TitleInner}>
          <h1 className={styles.HeaderMobile__Title}>{title}</h1>
        </button>
      )}
      {!!rightCorner && <div className={styles.HeaderMobile__SafeArea}>{rightCorner}</div>}
    </header>
  );
});
