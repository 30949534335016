import { ITeamStatsMini } from 'stores/teams-stats/interfaces/teams-stats-all.interface';

import { ITeamGlobalStatsRowData } from '../interfaces/global-stats-row-data.interfaces';

export function teamGlobalStatsRowDataAdapter(
  teamGlobalStats: ITeamStatsMini,
): ITeamGlobalStatsRowData {
  const {
    id,
    nickname,
    smallLogoUrl,
    color,
    assists,
    blocks,
    defensiveReb,
    fieldGoalsAttempted,
    fieldGoalsMade,
    fieldGoalsPercentage,
    freeThrowsAttempted,
    freeThrowsMade,
    freeThrowsPercentage,
    gamesPlayed,
    offensiveReb,
    personalFouls,
    points,
    totalRebounds,
    steals,
    threePointsFieldGoalsAttempted,
    threePointsFieldGoalsMade,
    threePointsFieldGoalsPercentage,
    turnovers,
    diffPoints,
    opponentPoints,
  } = teamGlobalStats;

  return {
    name: {
      id,
      nickname,
      smallLogoUrl,
      color,
    },
    assists,
    blocks,
    defensiveReb,
    fieldGoalsAttempted,
    fieldGoalsMade,
    fieldGoalsPercentage,
    freeThrowsAttempted,
    freeThrowsMade,
    freeThrowsPercentage,
    gamesPlayed,
    offensiveReb,
    personalFouls,
    points,
    totalRebounds,
    steals,
    threePointsFieldGoalsAttempted,
    threePointsFieldGoalsMade,
    threePointsFieldGoalsPercentage,
    turnovers,
    pointsDifferential: diffPoints,
    pointsAllowed: opponentPoints,
  };
}
