import { useCallback, useEffect, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ContainerNamesEnum } from 'services/google-analytic/enums/container-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { PermissionPopups } from 'services/local-database/config';

import { AuthStore } from 'stores/auth/auth.store';
import { LayoutStore } from 'stores/layout/layout.store';

import { TYPES } from 'configs/di-types.config';

import { WelcomePopup } from 'components/welcome-popup/welcome-popup.component';

import rankingsThumbnail from 'assets/images/rankings-thumbnail.webp';

import { WELCOME_POPUP_RANKINGS_LIST } from './welcome-popup.config';

export const WelcomePopupRankingsContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);

  const isWelcomePopupShown = useMemo(() => {
    if (!layoutStore.isFirstTimeIntroShown) {
      return true;
    }
    return authStore.isAuthorised
      ? authStore.userMe?.profile.rankingsFirstVisited
      : layoutStore.isWelcomeRankingsShown;
  }, [
    authStore.isAuthorised,
    authStore.userMe?.profile.rankingsFirstVisited,
    layoutStore.isFirstTimeIntroShown,
    layoutStore.isWelcomeRankingsShown,
  ]);

  const handleWelcomePopupAccept = useCallback(async () => {
    if (authStore.isAuthorised) {
      await authStore.updateUserMe({ rankingsFirstVisited: true });
    }

    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.IntroPromptRankingsEnter,
      },
    });
    layoutStore.setWelcomePopupShown(PermissionPopups.WELCOME_RANKINGS_SHOWN);
  }, [authStore, layoutStore]);

  const handleWelcomePopupClose = useCallback(async () => {
    if (authStore.isAuthorised) {
      await authStore.updateUserMe({ rankingsFirstVisited: true });
    }
    layoutStore.setWelcomePopupShown(PermissionPopups.WELCOME_RANKINGS_SHOWN);
  }, [authStore, layoutStore]);

  useEffect(() => {
    if (!isWelcomePopupShown) {
      GoogleAnalyticService.event({
        eventName: 'container_custom_view',
        eventParams: {
          container_type: ContainerNamesEnum.IntroPromptRankings,
        },
      });
    }
  }, [isWelcomePopupShown]);

  useEffect(() => {
    layoutStore.setAnyWelcomePopupOpened(!isWelcomePopupShown);
    return () => layoutStore.setAnyWelcomePopupOpened(false);
  }, [isWelcomePopupShown, layoutStore]);

  useEffect(() => {
    if (authStore.isAuthorised && authStore.userMe?.profile.mainFeedFirstVisited) {
      layoutStore.setWelcomePopupShown(PermissionPopups.WELCOME_RANKINGS_SHOWN);
    }
  }, [authStore.isAuthorised, authStore.userMe?.profile.mainFeedFirstVisited, layoutStore]);

  if (!isWelcomePopupShown) {
    return (
      <WelcomePopup
        onAccept={handleWelcomePopupAccept}
        onClose={handleWelcomePopupClose}
        thumbnail={rankingsThumbnail}
        title="Daily Player Rankings"
        description="The fastest and easiest way to keep up with player performances in real time!"
        accepButtonName="View Rankings"
        listItems={WELCOME_POPUP_RANKINGS_LIST}
      />
    );
  }
  return null;
});
