import {
  ISearchItem,
  ISearchItemResponse,
  SearchResultType,
} from 'stores/search/interfaces/search.interface';

export function searchAdapter(searchResponse: ISearchItemResponse): ISearchItem {
  const { id, image_src: imageSrc, options } = searchResponse;

  if (options.type === 'PLAYER') {
    return {
      id,
      name: `${options.first_name} ${options.last_name}`,
      imageSrc,
      options: {
        type: SearchResultType.PLAYER,
        isFollowed: options.is_followed,
        jersey: options.jersey,
        playerId: id,
        position: options.position,
        teamName: options.team_name,
        playerSlug: options.slug,
      },
    };
  }

  return {
    id,
    imageSrc,
    name: options.name,
    options: {
      type: SearchResultType.TEAM,
      isFollowed: options.is_followed,
      conference: options.conference === 'WESTERN' ? 'West' : 'East',
      loss: options.loss,
      win: options.win,
      teamId: id,
      rank: options.rank,
    },
  };
}
