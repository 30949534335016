import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IGameTeamInfo } from 'stores/game/interfaces/game.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Avatar, AvatarSize, IconAvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './team-row.module.less';

interface IBoxScoreProps {
  team: IGameTeamInfo;
  isHome: boolean;
  onTeamClick: (teamId: number) => void;
}

export const TeamRow: FC<IBoxScoreProps> = (props) => {
  const { team, isHome, onTeamClick } = props;

  const rowClassNames = useMemo(
    () =>
      cn(styles.TeamRow, {
        [styles['TeamRow--home']]: isHome,
        [styles['TeamRow--visitor']]: !isHome,
      }),
    [isHome],
  );

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handleTeamClick = useCallback(() => {
    onTeamClick(team.teamInfo.id);
  }, [onTeamClick, team.teamInfo.id]);

  const teamColorStyle = useMemo(
    () => ({ color: team.teamInfo.secondaryTextColor }),
    [team.teamInfo.secondaryTextColor],
  );

  return (
    <button className={rowClassNames} style={teamColorStyle} onClick={handleTeamClick}>
      {!isDesktopPlus && <div className={styles.Gradient} />}
      <div className={styles.TeamRow__TeamSection}>
        <Avatar
          size={AvatarSize.S}
          iconSize={IconAvatarSize.S}
          username={team.teamInfo.code}
          src={team.teamInfo.smallLogoUrl}
        />
        <h4 className={styles.TeamName}>{team.teamInfo.name}</h4>
      </div>
    </button>
  );
};
