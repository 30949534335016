import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionClick } from 'components/bars/bar-action.type';
import { ITab } from 'components/ui/tabs/tabs.component';

export enum GameDetailsTabs {
  TEAMS = 'Teams',
  POLLS = 'Polls',
  PLAYERS = 'Players',
  CHAT = 'Chat',
  PLAYS = 'Plays',
}

export const GAME_TABS: ITab<BarActionClick<GameDetailsTabs>>[] = [
  {
    id: GameDetailsTabs.PLAYS,
    title: GameDetailsTabs.PLAYS,
    action: {
      type: BarAction.Click,
      payload: GameDetailsTabs.PLAYS,
    },
  },
  {
    id: GameDetailsTabs.CHAT,
    title: GameDetailsTabs.CHAT,
    action: {
      type: BarAction.Click,
      payload: GameDetailsTabs.CHAT,
    },
  },
  {
    id: GameDetailsTabs.POLLS,
    title: GameDetailsTabs.POLLS,
    action: {
      type: BarAction.Click,
      payload: GameDetailsTabs.POLLS,
    },
  },
  {
    id: GameDetailsTabs.PLAYERS,
    title: GameDetailsTabs.PLAYERS,
    action: {
      type: BarAction.Click,
      payload: GameDetailsTabs.PLAYERS,
    },
  },
  {
    id: GameDetailsTabs.TEAMS,
    title: GameDetailsTabs.TEAMS,
    action: {
      type: BarAction.Click,
      payload: GameDetailsTabs.TEAMS,
    },
  },
];

export const GAME_STATS_CONFIG = [
  {
    field: 'fieldGoals',
    title: 'FG',
    isMain: true,
    isPrimary: true,
    isBoldTitle: true,
  },
  {
    field: 'threePointsField',
    title: '3PT',
    isMain: true,
    isPrimary: true,
    isBoldTitle: true,
  },
  {
    field: 'freeThrows',
    title: 'FT',
    isMain: true,
    isPrimary: true,
    isBoldTitle: true,
  },
  {
    field: 'totalRebounds',
    title: 'Rebounds',
    isPrimary: true,
  },
  {
    field: 'offensiveReb',
    title: 'Offensive Rebounds',
    isPrimary: false,
  },
  {
    field: 'defensiveReb',
    title: 'Defensive Rebounds',
    isPrimary: false,
  },
  {
    field: 'assists',
    title: 'Assists',
    isPrimary: true,
  },
  {
    field: 'steals',
    title: 'Steals',
    isPrimary: true,
  },
  {
    field: 'blocks',
    title: 'Blocks',
    isPrimary: true,
  },
  {
    field: 'turnovers',
    title: 'Turnovers',
    isPrimary: true,
  },
  {
    field: 'pointsOffTurnovers',
    title: 'Points Off Turnovers',
    isPrimary: false,
  },
  {
    field: 'fastBreakPoints',
    title: 'Fast Break Points',
    isPrimary: true,
  },
  {
    field: 'pointsInPaint',
    title: 'Points in Paint',
    isPrimary: true,
  },
  {
    field: 'fouls',
    title: 'Fouls',
    isPrimary: true,
  },
  {
    field: 'technicalFouls',
    title: 'Technical Fouls',
    isPrimary: false,
  },
  {
    field: 'flagrantFouls',
    title: 'Flagrant Fouls',
    isPrimary: false,
  },
  {
    field: 'largesLead',
    title: 'Largest Lead',
    isPrimary: true,
  },
];

export const GAME_TAB_DEFAULT = GameDetailsTabs.CHAT;

export const isGameDetailsTabs = (value: string): value is GameDetailsTabs => {
  return Object.values(GameDetailsTabs).some((enumValue) => enumValue === value);
};

export const NO_GAME_TITLE = 'No games available at the moment';
export const NO_GAME_MESSAGE = 'Please check back later';
