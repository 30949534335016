import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IEventParams } from 'services/google-analytic/interfaces/event-payload.interface';

import { StatisticsStore } from 'stores/statistics/statistics.store';
import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { Statistics } from 'components/statistics/statistics.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './team-statistics.module.less';

export const TeamStatisticsContainer = observer(() => {
  const statisticsStore = useInjection<StatisticsStore>(TYPES.StatisticsStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);

  const navigate = useNavigate();

  const handleGameClick = useCallback(
    (gameId: number, opponents?: string) => {
      navigate(getPath(paths.ARENA_GAME, { [paths.GAME_ID_PARAM]: gameId.toString() }));

      const eventParams: IEventParams = {
        type: ButtonTapsEnum.ShortcutGame,
      };

      if (opponents) {
        eventParams.game = opponents;
      }

      if (teamsStatsStore.team) {
        eventParams.team = teamsStatsStore.team.code;
      }

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams,
      });
    },
    [teamsStatsStore.team, navigate],
  );

  useEffect(() => {
    if (teamsStore.teamId) {
      statisticsStore.retrieveForTeam();
    }
  }, [teamsStore.teamId, statisticsStore]);

  if (statisticsStore.fetching) {
    return (
      <div className={styles.TeamStatisticsLoader}>
        <Loader isShow />
      </div>
    );
  }

  return (
    <div className={styles.TeamStatistics}>
      <Statistics
        games={statisticsStore.entries}
        firstGameIds={statisticsStore.firstGameIds}
        firstGameTodayId={statisticsStore.firstGameTodayId}
        onGameClick={handleGameClick}
      />
    </div>
  );
});
