import { memo, useMemo } from 'react';
import cn from 'classnames';

import scoreboardGridStyles from '../scoreboard-grid.module.less';
import styles from './scoreboard-line-score-header.module.less';

interface IScoreboardLineScoreHeaderProps {
  total: Maybe<number>;
}

export const ScoreboardLineScoreHeader = memo((props: IScoreboardLineScoreHeaderProps) => {
  const { total } = props;

  const quarters = useMemo(() => {
    let count;
    if (!total && total !== 0) {
      count = 4;
    } else {
      count = total <= 4 ? 4 : total;
    }

    return new Array(count).fill(null).map((_, index) => {
      if (index <= 3) {
        return `Q${index + 1}`;
      }

      if (index === 4) {
        return `OT`;
      }

      return `${index - 3}OT`;
    });
  }, [total]);

  const classNames = cn(styles.ScoreboardLineScoreHeader, scoreboardGridStyles.ScoreboardGrid);

  return (
    <div className={classNames}>
      {quarters.map((quarter) => (
        <div key={quarter} className={scoreboardGridStyles.ScoreboardGrid__Column}>
          {quarter}
        </div>
      ))}
      <div className={scoreboardGridStyles.ScoreboardGrid__Column}>F</div>
    </div>
  );
});
