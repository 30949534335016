import { ColorResponseType } from '../rgb-to-rgba.utils';

export const convertRGBToHEX = (color: ColorResponseType): string => {
  if (Array.isArray(color)) {
    return `#${valueToHex(color[0])}${valueToHex(color[1])}${valueToHex(color[2])}`;
  }

  return color;
};

function valueToHex(value: number) {
  const hex = value.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}
