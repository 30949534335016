export const monthColumnComparator = (currentItem: string, nextItem: string) => {
  const monthes = [
    'September',
    'August',
    'July',
    'June',
    'May',
    'April',
    'March',
    'February',
    'January',
    'December',
    'November',
    'October',
  ];
  return monthes.indexOf(currentItem) - monthes.indexOf(nextItem);
};
