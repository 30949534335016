import { IPlayerRankedStatsResponse } from 'services/player/interfaces/player-stats.interface';

import { seasonAdapter } from 'stores/seasons/adapters/season-adapter.util';

import { roundToSingleDecimal } from 'helpers/round-to-single-decimal.util';

import { IPlayerRankedStats } from '../interfaces/player-profile.interfaces';

export function playerRankedStatsAdapter(
  playerRankedStatsResponse: Maybe<IPlayerRankedStatsResponse>,
): Maybe<IPlayerRankedStats> {
  if (!playerRankedStatsResponse) {
    return null;
  }

  const {
    season,
    assists,
    assists_rank: assistsRank,
    blocks,
    blocks_rank: blocksRank,
    def_reb: defensiveReb,
    def_reb_rank: defensiveRebRank,
    fga: fieldGoalsAttempted,
    fga_rank: fieldGoalsAttemptedRank,
    fgm: fieldGoalsMade,
    fgm_rank: fieldGoalsMadeRank,
    fgp: fieldGoalsPercentage,
    fgp_rank: fieldGoalsPercentageRank,
    fta: freeThrowsAttempted,
    fta_rank: freeThrowsAttemptedRank,
    ftm: freeThrowsMade,
    ftm_rank: freeThrowsMadeRank,
    ftp: freeThrowsPercentage,
    ftp_rank: freeThrowsPercentageRank,
    games_played: gamesPlayed,
    games_played_rank: gamesPlayedRank,
    off_reb: offensiveReb,
    off_reb_rank: offensiveRebRank,
    personal_fouls: personalFouls,
    personal_fouls_rank: personalFoulsRank,
    points,
    points_rank: pointsRank,
    position,
    steals,
    steals_rank: stealsRank,
    tot_reb: totalRebounds,
    tot_reb_rank: totalReboundsRank,
    tpa: threePointsFieldGoalsAttempted,
    tpa_rank: threePointsFieldGoalsAttemptedRank,
    tpm: threePointsFieldGoalsMade,
    tpm_rank: threePointsFieldGoalsMadeRank,
    tpp: threePointsFieldGoalsPercentage,
    tpp_rank: threePointsFieldGoalsPercentageRank,
    turnovers,
    turnovers_rank: turnoversRank,
  } = playerRankedStatsResponse;

  return {
    season: seasonAdapter(season),
    assists: roundToSingleDecimal(assists),
    assistsRank,
    blocks: roundToSingleDecimal(blocks),
    blocksRank,
    defensiveReb: roundToSingleDecimal(defensiveReb),
    defensiveRebRank,
    fieldGoalsAttempted: roundToSingleDecimal(fieldGoalsAttempted),
    fieldGoalsAttemptedRank,
    fieldGoalsMade: roundToSingleDecimal(fieldGoalsMade),
    fieldGoalsMadeRank,
    fieldGoalsPercentage: roundToSingleDecimal(fieldGoalsPercentage),
    fieldGoalsPercentageRank,
    freeThrowsAttempted: roundToSingleDecimal(freeThrowsAttempted),
    freeThrowsAttemptedRank,
    freeThrowsMade: roundToSingleDecimal(freeThrowsMade),
    freeThrowsMadeRank,
    freeThrowsPercentage: roundToSingleDecimal(freeThrowsPercentage),
    freeThrowsPercentageRank,
    gamesPlayed: roundToSingleDecimal(gamesPlayed),
    gamesPlayedRank,
    offensiveReb: roundToSingleDecimal(offensiveReb),
    offensiveRebRank,
    personalFouls: roundToSingleDecimal(personalFouls),
    personalFoulsRank,
    points: roundToSingleDecimal(points),
    pointsRank,
    position,
    steals: roundToSingleDecimal(steals),
    stealsRank,
    threePointsFieldGoalsAttempted: roundToSingleDecimal(threePointsFieldGoalsAttempted),
    threePointsFieldGoalsAttemptedRank,
    threePointsFieldGoalsMade: roundToSingleDecimal(threePointsFieldGoalsMade),
    threePointsFieldGoalsMadeRank,
    threePointsFieldGoalsPercentage: roundToSingleDecimal(threePointsFieldGoalsPercentage),
    threePointsFieldGoalsPercentageRank,
    totalRebounds: roundToSingleDecimal(totalRebounds),
    totalReboundsRank,
    turnovers: roundToSingleDecimal(turnovers),
    turnoversRank,
  };
}
