export const trimEditorContent = (content: Array<unknown>) => {
  const firstIterationResult = [];
  const secondIterationResult = [];
  let isNeedCheck = true;

  if (Array.isArray(content) && content.length > 0) {
    for (let i = 0; i < content.length; i += 1) {
      let preparedNode = content[i];

      if (i === 0 || i === content.length - 1) {
        preparedNode = trimMobileNode(content[i]);
      }

      if (isNeedCheck) {
        if (
          (Array.isArray(preparedNode.children) &&
            preparedNode.children.length &&
            preparedNode.type === 'paragraph' &&
            preparedNode.children[0]?.type === 'text' &&
            preparedNode.children[0]?.text?.trim()?.length) ||
          preparedNode.type === 'list' ||
          (preparedNode.children.length && content[i].children[0]?.type === 'link') ||
          preparedNode.children[0]?.type === 'autolink' ||
          preparedNode.type === 'quote' ||
          preparedNode.type === 'heading' ||
          preparedNode.children[0]?.type === 'linebreak'
        ) {
          firstIterationResult.push(preparedNode);
          isNeedCheck = false;
        }
      } else {
        firstIterationResult.push(preparedNode);
      }
    }

    isNeedCheck = true;
    for (let j = firstIterationResult.length - 1; j >= 0; j -= 1) {
      if (isNeedCheck) {
        if (
          (Array.isArray(firstIterationResult[j].children) &&
            firstIterationResult[j].children.length &&
            firstIterationResult[j].type === 'paragraph' &&
            firstIterationResult[j].children[0]?.type === 'text' &&
            firstIterationResult[j].children[0]?.text?.trim()?.length) ||
          firstIterationResult[j].type === 'list' ||
          (firstIterationResult[j].children.length &&
            firstIterationResult[j].children[0]?.type === 'link') ||
          firstIterationResult[j].children[0]?.type === 'autolink' ||
          firstIterationResult[j].type === 'quote' ||
          firstIterationResult[j].type === 'heading' ||
          firstIterationResult[j].children[0]?.type === 'linebreak'
        ) {
          secondIterationResult.push(firstIterationResult[j]);
          isNeedCheck = false;
        }
      } else {
        secondIterationResult.push(firstIterationResult[j]);
      }
    }

    return secondIterationResult.reverse();
  }

  return [];
};

const trimMobileNode = (node: any) => {
  if (!node.children.length) {
    return node;
  }

  let startIndex = 0;
  while (startIndex < node.children.length && node.children[startIndex].type === 'linebreak') {
    startIndex++;
  }

  let endIndex = node.children.length - 1;
  while (endIndex >= 0 && node.children[endIndex].type === 'linebreak') {
    endIndex--;
  }

  const updatedChildren = node.children.slice(startIndex, endIndex + 1);

  return {
    ...node,
    children: updatedChildren,
  };
};
