import { useCallback, useEffect, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { LayoutStore } from 'stores/layout/layout.store';
import { GuideStorageKeys } from 'stores/player/enums/guide-storage-keys.enum';
import { PlayerStore } from 'stores/player/player.store';
import { SeasonsStore } from 'stores/seasons/seasons.store';
import { SplitsGamesStore } from 'stores/splits-games/splits-games.store';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';

import { useTeamClick } from 'hooks/use-team-click';

import { PlayerProfileGames } from 'components/player-profile/player-profile-games/player-profile-games.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from '../player-profile.module.less';

export const PlayerProfileGamesContainer = observer(() => {
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const seasonsStore = useInjection<SeasonsStore>(TYPES.SeasonsStore);
  const splitsGamesStore = useInjection<SplitsGamesStore>(TYPES.SplitsGamesStore);

  const handleTeamClick = useTeamClick();

  const handleSeasonChange = useCallback(
    (value: string) => {
      const seasonOption = seasonsStore.seasonsSelectOptions.find(
        (season) => season.value === value,
      );

      if (seasonOption) {
        playerStore.setSeasonOption(seasonOption);

        GoogleAnalyticService.event({
          eventName: 'button_custom_tap',
          eventParams: {
            button_tap_type: ButtonTapsEnum.PlayerProfileGamesFilterSeason,
            season_timeframe: seasonOption.label,
          },
        });
      }
    },
    [playerStore, seasonsStore.seasonsSelectOptions],
  );

  const handleSelectedTeamIdChange = useCallback(
    (value: Maybe<number>) => {
      splitsGamesStore.setSelectedTeamId(value);
    },
    [splitsGamesStore],
  );

  const handleSplitSizeChange = useCallback(
    (splitSize: Maybe<number>) => {
      const details = playerStore.playerDetails;
      const seasonOption = seasonsStore.currentSeasonOption;

      splitsGamesStore.setSplitSize(splitSize);

      if (details && seasonOption && splitSize) {
        const { firstname, lastname } = details;

        GoogleAnalyticService.event({
          eventName: 'button_custom_tap',
          eventParams: {
            button_tap_type: ButtonTapsEnum.PlayerProfileGamesFilterNumberGames,
            player: `${firstname} ${lastname}`,
            season_timeframe: seasonOption.label,
            num_games_filtered: splitSize,
          },
        });
      }
    },
    [splitsGamesStore, playerStore.playerDetails, seasonsStore.currentSeasonOption],
  );

  const handleGamesGuideTooltipChange = useCallback(
    (key: GuideStorageKeys) => {
      playerStore.changeGuideTooltipState(key);
    },
    [playerStore],
  );

  const selectedTeamInfo = useMemo<Maybe<ITeamsStats>>(() => {
    if (!splitsGamesStore.selectedTeamId) {
      return null;
    }

    return teamsStatsStore.findTeamById(splitsGamesStore.selectedTeamId);
  }, [splitsGamesStore.selectedTeamId, teamsStatsStore]);

  useEffect(() => {
    splitsGamesStore.fetchSplitsMonths();
    playerStore.retrievePlayerSplits();
  }, [splitsGamesStore, playerStore]);

  useEffect(() => {
    if (playerStore.playerDetails && seasonsStore.currentSeasonOption) {
      const { firstname, lastname } = playerStore.playerDetails;

      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.PlayerProfileGames,
          player: `${firstname} ${lastname}`,
          season_timeframe: seasonsStore.currentSeasonOption.label,
        },
      });
    }
  }, [playerStore.playerDetails, seasonsStore.currentSeasonOption]);

  if (!playerStore.seasonOption || splitsGamesStore.isFetchingSplits) {
    return (
      <div className={styles.LoaderWrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    <PlayerProfileGames
      isGamesGuideTooltipShown={playerStore.isGuideTooltipGamesShown}
      splitSize={splitsGamesStore.splitSize}
      selectedTeamId={splitsGamesStore.selectedTeamId}
      selectedTeamInfo={selectedTeamInfo}
      selectedSeason={playerStore.seasonOption}
      seasonOptions={seasonsStore.seasonsSelectOptions}
      splitsMonths={splitsGamesStore.splitsMonths}
      fetchingSplitGames={splitsGamesStore.fetching}
      player={playerStore.playerDetails}
      setDisabledScroll={layoutStore.setDisabledScroll}
      onSeasonChange={handleSeasonChange}
      onTeamClick={handleTeamClick}
      onChangeSelectedTeamId={handleSelectedTeamIdChange}
      onChangeSplitSize={handleSplitSizeChange}
      teamsOptions={teamsStatsStore.getTeamsSelectOptionsForGames()}
      onChangeGamesGuideTooltip={handleGamesGuideTooltipChange}
    />
  );
});
