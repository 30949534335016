import { FC, memo, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import styles from './team-player-label.module.less';

export interface ITeamPlayerLabelProps {
  team: Maybe<ITeamsStats>;
  player: Maybe<IPlayerStats>;
  isNeedLowOpacity?: boolean;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
}

export const TeamPlayerLabel: FC<ITeamPlayerLabelProps> = memo((props: ITeamPlayerLabelProps) => {
  const { team, player, isNeedLowOpacity = false, onTeamClick, onPlayerClick } = props;

  const teamPlayerLabel = useMemo(
    () =>
      cn(styles.TeamPlayerLabel, {
        [styles['TeamPlayerLabel--no-color']]: !player?.color,
      }),
    [player?.color],
  );

  const playerLabelClassNames = useMemo(
    () =>
      cn(styles.TeamPlayerLabel__Player, {
        [styles['TeamPlayerLabel__Player--single']]: !team,
      }),
    [team],
  );

  const separatorStyles = useMemo(() => {
    if (!player) {
      return {};
    }

    return {
      backgroundColor: isNeedLowOpacity ? player.colorWithOpacity : player.color,
    };
  }, [player, isNeedLowOpacity]);

  const teamStyles = useMemo(() => {
    if (!team) {
      return {};
    }

    return {
      background: isNeedLowOpacity ? team.colorWithOpacity : team.color,
      color: !isNeedLowOpacity ? team.secondaryTextColor : undefined,
    };
  }, [team, isNeedLowOpacity]);

  const playerStyles = useMemo(() => {
    if (!player || !player.color) {
      return {};
    }

    return {
      backgroundColor: isNeedLowOpacity ? player.colorWithOpacity : player.color,
      color: player.fontColor,
    };
  }, [player, isNeedLowOpacity]);

  const handleTeamClick = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      if (team) {
        onTeamClick(team.id);
      }
    },
    [onTeamClick, team],
  );

  const handlePlayerClick = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      if (player) {
        onPlayerClick(player.slug);
      }
    },
    [onPlayerClick, player],
  );

  if (team || player) {
    return (
      <div className={teamPlayerLabel}>
        {team && player && player.color && (
          <div style={separatorStyles} className={styles.TeamPlayerLabel__Separator} />
        )}
        {team && (
          <button
            className={styles.TeamPlayerLabel__Team}
            style={teamStyles}
            onClick={handleTeamClick}
          >
            <img src={team.smallLogoUrl} alt={team.code} />
            <span>{team.code}</span>
          </button>
        )}
        {player && (
          <button
            className={playerLabelClassNames}
            style={playerStyles}
            onClick={handlePlayerClick}
          >
            {player.firstname} {player.lastname}
          </button>
        )}
      </div>
    );
  }

  return null;
});
