import { memo } from 'react';
import cn from 'classnames';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import {
  IconButtonWithText,
  IconButtonWithTextTheme,
} from 'components/ui/icon-button-with-text/icon-button-with-text.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './bookmarks-header.module.less';

export interface IBookmarksHeaderProps {
  filtersCount: number;
  isFilterOpen: boolean;
  isForSidePanel: boolean;
  isSearchActive: boolean;
  onSearchButtonClick: () => void;
  onFilterButtonClick: () => void;
  onBackButtonClick: () => void;
  onCloseButtonClick: () => void;
}

export const BookmarksHeader = memo((props: IBookmarksHeaderProps) => {
  const { isForSidePanel, filtersCount, isFilterOpen, isSearchActive } = props;

  return isForSidePanel ? (
    <div className={styles.Header}>
      {isFilterOpen && (
        <div className={styles.Header__Filter}>
          <IconButton
            theme={IconButtonTheme.Transparent}
            iconName={IconFontName.ChevronLeft}
            onClick={props.onBackButtonClick}
          />
          <div className={styles.Title}>Filter</div>
        </div>
      )}
      {!isFilterOpen && (
        <div className={styles.Header__Main}>
          <div className={styles.Title}>Bookmarks</div>
          <div className={styles.Actions}>
            <div
              className={cn(
                styles.Actions__Search,
                isSearchActive ? styles['Actions__Search--active'] : '',
              )}
            >
              <IconButton
                theme={IconButtonTheme.Transparent}
                iconName={IconFontName.Search}
                onClick={props.onSearchButtonClick}
              />
            </div>
            <div className={styles.Header__Filter}>
              {!!filtersCount && (
                <IconButtonWithText
                  theme={IconButtonWithTextTheme.Primary}
                  onClick={props.onFilterButtonClick}
                  iconName={IconFontName.Filter}
                  text={filtersCount ? String(filtersCount) : ''}
                />
              )}
              {!filtersCount && (
                <IconButton
                  theme={IconButtonTheme.Transparent}
                  iconName={IconFontName.Filter}
                  onClick={props.onFilterButtonClick}
                />
              )}
            </div>
            <div className={styles.Header__Close}>
              <IconButton
                theme={IconButtonTheme.Transparent}
                iconName={IconFontName.Close}
                onClick={props.onCloseButtonClick}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className={styles.HeaderProfile}>
      <div className={styles.HeaderProfile__Label}>Bookmarks - All</div>
      <div className={styles.HeaderProfile__Filter}>
        {!!filtersCount && (
          <IconButtonWithText
            theme={IconButtonWithTextTheme.Primary}
            onClick={props.onFilterButtonClick}
            iconName={IconFontName.Filter}
            text={filtersCount ? String(filtersCount) : ''}
          />
        )}
        {!filtersCount && (
          <IconButton
            theme={IconButtonTheme.Transparent}
            iconName={IconFontName.Filter}
            onClick={props.onFilterButtonClick}
          />
        )}
      </div>
    </div>
  );
});
