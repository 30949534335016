import { FC, memo, PropsWithChildren, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './sidebar-following-item.module.less';

export interface ISidebarFollowingItemProps {
  imageSrc: string;
  name: string;
  slug: string;
  isSmall?: boolean;
  onClick: (id: string) => void;
}

export const SidebarFollowingItem: FC<ISidebarFollowingItemProps> = memo(
  (props: PropsWithChildren<ISidebarFollowingItemProps>) => {
    const { onClick, imageSrc, name, slug, isSmall = false } = props;

    const handleJoinButtonClick = useCallback(
      (event: SyntheticEvent) => {
        event.stopPropagation();
        onClick(slug);
      },
      [onClick, slug],
    );

    const itemClassNames = useMemo(
      () =>
        cn(styles.Item, {
          [styles['Item--small']]: isSmall,
        }),
      [isSmall],
    );

    return (
      <div className={itemClassNames}>
        <button onClick={handleJoinButtonClick}>
          {!!imageSrc && <img src={imageSrc} alt={name || ''} />}
        </button>
        {!!name && !isSmall && <div className={styles.Item__Name}>{name}</div>}
      </div>
    );
  },
);
