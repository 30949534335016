import { PlayerRankingsCategoryEnum } from 'services/player-rankings/interfaces/player-rankings-response.interface';

import { IPlayerRankingsItem } from '../interfaces/player-rankings.interface';

export const getPointsByCategory = (
  stats: IPlayerRankingsItem,
  categorySlug: PlayerRankingsCategoryEnum,
): number => {
  if (categorySlug === PlayerRankingsCategoryEnum.performers) {
    return stats.fantasyPoints;
  }

  return stats[categorySlug];
};
