import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import { IFollow } from 'stores/follow/interfaces/follow.interface';
import { ITeam } from 'stores/teams/interfaces/team.interface';

import { BarActionLink } from 'components/bars/bar-action.type';
import { SidebarCommunitiesList } from 'components/sidebars/sidebar-communities-list/sidebar-communities-list.component';
import { MAX_UNEXPANDED_ITEMS_LENGTH } from 'components/sidebars/sidebar-desktop/sidebar-desktop.component';
import { IconButton } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './sidebar-following-section-desktop.module.less';

export interface ISidebarFollowingSectionDesktopProps {
  isAuthorized: boolean;
  isExpand: boolean;
  teamId: Maybe<number>;
  activePath: string;
  followedTeams: ITeam[];
  followingPlayers: IFollow[];
  onTeamClick: (actionLink: BarActionLink) => void;
  onExpandClick: () => void;
}

const MAX_ITEMS_IN_COLLAPSED_STATE = 4;

export const SidebarFollowingSectionDesktop: FC<ISidebarFollowingSectionDesktopProps> = memo(
  (props: ISidebarFollowingSectionDesktopProps) => {
    const { isExpand, followingPlayers, isAuthorized, teamId, followedTeams, activePath } = props;

    const itemsLength = useMemo(
      () => followedTeams.length + followingPlayers.length,
      [followingPlayers, followedTeams],
    );

    const wrapperClassNames = useMemo(
      () =>
        cn(styles.FollowSection, {
          [styles['FollowSection--expand']]: isExpand,
          [styles['FollowSection--with-shadow']]:
            !isExpand && itemsLength > MAX_UNEXPANDED_ITEMS_LENGTH,
        }),
      [isExpand, itemsLength],
    );

    return (
      <div className={wrapperClassNames}>
        <SidebarCommunitiesList
          isAuthorized={isAuthorized}
          isExploreList={false}
          activePath={activePath}
          teams={followedTeams}
          onClick={props.onTeamClick}
          teamId={teamId}
          followingPlayers={followingPlayers}
        />
        {!isExpand && itemsLength > MAX_ITEMS_IN_COLLAPSED_STATE && (
          <div className={styles.FollowSection__Expander}>
            <IconButton
              iconSize={IconFontSize.ExtraBig}
              iconName={isExpand ? IconFontName.ChevronUp : IconFontName.ChevronDown}
              onClick={props.onExpandClick}
            />
          </div>
        )}
      </div>
    );
  },
);
