import React, { memo } from 'react';

import { IO_LOG_COLORS_MAP } from 'stores/io/constants/io-log.constants';
import { IIoLogEntry } from 'stores/io/interfaces/io-log-entry.interace';

import { Portal, PortalType } from 'components/ui/portal/portal.component';

import styles from './logger.module.less';

interface ILoggerProps {
  entries: IIoLogEntry[];
  onClearLogger: () => void;
  onCopyAllLogs: () => void;
}

export const Logger = memo((props: ILoggerProps) => {
  const { entries } = props;

  return (
    <Portal type={PortalType.Debug}>
      <div className={styles.Logger}>
        <table>
          <tbody>
            {entries.map((entry) => {
              const { isOut, id, value, color, name, time } = entry;

              return (
                <React.Fragment key={id}>
                  <tr style={{ backgroundColor: IO_LOG_COLORS_MAP[color] }}>
                    <th>
                      {time} {isOut ? '>' : '<'}
                    </th>
                    <th>{name}</th>
                  </tr>
                  {!!value && (
                    <tr style={{ backgroundColor: IO_LOG_COLORS_MAP[color] }}>
                      <td colSpan={2}>{value}</td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <div className={styles.Buttons}>
          <button onClick={props.onCopyAllLogs}>Copy All</button>
          <button onClick={props.onClearLogger}>Clear All</button>
        </div>
      </div>
    </Portal>
  );
});
