import { FC, SyntheticEvent, useMemo } from 'react';
import cn from 'classnames';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { IGame } from 'services/statistics/interface/game.interface';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './game-result.module.less';

interface IGameResultProps {
  game: IGame;
  isHomeTeamPlayer: boolean;
  onClick: (event: SyntheticEvent) => void;
}

export const GameResult: FC<IGameResultProps> = (props: IGameResultProps) => {
  const { game, isHomeTeamPlayer } = props;

  const homeTeamClassNames = useMemo<string>(
    () =>
      cn({
        [styles.Winner]:
          game.status === GameStatus.Finished &&
          game.teams.visitors.score.points < game.teams.home.score.points,
      }),
    [game.teams, game.status],
  );

  const visitorsTeamClassNames = useMemo<string>(
    () =>
      cn({
        [styles.Winner]:
          game.status === GameStatus.Finished &&
          game.teams.visitors.score.points > game.teams.home.score.points,
      }),
    [game.teams, game.status],
  );

  return (
    <div className={styles.Result}>
      <Avatar
        size={AvatarSize.XXS}
        username={isHomeTeamPlayer ? game.teams.home.name : game.teams.visitors.name}
        src={isHomeTeamPlayer ? game.teams.home.smallLogoUrl : game.teams.visitors.smallLogoUrl}
      />
      <div
        role="button"
        tabIndex={0}
        onKeyDown={props.onClick}
        className={styles.Result__Points}
        onClick={props.onClick}
      >
        <span className={visitorsTeamClassNames}>
          {isHomeTeamPlayer ? game.teams.home.score.points : game.teams.visitors.score.points}
        </span>
        <span>-</span>
        <span className={homeTeamClassNames}>
          {isHomeTeamPlayer ? game.teams.visitors.score.points : game.teams.home.score.points}
        </span>
      </div>
      <span className={styles.Result__Matcher}>{isHomeTeamPlayer ? '@' : 'vs'}</span>
      <Avatar
        size={AvatarSize.XXS}
        username={!isHomeTeamPlayer ? game.teams.home.name : game.teams.visitors.name}
        src={!isHomeTeamPlayer ? game.teams.home.smallLogoUrl : game.teams.visitors.smallLogoUrl}
      />
    </div>
  );
};
