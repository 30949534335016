import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { Avatar, AvatarSize } from '../avatar/avatar.component';

import { IStatsBoxRank } from './interfaces/stats-box.interface';

import styles from './stats-box.module.less';

export enum StatsBoxTheme {
  Transparent,
  Dark,
  Light,
  Team,
}

interface StatsBoxProps {
  theme: StatsBoxTheme;
  title: string;
  content?: Maybe<string>;
  sideContent?: string;
  avatarUrl?: string;
  borderColor?: Maybe<string>;
  rank?: IStatsBoxRank;
  onClick?: () => void;
}

export const StatsBox: FC<StatsBoxProps> = (props: StatsBoxProps) => {
  const { theme, title, content, sideContent, avatarUrl, borderColor, rank, onClick } = props;

  const boxStyle = useMemo(() => {
    if (borderColor) {
      return { border: `solid 0.1rem ${borderColor}` };
    }

    return {};
  }, [borderColor]);

  const handleBoxClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  const boxClassNames = useMemo(
    () =>
      cn(styles.StatsBox, {
        [styles['StatsBox--theme-transparent']]: theme === StatsBoxTheme.Transparent,
        [styles['StatsBox--theme-dark']]: theme === StatsBoxTheme.Dark,
        [styles['StatsBox--theme-light']]: theme === StatsBoxTheme.Light,
        [styles['StatsBox--theme-team']]: theme === StatsBoxTheme.Team,
        [styles['StatsBox--clickable']]: !!onClick,
      }),
    [theme, onClick],
  );

  const rankClassNames = useMemo(() => {
    return cn(styles.StatsBox__Rank, {
      [styles['StatsBox__Rank--strong']]: rank && rank.value <= rank.limits.low,
      [styles['StatsBox__Rank--middle']]:
        rank && rank.value > rank.limits.low && rank.value <= rank.limits.high,
      [styles['StatsBox__Rank--weak']]: rank && rank.value > rank.limits.high,
    });
  }, [rank]);

  const mainClassNames = useMemo(() => cn({ [styles.StatsBox__Main]: !!avatarUrl }), [avatarUrl]);

  return (
    <div role="presentation" className={boxClassNames} style={boxStyle} onClick={handleBoxClick}>
      <div className={styles.StatsBox__Title}>{title}</div>
      <div className={mainClassNames}>
        {avatarUrl && <Avatar size={AvatarSize.S} username={title} src={avatarUrl} />}
        {content && <div className={styles.StatsBox__Content}>{content}</div>}
        {sideContent && <div className={styles.StatsBox__Side}>{sideContent}</div>}
      </div>
      {!!rank && <div className={rankClassNames}>#{rank.value}</div>}
    </div>
  );
};
