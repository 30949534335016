import { FC, memo, useMemo } from 'react';

import { ColumnChart } from '../column-chart/column-chart.component';

import styles from './column-charts.module.less';

export interface IColumnChartItem {
  id: string;
  avatarUrl: Maybe<string>;
  name: string;
  value: number;
  color: string;
}

interface IColumnChartsProps {
  sortedItems: IColumnChartItem[];
}

export const ColumnCharts: FC<IColumnChartsProps> = memo((props: IColumnChartsProps) => {
  const { sortedItems } = props;

  const maxValue = useMemo(() => {
    const values = sortedItems.map((item) => item.value);

    return Math.max(...values);
  }, [sortedItems]);

  const minValue = useMemo(() => {
    const values = sortedItems.map((item) => item.value);

    return Math.min(...values);
  }, [sortedItems]);

  return (
    <div className={styles.ColumnCharts}>
      {sortedItems.map((item) => (
        <ColumnChart key={item.id} maxValue={maxValue} minValue={minValue} {...item} />
      ))}
    </div>
  );
});
