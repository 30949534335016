import { useCallback, useMemo, useState } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AuthStore } from 'stores/auth/auth.store';
import { IUpdateUserParams } from 'stores/auth/interfaces/update-user.params.interface';
import { LayoutStore } from 'stores/layout/layout.store';
import { PostsStore } from 'stores/posts/posts.store';
import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { JoinTeamContainer } from 'containers/join-team/join-team.container';

import { NULL_PLAYER_OPTION_COLOR } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';

import { SelectTeamAndPlayer } from 'components/forms/select-team-and-player/select-team-and-player-form.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { IInitialTeamAndPlayer } from 'components/profile/profile-info/interfaces/initial-team-and-player.interface';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './feed-actions.module.less';

export const FeedActionsContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);

  const userData = authStore.userMe;

  const { teamsThumbnails, players, playersSelectOptions } = teamsStatsStore;

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);

  const handleUpdateUser = useCallback(
    async (updateUserParams: IUpdateUserParams) => {
      setIsUpdatingUser(true);
      await authStore.updateUserMe(updateUserParams);
      setIsUpdatingUser(false);
    },
    [authStore],
  );

  const favTeamId = authStore.userMe?.profile.favoriteTeamId || null;

  const favPlayerId = authStore.userMe?.profile.favoritePlayerId || null;

  const favoritePlayer = useMemo(
    () => players.find((player) => player.id === favPlayerId),
    [favPlayerId, players],
  );

  const handleSubmitTeamAndPlayer = useCallback(
    async (data: IInitialTeamAndPlayer) => {
      const payload = {
        favoriteTeamId: data.teamId,
        favoritePlayerId: data.playerId,
      };

      if (
        data.teamId &&
        userData?.profile.thumbnailUrl &&
        !teamsThumbnails.includes(userData.profile.thumbnailUrl)
      ) {
        authStore.setShouldApplyFavoriteTeamBackground(false);

        if (data.teamId === userData?.profile?.favoriteTeamId) {
          setConfirmationModalOpen(false);
        } else {
          setConfirmationModalOpen(true);
        }
      }

      await handleUpdateUser(payload);
      await postsStore.forceFetchToRefreshFeed();
    },
    [userData, teamsThumbnails, authStore, postsStore, handleUpdateUser],
  );

  const initialTeamAndPlayer = useMemo(() => {
    const teamAndPlayer: IInitialTeamAndPlayer = {};
    if (favTeamId) {
      teamAndPlayer.teamId = favTeamId;
    }
    if (favPlayerId) {
      teamAndPlayer.playerId = favPlayerId;
    }

    return teamAndPlayer;
  }, [favTeamId, favPlayerId]);

  const memoizedInitialPlayerColor = useMemo(() => {
    if (favoritePlayer) {
      return favoritePlayer.color;
    }
    return NULL_PLAYER_OPTION_COLOR;
  }, [favoritePlayer]);

  const loading = useMemo(
    () => (authStore.fetching && isUpdatingUser) || teamsStore.fetching,
    [authStore.fetching, teamsStore.fetching, isUpdatingUser],
  );

  const handleCloseConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);

  const handleConfirmationSuccess = useCallback(() => {
    authStore.setShouldApplyFavoriteTeamBackground(true);
    setConfirmationModalOpen(false);
    authStore.updateUserMe({ favoriteTeamId: favTeamId });
  }, [authStore, favTeamId]);

  return (
    <div className={styles.HomeFeedActions}>
      {loading ? (
        <Loader isShow={loading} withBackground />
      ) : (
        <>
          {authStore.isAuthorised && (!userData || !favTeamId || !favPlayerId) ? (
            <>
              <SelectTeamAndPlayer
                teamsOptions={teamsStatsStore.getTeamsSelectOptions('Team')}
                playersOptions={playersSelectOptions}
                initialValues={initialTeamAndPlayer}
                initialFavPlayerColor={memoizedInitialPlayerColor}
                onSubmit={handleSubmitTeamAndPlayer}
                setDisabledScroll={layoutStore.setDisabledScroll}
              />
              <ConfirmationModal
                onClose={handleCloseConfirmationModal}
                visible={confirmationModalOpen}
                onSuccessCallback={handleConfirmationSuccess}
                title="Update Background"
                content="Would you like to change your custom background to the team's related one?"
                primaryButtonText="Yes"
                secondaryButtonText="No"
              />
            </>
          ) : null}
          <JoinTeamContainer />
        </>
      )}
    </div>
  );
});
