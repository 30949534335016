import { ICommentResponse } from 'services/comments/interfaces/comments.interface';

import { IComment } from 'stores/comments/interfaces/comments.interface';
import { gifAdapter } from 'stores/posts/adapters/gif-adapter.util';
import { imageAdapter } from 'stores/posts/adapters/image-adapter.util';
import { linkAdapter } from 'stores/posts/adapters/link-adapter.util';
import { pollAdapter } from 'stores/posts/adapters/poll-adapter.util';
import { prepareReactions } from 'stores/posts/adapters/publication-adapter.util';
import { videoAdapter } from 'stores/posts/adapters/video-adapter.util';
import {
  IGifAttachment,
  IImageAttachment,
  ILinkAttachment,
  IPollAttachment,
  IVideoAttachment,
} from 'stores/posts/interfaces/post.interface';

import { formatPastDate } from 'helpers/format/format-past-date.util';

export function commentAdapter(postResponse: ICommentResponse): IComment {
  const {
    replying_uuid: replyingUuid,
    is_bookmarked: isBookmarked,
    title,
    uuid,
    content,
    date_created: timestamp,
    comments_amount: commentsAmount,
    bookmarks_count: bookmarksCount,
    shares_count: sharesCount,
    votes_total: votesTotal,
    user,
    reactions_total: reactionsTotal,
    attachments,
    post_comments_amount: postCommentsAmount,
  } = postResponse;

  const convertedImages: Maybe<IImageAttachment[]> = attachments.images.length
    ? attachments.images.map(imageAdapter)
    : null;

  const convertedVideos: Maybe<IVideoAttachment[]> = attachments.videos.length
    ? attachments.videos.map(videoAdapter)
    : null;

  const convertedGifs: Maybe<IGifAttachment[]> = attachments.gifs.length
    ? attachments.gifs.map(gifAdapter)
    : null;

  const convertedPoll: Maybe<IPollAttachment> = attachments.poll
    ? { ...pollAdapter(attachments.poll) }
    : null;

  const convertedLinks: ILinkAttachment[] = attachments.links.length
    ? attachments.links.map(linkAdapter)
    : [];

  const isExistMediaAttachments: boolean = !!(
    convertedImages?.length ||
    convertedVideos?.length ||
    convertedGifs?.length
  );

  const filteredLinks: ILinkAttachment[] = convertedLinks.map((item) => {
    const updatedItem = { ...item };

    if (isExistMediaAttachments) {
      updatedItem.isEmbedded = false;
    }

    return updatedItem;
  });

  const embeddedLinks = isExistMediaAttachments
    ? []
    : filteredLinks.filter((item) => item.isEmbedded);

  const notEmbeddedLinks = isExistMediaAttachments
    ? convertedLinks
    : convertedLinks.filter((item) => !item.isEmbedded);

  return {
    isRepliesFetched: false,
    page: 1,
    commentsAmount,
    replyingUuid: replyingUuid || null,
    isBookmarked: !!isBookmarked,
    bookmarksCount,
    sharesCount,
    formattedDates: {
      timeOnly: formatPastDate(timestamp, 'timeOnly'),
      relativeLong: formatPastDate(timestamp, 'relativeLong'),
      relativeShort: formatPastDate(timestamp, 'relativeShort'),
      full: formatPastDate(timestamp),
    },
    votesTotal,
    reactionsTotal: prepareReactions(reactionsTotal),
    uuid,
    title,
    content,
    user: {
      smallAvatarUrl: user.small_avatar_url,
      smallThumbnailUrl: user.small_thumbnail_url,
      uuid: user.uuid,
      avatarUrl: user.avatar_url,
      name: user.real_name,
      username: user.username,
      favoriteTeamId: user.favorite_team_id,
      favoritePlayerId: user.favorite_player_id,
      thumbnailLogoUrl: user.thumbnail_logo_url,
      thumbnailNicknameUrl: user.thumbnail_nickname_url,
    },
    attachments: {
      images: convertedImages,
      poll: convertedPoll,
      videos: convertedVideos,
      gifs: convertedGifs,
      links: notEmbeddedLinks,
    },
    comments: [],
    embeddedLink: embeddedLinks.length ? embeddedLinks[0] : null,
    postCommentsAmount,
    contentCard: null,
    feeds: {
      teams: [],
      players: [],
      feed: null,
    },
  };
}
