import { memo, useMemo } from 'react';
import cn from 'classnames';

import { ENCODED_BULLET } from 'configs/encoded-symbols.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { ISplitForOneMonth } from 'components/player-profile/player-profile-games/player-profile-games.interface';
import { GuideTooltipPositions } from 'components/ui/guide-tooltip/enums/guide-tooltip-positions.enum';
import { GuideTooltip } from 'components/ui/guide-tooltip/guide-tooltip.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './month-header-item.module.less';

interface MonthHeaderItemProps {
  isOpen: boolean;
  isCompactView: boolean;
  isSplitByMonth: boolean;
  isGuideTooltipShown: boolean;
  monthData: ISplitForOneMonth;
  onMonthClick: () => void;
  onHeaderRowClick: () => void;
}

export const MonthHeaderItem = memo((props: MonthHeaderItemProps) => {
  const { isOpen, isSplitByMonth, isCompactView, isGuideTooltipShown, monthData } = props;

  const { month, totalGames, win, loss, away, home } = monthData;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const formattedTitle = useMemo(() => {
    if (month) {
      return month.slice(0, 3).toUpperCase();
    }

    return '';
  }, [month]);

  const circleClasses = useMemo(() => {
    return cn(styles.Circle, {
      [styles['Circle--close']]: !isOpen,
    });
  }, [isOpen]);

  const monthHeaderItemClassNames = useMemo(() => {
    return cn(styles.MonthHeaderItem, {
      [styles['MonthHeaderItem--compact']]: isCompactView,
    });
  }, [isCompactView]);

  const infoClasses = useMemo(
    () =>
      cn(styles.Info, {
        [styles['Info--compact']]: isCompactView,
      }),
    [isCompactView],
  );

  const gamesLabel = useMemo(() => {
    if (totalGames === 1) {
      return 'game';
    }

    return 'games';
  }, [totalGames]);

  const guideTooltipPosition = useMemo(() => {
    if (isDesktopPlus) {
      return GuideTooltipPositions.Center;
    }

    return GuideTooltipPositions.Right;
  }, [isDesktopPlus]);

  const toggleText = useMemo(() => {
    if (isSplitByMonth) {
      return formattedTitle;
    }

    return totalGames;
  }, [isSplitByMonth, formattedTitle, totalGames]);

  return (
    <div className={monthHeaderItemClassNames}>
      <GuideTooltip
        isShown={isGuideTooltipShown}
        text="Click to show/hide games"
        position={guideTooltipPosition}
      >
        <button onClick={props.onMonthClick} className={circleClasses}>
          <div className={styles.Text}>{toggleText}</div>
          <div className={styles.Arrow}>
            <IconFont
              name={isOpen ? IconFontName.ChevronUp : IconFontName.ChevronDown}
              size={IconFontSize.Small}
            />
          </div>
        </button>
      </GuideTooltip>
      <button onClick={props.onHeaderRowClick} className={infoClasses}>
        <div className={styles.Info__Text}>{`${totalGames} ${gamesLabel} (${win} - ${loss})`}</div>
        {!!home && <div className={styles.Info__Splitter}>{ENCODED_BULLET}</div>}
        {!!home && <div className={styles.Info__Text}>{`${home} home`}</div>}
        {!!away && <div className={styles.Info__Splitter}>{ENCODED_BULLET}</div>}
        {!!away && <div className={styles.Info__Text}>{`${away} away`}</div>}
      </button>
    </div>
  );
});
