import { FC, PropsWithChildren, useMemo } from 'react';

import {
  BaseModalComponent,
  IModal,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './join-community-modal.module.less';

interface IJoinCommunityModalProps extends IModal {
  processing: boolean;
  avatarSrc: Maybe<string>;
  name: string;
  onJoin: () => void;
}

export const JoinCommunityModal: FC<IJoinCommunityModalProps> = (
  props: PropsWithChildren<IJoinCommunityModalProps>,
) => {
  const { processing, visible, avatarSrc, name } = props;

  const title = useMemo(() => {
    const teamName = name.split(' ')?.reverse()?.[0];
    return `Follow the ${teamName} Team`;
  }, [name]);

  return (
    <BaseModalComponent
      visible={visible}
      size={ModalWindowSize.M}
      title={title}
      onClose={props.onClose}
      closeOnOverlayClick
    >
      <div className={styles.ModalTitle}>
        <Avatar size={AvatarSize.S} src={avatarSrc} username={name} />
        <span className={styles.ModalTitle__Name}>{name}</span>
      </div>
      <div className={styles.ModalContent}>
        To add Posts, to communicate with team members you have to follow the team. It&apos;s
        absolutely free. You can unfollow team whenever you want.
      </div>
      <div className={styles.ModalFooter}>
        <Button
          onClick={props.onClose}
          disabled={processing}
          size={ButtonSize.Small}
          theme={ButtonTheme.Secondary}
          fluid
        >
          Cancel
        </Button>
        <Button
          onClick={props.onJoin}
          disabled={processing}
          size={ButtonSize.Small}
          theme={ButtonTheme.Primary}
          fluid
        >
          Follow
        </Button>
      </div>
    </BaseModalComponent>
  );
};
