import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import {
  CheckboxSize,
  SingleCheckbox,
} from 'components/ui/form-fields/single-checkbox/single-checkbox.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import { IHookFormInput } from '../hook-form-input.interface';

import styles from './multiple-choose-section-checkboxes.module.less';

export interface IMultipleChooseSectionCheckboxesProps extends IHookFormInput {
  selectedItems: ICheckboxItem[];
  sectionTitle: string;
  sectionIconUrl: string;
  openedSectionId: string;
  isSectionEnabled: boolean;
  isDisabled: boolean;
  isMultipleChooseSectionOpen: boolean;
  onToggleCategory: (categoryId: string) => void;
  onToggleCategoryItem: (categoryId: string, tagId: string) => void;
  onSetOpenedSectionId: (sectionId: string) => void;
}

export interface ICheckboxItem {
  uuid: string;
  name: string;
  selected: boolean;
  iconUrl?: string;
}

export const MultipleChooseSectionCheckboxes = memo(
  (props: IMultipleChooseSectionCheckboxesProps) => {
    const {
      id: sectionId,
      sectionTitle,
      sectionIconUrl,
      selectedItems,
      openedSectionId,
      isSectionEnabled,
      isDisabled,
      isMultipleChooseSectionOpen,
      onToggleCategory,
      onToggleCategoryItem,
      onSetOpenedSectionId,
    } = props;

    const multiCheckboxClasses = useMemo(
      () => cn(styles.MultiCheckbox, { [styles['MultiCheckbox--disabled']]: isDisabled }),
      [isDisabled],
    );

    const multiCheckboxListClassName = useMemo(
      () =>
        cn(styles.MultiCheckbox__List, {
          [styles['MultiCheckbox__List--disabled']]: !isSectionEnabled,
        }),
      [isSectionEnabled],
    );

    const handleChangeCategory = useCallback(
      (value: string) => {
        onToggleCategory(value);
      },
      [onToggleCategory],
    );

    const handleChangeCategoryItem = useCallback(
      (tagId: string) => {
        onToggleCategoryItem(sectionId, tagId);
      },
      [sectionId, onToggleCategoryItem],
    );

    const handleToggleShowSection = useCallback(() => {
      if (openedSectionId === sectionId) {
        onSetOpenedSectionId('');
        return;
      }

      onSetOpenedSectionId(sectionId);
    }, [sectionId, openedSectionId, onSetOpenedSectionId]);

    return (
      <div className={multiCheckboxClasses}>
        <div className={styles.MultiCheckbox__FirstRow}>
          <SingleCheckbox
            disableHover
            fullWidth
            size={CheckboxSize.Big}
            key={sectionId}
            id={sectionId}
            label={sectionTitle}
            avatar={{ name: sectionTitle, src: sectionIconUrl }}
            onChange={handleChangeCategory}
            selected={isSectionEnabled}
          />
          <div
            className={styles.MultiCheckbox__ArrowSection}
            tabIndex={0}
            role="button"
            onClick={handleToggleShowSection}
            onKeyDown={handleToggleShowSection}
          >
            <IconFont
              size={IconFontSize.Big}
              name={
                isMultipleChooseSectionOpen
                  ? IconFontName.ChevronBigUp
                  : IconFontName.ChevronBigDown
              }
            />
          </div>
        </div>
        {isMultipleChooseSectionOpen && (
          <ul className={multiCheckboxListClassName}>
            {selectedItems.map(({ uuid, name, selected, iconUrl }) => {
              if (iconUrl) {
                return (
                  <SingleCheckbox
                    key={uuid}
                    disableHover
                    size={CheckboxSize.Big}
                    id={uuid}
                    label={name}
                    avatar={{ name, src: iconUrl }}
                    onChange={handleChangeCategoryItem}
                    selected={selected}
                    disabled={!isSectionEnabled}
                  />
                );
              }

              return (
                <SingleCheckbox
                  key={uuid}
                  disableHover
                  size={CheckboxSize.Big}
                  id={uuid}
                  label={name}
                  onChange={handleChangeCategoryItem}
                  selected={selected}
                  disabled={!isSectionEnabled}
                />
              );
            })}
          </ul>
        )}
      </div>
    );
  },
);
