import { memo } from 'react';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './header-avatar.module.less';

interface IHeaderAvatarProps {
  imageSrc: Maybe<string>;
  username: string;
  onAvatarClick: () => void;
}

export const HeaderAvatar = memo((props: IHeaderAvatarProps) => {
  const { imageSrc, username } = props;

  return (
    <button type="button" className={styles.HeaderAvatar} onClick={props.onAvatarClick}>
      <Avatar size={AvatarSize.S} src={imageSrc} username={username} />
    </button>
  );
});
