import { FC, memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { PlayerRankingsCategoryEnum } from 'services/player-rankings/interfaces/player-rankings-response.interface';

import { getPointsByCategory } from 'stores/player-rankings/helpers/get-points-by-category.util';
import { getRankByCategory } from 'stores/player-rankings/helpers/getRankByCategory.util';
import { IPlayerRankingsItem } from 'stores/player-rankings/interfaces/player-rankings.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import {
  PlayerRankingsAvatar,
  PlayerRankingsAvatarSize,
} from 'components/ui/player-rankings-avatar/player-rankings-avatar.component';

import styles from './rankings-player-item.module.less';

const FIRST_RANKINGS_PLAYER = 3;

export interface IRankingPlayerItemProps {
  player: IPlayerRankingsItem;
  categorySlug: PlayerRankingsCategoryEnum;
  onPlayerClick: (slug: string, gameId: Maybe<string>) => void;
  forDetails?: boolean;
}

export const RankingPlayerItem: FC<IRankingPlayerItemProps> = memo(
  (props: IRankingPlayerItemProps) => {
    const { player, onPlayerClick, categorySlug, forDetails = false } = props;

    const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

    const rank = useMemo(() => {
      return getRankByCategory<IPlayerRankingsItem>(player, categorySlug);
    }, [player, categorySlug]);

    const pointsByCategory = useMemo(() => {
      return getPointsByCategory(player, categorySlug);
    }, [categorySlug, player]);

    const handlePlayerClick = useCallback(() => {
      onPlayerClick?.(player.playerInfo.slug, player.gameId);
    }, [onPlayerClick, player]);

    const rankingsPlayerItemClasses = useMemo(
      () =>
        cn(styles.RankingsPlayerItem, {
          [styles['RankingsPlayerItem--large']]: rank <= FIRST_RANKINGS_PLAYER,
        }),
      [rank],
    );

    const avatarSize = useMemo(() => {
      if (!isDesktopPlus) {
        return rank <= FIRST_RANKINGS_PLAYER
          ? PlayerRankingsAvatarSize.SIZE_56
          : PlayerRankingsAvatarSize.SIZE_48;
      }

      return rank <= FIRST_RANKINGS_PLAYER
        ? PlayerRankingsAvatarSize.SIZE_80
        : PlayerRankingsAvatarSize.SIZE_56;
    }, [rank, isDesktopPlus]);

    return (
      <button className={rankingsPlayerItemClasses} onClick={handlePlayerClick}>
        <div className={styles.RankingsPlayerItem__Avatar}>
          <PlayerRankingsAvatar
            rank={rank}
            size={avatarSize}
            iconUrl={player.playerInfo.smallLogoUrl}
            forDetails={forDetails}
            teamLogoUrl={player.team.smallLogoUrl}
            playerName={player.playerInfo.fullName}
          />
        </div>
        <div className={styles.RankingsPlayerItem__FullName}>{player.playerInfo.fullName}</div>
        <div className={styles.RankingsPlayerItem__Points}>{pointsByCategory}</div>
      </button>
    );
  },
);
