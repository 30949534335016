import { FC, useCallback } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { MediaType } from 'types/media-type.type';

import { GalleryStore } from 'stores/gallery/gallery.store';

import { TYPES } from 'configs/di-types.config';

import { GalleryModal } from 'components/modals/gallery-modal/gallery-modal.component';

export const GalleryContainer: FC = observer(() => {
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);

  const handleClose = useCallback(() => {
    galleryStore.closeGallery();
  }, [galleryStore]);

  const handleDownloadMediaFileToGallery = useCallback(
    async (path: string, mediaType: MediaType) => {
      await galleryStore.downloadMediaFileToGallery(path, mediaType);
    },
    [galleryStore],
  );

  const handleFetchMediaFile = useCallback(
    async (path: string) => {
      return galleryStore.fetchMediaFile(path);
    },
    [galleryStore],
  );

  return (
    <GalleryModal
      setCurrentAttachmentId={galleryStore.setCurrentAttachmentId}
      currentAttachmentId={galleryStore.currentAttachmentId}
      attachments={galleryStore.galleryAttachments}
      author={galleryStore.author}
      visible={galleryStore.isGalleryOpen}
      date={galleryStore.attachmentDate}
      watermarkEntity={galleryStore.watermarkEntity}
      theme={galleryStore.theme}
      onClose={handleClose}
      onDownloadMediaFileToGallery={handleDownloadMediaFileToGallery}
      onFetchMediaFile={handleFetchMediaFile}
    />
  );
});
