import { FC, useMemo } from 'react';
import cn from 'classnames';

import styles from './game-stats-row-item.module.less';

interface IGameProps {
  primaryValue: number;
  isVisitor: boolean;
  isPrimary: boolean;
  secondaryValue?: number;
  percent?: number;
}

export const GameStatsRowItem: FC<IGameProps> = (props) => {
  const { primaryValue, isVisitor, isPrimary, secondaryValue, percent } = props;

  const valueTextSize = useMemo(
    () =>
      cn(styles.AdditionalSection__Value, {
        [styles['AdditionalSection__Value--big']]: isPrimary,
        [styles['AdditionalSection__Value--reverse']]: !isVisitor,
      }),
    [isPrimary, isVisitor],
  );

  const mainSectionClassnames = useMemo(
    () =>
      cn(styles.MainSection, {
        [styles['MainSection--reverse']]: !isVisitor,
      }),
    [isVisitor],
  );

  const additionalSectionClassnames = useMemo(
    () =>
      cn(styles.AdditionalSection, {
        [styles['AdditionalSection--reverse']]: !isVisitor,
      }),
    [isVisitor],
  );

  if (secondaryValue) {
    return (
      <div className={mainSectionClassnames}>
        <div className={styles.MainSection__Value}>
          {primaryValue}/{secondaryValue}
        </div>
        <div className={styles.MainSection__Percent}>{percent}%</div>
      </div>
    );
  }

  return (
    <div className={additionalSectionClassnames}>
      <div className={valueTextSize}>{primaryValue}</div>
    </div>
  );
};
