import { forwardRef, useMemo } from 'react';
import { GridOptions, RowClassParams, SortDirection } from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import cn from 'classnames';

import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { useTablePagination } from 'hooks/use-table-pagination';

import { PaginationPanel } from '../ui/pagination-panel/pagination-panel.component';

import { IHighlightRowOptions } from './interfaces/highlight-row-options.interface';

import 'ag-grid-community/styles/ag-grid.css';
import './ag-theme-base-table.less';

interface IBaseTableGridProps extends AgGridReactProps {
  gridOptions: GridOptions;
  hideHeader?: boolean;
  paginationWithUrlParam?: boolean;
  onRowClick?: (event: { data: any }) => void;
  highlightRowOptions?: Maybe<IHighlightRowOptions>;
  autoScrollColumn?: string;
}

const WRAPPER_CLASS_NAME = 'ag-theme-base-table';
const PAGINATION_CLASS_NAME = 'ag-theme-base-table-pagination';
const SORTED_CELL_CLASS_NAME = 'ag-cell-sorted';
const HIGHLIGHTED_ROW_CLASS_NAME = 'ag-row-highlighted';
const MINIMUM_ROWS_STICKY_HEADER = 3;
const DEFAULT_SORTING_ORDER: SortDirection[] = ['desc', 'asc'];

const DEFAULT_GRID_OPTIONS: GridOptions = {
  animateRows: true,
  suppressCellFocus: true,
  domLayout: 'autoHeight',
  suppressHorizontalScroll: true,
  suppressPaginationPanel: true,
  defaultColDef: {
    suppressMovable: true,
    minWidth: 60,
    flex: 1,
    sortable: false,
    cellClassRules: {
      [SORTED_CELL_CLASS_NAME]: (params) => !!params.column.getSort() && !!params.colDef.sortable,
    },
    headerClass: (params) => (params.column?.getSort() ? SORTED_CELL_CLASS_NAME : undefined),
    sortingOrder: DEFAULT_SORTING_ORDER,
  },
  tooltipShowDelay: 0,
};

export const BaseTableGrid = forwardRef<AgGridReact, IBaseTableGridProps>((props, ref) => {
  const {
    gridOptions,
    hideHeader = false,
    paginationWithUrlParam,
    highlightRowOptions = null,
    autoScrollColumn,
    onGridReady,
  } = props;

  const { currentPage, totalPages, handleGridReady, handlePageClick, handlePaginationChanged } =
    useTablePagination(
      ref,
      onGridReady,
      paginationWithUrlParam,
      highlightRowOptions,
      autoScrollColumn,
    );

  const classNames = useMemo(
    () =>
      cn(WRAPPER_CLASS_NAME, TOUCH_IGNORE_CLASS, {
        [`${WRAPPER_CLASS_NAME}--sticky`]:
          gridOptions.rowData && gridOptions.rowData.length >= MINIMUM_ROWS_STICKY_HEADER,
      }),
    [gridOptions.rowData],
  );

  const paginationClassNames = useMemo<string>(
    () => cn(PAGINATION_CLASS_NAME, TOUCH_IGNORE_CLASS),
    [],
  );

  const hideHeaderProps = hideHeader
    ? {
        suppressAggFuncInHeader: true,
        headerHeight: 0,
      }
    : {};

  const rowClassRules = useMemo(() => {
    return {
      [HIGHLIGHTED_ROW_CLASS_NAME]: (params: RowClassParams) => {
        if (highlightRowOptions) {
          // if the filed is nested object
          // eg. we are looking for the id, but it is nested inside name field
          // field: "name.id"
          const fields = highlightRowOptions.field.split('.');
          const value = fields.reduce((acc, currentValue) => acc[currentValue], params.data);

          return value === highlightRowOptions.value;
        }

        return false;
      },
    };
  }, [highlightRowOptions]);

  return (
    <>
      <div className={classNames}>
        <AgGridReact
          ref={ref}
          {...props}
          {...hideHeaderProps}
          gridOptions={{
            ...DEFAULT_GRID_OPTIONS,
            ...gridOptions,
            rowClassRules,
          }}
          onPaginationChanged={handlePaginationChanged}
          onGridReady={handleGridReady}
          onRowClicked={props.onRowClick}
        />
      </div>
      {gridOptions.pagination && totalPages !== null && (
        <div className={paginationClassNames}>
          <PaginationPanel
            currentPage={currentPage}
            totalPages={totalPages}
            onPageClick={handlePageClick}
          />
        </div>
      )}
    </>
  );
});
