export const userReportHandler = () => {
  const recipientEmail = 'report@digitssports.com';
  const emailSubject = 'Abusive user report';
  const emailBodyTemplate = 'Describe the issue';
  const mailtoUrl = `mailto:${encodeURIComponent(recipientEmail)}?subject=${encodeURIComponent(
    emailSubject,
  )}&body=${encodeURIComponent(emailBodyTemplate)}`;
  const mailtoLink = document.createElement('a');
  mailtoLink.setAttribute('href', mailtoUrl);
  mailtoLink.setAttribute('target', '_blank');
  mailtoLink.click();
};
