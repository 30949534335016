import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { formatBigNumbers } from 'helpers/format/format-big-numbers.utils';

import { usePopularReactions } from 'hooks/use-popular-reactions';

import { IReaction } from 'components/posts/post-card/interfaces/reactions.interface';
import { ReactionTheme } from 'components/reactions/reactions/reactions.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './reactions-picker.module.less';

interface IReactionsPickerProps {
  theme: ReactionTheme;
  reactions: {
    positive?: IReaction[];
    negative?: IReaction[];
  };
  reactionsSum: number;
  onOpenPopupReactions?: () => void;
}

export const ReactionsPicker = memo((props: IReactionsPickerProps) => {
  const { reactionsSum, reactions, theme } = props;

  const filteredReactions = usePopularReactions(reactions);

  const reactionPickerClasses = useMemo(
    () =>
      cn(styles.ReactionsPicker, {
        [styles['ReactionsPicker--regular-theme']]: ReactionTheme.Regular === theme,
        [styles['ReactionsPicker--smaller-theme']]: ReactionTheme.Smaller === theme,
      }),
    [theme],
  );

  const setReactionsPickerItemClassNames = useCallback(
    (isSelected: boolean) =>
      cn(styles.ReactionsPicker__Item, {
        [styles['ReactionsPicker__Item--selected']]: isSelected,
      }),
    [],
  );

  const placeholderIconSize = useMemo(() => {
    if (ReactionTheme.Smaller === theme) {
      return IconFontSize.Small;
    }

    return IconFontSize.Big;
  }, [theme]);

  return (
    <div
      role="presentation"
      aria-label="ReactionsPicker"
      className={reactionPickerClasses}
      onClick={props.onOpenPopupReactions}
    >
      {filteredReactions.map((reaction) => (
        <button
          key={reaction.unicode}
          className={setReactionsPickerItemClassNames(reaction.isSelected)}
        >
          <img src={reaction.htmlValue} alt={`${reaction.unicode} emoji`} />
        </button>
      ))}
      {!!filteredReactions.length && (
        <span className={styles.ReactionsPicker__Total}>{formatBigNumbers(reactionsSum)}</span>
      )}
      {!filteredReactions.length && (
        <div className={styles.ReactionsPicker__Placeholder}>
          <IconButton
            iconName={IconFontName.Reaction}
            theme={IconButtonTheme.Transparent}
            iconSize={placeholderIconSize}
          />
        </div>
      )}
    </div>
  );
});
