import { FC } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { LayoutStore } from 'stores/layout/layout.store';

import { TYPES } from 'configs/di-types.config';

import { BookmarkSidePanel } from 'components/side-panels/bookmark-side-panel/bookmark-side-panel.component';

export const BookmarkPanelContainer: FC = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);

  return <BookmarkSidePanel isOpen={layoutStore.sidePanelActiveTab === 'bookmarked'} />;
});
