import { inject, injectable } from 'inversify';
import { action, autorun, makeObservable, observable, reaction } from 'mobx';

import { INewsResponse } from 'services/news/interfaces/news-response.interface';
import { NewsService } from 'services/news/news.service';
import { NewsResponseType } from 'services/news/types/news-response.type';

import { TeamsStore } from 'stores/teams/teams.store';

import { TYPES } from 'configs/di-types.config';

import { EntriesStore } from '../entries/entries.store';

import { newsAdapter } from './adapters/news-adapter.util';
import { INews } from './interfaces/news.interface';

@injectable()
export class NewsStore extends EntriesStore<INews, INewsResponse> {
  private readonly newsService: NewsService;

  private readonly teamsStore: TeamsStore;

  public teamLatestNews: INews[];

  constructor(
    @inject(TYPES.NewsService) newsService: NewsService,
    @inject(TYPES.TeamsStore) teamsStore: TeamsStore,
  ) {
    super();

    this.newsService = newsService;

    this.teamsStore = teamsStore;

    this.teamLatestNews = [];

    makeObservable(this, {
      teamLatestNews: observable,
      setTeamLatestNews: action.bound,
      retrieveTeamLatestNews: action.bound,
    });

    reaction(() => this.teamsStore.teamId, this.retrieveTeamLatestNews);
    autorun(() => this.retrieveTeamLatestNews());
  }

  public async retrieveTeamLatestNews() {
    this.setFetching(true);
    this.resetErrors();

    if (this.teamsStore.teamId) {
      const response: IResponse<NewsResponseType> = await this.newsService.retrievePostsNewsTeam(
        this.teamsStore.teamId,
        {
          page: 1,
          limit: 3,
        },
      );

      if (response.success) {
        this.setTeamLatestNews(response.data.map(newsAdapter));
      } else {
        this.setErrors(response.errors);
      }
    }

    this.setFetching(false);
  }

  public setTeamLatestNews(news: INews[]) {
    this.teamLatestNews = news;
  }
}
