import { v4 } from 'uuid';

import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';

import { splitByTheLastSymbol } from 'helpers/split-by-the-last-symbol';

export const getPreviewImage = (
  mediumLogoUrl: string,
  filename: string,
  smallLogoUrl?: Maybe<string>,
) => {
  const splitFilenameArr = splitByTheLastSymbol(mediumLogoUrl, '.');
  const extension = splitFilenameArr[1];

  const image: IInteractiveMediaAttachment = {
    type: 'image',
    url: mediumLogoUrl,
    smallPreviewUrl: smallLogoUrl || mediumLogoUrl,
    mediumPreviewUrl: mediumLogoUrl,
    uuid: v4(),
    mimeType: `image/${extension}`,
    filename: `${filename}.${extension}`,
  };

  return image;
};
