import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import {
  IGameChatEventsResponse,
  IGameChatMessageCreationPayload,
  IGameChatMessageDeletePayload,
  IGameChatMessageResponse,
  IGameChatMessagesResponse,
  IGameChatMessageVoteDataPayload,
  IGameChatMessageVoteResponse,
  IGameChatSearchParams,
  IGetGameChatMessagePayload,
  IGetGamePollsPayload,
} from 'services/game-chat/interfaces/game-chat-messages.interface';
import { HttpService } from 'services/http/http.service';
import { IPollAttachmentResponse } from 'services/posts/interfaces/post-attachments-response.interface';

import { TYPES } from 'configs/di-types.config';
import {
  GAME_ID_PARAM,
  MESSAGE_ID_PARAM,
  POLL_ID_PARAM,
  POLL_OPTION_ID_PARAM,
} from 'routes/paths.constants';

import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

import {
  GAME_CHAT_ITEM,
  GAME_CHAT_ITEM_DELETE,
  GAME_CHAT_ITEM_VOTE_DOWN,
  GAME_CHAT_ITEM_VOTE_UP,
  GAME_MESSAGES,
  GAME_NEW_MESSAGE,
  GAME_POLLS,
  POST_POLL_VOTE,
} from '../http/consts/api-endpoints.constants';

@injectable()
export class GameChatService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public async fetchAllGameMessages(
    pagination: IPagination,
    gamedId: number,
    type: string,
  ): Promise<IResponse<IGameChatMessagesResponse>> {
    const { limit, page } = pagination;

    const paramsObject: IGameChatSearchParams = {
      limit: String(limit),
      page: String(page),
      sort: 'DESC',
      type,
    };

    const routeParams = {
      [GAME_ID_PARAM]: String(gamedId),
    };

    return this.httpService.request<IGameChatMessagesResponse>({
      method: 'GET',
      url: GAME_MESSAGES,
      params: new URLSearchParams({ ...paramsObject }),
      routeParams,
    });
  }

  public async fetchAllGameEvents(
    pagination: IPagination,
    gamedId: number,
    type: string,
  ): Promise<IResponse<IGameChatEventsResponse>> {
    const { limit, page } = pagination;

    const paramsObject: IGameChatSearchParams = {
      limit: String(limit),
      page: String(page),
      sort: 'DESC',
      type,
    };

    const routeParams = {
      [GAME_ID_PARAM]: String(gamedId),
    };

    return this.httpService.request<IGameChatEventsResponse>({
      method: 'GET',
      url: GAME_MESSAGES,
      params: new URLSearchParams({ ...paramsObject }),
      routeParams,
    });
  }

  public async fetchSingleGameChatMessage(
    payload: IGetGameChatMessagePayload,
  ): Promise<IResponse<IGameChatMessageResponse>> {
    return this.httpService.request<IGameChatMessageResponse, IGetGameChatMessagePayload>({
      method: 'GET',
      url: GAME_CHAT_ITEM,
      routeParams: {
        [GAME_ID_PARAM]: String(payload.gameId),
        [MESSAGE_ID_PARAM]: payload.messageId,
      },
    });
  }

  public async fetchPolls(
    payload: IGetGamePollsPayload,
  ): Promise<IResponse<IGameChatMessagesResponse>> {
    return this.httpService.request<IGameChatMessagesResponse, IGetGamePollsPayload>({
      method: 'GET',
      url: GAME_POLLS,
      routeParams: {
        [GAME_ID_PARAM]: String(payload.gameId),
      },
    });
  }

  public async createGameChatMessage(
    payload: IGameChatMessageCreationPayload,
  ): Promise<IResponse<IGameChatMessageResponse>> {
    return this.httpService.request<IGameChatMessageResponse, IGameChatMessageCreationPayload>({
      method: 'POST',
      headers: {
        'X-Version': '2',
      },
      url: GAME_NEW_MESSAGE,
      routeParams: {
        [GAME_ID_PARAM]: String(payload.gameId),
      },
      body: payload,
    });
  }

  public async deleteGameChatMessage(
    payload: IGameChatMessageDeletePayload,
  ): Promise<IResponse<null>> {
    return this.httpService.request<null, IGameChatMessageDeletePayload>({
      method: 'DELETE',
      url: GAME_CHAT_ITEM_DELETE,
      routeParams: {
        [GAME_ID_PARAM]: String(payload.gameId),
        [MESSAGE_ID_PARAM]: payload.messageId,
      },
    });
  }

  public async gameChatMessagePollVote(
    payload: IPollVoteData,
  ): Promise<IResponse<IPollAttachmentResponse>> {
    return this.httpService.request<IPollAttachmentResponse, IPollVoteData>({
      method: 'POST',
      url: POST_POLL_VOTE,
      routeParams: { [POLL_ID_PARAM]: payload.pollId, [POLL_OPTION_ID_PARAM]: payload.answerId },
    });
  }

  public async gameChatMessageVoteUp(
    payload: IGameChatMessageVoteDataPayload,
  ): Promise<IResponse<IGameChatMessageVoteResponse>> {
    return this.httpService.request<IGameChatMessageVoteResponse, IGameChatMessageVoteDataPayload>({
      method: 'POST',
      url: GAME_CHAT_ITEM_VOTE_UP,
      body: payload,
      routeParams: {
        [GAME_ID_PARAM]: String(payload.gameId),
        [MESSAGE_ID_PARAM]: payload.messageId,
      },
    });
  }

  public async gameChatMessageVoteDown(
    payload: IGameChatMessageVoteDataPayload,
  ): Promise<IResponse<IGameChatMessageVoteResponse>> {
    return this.httpService.request<IGameChatMessageVoteResponse, IGameChatMessageVoteDataPayload>({
      method: 'POST',
      url: GAME_CHAT_ITEM_VOTE_DOWN,
      body: payload,
      routeParams: {
        [GAME_ID_PARAM]: String(payload.gameId),
        [MESSAGE_ID_PARAM]: payload.messageId,
      },
    });
  }
}
