export enum StandingsTeamAchievementEnum {
  // Can be changed according to fields from backend
  Star = 'clinched_best_league_record',
  GreenFilled = 'clinched_conference',
  Green = 'clinched_division',
  PurpleFilled = 'clinched_playoff_berth',
  Purple = 'clinched_playoff_won_a_play_in',
  Blue = 'currently_in_play_in',
  RedFilled = 'eliminated_from_playoff',
  Red = 'clinched_play_in_berth',
}
