import { ReactNode, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { Portal, PortalType } from 'components/ui/portal/portal.component';

import styles from './base-bottom-sheet.module.less';

interface IBottomSheetProps {
  children: ReactNode;
  visible: boolean;
  isBottomBarHidden?: boolean;
  hasBackdrop?: boolean;
  hasBottomBar?: boolean;
  hasContainerShadow?: boolean;
  onClose?: () => void;
}

export const BaseBottomSheet = (props: IBottomSheetProps) => {
  const {
    children,
    visible,
    isBottomBarHidden,
    hasBackdrop,
    hasBottomBar,
    hasContainerShadow,
    onClose,
  } = props;

  const classBottomSheetNames = useMemo(
    () =>
      cn(styles.BottomSheet, {
        [styles['BottomSheet--visible']]: visible,
        [styles['BottomSheet--backdrop']]: hasBackdrop,
        [styles['BottomSheet--bottom-bar']]: hasBottomBar,
        [styles['BottomSheet--container-shadow']]: hasContainerShadow,
        [styles['BottomSheet--bottom-bar-hidden']]: isBottomBarHidden,
      }),
    [visible, isBottomBarHidden, hasBackdrop, hasBottomBar, hasContainerShadow],
  );

  const handleClose = useCallback(
    (event: SyntheticEvent) => {
      if (onClose) {
        event.stopPropagation();

        onClose();
      }
    },
    [onClose],
  );

  return (
    <Portal type={PortalType.BottomSheet}>
      <div className={classBottomSheetNames}>
        {hasBackdrop && (
          <button
            type="button"
            aria-label="Bottom Sheet Backdrop"
            className={styles.BottomSheet__Backdrop}
            onClick={handleClose}
          />
        )}
        <div className={styles.BottomSheet__Content}>{children}</div>
      </div>
    </Portal>
  );
};
