import { memo, useEffect, useMemo, useRef } from 'react';
import { GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import cn from 'classnames';

import { ITeamStatsRow } from 'containers/team-stats/team-stats.interface';

import { BaseTableGrid } from 'components/base-table/base-table.component';

import './ag-theme-team-stats.less';

interface ITeamStatsTableProps {
  tableGridOption: GridOptions<ITeamStatsRow>;
  withColors: boolean;
}

export const TeamStatsTable = memo((props: ITeamStatsTableProps) => {
  const { tableGridOption, withColors } = props;

  const gridRef = useRef<AgGridReact<ITeamStatsRow>>(null);

  useEffect(() => {
    if (gridRef.current && tableGridOption.rowData) {
      gridRef.current?.api?.setRowData(tableGridOption.rowData);
    }
  }, [tableGridOption.rowData]);

  const classNames = useMemo(
    () => cn('ag-theme-team-stats', { 'ag-theme-team-stats--with-colors': withColors }),
    [withColors],
  );

  return (
    <div key={tableGridOption.gridId} className={classNames}>
      <BaseTableGrid ref={gridRef} gridOptions={tableGridOption} />
    </div>
  );
});
