import { useCallback, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { CommunityHeader } from 'components/communities/community-header/community-header.component';

export const TeamHeaderContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);

  const { teams } = teamsStatsStore;

  const currentTeam = useMemo(
    () => teams.find((team) => teamsStore.teamId === team.id) || null,
    [teamsStore.teamId, teams],
  );

  const handleButtonClick = useCallback(async () => {
    if (teamsStatsStore.team) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.TeamProfileFollow,
          team: teamsStatsStore.team.name,
        },
      });
    }

    if (authStore.triggerAuthorisationCheck()) {
      await teamsStore.followTeam();
    }
  }, [authStore, teamsStore, teamsStatsStore]);

  const { smallLogoUrl = '', name } = teamsStatsStore.team || {};

  const handleContextItemClick = useCallback(
    async (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click && barAction.payload === 'leave') {
        await teamsStore.unfollowTeam();
      }
    },
    [teamsStore],
  );

  if (!teamsStatsStore.team) {
    return null;
  }

  return (
    <CommunityHeader
      isJoined={teamsStore.isFollowedTeam}
      processing={teamsStore.fetching}
      avatarSrc={smallLogoUrl}
      name={name || ''}
      onButtonClick={handleButtonClick}
      onContextItemClick={handleContextItemClick}
      teamTextColor={currentTeam?.secondaryTextColor || null}
    />
  );
});
