import { FC, memo } from 'react';

import { IGameWithStats } from 'stores/game/interfaces/game.interface';

import { ShareGamesScores } from '../share-game-scores/share-game-scores.components';
import { ShareTeam } from '../share-team/share-team.component';

import styles from './share-game-score.module.less';

interface IShareGameScoreProps {
  game: IGameWithStats;
  isDraw: boolean;
  homeIsWinning: boolean;
}

export const ShareGameScore: FC<IShareGameScoreProps> = memo((props: IShareGameScoreProps) => {
  const { game, isDraw, homeIsWinning } = props;

  return (
    <div className={styles.GameInfo}>
      <ShareTeam team={game.visitors} theme="Visitor" />
      <ShareGamesScores
        status={game.status}
        homePoints={game.scores.total[0]}
        visitorsPoints={game.scores.total[1]}
        homeIsWinning={homeIsWinning && !isDraw}
        formattedPeriod={game.formattedPeriod}
        clockFormatted={game.clockFormatted}
      />
      <ShareTeam team={game.home} theme="Home" />
    </div>
  );
});
