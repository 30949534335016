import { FC } from 'react';

import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { ISingleCategoryRecord } from 'stores/feed-filters/interfaces/feed-filters.interface';

import { FeedFilters } from 'components/feed-filter/feed-filter.component';
import {
  BaseModalComponent,
  IModal,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './feed-filters-modal.module.less';

interface IFeedFiltersModalProps extends IModal {
  filters: Maybe<ISingleCategoryRecord[]>;
  isShowFeedsToggle: boolean;
  onResetFilters: () => void;
  onToggleCategoryItem: (categoryId: string, tagId: string) => void;
  onToggleCategory: (categoryId: string) => void;
  onApplyFilters: () => void;
  onChangeFeeds: (value: FeedTypes) => void;
}

export const FeedFiltersModal: FC<IFeedFiltersModalProps> = (props) => {
  const { visible, isShowFeedsToggle } = props;

  return (
    <BaseModalComponent
      visible={visible}
      size={ModalWindowSize.M}
      title="Feed Filters"
      isScrollDisabled
      onClose={props.onClose}
    >
      <div className={styles.ModalContent}>
        <FeedFilters
          isShowFeedsToggle={isShowFeedsToggle}
          filters={props.filters}
          onResetFilters={props.onResetFilters}
          onToggleCategoryItem={props.onToggleCategoryItem}
          onToggleCategory={props.onToggleCategory}
          onChangeFeeds={props.onChangeFeeds}
        />
      </div>
      <div className={styles.ModalFooter}>
        <Button
          onClick={props.onApplyFilters}
          size={ButtonSize.Small}
          theme={ButtonTheme.Primary}
          fluid
        >
          Apply
        </Button>
      </div>
    </BaseModalComponent>
  );
};
