import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  SortChangedEvent,
  SortDirection,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import cn from 'classnames';

import { StandingsMode } from 'stores/standings/enums/standings-mode.enum';
import { StandingsType } from 'stores/standings/enums/standings-type.enum';
import {
  EXPANDED_TYPE_COL_DEF,
  isAlwaysVisibleColumn,
  STANDINGS_CONFERENCE_MAX_INDEX_FOR_RANK,
  STANDINGS_TYPE_COL_DEF,
  THEME_CLASS_NAME,
  VS_DIVISION_TYPE_COL_DEF,
} from 'stores/standings/standings.config';

import { BaseTableGrid } from 'components/base-table/base-table.component';
import { handleRankValue } from 'components/base-table/helpers/handle-rank-value.util';

import '../ag-theme-standings.less';
import styles from './standings-table.module.less';

interface IStandingsTableProps {
  mode: StandingsMode;
  type: StandingsType;
  tableHeader: string;
  tablesGirdOptions: GridOptions[];
}

export const StandingsTable = memo((props: IStandingsTableProps) => {
  const { mode, type, tablesGirdOptions, tableHeader } = props;

  const agGridRefs = useRef<AgGridReact[]>([]);

  const handleSortChanged = useCallback(
    (event: SortChangedEvent) => {
      const sortedTableState = event.columnApi.getColumnState();
      event.api.refreshHeader();
      event.api.refreshCells();

      agGridRefs.current.forEach((grid) => {
        if (grid) {
          const sortedColumnState = sortedTableState.find((col) => !!col.sort);
          let rankDirection: SortDirection = null;
          const stopIndex =
            mode === StandingsMode.Conference ? STANDINGS_CONFERENCE_MAX_INDEX_FOR_RANK : null;

          if (sortedColumnState?.colId === 'gamesBehind') {
            rankDirection = sortedColumnState.sort === 'asc' ? 'desc' : 'asc';
          }

          grid.columnApi.applyColumnState({ state: sortedTableState });

          handleRankValue(event, rankDirection, stopIndex);
        }
      });
    },
    [mode],
  );

  const handleGridReady = useCallback(
    (event: GridReadyEvent) => {
      const sortedTableState = event.columnApi.getColumnState();
      const sortedColumnState = sortedTableState.find((col) => !!col.sort);
      let rankDirection: SortDirection = null;
      const stopIndex =
        mode === StandingsMode.Conference ? STANDINGS_CONFERENCE_MAX_INDEX_FOR_RANK : null;

      if (sortedColumnState?.colId === 'gamesBehind') {
        rankDirection = sortedColumnState.sort === 'asc' ? 'desc' : 'asc';
      }

      handleRankValue(event, rankDirection, stopIndex);
    },
    [mode],
  );

  useEffect(() => {
    agGridRefs.current = agGridRefs.current.slice(0, tablesGirdOptions.length);
  }, [tablesGirdOptions]);

  useEffect(() => {
    if (agGridRefs.current.length) {
      agGridRefs.current.forEach((grid) => {
        if (grid.api) {
          const currentColDef = grid.api.getColumnDefs();

          if (currentColDef) {
            const alwaysVisibleColDef = currentColDef.filter(
              (colDef: ColDef) => colDef.colId && isAlwaysVisibleColumn(colDef.colId),
            );

            if (type === StandingsType.Standings) {
              grid.api.setColumnDefs([...alwaysVisibleColDef, ...STANDINGS_TYPE_COL_DEF]);
            }

            if (type === StandingsType.Expanded) {
              grid.api.setColumnDefs([...alwaysVisibleColDef, ...EXPANDED_TYPE_COL_DEF]);
            }

            if (type === StandingsType.VsDivision) {
              grid.api.setColumnDefs([...alwaysVisibleColDef, ...VS_DIVISION_TYPE_COL_DEF]);
            }
          }
        }
      });
    }
  }, [type]);

  const classNames = useMemo(
    () =>
      cn(THEME_CLASS_NAME, {
        [`${THEME_CLASS_NAME}--mode-conference`]: mode === StandingsMode.Conference,
        [`${THEME_CLASS_NAME}--type-standings`]: type === StandingsType.Standings,
        [`${THEME_CLASS_NAME}--type-expanded`]: type === StandingsType.Expanded,
        [`${THEME_CLASS_NAME}--type-vs-division`]: type === StandingsType.VsDivision,
      }),
    [mode, type],
  );

  return (
    <>
      <div className={styles.StandingsTableHeader}>{tableHeader}</div>

      {tablesGirdOptions.map((tableGridOption, index, arr) => {
        const otherOptions = arr.filter((_, otherIndex) => index !== otherIndex);

        const { alignedGrids } = tableGridOption;

        if (alignedGrids) {
          alignedGrids.push(...otherOptions);
        }

        return (
          <div key={tableGridOption.gridId} className={classNames}>
            <BaseTableGrid
              ref={(node: AgGridReact) => {
                // this is used for creating array of refs with all elements we might have, in react doc we have this example using map instead of array https://react.dev/learn/manipulating-the-dom-with-refs#how-to-manage-a-list-of-refs-using-a-ref-callback
                agGridRefs.current[index] = node;
              }}
              gridOptions={tableGridOption}
              onSortChanged={handleSortChanged}
              onGridReady={handleGridReady}
            />
          </div>
        );
      })}
    </>
  );
});
