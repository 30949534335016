import { ICategoryPlayerRankingsItemResponse } from 'services/player-rankings/interfaces/player-rankings-response.interface';

import { teamInfoAdapter } from 'stores/game/adapters/game-adapter.util';
import { ICategoryPlayerRankingsItem } from 'stores/player-rankings/interfaces/player-rankings.interface';
import { singleGameMiniAdapter } from 'stores/statistics/adapters/single-game-mini-adapter.util';

export function playerRankingsCategoryAdapter(
  response: ICategoryPlayerRankingsItemResponse,
): ICategoryPlayerRankingsItem {
  const {
    game,
    player_info: playerInfo,
    team,
    minutes,
    is_live: isLive,
    fantasy_points: fantasyPoints,
    points,
    tot_reb: rebounds,
    assists,
    steals,
    blocks,
    plus_minus: plusMinus,
    tsp,
    fgm,
    fga,
    fgp,
    tpa,
    tpm,
    tpp,
    ftm,
    fta,
    ftp,
    off_reb: offReb,
    def_reb: defReb,
    turnovers,
    position,
    personal_fouls: personalFouls,
    fantasy_points_rank: fantasyPointsRank,
    points_rank: pointsRank,
    tot_reb_rank: reboundsRank,
    assists_rank: assistsRank,
    blocks_rank: blocksRank,
    steals_rank: stealsRank,
  } = response;

  const { firstname, lastname, slug, small_logo_url: smallLogoUrl, jersey } = playerInfo;

  return {
    team: teamInfoAdapter(team),
    game: singleGameMiniAdapter(game),
    playerInfo: {
      slug,
      smallLogoUrl,
      jersey,
      fullName: `${firstname.charAt(0)}. ${lastname}`,
      position,
    },
    fantasyPoints: Math.floor(fantasyPoints),
    points,
    minutes: Math.trunc(minutes),
    isLive,
    rebounds,
    assists,
    blocks,
    steals,
    personalFouls,
    turnovers,
    offReb,
    defReb,
    tsp: Math.floor(tsp * 10) / 10,
    fgm,
    fga,
    fgp: Math.floor(fgp * 10) / 10,
    tpa,
    tpm,
    tpp: Math.floor(tpp * 10) / 10,
    ftm,
    fta,
    ftp: Math.floor(ftp * 10) / 10,
    plusMinus,
    fantasyPointsRank,
    pointsRank,
    reboundsRank,
    assistsRank,
    blocksRank,
    stealsRank,
  };
}
