import { memo, useMemo } from 'react';
import cn from 'classnames';

import styles from './profile-single-point.module.less';

interface IProfileSinglePointProps {
  isCompactView: boolean;
  label: string;
  value: number | string;
  categoryName: string;
  forHeader?: boolean;
  onClick: () => void;
}

enum PlusMinusPointState {
  Negative,
  Positive,
}

export const ProfileSinglePoint = memo((props: IProfileSinglePointProps) => {
  const { label, value, categoryName, forHeader = false, isCompactView } = props;

  const isPlusMinusPoint = useMemo(() => categoryName === 'plusMinus', [categoryName]);

  const isMinutesPoint = useMemo(() => categoryName === 'minutes', [categoryName]);

  const plusMinusPointState = useMemo(() => {
    if (typeof value === 'number' && isPlusMinusPoint) {
      if (Math.sign(value) === -1) {
        return PlusMinusPointState.Negative;
      }

      if (Math.sign(value) === 1) {
        return PlusMinusPointState.Positive;
      }
    }

    return null;
  }, [value, isPlusMinusPoint]);

  const pointValue = useMemo(() => {
    if (isPlusMinusPoint && PlusMinusPointState.Positive === plusMinusPointState) {
      return `+${value}`;
    }

    return value;
  }, [value, isPlusMinusPoint, plusMinusPointState]);

  const pointClasses = useMemo<string>(
    () =>
      cn(styles.Point, {
        [styles['Point--compact']]: isCompactView,
      }),
    [isCompactView],
  );

  const pointValueClasses = useMemo(
    () =>
      cn(styles.Point__Value, {
        [styles['Point__Value--positive']]:
          isPlusMinusPoint && PlusMinusPointState.Positive === plusMinusPointState,
        [styles['Point__Value--negative']]:
          isPlusMinusPoint && PlusMinusPointState.Negative === plusMinusPointState,
        [styles['Point__Value--for-header']]: forHeader,
        [styles['Point__Value--compact']]: isCompactView,
        [styles['Point__Value--muddy']]: isMinutesPoint && !forHeader,
      }),
    [isPlusMinusPoint, plusMinusPointState, forHeader, isCompactView, isMinutesPoint],
  );

  const pointLabelClasses = useMemo(
    () =>
      cn(styles.Point__Label, {
        [styles['Point__Label--for-header']]: forHeader,
      }),
    [forHeader],
  );

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={props.onClick}
      onClick={props.onClick}
      className={pointClasses}
    >
      <span className={pointValueClasses}>{pointValue}</span>
      <span className={pointLabelClasses}>{label}</span>
    </div>
  );
});
