import { IGameDetailedStatsResponse } from 'services/games-detailed/interfaces/game-detailed-response.interface';

import { IGameDetailedResults } from '../interfaces/game-detailed-team.interface';

export function gameDetailedTeamStatAdapter(
  teamStat: Maybe<IGameDetailedStatsResponse>,
): Maybe<IGameDetailedResults> {
  if (!teamStat) {
    return null;
  }

  return {
    lossHome: teamStat.loss_home,
    lossAway: teamStat.loss_away,
    lossTotal: teamStat.loss_total,
    winHome: teamStat.win_home,
    winAway: teamStat.win_away,
    winTotal: teamStat.win_total,
  };
}
