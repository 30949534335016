import negativeAngerEmoji from 'assets/images/reactions/negative-anger.webp';
import negativePooEmoji from 'assets/images/reactions/negative-poo.webp';
import negativeScreamingEmoji from 'assets/images/reactions/negative-screaming.webp';
import negativeSkullEmoji from 'assets/images/reactions/negative-skull.webp';
import negativeThumbEmoji from 'assets/images/reactions/negative-thumb.webp';
import negativeVomitingEmoji from 'assets/images/reactions/negative-vomiting.webp';
import positiveCryEmoji from 'assets/images/reactions/positive-cry.webp';
import positiveFireEmoji from 'assets/images/reactions/positive-fire.webp';
import positiveHandsEmoji from 'assets/images/reactions/positive-hands.webp';
import positiveHeartEmoji from 'assets/images/reactions/positive-heart.webp';
import positiveSmileEmoji from 'assets/images/reactions/positive-smile.webp';
import positiveStarEmoji from 'assets/images/reactions/positive-star.webp';

export const NEGATIVE_REACTIONS = [
  { unicode: 'U+1F4A9', htmlValue: negativePooEmoji }, // 💩
  { unicode: 'U+1F631', htmlValue: negativeScreamingEmoji }, // 😱
  { unicode: 'U+1F92C', htmlValue: negativeAngerEmoji }, // 🤬
  { unicode: 'U+1F92E', htmlValue: negativeVomitingEmoji }, // 🤮
  { unicode: 'U+1F44E', htmlValue: negativeThumbEmoji }, // 👎
  { unicode: 'U+1F480', htmlValue: negativeSkullEmoji }, // 💀
];

export const POSITIVE_REACTIONS = [
  { unicode: 'U+1F525', htmlValue: positiveFireEmoji }, // 🔥
  { unicode: 'U+1F64F', htmlValue: positiveHandsEmoji }, // 🙏
  { unicode: 'U+2764', htmlValue: positiveHeartEmoji }, // ❤️
  { unicode: 'U+2B50', htmlValue: positiveStarEmoji }, // ⭐️
  { unicode: 'U+1F602', htmlValue: positiveSmileEmoji }, // 😂
  { unicode: 'U+1F979', htmlValue: positiveCryEmoji }, // 🥹
];
