import { FC, useCallback, useMemo } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { getPreviewImage } from 'stores/gallery/utils/get-preview-image.util';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { StandingsConference } from 'stores/standings/enums/standings-conference.enum';
import { ITeamStatsExtended } from 'stores/teams-stats/interfaces/teams-stats-all.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { ProfileInfoSocials } from 'components/profile/profile-info/profile-info-socials/profile-info-socials.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { IStatsBoxItem } from 'components/ui/stats-box/interfaces/stats-box.interface';
import { StatsBox, StatsBoxTheme } from 'components/ui/stats-box/stats-box.component';

import { prepareTeamStatsBoxItems } from './utils/prepare-team-stats-box-items.util';

import styles from './team-profile-info.module.less';

interface ITeamProfileInfoProps {
  teamsStats: Maybe<ITeamStatsExtended>;
  onRankButtonClick: () => void;
  onOpenPreview: (image: IInteractiveMediaAttachment) => void;
}

export const TeamProfileInfo: FC<ITeamProfileInfoProps> = (props: ITeamProfileInfoProps) => {
  const { teamsStats, onOpenPreview } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const navigate = useNavigate();

  const avatarSize = useMemo<AvatarSize>(() => {
    if (isDesktopPlus) {
      return AvatarSize.MEGA;
    }

    return AvatarSize.XXL;
  }, [isDesktopPlus]);

  const conferenceRankText = useMemo(() => {
    if (teamsStats?.conference) {
      const teamConference =
        teamsStats.conference === StandingsConference.East ? 'Eastern' : 'Western';
      return ` in ${teamConference} Conference`;
    }

    return null;
  }, [teamsStats?.conference]);

  const teamStatsBoxItems = useMemo<Maybe<IStatsBoxItem[]>>(() => {
    if (teamsStats) {
      return prepareTeamStatsBoxItems(teamsStats);
    }

    return null;
  }, [teamsStats]);

  const handleOpenPreview = useCallback(() => {
    if (!teamsStats?.mediumLogoUrl) {
      return;
    }

    const image = getPreviewImage(
      teamsStats.mediumLogoUrl,
      `${teamsStats.nickname.toLowerCase()}-logo`,
      teamsStats.smallLogoUrl,
    );

    onOpenPreview(image);
  }, [onOpenPreview, teamsStats]);

  const handleRankClick = useCallback(
    (sort?: string) => () => {
      if (sort) {
        navigate({
          pathname: paths.HOME_STATS_TEAMS_ALL,
          search: `${createSearchParams({ sort, direction: 'desc' })}`,
        });
      }
    },
    [navigate],
  );

  return (
    <div className={styles.TeamProfileInfoWrapper}>
      {teamsStats && (
        <>
          <div className={styles.TeamProfileInfo}>
            <button className={styles.TeamProfileInfo__Avatar} onClick={handleOpenPreview}>
              <Avatar src={teamsStats.mediumLogoUrl} username={teamsStats.name} size={avatarSize} />
            </button>
            <div className={styles.TeamProfileInfo__NameAndDetails}>
              <h3 className={styles.TeamProfileInfo__Name}>{teamsStats.name}</h3>
              <div className={styles.TeamProfileInfo__Stats}>
                <span className={styles.TeamProfileInfo__Code}>[{teamsStats.code}]</span>
                <button
                  className={styles.TeamProfileInfo__RankButton}
                  onClick={props.onRankButtonClick}
                >
                  <span className={styles.TeamProfileInfo__Position}>
                    #{teamsStats.conferenceRank}
                  </span>
                  {conferenceRankText}
                </button>
                <div className={styles.TeamProfileInfo__Results}>
                  <IconFont name={IconFontName.Ball} size={IconFontSize.Small} />
                  <span className={styles.TeamProfileInfo__Results}>
                    {teamsStats.totalWin} - {teamsStats.totalLose}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.TeamProfileInfo__Socials}>
              <ProfileInfoSocials
                instagramUrl={teamsStats.instagramUrl}
                twitterUrl={teamsStats.twitterUrl}
                youtubeUrl={teamsStats.youtubeUrl}
                tiktokUrl={teamsStats.tiktokUrl}
              />
            </div>
          </div>
          <div className={styles.TeamProfile__Stats}>
            {teamStatsBoxItems &&
              teamStatsBoxItems.map((item) => (
                <StatsBox
                  key={item.slug}
                  title={isDesktopPlus ? item.title : item.slug}
                  content={item.content}
                  theme={StatsBoxTheme.Transparent}
                  rank={item.rank}
                  onClick={handleRankClick(item.sortByColumn)}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};
