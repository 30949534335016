import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { ScrollTopContainer } from 'containers/scroll-top/scroll-top.container';
import { UserAgreementContainer } from 'containers/user-agreement/user-agreement.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useLayoutEntity } from 'hooks/use-layout-entity';
import { useResponsive } from 'hooks/use-responsive';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

import style from './user-agreement.module.less';

export const UserAgreementRoute = () => {
  useLayoutEntity({ type: LayoutEntity.PageTitle, value: 'User Agreement' });

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  return (
    <PageWrapperContainer>
      <Row layout={RowLayout.MainContentExtended}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <UserAgreementContainer />
        </Column>
        {isDesktopPlus && (
          <div className={style.ScrollTopWrapper}>
            <ScrollTopContainer />
          </div>
        )}
      </Row>
    </PageWrapperContainer>
  );
};
