export function getRandomItemsFromArray<T>(arr: T[], amount: number): T[] {
  const { length } = arr;

  if (length <= amount) {
    return arr;
  }

  const indices: number[] = [];
  while (indices.length < amount) {
    const randomIndex = Math.floor(Math.random() * length);

    if (!indices.includes(randomIndex)) {
      indices.push(randomIndex);
    }
  }

  return indices.map((index) => arr[index]);
}
