import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import { TEAM_ID_API_PARAM } from 'services/http/consts/api-endpoint-params.constants';
import { POSTS_NEWS_TEAM } from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { TYPES } from 'configs/di-types.config';

import { NewsResponseType } from './types/news-response.type';

@injectable()
export class NewsService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public async retrievePostsNewsTeam(
    teamId: number,
    pagination: IPagination,
  ): Promise<IResponse<NewsResponseType>> {
    const { limit, page } = pagination;

    return this.httpService.request<NewsResponseType>({
      method: 'GET',
      url: POSTS_NEWS_TEAM,
      params: new URLSearchParams({ limit: limit.toString(), page: page.toString() }),
      routeParams: { [TEAM_ID_API_PARAM]: teamId.toString() },
    });
  }
}
