import { FC, useCallback, useMemo, useRef } from 'react';
import { FieldValues } from 'react-hook-form';

import { IUpdateUserParams } from 'stores/auth/interfaces/update-user.params.interface';
import { IUserMe } from 'stores/auth/interfaces/user-me.interface';
import { getPreviewImage } from 'stores/gallery/utils/get-preview-image.util';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';

import { IPlayerOption } from 'containers/profile/profile-info/interfaces/player-option.interface';
import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';

import { PROFILE_INFO_GROUPS } from 'configs/context-menu-groups.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { BarActionType } from 'components/bars/bar-action.type';
import { EditProfileFormFieldsType } from 'components/forms/edit-profile/edit-profile-form.component';
import { ProfileAvatarForm } from 'components/forms/profile-avatar/profile-avatar-form.component';
import { ProfileTeamAndPlayerSelectForm } from 'components/forms/profile-team-and-player-select-form/profile-team-and-player-select-form.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { ContextMenuTooltip } from 'components/ui/context-menu-tooltip/context-menu-tooltip.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import { IInitialTeamAndPlayer } from './interfaces/initial-team-and-player.interface';

import styles from './profile-info.module.less';

const acceptFiles = 'image/png, image/jpeg, image/jpg';

interface IProfileInfoProps {
  isCropModalVisible: boolean;
  userData: IUserMe | null;
  teamsOptions: ITeamOption[];
  playersOptions: IPlayerOption[];
  favoriteTeamId: Maybe<number>;
  favoritePlayer: Maybe<IPlayerStats>;
  setDisabledScroll: (isDisabledScroll: boolean) => void;
  onUpdateUser: (updateUserParams: IUpdateUserParams) => void;
  onShowEditModal: (field?: EditProfileFormFieldsType) => void;
  onContextItemClick: (barAction: BarActionType) => void;
  onSetCropModal: (isOpen: boolean) => void;
  onRedirectToSettings: () => void;
  onOpenPreview: (image: IInteractiveMediaAttachment) => void;
}

export const ProfileInfo: FC<IProfileInfoProps> = (props: IProfileInfoProps) => {
  const {
    userData,
    teamsOptions,
    playersOptions,
    favoriteTeamId,
    favoritePlayer,
    setDisabledScroll,
    onUpdateUser,
    onShowEditModal,
    onOpenPreview,
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const { isNativeApp } = useMainProvider();

  const handleSubmitAvatar = useCallback(
    (avatarData: FieldValues) => {
      const cleanedBase64 = avatarData.avatar.value.split(',')[1];
      onUpdateUser({ avatar: cleanedBase64 });
    },
    [onUpdateUser],
  );

  const handleShowEditProfileModal = useCallback(
    (field?: EditProfileFormFieldsType) => () => {
      onShowEditModal(field);
    },
    [onShowEditModal],
  );

  const handleSubmitTeamAndPlayer = useCallback(
    (data: IInitialTeamAndPlayer) => {
      const payload = {
        favoriteTeamId: data.teamId,
        favoritePlayerId: data.playerId,
      };
      onUpdateUser(payload);
    },
    [onUpdateUser],
  );

  const handleOpenPreview = useCallback(() => {
    if (!userData?.profile.avatarUrl || isDesktopPlus) {
      return;
    }

    const image = getPreviewImage(
      userData.profile.avatarUrl,
      `${userData.username.toLowerCase()}-logo`,
      userData.profile.smallAvatarUrl,
    );

    onOpenPreview(image);
  }, [isDesktopPlus, onOpenPreview, userData]);

  const handleScrollToSelectorsForm = useCallback(() => {
    if (isNativeApp) {
      wrapperRef.current?.scrollIntoView();
    }
  }, [isNativeApp]);

  const initialTeamAndPlayer = useMemo(() => {
    const teamAndPlayer: IInitialTeamAndPlayer = {};

    if (favoriteTeamId) {
      teamAndPlayer.teamId = favoriteTeamId;
    }
    if (favoritePlayer) {
      teamAndPlayer.playerId = favoritePlayer.id;
    }

    return teamAndPlayer;
  }, [favoriteTeamId, favoritePlayer]);

  const memoizedInitialPlayerColor = useMemo(() => {
    if (favoritePlayer) {
      return favoritePlayer.color;
    }
    return null;
  }, [favoritePlayer]);

  const editButtonText = useMemo(() => (isDesktopPlus ? 'Edit Profile' : 'Edit'), [isDesktopPlus]);

  return (
    <div className={styles.ProfileInfo}>
      {userData && (
        <div className={styles.ProfileInfo__Data}>
          <div
            role="presentation"
            className={styles.ProfileInfoData__Avatar}
            onClick={handleOpenPreview}
          >
            <ProfileAvatarForm
              username={userData.username}
              onSubmit={handleSubmitAvatar}
              accept={acceptFiles}
              avatarUrl={userData.profile.avatarUrl}
              isCropModalVisible={props.isCropModalVisible}
              onSetCropModal={props.onSetCropModal}
            />
          </div>
          <div ref={wrapperRef} className={styles.ProfileInfoData__NamesAndSocials}>
            <div className={styles.ProfileInfoData__Names}>
              <h3 className={styles.ProfileInfoDataNames__Username}>{userData.username}</h3>
              {!userData.profile.realName ? (
                <Button
                  onClick={handleShowEditProfileModal('realname')}
                  iconName={IconFontName.Add}
                  theme={ButtonTheme.TextSecondary}
                  size={ButtonSize.Big}
                >
                  Add Real Name
                </Button>
              ) : (
                <div className={styles.ProfileInfoDataNames__RealName}>
                  {userData.profile.realName}
                </div>
              )}
            </div>
          </div>
          <div className={styles.ProfileInfoData__TeamAndPlayer}>
            {!!teamsOptions.length && !!playersOptions.length && (
              <ProfileTeamAndPlayerSelectForm
                teamsOptions={teamsOptions}
                playersOptions={playersOptions}
                initialValues={initialTeamAndPlayer}
                initialFavPlayerColor={memoizedInitialPlayerColor}
                setDisabledScroll={setDisabledScroll}
                onSubmit={handleSubmitTeamAndPlayer}
                onScrollToSelectorsForm={handleScrollToSelectorsForm}
              />
            )}
          </div>
          <div className={styles.ProfileInfoData__Buttons}>
            <Button
              onClick={handleShowEditProfileModal()}
              theme={ButtonTheme.Primary}
              size={ButtonSize.SmallSecondary}
            >
              {editButtonText}
            </Button>
            {!isDesktopPlus && !isNativeApp && (
              <ContextMenuTooltip
                groups={PROFILE_INFO_GROUPS}
                onItemClick={props.onContextItemClick}
                toggleOnClick
                tooltipProps={{ tooltipOffset: 8, placement: 'bottom-end' }}
              >
                <IconButton iconName={IconFontName.More} theme={IconButtonTheme.Secondary} />
              </ContextMenuTooltip>
            )}
            {isNativeApp && !isDesktopPlus && (
              <IconButton
                iconName={IconFontName.Settings}
                theme={IconButtonTheme.Secondary}
                onClick={props.onRedirectToSettings}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
