import { Dialog } from '@capacitor/dialog';
import { IOSSettings, NativeSettings } from 'capacitor-native-settings';

export const handleIosPhotosDenied = async () => {
  const { value } = await Dialog.confirm({
    title: 'Please Allow Access to Photos and Videos',
    message:
      'Digits needs access to Photos so that you can upload photos and videos. Please go to your device settings > Privacy > Photos and set Digits to ON',
    okButtonTitle: 'Settings',
    cancelButtonTitle: 'Not now',
  });

  if (value) {
    await NativeSettings.openIOS({ option: IOSSettings.App });
  }
};
