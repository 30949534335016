import { FC, memo } from 'react';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './submit-section.module.less';

export interface ISubmitSectionProps {
  disabled?: boolean;
  isFormValid: boolean;
  buttonText?: string;
}

export const SubmitSection: FC<ISubmitSectionProps> = memo((props: ISubmitSectionProps) => {
  const { disabled = false, isFormValid, buttonText } = props;

  return (
    <div className={styles.SubmitSection}>
      <Button
        fluid
        disabled={disabled || !isFormValid}
        theme={ButtonTheme.Primary}
        size={ButtonSize.Big}
        type="submit"
      >
        {buttonText}
      </Button>
    </div>
  );
});
