import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import { STATISTICS_SEASONS } from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { DEFAULT_SEASONS_PAGINATION } from 'stores/seasons/seasons.config';

import { TYPES } from 'configs/di-types.config';

import { ISeasonResponse } from './interfaces/season-response.interface';

@injectable()
export class SeasonsService {
  private readonly httpService: HttpService;

  constructor(@inject<HttpService>(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchSeasons(
    pagination: IPagination = DEFAULT_SEASONS_PAGINATION,
  ): Promise<IResponse<ISeasonResponse[]>> {
    const { limit, page } = pagination;

    const params = new URLSearchParams({
      limit: String(limit),
      page: String(page),
    });

    return this.httpService.request<ISeasonResponse[]>({
      method: 'GET',
      url: STATISTICS_SEASONS,
      params,
    });
  }
}
