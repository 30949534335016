import { memo } from 'react';

import { getStatsPropertyAbbreviationByKey } from 'helpers/get-stats-property-abbreviation-by-key.util';

import { STATS_ABBRS } from 'components/player-profile/player-profile-games/game-log.config';
import {
  ISingleSplitItem,
  ISplitStats,
} from 'components/player-profile/player-profile-games/player-profile-games.interface';
import { ProfileSinglePoint } from 'components/player-profile/profile-single-point/profile-single-point.component';

interface IGameStatsRowProps {
  stats: ISingleSplitItem | ISplitStats;
  onRowClick: () => void;
  forHeader: boolean;
  isCompactView: boolean;
}

export const GameStatsRow = memo((props: IGameStatsRowProps) => {
  const { stats, forHeader, isCompactView } = props;

  return (
    <>
      {STATS_ABBRS.map((item) => (
        <ProfileSinglePoint
          onClick={props.onRowClick}
          key={item}
          forHeader={forHeader}
          isCompactView={isCompactView}
          categoryName={item}
          label={getStatsPropertyAbbreviationByKey(item)}
          value={stats[item as keyof ISplitStats]}
        />
      ))}
    </>
  );
});
