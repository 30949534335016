import { memo, useMemo } from 'react';
import cn from 'classnames';

import { IReportReason } from 'services/reports/interfaces/report-reason.interface';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import {
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';

import styles from './report-submittion.module.less';

interface IReportSubmittionProps {
  isModalReport: boolean;
  reason: IReportReason;
  onReportSubmit: () => void;
}

export const ReportSubmittion = memo((props: IReportSubmittionProps) => {
  const { isModalReport, reason } = props;

  const reportSubmittionClasses = useMemo(
    () =>
      cn(styles.ReportSubmittion, {
        [styles['ReportSubmittion--modal-theme']]: isModalReport,
      }),
    [isModalReport],
  );

  const submitReportButtonSize = useMemo(() => {
    if (isModalReport) {
      return ButtonSize.Small;
    }

    return ButtonSize.Big;
  }, [isModalReport]);

  return (
    <div className={reportSubmittionClasses}>
      <div className={styles.ReportSubmittion__Header}>
        <IconFont name={IconFontName.Info} size={IconFontSize.Big} />
        <span className={styles.ReportSubmittion__ReportName}>{reason.name}</span>
      </div>
      <div className={styles.ReportSubmittion__Body}>
        <RichTextPreview
          id={String(reason.id)}
          editorState={reason.description}
          editorType={RichTextContentTypes.FullHeight}
        />
      </div>
      <div className={styles.ReportSubmittion__Footer}>
        <Button
          size={submitReportButtonSize}
          theme={ButtonTheme.Primary}
          onClick={props.onReportSubmit}
        >
          Submit Report
        </Button>
      </div>
    </div>
  );
});
