import { FC, memo } from 'react';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import { ENCODED_DASH_SYMBOL } from 'configs/encoded-symbols.config';

import styles from './live-game-scores.module.less';

interface ILiveGamesScoresProps {
  visitorsPoints: number;
  homePoints: number;
  formattedPeriod: string;
  clock: Maybe<string>;
  status: GameStatus;
}

export const LiveGamesScores: FC<ILiveGamesScoresProps> = memo((props: ILiveGamesScoresProps) => {
  const { visitorsPoints, homePoints, formattedPeriod, clock, status } = props;

  return (
    <div className={styles.Scores}>
      <span className={styles.Score}>
        {visitorsPoints} {ENCODED_DASH_SYMBOL} {homePoints}
      </span>
      <div className={styles.Time}>
        <span>{formattedPeriod}</span>
        {status !== GameStatus.Halftime && <span>{clock}</span>}
      </div>
    </div>
  );
});
