import { SortEnum } from 'stores/posts/posts.config';

import { CommentsTabsType } from 'containers/posts/post-detailed/post-detailed.container';

import { BarAction } from 'components/bars/bar-action.enum';

export const COMMENTS_SORT_TABS_ACTIONS: CommentsTabsType = [
  {
    id: 'new',
    title: 'New',
    action: {
      type: BarAction.Click,
      payload: SortEnum.NEW,
    },
  },
  {
    id: 'old',
    title: 'Old',
    action: {
      type: BarAction.Click,
      payload: SortEnum.OLD,
    },
  },
];
