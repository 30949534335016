import { IGameChatEventResponse } from 'services/game-chat/interfaces/game-chat-messages.interface';

import { IGameChatEvent } from 'stores/game-events/interfaces/game-chat-event.interface';
import { prepareReactions } from 'stores/posts/adapters/publication-adapter.util';

import { formatPastDate } from 'helpers/format/format-past-date.util';

export function gameChatEventAdapter(response: IGameChatEventResponse): IGameChatEvent {
  const {
    item_type: itemType,
    uuid,
    content,
    votes_total: votesTotal,
    reactions_total: reactionsTotal,
    event_type: EventType,
    home_points: homeTeamPoints,
    visitors_points: visitorsTeamPoints,
    period,
    clock,
    team_result_type: teamResultType,
    score_type: scoreType,
    date_created: dateCreated,
  } = response;

  const isTeamEvent = !!teamResultType;
  const isHomeTeamEvent = !!teamResultType && teamResultType === 'home';

  return {
    uuid,
    itemType,
    eventType: EventType,
    scoreType: scoreType || 'simple',
    isTeamEvent,
    isHomeTeamEvent,
    period,
    clock,
    homeTeamPoints,
    visitorsTeamPoints,
    content,
    date: formatPastDate(dateCreated, 'relativeLong'),
    votesTotal,
    reactionsTotal: prepareReactions(reactionsTotal),
  };
}
