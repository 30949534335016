import {
  IPlayerCareer,
  IPlayerStatsCareerAverageRowData,
} from '../interfaces/player-profile.interfaces';

export function playerStatsCareerAverageRowDataAdapter(
  playerCareer: IPlayerCareer,
): IPlayerStatsCareerAverageRowData[] {
  const playerCareerAverageStats = {
    season: 'Career',
    team: null,
    gamesPlayed: playerCareer.gamesPlayed,
    points: playerCareer.points,
    steals: playerCareer.steals,
    totalRebounds: playerCareer.totalRebounds,
    assists: playerCareer.assists,
    offensiveReb: playerCareer.offensiveReb,
    defensiveReb: playerCareer.defensiveReb,
    blocks: playerCareer.blocks,
    personalFouls: playerCareer.personalFouls,
    turnovers: playerCareer.turnovers,
    fieldGoalsMade: playerCareer.fieldGoalsMade,
    fieldGoalsAttempted: playerCareer.fieldGoalsAttempted,
    fieldGoalsPercentage: playerCareer.fieldGoalsPercentage,
    threePointsFieldGoalsMade: playerCareer.threePointsFieldGoalsMade,
    threePointsFieldGoalsAttempted: playerCareer.threePointsFieldGoalsAttempted,
    threePointsFieldGoalsPercentage: playerCareer.threePointsFieldGoalsPercentage,
    freeThrowsMade: playerCareer.freeThrowsMade,
    freeThrowsAttempted: playerCareer.freeThrowsAttempted,
    freeThrowsPercentage: playerCareer.freeThrowsPercentage,
  };

  return [playerCareerAverageStats];
}
