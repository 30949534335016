export enum SortEnum {
  NEW = 'new',
  OLD = 'old',
}

export const isSortValue = (value: string): value is SortEnum => {
  return Object.values(SortEnum).some((enumValue) => enumValue === value);
};

export const NBA_FEED_ID = 'nba-feed';
