import { FC, memo, PropsWithChildren, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './sidebar-item.module.less';

export enum SidebarItemTheme {
  Vector,
  Image,
}

export interface ISidebarItemProps {
  isNeedPlusButton?: boolean;
  isActive: boolean;
  isDisabled?: boolean;
  isMobile?: boolean;
  isSetting?: boolean;
  isRoot?: boolean;
  isNoJoined?: boolean;
  followers?: string;
  activityStatus?: Maybe<string>;
  imageSrc?: Maybe<string>;
  name: string;
  notificationsCount?: number;
  theme: SidebarItemTheme;
  onClick: () => void;
  onJoinTeam?: () => void;
}

export const SidebarItem: FC<ISidebarItemProps> = memo(
  (props: PropsWithChildren<ISidebarItemProps>) => {
    const {
      isNeedPlusButton,
      isActive,
      isDisabled,
      isMobile = false,
      isSetting,
      isRoot,
      isNoJoined,
      activityStatus,
      imageSrc,
      name,
      notificationsCount,
      followers,
      theme,
      onJoinTeam,
    } = props;

    const hasNotifications: boolean = !!notificationsCount;

    const classNames = useMemo(
      () =>
        cn(styles.SidebarItem, {
          [styles['SidebarItem--has-notification']]: hasNotifications,
          [styles['SidebarItem--is-active']]: isActive,
          [styles['SidebarItem--is-disabled']]: isDisabled,
          [styles['SidebarItem--is-mobile']]: isMobile,
          [styles['SidebarItem--is-root']]: isRoot,
          [styles['SidebarItem--theme-image']]: theme === SidebarItemTheme.Image,
          [styles['SidebarItem--theme-vector']]: theme === SidebarItemTheme.Vector,
          [styles['SidebarItem--is-nojoined']]: isNoJoined,
        }),
      [hasNotifications, isActive, isMobile, isRoot, isNoJoined, theme, isDisabled],
    );

    const handleJoinButtonClick = useCallback(
      (event: SyntheticEvent) => {
        event.stopPropagation();

        onJoinTeam?.();
      },
      [onJoinTeam],
    );

    const sidebarItemInfoNameClasses = useMemo(
      () =>
        cn(styles.SidebarItemInfo__Name, {
          [styles['SidebarItemInfo__Name--is-active']]: isActive,
        }),
      [isActive],
    );

    return (
      <li data-numnotifications={notificationsCount} className={classNames}>
        <button className={styles.SidebarItem__Clickable} onClick={props.onClick}>
          {!!imageSrc && <img src={imageSrc} alt={name} />}
          {!!name && isSetting && <span className={sidebarItemInfoNameClasses}>{name}</span>}
          {isMobile && (
            <div className={styles.SidebarItemInfo}>
              {!!name && <span className={styles.SidebarItemInfo__Name}>{name}</span>}
              <span className={styles.SidebarItemInfo__Followers}>{`${followers} members`}</span>
              {!!activityStatus || (
                <span className={styles.SidebarItemInfo__Status}>{activityStatus}</span>
              )}
            </div>
          )}
        </button>
        {isNeedPlusButton && (
          <div className={styles.PlusIcon}>
            <IconButton
              customColor="#F7FAFA"
              theme={IconButtonTheme.Secondary}
              onClick={handleJoinButtonClick}
              iconName={IconFontName.Add}
              iconSize={IconFontSize.Big}
            />
          </div>
        )}
      </li>
    );
  },
);
