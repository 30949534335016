import { memo, useCallback, useMemo } from 'react';

import {
  GLOBAL_STATS_EMPTY_STATE_MESSAGE,
  GLOBAL_STATS_EMPTY_STATE_TITLE,
} from 'stores/global-stats/global-stats.config';
import { ITeamsStatsLeaders } from 'stores/global-stats/interfaces/teams-stats-leaders.interface';

import { EmptyState } from 'components/empty-state/empty-state.component';
import { StatsLeadersCard } from 'components/ui/stats-leaders-card/stats-leaders-card.component';

import styles from '../stats-leaders.module.less';

interface IStatsTeamsLeadersProps {
  leaders: ITeamsStatsLeaders;
  onShowAllLeaders: (sortParam: string, direction: string) => void;
  onTeamClick: (teamId: number) => void;
}

export const StatsTeamsLeaders = memo((props: IStatsTeamsLeadersProps) => {
  const { leaders, onTeamClick } = props;

  const allEmpty = useMemo(() => {
    const values = Object.values(leaders);

    return values.every((value) => !value.length);
  }, [leaders]);

  const handleTeamClick = useCallback(
    (teamId: string) => {
      onTeamClick(Number(teamId));
    },
    [onTeamClick],
  );

  if (allEmpty) {
    return (
      <div className={styles.StatsLeaders__EmptyState}>
        <EmptyState
          title={GLOBAL_STATS_EMPTY_STATE_TITLE}
          message={GLOBAL_STATS_EMPTY_STATE_MESSAGE}
        />
      </div>
    );
  }

  return (
    <div className={styles.StatsLeaders}>
      <div className={styles.StatsLeaders__Section}>
        <div className={styles.StatsLeaders__Header}>Offensive Leaders</div>
        <StatsLeadersCard
          items={leaders.points}
          leaderByLong="Points"
          leaderByAbbreviation="PTS"
          leaderField="points"
          onShowAllLeaders={props.onShowAllLeaders}
          onRowClick={handleTeamClick}
        />
        <StatsLeadersCard
          items={leaders.fieldGoalsPercentage}
          leaderByLong="Field Goal %"
          leaderByAbbreviation="FGP"
          leaderField="fieldGoalsPercentage"
          onShowAllLeaders={props.onShowAllLeaders}
          onRowClick={handleTeamClick}
        />
        <StatsLeadersCard
          items={leaders.pointsDifferential}
          leaderByLong="Points Differential"
          leaderByAbbreviation="Diff"
          leaderField="pointsDifferential"
          onShowAllLeaders={props.onShowAllLeaders}
          onRowClick={handleTeamClick}
        />
      </div>

      <div className={styles.StatsLeaders__Section}>
        <div className={styles.StatsLeaders__Header}>Defensive Leaders</div>
        <StatsLeadersCard
          items={leaders.pointsAllowed}
          leaderByLong="Points Allowed"
          leaderByAbbreviation="PTA"
          leaderField="pointsAllowed"
          sortDirection="asc"
          onShowAllLeaders={props.onShowAllLeaders}
          onRowClick={handleTeamClick}
        />
        <StatsLeadersCard
          items={leaders.rebounds}
          leaderByLong="Rebounds"
          leaderByAbbreviation="REB"
          leaderField="totalRebounds"
          onShowAllLeaders={props.onShowAllLeaders}
          onRowClick={handleTeamClick}
        />
        <StatsLeadersCard
          items={leaders.blocks}
          leaderByLong="Blocks"
          leaderByAbbreviation="BLK"
          leaderField="blocks"
          onShowAllLeaders={props.onShowAllLeaders}
          onRowClick={handleTeamClick}
        />
      </div>
    </div>
  );
});
