import { addDays } from 'date-fns';
import format from 'date-fns/format';
import formatTz from 'date-fns-tz/format';
import { inject } from 'inversify';
import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { GamesDetailedService } from 'services/games-detailed/games-detailed.service';
import { IGameDetailedResponse } from 'services/games-detailed/interfaces/game-detailed-response.interface';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { IoMessage } from 'stores/io/enums/io-message.enum';
import { IoStore } from 'stores/io/io.store';
import { gameDetailedAdapter } from 'stores/scoreboard/adapters/game-detailed-adapter.util';
import { IGameDetailed } from 'stores/scoreboard/interfaces/game-detailed.interface';
import { statusWeights } from 'stores/statistics/adapters/games-adapter.util';
import { TeamsStore } from 'stores/teams/teams.store';

import { DATE_FORMAT, FULL_DATE_FORMAT, TIME_ZONE_FORMAT } from 'configs/date.config';
import { TYPES } from 'configs/di-types.config';
import { getFormattedTimeZone } from 'helpers/get-formatted-time-zone.util';

export class ScoreboardStore extends ApiConnectedStore {
  private readonly ioStore: IoStore;

  private readonly gamesDetailedService: GamesDetailedService;

  private readonly teamsStore: TeamsStore;

  public selectedDates: string[];

  public selectedDate: string;

  public isFetchingCurrentDate: boolean;

  public entries: IGameDetailed[];

  public timezone: string;

  constructor(
    @inject(TYPES.IoStore) ioStore: IoStore,
    @inject(TYPES.TeamsStore) teamsStore: TeamsStore,
    @inject(TYPES.GamesDetailedService) gamesDetailedService: GamesDetailedService,
  ) {
    super();

    this.ioStore = ioStore;

    this.teamsStore = teamsStore;

    this.gamesDetailedService = gamesDetailedService;

    this.selectedDates = [];

    this.isFetchingCurrentDate = false;

    this.selectedDate = '';

    this.entries = [];

    this.timezone = getFormattedTimeZone();

    makeObservable(this, {
      selectedDate: observable,
      selectedDates: observable,
      entries: observable,
      isFetchingCurrentDate: observable,

      formattedDates: computed,

      setEntries: action.bound,
      fetchCurrentDate: action.bound,
      retrieve: action.bound,
      retrieveAllGames: action.bound,
      retrieveByTeam: action.bound,
      setSelectedDate: action.bound,
      setSelectedDates: action.bound,
      setIsFetchingCurrentDate: action.bound,
      updateSubscriptions: action.bound,
      handleGameStatisticsDetailedUpdated: action.bound,
      handleSelectedDateChange: action.bound,
    });

    reaction(() => [this.selectedDates, this.teamsStore.teamId], this.retrieve);

    reaction(() => this.ioStore.socket, this.updateSubscriptions);

    reaction(() => this.selectedDate, this.handleSelectedDateChange);

    autorun(() => this.updateSubscriptions());

    autorun(() => this.fetchCurrentDate());
  }

  public async fetchCurrentDate() {
    this.setIsFetchingCurrentDate(true);

    const timezone = formatTz(new Date(), TIME_ZONE_FORMAT);
    const dateResponse = await this.gamesDetailedService.fetchNearestDate(timezone);

    if (dateResponse.success) {
      this.setSelectedDate(dateResponse.data.date);
    }

    this.setIsFetchingCurrentDate(false);
  }

  public updateSubscriptions(): void {
    this.ioStore.socket.on(
      IoMessage.GameStatisticsDetailedUpdated,
      this.handleGameStatisticsDetailedUpdated,
    );
  }

  public handleGameStatisticsDetailedUpdated = (rawScoreboardGame: IGameDetailedResponse) => {
    const newGame = gameDetailedAdapter(rawScoreboardGame);

    this.setEntries(
      this.entries.map((existingGame) => {
        if (existingGame.id === newGame.id) {
          return newGame;
        }

        return existingGame;
      }),
    );
  };

  public get formattedDates(): string[] {
    return this.selectedDates.map((date) => format(new Date(date), FULL_DATE_FORMAT));
  }

  public setEntries(entities: IGameDetailed[]) {
    this.entries = entities;
  }

  public setIsFetchingCurrentDate(value: boolean) {
    this.isFetchingCurrentDate = value;
  }

  public setSelectedDates(dates: string[]) {
    this.selectedDates = dates;
  }

  public handleSelectedDateChange() {
    const dateObj = new Date(this.selectedDate);
    const newDates = [
      dateObj.toISOString(),
      addDays(dateObj, 1).toISOString(),
      addDays(dateObj, 2).toISOString(),
      addDays(dateObj, 3).toISOString(),
      addDays(dateObj, 4).toISOString(),
      addDays(dateObj, 5).toISOString(),
      addDays(dateObj, 6).toISOString(),
    ];

    this.setSelectedDates(newDates);
  }

  public setSelectedDate(dateIso: string) {
    this.selectedDate = dateIso;
  }

  public async retrieve() {
    if (this.selectedDates.length) {
      if (this.teamsStore.teamId) {
        await this.retrieveByTeam();
      } else {
        await this.retrieveAllGames();
      }
    }
  }

  public async retrieveAllGames() {
    this.resetErrors();
    this.setFetched(false);
    this.setFetching(true);

    const startDate = format(new Date(this.formattedDates[0]), DATE_FORMAT);
    const endDate = format(
      new Date(this.formattedDates[this.formattedDates.length - 1]),
      DATE_FORMAT,
    );
    const timezone = formatTz(new Date(), TIME_ZONE_FORMAT);

    const gamesDetailedResponse = await this.gamesDetailedService.retrieve(
      startDate,
      timezone,
      endDate,
    );

    if (gamesDetailedResponse.success) {
      const newGames = gamesDetailedResponse.data.map(gameDetailedAdapter);
      this.setEntries(newGames.sort((a, b) => statusWeights[b.status] - statusWeights[a.status]));
    }

    this.setFetched(true);
    this.setFetching(false);
  }

  public async retrieveByTeam() {
    this.resetErrors();
    this.setFetched(false);
    this.setFetching(true);

    if (this.teamsStore.teamId) {
      const startDate = format(new Date(this.formattedDates[0]), DATE_FORMAT);
      const endDate = format(
        new Date(this.formattedDates[this.formattedDates.length - 1]),
        DATE_FORMAT,
      );
      const timezone = formatTz(new Date(), TIME_ZONE_FORMAT);

      const gamesDetailedResponse = await this.gamesDetailedService.retrieveForTeam(
        this.teamsStore.teamId,
        startDate,
        timezone,
        endDate,
      );

      if (gamesDetailedResponse.success) {
        const newGames = gamesDetailedResponse.data.map(gameDetailedAdapter);
        this.setEntries(newGames.sort((a, b) => statusWeights[b.status] - statusWeights[a.status]));
      }
    }

    this.setFetched(true);
    this.setFetching(false);
  }

  public reset() {
    super.reset();

    this.setEntries([]);
  }
}
