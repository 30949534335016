import { memo, useMemo } from 'react';
import cn from 'classnames';

import loseIcon from 'assets/images/svg/loss.svg';
import etheriumIcon from 'assets/images/svg/vector-icons/etherium.svg';
import fireIcon from 'assets/images/svg/vector-icons/fire.svg';
import googleIcon from 'assets/images/svg/vector-icons/google.svg';
import rankingBronzeIcon from 'assets/images/svg/vector-icons/ranking-bronze.svg';
import rankingGoldIcon from 'assets/images/svg/vector-icons/ranking-gold.svg';
import rankingSilverIcon from 'assets/images/svg/vector-icons/ranking-silver.svg';
import winnerIcon from 'assets/images/svg/vector-icons/winner.svg';
import winIcon from 'assets/images/svg/win.svg';

import styles from './vector-icon.module.less';

export enum VectorIconName {
  Etherium = 'etherium',
  Fire = 'fire',
  Winner = 'winner',
  Google = 'google',
  RankingGold = 'rankingGold',
  RankingSilver = 'rankingSilver',
  RankingBronze = 'rankingBronze',
  Lose = 'lose',
  Win = 'win',
}

export enum VectorIconSize {
  M = 'M',
  S = 'S',
  XS = 'XS',
}

const ICON_ASSETS_MAP: Record<VectorIconName, string> = {
  [VectorIconName.Etherium]: etheriumIcon,
  [VectorIconName.Fire]: fireIcon,
  [VectorIconName.Winner]: winnerIcon,
  [VectorIconName.Google]: googleIcon,
  [VectorIconName.RankingGold]: rankingGoldIcon,
  [VectorIconName.RankingSilver]: rankingSilverIcon,
  [VectorIconName.RankingBronze]: rankingBronzeIcon,
  [VectorIconName.Lose]: loseIcon,
  [VectorIconName.Win]: winIcon,
};

interface IVectorIconProps {
  name: VectorIconName;
  size?: VectorIconSize;
}

export const VectorIcon = memo((props: IVectorIconProps) => {
  const { name, size = VectorIconSize.M } = props;

  const asset = useMemo<string>(() => ICON_ASSETS_MAP[name], [name]);

  const classNames = useMemo<string>(
    () =>
      cn(styles.VectorIcon, {
        [styles['VectorIcon--size-m']]: size === VectorIconSize.M,
        [styles['VectorIcon--size-s']]: size === VectorIconSize.S,
        [styles['VectorIcon--size-xs']]: size === VectorIconSize.XS,
      }),
    [size],
  );

  return <span className={classNames} style={{ backgroundImage: `url(${asset})` }} />;
});
