import { initReactI18next } from 'react-i18next';
import { App, BackButtonListenerEvent } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { StatusBar } from '@capacitor/status-bar';
import { RateApp } from 'capacitor-rate-app';
import i18next from 'i18next';
import { inject, injectable } from 'inversify';
import { applicationLogger } from 'loggers/application.logger';
import { action, autorun, computed, makeObservable, observable } from 'mobx';
import { NotificationClickEvent } from 'onesignal-cordova-plugin';
import { TPlatformName } from 'types/platform-name.type';

import { ApplicationService } from 'services/application/application.service';
import { IUploadImageData } from 'services/application/interfaces/upload-image.interface';
import AppsFlyerService from 'services/apps-flyer/apps-flyer.service';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { PermissionPopups } from 'services/local-database/config';
import { LocalDatabaseService } from 'services/local-database/local-database.service';
import { PushNotificationService } from 'services/push-notification/push-notification.service';
import { IReportReason } from 'services/reports/interfaces/report-reason.interface';
import { ReportsService } from 'services/reports/reports.service';
import { StorageField } from 'services/storage/enum/storage-field.enum';
import { StorageService } from 'services/storage/storage.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';

import { REVIEW_PROMPT_SHOWN_ID, REVIEW_PROMPT_VERSION_COUNT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { DEFAULT_LANGUAGE, Language } from 'configs/i18n.config';
import { customFormData } from 'helpers/custom-form-data.util';
import { toBase64 } from 'helpers/to-base64.util';

@injectable()
export class ApplicationStore extends ApiConnectedStore {
  private readonly storageService: StorageService;

  private readonly applicationService: ApplicationService;

  private readonly localDatabaseService: LocalDatabaseService;

  private readonly pushNotificationService: PushNotificationService;

  private readonly reportsService: ReportsService;

  public appId: string;

  public currentLanguage: Language;

  public platformName: TPlatformName;

  public isAllowedPushNotification: boolean;

  public isShowCustomNotificationPopup: boolean;

  public reportReasons: Maybe<IReportReason[]>;

  constructor(
    @inject(TYPES.StorageService) storageService: StorageService,
    @inject(TYPES.LocalDatabaseService) localDatabaseService: LocalDatabaseService,
    @inject(TYPES.ApplicationService) applicationService: ApplicationService,
    @inject(TYPES.PushNotificationService) pushNotificationService: PushNotificationService,
    @inject(TYPES.ReportsService) reportsService: ReportsService,
  ) {
    super();

    this.storageService = storageService;

    this.applicationService = applicationService;

    this.localDatabaseService = localDatabaseService;

    this.pushNotificationService = pushNotificationService;

    this.reportsService = reportsService;

    this.appId = '';

    this.currentLanguage = DEFAULT_LANGUAGE;

    this.platformName = 'web';

    this.isAllowedPushNotification = false;

    this.isShowCustomNotificationPopup = false;

    this.reportReasons = null;

    makeObservable(this, {
      currentLanguage: observable,
      platformName: observable,
      appId: observable,
      isAllowedPushNotification: observable,
      reportReasons: observable,
      isShowCustomNotificationPopup: observable,

      isNativeApp: computed,
      isNativeAndroidApp: computed,

      setPlatformName: action.bound,
      setCurrentLanguage: action.bound,
      setAppId: action.bound,
      setAllowedPushNotification: action.bound,
      convertImageItemToAttachment: action.bound,
      setReportReasons: action.bound,
      setIsShowCustomNotificationPopup: action.bound,
      disableNotificationPopup: action.bound,
    });

    autorun(() => this.listenHardwareBack());
    autorun(() => this.localDatabaseService.configureStorage());
    autorun(() => this.retrieveReportReasons());
    autorun(() => this.checkShowingCustomNotificationPopup());
  }

  private async checkShowingCustomNotificationPopup() {
    if (this.isNativeApp) {
      const wasNotificationPopupShown = await this.localDatabaseService.getPopupsByKey(
        PermissionPopups.NOTIFICATION,
      );

      if (this.pushNotificationService.hasPermissions && !wasNotificationPopupShown?.value) {
        await this.disableNotificationPopup();
        return;
      }

      if (!wasNotificationPopupShown?.value) {
        this.setIsShowCustomNotificationPopup(true);
      }
    }
  }

  public async disableNotificationPopup() {
    await this.localDatabaseService.updatePopupsTableByKey(PermissionPopups.NOTIFICATION, true);
  }

  private async triggerReviewPrompt() {
    if (!this.isNativeApp) return;

    const versionState: Maybe<string> = await this.storageService.get(StorageField.AppVersionState);

    if (versionState === REVIEW_PROMPT_SHOWN_ID) return;

    const versionCollection: string[] = versionState ? versionState.split(',') : [VERSION];

    if (!versionCollection.includes(VERSION)) {
      versionCollection.push(VERSION);
    }

    if (versionCollection.length === REVIEW_PROMPT_VERSION_COUNT) {
      GoogleAnalyticService.event({
        eventName: 'rate_prompt_view',
        eventParams: {},
      });
      RateApp.requestReview();
      this.storageService.set(StorageField.AppVersionState, REVIEW_PROMPT_SHOWN_ID);
    } else {
      this.storageService.set(StorageField.AppVersionState, versionCollection.toString());
    }
  }

  private listenHardwareBack() {
    App.addListener('backButton', (event: BackButtonListenerEvent) => {
      if (event.canGoBack) {
        window.history.back();
      } else {
        App.exitApp();
      }
    });
  }

  private async retrieveReportReasons() {
    this.setErrors([]);

    const response = await this.reportsService.fetchReportReasons();

    if (response.success) {
      this.setReportReasons(response.data);
      return;
    }

    this.setErrors(response.errors);
  }

  private getDeviceInfo() {
    return Device.getInfo();
  }

  private async getDeviceId() {
    const appId = await Device.getId();

    return appId.identifier;
  }

  private async fetchTranslations(language: Language): Promise<Record<string, any>> {
    let translations;

    try {
      translations = await import(`i18n/${this.currentLanguage}.json`);
    } catch (error) {
      applicationLogger.error({ msg: 'Cannot fetch translations', language });
    }

    if (typeof translations.default !== 'object') {
      return {};
    }

    return translations.default;
  }

  private async configureStatusBarOverlays() {
    await StatusBar.setOverlaysWebView({
      overlay: true,
    });
  }

  private async configureStorage() {
    await this.storageService.configureLocalStorage();
  }

  private async retrieveAppId() {
    const appId = await this.getDeviceId();

    this.setAppId(appId);
  }

  public async convertImageItemToAttachment(payload: File): Promise<Maybe<IUploadImageData>> {
    const formData = await customFormData(payload, 'file', 'base64File', this.isNativeApp);
    const base64String = await toBase64(payload);
    const imageUrl = base64String?.value || '';
    const response = await this.applicationService.uploadImage(formData);

    if (response.success) {
      return {
        hash: response.data.hash,
        mimeType: response.data.mime_type,
        filename: response.data.filename,
        url: imageUrl,
        uuid: response.data.uuid,
      };
    }
    this.setErrors(response.errors);

    return null;
  }

  private async retrievePlatformName() {
    const deviceInfo = await this.getDeviceInfo();

    this.setPlatformName(deviceInfo.platform);
  }

  public get isNativeApp(): boolean {
    return this.platformName === 'ios' || this.platformName === 'android';
  }

  public get isNativeAndroidApp(): boolean {
    return this.platformName === 'android';
  }

  public async initialise() {
    applicationLogger.info({ msg: 'Start initialisation' });

    this.setFetching(true);

    try {
      await this.configureStorage();
      await this.retrieveAppId();
      await this.initialiseI18n();
      await this.retrievePlatformName();
      await this.triggerReviewPrompt();

      AppsFlyerService.initialisation();

      if (this.isNativeAndroidApp) {
        await this.configureStatusBarOverlays();
      }
    } catch (error) {
      applicationLogger.error({ msg: 'Cannot initialise application store', error });

      this.setErrors([{ message: 'Cannot initialise application' }]);
    } finally {
      this.setFetched(true);
      this.setFetching(false);
    }

    applicationLogger.info({ msg: 'End i18n initialisation' });
  }

  public async initialiseI18n() {
    applicationLogger.info({ msg: 'Start i18n initialisation' });

    this.setFetching(true);

    try {
      const translations = await this.fetchTranslations(this.currentLanguage);

      applicationLogger.debug({
        msg: 'Translations has been loaded',
        translations,
        currentLanguage: this.currentLanguage,
      });

      await i18next.use(initReactI18next).init({
        resources: {
          [this.currentLanguage]: translations,
        },
        lng: this.currentLanguage,
        fallbackLng: DEFAULT_LANGUAGE,
      });
    } catch (error) {
      applicationLogger.error({ msg: 'Cannot initialise i18n', error });
    } finally {
      this.setFetching(false);
    }

    applicationLogger.info({ msg: 'End i18n initialisation' });
  }

  public initialisePushNotification() {
    this.pushNotificationService.initialise();
  }

  public async requestPushNotificationPermission() {
    const accepted = await this.pushNotificationService.requestPermission();
    this.setAllowedPushNotification(accepted);
  }

  public handlePushNotificationClick(callback: (event: NotificationClickEvent) => void) {
    this.pushNotificationService.handleNotificationClick(callback);
  }

  public handlePushNotificationPermissionChange(callback: (event: boolean) => void) {
    this.pushNotificationService.handlePermissionChange(callback);
  }

  public get hasPushNotificationPermissions(): boolean {
    return this.pushNotificationService.hasPermissions;
  }

  public loginPushNotificationUser(userID: string) {
    this.pushNotificationService.loginUser(userID);
  }

  public logoutPushNotificationUser() {
    this.pushNotificationService.logoutUser();
  }

  public setCurrentLanguage(currentLanguage: Language) {
    applicationLogger.debug({ msg: 'Set current language', currentLanguage });

    this.currentLanguage = currentLanguage;
  }

  public setAppId(appId: string) {
    this.appId = appId;
  }

  public setPlatformName(platformName: TPlatformName) {
    this.platformName = platformName;
  }

  public setAllowedPushNotification(isAllowedPushNotification: boolean) {
    this.isAllowedPushNotification = isAllowedPushNotification;
  }

  public setReportReasons(reasons: IReportReason[]) {
    this.reportReasons = reasons;
  }

  public setIsShowCustomNotificationPopup(value: boolean) {
    this.isShowCustomNotificationPopup = value;
  }
}
