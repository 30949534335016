export const DAY_FORMAT = 'MMM d';

export const DATE_FORMAT = 'yyyy-MM-dd';

export const GAME_DATE_FORMAT = 'MMMM dd, yyyy';

export const GAME_DATE_TIME_FORMAT = 'h:mm aa, MMMM dd, yyyy';

export const ARENA_GAME_ITEM_FORMAT = 'MM-dd-yy';

export const FULL_DATE_FORMAT = 'EEEE, LLLL, d, yyyy';

export const POST_DATE_TIME_FORMAT = 'h:mm aa · LLL d, y';

export const POST_TIME_ONLY_FORMAT = 'h:mm aa';

export const TIME_FORMAT = 'h:mm aa';

export const TIME_ZONE_FORMAT = 'xxx';

export const SHORT_WEEKDAY_FORMAT = 'EEEEE';

export const SHORT_DAY_WITH_DATE_FORMAT = 'E M/d';

export const FULL_DAY_WITH_DATE_FORMAT = 'EEE M/d';

export const CODE_EXPIRATION_INTERVAL_IN_MILLISECONDS = 60 * 1000;
