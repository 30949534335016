import { FC, useCallback, useMemo } from 'react';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import desktopImageSrc from 'assets/images/application-crash-desktop.svg';
import mobileImageSrc from 'assets/images/application-crash-mobile.svg';

import styles from './application-crash.module.less';

export const ApplicationCrash: FC = () => {
  const handleReload = useCallback(() => {
    window.location.href = '/';
  }, []);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const imageSrc = useMemo(
    () => (isDesktopPlus ? desktopImageSrc : mobileImageSrc),
    [isDesktopPlus],
  );

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Content}>
        <div className={styles.Content__Side}>
          <h4 className={styles.ContentSide__Heading}>Application crash</h4>
          <p className={styles.ContentSide__Message}>
            Something went wrong. Please try again later
          </p>
          <Button
            theme={ButtonTheme.Secondary}
            size={ButtonSize.SmallSecondary}
            onClick={handleReload}
          >
            Reload
          </Button>
        </div>
        <img src={imageSrc} alt="Crash" />
      </div>
    </div>
  );
};
