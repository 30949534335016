import { inject, injectable } from 'inversify';

import {
  ImageUploadPayloadType,
  IUploadImageResponse,
} from 'services/application/interfaces/upload-image.interface';
import { HttpService } from 'services/http/http.service';

import { TYPES } from 'configs/di-types.config';

import { IMAGES_UPLOAD } from '../http/consts/api-endpoints.constants';

@injectable()
export class ApplicationService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public uploadImage(payload: ImageUploadPayloadType): Promise<IResponse<IUploadImageResponse>> {
    return this.httpService.request<IUploadImageResponse>({
      url: IMAGES_UPLOAD,
      method: 'POST',
      body: payload,
      isMultipart: true,
    });
  }
}
