import { IGifAttachmentResponse } from 'services/posts/interfaces/post-attachments-response.interface';
import { IGifAttachment } from '../interfaces/post.interface';

export function gifAdapter(gifResponse: IGifAttachmentResponse): IGifAttachment {
  return {
    dateCreated: gifResponse.date_created,
    type: 'gif',
    uuid: gifResponse.uuid,
    url: gifResponse.giphy_id,
    mimeType: '',
    filename: '',
  };
}
