import { StatsMode } from './enums/global-stats.enums';
import { IGlobalStatsPlayersFilters } from './interfaces/global-stats-players-filters.interface';

export const STATS_SWITCHER_OPTIONS = [
  {
    id: StatsMode.PLAYERS,
    element: 'Players',
  },
  {
    id: StatsMode.TEAMS,
    element: 'Teams',
  },
];

export const DEFAULT_GLOBAL_STATS_PLAYER_FILTERS: IGlobalStatsPlayersFilters = {
  position: 'ALL',
  teamId: null,
};

export const DEFAULT_STATS_EMPTY_STATE_OBJECT = {
  [StatsMode.TEAMS]: false,
  [StatsMode.PLAYERS]: false,
};

export const SORT_SEARCH_PARAM = 'sort';
export const PLAYER_SLUG_SEARCH_PARAM = 'playerSlug';
export const SORT_DIRECTION_PARAM = 'direction';

export const SORT_DIRECTIONS = ['asc', 'desc'];

export const PLAYER_GLOBAL_STATS_SORT_VALUES = [
  'assists',
  'blocks',
  'defensiveReb',
  'fieldGoalsAttempted',
  'fieldGoalsMade',
  'fieldGoalsPercentage',
  'freeThrowsAttempted',
  'freeThrowsMade',
  'freeThrowsPercentage',
  'gamesPlayed',
  'offensiveReb',
  'personalFouls',
  'points',
  'steals',
  'threePointsFieldGoalsAttempted',
  'threePointsFieldGoalsMade',
  'threePointsFieldGoalsPercentage',
  'totalRebounds',
  'turnovers',
];

export const TEAMS_GLOBAL_STATS_SORT_VALUES = [
  ...PLAYER_GLOBAL_STATS_SORT_VALUES,
  'pointsAllowed',
  'pointsDifferential',
];

export const GLOBAL_STATS_EMPTY_STATE_TITLE = 'No Data for the Selected Season';

export const GLOBAL_STATS_EMPTY_STATE_MESSAGE = 'Unfortunately, there is no data for this season';
