import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

export const useIframeError = (): [MutableRefObject<Maybe<HTMLIFrameElement>>, boolean] => {
  const [hasError, setHasError] = useState(false);
  const ref = useRef<Maybe<HTMLIFrameElement>>(null);

  const handleMessage = useCallback((event: MessageEvent) => {
    if (
      event.data &&
      event.data.eventName === 'FlockerEmbedVideoIframeError' &&
      event.source === ref.current?.contentWindow
    ) {
      setHasError(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  return [ref, hasError];
};
