import { useMemo } from 'react';
import cn from 'classnames';

import { BaseBottomSheet } from 'components/bottom-sheet/base-bottom-sheet/base-bottom-sheet.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { Portal, PortalType } from 'components/ui/portal/portal.component';

import logoBg from 'assets/images/svg/logo-bg.svg';

import styles from './deeplink-banner-bottom-sheet.module.less';

interface IDeeplinkBannerBottomSheetProps {
  visible: boolean;
  avatarSrc: Maybe<string>;
  isBottomBarHidden: boolean;
  isAnySidebarOpen: boolean;
  onClose: () => void;
  onOpenApp: () => void;
}

export const DeeplinkBannerBottomSheet = (props: IDeeplinkBannerBottomSheetProps) => {
  const { visible, isBottomBarHidden, isAnySidebarOpen, avatarSrc } = props;

  const classDeeplinkBannerWrapperNames = useMemo(
    () =>
      cn(styles.DeeplinkBannerWrapper, {
        [styles['DeeplinkBannerWrapper--smooth-hidden']]: isAnySidebarOpen,
      }),
    [isAnySidebarOpen],
  );

  return (
    <Portal type={PortalType.BottomSheet}>
      <div className={classDeeplinkBannerWrapperNames}>
        <BaseBottomSheet
          visible={visible}
          isBottomBarHidden={isBottomBarHidden}
          hasBottomBar
          hasContainerShadow
        >
          <div className={styles.DeeplinkBannerBottomSheet}>
            <div className={styles.DeeplinkBannerBottomSheet__Avatar}>
              <Avatar src={avatarSrc || logoBg} size={AvatarSize.XXL} username="Avatar" />
            </div>
            <span className={styles.DeeplinkBannerBottomSheet__Title}>
              Discover all of Digits in the app
            </span>
            <span className={styles.DeeplinkBannerBottomSheet__Subtitle}>
              Your all-in-one NBA hub awaits
            </span>
            <Button size={ButtonSize.Small} theme={ButtonTheme.Primary} onClick={props.onOpenApp}>
              Get the Digits app
            </Button>
            <Button size={ButtonSize.Big} theme={ButtonTheme.Text} onClick={props.onClose}>
              Not now
            </Button>
          </div>
        </BaseBottomSheet>
      </div>
    </Portal>
  );
};
