import { IFollowPayload } from 'services/follow/interfaces/followings-response.interface';

import { IFollow } from 'stores/follow/interfaces/follow.interface';

export const createFollowPayload = (
  teams: IFollow[],
  players: IFollow[],
  initial: IFollow[],
): IFollowPayload => {
  const nonDigitRegexp = /\D/g;
  const parsedTeams = teams.map((item) => {
    return { ...item, id: Number(item.id.replace(nonDigitRegexp, '')) };
  });
  const parsedPlayers = players.map((item) => {
    return { ...item, id: Number(item.id.replace(nonDigitRegexp, '')) };
  });
  const initialParsed = initial.map((item) => {
    return { ...item, id: Number(item.id.replace(nonDigitRegexp, '')) };
  });

  const currentFollowTeamsId = parsedTeams.filter((item) => item.isFollow).map((item) => item.id);
  const currentFollowPlayersId = parsedPlayers
    .filter((item) => item.isFollow)
    .map((item) => item.id);

  const initialFollowTeamsId = initialParsed
    .filter((item) => item.type === 'team' && item.isFollow)
    .map((item) => Number(item.id));

  const initialFollowPlayersId = initialParsed
    .filter((item) => item.type === 'player' && item.isFollow)
    .map((item) => Number(item.id));

  const playerSet = new Set(currentFollowPlayersId);
  const teamSet = new Set(currentFollowTeamsId);

  const unfollowPlayers = initialFollowPlayersId.filter((item) => !playerSet.has(item));
  const unfollowTeams = initialFollowTeamsId.filter((item) => !teamSet.has(item));

  return {
    followTeams: currentFollowTeamsId.filter((item) => !initialFollowTeamsId.includes(item)),
    followPlayers: currentFollowPlayersId.filter((item) => !initialFollowPlayersId.includes(item)),
    unFollowTeams: unfollowTeams,
    unFollowPlayers: unfollowPlayers,
  };
};
