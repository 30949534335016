import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { FollowStore } from 'stores/follow/follow.store';
import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutHeaderLeftActionEnum } from 'stores/layout/interfaces/layout-header-left.interface';
import { LayoutStore } from 'stores/layout/layout.store';
import { PlayerStore } from 'stores/player/player.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useLayoutEntity } from 'hooks/use-layout-entity';

import { PlayerProfileHeader } from 'components/player-profile/player-profile-header/player-profile-header.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import { getPlayerProfileRouteTabs } from './player-profile-tabs.util';

export const PlayerProfileContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const followStore = useInjection<FollowStore>(TYPES.FollowStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);

  const params = useParams<{
    [paths.PLAYER_SLUG_PARAM]: string;
    [paths.POST_ID_PARAM]: string;
  }>();

  const { playerSlug, postId } = params;

  const navigate = useNavigate();
  const location = useLocation();

  const handleFollowPlayer = useCallback(() => {
    if (authStore.triggerAuthorisationCheck()) {
      playerStore.followPlayer();
    }
  }, [playerStore, authStore]);

  const handleUnfollowPlayer = useCallback(() => {
    playerStore.unfollowPlayer();
  }, [playerStore]);

  const renderHeader = useCallback(() => {
    const player = playerStore.playerDetails;

    if (player && !postId) {
      return (
        <PlayerProfileHeader
          isFollowFetched={!!followStore.follow.length}
          isFollowedPlayer={playerStore.isFollowedPlayer}
          isFollowFetching={followStore.fetching}
          avatarUrl={player.mediumLogoUrl}
          firstname={player.firstname}
          lastname={player.lastname}
          onFollowPlayer={handleFollowPlayer}
          onUnfollowPlayer={handleUnfollowPlayer}
        />
      );
    }

    return null;
  }, [
    postId,
    playerStore.playerDetails,
    playerStore.isFollowedPlayer,
    followStore.fetching,
    followStore.follow,
    handleFollowPlayer,
    handleUnfollowPlayer,
  ]);

  const playerProfileTabs = useMemo(() => {
    const details = playerStore.playerDetails;

    if (details) {
      return getPlayerProfileRouteTabs(details.slug, details.hasFeed);
    }

    return null;
  }, [playerStore.playerDetails]);

  useLayoutEntity({
    type: LayoutEntity.Tabs,
    value: playerProfileTabs,
  });

  useLayoutEntity({
    type: LayoutEntity.HeaderCenter,
    value: renderHeader,
  });

  useLayoutEntity({
    type: LayoutEntity.HeaderLeft,
    value: { icon: IconFontName.ChevronLeft, type: LayoutHeaderLeftActionEnum.Back },
  });

  useEffect(() => {
    if (playerSlug) {
      playerStore.reset();
      teamsStatsStore.setTeamsGamesDetail([]);
      playerStore.setPlayerSlug(playerSlug);
    }

    layoutStore.setSidePanelActiveTab(null);
  }, [layoutStore, playerSlug, playerStore, teamsStatsStore]);

  useEffect(() => {
    const details = playerStore.playerDetails;

    if (details && !details.hasFeed) {
      const feedPath = getPath(paths.PLAYER_PROFILE_FEED, {
        [paths.PLAYER_SLUG_PARAM]: details.slug,
      });

      if (feedPath === location.pathname) {
        navigate(
          getPath(paths.PLAYER_PROFILE_OVERFIEW, {
            [paths.PLAYER_SLUG_PARAM]: details.slug,
          }),
          { replace: true },
        );
      }
    }
  }, [playerStore.playerDetails, location.pathname, navigate]);

  useEffect(() => {
    if (playerStore.playerDetails) {
      const { firstname, lastname, hasFeed } = playerStore.playerDetails;

      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.PlayerProfile,
          player: `${firstname} ${lastname}`,
          is_top_player: Number(hasFeed),
        },
      });
    }
  }, [playerStore.playerDetails]);

  useEffect(() => {
    if (playerStore.playerDetails?.team) {
      document.documentElement.style.setProperty(
        '--player-primary-color',
        playerStore.playerDetails.team.color,
      );
      document.documentElement.style.setProperty(
        '--player-text-color',
        playerStore.playerDetails.team.textColor,
      );
      document.documentElement.style.setProperty(
        '--player-secondary-text-color',
        playerStore.playerDetails.team.secondaryTextColor,
      );
    }

    return () => {
      document.documentElement.style.removeProperty('--player-primary-color');
      document.documentElement.style.removeProperty('--player-text-color');
      document.documentElement.style.removeProperty('--player-secondary-text-color');
    };
  }, [playerStore.playerDetails]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--team-primary-color',
      playerStore.playerDetails?.team?.color || null,
    );
    document.documentElement.style.setProperty(
      '--team-secondary-color',
      playerStore.playerDetails?.team?.textColor || null,
    );

    return () => {
      document.documentElement.style.removeProperty('--team-primary-color');
      document.documentElement.style.removeProperty('--team-secondary-color');
    };
  }, [playerStore.playerDetails?.team]);

  useEffect(() => {
    playerStore.setPageActive(true);

    return () => {
      playerStore.setPageActive(false);
    };
  }, [playerStore]);

  return null;
});
