import { FC } from 'react';

import { IReportReason } from 'services/reports/interfaces/report-reason.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { ReportsIonBottomSheet } from 'components/bottom-sheet/reports-ion-bottom-sheet/reports-ion-bottom-sheet.component';
import { ReportsModal } from 'components/modals/reports-modal/reports-modal.component';

interface IReportsPopupProps {
  visible: boolean;
  reasons: IReportReason[];
  onClose: () => void;
  onSendReport?: (reasonId: number) => Promise<boolean>;
}

export const ReportsPopup: FC<IReportsPopupProps> = (props) => {
  const { visible, reasons } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  if (isDesktopPlus) {
    return (
      <ReportsModal
        visible={visible}
        reasons={reasons}
        onClose={props.onClose}
        onSendReport={props.onSendReport}
      />
    );
  }

  return (
    <ReportsIonBottomSheet
      visible={visible}
      reasons={reasons}
      onClose={props.onClose}
      onSendReport={props.onSendReport}
    />
  );
};
