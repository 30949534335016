import { FC } from 'react';

import { PlayerProfileGamesContainer } from 'containers/player-profile/player-profile-games/player-profile-games.container';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const PlayerProfileGamesRoute: FC = () => {
  return (
    <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
      <Column mobile={1} desktop={{ start: 1, end: 3 }}>
        <PlayerProfileGamesContainer />
      </Column>
    </Row>
  );
};
