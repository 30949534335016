import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: Maybe<number>) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };

    // DELAY is FLEXIBLE value and could be changed conditionally with custom implementation (for example from input)
    // You SHOULD specify delay as a NULL to prevent creating new timers that could lead to memory leaks
    if (typeof delay === 'number') {
      const interval = setInterval(tick, delay);
      return () => clearInterval(interval);
    }

    return undefined;
  }, [delay]);
};
