import { SeasonType } from 'services/seasons/enums/season-type.enum';

import { CareerPerMode } from 'stores/player/enums/career-per-mode.enum';

import { NULL_TEAM_OPTION_COLOR, NULL_TEAM_OPTION_TEXT_COLOR } from 'configs/controls.config';

import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';

import nbaLogo from 'assets/images/svg/nba-default-image.svg';

export const DEFAULT_CAREER_SEASON_TYPE_OPTION = { label: 'Regular Season', value: SeasonType.REG };

export const CAREER_SEASON_TYPE_OPTIONS: ISelectOption[] = [
  { label: 'Preseason', value: SeasonType.PRE },
  DEFAULT_CAREER_SEASON_TYPE_OPTION,
  { label: 'Playoffs', value: SeasonType.PST },
  { label: 'Play In', value: SeasonType.PIT },
  { label: 'In-Season Tournament', value: SeasonType.IST },
];

export const DEFAULT_CAREER_PER_MODE_OPTION = { label: 'Per Game', value: CareerPerMode.PerGame };

export const CAREER_PER_MODE_OPTIONS: ISelectOption[] = [
  { label: 'Totals', value: CareerPerMode.Totals },
  DEFAULT_CAREER_PER_MODE_OPTION,
  { label: 'Per 48 Minutes', value: CareerPerMode.Per48 },
  { label: 'Per 40 Minutes', value: CareerPerMode.Per40 },
  { label: 'Per 36 Minutes', value: CareerPerMode.Per36 },
  { label: 'Per 1 Minute', value: CareerPerMode.Per1 },
  { label: 'Minutes Per', value: CareerPerMode.MinutesPer },
];

const defaultDropdownItem = {
  color: NULL_TEAM_OPTION_COLOR,
  secondaryTextColor: NULL_TEAM_OPTION_TEXT_COLOR,
  logo: null,
  value: null,
  code: '',
  label: '',
  mediumLogoUrl: '',
  smallLogoUrl: '',
  nickname: '',
  name: '',
};

export const GAMES_LIMIT_FILTER_OPTIONS = [
  {
    ...defaultDropdownItem,
    code: 'All',
    name: 'All',
    value: null,
    smallLogoUrl: nbaLogo,
    label: 'All Games Monthly Interval',
  },
  {
    ...defaultDropdownItem,
    code: '5',
    name: '5',
    value: 5,
    label: '5 Games Interval',
  },
  {
    ...defaultDropdownItem,
    code: '10',
    name: '10',
    value: 10,
    label: '10 Games Interval',
  },
  {
    ...defaultDropdownItem,
    code: '15',
    name: '15',
    value: 15,
    label: '15 Games Interval',
  },
  {
    ...defaultDropdownItem,
    code: '20',
    name: '20',
    value: 20,
    label: '20 Games Interval',
  },
  {
    ...defaultDropdownItem,
    code: '25',
    name: '25',
    value: 25,
    label: '25 Games Interval',
  },
];
