import { FC, useMemo } from 'react';
import cn from 'classnames';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import { IGameWithStats } from 'stores/game/interfaces/game.interface';

import styles from './game-information.module.less';

interface IGameInformationProps {
  game: IGameWithStats;
  homeIsWinning: boolean;
}

export const GameInformation: FC<IGameInformationProps> = (props) => {
  const { game, homeIsWinning } = props;

  const homeTeamClassNames = useMemo(
    () => cn(styles.PointsRow, styles.Row, { [styles['PointsRow--winning']]: homeIsWinning }),
    [homeIsWinning],
  );

  const visitorsTeamClassNames = useMemo(
    () => cn(styles.PointsRow, styles.Row, { [styles['PointsRow--winning']]: !homeIsWinning }),
    [homeIsWinning],
  );

  const informationSectionClassNames = useMemo(
    () =>
      cn(styles.Information__Static, {
        [styles['Information__Static--future']]: game.status !== GameStatus.Scheduled,
      }),
    [game.status],
  );

  return (
    <div className={styles.Information}>
      <div className={informationSectionClassNames}>
        <div className={styles.Row}>
          <div className={styles.Row__Label}>Time:</div>
          <div className={styles.Row__Value}>{game.gameDateTimeStart}</div>
        </div>
        <div className={styles.Row}>
          <div className={styles.Row__Label}>Location:</div>
          <div className={styles.Row__Value}>{game.location}</div>
        </div>
      </div>
      {game.status !== GameStatus.Scheduled && (
        <div className={styles.Information__Dynamic}>
          <div className={cn(styles.HeaderRow, styles.Row)}>
            <div>Team</div>
            <div>Q1</div>
            <div>Q2</div>
            <div>Q3</div>
            <div>Q4</div>
            {game.scores.q5 && <span>OT</span>}
            {game.scores.q6 && <span>2OT</span>}
            {game.scores.q7 && <span>3OT</span>}
            <div>F</div>
          </div>
          <div className={visitorsTeamClassNames}>
            <div>{game.visitors.teamInfo.code}</div>
            <div>{game.scores.q1[1]}</div>
            <div>{game.scores.q2[1]}</div>
            <div>{game.scores.q3[1]}</div>
            <div>{game.scores.q4[1]}</div>
            {game.scores.q5 && <span>{game.scores.q5[1]}</span>}
            {game.scores.q6 && <span>{game.scores.q6[1]}</span>}
            {game.scores.q7 && <span>{game.scores.q7[1]}</span>}
            <div>{game.scores.total[1]}</div>
          </div>
          <div className={homeTeamClassNames}>
            <div>{game.home.teamInfo.code}</div>
            <div>{game.scores.q1[0]}</div>
            <div>{game.scores.q2[0]}</div>
            <div>{game.scores.q3[0]}</div>
            <div>{game.scores.q4[0]}</div>
            {game.scores.q5 && <span>{game.scores.q5[0]}</span>}
            {game.scores.q6 && <span>{game.scores.q6[0]}</span>}
            {game.scores.q7 && <span>{game.scores.q7[0]}</span>}
            <div>{game.scores.total[0]}</div>
          </div>
        </div>
      )}
    </div>
  );
};
