import { memo, useCallback, useMemo } from 'react';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IEventParams } from 'services/google-analytic/interfaces/event-payload.interface';

import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { IPlayerFeed, ITeamFeed } from 'stores/posts/interfaces/post.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { formatBigNumbers } from 'helpers/format/format-big-numbers.utils';

import { useResponsive } from 'hooks/use-responsive';

import {
  ReactionDirectionType,
  Reactions,
  ReactionTheme,
} from 'components/reactions/reactions/reactions.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import { PostsTheme } from '../enums/posts-theme.enum';
import { IReaction } from '../interfaces/reactions.interface';

import styles from './post-card-actions-bar.module.less';

export interface IPostCardActionsBarProps {
  theme: PostsTheme;
  activeFeed: Maybe<FeedTypes>;
  isBookmarked: boolean;
  commentsCount: number;
  bookmarksCount: number;
  sharesCount: number;
  reactionsSum: number;
  reactions: {
    positive?: IReaction[];
    negative?: IReaction[];
  };
  hasExtendedActions: boolean;
  feedTeam: Maybe<ITeamFeed>;
  feedPlayer: Maybe<IPlayerFeed>;
  triggerAuthorisationCheck?: () => boolean;
  onBookmarkClick?: () => void;
  onCommentClick?: () => void;
  onShareClick?: () => void;
  onVoteClick: (reaction: string, type: ReactionDirectionType) => void;
}

export const PostCardActionsBar = memo((props: IPostCardActionsBarProps) => {
  const {
    theme,
    activeFeed,
    isBookmarked,
    reactionsSum,
    feedTeam,
    feedPlayer,
    commentsCount,
    bookmarksCount,
    sharesCount,
    hasExtendedActions,
    reactions,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  // TODO: To check out bookmark cards
  const reactionsTheme = useMemo(() => {
    if (theme === PostsTheme.Detail || isDesktopPlus) {
      return ReactionTheme.Regular;
    }

    return ReactionTheme.Smaller;
  }, [theme, isDesktopPlus]);

  const actionIconSize = useMemo(() => {
    if (theme === PostsTheme.Detail || isDesktopPlus) {
      return IconFontSize.Big;
    }

    return IconFontSize.Small;
  }, [theme, isDesktopPlus]);

  const handlePickerClick = useCallback(() => {
    const eventParams: IEventParams = {};

    if (FeedTypes.Team === activeFeed && feedTeam) {
      eventParams.button_tap_type = ButtonTapsEnum.TeamProfileFeedReact;
      eventParams.team = feedTeam.name;
    }

    if (FeedTypes.Player === activeFeed && feedPlayer) {
      eventParams.button_tap_type = ButtonTapsEnum.PlayerPostFeedReact;
      eventParams.player = `${feedPlayer.firstname} ${feedPlayer.lastname}`;
    }

    if (activeFeed && FeedTypes.Team !== activeFeed && FeedTypes.Player !== activeFeed) {
      eventParams.button_tap_type = ButtonTapsEnum.HomeFeedReact;
      eventParams.feed = activeFeed;
    }

    if (eventParams.button_tap_type) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams,
      });
    }
  }, [activeFeed, feedTeam, feedPlayer]);

  return (
    <div className={styles.PostCardActionsBar}>
      <div className={styles.PostCardActionsBar__Actions}>
        {hasExtendedActions && (
          <>
            <div className={styles.PostCardActionsBar__Item}>
              <Reactions
                theme={reactionsTheme}
                reactions={reactions}
                reactionsSum={reactionsSum}
                triggerAuthorisationCheck={props.triggerAuthorisationCheck}
                onPickerClick={handlePickerClick}
                onVoteClick={props.onVoteClick}
              />
            </div>
            <div className={styles.PostCardActionsBar__Item}>
              <IconButton
                onClick={props.onCommentClick}
                iconName={IconFontName.Chat}
                theme={IconButtonTheme.Transparent}
                iconSize={actionIconSize}
              />
              {!!commentsCount && (
                <span className={styles.PostCardActionsBar__Number}>
                  {formatBigNumbers(commentsCount)}
                </span>
              )}
            </div>
          </>
        )}
        <div className={styles.PostCardActionsBar__Item}>
          {isBookmarked ? (
            <IconButton
              iconName={IconFontName.BookmarkFilled}
              theme={IconButtonTheme.Transparent}
              onClick={props.onBookmarkClick}
              active={isBookmarked}
              iconSize={actionIconSize}
            />
          ) : (
            <IconButton
              iconName={IconFontName.Bookmark}
              theme={IconButtonTheme.Transparent}
              onClick={props.onBookmarkClick}
              iconSize={actionIconSize}
            />
          )}
          {!!bookmarksCount && (
            <span className={styles.PostCardActionsBar__Number}>
              {formatBigNumbers(bookmarksCount)}
            </span>
          )}
        </div>
        <div className={styles.PostCardActionsBar__Item}>
          <IconButton
            onClick={props.onShareClick}
            iconName={IconFontName.Share}
            theme={IconButtonTheme.Transparent}
            iconSize={actionIconSize}
          />
          {!!sharesCount && (
            <span className={styles.PostCardActionsBar__Number}>
              {formatBigNumbers(sharesCount)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
});
