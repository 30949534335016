import { FC, forwardRef, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import zxcvbn from 'zxcvbn-typescript';

import { IFormField } from 'components/ui/form-fields/form-field.interface';
import { Input } from 'components/ui/form-fields/input/input.component';
import { HelperTextTheme } from 'components/ui/helper-text/helper-text.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

const DEFAULT_TEXT_MAP = {
  [HelperTextTheme.Weak]: 'Weak',
  [HelperTextTheme.Medium]: 'Medium',
  [HelperTextTheme.Strong]: 'Strong',
  [HelperTextTheme.VeryStrong]: 'Very strong',
};

export interface IPassInputProps extends IFormField {
  withBadge?: boolean;
  withMeter?: boolean;
}

export const PasswordInput: FC<IPassInputProps> = forwardRef<HTMLInputElement, IPassInputProps>(
  (props: IPassInputProps, ref) => {
    const {
      withBadge = true,
      withMeter = true,
      value = '',
      error,
      onChange,
      onBlur,
      ...rest
    } = props;

    const [showPass, setShowPass] = useState<boolean>(false);

    const handleChange = useCallback(
      (currentValue: string) => {
        if (onChange) {
          onChange(currentValue);
        }
      },
      [onChange],
    );

    const helperTheme = useMemo(() => {
      const result = zxcvbn(value).score;

      switch (result) {
        case 2: {
          return HelperTextTheme.Medium;
        }
        case 3: {
          return HelperTextTheme.Strong;
        }
        case 4: {
          return HelperTextTheme.VeryStrong;
        }
        default: {
          return HelperTextTheme.Weak;
        }
      }
    }, [value]);

    const helperText = useMemo<Maybe<string>>(() => {
      if (withMeter && value) {
        return DEFAULT_TEXT_MAP[helperTheme];
      }

      return null;
    }, [withMeter, helperTheme, value]);

    const handleBlur = useCallback(
      (event: SyntheticEvent) => {
        if (onBlur) {
          onBlur(event);
        }
      },
      [onBlur],
    );

    const handleEyeButtonClick = useCallback(() => {
      setShowPass((prevState: boolean) => !prevState);
    }, []);

    const rightIconName = useMemo(() => {
      if (!showPass) {
        return IconFontName.Eye;
      }

      return IconFontName.EyeOff;
    }, [showPass]);

    return (
      <Input
        {...rest}
        error={error}
        helperTextContent={helperText}
        helperTextTheme={helperTheme}
        leftIconName={withBadge ? IconFontName.Lock : null}
        ref={ref}
        rightIconProps={{
          name: rightIconName,
          onClick: handleEyeButtonClick,
        }}
        type={showPass ? 'text' : 'password'}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  },
);
