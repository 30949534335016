import { memo, useMemo } from 'react';
import cn from 'classnames';

import styles from './attachments-image-item.module.less';

export enum AttachmentsImageTheme {
  Default,
  Attachments,
  SmallPreview,
}

export interface IAttachmentsImageItem {
  theme?: AttachmentsImageTheme;
  mimeType: string;
  imageUrl: string;
  alt: string;
}

export const AttachmentsImageItem = memo((props: IAttachmentsImageItem) => {
  const { theme = AttachmentsImageTheme.Default, imageUrl, mimeType, alt } = props;

  const imageClassName = useMemo(
    () =>
      cn(styles.AttachmentsImage, {
        [styles['AttachmentsImage--theme-attachments']]:
          theme === AttachmentsImageTheme.Attachments,
        [styles['AttachmentsImage--theme-small-preview']]:
          theme === AttachmentsImageTheme.SmallPreview,
        [styles['AttachmentsImage--theme-default']]: theme === AttachmentsImageTheme.Default,
      }),
    [theme],
  );

  return (
    <div className={styles.AttachmentsImageItem}>
      <img className={imageClassName} src={imageUrl} alt={alt} />
      {mimeType === 'image/gif' && <div className={styles.GifIcon} />}
    </div>
  );
});
