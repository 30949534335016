import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { FeedTabsContainer } from 'containers/feed-tabs/feed-tabs-container';

export const HomeRoute: FC = () => {
  return (
    <>
      <FeedTabsContainer />
      <Outlet />
    </>
  );
};
