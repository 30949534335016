import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { StatsMode } from 'stores/global-stats/enums/global-stats.enums';
import {
  GLOBAL_STATS_EMPTY_STATE_MESSAGE,
  GLOBAL_STATS_EMPTY_STATE_TITLE,
  SORT_DIRECTION_PARAM,
  SORT_SEARCH_PARAM,
} from 'stores/global-stats/global-stats.config';
import { GlobalStatsStore } from 'stores/global-stats/global-stats.store';
import { getSortedTeamItems } from 'stores/global-stats/utils/get-sorted-team-items.util';
import { isSortDirection } from 'stores/global-stats/utils/is-sort-direction.util';
import { isTeamsGlobalStatsSortValue } from 'stores/global-stats/utils/is-teams-global-stats-sort-value.util';

import { TYPES } from 'configs/di-types.config';

import { useTeamClick } from 'hooks/use-team-click';

import { EmptyState } from 'components/empty-state/empty-state.component';
import { GlobalStatsTeams } from 'components/stats/global-stats/global-stats-teams.component';
import { Glossary } from 'components/ui/glossary/glossary.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from '../stats.module.less';

export const GlobalStatsTeamsContainer = observer(() => {
  const globalStatsStore = useInjection<GlobalStatsStore>(TYPES.GlobalStatsStore);

  const [searchParams, setSearchParams] = useSearchParams();

  const sortField = searchParams.get(SORT_SEARCH_PARAM);
  const sortDirection = searchParams.get(SORT_DIRECTION_PARAM);

  const handleTeamClick = useTeamClick();

  const sortedItems = useMemo(() => {
    if (isTeamsGlobalStatsSortValue(sortField) && isSortDirection(sortDirection)) {
      return getSortedTeamItems(sortField, globalStatsStore.teamsGlobalStatsRowData, sortDirection);
    }

    return getSortedTeamItems('points', globalStatsStore.teamsGlobalStatsRowData);
  }, [globalStatsStore.teamsGlobalStatsRowData, sortField, sortDirection]);

  const handleSortChange = useCallback(
    (sort: string, direction: string) => {
      setSearchParams(
        {
          sort,
          direction,
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const loading = useMemo(
    () =>
      !globalStatsStore.teamsGlobalStats.length ||
      !sortedItems.length ||
      !globalStatsStore.seasonId ||
      globalStatsStore.fetching,
    [
      globalStatsStore.fetching,
      globalStatsStore.seasonId,
      globalStatsStore.teamsGlobalStats.length,
      sortedItems.length,
    ],
  );

  if (globalStatsStore.shouldDisplayEmptyState[StatsMode.TEAMS]) {
    return (
      <div className={styles.EmptyStateWrapper}>
        <EmptyState
          title={GLOBAL_STATS_EMPTY_STATE_TITLE}
          message={GLOBAL_STATS_EMPTY_STATE_MESSAGE}
        />
      </div>
    );
  }

  if (loading) {
    return (
      <div className={styles.LoaderWrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    <>
      <GlobalStatsTeams
        sortedField={sortField}
        sortDirection={sortDirection}
        sortedItems={sortedItems}
        teamsStatsRowData={globalStatsStore.teamsGlobalStatsRowData}
        onSortChange={handleSortChange}
        onTeamClick={handleTeamClick}
      />
      <Glossary entities={globalStatsStore.glossaryEntities} />
    </>
  );
});
