import { FC, useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { ICountdownTime } from 'stores/auth/interfaces/countdown-time.interface';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { CountdownTimer } from 'components/ui/countdown-timer/countdown-timer.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './confirm-email-countdown-timer.module.less';

interface IConfirmEmailCountdownTimerProps {
  processing: boolean;
  countdownEndTimestamp: number;
  onSetCountdownTime: (time: Maybe<ICountdownTime>) => void;
  onClick: () => void;
}

export const ConfirmEmailCountdownTimer: FC<IConfirmEmailCountdownTimerProps> = (props) => {
  const { onClick, processing, countdownEndTimestamp } = props;

  const [isTimerExpired, setIsTimerExpired] = useState<boolean>(false);

  const handleGetNewCodeClick = useCallback(() => {
    onClick();
    setIsTimerExpired(false);
  }, [onClick]);

  const handleOnTimerExpiration = useCallback(() => setIsTimerExpired(true), []);

  const classNames = useMemo<string>(
    () =>
      cn(styles.ConfirmEmailCountdownTimer, {
        [styles['ConfirmEmailCountdownTimer--loading']]: processing,
        [styles['ConfirmEmailCountdownTimer--is-timer-expired']]: !isTimerExpired && !processing,
      }),
    [processing, isTimerExpired],
  );

  return (
    <div className={classNames}>
      {processing && <Loader isShow withBackground />}
      <div className={styles.CountdownTimerWrapper}>
        {isTimerExpired ? (
          <Button
            theme={ButtonTheme.Secondary}
            size={ButtonSize.Big}
            onClick={handleGetNewCodeClick}
          >
            Get new code
          </Button>
        ) : (
          <CountdownTimer
            onExpiration={handleOnTimerExpiration}
            onSetCountdownTime={props.onSetCountdownTime}
            countdownEndTimestamp={countdownEndTimestamp}
          />
        )}
      </div>
    </div>
  );
};
