import { FC, useCallback, useMemo } from 'react';
import { parse } from 'date-fns';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { GameStore } from 'stores/game/game.store';
import { GameChatStore } from 'stores/game-chat/game-chat.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { GameChatComment } from 'components/game/game-chat-message/game-chat-message.component';
import { Countdown } from 'components/game/game-countdown/game-countdown.component';
import { ReactionDirectionType } from 'components/reactions/reactions/reactions.component';
import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

import styles from './game-polls.module.less';

export const GamePollsContainer: FC = observer(() => {
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
  const gameChatStore = useInjection<GameChatStore>(TYPES.GameChatStore);
  const gameStore = useInjection<GameStore>(TYPES.GameStore);
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handleMockCallback = useCallback(() => {}, []);

  const handleVote = useCallback(
    async (messageId: string, reaction: string, type: ReactionDirectionType) => {
      const isAuth = authStore.triggerAuthorisationCheck();

      if (isAuth && gameStore.id) {
        const payload = {
          gameId: gameStore.id,
          messageId,
          reaction,
        };
        if (type === 'up') {
          await gameChatStore.gamePollVoteUp(payload);
        }

        if (type === 'down') {
          await gameChatStore.gamePollVoteDown(payload);
        }
      }
    },
    [authStore, gameChatStore, gameStore.id],
  );

  const handlePollVote = useCallback(
    async (pollData: IPollVoteData) => {
      if (!authStore.triggerAuthorisationCheck() || !gameStore.id) {
        return;
      }

      await gameChatStore.gamePollVote(pollData);
    },
    [authStore, gameChatStore, gameStore.id],
  );

  const handlePollExpired = useCallback(
    async (messageId: Maybe<string>) => {
      if (!authStore.triggerAuthorisationCheck()) {
        return;
      }

      if (messageId && gameStore.id) {
        await gameChatStore.fetchSingleGameChatMessage({
          messageId,
          gameId: gameStore.id,
        });
      }
    },
    [authStore, gameChatStore, gameStore.id],
  );

  const gameStartTimestamp = useMemo(() => {
    const gameDate = gameStore.entry?.gameDateTimeStart || '';
    const dateObject = parse(gameDate, 'h:mm a, MMMM dd, yyyy', new Date());

    return dateObject.getTime();
  }, [gameStore.entry]);

  return (
    <div className={styles.GamePolls}>
      {gameStore.entry?.status === GameStatus.Scheduled && (
        <Countdown
          gameStatus={gameStore.entry?.status}
          forPolls
          label="Polls close when games start"
          targetDate={gameStartTimestamp}
        />
      )}
      {gameStore.entry?.status !== GameStatus.Scheduled && gameChatStore.polls.length && (
        <div className={styles.GamePolls__Finished}>Polls are closed</div>
      )}
      <div className={styles.GamePolls__Items}>
        {gameChatStore.polls.map((item) => (
          <GameChatComment
            isGamePolls
            reportReasons={applicationStore.reportReasons}
            isMyComment={item.user.uuid === authStore?.userMe?.id}
            key={item.uuid}
            author={{
              uuid: item.user.uuid,
              name: item.user.name,
              username: item.user.username,
              favoriteTeam: null,
              favoritePlayer: null,
              smallAvatarUrl: item.user.smallAvatarUrl,
            }}
            reactions={{
              positive: item.reactionsTotal.upVote,
              negative: item.reactionsTotal.downVote,
            }}
            reactionsSum={item.votesTotal || 0}
            commentData={{
              comment: item.content,
              createAt: isDesktopPlus
                ? item.formattedDates.relativeLong
                : item.formattedDates.relativeShort,
            }}
            attachments={item.attachments}
            scrollCommentId={null}
            messageId={item.uuid}
            gameId={gameStore.id || 0}
            onVoteComment={handleVote}
            onPollVote={handlePollVote}
            onPollExpired={handlePollExpired}
            onOpenPreview={handleMockCallback}
            triggerAuthorisationCheck={authStore.triggerAuthorisationCheck}
          />
        ))}
      </div>
    </div>
  );
});
