import { useMemo } from 'react';

import { IReaction } from 'components/posts/post-card/interfaces/reactions.interface';

export const useSelectedReaction = (reactions: {
  positive?: IReaction[];
  negative?: IReaction[];
}) => {
  const positiveSelected = useMemo(
    () => reactions.positive?.find((reaction) => reaction.isSelected),
    [reactions.positive],
  );

  const negativeSelected = useMemo(
    () => reactions.negative?.find((reaction) => reaction.isSelected),
    [reactions.negative],
  );

  return {
    positiveSelected,
    negativeSelected,
  };
};
