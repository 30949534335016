import { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { IFollow } from 'stores/follow/interfaces/follow.interface';
import { ITeam } from 'stores/teams/interfaces/team.interface';

import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';
import { ARENA_PART, RANKINGS_PART } from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { SidebarCommunitiesList } from 'components/sidebars/sidebar-communities-list/sidebar-communities-list.component';
import { SidebarFollowingSectionDesktop } from 'components/sidebars/sidebar-followings-section-desktop/sidebar-following-section-desktop.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconButton } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import arenaDark from 'assets/images/svg/arena-dark.svg';
import arenaLight from 'assets/images/svg/arena-light.svg';
import logo from 'assets/images/svg/logo.svg';
import profileDark from 'assets/images/svg/profile-dark.svg';
import profileLight from 'assets/images/svg/profile-light.svg';
import rankingsDark from 'assets/images/svg/rankings-dark.svg';
import rankingsLight from 'assets/images/svg/rankings-light.svg';

import { SidebarItem, SidebarItemTheme } from '../../ui/sidebar-item/sidebar-item.component';

import styles from './sidebar-desktop.module.less';

interface ISidebarProps {
  isAuthorized: boolean;
  activePath: string;
  exploreTeams: ITeam[];
  followedTeams: ITeam[];
  teamId: Maybe<number>;
  firstNextNotFinishedGameId: Maybe<number>;
  teamName?: string;
  followingPlayers: IFollow[];
  onClick: (action: BarActionType) => void;
  onTeamFollow: () => void;
  onLogoClick: () => void;
}

export const MAX_UNEXPANDED_ITEMS_LENGTH = 4;

export const SidebarDesktop = memo((props: ISidebarProps) => {
  const {
    isAuthorized,
    firstNextNotFinishedGameId,
    activePath,
    followedTeams,
    exploreTeams,
    teamName,
    teamId,
    followingPlayers,
    onClick,
  } = props;

  const navigate = useNavigate();

  const [isExpand, setIsExpand] = useState(false);

  const isHomeActive = useMemo<boolean>(() => activePath.includes(paths.HOME), [activePath]);

  const isProfileActive = useMemo<boolean>(() => activePath === paths.PROFILE, [activePath]);

  const handleToggleExpandClick = useCallback(() => {
    setIsExpand(!isExpand);
  }, [isExpand]);

  const nextGameUrl = useMemo(() => {
    if (firstNextNotFinishedGameId) {
      return getPath(paths.ARENA_GAME, {
        [paths.GAME_ID_PARAM]: String(firstNextNotFinishedGameId),
      });
    }

    return getPath(paths.ARENA);
  }, [firstNextNotFinishedGameId]);

  const handleItemClick = useCallback(
    (action: BarActionType) => () => {
      if (onClick) {
        GoogleAnalyticService.event({
          eventName: 'button_custom_tap',
          eventParams: {
            button_tap_type: ButtonTapsEnum.UserProfile,
          },
        });

        onClick(action);
      }
    },
    [onClick],
  );

  const handleArenaClick = useCallback(() => {
    if (onClick && nextGameUrl) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.Arena,
        },
      });

      onClick({ type: BarAction.Link, path: nextGameUrl });
    }
  }, [onClick, nextGameUrl]);

  const handleRankingsClick = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.Rankings,
      },
    });

    onClick?.({ type: BarAction.Link, path: paths.PLAYERS_RANKINGS });
  }, [onClick]);

  const isArenaActive = useMemo<boolean>(() => activePath.includes(ARENA_PART), [activePath]);
  const isRankingsActive = useMemo<boolean>(() => activePath.includes(RANKINGS_PART), [activePath]);

  const handleEditClick = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.ExploreEditFavorites,
        team: teamName,
      },
    });

    navigate(paths.FOLLOWING);
  }, [teamName, navigate]);

  const itemsLength = useMemo(
    () => followedTeams.length + followingPlayers.length,
    [followingPlayers, followedTeams],
  );

  const communitiesClassNames = useMemo(
    () =>
      cn(styles.SidebarDesktop__Communities, {
        [styles['SidebarDesktop__Communities--with-padding']]: isExpand,
      }),
    [isExpand],
  );

  return (
    <nav className={styles.SidebarDesktop}>
      <ul className={styles.SidebarDesktop__List}>
        <SidebarItem
          isActive={isHomeActive}
          isRoot
          imageSrc={logo}
          name="Home"
          theme={SidebarItemTheme.Vector}
          onClick={props.onLogoClick}
        />
        <SidebarItem
          isActive={isArenaActive}
          isSetting
          name="Arena"
          imageSrc={isArenaActive ? arenaLight : arenaDark}
          theme={SidebarItemTheme.Vector}
          onClick={handleArenaClick}
        />
        <SidebarItem
          isActive={isRankingsActive}
          isSetting
          name="Rankings"
          imageSrc={isRankingsActive ? rankingsLight : rankingsDark}
          theme={SidebarItemTheme.Vector}
          onClick={handleRankingsClick}
        />
        {isAuthorized && (
          <SidebarItem
            isActive={isProfileActive}
            isSetting
            name="Profile"
            imageSrc={isProfileActive ? profileLight : profileDark}
            theme={SidebarItemTheme.Vector}
            onClick={handleItemClick({ type: BarAction.Link, path: paths.PROFILE })}
          />
        )}
        {isAuthorized && (
          <div className={styles.SidebarDesktop__Follow}>
            <Button theme={ButtonTheme.Primary} size={ButtonSize.Small} onClick={handleEditClick}>
              Follow
            </Button>
          </div>
        )}
        <div className={communitiesClassNames}>
          {isAuthorized && !!itemsLength && (
            <>
              <hr className={styles.SidebarDesktop__Separator} />
              <li className={styles.SidebarDesktop__SectionLabel}>Following</li>
              <li className={styles.SidebarDesktop__CommunitiesListItem}>
                <SidebarFollowingSectionDesktop
                  isExpand={isExpand}
                  onExpandClick={handleToggleExpandClick}
                  isAuthorized={isAuthorized}
                  teamId={teamId}
                  activePath={activePath}
                  followedTeams={followedTeams}
                  onTeamClick={props.onClick}
                  followingPlayers={followingPlayers}
                />
              </li>
            </>
          )}
          {!!exploreTeams.length && (
            <>
              <hr className={styles.SidebarDesktop__Separator} />
              <li className={styles.SidebarDesktop__SectionLabel}>Explore</li>
              <li className={styles.SidebarDesktop__CommunitiesListItem}>
                <SidebarCommunitiesList
                  isExploreList
                  isAuthorized={isAuthorized}
                  activePath={activePath}
                  teams={exploreTeams}
                  teamId={teamId}
                  onClick={props.onClick}
                  onJoinTeam={props.onTeamFollow}
                />
              </li>
            </>
          )}
        </div>
      </ul>
      {isExpand && itemsLength > MAX_UNEXPANDED_ITEMS_LENGTH && (
        <ul className={styles.SidebarDesktop__ExpandButton}>
          <IconButton
            iconSize={IconFontSize.ExtraBig}
            iconName={IconFontName.ChevronUp}
            onClick={handleToggleExpandClick}
          />
        </ul>
      )}
    </nav>
  );
});
