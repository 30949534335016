import { memo, useMemo } from 'react';
import { ContentEditable as LexicalContentEditable } from '@lexical/react/LexicalContentEditable';
import cn from 'classnames';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { EditorTheme } from '../base-editor/base-editor.component';

import styles from './content-editable-with-scroll.module.less';

interface IContentEditableProps {
  theme?: EditorTheme;
  isFullHeight?: boolean;
  isWithPoll?: boolean;
  isWithInitialHeight?: boolean;
}
export const ContentEditableWithScroll = memo((props: IContentEditableProps): JSX.Element => {
  const { theme, isFullHeight, isWithPoll, isWithInitialHeight } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const contentEditableClasses = useMemo(
    () =>
      cn(styles.ContentEditable, {
        [styles['ContentEditable--full-height']]: isFullHeight,
        [styles['ContentEditable--mobile']]: !isDesktopPlus,
        [styles['ContentEditable--with-initial-height']]: isWithInitialHeight,
        [styles['ContentEditable--with-poll']]: isWithPoll,
        [styles['ContentEditable--bottom-sheet-theme']]: theme === EditorTheme.BottomSheet,
      }),
    [theme, isDesktopPlus, isFullHeight, isWithInitialHeight, isWithPoll],
  );

  return <LexicalContentEditable autoCapitalize="on" className={contentEditableClasses} />;
});
