import { FC } from 'react';
import { components, MultiValueRemoveProps } from 'react-select';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { ICheckboxItem } from 'components/ui/form-fields/multiple-choose-checkboxes/multiple-choose-checkboxes.component';

import styles from './checkbox-select-multi-value-remove.module.less';

interface ICheckboxSelectMultiValueRemoveProps extends MultiValueRemoveProps<ICheckboxItem, true> {}

export const MULTI_VALUE_REMOVE_ID = 'MultiValueRemove';

export const CheckboxSelectMultiValueRemove: FC<ICheckboxSelectMultiValueRemoveProps> = (
  props: ICheckboxSelectMultiValueRemoveProps,
) => {
  return (
    <components.MultiValueRemove {...props}>
      <div id={MULTI_VALUE_REMOVE_ID} className={styles.MultiValueRemove}>
        <IconButton iconName={IconFontName.Close} theme={IconButtonTheme.Transparent} />
      </div>
    </components.MultiValueRemove>
  );
};
