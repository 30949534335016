import { useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';

import { StandingsStore } from 'stores/standings/standings.store';

import { TYPES } from 'configs/di-types.config';

import { useGaScreenCustomView } from 'hooks/use-ga-screen-custom-view';
import { useTeamClick } from 'hooks/use-team-click';
import { useToggleIgnoredTouchClass } from 'hooks/use-toggle-ignored-touch-class';

import { Standings } from 'components/standings/standings.component';
import { Glossary } from 'components/ui/glossary/glossary.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './standings.module.less';

export const StandingsContainer = observer(() => {
  const standingsStore = useInjection<StandingsStore>(TYPES.StandingsStore);

  const { googleAnalyticsHomeFeed } = useGaScreenCustomView();

  useToggleIgnoredTouchClass(!standingsStore.fetched);

  const handleTeamClick = useTeamClick();

  useEffect(() => {
    googleAnalyticsHomeFeed(ScreenNamesEnum.HomeStandings);
  }, [googleAnalyticsHomeFeed]);

  if (!standingsStore.fetched) {
    return (
      <div className={styles.Wrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    <>
      <Standings
        mode={standingsStore.mode}
        type={standingsStore.type}
        formattedData={standingsStore.formattedRowData}
        onTeamClick={handleTeamClick}
      />
      <Glossary entities={standingsStore.glossaryEntities} />
    </>
  );
});
