import { memo, SyntheticEvent, useCallback, useMemo } from 'react';
import { Browser } from '@capacitor/browser';
import cn from 'classnames';

import { ILinkAttachment } from 'stores/posts/interfaces/post.interface';

import { getDomainFromLink } from 'helpers/get-domain-from-link.util';

import { useMainProvider } from 'hooks/use-main-provider';

import styles from './link-set-item.module.less';

interface ILinkSetItemProps {
  link: ILinkAttachment;
}

export const LinkSetItem = memo((props: ILinkSetItemProps) => {
  const { link } = props;

  const { isNativeApp } = useMainProvider();

  const imageEl = useMemo(
    () =>
      link.previewImageUrl && (
        <img
          className={styles.LinkSetItem__Image}
          src={link.previewImageUrl}
          alt={link.title || ''}
        />
      ),

    [link.previewImageUrl, link.title],
  );

  const sourceLogoEl = useMemo(
    () =>
      link.sourceFaviconUrl ? (
        <img
          className={styles.LinkSetItemSource__Logo}
          src={link.sourceFaviconUrl}
          alt={link.sourceName || ''}
        />
      ) : null,
    [link.sourceFaviconUrl, link.sourceName],
  );

  const sourceName = useMemo(() => {
    if (link.sourceName) {
      return link.sourceName;
    }

    return getDomainFromLink(link.url);
  }, [link.sourceName, link.url]);

  const linkContent = useMemo(() => link.title || link.url, [link.title, link.url]);

  const itemClassNames = useMemo(
    () =>
      cn(styles.LinkSetItem, {
        [styles['LinkSetItem--no-image']]: !link.previewImageUrl,
      }),
    [link.previewImageUrl],
  );

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      if (isNativeApp) {
        event.preventDefault();
        Browser.open({ url: link.url });
      }
    },
    [isNativeApp, link],
  );

  return (
    <a
      href={link.url}
      target="_blank"
      rel="noreferrer"
      className={itemClassNames}
      onClick={handleClick}
    >
      <div className={styles.LinkSetItem__Source}>
        {sourceLogoEl}
        <div className={styles.LinkSetItemSource__Name}>{sourceName}</div>
      </div>
      <div className={styles.LinkSetItem__Title}>{linkContent}</div>
      {imageEl}
    </a>
  );
});
