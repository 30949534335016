import { FC, useCallback } from 'react';
import { Browser } from '@capacitor/browser';

import { useMainProvider } from 'hooks/use-main-provider';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import style from './profile-info-socials.module.less';

interface IProfileSocialsProps {
  instagramUrl: Maybe<string>;
  twitterUrl: Maybe<string>;
  youtubeUrl: Maybe<string>;
  tiktokUrl: Maybe<string>;
}

export const ProfileInfoSocials: FC<IProfileSocialsProps> = (props: IProfileSocialsProps) => {
  const { instagramUrl, twitterUrl, youtubeUrl, tiktokUrl } = props;

  const { isNativeApp } = useMainProvider();

  const handleSocialClick = useCallback(
    (url: Maybe<string>) => () => {
      if (url && isNativeApp) {
        Browser.open({ url });
        return;
      }

      if (url) {
        window.open(url, '_blank');
      }
    },
    [isNativeApp],
  );

  return (
    <div className={style.ProfileInfoSocials}>
      {instagramUrl && (
        <IconButton
          theme={IconButtonTheme.Navigation}
          iconName={IconFontName.Insta}
          onClick={handleSocialClick(instagramUrl)}
        />
      )}
      {twitterUrl && (
        <IconButton
          theme={IconButtonTheme.Navigation}
          iconName={IconFontName.Twitter}
          onClick={handleSocialClick(twitterUrl)}
        />
      )}
      {youtubeUrl && (
        <IconButton
          theme={IconButtonTheme.Navigation}
          iconName={IconFontName.Youtube}
          onClick={handleSocialClick(youtubeUrl)}
        />
      )}
      {tiktokUrl && (
        <IconButton
          theme={IconButtonTheme.Navigation}
          iconName={IconFontName.TikTok}
          onClick={handleSocialClick(tiktokUrl)}
        />
      )}
    </div>
  );
};
