import { useMemo } from 'react';
import card1 from 'assets/images/svg/game-card-1.svg';
import card2 from 'assets/images/svg/game-card-2.svg';
import cn from 'classnames';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './notification-permission.module.less';

interface INotificationPermissionProps {
  onClose: () => void;
  onAllowNotifications: () => void;
}

export const NotificationPermission = (props: INotificationPermissionProps) => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const notificationPermissionClassNames = useMemo<string>(
    () =>
      cn(styles.NotificationPermission, {
        [styles['NotificationPermission--with-top-space']]: !isDesktopPlus,
      }),
    [isDesktopPlus],
  );

  return (
    <div className={notificationPermissionClassNames}>
      <div className={styles.GameCardsSection}>
        <div className={styles.GameCardsSection__Buttons}>
          <div className={styles.GameCardsSection__Left}>
            <div className={styles.BigCircle} />
            <div className={styles.BigCircle} />
            <div className={styles.GameCardsSection__SmallCircles}>
              <div className={styles.SmallCircle} />
              <div className={styles.SmallCircle} />
            </div>
            <div className={styles.BigCircle} />
            <div className={styles.BigCircle} />
          </div>
          <div className={styles.GameCardsSection__Right}>
            <div className={styles.BigCircle} />
          </div>
        </div>
        <div className={styles.GameCardsSection__GameCard}>
          <img src={card1} alt="card-1" />
        </div>
        <div className={styles.GameCardsSection__GameCard}>
          <img src={card2} alt="card-2" />
        </div>
      </div>
      <div className={styles.MainContent}>
        <div className={styles.MainContent__Title}>Notify me when games start</div>
        <div className={styles.MainContent__Message}>
          You will also receive notifications including interactions, new posts, suggestions, and
          more. You can turn this off any time.
        </div>
        <div className={styles.MainContent__Buttons}>
          <Button
            onClick={props.onAllowNotifications}
            fluid
            size={ButtonSize.Big}
            theme={ButtonTheme.Primary}
          >
            Get Notified
          </Button>
          <Button
            onClick={props.onClose}
            fluid
            size={ButtonSize.SmallSecondary}
            theme={ButtonTheme.Text}
          >
            Not now
          </Button>
        </div>
      </div>
    </div>
  );
};
