import { FC } from 'react';

import { IReportReason } from 'services/reports/interfaces/report-reason.interface';

import { ReportsMain } from 'components/reports/report-main/report-main.component';

import { BaseIonBottomSheet } from '../base-ion-bottom-sheet/base-ion-bottom-sheet.component';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 0.95;

interface IReportsIonBottomSheetProps {
  visible: boolean;
  reasons: IReportReason[];
  onClose: () => void;
  onSendReport?: (reasonId: number) => Promise<boolean>;
}

export const ReportsIonBottomSheet: FC<IReportsIonBottomSheetProps> = (props) => {
  const { visible, reasons } = props;

  return (
    <BaseIonBottomSheet
      visible={visible}
      safeAreaBottom={0}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      initialBreakpoint={INITIAL_BREAKPOINT}
      onClose={props.onClose}
    >
      <ReportsMain
        reasons={reasons}
        onSendReport={props.onSendReport}
        onCloseBottomSheet={props.onClose}
      />
    </BaseIonBottomSheet>
  );
};
