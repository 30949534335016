import { memo } from 'react';

import { SETTINGS_PRIVACY_SAFETY } from 'configs/item-line-list.config';

import { BarActionType } from 'components/bars/bar-action.type';
import { ItemLineList } from 'components/ui/item-line-list/item-line-list.component';

import styles from './policy-settings.module.less';

interface IPolicySettingsProps {
  onItemClick: (barAction: BarActionType) => void;
}

export const PolicySettings = memo((props: IPolicySettingsProps) => {
  return (
    <div className={styles.PolicySettings}>
      <ItemLineList items={SETTINGS_PRIVACY_SAFETY} onItemClick={props.onItemClick} />
    </div>
  );
});
