import { FC, useCallback } from 'react';

import { ISignUpWithPasswordParams } from 'stores/auth/interfaces/sign-up-with-password-params.interface';

import {
  PasswordsForm,
  PasswordsFormDataType,
} from 'components/forms/passwords/passwords-form.component';

export interface ISignUpWithPasswordProps {
  processing: boolean;
  preservedEmail: string;
  onSubmit: (signUpWithPasswordParams: ISignUpWithPasswordParams) => void;
}

export const SignUpWithPassword: FC<ISignUpWithPasswordProps> = (props) => {
  const { processing, preservedEmail, onSubmit } = props;

  const handleSubmit = useCallback(
    (formData: PasswordsFormDataType) => {
      onSubmit({ email: preservedEmail, password: formData.password });
    },
    [onSubmit, preservedEmail],
  );

  return (
    <PasswordsForm processing={processing} submitText="Create account" onSubmit={handleSubmit} />
  );
};
