import { FC, memo } from 'react';
import { YouTubeEmbed } from 'react-social-media-embed';
import { YouTubeEvent } from 'react-youtube';

import { EmbedWrapperCard } from 'components/social-card/embed-wrapper-card/embed-wrapper-card.component';

interface IYoutubeProps {
  sourceUrl: string;
  width: number;
}

export const Youtube: FC<IYoutubeProps> = memo(({ sourceUrl, width }: IYoutubeProps) => {
  const onPlayerReady = (event: YouTubeEvent) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <EmbedWrapperCard isYoutube>
      <YouTubeEmbed url={sourceUrl} youTubeProps={{ onReady: onPlayerReady }} width={width} />
    </EmbedWrapperCard>
  );
});
