import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { emailSchema } from 'validation/schemas/email.schema';
import { passwordSchema } from 'validation/schemas/password.schema';
import { z } from 'zod';

import { ForgotPasswordSection } from 'components/auth/forgot-password-section/forgot-password-component';
import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE, DEFAULT_VALIDATION_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { SubmitSection } from 'components/forms/submit-section/submit-section.component';
import { PasswordInput } from 'components/ui/form-fields/password-input/password-input.component';
import { TextInput } from 'components/ui/form-fields/text-input/text-input.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import formsStyles from '../forms.module.less';

const loginByPasswordFormSchema = z
  .object({
    email: emailSchema,
    password: passwordSchema,
  })
  .strict();

export type LoginByPasswordFormDataType = z.infer<typeof loginByPasswordFormSchema>;

interface ILoginByPasswordFormProps extends IForm<LoginByPasswordFormDataType> {
  onForgotPasswordButtonClick: () => void;
}

export const LoginByPasswordForm: FC<ILoginByPasswordFormProps> = (props) => {
  const { submitText, initialValues, onSubmit } = props;

  const { control, formState, handleSubmit } = useForm({
    defaultValues: initialValues,
    mode: DEFAULT_VALIDATION_MODE,
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(loginByPasswordFormSchema),
  });

  const { isValid, errors } = formState;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={formsStyles.FormContent}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.email?.message}
              type="email"
              id="email"
              label="Email"
              leftIconName={IconFontName.Mention}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <PasswordInput
              {...field}
              withBadge
              withMeter={false}
              error={errors.password?.message}
              id="password"
              label="Password"
            />
          )}
        />
      </div>
      <ForgotPasswordSection
        buttonText="Forgot Password"
        onButtonClick={props.onForgotPasswordButtonClick}
      />
      <SubmitSection isFormValid={isValid} buttonText={submitText} />
    </Form>
  );
};
