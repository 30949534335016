import { memo } from 'react';

import { IComment } from 'stores/comments/interfaces/comments.interface';
import { OpenPreviewType } from 'stores/gallery/types/gallery.type';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { CommentWithoutInteractions } from 'components/comment/сomment-without-interaction/comment-without-interaction.component';
import {
  BaseModalComponent,
  IModal,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

import styles from './single-comment-modal.module.less';

export interface ISingleCommentModalProps extends IModal {
  comment: Maybe<IComment>;
  favoriteTeam: Maybe<ITeamsStats>;
  favoritePlayer: Maybe<IPlayerStats>;
  postId: string;
  onPollVote: (voteData: IPollVoteData) => void;
  onPollExpired: (postId: string, commentId: Maybe<string>) => void;
  onOpenPreview: OpenPreviewType;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
}

export const SingleCommentModal = memo((props: ISingleCommentModalProps) => {
  const { visible, comment, postId, favoriteTeam, favoritePlayer } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  if (!comment) {
    return null;
  }

  return (
    <BaseModalComponent
      size={ModalWindowSize.M}
      visible={visible}
      closeOnOverlayClick
      title="Comment Details"
      onClose={props.onClose}
    >
      <div className={styles.ModalContentWrapper}>
        <div className={styles.ModalContent}>
          <CommentWithoutInteractions
            team={null}
            id={comment.uuid}
            postId={postId}
            commentAuthor={{
              uuid: comment.user.uuid,
              name: comment.user.name,
              username: comment.user.username,
              favoriteTeam,
              favoritePlayer,
              smallAvatarUrl: comment.user.smallAvatarUrl,
            }}
            commentData={{
              comment: comment.content,
              createAt: isDesktopPlus
                ? comment.formattedDates.relativeLong
                : comment.formattedDates.relativeShort,
            }}
            attachments={comment.attachments}
            onOpenPreview={props.onOpenPreview}
            onPollVote={props.onPollVote}
            onPollExpired={props.onPollExpired}
            onTeamClick={props.onTeamClick}
            onPlayerClick={props.onPlayerClick}
          />
        </div>
      </div>
    </BaseModalComponent>
  );
});
