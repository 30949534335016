import { forwardRef, SyntheticEvent, useCallback } from 'react';

import { TITLE_INPUT_MAX_LENGTH } from 'configs/controls.config';

import { IFormField } from 'components/ui/form-fields/form-field.interface';
import { TextArea } from 'components/ui/form-fields/text-area/text-area.component';

import styles from './title-text-area.module.less';

export interface ITitleTextAreaProps extends IFormField {
  rows: number;
}

export const TitleTextArea = forwardRef<HTMLTextAreaElement, ITitleTextAreaProps>(
  (props: ITitleTextAreaProps, ref) => {
    const { onChange, onBlur, onFocus, value, ...rest } = props;

    const handleChange = useCallback(
      (currentValue: string) => {
        if (onChange) {
          onChange(currentValue);
        }
      },
      [onChange],
    );

    const handleBlur = useCallback(
      (event: SyntheticEvent) => {
        if (onBlur) {
          onBlur(event);
        }
      },
      [onBlur],
    );

    const handleFocus = useCallback(
      (event: SyntheticEvent) => {
        if (onFocus) {
          onFocus(event);
        }
      },
      [onFocus],
    );
    return (
      <div className={styles.TextArea}>
        <TextArea
          {...rest}
          value={value}
          ref={ref}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          maxLength={TITLE_INPUT_MAX_LENGTH}
        />
      </div>
    );
  },
);
