import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import styles from './performacne-item.module.less';

export interface IPerformanceItemProps {
  label: string;
  value: string | number;
  isPlusMinus?: boolean;
}

export const PerformanceItem: FC<IPerformanceItemProps> = memo((props: IPerformanceItemProps) => {
  const { label, value, isPlusMinus = false } = props;

  const itemValueClassNames = useMemo(
    () =>
      cn(styles.Item__Value, {
        [styles['Item__Value--plus-minus-positive']]: isPlusMinus && value > 0,
        [styles['Item__Value--plus-minus-negative']]: isPlusMinus && value < 0,
      }),
    [isPlusMinus, value],
  );

  return (
    <div className={styles.Item}>
      <div className={itemValueClassNames}>{value}</div>
      <div className={styles.Item__Label}>{label}</div>
    </div>
  );
});
