import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  CONTENT_HEIGHT_LIMIT,
  CSS_VAR_OPACITY_MOBILE_HEADER,
  CSS_VAR_SCROLL_SMOOTH_DURATION,
  CSS_VAR_TRANSFORM_MOBILE_BOTTOM_BAR,
  CSS_VAR_TRANSFORM_MOBILE_FIXED_ELEMENT,
  CSS_VAR_TRANSFORM_MOBILE_HEADER,
  INACTIVE_TOP_AREA,
  NEGATIVE_LIMIT_POSITION,
  NEGATIVE_LIMIT_POSITION_WITHOUT_NAVIGATION,
  POSITIVE_LIMIT_POSITION,
  REACTION_HIDDEN_TIME,
  WINDOW_HEIGHT,
} from 'configs/scroll-container.config';

import { useRetrieveSafeAreas } from 'hooks/use-retrieve-safe-areas';

const SLIDER_TOGGLE_POSSITION_POINT = 104;

enum Direction {
  UP = 'UP',
  DOWN = 'DOWN',
}

interface IUseScrollContainerResponse {
  isFixedSliderToggle: boolean;
  isHiddenSliderToggle: boolean;
  handleScrollContainer: (element: HTMLElement) => void;
}

export const useScrollContainer = (
  hasTabNavigation: boolean,
  pathname: string,
): IUseScrollContainerResponse => {
  const safeAreaInsets = useRetrieveSafeAreas();

  const [isHiddenSliderToggle, setIsHiddenSliderToggle] = useState(false);
  const [isFixedSliderToggle, setIsFixedSliderToggle] = useState(false);

  const [contentHeight, setContentHeight] = useState(0);
  const [isScrollEnd, setIsScrollEnd] = useState(false);

  const [prevPosition, setPrevPosition] = useState(0);

  const timeoutRef = useRef<NodeJS.Timeout>();
  const directionRef = useRef<Direction>(Direction.UP);
  const headerFixedPositionRef = useRef(0);
  const footerFixedPositionRef = useRef(0);

  const safeAreaInsetTop = useMemo(() => safeAreaInsets?.top ?? 0, [safeAreaInsets?.top]);
  const safeAreaInsetBottom = useMemo(() => safeAreaInsets?.bottom ?? 0, [safeAreaInsets?.bottom]);

  const setCssProperty = useCallback(
    (cssVariable: string, value: number, suffix: string = 'px') => {
      document.documentElement.style.setProperty(cssVariable, `${value}${suffix}`);
    },
    [],
  );

  const getCssPropertyValue = useCallback((cssVariable: string): number => {
    const value = document.documentElement.style.getPropertyValue(cssVariable);

    return Number(value.slice(0, -2));
  }, []);

  const removeCssProperty = useCallback((cssVariable: string) => {
    document.documentElement.style.removeProperty(cssVariable);
  }, []);

  const negativePosition = useMemo(() => {
    if (hasTabNavigation) {
      return NEGATIVE_LIMIT_POSITION;
    }

    return NEGATIVE_LIMIT_POSITION_WITHOUT_NAVIGATION;
  }, [hasTabNavigation]);

  const updateSliderToggleScrollState = useCallback((currentPosition: number) => {
    const isFixedElement = currentPosition > SLIDER_TOGGLE_POSSITION_POINT;
    setIsFixedSliderToggle(isFixedElement);
  }, []);

  const handleScrollContainer = useCallback(
    (element: HTMLElement) => {
      const currentPosition = element.scrollTop;
      const currentBottomPosition = currentPosition + WINDOW_HEIGHT;

      updateSliderToggleScrollState(currentPosition);

      if (contentHeight < CONTENT_HEIGHT_LIMIT) return;

      if (currentPosition <= INACTIVE_TOP_AREA || contentHeight <= currentBottomPosition) {
        removeCssProperty(CSS_VAR_SCROLL_SMOOTH_DURATION);
        removeCssProperty(CSS_VAR_TRANSFORM_MOBILE_HEADER);
        removeCssProperty(CSS_VAR_TRANSFORM_MOBILE_BOTTOM_BAR);
        removeCssProperty(CSS_VAR_TRANSFORM_MOBILE_FIXED_ELEMENT);
        setPrevPosition(currentPosition);
        return;
      }

      setCssProperty(CSS_VAR_SCROLL_SMOOTH_DURATION, 0, 'ms');

      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        setIsScrollEnd(true);
      }, REACTION_HIDDEN_TIME);

      // UP direction
      if (prevPosition > currentPosition) {
        if (directionRef.current === Direction.DOWN) {
          const headerTransformPosition = getCssPropertyValue(CSS_VAR_TRANSFORM_MOBILE_HEADER);
          const footerTransformPosition = getCssPropertyValue(CSS_VAR_TRANSFORM_MOBILE_BOTTOM_BAR);

          headerFixedPositionRef.current = currentPosition + headerTransformPosition;
          footerFixedPositionRef.current = currentPosition - footerTransformPosition;
        }

        let headerCurrentPosition = headerFixedPositionRef.current - currentPosition;
        let footerCurrentPosition = currentPosition - footerFixedPositionRef.current;

        if (headerCurrentPosition > 0) {
          headerCurrentPosition = 0;
        }

        if (footerCurrentPosition < 0) {
          footerCurrentPosition = 0;
        }

        if (POSITIVE_LIMIT_POSITION > footerCurrentPosition) {
          setCssProperty(CSS_VAR_TRANSFORM_MOBILE_FIXED_ELEMENT, footerCurrentPosition);
        }

        setCssProperty(CSS_VAR_TRANSFORM_MOBILE_HEADER, headerCurrentPosition);
        setCssProperty(CSS_VAR_TRANSFORM_MOBILE_BOTTOM_BAR, footerCurrentPosition);

        directionRef.current = Direction.UP;
      }

      // DOWN direction
      if (prevPosition < currentPosition) {
        if (directionRef.current === Direction.UP) {
          const headerTransformPosition = getCssPropertyValue(CSS_VAR_TRANSFORM_MOBILE_HEADER);
          const footerTransformPosition = getCssPropertyValue(CSS_VAR_TRANSFORM_MOBILE_BOTTOM_BAR);

          headerFixedPositionRef.current = currentPosition + headerTransformPosition;
          footerFixedPositionRef.current = currentPosition - footerTransformPosition;
        }

        let headerCurrentPosition = -(currentPosition - headerFixedPositionRef.current);
        let footerCurrentPosition = currentPosition - footerFixedPositionRef.current;
        let fixedElementCurrentPosition = footerCurrentPosition;

        if (headerCurrentPosition < negativePosition - safeAreaInsetTop) {
          headerCurrentPosition = negativePosition - safeAreaInsetTop;
        }

        if (footerCurrentPosition <= POSITIVE_LIMIT_POSITION) {
          fixedElementCurrentPosition = footerCurrentPosition;
        } else {
          fixedElementCurrentPosition = POSITIVE_LIMIT_POSITION;
        }

        if (footerCurrentPosition > POSITIVE_LIMIT_POSITION + safeAreaInsetBottom) {
          footerCurrentPosition = POSITIVE_LIMIT_POSITION + safeAreaInsetBottom;
        }

        setCssProperty(CSS_VAR_TRANSFORM_MOBILE_HEADER, headerCurrentPosition);
        setCssProperty(CSS_VAR_TRANSFORM_MOBILE_BOTTOM_BAR, footerCurrentPosition);
        setCssProperty(CSS_VAR_TRANSFORM_MOBILE_FIXED_ELEMENT, fixedElementCurrentPosition);

        directionRef.current = Direction.DOWN;
      }

      setIsScrollEnd(false);
      setPrevPosition(currentPosition);
    },
    [
      contentHeight,
      prevPosition,
      safeAreaInsetTop,
      safeAreaInsetBottom,
      negativePosition,
      setCssProperty,
      getCssPropertyValue,
      removeCssProperty,
      updateSliderToggleScrollState,
    ],
  );

  useEffect(() => {
    const headerTransformPosition = getCssPropertyValue(CSS_VAR_TRANSFORM_MOBILE_HEADER);

    if (headerTransformPosition !== 0 && isScrollEnd) {
      removeCssProperty(CSS_VAR_SCROLL_SMOOTH_DURATION);

      const fullNegativeHeight = NEGATIVE_LIMIT_POSITION - safeAreaInsetTop;
      const fullPositiveHeight = POSITIVE_LIMIT_POSITION + safeAreaInsetBottom;

      setCssProperty(CSS_VAR_TRANSFORM_MOBILE_HEADER, fullNegativeHeight);
      setCssProperty(CSS_VAR_TRANSFORM_MOBILE_BOTTOM_BAR, fullPositiveHeight);
      setCssProperty(CSS_VAR_TRANSFORM_MOBILE_FIXED_ELEMENT, POSITIVE_LIMIT_POSITION);

      headerFixedPositionRef.current = prevPosition + fullNegativeHeight;
      footerFixedPositionRef.current = prevPosition - fullPositiveHeight;
      directionRef.current = Direction.DOWN;
    }
  }, [
    prevPosition,
    safeAreaInsetTop,
    safeAreaInsetBottom,
    isScrollEnd,
    setCssProperty,
    getCssPropertyValue,
    removeCssProperty,
  ]);

  useEffect(() => {
    const headerTransformPosition = getCssPropertyValue(CSS_VAR_TRANSFORM_MOBILE_HEADER);

    const percentDifference =
      (Math.abs(headerTransformPosition) * 100) / Math.abs(NEGATIVE_LIMIT_POSITION);
    const transparentDefinition = 1 - Math.trunc(percentDifference) / 100;
    const roundedNumber = Number(transparentDefinition.toFixed(2));

    setCssProperty(CSS_VAR_OPACITY_MOBILE_HEADER, roundedNumber, '');

    setIsHiddenSliderToggle(roundedNumber === 0);
  }, [prevPosition, setCssProperty, getCssPropertyValue]);

  useEffect(() => {
    const element: Maybe<HTMLElement> = document.getElementById('PageWrapper');

    const resizeObserver = new ResizeObserver((entries) => {
      setContentHeight(entries[0].target.clientHeight);
    });

    if (element) {
      resizeObserver.observe(element);
    }

    directionRef.current = Direction.UP;

    return () => {
      resizeObserver.disconnect();
    };
  }, [pathname]);

  useEffect(() => {
    setCssProperty(CSS_VAR_SCROLL_SMOOTH_DURATION, 0, 'ms');
    removeCssProperty(CSS_VAR_TRANSFORM_MOBILE_HEADER);
    removeCssProperty(CSS_VAR_TRANSFORM_MOBILE_BOTTOM_BAR);
    removeCssProperty(CSS_VAR_TRANSFORM_MOBILE_FIXED_ELEMENT);
    removeCssProperty(CSS_VAR_OPACITY_MOBILE_HEADER);
  }, [pathname, removeCssProperty, setCssProperty]);

  return {
    handleScrollContainer,
    isHiddenSliderToggle,
    isFixedSliderToggle,
  };
};
