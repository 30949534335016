import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionClick } from 'components/bars/bar-action.type';
import { ITab } from 'components/ui/tabs/tabs.component';

export const PROFILE_TABS: ITab<BarActionClick<string>>[] = [
  {
    id: 'bookmarks',
    title: 'Bookmarks',
    action: {
      type: BarAction.Click,
      payload: 'bookmarks',
    },
  },
];
