import { FC } from 'react';
import { components, SingleValueProps } from 'react-select';
import { IPlayerOption } from 'containers/profile/profile-info/interfaces/player-option.interface';

import styles from './player-single-value.module.less';

interface IPlayerSingleValue extends SingleValueProps<Maybe<IPlayerOption>, false> {}

export const PlayerSingleValue: FC<IPlayerSingleValue> = ({
  children,
  ...props
}: IPlayerSingleValue) => {
  return (
    <components.SingleValue {...props}>
      <div style={{ color: props.data?.fontColor }} className={styles.PlayerSingleValue}>
        {props.data?.label}
      </div>
    </components.SingleValue>
  );
};
