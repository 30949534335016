import { SeasonType } from 'services/seasons/enums/season-type.enum';

import { ISeason } from 'stores/seasons/interfaces/season.interface';

export const seasonColumnComparator = (currentItem: ISeason, nextItem: ISeason) => {
  if (currentItem.key === nextItem.key) {
    const typesInOrder = [SeasonType.PRE, SeasonType.REG, SeasonType.PST, SeasonType.PIT];
    return typesInOrder.indexOf(currentItem.type) - typesInOrder.indexOf(nextItem.type);
  }

  return currentItem.key - nextItem.key;
};
