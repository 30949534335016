import { SortDirection } from 'ag-grid-community';

import { PLAYERS_PAGE_SIZE } from 'components/stats/global-stats/global-stats-table.config';
import { IColumnChartItem } from 'components/ui/column-charts/column-charts.component';

import { IPlayerGlobalStatsRowData } from '../interfaces/global-stats-row-data.interfaces';
import { PlayersGlobalStatsSortType } from '../types/global-stats-sort.types';

export const getSortedPlayersItems = (
  sort: PlayersGlobalStatsSortType,
  playersGlobalStatsRowData: IPlayerGlobalStatsRowData[],
  page: number,
  sortDirection: SortDirection = 'desc',
): IColumnChartItem[] => {
  const start = PLAYERS_PAGE_SIZE * (page - 1);
  const end = start + PLAYERS_PAGE_SIZE;

  return playersGlobalStatsRowData
    .map((rowData) => ({
      avatarUrl: rowData.name.smallLogoUrl,
      color: rowData.name.color,
      id: `${rowData.name.id}-${rowData.name.color}`,
      name: rowData.name.name,
      value: rowData[sort],
    }))
    .sort((currentItem, nextItem) =>
      sortDirection === 'desc'
        ? nextItem.value - currentItem.value
        : currentItem.value - nextItem.value,
    )
    .slice(start, end);
};
