import { useCallback, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AuthStore } from 'stores/auth/auth.store';
import { IUpdateUserParams } from 'stores/auth/interfaces/update-user.params.interface';
import { BookmarksStore } from 'stores/bookmarks/bookmarks.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { getPreviewImage } from 'stores/gallery/utils/get-preview-image.util';
import { LayoutStore } from 'stores/layout/layout.store';

import { ACCEPT_FILES } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { ProfileThumbnailForm } from 'components/forms/profile-thumbnail/profile-thumbnail-form.component';
import { GalleryTheme } from 'components/modals/gallery-modal/gallery-modal.component';
import { Loader } from 'components/ui/loader/loader.component';

import defaultThumbnail from 'assets/images/svg/user-default-background.svg';

import styles from './profile-thumbnail.module.less';

export const ProfileThumbnailContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const bookmarksStore = useInjection<BookmarksStore>(TYPES.BookmarksStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const userData = authStore.userMe;

  const handleUpdateUser = useCallback(
    async (updateUserParams: IUpdateUserParams) => {
      await authStore.updateUserMe(updateUserParams);
      await bookmarksStore.refresh();
    },
    [authStore, bookmarksStore],
  );

  const handleSubmitThumbnail = useCallback(
    (thumbnailData: FieldValues) => {
      const cleanedBase64 = thumbnailData.thumbnail.value.split(',')[1];
      handleUpdateUser({ thumbnail: cleanedBase64 });
    },
    [handleUpdateUser],
  );

  const handleCropModalOpen = useCallback(
    (isOpen: boolean) => {
      layoutStore.setIsCropAvatarModalOpen(false);
      layoutStore.setIsCropBackgroundModalOpen(isOpen);
    },
    [layoutStore],
  );

  const bannerUrl = useMemo(
    () => userData?.profile.thumbnailUrl || defaultThumbnail,
    [userData?.profile.thumbnailUrl],
  );

  const handleOpenPreview = useCallback(() => {
    if (!userData || isDesktopPlus) {
      return;
    }

    const image = getPreviewImage(
      bannerUrl,
      `${userData.username.toLowerCase()}-thumbnail`,
      userData.profile.smallThumbnailUrl,
    );

    const watermarkEntity = {
      name: userData.username,
      logoUrl: userData.profile.smallAvatarUrl,
    };

    galleryStore.setGalleryAttachments([image]);
    galleryStore.setTheme(GalleryTheme.Preview);
    galleryStore.setWatermarkEntity(watermarkEntity);
    galleryStore.setIsGalleryOpen(true);
  }, [userData, isDesktopPlus, bannerUrl, galleryStore]);

  if (authStore.fetching) {
    return (
      <div className={styles.Wrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    userData && (
      <div role="presentation" onClick={handleOpenPreview}>
        <ProfileThumbnailForm
          accept={ACCEPT_FILES}
          onSubmit={handleSubmitThumbnail}
          thumbnailUrl={bannerUrl}
          thumbnailLogoUrl={userData?.profile.thumbnailLogoUrl}
          thumbnailNicknameUrl={userData?.profile.thumbnailNicknameUrl}
          isCropModalVisible={layoutStore.isCropBackgroundModalOpen}
          onSetCropModal={handleCropModalOpen}
        />
      </div>
    )
  );
});
