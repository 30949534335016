import { inject, injectable } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import {
  ICategoryPlayerRankingsItemResponse,
  ICategoryPlayerRankingsItemsResponse,
  IPlayerRankingsAdapter,
} from 'services/player-rankings/interfaces/player-rankings-response.interface';
import { PlayerRankingsService } from 'services/player-rankings/player-rankings.service';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { LayoutStore } from 'stores/layout/layout.store';

import { TYPES } from 'configs/di-types.config';

import { playerRankingsAdapter } from './adapters/player-rankings-adapter.util';
import { playerRankingsCategoryAdapter } from './adapters/player-rankings-category-adapter.util';
import {
  ICategoryPlayerRankingsItem,
  IPlayerRankings,
} from './interfaces/player-rankings.interface';

const PAGINATION_LIMIT = 20;

@injectable()
export class PlayerRankingsStore extends AdvancedEntriesStore<
  ICategoryPlayerRankingsItem,
  ICategoryPlayerRankingsItemResponse,
  ICategoryPlayerRankingsItemsResponse
> {
  private readonly layoutStore: LayoutStore;

  private readonly playerRankingsService: PlayerRankingsService;

  public filter: Maybe<string>;

  public isFetchingPlayerRankings: boolean;

  public playerRankings: Maybe<IPlayerRankings>;

  constructor(
    @inject(TYPES.PlayerRankingsService) playerRankingsService: PlayerRankingsService,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
  ) {
    super(PAGINATION_LIMIT, false);

    this.layoutStore = layoutStore;

    this.playerRankingsService = playerRankingsService;

    this.filter = null;

    this.isFetchingPlayerRankings = false;

    this.entries = [];

    this.playerRankings = null;

    makeObservable(this, {
      filter: observable,
      playerRankings: observable,
      isFetchingPlayerRankings: observable,

      refresh: action.bound,
      setPlayerRankings: action.bound,
      setFetchingPlayerRankings: action.bound,
      setFilter: action.bound,
    });

    reaction(() => this.filter, this.refresh);
  }

  public setPlayerRankings(value: Maybe<IPlayerRankings>) {
    this.playerRankings = value;
  }

  public setFetchingPlayerRankings(value: boolean) {
    this.isFetchingPlayerRankings = value;
  }

  public async fetchAllStatsByPullToRefresh() {
    await this.fetchPlayerRankings();

    this.layoutStore.setPulledRefresher(false);
  }

  public async fetchSingleCategoryByPullToRefresh() {
    await this.refresh();

    this.layoutStore.setPulledRefresher(false);
  }

  public setFilter(value: Maybe<string>) {
    this.filter = value;
  }

  public async fetchPlayerRankings() {
    this.setFetchingPlayerRankings(true);
    this.setPlayerRankings(null);

    const response = await this.playerRankingsService.fetchPlayerRankings();

    if (response.success) {
      this.setPlayerRankings(playerRankingsAdapter(response.data));
    }

    this.setFetchingPlayerRankings(false);
  }

  public async fetchNext(): Promise<void> {
    if (this.filter) {
      await this.retrieveNext(
        this.playerRankingsService.fetchPlayerRankingsByCategory(this.pagination, this.filter),
        <IPlayerRankingsAdapter>playerRankingsCategoryAdapter,
      );
    }
  }

  public async refresh() {
    await super.refresh();
    await this.fetchNext();
  }
}
