import { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { IPlayerDetails } from 'stores/player/interfaces/player-profile.interfaces';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import {
  GAME_ROW_HEIGHT,
  STATS_ABBRS,
} from 'components/player-profile/player-profile-games/game-log.config';
import { GameStatsRow } from 'components/player-profile/player-profile-games/game-stats-row/game-stats-row.component';
import { TeamAvatar, TeamAvatarTheme } from 'components/ui/team-avatar/team-avatar.component';

import { ISingleSplitItem } from '../player-profile-games.interface';

import styles from './recent-splits.module.less';

interface IRecentSplitsProps {
  games: ISingleSplitItem[];
  player: Maybe<IPlayerDetails>;
  onTeamClick: (teamId: number) => void;
}

export const RecentSplits = memo((props: IRecentSplitsProps) => {
  const { games, player } = props;

  const navigate = useNavigate();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handleRowClick = useCallback(
    (gameId: number) => {
      if (player?.slug) {
        navigate(
          getPath(paths.PLAYER_PERFORMANCE, {
            [paths.PLAYER_SLUG_PARAM]: player.slug,
            [paths.GAME_ID_PARAM]: String(gameId),
          }),
        );
      }
    },
    [navigate, player?.slug],
  );

  const handleStatsRowClick = useCallback(
    (gameId: number) => () => handleRowClick(gameId),
    [handleRowClick],
  );

  const splitGridTemplate = useMemo(() => {
    const gridTemplateRows = `repeat(${games.length}, ${GAME_ROW_HEIGHT}rem)`;

    if (isDesktopPlus) {
      return {
        gridTemplateColumns: `repeat(${STATS_ABBRS.length}, minmax(max-content, 1fr))`,
        gridTemplateRows,
      };
    }

    return {
      gridTemplateColumns: `repeat(${STATS_ABBRS.length}, max-content)`,
      gridTemplateRows,
    };
  }, [games.length, isDesktopPlus]);

  if (!games.length) {
    return null;
  }

  return (
    <div className={styles.RecentSplits}>
      <div className={styles.RecentSplits__FirstColumn}>
        {games.map((item) => (
          <TeamAvatar
            theme={TeamAvatarTheme.RecentSplits}
            key={item.game.id}
            isWinner={item.isWinner}
            isHomePlayer={item.isHomePlayer}
            game={item.game}
            player={player}
            isCompactView={false}
            onRowClick={handleRowClick}
            onTeamClick={props.onTeamClick}
          />
        ))}
      </div>
      <div style={splitGridTemplate} className={styles.RecentSplits__SecondColumn}>
        {games.map((item) => (
          <GameStatsRow
            isCompactView={false}
            key={item.game.id}
            forHeader={false}
            onRowClick={handleStatsRowClick(item.game.id)}
            stats={item}
          />
        ))}
      </div>
    </div>
  );
});
