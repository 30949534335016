import { ITeamsStatsLeadersResponse } from 'services/team-stats/interfaces/teams-stats-leaders.interfaces';

import { ITeamsStatsLeaders } from '../interfaces/teams-stats-leaders.interface';

import { teamLeaderAdapter } from './team-leader-adapter.util';

export function teamsStatsLeadersAdapter(
  teamStatsLeadersResponse: ITeamsStatsLeadersResponse,
): ITeamsStatsLeaders {
  const {
    blocks,
    diff_points: diffPoints,
    fgp,
    opponent_points: pointsAllowed,
    points,
    rebounds,
  } = teamStatsLeadersResponse;

  return {
    blocks: teamLeaderAdapter(blocks, 'blocks'),
    fieldGoalsPercentage: teamLeaderAdapter(fgp, 'fgp'),
    points: teamLeaderAdapter(points, 'points'),
    pointsAllowed: teamLeaderAdapter(pointsAllowed, 'opponent_points'),
    rebounds: teamLeaderAdapter(rebounds, 'tot_reb'),
    pointsDifferential: teamLeaderAdapter(diffPoints, 'diff_points'),
  };
}
