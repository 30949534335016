import { useState, useEffect } from 'react';

import { debounce } from 'helpers/debounce.util';

export const useResponsive = (
  bounds: number[],
  checkHeight = false,
  debounceTime = 100,
  ignoreFullScreen = false,
): boolean[] => {
  const [passed, setPassed] = useState<boolean[]>(
    bounds.map((bound) => window.innerWidth >= bound),
  );

  useEffect(() => {
    const handleResize = debounce(() => {
      if (ignoreFullScreen && document.fullscreenElement) return;

      const value = checkHeight ? window.innerHeight : window.innerWidth;
      setPassed(bounds.map((bound) => value >= bound));
    }, debounceTime);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ignoreFullScreen, checkHeight, debounceTime, bounds]);

  return passed;
};
