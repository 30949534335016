import { useCallback } from 'react';

import { IReportReason } from 'services/reports/interfaces/report-reason.interface';

import { OpenPreviewType } from 'stores/gallery/types/gallery.type';
import { IPost } from 'stores/posts/interfaces/post.interface';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { IAuthor } from 'components/modals/share-modal/share-modal.component';
import { PostsTheme } from 'components/posts/post-card/enums/posts-theme.enum';
import { PostCard } from 'components/posts/post-card/post-card.component';
import { ReactionDirectionType } from 'components/reactions/reactions/reactions.component';
import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';
import { RichTextContentTypes } from 'components/ui/rich-text-preview/rich-text-preview.component';

import { IFormattedDates } from '../post-card/interfaces/post-info.interface';
import { PostCardFeedsTheme } from '../post-card/post-card-feeds/post-card-feeds.component';

export interface ISharePublicationData {
  user: IAuthor;
  formattedDates: IFormattedDates;
  uuid?: string;
}

export interface IPostDetailedProps {
  feedsTheme: PostCardFeedsTheme;
  team: Maybe<ITeamsStats>;
  post: IPost;
  favoriteTeam: Maybe<ITeamsStats>;
  favoritePlayer: Maybe<IPlayerStats>;
  reportReasons: Maybe<IReportReason[]>;
  userId: Maybe<string>;
  onTogglePostToBookmarks: (id: string) => void;
  onShareClick: (comment?: ISharePublicationData) => void;
  onPollVote: (voteData: IPollVoteData) => void;
  onPollExpired: (postId: string) => void;
  onVotePost?: (
    postId: string,
    reaction: string,
    type: ReactionDirectionType,
    teamId?: number,
    playerId?: number,
  ) => void;
  onOpenPreview: OpenPreviewType;
  onDeletePost: (id: string) => void;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  triggerAuthorisationCheck: () => boolean;
  onCloseReportsPopup: (postId: string) => void;
  onSendReport?: (
    postId: string,
    reasonId: number,
    teamId?: number,
    playerId?: number,
  ) => Promise<boolean>;
}

export const PostDetailed = (props: IPostDetailedProps) => {
  const {
    feedsTheme,
    userId,
    team,
    post,
    favoriteTeam,
    favoritePlayer,
    reportReasons,
    onShareClick,
  } = props;

  const handleShowModal = useCallback(
    (comment?: ISharePublicationData) => () => {
      onShareClick(comment);
    },
    [onShareClick],
  );

  return (
    <PostCard
      feedsTheme={feedsTheme}
      theme={PostsTheme.Detail}
      editorType={RichTextContentTypes.FullHeight}
      isLinksClickable
      userId={userId}
      team={team}
      isBookmarked={post.isBookmarked}
      userInfo={post.user}
      favoriteTeam={favoriteTeam}
      favoritePlayer={favoritePlayer}
      reportReasons={reportReasons}
      postInfo={{
        embeddedLink: post.embeddedLink,
        bookmarksCount: post.bookmarksCount,
        commentsCount: post.commentsAmount,
        id: post.uuid,
        createdDates: post.formattedDates,
        content: post.content,
        reactionsSum: post.votesTotal,
        sharesCount: post.sharesCount,
        title: post.title,
        reactions: {
          positive: post.reactionsTotal.upVote,
          negative: post.reactionsTotal.downVote,
        },
        attachments: post.attachments,
        source: post.source,
        contentCard: post.contentCard,
        feeds: post.feeds,
      }}
      onDeletePost={props.onDeletePost}
      onOpenPreview={props.onOpenPreview}
      onVotePost={props.onVotePost}
      onTogglePostToBookmarks={props.onTogglePostToBookmarks}
      onShareClick={handleShowModal()}
      onPollVote={props.onPollVote}
      onPollExpired={props.onPollExpired}
      onTeamClick={props.onTeamClick}
      onPlayerClick={props.onPlayerClick}
      onSendReport={props.onSendReport}
      onCloseReportsPopup={props.onCloseReportsPopup}
      triggerAuthorisationCheck={props.triggerAuthorisationCheck}
    />
  );
};
