import { FC } from 'react';

import { PlayerProfileFeedContainer } from 'containers/player-profile/player-profile-feed/player-profile-feed.container';
import { PlayerProfileFeedBoardContainer } from 'containers/player-profile/player-profile-feed-board/player-profile-feed-board.container';
import { ScrollTopContainer } from 'containers/scroll-top/scroll-top.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useEnableRefresher } from 'hooks/use-enable-refresher';
import { useResponsive } from 'hooks/use-responsive';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const PlayerProfileFeedRoute: FC = () => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  useEnableRefresher();

  return (
    <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
      <Column mobile={1}>
        <PlayerProfileFeedContainer />
      </Column>
      {isDesktopPlus && (
        <Column mobile={2}>
          <PlayerProfileFeedBoardContainer />
          <ScrollTopContainer />
        </Column>
      )}
    </Row>
  );
};
