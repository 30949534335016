import { FC, memo } from 'react';

import {
  TOP_PERFORMERS_HINT_ITEMS,
  TOP_PERFORMERS_HINT_MESSAGE,
} from 'containers/player-rankings/player-rankings.config';

import { ENCODED_BULLET } from 'configs/encoded-symbols.config';

import styles from './top-performer-calculations-main.module.less';

interface ITopPerformerCalculationsMainProps {
  withHeader?: boolean;
}
export const TopPerformerCalculationsMain: FC<ITopPerformerCalculationsMainProps> = memo(
  (props: ITopPerformerCalculationsMainProps) => {
    const { withHeader = false } = props;

    return (
      <div className={styles.Hint}>
        {withHeader && <div className={styles.Hint__Header}>Calculation</div>}
        <div className={styles.Hint__Message}>{TOP_PERFORMERS_HINT_MESSAGE}</div>
        <div className={styles.Hint__Categories}>
          {TOP_PERFORMERS_HINT_ITEMS.map((item) => {
            return (
              <div key={item.title} className={styles.Item}>
                <span className={styles.Item__Title}>{ENCODED_BULLET}</span>
                <span className={styles.Item__Title}>{item.title}</span>
                <span className={styles.Item__Description}>{item.description}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);
