import { IPlayerPeriodPerformance } from 'stores/player-performance/interfaces/player-performance.interface';

interface IPerformanceStat {
  label: string;
  key: keyof IPlayerPeriodPerformance;
}

export const performanceStatsConfig: IPerformanceStat[] = [
  {
    key: 'minutes',
    label: 'MIN',
  },
  {
    key: 'points',
    label: 'PTS',
  },
  {
    key: 'totalRebounds',
    label: 'REB',
  },
  {
    key: 'assists',
    label: 'AST',
  },
  {
    key: 'blocks',
    label: 'BLK',
  },
  {
    key: 'steals',
    label: 'STL',
  },
  {
    key: 'plusMinus',
    label: '+/-',
  },
  {
    key: 'trueShootingPercentage',
    label: 'TS%',
  },
  {
    key: 'fieldGoals',
    label: 'FG',
  },
  {
    key: 'fieldGoalsPercentage',
    label: 'FG%',
  },
  {
    key: 'threePoints',
    label: '3PT',
  },
  {
    key: 'threePointsFieldGoalsPercentage',
    label: '3PT%',
  },
  {
    key: 'freeThrows',
    label: 'FT',
  },
  {
    key: 'freeThrowsPercentage',
    label: 'FT%',
  },
  {
    key: 'offensiveReb',
    label: 'OREB',
  },
  {
    key: 'defensiveReb',
    label: 'DREB',
  },
  {
    key: 'turnovers',
    label: 'TO',
  },
  {
    key: 'personalFouls',
    label: 'PF',
  },
  {
    key: 'fantasyPoints',
    label: 'FPS',
  },
];

export const sharePerformanceStatsConfig: { label: string; key: keyof IPlayerPeriodPerformance }[] =
  [
    {
      key: 'points',
      label: 'PTS',
    },
    {
      key: 'totalRebounds',
      label: 'REB',
    },
    {
      key: 'assists',
      label: 'AST',
    },
    {
      key: 'blocks',
      label: 'BLK',
    },
    {
      key: 'steals',
      label: 'STL',
    },
    {
      key: 'plusMinus',
      label: '+/-',
    },
  ];
