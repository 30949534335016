import { memo, useMemo } from 'react';
import cn from 'classnames';

import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import { StandingsTeamAchievementEnum } from 'stores/standings/enums/standings-team-achievement.enum';

import styles from './standings-team-achievement.module.less';

interface IStandingsTeamAchievementProps {
  achievement: StandingsTeamAchievementEnum;
}

export const StandingsTeamAchievement = memo((props: IStandingsTeamAchievementProps) => {
  const { achievement } = props;

  const iconClasses = useMemo(
    () =>
      cn({
        [styles.StandingsTeamAchievement]: achievement !== StandingsTeamAchievementEnum.Star,
        [styles['StandingsTeamAchievement--star']]:
          achievement === StandingsTeamAchievementEnum.Star,
        [styles['StandingsTeamAchievement--green-filled']]:
          achievement === StandingsTeamAchievementEnum.GreenFilled,
        [styles['StandingsTeamAchievement--green']]:
          achievement === StandingsTeamAchievementEnum.Green,
        [styles['StandingsTeamAchievement--purple-filled']]:
          achievement === StandingsTeamAchievementEnum.PurpleFilled,
        [styles['StandingsTeamAchievement--purple']]:
          achievement === StandingsTeamAchievementEnum.Purple,
        [styles['StandingsTeamAchievement--blue-filled']]:
          achievement === StandingsTeamAchievementEnum.Blue,
        [styles['StandingsTeamAchievement--red-filled']]:
          achievement === StandingsTeamAchievementEnum.RedFilled,
        [styles['StandingsTeamAchievement--red']]: achievement === StandingsTeamAchievementEnum.Red,
      }),
    [achievement],
  );

  return (
    <div className={iconClasses}>
      {achievement === StandingsTeamAchievementEnum.Star && (
        <IconFont name={IconFontName.StarFilled} size={IconFontSize.ExtraSmall} />
      )}
    </div>
  );
});
