import { cloneElement, memo, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Camera, CameraResultType, CameraSource, GalleryPhotos } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import { v4 } from 'uuid';

import {
  ConvertImageItemToAttachmentType,
  IUploadImageData,
} from 'services/application/interfaces/upload-image.interface';
import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ContainerNamesEnum } from 'services/google-analytic/enums/container-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { base64ToFile } from 'helpers/base64-to-file.util';
import { getOrientationFromBase64 } from 'helpers/get-orientation-from-base64.util';
import { setImageOrientation } from 'helpers/set-image-orientation.util';
import { IFileData } from 'helpers/to-base64.util';

import { useMainProvider } from 'hooks/use-main-provider';

import { PhotoCameraBottomSheet } from 'components/bottom-sheet/photo-camera-bottom-sheet/photo-camera-bottom-sheet.component';
import { handleIosPhotosDenied } from 'components/forms/photo-camera-field-with-backend-processing/utils/handle-ios-photos-denied.util';
import {
  cameraPopupButton,
  cameraPopupMessage,
  cameraPopupTitle,
  photoPopupButton,
  photoPopupMessage,
  photoPopupTitle,
} from 'components/popups/media-permission-popup/config';
import { MediaPermissionPopup } from 'components/popups/media-permission-popup/media-permission-popup.component';

import cameraImage from 'assets/images/svg/camera-image.svg';
import photosImage from 'assets/images/svg/gallery-image.svg';

import { handleIosCameraDenied } from './utils/handle-ios-camera-denied.util';

interface IPhotoCameraProps {
  enabledFunction: boolean;
  children: JSX.Element;
  setLoadingImages?: (images: string[]) => void;
  convertImageItemToAttachment: ConvertImageItemToAttachmentType;
}

export const PhotoCameraFieldWithBackendProcessing = memo(
  (props: IPhotoCameraProps): JSX.Element => {
    const { enabledFunction, children, setLoadingImages, convertImageItemToAttachment } = props;

    const { isNativeAndroidApp } = useMainProvider();

    const [isCameraPermissionPopupOpen, setIsCameraPermissionPopupOpen] = useState(false);
    const [isPhotoPermissionPopupOpen, setIsPhotoPermissionPopupOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imagesFromCamera, setImagesFromCamera] = useState<IUploadImageData[]>([]);

    const defineFileDataScheme = useCallback(
      (base64Data: string, type: string = 'jpeg'): IFileData => {
        const hash = v4();

        return {
          value: base64Data,
          meta: {
            size: 0,
            type,
            filename: 'Default',
            hash,
          },
        };
      },
      [],
    );

    const retrieveDataFiles = useCallback(
      async ({ photos }: GalleryPhotos): Promise<File[]> => {
        const uploadedFiles: File[] = [];

        setIsModalOpen(false);

        if (setLoadingImages) {
          const loaders: string[] = photos.map((photo) => photo.webPath);

          setLoadingImages(loaders);
        }

        const result = await Promise.allSettled(
          photos.map(async (photo) => {
            if (photo.path) {
              const { data: fileSystemData } = await Filesystem.readFile({ path: photo.path });

              if (typeof fileSystemData === 'string') {
                const dataFile = defineFileDataScheme(fileSystemData, photo.format);
                const fileInstance = base64ToFile(dataFile.value, dataFile.meta.type);
                uploadedFiles.push(fileInstance);
              }
            }
          }),
        );

        if (result) {
          return uploadedFiles;
        }

        return [];
      },
      [defineFileDataScheme, setLoadingImages],
    );

    const handleSelectFromLibrary = useCallback(async () => {
      const photos = await Camera.pickImages({});
      const files = await retrieveDataFiles(photos);
      const imagesAttachments: IUploadImageData[] = [];
      const result = await Promise.allSettled(
        files.map(async (file) => {
          const imageAttachment = await convertImageItemToAttachment(file);

          if (imageAttachment) {
            imagesAttachments.push(imageAttachment);
          }
        }),
      );

      if (result) {
        if (setLoadingImages) {
          setLoadingImages([]);
        }
        setImagesFromCamera(imagesAttachments);
      }
    }, [retrieveDataFiles, setLoadingImages, convertImageItemToAttachment]);

    const handleTakePicture = useCallback(async () => {
      const photo = await Camera.getPhoto({
        allowEditing: false,
        correctOrientation: true,
        resultType: CameraResultType.Base64,
        saveToGallery: false,
        presentationStyle: 'fullscreen',
        source: CameraSource.Camera,
      });

      const { base64String, format } = photo;

      setIsModalOpen(false);

      if (setLoadingImages) {
        setLoadingImages([`Default.${format}`]);
      }

      if (base64String) {
        getOrientationFromBase64(base64String, format, async (orientation: number) => {
          setImageOrientation(base64String, format, orientation, async (base64Result: string) => {
            const fileInstance = base64ToFile(base64Result, format);
            const imageAttachment = await convertImageItemToAttachment(fileInstance);

            if (imageAttachment) {
              setImagesFromCamera([imageAttachment]);
            }

            if (setLoadingImages) {
              setLoadingImages([]);
            }
          });
        });
      }
    }, [convertImageItemToAttachment, setLoadingImages]);

    const handleRejectCameraPermissions = useCallback(() => {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PermissionsCameraAccessNotNow,
        },
      });
      setIsCameraPermissionPopupOpen(false);
    }, []);

    const handleRejectPhotoPermissions = useCallback(() => {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PermissionsPhotoLibraryAllowNotNow,
        },
      });
      setIsPhotoPermissionPopupOpen(false);
    }, []);

    const handleAllowCameraPermissions = useCallback(async () => {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PermissionsCameraAccessAllow,
        },
      });
      setIsCameraPermissionPopupOpen(false);

      const permission = await Camera.checkPermissions();

      if (permission.camera === 'denied' && !isNativeAndroidApp) {
        await handleIosCameraDenied();
      } else {
        await handleTakePicture();
      }
    }, [handleTakePicture, isNativeAndroidApp]);

    const handleAllowPhotoPermissions = useCallback(async () => {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PermissionsPhotoLibraryAllow,
        },
      });
      setIsPhotoPermissionPopupOpen(false);
      const permission = await Camera.checkPermissions();

      if (permission.photos === 'denied' && !isNativeAndroidApp) {
        await handleIosPhotosDenied();
      } else {
        await handleSelectFromLibrary();
      }
    }, [handleSelectFromLibrary, isNativeAndroidApp]);

    const handleOpenBottomSheetModal = useCallback(
      (event: SyntheticEvent) => {
        if (enabledFunction) {
          event.preventDefault();
          setIsModalOpen(true);
        }
      },
      [enabledFunction],
    );

    const handleClose = useCallback(() => {
      setIsModalOpen(false);
    }, []);

    const handleCheckPhotoPermissions = useCallback(
      async (event: SyntheticEvent) => {
        event.preventDefault();
        const permission = await Camera.checkPermissions();

        if (permission.photos !== 'granted') {
          setIsPhotoPermissionPopupOpen(true);
          handleClose();
        } else {
          handleClose();
          setIsPhotoPermissionPopupOpen(false);
          await handleSelectFromLibrary();
        }
      },
      [handleSelectFromLibrary, handleClose],
    );

    const handleCheckCameraPermissions = useCallback(
      async (event: SyntheticEvent) => {
        event.preventDefault();
        const permission = await Camera.checkPermissions();

        if (permission.camera !== 'granted') {
          setIsCameraPermissionPopupOpen(true);
          handleClose();
        } else {
          handleClose();
          setIsCameraPermissionPopupOpen(false);
          await handleTakePicture();
        }
      },
      [handleTakePicture, handleClose],
    );

    useEffect(() => {
      if (isCameraPermissionPopupOpen) {
        GoogleAnalyticService.event({
          eventName: 'container_custom_view',
          eventParams: {
            container_type: ContainerNamesEnum.PermissionsCameraAccess,
          },
        });
      }
    }, [isCameraPermissionPopupOpen]);

    useEffect(() => {
      if (isPhotoPermissionPopupOpen) {
        GoogleAnalyticService.event({
          eventName: 'container_custom_view',
          eventParams: {
            container_type: ContainerNamesEnum.PermissionsPhotoLibrary,
          },
        });
      }
    }, [isPhotoPermissionPopupOpen]);

    if (!enabledFunction) {
      return children;
    }

    return (
      <>
        {cloneElement(children, {
          onClick: handleOpenBottomSheetModal,
          imagesFromCamera,
        })}
        <PhotoCameraBottomSheet
          visible={isModalOpen}
          onSelectFromLibrary={handleCheckPhotoPermissions}
          onTakePicture={handleCheckCameraPermissions}
          onClose={handleClose}
        />
        {/* this is camera access popup */}
        <MediaPermissionPopup
          visible={isCameraPermissionPopupOpen}
          title={cameraPopupTitle}
          message={cameraPopupMessage}
          primaryButtonText={cameraPopupButton}
          imageUrl={cameraImage}
          onClose={handleRejectCameraPermissions}
          onApprove={handleAllowCameraPermissions}
        />
        {/* this is photos access popup */}
        <MediaPermissionPopup
          visible={isPhotoPermissionPopupOpen}
          title={photoPopupTitle}
          message={photoPopupMessage}
          primaryButtonText={photoPopupButton}
          imageUrl={photosImage}
          onClose={handleRejectPhotoPermissions}
          onApprove={handleAllowPhotoPermissions}
        />
      </>
    );
  },
);
