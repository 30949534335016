import { ISplitsInGameResponse } from 'services/player/interfaces/player-splits.interface';

import { singleGameMiniAdapter } from 'stores/statistics/adapters/single-game-mini-adapter.util';

import { roundToSingleDecimal } from 'helpers/round-to-single-decimal.util';

import { ISingleSplitItem } from 'components/player-profile/player-profile-games/player-profile-games.interface';

export function singleSplitAdapter(response: ISplitsInGameResponse): ISingleSplitItem {
  const {
    assists,
    blocks,
    fgm,
    fgp,
    ftm,
    fta,
    ftp,
    fga,
    personal_fouls: personalFouls,
    points,
    steals,
    tot_reb: totalRebounds,
    tpm,
    tpp,
    tpa,
    turnovers,
    minutes,
    game,
    def_reb: defensiveReb,
    off_reb: offensiveReb,
    fantasy_points: fantasyPoints,
    plus_minus: plusMinus,
    tsp,
    is_winning_team: isWinningTeam,
    is_home_player: isHomePlayer,
  } = response;

  return {
    isWinner: isWinningTeam,
    isHomePlayer,
    game: singleGameMiniAdapter(game),
    minutes: Math.round(minutes),
    fantasyPoints: roundToSingleDecimal(fantasyPoints),
    points: roundToSingleDecimal(points),
    rebounds: roundToSingleDecimal(totalRebounds),
    assists: roundToSingleDecimal(assists),
    steals: roundToSingleDecimal(steals),
    blocks: roundToSingleDecimal(blocks),
    plusMinus: roundToSingleDecimal(plusMinus),
    tsp: roundToSingleDecimal(tsp),
    fgm: roundToSingleDecimal(fgm),
    fga: roundToSingleDecimal(fga),
    fgp: roundToSingleDecimal(fgp),
    tpa: roundToSingleDecimal(tpa),
    tpm: roundToSingleDecimal(tpm),
    tpp: roundToSingleDecimal(tpp),
    ftm: roundToSingleDecimal(ftm),
    fta: roundToSingleDecimal(fta),
    ftp: roundToSingleDecimal(ftp),
    offReb: roundToSingleDecimal(offensiveReb),
    defReb: roundToSingleDecimal(defensiveReb),
    turnovers: roundToSingleDecimal(turnovers),
    personalFouls: roundToSingleDecimal(personalFouls),
  };
}
