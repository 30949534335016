import ReactSelect, { OnChangeValue } from 'react-select';
import cn from 'classnames';
import { FC, useCallback, memo, useMemo, useState, useRef, SyntheticEvent } from 'react';

import { IHookFormInput } from 'components/ui/form-fields/hook-form-input.interface';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { IPostTag } from 'stores/posts/interfaces/post-tag.interface';
import { useResponsive } from 'hooks/use-responsive';
import { MIN_TABLET_WIDTH } from 'configs/responsive.configs';
import { CheckboxSelectDropdownIndicator as DropdownIndicator } from './checkbox-select-dropdown-indicator/checkbox-select-dropdown-indicator.component';
import {
  MULTI_VALUE_REMOVE_ID,
  CheckboxSelectMultiValueRemove as MultiValueRemove,
} from './checkbox-select-multi-value-remove/checkbox-select-multi-value-remove.component';
import { CheckboxSelectValueContainer as ValueContainer } from './checkbox-select-value-container/checkbox-select-value-container.component';
import { CheckboxSelectMultiValue as MultiValue } from './checkbox-select-multi-value/checkbox-select-multi-value.component';
import { CheckboxSelectMultiValueContainer as MultiValueContainer } from './checkbox-select-multi-value-container/checkbox-select-multi-value-container.component';
import { CheckboxSelectMenu as Menu } from './checkbox-select-menu/checkbox-select-menu.component';
import { ICheckboxItem } from '../multiple-choose-checkboxes/multiple-choose-checkboxes.component';

import styles from './checkbox-select.module.less';

export interface ICheckboxSelectProps extends IHookFormInput<string[]> {
  label: string;
  hasInnerLabel?: boolean;
  maxOptions?: number;
  options: IPostTag[];
  withActionButtons?: boolean;
  onApplyClick?: (value: string[]) => void;
}

const ALL_OPTION = { id: '0', selected: true, value: 'all', label: 'Show All' };

const CHECKBOX_SELECT_MENU_CLASS_NAME = 'CheckboxSelect__menu';

const ClearIndicator = () => null;

export const CheckboxSelect: FC<ICheckboxSelectProps> = memo((props: ICheckboxSelectProps) => {
  const {
    options,
    id,
    name,
    disabled = false,
    placeholder,
    value,
    label,
    hasInnerLabel = false,
    onChange,
    withActionButtons = false,
    maxOptions,
    onApplyClick,
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [isTabletPlus] = useResponsive([MIN_TABLET_WIDTH]);

  const wrapperRef = useRef<Maybe<HTMLDivElement>>(null);

  useOnClickOutside(wrapperRef, () => setMenuIsOpen(false));

  const handleApplyClick = useCallback(
    (filters: string[]) => {
      setMenuIsOpen(false);

      if (onApplyClick) {
        onApplyClick(filters);
      }
    },
    [onApplyClick],
  );

  const handleSelectClick = useCallback((event: SyntheticEvent) => {
    if (event.target instanceof HTMLElement && event.target.closest(`#${MULTI_VALUE_REMOVE_ID}`)) {
      setMenuIsOpen(true);
      return;
    }

    if (
      event.target instanceof HTMLElement &&
      !event.target.closest(`.${CHECKBOX_SELECT_MENU_CLASS_NAME}`)
    ) {
      setMenuIsOpen((prev) => !prev);
    }
  }, []);

  const handleChange = useCallback(
    (selectedOptions: OnChangeValue<ICheckboxItem, true>) => {
      if (onChange && selectedOptions) {
        const newValue = selectedOptions.map((option) => option.value);
        onChange(newValue);
      }
    },
    [onChange],
  );

  const handleMock = useCallback(() => {}, []);

  const manualOptions = useMemo<ICheckboxItem[]>(() => {
    const filtered = options.filter((tag) => !tag.isAutotag);

    return filtered.map(({ uuid, name: tagName }) => ({
      id: uuid,
      label: tagName,
      value: uuid,
      selected: !!value?.includes(uuid),
    }));
  }, [options, value]);

  const autoOptions = useMemo<ICheckboxItem[]>(() => {
    const filtered = options.filter((tag) => tag.isAutotag);

    return filtered.map(({ uuid, name: tagName }) => ({
      id: uuid,
      label: tagName,
      value: uuid,
      selected: !!value?.includes(uuid),
    }));
  }, [options, value]);

  const selectOptions = useMemo<ICheckboxItem[]>(
    () => [ALL_OPTION, ...manualOptions, ...autoOptions],
    [autoOptions, manualOptions],
  );

  const currentManualOptions = useMemo<Maybe<ICheckboxItem[]>>(
    () => manualOptions.filter(({ value: newValue }) => value?.includes(newValue)),
    [manualOptions, value],
  );

  const currentAutoOptions = useMemo<Maybe<ICheckboxItem[]>>(
    () => autoOptions.filter(({ value: newValue }) => value?.includes(newValue)),
    [autoOptions, value],
  );

  const currentOptions = useMemo(() => {
    if (
      currentManualOptions?.length === manualOptions.length &&
      currentAutoOptions?.length === autoOptions.length &&
      isTabletPlus
    ) {
      return [ALL_OPTION];
    }

    return currentManualOptions && currentAutoOptions
      ? [...currentManualOptions, ...currentAutoOptions]
      : null;
  }, [
    autoOptions.length,
    currentAutoOptions,
    currentManualOptions,
    isTabletPlus,
    manualOptions.length,
  ]);

  const optionsCount = useMemo(() => {
    if (currentOptions) {
      if (!isTabletPlus) {
        // on mobile we display only the number of selected options
        return currentOptions.length;
      }

      // on tablet and desktop we display one selected option and the number of remaining options
      return currentOptions.length - 1;
    }

    return 0;
  }, [currentOptions, isTabletPlus]);

  const isAllSelected = useMemo(() => {
    if (currentAutoOptions && currentManualOptions) {
      return (
        currentManualOptions.length === manualOptions.length &&
        currentAutoOptions.length === autoOptions.length
      );
    }

    return false;
  }, [autoOptions.length, currentAutoOptions, currentManualOptions, manualOptions.length]);

  const selectPlaceholder = useMemo(() => {
    if (placeholder) {
      return placeholder;
    }

    if (!isTabletPlus) {
      return null;
    }

    return 'Show All';
  }, [isTabletPlus, placeholder]);

  const selectClassNames = useMemo(
    () =>
      cn({
        'CheckboxSelect--inner-label': hasInnerLabel,
        'CheckboxSelect--with-count': optionsCount,
        'CheckboxSelect--all-value': isAllSelected,
        'CheckboxSelect--all-placeholder': !placeholder,
      }),
    [hasInnerLabel, isAllSelected, placeholder, optionsCount],
  );

  const labelClassNames = useMemo(
    () =>
      cn(styles.Label, {
        [styles['Label--inner']]: hasInnerLabel,
      }),
    [hasInnerLabel],
  );

  return (
    <div
      ref={wrapperRef}
      className={styles.CheckboxSelectWrapper}
      role="button"
      aria-label="Select"
      tabIndex={0}
      onClick={handleSelectClick}
      onKeyDown={handleMock}
      onTouchStart={handleSelectClick}
    >
      <div className={labelClassNames}>{label}</div>
      <ReactSelect<ICheckboxItem, true>
        unstyled
        id={id}
        name={name}
        isSearchable={false}
        options={selectOptions}
        placeholder={selectPlaceholder}
        isDisabled={disabled}
        value={currentOptions}
        onChange={handleChange}
        isMulti
        menuIsOpen={menuIsOpen}
        menuPlacement="auto"
        menuShouldScrollIntoView={false}
        components={{
          DropdownIndicator,
          Menu,
          MultiValueRemove,
          MultiValue,
          MultiValueContainer,
          ValueContainer,
          ClearIndicator,
        }}
        className={selectClassNames}
        classNamePrefix="CheckboxSelect"
        // /TS ignore is used to pass custom props to custom components, according to this example from react-select documentation https://codesandbox.io/s/xxl6n9?module=/example.tsx
        // @ts-ignore
        optionsCount={optionsCount}
        manualOptions={manualOptions}
        autoOptions={autoOptions}
        maxOptions={maxOptions}
        onApplyClick={handleApplyClick}
        withActionButtons={withActionButtons}
      />
    </div>
  );
});
