import { inject, injectable } from 'inversify';

import {
  IGameResponse,
  IPlayerInGameStatistics,
} from 'services/game/interfaces/game-response.interface';
import {
  GAME_ID_API_PARAM,
  PLAYER_SLUG_API_PARAM,
  TEAM_ID_API_PARAM,
} from 'services/http/consts/api-endpoint-params.constants';
import {
  GAME_STATISTICS,
  PLAYER_PERFORMANCE,
  SHARE_GAME,
  SHARE_PLAYER_PERFORMANCE,
  TEAMS_BOX_SCORES,
} from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { IPlayerPerformanceResponse } from 'stores/player-performance/interfaces/player-performance.interface';
import { IShareGame } from 'stores/share/interfaces/share-data.interface';
import { ISharePlayerPerformanceResponse } from 'stores/share/interfaces/share-response.interface';

import { TYPES } from 'configs/di-types.config';

@injectable()
export class GameService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchTeamBoxScores(teamId: number): Promise<IResponse<IPlayerInGameStatistics[]>> {
    return this.httpService.request<IPlayerInGameStatistics[]>({
      method: 'GET',
      url: TEAMS_BOX_SCORES,
      routeParams: { [TEAM_ID_API_PARAM]: teamId.toString() },
    });
  }

  public fetchGameDetails(gameId: number): Promise<IResponse<IGameResponse>> {
    return this.httpService.request<IGameResponse>({
      method: 'GET',
      url: GAME_STATISTICS,
      routeParams: { [GAME_ID_API_PARAM]: String(gameId) },
    });
  }

  public shareGame(gameId: number): Promise<IResponse<IShareGame>> {
    return this.httpService.request<IShareGame>({
      method: 'POST',
      url: SHARE_GAME,
      routeParams: { [GAME_ID_API_PARAM]: String(gameId) },
    });
  }

  public sharePlayerPerformance(
    gameId: string,
    playerSlug: string,
  ): Promise<IResponse<ISharePlayerPerformanceResponse>> {
    return this.httpService.request<ISharePlayerPerformanceResponse>({
      method: 'POST',
      url: SHARE_PLAYER_PERFORMANCE,
      routeParams: { [GAME_ID_API_PARAM]: String(gameId), [PLAYER_SLUG_API_PARAM]: playerSlug },
    });
  }

  public fetchPlayerPerformanceInGame(
    gameId: string,
    playerSlug: string,
  ): Promise<IResponse<IPlayerPerformanceResponse>> {
    return this.httpService.request<IPlayerPerformanceResponse>({
      method: 'GET',
      url: PLAYER_PERFORMANCE,
      routeParams: { [GAME_ID_API_PARAM]: gameId, [PLAYER_SLUG_API_PARAM]: playerSlug },
    });
  }
}
