import { memo, useCallback } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './stats-first-cell.module.less';

interface IStatsTeamCellProps extends ICellRendererParams {
  onTeamClick: (teamId: string) => void;
}

export const StatsTeamCell = memo((props: IStatsTeamCellProps) => {
  const { value, onTeamClick } = props;

  const handleTeamClick = useCallback(() => {
    if (value?.id) {
      onTeamClick(value.id);
    }
  }, [onTeamClick, value?.id]);

  if (!value) {
    return null;
  }

  return (
    <button className={styles.StatsCell} onClick={handleTeamClick}>
      <Avatar size={AvatarSize.XS} username={value.nickname} src={value.smallLogoUrl} />
      <div className={styles.StatsCell__Name}>{value.nickname}</div>
    </button>
  );
});
