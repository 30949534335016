import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Device } from '@capacitor/device';
import { TPlatformName } from 'types/platform-name.type';

import { useRetrieveSafeAreas } from 'hooks/use-retrieve-safe-areas';

import { IReaction } from 'components/posts/post-card/interfaces/reactions.interface';
import { ReactionDirectionType } from 'components/reactions/reactions/reactions.component';
import { ReactionsList } from 'components/reactions/reactions-list/reactions-list.component';

import { BaseIonBottomSheet } from '../base-ion-bottom-sheet/base-ion-bottom-sheet.component';

import styles from './reactions-ion-bottom-sheet.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

interface IReactionsIonBottomSheetProps {
  visible: boolean;
  reactions: {
    positive?: IReaction[];
    negative?: IReaction[];
  };
  onClose: () => void;
  onVoteClick: (reaction: string, type: ReactionDirectionType) => void;
}

export const ReactionsIonBottomSheet = memo((props: IReactionsIonBottomSheetProps) => {
  const { visible, reactions } = props;

  const [platformName, setPlatformName] = useState<Maybe<TPlatformName>>(null);

  const safeAreaSizes = useRetrieveSafeAreas();

  const bottomSheetRef = useRef<Maybe<HTMLIonModalElement>>(null);

  const getPlatformName = useCallback(async () => {
    const deviceInfo = await Device.getInfo();

    setPlatformName(deviceInfo.platform);
  }, []);

  const handleBottomSheetClose = useCallback(() => {
    bottomSheetRef.current?.dismiss();
  }, []);

  useEffect(() => {
    getPlatformName();
  }, [getPlatformName]);

  if (!safeAreaSizes || !platformName) {
    return null;
  }

  return (
    <BaseIonBottomSheet
      ref={bottomSheetRef}
      visible={visible}
      initialBreakpoint={INITIAL_BREAKPOINT}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      safeAreaBottom={safeAreaSizes.bottom}
      isAutoHeight
      onClose={props.onClose}
    >
      <div className={styles.ReactionsIonBottomSheetWrapper}>
        <ReactionsList
          reactions={reactions}
          onVoteClick={props.onVoteClick}
          onClosePopupReactions={handleBottomSheetClose}
        />
      </div>
    </BaseIonBottomSheet>
  );
});
