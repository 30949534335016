import { FC, useCallback, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';

import { FeedTypes } from 'stores/feed-filters/enums/feed-types.enum';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';

import { FeedTabsContainer } from 'containers/feed-tabs/feed-tabs-container';
import { TeamContainer } from 'containers/team/team.container';
import { TeamHeaderContainer } from 'containers/team/team-header/team-header.container';
import { WelcomePopupTeamContainer } from 'containers/welcome-popup/welcome-popup-team.container';

import { TYPES } from 'configs/di-types.config';
import * as paths from 'routes/paths.constants';

import { useLayoutEntity } from 'hooks/use-layout-entity';

export const TeamRoute: FC = () => {
  const feedFiltersStore = useInjection<FeedFiltersStore>(TYPES.FeedFiltersStore);

  const params = useParams<{ [paths.TEAM_ID_PARAM]: string; [paths.POST_ID_PARAM]: string }>();

  const { postId } = params;

  const renderHeader = useCallback(() => {
    if (!postId) {
      return <TeamHeaderContainer />;
    }

    return null;
  }, [postId]);

  useLayoutEntity({
    type: LayoutEntity.HeaderCenter,
    value: renderHeader,
  });

  useEffect(() => {
    feedFiltersStore.setActiveFeed(FeedTypes.Team);
  }, [feedFiltersStore]);

  return (
    <>
      <WelcomePopupTeamContainer />
      <TeamContainer />
      <FeedTabsContainer />
      <Outlet />
    </>
  );
};
