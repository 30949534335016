import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useInjection } from 'inversify-react';

import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

export const useTeamClick = (teamPath = paths.TEAM_PROFILE) => {
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);

  const navigate = useNavigate();

  const handleTeamClick = useCallback(
    async (teamId: number) => {
      if (teamsStore.fetching) {
        return;
      }

      if (teamId !== teamsStore.teamId) {
        teamsStatsStore.setTeam(null);
        teamsStatsStore.setTeamsStats(null);
      }

      navigate(
        getPath(teamPath, {
          [paths.TEAM_ID_PARAM]: String(teamId),
        }),
      );
    },
    [teamPath, teamsStore, teamsStatsStore, navigate],
  );

  return handleTeamClick;
};
