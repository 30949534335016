import {
  ChangeEvent,
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Browser } from '@capacitor/browser';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import cn from 'classnames';
import {
  $getSelection,
  $isRangeSelection,
  GridSelection,
  LexicalEditor,
  NodeSelection,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';

import { useMainProvider } from 'hooks/use-main-provider';

import { getSelectedNode } from 'components/editor/utils/get-selected-node';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './floating-link-editor.module.less';

const LOW_PRIORITY = 1;
const FOCUS_DELAY = 100;

interface IFloatingLinkEditorProps {
  editorProp: LexicalEditor;
}

export const FloatingLinkEditor = memo((props: IFloatingLinkEditorProps) => {
  const { editorProp } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const mouseDownRef = useRef(false);

  const [linkUrl, setLinkUrl] = useState('');
  const [isEditMode, setIsEditMode] = useState(true);
  const [lastSelection, setLastSelection] =
    useState<Maybe<RangeSelection | NodeSelection | GridSelection>>();

  const [visible, setVisible] = useState(false);

  const { isNativeApp } = useMainProvider();

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }

    const nativeSelection = window.getSelection();

    const { activeElement } = document;

    const rootElement = editorProp?.getRootElement();

    if (
      selection !== null &&
      !nativeSelection?.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection?.anchorNode || null)
    ) {
      if (!mouseDownRef.current) {
        setVisible(true);
      }

      setLastSelection(selection);

      return;
    }

    if (!activeElement) {
      setVisible(false);
      setLastSelection(null);
      setIsEditMode(false);
      setLinkUrl('');
    }
  }, [editorProp]);

  useEffect(() => {
    return mergeRegister(
      editorProp?.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editorProp?.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LOW_PRIORITY,
      ),
    );
  }, [editorProp, updateLinkEditor]);

  useEffect(() => {
    editorProp.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editorProp, updateLinkEditor]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isEditMode) {
      timeout = setTimeout(() => {
        inputRef.current?.focus();
      }, FOCUS_DELAY);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isEditMode]);

  const handleEditClick = useCallback(() => {
    setIsEditMode(true);
  }, [setIsEditMode]);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLinkUrl(event.target.value);
    },
    [setLinkUrl],
  );

  const handleOnSave = useCallback(() => {
    if (lastSelection !== null) {
      if (linkUrl !== '') {
        editorProp?.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
      }
      setIsEditMode(false);
    }
  }, [editorProp, setIsEditMode, lastSelection, linkUrl]);

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        if (lastSelection !== null) {
          if (linkUrl !== '') {
            editorProp?.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
          }
          setIsEditMode(false);
        }

        return;
      }

      if (event.key === 'Escape') {
        event.preventDefault();
        setIsEditMode(false);
      }
    },
    [editorProp, lastSelection, linkUrl],
  );

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      if (isNativeApp) {
        event.preventDefault();

        Browser.open({ url: linkUrl });
      }
    },
    [isNativeApp, linkUrl],
  );

  const floatingLinkClasses = useMemo(
    () =>
      cn(styles.FloatingLink, {
        [styles['FloatingLink--visible']]: visible,
      }),
    [visible],
  );

  return (
    <div className={floatingLinkClasses}>
      {isEditMode ? (
        <div className={styles.FloatingLink__InputWrapper}>
          <input
            ref={inputRef}
            className={styles.FloatingLink__LinkInput}
            value={linkUrl}
            placeholder="type url here"
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
          />
          <div className={styles.FloatingLink__Button}>
            <Button size={ButtonSize.Big} theme={ButtonTheme.Text} onClick={handleOnSave}>
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.FloatingLink__LinkWrapper}>
          <a href={linkUrl} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
            {linkUrl}
          </a>
          <div className={styles.FloatingLink__Button}>
            <Button size={ButtonSize.Big} theme={ButtonTheme.Text} onClick={handleEditClick}>
              Edit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
});
