import { FC } from 'react';

import { GlobalSearchContainer } from 'containers/global-search/global-search.container';
import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';

import { useMobileLayoutStructure } from 'hooks/use-mobile-layout-structure';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const SearchRoute: FC = () => {
  useMobileLayoutStructure({
    isHiddenBottomBar: true,
    isHiddenHeader: true,
  });

  return (
    <PageWrapperContainer>
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <GlobalSearchContainer />
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
