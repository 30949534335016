import { useCallback, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScoreboardStore } from 'stores/scoreboard/scoreboard.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';

import { WeekBar } from 'components/ui/week-bar/week-bar.component';

export const WeekBarContainer = observer(() => {
  const scoreboardStore = useInjection<ScoreboardStore>(TYPES.ScoreboardStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);

  const activeDate = useMemo<Maybe<Date>>(() => {
    if (!scoreboardStore.selectedDates.length) {
      return null;
    }

    return new Date(scoreboardStore.selectedDates[0]);
  }, [scoreboardStore.selectedDates]);

  const handleDateClick = useCallback(
    (date: Maybe<Date>) => {
      if (date) {
        scoreboardStore.setSelectedDate(date.toISOString());
      }
    },
    [scoreboardStore],
  );

  if (!activeDate || scoreboardStore.isFetchingCurrentDate) {
    return null;
  }

  return (
    <WeekBar
      disabled={scoreboardStore.fetching || teamsStatsStore.fetching}
      activeDate={activeDate}
      onDateClick={handleDateClick}
    />
  );
});
