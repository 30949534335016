import { FC, useMemo } from 'react';

import { IGameWithStats } from 'stores/game/interfaces/game.interface';

import {
  BaseModalComponent,
  IModal,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import { ShareGameScore } from './share-game-score/share-game-score.component';

import styles from './share-game-modal.module.less';

export interface IShareGameModalProps extends IModal {
  game: IGameWithStats;
  onCopyLink: () => void;
}

export const ShareGameModal: FC<IShareGameModalProps> = (props: IShareGameModalProps) => {
  const { game, visible } = props;

  const homeIsWinning = useMemo(
    () => Number(game.scores.total[0]) > Number(game.scores.total[1]),
    [game.scores.total],
  );

  const isDraw = useMemo(() => game.scores.total[0] === game.scores.total[1], [game.scores.total]);

  return (
    <BaseModalComponent
      headerButton={{
        icon: IconFontName.Link,
        text: 'Copy Link',
        onClick: props.onCopyLink,
        tooltip: {
          eventType: TooltipEventType.click,
          placement: 'top',
          text: 'Link Copied',
          disappearTime: 500,
        },
      }}
      size={ModalWindowSize.M}
      visible={visible}
      closeOnOverlayClick
      title="Share"
      onClose={props.onClose}
    >
      <div className={styles.ModalContentWrapper}>
        <div className={styles.ModalContent}>
          <ShareGameScore game={game} homeIsWinning={homeIsWinning} isDraw={isDraw} />
        </div>
      </div>
    </BaseModalComponent>
  );
};
