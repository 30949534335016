import { IPlayerLeaderResponse } from 'services/player/interfaces/player-leaders.interface';

import { convertColorToRGB } from 'helpers/rgb-to-rgba.utils';
import { roundToSingleDecimal } from 'helpers/round-to-single-decimal.util';

import { IColumnChartItem } from 'components/ui/column-charts/column-charts.component';

type PlayerLeaderBy = Pick<
  IPlayerLeaderResponse,
  'blocks' | 'steals' | 'tpm' | 'assists' | 'points' | 'tot_reb'
>;

export function playerLeaderAdapter(
  leadersResponse: IPlayerLeaderResponse[],
  leaderBy: keyof PlayerLeaderBy,
): IColumnChartItem[] {
  return leadersResponse.map((leader) => {
    const { small_logo_url: smallLogoUrl, color, firstname, lastname, slug } = leader;

    return {
      avatarUrl: smallLogoUrl,
      color: convertColorToRGB(color),
      name: `${firstname} ${lastname}`,
      id: slug,
      value: roundToSingleDecimal(leader[leaderBy]),
    };
  });
}
