import { useCallback, useEffect, useMemo, useState } from 'react';
import { json, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { BookmarksStore } from 'stores/bookmarks/bookmarks.store';
import { FeedFiltersStore } from 'stores/feed-filters/feed-filters.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { MetaTagsStore } from 'stores/meta-tags/meta-tags.store';
import { PlayerStore } from 'stores/player/player.store';
import {
  IFormattedReactions,
  IInteractiveMediaAttachment,
} from 'stores/posts/interfaces/post.interface';
import { SortEnum } from 'stores/posts/posts.config';
import { PostsStore } from 'stores/posts/posts.store';
import { SharedType } from 'stores/share/enums/share-type.enum';
import { ShareStore } from 'stores/share/share.store';
import { TeamsStore } from 'stores/teams/teams.store';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TopLevelCommentsContainer } from 'containers/top-level-comments/top-level-comments.container';

import { SCROLL_TOP_ELEMENT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { DEFAULT_SHARE_TITLE } from 'configs/share.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';
import { useNativeShare } from 'hooks/use-native-share';
import { useTeamClick } from 'hooks/use-team-click';

import { BarActionClick } from 'components/bars/bar-action.type';
import { IAuthor, ShareModal } from 'components/modals/share-modal/share-modal.component';
import { PostCardFeedsTheme } from 'components/posts/post-card/post-card-feeds/post-card-feeds.component';
import {
  ISharePublicationData,
  PostDetailed,
} from 'components/posts/post-detailed/post-detailed.component';
import { ReactionDirectionType } from 'components/reactions/reactions/reactions.component';
import { Loader } from 'components/ui/loader/loader.component';
import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';
import { ITab } from 'components/ui/tabs/tabs.component';

import styles from './post-detailed.module.less';

export type CommentsTabsType = ITab<BarActionClick<SortEnum>>[];

export const PostDetailedContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);
  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const bookmarksStore = useInjection<BookmarksStore>(TYPES.BookmarksStore);
  const shareStore = useInjection<ShareStore>(TYPES.ShareStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const feedFiltersStore = useInjection<FeedFiltersStore>(TYPES.FeedFiltersStore);
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
  const metaTagsStore = useInjection<MetaTagsStore>(TYPES.MetaTagsService);
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);

  const params = useParams<{
    [paths.TEAM_ID_PARAM]: string;
    [paths.POST_ID_PARAM]: string;
    [paths.PLAYER_SLUG_PARAM]: string;
  }>();

  const { teamId, postId, playerSlug } = params;

  const navigate = useNavigate();
  const location = useLocation();

  const { isNativeApp } = useMainProvider();
  const { shareByNativeModal } = useNativeShare();

  const [sharePublicationData, setSharePublicationData] =
    useState<Maybe<ISharePublicationData>>(null);

  const getTeamId = useMemo(() => {
    if (teamId) {
      return Number(teamId);
    }

    return null;
  }, [teamId]);

  const playerId = useMemo(() => {
    if (playerStore.playerDetails) {
      return playerStore.playerDetails.id;
    }

    return null;
  }, [playerStore.playerDetails]);

  const fetchPost = useCallback(async () => {
    if (postId) {
      await postsStore.fetchRegularPostById({
        teamId: getTeamId || null,
        playerId,
        postId,
      });
    }
  }, [postId, postsStore, getTeamId, playerId]);

  useEffect(() => {
    const topElement = document.getElementById(SCROLL_TOP_ELEMENT);

    topElement?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (layoutStore.isPulledRefresher) {
      fetchPost();
    }
  }, [layoutStore.isPulledRefresher, fetchPost]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const hasUserID = queryParams.has(paths.USER_ID_PARAM);
    const hasUsername = queryParams.has(paths.USERNAME_PARAM);
    const hasSharedType = queryParams.has(paths.TYPE_PARAM);

    const userID = queryParams.get(paths.USER_ID_PARAM);
    const username = queryParams.get(paths.USERNAME_PARAM);
    const sharedType = queryParams.get(paths.TYPE_PARAM) as Maybe<SharedType>;

    if (hasUserID && hasUsername && hasSharedType) {
      authStore.sendGoogleAnalyticFollowedLink(sharedType, username, userID);
    }
  }, [authStore, location]);

  const handleUpdatePostCommentsCount = useCallback(
    (count: number) => {
      if (postsStore.post) {
        const newPost = {
          ...postsStore.post,
          commentsAmount: count,
        };

        postsStore.setPost(newPost);
      }
    },
    [postsStore],
  );

  const handleTogglePostToBookmark = useCallback(
    async (currentPostId: string) => {
      if (!authStore.triggerAuthorisationCheck()) return;

      if (playerId) {
        await postsStore.togglePlayerPostBookmark({
          postId: currentPostId,
          playerId,
        });
      } else if (getTeamId) {
        await postsStore.toggleTeamPostBookmark({
          postId: currentPostId,
          teamId: getTeamId,
        });
      } else {
        await postsStore.togglePostBookmark({ id: currentPostId });
      }

      await fetchPost();
      await bookmarksStore.refresh();
    },
    [getTeamId, playerId, authStore, postsStore, bookmarksStore, fetchPost],
  );

  const handleCopySharedLink = useCallback(() => {
    shareStore.copyLink();
  }, [shareStore]);

  const handleShowShareModal = useCallback(
    async (comment?: ISharePublicationData) => {
      if (postId) {
        await shareStore.fetchSharePublicationData(
          postId,
          comment?.uuid,
          getTeamId,
          playerStore.playerDetails?.id,
        );
      }

      if (postsStore.post && shareStore.shareData?.type === SharedType.Post) {
        postsStore.setPost({ ...postsStore.post, sharesCount: shareStore.shareData.count });
      }

      if (
        isNativeApp &&
        (shareStore.shareData?.type === SharedType.Post ||
          shareStore.shareData?.type === SharedType.Comment)
      ) {
        shareByNativeModal(
          shareStore.shareData.link,
          shareStore.shareData.title || metaTagsStore.metaTags?.title || DEFAULT_SHARE_TITLE,
        );
      } else {
        if (comment) {
          setSharePublicationData(comment);
        } else if (postsStore.post) {
          setSharePublicationData({
            user: {
              username: postsStore.post.user.username,
              name: postsStore.post.user.name,
              smallAvatarUrl: postsStore.post.user.smallAvatarUrl,
            },
            formattedDates: postsStore.post.formattedDates,
          });
        }
        shareStore.setIsShareModalVisible(true);
      }
    },
    [
      isNativeApp,
      getTeamId,
      postId,
      shareStore,
      postsStore,
      metaTagsStore.metaTags,
      playerStore.playerDetails,
      shareByNativeModal,
    ],
  );

  const handleResetSharing = useCallback(() => {
    shareStore.reset();
    setSharePublicationData(null);
  }, [shareStore]);

  const preventFiltersUpdate = useCallback(() => {
    feedFiltersStore.setShouldUpdateFilterKey(false);
  }, [feedFiltersStore]);

  useEffect(() => {
    if (playerSlug) {
      if (playerId) {
        fetchPost();
      }
    } else {
      fetchPost();
    }
  }, [playerSlug, playerId, authStore.isAuthorised, fetchPost]);

  useEffect(() => {
    return () => {
      postsStore.setPost(null);
    };
  }, [postsStore]);

  useEffect(() => {
    if (postsStore.isIdInvalid) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw json('Not Found', { status: 404 });
    }
  }, [postsStore.isIdInvalid]);

  useEffect(() => {
    if (postId) {
      postsStore.setCurrentPostId(postId);
      preventFiltersUpdate();
    }
  }, [preventFiltersUpdate, postId, postsStore]);

  const updatePostWithNewReactions = useCallback(
    (reactions: Maybe<IFormattedReactions>) => {
      if (reactions && postsStore.post) {
        const newPost = {
          ...postsStore.post,
          ...reactions,
        };

        postsStore.setPost(newPost);
      }
    },
    [postsStore],
  );

  const handleVotePost = useCallback(
    async (
      postIdParam: string,
      reaction: string,
      type: ReactionDirectionType,
      teamIdParam?: number,
      playerIdParam?: number,
    ) => {
      if (!authStore.triggerAuthorisationCheck()) return;

      if (playerIdParam) {
        if (type === 'up') {
          const reactions = await postsStore.playerPostVoteUp({
            postId: postIdParam,
            reaction,
            playerId: playerIdParam,
          });

          updatePostWithNewReactions(reactions);
        }

        if (type === 'down') {
          const reactions = await postsStore.playerPostVoteDown({
            postId: postIdParam,
            reaction,
            playerId: playerIdParam,
          });

          updatePostWithNewReactions(reactions);
        }

        return;
      }

      if (teamIdParam) {
        if (teamsStore.isFollowedTeam) {
          if (type === 'up') {
            const reactions = await postsStore.teamPostVoteUp({
              postId: postIdParam,
              reaction,
              teamId: teamIdParam,
            });

            updatePostWithNewReactions(reactions);
          }

          if (type === 'down') {
            const reactions = await postsStore.teamPostVoteDown({
              postId: postIdParam,
              reaction,
              teamId: teamIdParam,
            });

            updatePostWithNewReactions(reactions);
          }
        } else {
          teamsStore.setJoinModalVisible(true);
        }

        return;
      }

      if (type === 'up') {
        const reactions = await postsStore.postVoteUp({
          postId: postIdParam,
          reaction,
        });

        updatePostWithNewReactions(reactions);
      }

      if (type === 'down') {
        const reactions = await postsStore.postVoteDown({
          postId: postIdParam,
          reaction,
        });

        updatePostWithNewReactions(reactions);
      }
    },
    [authStore, postsStore, updatePostWithNewReactions, teamsStore],
  );

  const navigateToFeed = useCallback(
    (teamIdParam?: Maybe<number>) => {
      if (teamIdParam) {
        navigate(
          getPath(paths.TEAM, {
            [paths.TEAM_ID_PARAM]: teamIdParam.toString(),
          }),
        );
      } else {
        navigate(getPath(paths.HOME_FEED));
      }

      feedFiltersStore.setShouldUpdateFilterKey(true);
    },
    [feedFiltersStore, navigate],
  );

  const handleDeletePost = useCallback(
    async (id: string) => {
      if (!authStore.triggerAuthorisationCheck()) return;

      const isDeleteSuccess = await postsStore.deletePost({ postId: id, teamId: getTeamId });

      await bookmarksStore.refresh();

      if (isDeleteSuccess) {
        navigateToFeed(getTeamId);
      }
    },
    [getTeamId, authStore, bookmarksStore, postsStore, navigateToFeed],
  );

  const handleOpenPreview = useCallback(
    (author: IAuthor, slides: IInteractiveMediaAttachment[], date: string, index: number) => {
      const watermarkEntity = teamsStatsStore.team
        ? { name: teamsStatsStore.team.nickname, logoUrl: teamsStatsStore.team.mediumLogoUrl }
        : null;

      galleryStore.setGalleryAttachments(slides);
      galleryStore.setCurrentAttachmentId(index);
      galleryStore.setAuthor(author);
      galleryStore.setAttachmentDate(date);
      galleryStore.setIsGalleryOpen(true);
      galleryStore.setWatermarkEntity(watermarkEntity);
    },
    [galleryStore, teamsStatsStore.team],
  );

  const handlePollExpired = useCallback(
    async (id: string) => {
      if (!authStore.triggerAuthorisationCheck()) {
        return;
      }

      await postsStore.fetchRegularPostById({
        postId: id,
        playerId,
        teamId: getTeamId,
      });

      await bookmarksStore.refresh();
    },
    [playerId, getTeamId, authStore, bookmarksStore, postsStore],
  );

  const handlePollVote = useCallback(
    async (pollData: IPollVoteData) => {
      if (!authStore.triggerAuthorisationCheck() || !postId) {
        if (teamId && !teamsStore.isFollowedTeam) {
          teamsStore.setJoinModalVisible(true);
        }

        return;
      }

      await postsStore.postPollVote(pollData);

      await postsStore.fetchRegularPostById({
        postId,
        playerId,
        teamId: teamsStore.teamId || null,
      });

      await bookmarksStore.refresh();
    },
    [postId, playerId, teamId, authStore, bookmarksStore, teamsStore, postsStore],
  );

  const getFavoriteTeam = useCallback(
    (teamIdParam: Maybe<number>): Maybe<ITeamsStats> => {
      return teamsStatsStore.findTeamById(teamIdParam);
    },
    [teamsStatsStore],
  );

  const getFavoritePlayer = useCallback(
    (playerIdParam: Maybe<number>): Maybe<IPlayerStats> => {
      return teamsStatsStore.findPlayerById(playerIdParam);
    },
    [teamsStatsStore],
  );

  const handleTeamClick = useTeamClick();

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(paths.PLAYER_PROFILE, { [paths.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  const handleRedirectToParentRoute = useCallback(
    (id: string) => {
      if (!postsStore.isSubmittedContentReport) {
        return;
      }

      postsStore.setSubmittedContentReport(false);
      postsStore.removePostFromEntries(id);

      if (location.key === 'default') {
        navigate(paths.HOME_FEED);
        return;
      }

      navigate(-1);
    },
    [location, navigate, postsStore],
  );

  const handleReportSubmit = useCallback(
    async (postIdParam: string, reasonId: number, teamIdParam?: number, playerIdParam?: number) => {
      let response = false;

      if (playerIdParam) {
        response = await postsStore.sendPlayerPostReport(playerIdParam, postIdParam, reasonId);
      }

      if (teamIdParam) {
        response = await postsStore.sendTeamPostReport(teamIdParam, postIdParam, reasonId);
      }

      if (!playerIdParam && !teamIdParam) {
        response = await postsStore.sendPostReport(postIdParam, reasonId);
      }

      return response;
    },
    [postsStore],
  );

  if (!postsStore.post) {
    return (
      <div className={styles.Loader}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    <div className={styles.PostDetailed}>
      <PostDetailed
        feedsTheme={PostCardFeedsTheme.List}
        userId={authStore?.userMe?.id || null}
        team={teamsStatsStore.team}
        post={postsStore.post}
        reportReasons={applicationStore.reportReasons}
        favoriteTeam={getFavoriteTeam(Number(postsStore.post.user.favoriteTeamId))}
        favoritePlayer={getFavoritePlayer(postsStore.post.user.favoritePlayerId)}
        onDeletePost={handleDeletePost}
        onTogglePostToBookmarks={handleTogglePostToBookmark}
        onShareClick={handleShowShareModal}
        onVotePost={handleVotePost}
        onOpenPreview={handleOpenPreview}
        onPollExpired={handlePollExpired}
        onPollVote={handlePollVote}
        onTeamClick={handleTeamClick}
        onPlayerClick={handlePlayerClick}
        onSendReport={handleReportSubmit}
        onCloseReportsPopup={handleRedirectToParentRoute}
        triggerAuthorisationCheck={authStore.triggerAuthorisationCheck}
      />
      <TopLevelCommentsContainer
        postId={postsStore.post.uuid}
        onUpdatePostCommentsCount={handleUpdatePostCommentsCount}
        onShareClick={handleShowShareModal}
      />
      {!isNativeApp &&
        sharePublicationData &&
        (shareStore.shareData?.type === SharedType.Post ||
          shareStore.shareData?.type === SharedType.Comment) && (
          <ShareModal
            id={postsStore.post.uuid}
            visible={shareStore.isShareModalVisible}
            author={sharePublicationData.user}
            shareContent={{
              pollOptions: shareStore.shareData.pollOptions,
              attachments: shareStore.shareData.attachmentCounts,
              dateCreated: sharePublicationData.formattedDates.relativeLong,
              content: shareStore.shareData.content,
              title: shareStore.shareData.title,
            }}
            onCopyLink={handleCopySharedLink}
            onClose={handleResetSharing}
          />
        )}
    </div>
  );
});
