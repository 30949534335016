import { useCallback } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { SeasonsStore } from 'stores/seasons/seasons.store';
import { StandingsStore } from 'stores/standings/standings.store';
import { isStandingsModeValue } from 'stores/standings/utils/is-standings-mode-value.util';
import { isStandingsTypeValue } from 'stores/standings/utils/is-standings-type-value.util';

import { TYPES } from 'configs/di-types.config';

import { StandingsFilters } from 'components/standings/standings-filters/standings-filters.component';

export const StandingsFiltersContainer = observer(() => {
  const standingsStore = useInjection<StandingsStore>(TYPES.StandingsStore);

  const seasonsStore = useInjection<SeasonsStore>(TYPES.SeasonsStore);

  const handleTypeChange = useCallback(
    (type: string) => {
      if (isStandingsTypeValue(type)) {
        standingsStore.setType(type);
      }
    },
    [standingsStore],
  );

  const handleSeasonIdChange = useCallback(
    (seasonId: string) => {
      standingsStore.setSeasonId(seasonId);
    },
    [standingsStore],
  );

  const handleModeChange = useCallback(
    (mode: string) => {
      if (isStandingsModeValue(mode)) {
        standingsStore.setMode(mode);
      }
    },
    [standingsStore],
  );

  const handleResetButtonClick = useCallback(() => {
    standingsStore.setSeasonId(seasonsStore.currentSeasonOption?.value || null);
  }, [seasonsStore, standingsStore]);

  if (!standingsStore.seasonId) {
    return null;
  }

  return (
    <StandingsFilters
      mode={standingsStore.mode}
      type={standingsStore.type}
      seasonId={standingsStore.seasonId}
      seasonsOptions={seasonsStore.seasonsSelectOptions}
      onModeChange={handleModeChange}
      onTypeChange={handleTypeChange}
      onSeasonIdChange={handleSeasonIdChange}
      onResetClick={handleResetButtonClick}
    />
  );
});
