import { FC } from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import styles from './custom-dropdown-indicator.module.less';

export const CustomDropdownIndicator: FC<DropdownIndicatorProps> = (
  props: DropdownIndicatorProps,
) => {
  const { selectProps } = props;
  return (
    <components.DropdownIndicator className={styles.Wrapper} {...props}>
      <IconButton
        iconName={selectProps?.menuIsOpen ? IconFontName.ChevronUp : IconFontName.ChevronDown}
        theme={IconButtonTheme.Transparent}
      />
    </components.DropdownIndicator>
  );
};
