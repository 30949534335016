import { memo } from 'react';

import { BarActionType } from 'components/bars/bar-action.type';
import { IItemLineGroup } from 'components/ui/item-line-list/interfaces/item-line-group.interface';
import { ItemLineTheme } from 'components/ui/item-line-list/item-line/item-line.component';
import { ItemLineList } from 'components/ui/item-line-list/item-line-list.component';

import styles from './report-list.module.less';

interface IReportListProps {
  reasons: IItemLineGroup[];
  onReportItemClick: (barAction: BarActionType) => void;
}

export const ReportList = memo((props: IReportListProps) => {
  const { reasons } = props;

  return (
    <div className={styles.ReportList}>
      <h4 className={styles.ReportList__Title}>Why are you reporting this?</h4>
      <ItemLineList
        items={reasons}
        theme={ItemLineTheme.Reports}
        onItemClick={props.onReportItemClick}
      />
    </div>
  );
});
