import { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { BarActionType } from 'components/bars/bar-action.type';

import { Tab, TabsSize } from './tab/tab.component';

import styles from './tabs.module.less';

export interface ITab<T extends BarActionType = BarActionType> {
  id: string;
  title: string;
  action: T;
}

export interface ITabsProps {
  isPulledRefresher?: boolean;
  size?: TabsSize;
  activeTab?: Maybe<string>;
  tabs: ITab[];
  rightCorner?: Maybe<ReactNode>;
  forProfile?: boolean;
  forGame?: boolean;
  fullWidth?: boolean;
  onClick: (action: BarActionType) => void;
}

export const Tabs: FC<ITabsProps> = memo((props: ITabsProps) => {
  const {
    forGame,
    isPulledRefresher,
    size = TabsSize.M,
    tabs,
    activeTab,
    rightCorner,
    forProfile = false,
    fullWidth = false,
    onClick,
  } = props;

  const handleTabClick = useCallback(
    (action: BarActionType) => () => {
      onClick(action);
    },
    [onClick],
  );

  const tabsClassNames = useMemo(
    () =>
      cn(styles.Tabs, {
        [styles['Tabs--for-profile']]: forProfile,
        [styles['Tabs--for-game']]: forGame,
        [styles['Tabs--size-m']]: size === TabsSize.M,
        [styles['Tabs--size-s']]: size === TabsSize.S,
        [styles['Tabs--freezed']]: isPulledRefresher,
      }),
    [forProfile, forGame, size, isPulledRefresher],
  );

  const menuClassNames = useMemo(
    () =>
      cn(styles.Tabs__Menu, {
        [styles['Tabs__Menu--full-width']]: fullWidth,
      }),
    [fullWidth],
  );

  return (
    <nav className={tabsClassNames}>
      <ul className={menuClassNames}>
        {tabs.map(({ id, title, action }) => (
          <Tab
            key={id}
            size={size}
            isActive={id === activeTab}
            onClick={handleTabClick(action)}
            title={title}
          />
        ))}
      </ul>
      {!!rightCorner && <div className={styles.Tabs__RightCorner}>{rightCorner}</div>}
    </nav>
  );
});
