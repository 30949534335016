import { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { ICellRendererParams } from 'ag-grid-community';
import cn from 'classnames';
import { formatDigitsToSerialNumber } from 'helpers/format/format-digit-to-serial-number';
import * as paths from 'routes/paths.constants';

import { StatsMode } from 'stores/global-stats/enums/global-stats.enums';

import styles from './team-rank.module.less';

interface IRankedStatsCellProps extends ICellRendererParams {
  maxUpRank: number;
  minDownRank: number;
  role: StatsMode;
}

export const RankedStatsCell = memo((props: IRankedStatsCellProps) => {
  const { rowIndex, column, value, maxUpRank, minDownRank, role } = props;

  const navigate = useNavigate();

  const handleRankClick = useCallback(() => {
    const sort = column?.getColId();
    const pathname =
      role === StatsMode.TEAMS ? paths.HOME_STATS_TEAMS_ALL : paths.HOME_STATS_PLAYERS_ALL;

    if (sort) {
      navigate({
        pathname,
        search: `${createSearchParams({ sort, direction: 'desc' })}`,
      });
    }
  }, [column, navigate, role]);

  const itemClassNames = useMemo(
    () =>
      cn(styles.Rank, {
        [styles['Rank--up']]: value <= maxUpRank,
        [styles['Rank--down']]: value >= minDownRank,
      }),
    [maxUpRank, minDownRank, value],
  );

  if (rowIndex === 1) {
    if (column?.getColId() === 'type' || column?.getColId() === 'gamesPlayed') {
      return value;
    }

    return (
      <button className={itemClassNames} onClick={handleRankClick}>
        {formatDigitsToSerialNumber(value)}
      </button>
    );
  }

  return value;
});
