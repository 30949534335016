import { useCallback, useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { AuthMode } from 'stores/auth/enums/auth-mode.enum';
import { ILoginByPasswordParams } from 'stores/auth/interfaces/login-by-password-params.interface';

import { TYPES } from 'configs/di-types.config';

import { Login } from 'components/auth/login/login.component';

export const LoginContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const handleLoginHeaderButtonClick = useCallback(() => {
    authStore.setAuthMode(AuthMode.SignUp);

    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.LoginSignUp,
      },
    });
  }, [authStore]);

  const handleForgotPasswordButtonClick = useCallback(() => {
    authStore.setAuthMode(AuthMode.ForgotPassword);

    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.LoginForgotPassword,
      },
    });
  }, [authStore]);

  const handleLoginSubmit = useCallback(
    (params: ILoginByPasswordParams) => {
      authStore.loginByPassword(params);

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.LoginSubmit,
        },
      });
    },
    [authStore],
  );

  const handleGoogleSignIn = useCallback(() => {
    authStore.googleSignIn();
  }, [authStore]);

  const handleAppleSignIn = useCallback(() => {
    authStore.appleSignIn();
  }, [authStore]);

  useEffect(() => {
    GoogleAnalyticService.event({
      eventName: 'screen_custom_view',
      eventParams: {
        screen_type: ScreenNamesEnum.Login,
      },
    });
  }, []);

  return (
    <Login
      onHeaderButtonClick={handleLoginHeaderButtonClick}
      onLoginByPasswordSubmit={handleLoginSubmit}
      onForgotPasswordButtonClick={handleForgotPasswordButtonClick}
      onAppleSignIn={handleAppleSignIn}
      onGoogleSignIn={handleGoogleSignIn}
    />
  );
});
