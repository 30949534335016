import { useCallback, useState } from 'react';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import { NotificationPermission } from 'components/notification-permission/notification-permission.component';

import styles from './notification-permission-bottom-sheet.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

interface INotificationPermissionBottomSheetProps {
  visible: boolean;
  onClose: () => void;
  onAllowNotifications: () => void;
}

export const NotificationPermissionBottomSheet = (
  props: INotificationPermissionBottomSheetProps,
) => {
  const { visible, onClose, onAllowNotifications } = props;

  const [canSheetDismiss, setCanSheetDismiss] = useState(false);

  const handleClose = useCallback(() => {
    setCanSheetDismiss(true);
    onClose();
  }, [onClose]);

  const handleAllow = useCallback(() => {
    setCanSheetDismiss(true);
    onAllowNotifications();
  }, [onAllowNotifications]);

  return (
    <BaseIonBottomSheet
      canDismiss={canSheetDismiss}
      visible={visible}
      initialBreakpoint={INITIAL_BREAKPOINT}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      safeAreaBottom={0}
      isAutoHeight
      onClose={props.onClose}
    >
      <div className={styles.NotificationPermissionBottomSheetWrapper}>
        <NotificationPermission onAllowNotifications={handleAllow} onClose={handleClose} />
      </div>
    </BaseIonBottomSheet>
  );
};
