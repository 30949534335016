import { inject, injectable } from 'inversify';

import { INearestDateResponse } from 'services/games-detailed/interfaces/game-detailed-response.interface';
import { GamesDetailedResponseType } from 'services/games-detailed/types/games-detailed-response.type';
import {
  GAMES_STATISTICS_DETAILED,
  NEAREST_DATE,
  TEAM_GAME_STATISTICS_DETAILED,
} from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { TYPES } from 'configs/di-types.config';

import { TEAM_ID_API_PARAM } from '../http/consts/api-endpoint-params.constants';

@injectable()
export class GamesDetailedService {
  private readonly httpService: HttpService;

  constructor(@inject<HttpService>(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public retrieve(
    dateStart: string,
    timezone: string,
    dateEnd?: string,
  ): Promise<IResponse<GamesDetailedResponseType>> {
    let paramsObj;
    if (dateEnd) {
      paramsObj = { date_start: dateStart, date_end: dateEnd, timezone };
    } else {
      paramsObj = { date_start: dateStart, timezone };
    }

    return this.httpService.request<GamesDetailedResponseType>({
      method: 'GET',
      url: GAMES_STATISTICS_DETAILED,
      params: new URLSearchParams(paramsObj),
    });
  }

  public retrieveForTeam(
    teamId: number,
    dateStart: string,
    timezone: string,
    dateEnd?: string,
  ): Promise<IResponse<GamesDetailedResponseType>> {
    let paramsObj;

    if (dateEnd) {
      paramsObj = { date_start: dateStart, date_end: dateEnd, timezone };
    } else {
      paramsObj = { date_start: dateStart, timezone };
    }

    return this.httpService.request<GamesDetailedResponseType>({
      method: 'GET',
      url: TEAM_GAME_STATISTICS_DETAILED,
      params: new URLSearchParams(paramsObj),
      routeParams: { [TEAM_ID_API_PARAM]: teamId.toString() },
    });
  }

  public fetchNearestDate(timezone: string): Promise<IResponse<INearestDateResponse>> {
    return this.httpService.request<INearestDateResponse>({
      method: 'GET',
      url: NEAREST_DATE,
      params: new URLSearchParams({ timezone }),
    });
  }
}
