import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { GameStore } from 'stores/game/game.store';
import { StatisticsStore } from 'stores/statistics/statistics.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { Statistics } from 'components/statistics/statistics.component';
import { StatisticsType } from 'components/statistics/statistics-type.enum';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './game-header.module.less';

export const GameHeaderContainer = observer(() => {
  const gameStore = useInjection<GameStore>(TYPES.GameStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const statisticsStore = useInjection<StatisticsStore>(TYPES.StatisticsStore);

  const navigate = useNavigate();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  useEffect(() => {
    return () => {
      gameStore.setGamesForArena([]);
    };
  }, [gameStore]);

  const handleBack = useCallback(() => {
    navigate(-1);

    if (teamsStatsStore.team?.id) {
      statisticsStore.retrieveForTeam();
    } else {
      statisticsStore.retrieve();
    }
  }, [statisticsStore, teamsStatsStore.team, navigate]);

  const handleGameClick = useCallback(
    (openGameId: number) => {
      navigate(
        getPath(paths.ARENA_GAME, {
          [paths.GAME_ID_PARAM]: String(openGameId),
        }),
        { replace: true },
      );
    },
    [navigate],
  );

  return (
    <div className={styles.GameDetailsTopHeader}>
      {!isDesktopPlus && (
        <div className={styles.GameDetailsTopHeader__BackButton}>
          <IconButton
            iconName={IconFontName.ChevronLeft}
            theme={IconButtonTheme.Transparent}
            onClick={handleBack}
          />
        </div>
      )}
      <div className={styles.GameDetailsTopHeader__Statistics}>
        {gameStore.isLoadingMiniSchedule ? (
          <div className={styles.GameDetailsTopHeader__Loader}>
            <Loader isShiftLeft={!isDesktopPlus} isShow isLocal />
          </div>
        ) : (
          <Statistics
            type={StatisticsType.Arena}
            games={gameStore.gamesForArena}
            selectedGameId={gameStore.entry?.id || null}
            firstGameIds={gameStore.arenaFirstGameIds}
            onGameClick={handleGameClick}
          />
        )}
      </div>
    </div>
  );
});
