import { useEffect } from 'react';

import { CodeGesture, GESTURES_MAP } from 'configs/gestures.config';

const GRID_SIZE = 3;

export const useCustomTouch = (gesture: CodeGesture, handler: () => void) => {
  useEffect(() => {
    const requiredNumbers = GESTURES_MAP[gesture].code;

    const cellWidth = window.innerWidth / GRID_SIZE;
    const cellHeight = window.innerHeight / GRID_SIZE;

    let enteredNumbers = '';

    const handleTouch = (event: TouchEvent) => {
      if (!event.touches[0]) return;

      const currentCol = Math.floor(event.touches[0].clientX / cellWidth);
      const currentRow = Math.floor(event.touches[0].clientY / cellHeight);
      const currentNumber = currentRow * GRID_SIZE + currentCol + 1;

      if (enteredNumbers[enteredNumbers.length - 1] !== `${currentNumber}`) {
        enteredNumbers += currentNumber;
      }
    };

    const handleTouchEnd = () => {
      if (requiredNumbers === enteredNumbers) {
        handler();
      }

      enteredNumbers = '';
    };

    document.addEventListener('touchstart', handleTouch);
    document.addEventListener('touchmove', handleTouch);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouch);
      document.removeEventListener('touchmove', handleTouch);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [gesture, handler]);
};
