import { useMemo } from 'react';
import cn from 'classnames';

import styles from './slide-dot.module.less';

export interface ISlideDotProps {
  isSelected: boolean;
}

export const SlideDot = (props: ISlideDotProps) => {
  const { isSelected } = props;

  const slideArrowClasses = useMemo(
    () =>
      cn(styles.SlideDot, {
        [styles['SlideDot--selected']]: isSelected,
      }),
    [isSelected],
  );

  return <div className={slideArrowClasses} />;
};
