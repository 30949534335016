import { HttpHeaders } from '@capacitor/core';
import { inject, injectable } from 'inversify';
import { authLogger } from 'loggers/auth.logger';

import { ISessionResponse } from 'services/auth/interfaces/session-response.interface';
import { HttpOptionsType } from 'services/http/interfaces/http-options.interface';
import { transport } from 'services/http/utils/transport.util';
import { StorageField } from 'services/storage/enum/storage-field.enum';
import { StorageService } from 'services/storage/storage.service';

import { TYPES } from 'configs/di-types.config';

import { AUTH_REFRESH } from './consts/api-endpoints.constants';

@injectable()
export class HttpService {
  private readonly storageService: StorageService;

  constructor(@inject<StorageService>(TYPES.StorageService) storageService: StorageService) {
    this.storageService = storageService;
  }

  public fetchRefreshedSession(session: ISessionResponse) {
    const { access_token: accessToken, refresh_token: refreshToken } = session;

    const refinedHeaders: HttpHeaders = {
      'X-Version': '2',
    };

    return transport<ISessionResponse, Omit<ISessionResponse, 'expiresIn'>>({
      url: AUTH_REFRESH,
      method: 'POST',
      headers: refinedHeaders,
      body: {
        access_token: accessToken,
        refresh_token: refreshToken,
      },
    });
  }

  public async request<T = undefined, V = undefined>(
    options: HttpOptionsType<V>,
  ): Promise<IResponse<T>> {
    const session = await this.storageService.get<ISessionResponse>(StorageField.Session);

    const refinedOptions: HttpOptionsType<V> = { ...options };

    if (session) {
      const refinedHeaders: HttpHeaders = options.headers || {};

      refinedHeaders.Authorization = `Bearer ${session.access_token}`;

      refinedOptions.headers = refinedHeaders;
    }

    const result = await transport<T, V>(refinedOptions);

    if (result.code === 401 && session) {
      authLogger.info({ msg: 'Token refreshing required' });

      const refreshedSession = await this.fetchRefreshedSession(session);

      authLogger.debug({ msg: 'Refresh session', refreshedSession });

      if (refreshedSession.success) {
        authLogger.info({ msg: 'Session has been successfully refreshed' });

        this.storageService.set(StorageField.Session, refreshedSession.data);

        const refreshedHeaders: HttpHeaders = {};

        refreshedHeaders.Authorization = `Bearer ${refreshedSession.data.access_token}`;

        refinedOptions.headers = refreshedHeaders;

        return transport(refinedOptions);
      }

      authLogger.error({ msg: 'Session refreshing is failed' });

      this.storageService.remove(StorageField.Session);
    }

    return result;
  }
}
