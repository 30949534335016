import { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  OffsetOptions,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cn from 'classnames';

import { useToggleIgnoredTouchClass } from 'hooks/use-toggle-ignored-touch-class';

import styles from './tooltip.module.less';

export enum TooltipEventType {
  click,
  hover,
}
export interface ITooltipProps {
  tooltipPortalRoot?: Maybe<HTMLElement>;
  tooltipContent: ReactNode;
  closeOnContentClick?: boolean;
  children: ReactNode;
  eventType: TooltipEventType;
  placement?: Placement;
  tooltipOffset?: OffsetOptions;
  tooltipDisappearTime?: number;
  isNeedCloseTooltip?: boolean;
  toggleOnClick?: boolean;
  withoutShadowEffect?: boolean;
  setIsNeedCloseTooltip?: (value: boolean) => void;
  onPickerClick?: () => void;
  allowOpeningCheck?: () => boolean;
}

export const Tooltip: FC<ITooltipProps> = (props: PropsWithChildren<ITooltipProps>) => {
  const {
    tooltipPortalRoot,
    tooltipContent,
    children,
    placement,
    tooltipOffset = 0,
    eventType,
    tooltipDisappearTime,
    closeOnContentClick = false,
    isNeedCloseTooltip,
    toggleOnClick = false,
    withoutShadowEffect = false,
    setIsNeedCloseTooltip,
    onPickerClick,
    allowOpeningCheck,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  useToggleIgnoredTouchClass(isOpen);

  const middleware = useMemo(() => {
    const withPlacement = [offset(tooltipOffset), flip()];

    if (placement) {
      return withPlacement;
    }

    return [...withPlacement, flip(), shift()];
  }, [placement, tooltipOffset]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        onPickerClick?.();
      }

      if (!allowOpeningCheck || allowOpeningCheck?.()) {
        setIsOpen(open);
      }
    },
    [allowOpeningCheck, onPickerClick],
  );

  const { x, y, strategy, refs, context } = useFloating({
    open: isOpen,
    placement: placement || 'bottom',
    onOpenChange: handleOpenChange,
    middleware,
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { enabled: eventType === TooltipEventType.hover, move: false });
  const click = useClick(context, {
    enabled: eventType === TooltipEventType.click,
    toggle: toggleOnClick,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, click, dismiss, role]);

  useEffect(() => {
    if (setIsNeedCloseTooltip && isNeedCloseTooltip) {
      setIsOpen(false);
      setIsNeedCloseTooltip(false);
    }
  }, [setIsOpen, isNeedCloseTooltip, setIsNeedCloseTooltip]);

  useEffect(() => {
    if (tooltipDisappearTime) {
      setTimeout(() => {
        if (isOpen) {
          setIsOpen(false);
        }
      }, tooltipDisappearTime);
    }
  }, [isOpen, tooltipDisappearTime]);

  const classTooltipNames = useMemo(
    () =>
      cn(styles.Tooltip, {
        [styles['Tooltip--no-shadow']]: withoutShadowEffect,
      }),
    [withoutShadowEffect],
  );

  const handleClick = useCallback(() => {
    if (closeOnContentClick) {
      setIsOpen(false);
    }
  }, [closeOnContentClick]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      <FloatingPortal root={tooltipPortalRoot}>
        {isOpen && (
          <div
            tabIndex={0}
            role="button"
            aria-label="Tootltip"
            onClick={handleClick}
            onKeyDown={handleClick}
            className={classTooltipNames}
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            {tooltipContent}
          </div>
        )}
      </FloatingPortal>
    </>
  );
};
