import { FC, memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { SeasonType } from 'services/seasons/enums/season-type.enum';

import { IGameTeamInfo } from 'stores/game/interfaces/game.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Timeouts } from 'components/game/game-score/timeouts/timeouts.component';
import { Avatar, AvatarSize, IconAvatarSize } from 'components/ui/avatar/avatar.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { TeamPentagon } from 'components/ui/team-pentagon/team-pentagon.component';
import {
  VectorIcon,
  VectorIconName,
  VectorIconSize,
} from 'components/ui/vector-icon/vector-icon.component';

import bonusActive from 'assets/images/svg/bonus-active.svg';
import bonusNotActive from 'assets/images/svg/bonus-not-active.svg';

import styles from './team.module.less';

export enum ThemeTeam {
  Visitor,
  Home,
}

interface ITeamProps {
  theme: ThemeTeam;
  team: IGameTeamInfo;
  isWinning: boolean;
  status: GameStatus;
  seasonType: SeasonType;
  onTeamClick: (teamId: number) => void;
}

const TOTAL_TIMEOUTS = 7;

export const Team: FC<ITeamProps> = memo((props: ITeamProps) => {
  const { team, theme, status, isWinning, seasonType, onTeamClick } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const liveOrHalftime = useMemo(
    () => status === GameStatus.Live || status === GameStatus.Halftime,
    [status],
  );

  const teamFirstRowClassNames = useMemo(
    () =>
      cn(styles.TeamFirstRow, {
        [styles['TeamFirstRow--expand']]: liveOrHalftime,
      }),
    [liveOrHalftime],
  );

  const handleTeamClick = useCallback(() => {
    onTeamClick(team.teamInfo.id);
  }, [team.teamInfo.id, onTeamClick]);

  const statistics = useMemo(() => {
    if (seasonType === SeasonType.PST) {
      return `${team.winTotal} - ${team.lossTotal}`;
    }

    if (isDesktopPlus && theme === ThemeTeam.Visitor) {
      return `${team.winTotal} - ${team.lossTotal}, ${team.winAway} - ${team.lossAway} Away`;
    }

    if (isDesktopPlus && theme === ThemeTeam.Home) {
      return `${team.winTotal} - ${team.lossTotal}, ${team.winHome} - ${team.lossHome} Home`;
    }

    return `${team.winTotal} - ${team.lossTotal}`;
  }, [isDesktopPlus, theme, team, seasonType]);

  const teamColorStyle = useMemo(
    () => ({ color: team.teamInfo.secondaryTextColor }),
    [team.teamInfo.secondaryTextColor],
  );

  const avatarSize = useMemo(() => {
    if (isDesktopPlus) {
      return AvatarSize.L;
    }

    return AvatarSize.M;
  }, [isDesktopPlus]);

  if (isDesktopPlus && theme === ThemeTeam.Home) {
    return (
      <button className={styles.Home} style={teamColorStyle} onClick={handleTeamClick}>
        <div className={styles.Home__Items}>
          <div className={teamFirstRowClassNames}>
            {isWinning && status === GameStatus.Finished && (
              <div className={styles.Winner}>
                <VectorIcon size={VectorIconSize.S} name={VectorIconName.Winner} />
              </div>
            )}
            <div className={styles.HomeInfo}>
              <div className={styles.HomeInfo__Name}>{team.teamInfo.name}</div>
              <div className={styles.HomeInfo__Results}>{statistics}</div>
              {liveOrHalftime && (
                <div className={styles.HomeSecondRow}>
                  <Timeouts
                    total={TOTAL_TIMEOUTS}
                    used={team.usedTimeouts}
                    reverseDirection={false}
                  />
                  <img src={team.isBonusActive ? bonusActive : bonusNotActive} alt="Bonus" />
                </div>
              )}
            </div>
            <Avatar
              size={avatarSize}
              iconFontName={IconFontName.Mention}
              iconSize={IconAvatarSize.S}
              username={team.teamInfo.code}
              src={team.teamInfo.smallLogoUrl}
            />
          </div>
        </div>
        <TeamPentagon color={team.teamInfo.primaryColor} />
      </button>
    );
  }

  if (!isDesktopPlus && theme === ThemeTeam.Home) {
    return (
      <button className={styles.Home} style={teamColorStyle} onClick={handleTeamClick}>
        <div className={styles.Home__Items}>
          <div className={teamFirstRowClassNames}>
            <div className={styles.HomeInfo}>
              <div className={styles.HomeInfo__Row}>
                {isWinning && status === GameStatus.Finished && (
                  <div className={styles.Winner}>
                    <VectorIcon size={VectorIconSize.S} name={VectorIconName.Winner} />
                  </div>
                )}
                <div className={styles.HomeInfo__Name}>{team.teamInfo.code}</div>
              </div>
              <div className={styles.HomeInfo__Results}>{statistics}</div>
            </div>
            <Avatar
              size={avatarSize}
              iconFontName={IconFontName.Mention}
              iconSize={IconAvatarSize.S}
              username={team.teamInfo.code}
              src={team.teamInfo.smallLogoUrl}
            />
          </div>
          {liveOrHalftime && (
            <div className={styles.HomeSecondRow}>
              <Timeouts total={TOTAL_TIMEOUTS} used={team.usedTimeouts} reverseDirection={false} />
              <img src={team.isBonusActive ? bonusActive : bonusNotActive} alt="Bonus" />
            </div>
          )}
        </div>
        <TeamPentagon color={team.teamInfo.primaryColor} />
      </button>
    );
  }

  if (isDesktopPlus && theme === ThemeTeam.Visitor) {
    return (
      <button className={styles.Visitor} style={teamColorStyle} onClick={handleTeamClick}>
        <div className={styles.Visitor__Items}>
          <div className={teamFirstRowClassNames}>
            <Avatar
              size={avatarSize}
              iconFontName={undefined}
              iconSize={IconAvatarSize.S}
              username={team.teamInfo.code}
              src={team.teamInfo.smallLogoUrl}
            />
            <div className={styles.VisitorInfo}>
              <div className={styles.VisitorInfo__Name}>{team.teamInfo.name}</div>
              <div className={styles.VisitorInfo__Results}>{statistics}</div>
              {liveOrHalftime && (
                <div className={styles.VisitorSecondRow}>
                  <img src={team.isBonusActive ? bonusActive : bonusNotActive} alt="Bonus" />
                  <Timeouts total={TOTAL_TIMEOUTS} used={team.usedTimeouts} reverseDirection />
                </div>
              )}
            </div>
            {isWinning && status === GameStatus.Finished && (
              <div className={styles.Winner}>
                <VectorIcon size={VectorIconSize.S} name={VectorIconName.Winner} />
              </div>
            )}
          </div>
        </div>
        <TeamPentagon color={team.teamInfo.primaryColor} />
      </button>
    );
  }

  if (!isDesktopPlus && theme === ThemeTeam.Visitor) {
    return (
      <button className={styles.Visitor} style={teamColorStyle} onClick={handleTeamClick}>
        <div className={styles.Visitor__Items}>
          <div className={teamFirstRowClassNames}>
            <Avatar
              size={avatarSize}
              iconFontName={undefined}
              iconSize={IconAvatarSize.S}
              username={team.teamInfo.code}
              src={team.teamInfo.smallLogoUrl}
            />
            <div className={styles.VisitorInfo}>
              <div className={styles.VisitorInfo__Row}>
                <div className={styles.VisitorInfo__Name}>{team.teamInfo.code}</div>
                {isWinning && status === GameStatus.Finished && (
                  <div className={styles.Winner}>
                    <VectorIcon size={VectorIconSize.S} name={VectorIconName.Winner} />
                  </div>
                )}
              </div>
              <div className={styles.VisitorInfo__Results}>{statistics}</div>
            </div>
          </div>
          {liveOrHalftime && (
            <div className={styles.VisitorSecondRow}>
              <img src={team.isBonusActive ? bonusActive : bonusNotActive} alt="Bonus" />
              <Timeouts total={TOTAL_TIMEOUTS} used={team.usedTimeouts} reverseDirection />
            </div>
          )}
        </div>

        <TeamPentagon color={team.teamInfo.primaryColor} />
      </button>
    );
  }

  return null;
});
