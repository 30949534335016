import { memo, useCallback, useEffect, useRef } from 'react';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { IBoxScoresPlayerStatistics } from 'stores/teams-stats/interfaces/players-stats.interface';

import { BaseTableGrid } from 'components/base-table/base-table.component';

import './ag-theme-box-scores.less';

interface IBoxScoresPlayerStatsTableProps {
  tableGridOption: GridOptions;
  hideHeader?: boolean;
  onRowClick: (event: { data: IBoxScoresPlayerStatistics }) => void;
}

export const BoxScoresPlayerStatsTable = memo((props: IBoxScoresPlayerStatsTableProps) => {
  const { tableGridOption, hideHeader } = props;
  const agGridRef = useRef<AgGridReact>(null);

  const updateColumnDefinitions = useCallback((columnDefs: ColDef[]) => {
    if (agGridRef?.current?.api) {
      agGridRef.current.api?.setColumnDefs(columnDefs);
    }
  }, []);

  useEffect(() => {
    if (agGridRef.current && tableGridOption.rowData) {
      agGridRef.current?.api?.setRowData(tableGridOption.rowData);
    }
  }, [tableGridOption.rowData]);

  useEffect(() => {
    if (tableGridOption.columnDefs) {
      updateColumnDefinitions(tableGridOption.columnDefs);
    }
  }, [updateColumnDefinitions, tableGridOption]);

  return (
    <div className="ag-theme-box-scores">
      <BaseTableGrid
        onRowClick={props.onRowClick}
        hideHeader={hideHeader}
        ref={agGridRef}
        gridOptions={tableGridOption}
      />
    </div>
  );
});
