import { memo, ReactNode, useMemo } from 'react';
import cn from 'classnames';

import styles from './embed-wrapper-card.module.less';

interface IEmbedWrapperCardProps {
  isYoutube?: boolean;
  children: ReactNode;
}

export const EmbedWrapperCard = memo((props: IEmbedWrapperCardProps) => {
  const { isYoutube = false, children } = props;

  const embedWrapperCardClasses = useMemo(
    () =>
      cn(styles.EmbedWrapperCard, {
        [styles['EmbedWrapperCard--is-youtube']]: isYoutube,
      }),
    [isYoutube],
  );

  return <div className={embedWrapperCardClasses}>{children}</div>;
});
