import { FC, memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import styles from './pagination-panel.module.less';

interface IPaginationPanelProps {
  currentPage: number;
  totalPages: number;
  onPageClick: (page: number) => void;
}

export const PaginationPanel: FC<IPaginationPanelProps> = memo((props: IPaginationPanelProps) => {
  const { currentPage, totalPages, onPageClick } = props;

  const pageNumbers = useMemo(
    () => Array.from({ length: totalPages }, (_, index) => index + 1),
    [totalPages],
  );

  const handleItemClick = useCallback(
    (page: number) => () => {
      if (page === currentPage) {
        return;
      }

      onPageClick(page);
    },
    [currentPage, onPageClick],
  );

  const getPageItemsClassNames = useCallback(
    (item: number) =>
      cn(styles.PaginationPanel__Item, {
        [styles['PaginationPanel__Item--active']]: item === currentPage,
      }),
    [currentPage],
  );

  return (
    <div className={styles.PaginationPanel}>
      {pageNumbers.map((item) => (
        <button className={getPageItemsClassNames(item)} key={item} onClick={handleItemClick(item)}>
          {item}
        </button>
      ))}
    </div>
  );
});
