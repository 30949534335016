import { FC } from 'react';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { StandingsContainer } from 'containers/standings/standings.container';
import { StandingsFiltersContainer } from 'containers/standings-filters/standings-filters.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

import styles from './home-standings.module.less';

export const HomeStandingsRoute: FC = () => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  return (
    <PageWrapperContainer>
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          {isDesktopPlus && <h3 className={styles.HomeStandings__Title}>NBA Standings</h3>}
          <StandingsFiltersContainer />
          <StandingsContainer />
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
