import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { BarActionType } from 'components/bars/bar-action.type';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import { IItemLine } from '../interfaces/item-line.interface';

import styles from './item-line.module.less';

export enum ItemLineTheme {
  Reports,
  Settings,
}

interface IItemLineProps {
  item: IItemLine;
  theme: ItemLineTheme;
  active?: boolean;
  onClick: (barAction: BarActionType) => void;
}

export const ItemLine = memo((props: IItemLineProps) => {
  const { item, theme, active = false, onClick } = props;

  const handleClick = useCallback(() => {
    onClick(item.actionType);
  }, [onClick, item.actionType]);

  const itemLineClasses = useMemo(
    () =>
      cn(styles.ItemLine, {
        [styles['ItemLine--settings-theme']]: ItemLineTheme.Settings === theme,
        [styles['ItemLine--reports-theme']]: ItemLineTheme.Reports === theme,
        [styles['ItemLine--without-icon']]: !item.icon,
        [styles['ItemLine--active']]: active,
      }),
    [theme, active, item.icon],
  );

  const chevronRightSize = useMemo(() => {
    if (ItemLineTheme.Reports === theme) {
      return IconFontSize.ExtraBig;
    }

    return IconFontSize.Big;
  }, [theme]);

  return (
    <li className={itemLineClasses}>
      <button className={styles.ItemLine__Button} onClick={handleClick}>
        {item.icon && <IconFont name={item.icon} size={IconFontSize.Big} />}
        <span className={styles.ItemLine__Title}>{item.text}</span>
        {!item.noArrow && (
          <span className={styles.ItemLine__Arrow}>
            <IconFont name={IconFontName.ChevronRight} size={chevronRightSize} />
          </span>
        )}
      </button>
    </li>
  );
});
