import { useCallback, useEffect, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ContainerNamesEnum } from 'services/google-analytic/enums/container-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { PermissionPopups } from 'services/local-database/config';

import { AuthStore } from 'stores/auth/auth.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';

import { WelcomePopup } from 'components/welcome-popup/welcome-popup.component';

import teamThumbanil from 'assets/images/commuinity-thumbnail.webp';
import teamBackground from 'assets/images/community-background.webp';

import { WELCOME_POPUP_TEAM_LIST } from './welcome-popup.config';

export const WelcomePopupTeamContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);

  const isWelcomePopupShown = useMemo(() => {
    if (!layoutStore.isFirstTimeIntroShown) {
      return true;
    }

    return authStore.isAuthorised
      ? authStore.userMe?.profile.teamFeedFirstVisited
      : layoutStore.isWelcomeTeamShown;
  }, [
    authStore.isAuthorised,
    authStore.userMe?.profile.teamFeedFirstVisited,
    layoutStore.isFirstTimeIntroShown,
    layoutStore.isWelcomeTeamShown,
  ]);

  const handleWelcomePopupAccept = useCallback(async () => {
    if (authStore.isAuthorised) {
      await authStore.updateUserMe({ teamFeedFirstVisited: true });
    }

    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.IntroPromptTeamEnter,
      },
    });

    layoutStore.setWelcomePopupShown(PermissionPopups.WELCOME_TEAM_SHOWN);
  }, [authStore, layoutStore]);

  const handleWelcomePopupClose = useCallback(async () => {
    if (authStore.isAuthorised) {
      await authStore.updateUserMe({ teamFeedFirstVisited: true });
    }

    layoutStore.setWelcomePopupShown(PermissionPopups.WELCOME_TEAM_SHOWN);
  }, [authStore, layoutStore]);

  const teamName = useMemo(() => teamsStatsStore.team?.nickname || '', [teamsStatsStore.team]);

  const teamCode = useMemo(() => teamsStatsStore.team?.code || '', [teamsStatsStore.team]);

  useEffect(() => {
    if (!isWelcomePopupShown && teamCode) {
      GoogleAnalyticService.event({
        eventName: 'container_custom_view',
        eventParams: {
          container_type: ContainerNamesEnum.IntroPromptTeam,
          team: teamCode,
        },
      });
    }
  }, [isWelcomePopupShown, teamCode]);

  useEffect(() => {
    layoutStore.setAnyWelcomePopupOpened(!isWelcomePopupShown);
    return () => layoutStore.setAnyWelcomePopupOpened(false);
  }, [isWelcomePopupShown, layoutStore]);

  useEffect(() => {
    if (authStore.isAuthorised && authStore.userMe?.profile.teamFeedFirstVisited) {
      layoutStore.setWelcomePopupShown(PermissionPopups.WELCOME_TEAM_SHOWN);
    }
  }, [authStore.isAuthorised, authStore.userMe?.profile.teamFeedFirstVisited, layoutStore]);

  if (!isWelcomePopupShown) {
    return (
      <WelcomePopup
        onAccept={handleWelcomePopupAccept}
        onClose={handleWelcomePopupClose}
        thumbnail={teamThumbanil}
        background={teamBackground}
        title={`Welcome to ${teamName}'s Team!`}
        description="Here you can find the latest updates and stats from your favorite teams."
        accepButtonName="Enter Team"
        listItems={WELCOME_POPUP_TEAM_LIST}
      />
    );
  }

  return null;
});
