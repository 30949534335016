import { FC } from 'react';

import {
  BaseModalComponent,
  IModal,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { NotificationPermission } from 'components/notification-permission/notification-permission.component';

import styles from './notification-permission-modal.module.less';

interface INotificationPermissionModalProps extends IModal {
  visible: boolean;
  onClose: () => void;
  onAllowNotifications: () => void;
}

export const NotificationPermissionModal: FC<INotificationPermissionModalProps> = (props) => {
  const { visible } = props;

  return (
    <BaseModalComponent
      visible={visible}
      size={ModalWindowSize.M}
      title={null}
      withHeader={false}
      isScrollDisabled
      onClose={props.onClose}
    >
      <div className={styles.ModalContent}>
        <NotificationPermission
          onAllowNotifications={props.onAllowNotifications}
          onClose={props.onClose}
        />
      </div>
    </BaseModalComponent>
  );
};
