import { memo, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { SeasonType } from 'services/seasons/enums/season-type.enum';

import { IGameDetailedResults } from 'stores/scoreboard/interfaces/game-detailed-team.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { TeamPentagon } from 'components/ui/team-pentagon/team-pentagon.component';

import styles from './scoreboard-team.module.less';

export enum ScoreboardTeamOrder {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

interface IScoreboardTeamProps {
  id: number;
  isHome: boolean;
  avatarSrc?: Maybe<string>;
  color: string;
  nickname: string;
  status: GameStatus;
  seasonType: SeasonType;
  teamOrder: ScoreboardTeamOrder;
  results: Maybe<IGameDetailedResults>;
  secondaryTextColor: string;
  onTeamClick: (teamId: number) => void;
}

export const ScoreboardTeam = memo((props: IScoreboardTeamProps) => {
  const {
    isHome,
    avatarSrc,
    color,
    nickname,
    status,
    teamOrder,
    results,
    seasonType,
    id,
    secondaryTextColor,
    onTeamClick,
  } = props;

  const withBackground = status === GameStatus.Live || status === GameStatus.Halftime;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const isHomeHorizontal = useMemo(
    () => isHome && teamOrder === ScoreboardTeamOrder.Horizontal && status !== GameStatus.Scheduled,
    [teamOrder, isHome, status],
  );

  const infoClassNames = useMemo<string>(
    () => cn(styles.ScoreboardTeam__Info, styles.TeamInfo),
    [],
  );

  const teamClassNames = useMemo<string>(
    () =>
      cn(styles.ScoreboardTeam, {
        [styles['ScoreboardTeam--home-horizontal']]: isHomeHorizontal,
      }),
    [isHomeHorizontal],
  );

  const teamResults = useMemo(() => {
    if (!results) {
      return '(0-0, 0-0)';
    }

    if (seasonType === SeasonType.PST && results) {
      return `${results.winTotal}-${results.lossTotal}`;
    }

    const totalStats = `${results.winTotal}-${results.lossTotal}`;
    const homeAwayStats = isHome
      ? `${results.winHome}-${results.lossHome}`
      : `${results.winAway}-${results.lossAway}`;

    return `(${totalStats}, ${homeAwayStats})`;
  }, [isHome, seasonType, results]);

  const teamColorStyles = useMemo(() => {
    if (withBackground) {
      return { color: secondaryTextColor };
    }

    return {};
  }, [secondaryTextColor, withBackground]);

  const avatarSize = useMemo(
    () => (withBackground || isDesktopPlus ? AvatarSize.M : AvatarSize.S),
    [withBackground, isDesktopPlus],
  );

  const handleTeamClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      onTeamClick(id);
    },
    [onTeamClick, id],
  );

  return (
    <button className={teamClassNames} onClick={handleTeamClick}>
      {withBackground && (
        <div className={styles.ScoreboardTeam__Background}>
          <TeamPentagon color={color} />
        </div>
      )}
      <div className={styles.ScoreboardTeam__Avatar}>
        <Avatar
          iconFontName={isHome ? IconFontName.Mention : null}
          size={avatarSize}
          src={avatarSrc}
          username={nickname}
        />
      </div>
      <div className={infoClassNames} style={teamColorStyles}>
        <div className={styles.TeamInfo__Name}>{nickname}</div>
        <div className={styles.TeamInfo__Results}>{teamResults}</div>
      </div>
    </button>
  );
});
