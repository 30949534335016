import { IStandingsResponse } from 'services/standings/interfaces/standings-response.interface';

import { IStandingsRowData } from '../interfaces/standings-row-data.interface';

export const standingsAdapter = (standingsResponse: IStandingsResponse): IStandingsRowData => {
  const {
    id,
    name,
    nickname,
    code,
    conference,
    division,
    small_logo_url: smallLogoUrl,
    medium_logo_url: mediumLogoUrl,
    achievement,
    win_total: wins,
    loss_total: losses,
    win_percentage: percentage,
    games_behind: gamesBehind,
    win_home: winHome,
    win_away: winAway,
    loss_home: lossHome,
    loss_away: lossAway,
    div_win: divWin,
    div_loss: divLoss,
    conf_win: confWin,
    conf_loss: confLoss,
    ppg: pointsPerGame,
    oppg: opponentPointsPerGame,
    diff,
    streak,
    win_last_10: winLast10,
    loss_last_10: lossLast10,
    win_10_pt_games: win10PtGames,
    loss_10_pt_games: loss10PtGames,
    win_3_pt_games: win3PtGames,
    loss_3_pt_games: loss3PtGames,
    win_vs_500_and_above: winVs500AndAbove,
    loss_vs_500_and_above: lossVs500AndAbove,
    win_vs_500_and_below: winVs500AndBellow,
    loss_vs_500_and_below: lossVs500AndBellow,
    east_win: eastWin,
    east_loss: eastLoss,
    west_win: westWin,
    west_loss: westLoss,
    atlantic_win: atlanticWin,
    atlantic_loss: atlanticLoss,
    central_win: centralWin,
    central_loss: centralLoss,
    northwest_win: northwestWin,
    northwest_loss: northwestLoss,
    pacific_win: pacificWin,
    pacific_loss: pacificLoss,
    southeast_win: southeastWin,
    southeast_loss: southeastLoss,
    southwest_win: southwestWin,
    southwest_loss: southwestLoss,
  } = standingsResponse;

  const winPercentage = percentage === 1 ? percentage.toFixed(3) : percentage.toFixed(3).slice(1);

  const differential = (diff > 0 ? '+' : '') + diff.toFixed(1);

  return {
    team: {
      id,
      name,
      nickname,
      code,
      conference,
      division,
      smallLogoUrl,
      mediumLogoUrl,
      achievement,
    },
    wins,
    losses,
    winPercentage,
    gamesBehind,
    homeRecord: `${winHome}-${lossHome}`,
    awayRecord: `${winAway}-${lossAway}`,
    divisionRecord: `${divWin}-${divLoss}`,
    conferenceRecord: `${confWin}-${confLoss}`,
    pointsPerGame,
    opponentPointsPerGame,
    differential,
    streak,
    last10: `${winLast10}-${lossLast10}`,

    games3pt: `${win3PtGames}-${loss3PtGames}`,
    games10pt: `${win10PtGames}-${loss10PtGames}`,
    vs500Above: `${winVs500AndAbove}-${lossVs500AndAbove}`,
    vs500Below: `${winVs500AndBellow}-${lossVs500AndBellow}`,

    eastRecord: `${eastWin}-${eastLoss}`,
    atlanticRecord: `${atlanticWin}-${atlanticLoss}`,
    centralRecord: `${centralWin}-${centralLoss}`,
    southeastRecord: `${southeastWin}-${southeastLoss}`,
    westRecord: `${westWin}-${westLoss}`,
    northwestRecord: `${northwestWin}-${northwestLoss}`,
    pacificRecord: `${pacificWin}-${pacificLoss}`,
    southwestRecord: `${southwestWin}-${southwestLoss}`,
  };
};
