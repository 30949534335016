import { FC, memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { PollStatusEnum } from 'services/posts/enums/post.enum';

import { IconFont, IconFontName, IconFontSize } from '../../icon-font/icon-font.component';

import styles from './poll-item.module.less';

export enum PollTheme {
  Post,
  Comment,
}

export interface IPollItemProps {
  theme: PollTheme;
  id: string;
  answer: string;
  percentage: number;
  votesCount: number;
  userAnswerId: Maybe<string>;
  status: PollStatusEnum;
  expired: boolean;
  onAnswerClick: (uuid: string) => void;
}

export const PollItem: FC<IPollItemProps> = memo((props: IPollItemProps) => {
  const {
    theme,
    id,
    answer,
    percentage,
    votesCount,
    userAnswerId,
    status,
    expired,
    onAnswerClick,
  } = props;

  const isActive = useMemo(() => userAnswerId === id, [userAnswerId, id]);
  const isExpired = useMemo(() => status === PollStatusEnum.Closed || expired, [status, expired]);
  const isVoted = useMemo(() => userAnswerId || isExpired, [userAnswerId, isExpired]);

  const pollItemIconClasses = useMemo(
    () =>
      cn(styles.PollItem__Icon, {
        [styles['PollItem__Icon--expired']]: isExpired && !isActive,
      }),
    [isActive, isExpired],
  );

  const pollItemClasses = useMemo(
    () =>
      cn(styles.PollItem, {
        [styles['PollItem--inactive']]: isExpired || userAnswerId,
        [styles['PollItem--comment-theme']]: theme === PollTheme.Comment,
      }),
    [isExpired, userAnswerId, theme],
  );

  const handleAnswerClick = useCallback(() => {
    onAnswerClick(id);
  }, [onAnswerClick, id]);

  return (
    <div className={pollItemClasses}>
      {isVoted && <div className={styles.PollItem__Percentage}>{`${percentage}`}%</div>}
      <div role="presentation" className={styles.PollItem__Bar} onClick={handleAnswerClick}>
        {isVoted && (
          <span className={styles.PollItem__Progress} style={{ width: `${percentage}%` }} />
        )}
        {isActive && userAnswerId && (
          <div className={pollItemIconClasses}>
            <IconFont name={IconFontName.Confirm} size={IconFontSize.Small} />
          </div>
        )}
        <span className={styles.PollItem__Title}>{answer}</span>
        {isVoted && <div className={styles.PollItem__Counter}>{votesCount}</div>}
      </div>
    </div>
  );
});
