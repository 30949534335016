import { FC, memo, PropsWithChildren, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IButton } from 'components/ui/button/button.interface';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './icon-button-with-text.module.less';

export enum IconButtonWithTextTheme {
  Primary,
  Tertiary,
}

export enum IconButtonWithTextSize {
  Small,
}

export interface IIconButtonWithTextProps extends IButton {
  iconName: IconFontName;
  text: string;
  textLabel?: string;
  theme?: IconButtonWithTextTheme;
  size?: IconButtonWithTextSize;
}

export const IconButtonWithText: FC<IIconButtonWithTextProps> = memo(
  (props: PropsWithChildren<IIconButtonWithTextProps>) => {
    const {
      disabled,
      pseudoDisabled = false,
      iconName,
      text,
      textLabel,
      theme = IconButtonWithTextTheme.Primary,
      onClick,
      size,
    } = props;

    const classNames = useMemo(
      () =>
        cn(styles.IconButton, {
          [styles['IconButton--theme-primary']]: theme === IconButtonWithTextTheme.Primary,
          [styles['IconButton--theme-tertiary']]: theme === IconButtonWithTextTheme.Tertiary,
          [styles['IconButton--small']]: size === IconButtonWithTextSize.Small,
          [styles['IconButton--pseudo-disabled']]: pseudoDisabled,
        }),
      [pseudoDisabled, theme, size],
    );

    const handleClick = useCallback(
      (event: SyntheticEvent) => {
        if (onClick) {
          onClick(event);
        }
      },
      [onClick],
    );

    return (
      <button
        disabled={disabled}
        aria-label={textLabel}
        className={classNames}
        type="button"
        onClick={handleClick}
      >
        <IconFont name={iconName} size={IconFontSize.Small} />
        {!!text && <span className={styles.IconButton__Text}>{text}</span>}
      </button>
    );
  },
);
