import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { GlobalStatsStore } from 'stores/global-stats/global-stats.store';

import { TYPES } from 'configs/di-types.config';
import * as paths from 'routes/paths.constants';

import { useTeamClick } from 'hooks/use-team-click';

import { StatsTeamsLeaders } from 'components/stats/stats-leaders/stats-teams-leaders/stats-teams-leaders.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from '../stats.module.less';

export const StatsTeamsLeadersContainer = observer(() => {
  const globalStatsStore = useInjection<GlobalStatsStore>(TYPES.GlobalStatsStore);

  const navigate = useNavigate();

  const handleShowAllLeaders = useCallback(
    (sortParam: string, direction: string) => {
      navigate({
        pathname: paths.HOME_STATS_TEAMS_ALL,
        search: `${createSearchParams({ sort: sortParam, direction })}`,
      });
    },
    [navigate],
  );

  const handleTeamClick = useTeamClick();

  if (globalStatsStore.teamsStatsLeaders && !globalStatsStore.fetching) {
    return (
      <StatsTeamsLeaders
        leaders={globalStatsStore.teamsStatsLeaders}
        onShowAllLeaders={handleShowAllLeaders}
        onTeamClick={handleTeamClick}
      />
    );
  }

  return (
    <div className={styles.LoaderWrapper}>
      <Loader isShow withBackground />
    </div>
  );
});
