import { FC } from 'react';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import { IGameTeamInfo } from 'stores/game/interfaces/game.interface';

import { GAME_STATS_CONFIG } from 'components/game/game.config';
import { GameStatsAdditionalFieldsType, GameStatsMainFieldsType } from 'components/game/game.types';
import { GameStatsRow } from 'components/game/game-stats/game-stats-row/game-stats-row.component';

import styles from './game-stats.module.less';

interface IGameProps {
  homeTeam: IGameTeamInfo;
  visitorsTeam: IGameTeamInfo;
  gameStatus: GameStatus;
}

export const GameStats: FC<IGameProps> = (props) => {
  const { homeTeam, visitorsTeam, gameStatus } = props;

  return (
    <div className={styles.GameStats}>
      {gameStatus === GameStatus.Scheduled && (
        <div className={styles.GameStats__Label}>Team comparison</div>
      )}
      {GAME_STATS_CONFIG.map(({ field, isPrimary, title, isMain }) => {
        if (!homeTeam.statistics || !visitorsTeam.statistics) {
          return null;
        }

        if (isMain) {
          return (
            <GameStatsRow
              key={field}
              homeValue={homeTeam.statistics.mainStats[field as GameStatsMainFieldsType].made}
              homePercent={
                homeTeam.statistics.mainStats[field as GameStatsMainFieldsType].percentage
              }
              secondaryHomeValue={
                homeTeam.statistics.mainStats[field as GameStatsMainFieldsType].attempted
              }
              visitorsValue={
                visitorsTeam.statistics.mainStats[field as GameStatsMainFieldsType].made
              }
              visitorsPercent={
                visitorsTeam.statistics.mainStats[field as GameStatsMainFieldsType].percentage
              }
              secondaryVisitorsValue={
                visitorsTeam.statistics.mainStats[field as GameStatsMainFieldsType].attempted
              }
              homeColor={homeTeam.teamInfo.primaryColor}
              visitorsColor={visitorsTeam.teamInfo.primaryColor}
              isPrimary={isPrimary}
              title={title}
            />
          );
        }

        return (
          <GameStatsRow
            key={field}
            homeValue={homeTeam.statistics.additionalStats[field as GameStatsAdditionalFieldsType]}
            visitorsValue={
              visitorsTeam.statistics.additionalStats[field as GameStatsAdditionalFieldsType]
            }
            homeColor={homeTeam.teamInfo.primaryColor}
            visitorsColor={visitorsTeam.teamInfo.primaryColor}
            isPrimary={isPrimary}
            title={title}
          />
        );
      })}
    </div>
  );
};
