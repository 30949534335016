import { inject, injectable } from 'inversify';

import {
  IFollowItemResponse,
  ISelectedLocalFollowings,
  IUpdateFollowingsPayload,
} from 'services/follow/interfaces/followings-response.interface';
import {
  PLAYER_ID_API_PARAM,
  TEAM_ID_API_PARAM,
} from 'services/http/consts/api-endpoint-params.constants';
import { HttpService } from 'services/http/http.service';
import { StorageField } from 'services/storage/enum/storage-field.enum';
import { StorageService } from 'services/storage/storage.service';

import { TYPES } from 'configs/di-types.config';

import { FOLLOW_PLAYER, FOLLOW_TEAM, FOLLOWINGS } from '../http/consts/api-endpoints.constants';

@injectable()
export class FollowService {
  private readonly httpService: HttpService;

  private readonly storageService: StorageService;

  constructor(
    @inject(TYPES.HttpService) httpService: HttpService,
    @inject(TYPES.StorageService) storageService: StorageService,
  ) {
    this.httpService = httpService;
    this.storageService = storageService;
  }

  public loadFollowings(): Promise<IResponse<IFollowItemResponse[]>> {
    return this.httpService.request<IFollowItemResponse[]>({
      url: FOLLOWINGS,
      method: 'GET',
    });
  }

  public updateFollowings(
    payload: IUpdateFollowingsPayload,
  ): Promise<IResponse<IFollowItemResponse[]>> {
    return this.httpService.request<IFollowItemResponse[], IUpdateFollowingsPayload>({
      url: FOLLOWINGS,
      method: 'POST',
      body: payload,
    });
  }

  public getFollowingsFromSessions(): Promise<Maybe<ISelectedLocalFollowings>> {
    return this.storageService.get<ISelectedLocalFollowings>(StorageField.Followings, true, true);
  }

  public setFollowingsIntoSessions(payload: ISelectedLocalFollowings) {
    this.storageService.set<ISelectedLocalFollowings>(StorageField.Followings, payload, true);
  }

  public removeFollowingsFromSessions() {
    this.storageService.remove(StorageField.Followings);
  }

  public followTeam(teamId: number): Promise<IResponse<null>> {
    return this.httpService.request<null>({
      url: FOLLOW_TEAM,
      method: 'POST',
      routeParams: { [TEAM_ID_API_PARAM]: teamId.toString() },
    });
  }

  public followPlayer(playerId: number): Promise<IResponse<null>> {
    return this.httpService.request<null>({
      url: FOLLOW_PLAYER,
      method: 'POST',
      routeParams: { [PLAYER_ID_API_PARAM]: playerId.toString() },
    });
  }

  public unfollowPlayer(playerId: number): Promise<IResponse<null>> {
    return this.httpService.request<null>({
      url: FOLLOW_PLAYER,
      method: 'DELETE',
      routeParams: { [PLAYER_ID_API_PARAM]: playerId.toString() },
    });
  }
}
