import { FC, memo, useCallback, useMemo } from 'react';
import { WithActiveType } from 'types/with-active.type';

import { IUserMe } from 'stores/auth/interfaces/user-me.interface';

import * as paths from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarMultipleAction } from 'components/bars/bar-action.type';
import { BarActionsListType } from 'components/bars/bar-actions-list-type.enum';
import { IBarItem } from 'components/bars/bar-item.interface';

import { BottomBarItem } from './bottom-bar-item/bottom-bar-item.component';

import styles from './bottom-bar-mobile.module.less';

export interface IBottomBarMobileProps {
  activePath: string;
  onClick: (action: BarMultipleAction<BarActionsListType>) => void;
  items: IBarItem<BarMultipleAction<BarActionsListType>>[];
  userMe: Maybe<IUserMe>;
}

export const BottomBarMobile: FC<IBottomBarMobileProps> = memo((props: IBottomBarMobileProps) => {
  const { activePath, onClick, items, userMe } = props;

  const isActiveNavItem = useCallback(
    (action: BarMultipleAction<BarActionsListType>) => {
      if (action.type !== BarAction.Link) {
        return false;
      }

      if (action.path === paths.PLAYERS_RANKINGS && activePath.includes(paths.PLAYERS_RANKINGS)) {
        return true;
      }

      return action.path.includes(activePath);
    },
    [activePath],
  );

  const preparedNavItems = useMemo<
    Array<WithActiveType<IBarItem<BarMultipleAction<BarActionsListType>>>>
  >(
    () =>
      items.map((item) => ({
        ...item,
        username: userMe?.username,
        avatarUrl: userMe?.profile.smallAvatarUrl,
        isActive: isActiveNavItem(item.action),
      })),
    [items, userMe, isActiveNavItem],
  );

  const handleItemClick = useCallback(
    (action: BarMultipleAction<BarActionsListType>) => () => {
      onClick(action);
    },
    [onClick],
  );

  return (
    <nav className={styles.BottomBar}>
      <ul className={styles.BottomBar__ItemsList}>
        {preparedNavItems.map((item) => (
          <BottomBarItem
            isDisabled={item?.isDisabled}
            key={item.name}
            isActive={item.isActive}
            icon={item.icon}
            name={item.name}
            onClick={handleItemClick(item.action)}
            username={item.username}
            avatarUrl={item.avatarUrl}
            isAuthorised={!!userMe}
          />
        ))}
      </ul>
    </nav>
  );
});
