import { FC, useMemo } from 'react';
import { ColDef, SortDirection } from 'ag-grid-community';

import {
  IPlayerCareerRecord,
  IPlayerStatsCareerAverageRowData,
} from 'stores/player/interfaces/player-profile.interfaces';

import {
  CAREER_PER_MODE_OPTIONS,
  CAREER_SEASON_TYPE_OPTIONS,
} from 'containers/player-profile/player-profile.config';

import { EmptyState } from 'components/empty-state/empty-state.component';
import {
  DEFAULT_PLAYER_SPLITS_COLUMNS,
  SEASONAL_PLAYER_STATS_FIRST_COLUMNS,
} from 'components/player-profile/player-profile-tables/player-profile-tables.config';
import { PlayerStatsTable } from 'components/team-stats/players-stats-table/players-stats-table.component';
import { Select } from 'components/ui/form-fields/select/select.component';
import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';

import playerProfileStyles from '../player-profile.module.less';
import styles from './player-profile-stats.module.less';

interface IPlayerProfileStatsProps {
  playerSeasonalStatsRowData: IPlayerCareerRecord[];
  playerStatsCareerAverageRowData: IPlayerStatsCareerAverageRowData[];
  hasTeamColor: boolean;
  selectedSeasonType: ISelectOption;
  selectedPerMode: ISelectOption;
  onTeamClick: (teamId: number) => void;
  onSeasonTypeChange: (season: string) => void;
  onPerModeChange: (season: string) => void;
}

export const PlayerProfileStats: FC<IPlayerProfileStatsProps> = (props) => {
  const {
    playerSeasonalStatsRowData,
    playerStatsCareerAverageRowData,
    hasTeamColor,
    selectedSeasonType,
    selectedPerMode,
    onTeamClick,
  } = props;

  const columnDefs = useMemo<ColDef[]>(() => {
    const sort: SortDirection = 'asc';

    return [
      ...SEASONAL_PLAYER_STATS_FIRST_COLUMNS.map((colDef, index) =>
        index === 1 ? { ...colDef, cellRendererParams: { onTeamClick } } : { ...colDef, sort },
      ),
      ...DEFAULT_PLAYER_SPLITS_COLUMNS,
    ];
  }, [onTeamClick]);

  return (
    <div className={playerProfileStyles.PlayerProfile}>
      <div className={playerProfileStyles.PlayerProfile__Section}>
        <div className={styles.Selectors}>
          <Select
            name="season-type"
            id="season-type"
            options={CAREER_SEASON_TYPE_OPTIONS}
            value={selectedSeasonType.value}
            onChange={props.onSeasonTypeChange}
          />
          <Select
            name="per-mode"
            id="per-mode"
            options={CAREER_PER_MODE_OPTIONS}
            value={selectedPerMode.value}
            onChange={props.onPerModeChange}
          />
        </div>
      </div>

      <div className={playerProfileStyles.PlayerProfile__Section}>
        <div className={playerProfileStyles.PlayerProfile__Header}>Season</div>
        <div className={styles.SeasonSection}>
          {!playerSeasonalStatsRowData.length || !playerStatsCareerAverageRowData.length ? (
            <EmptyState
              title="No Data for the Selected Season Type"
              message="Unfortunately, the player does not have any data for this season type"
            />
          ) : (
            <PlayerStatsTable
              tableGridOption={{
                columnDefs,
                rowData: playerSeasonalStatsRowData,
                pinnedBottomRowData: playerStatsCareerAverageRowData,
                gridId: '1',
              }}
              withColors={hasTeamColor}
            />
          )}
        </div>
      </div>
    </div>
  );
};
