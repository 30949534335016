import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { GridOptions, RowDataUpdatedEvent, SortChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import cn from 'classnames';

import { BaseTableGrid } from 'components/base-table/base-table.component';
import { handleRankValue } from 'components/base-table/helpers/handle-rank-value.util';

import './ag-theme-players-stats.less';

interface IPlayerStatsTableProps {
  tableGridOption: GridOptions;
  withColors: boolean;
}

export const PlayerStatsTable = memo((props: IPlayerStatsTableProps) => {
  const { tableGridOption, withColors } = props;
  const agGridRef = useRef<AgGridReact>(null);

  const handleSortChanged = useCallback((event: SortChangedEvent) => {
    const sortedTableState = event.columnApi?.getColumnState();
    event.api?.refreshHeader();
    event.api?.refreshCells();

    agGridRef?.current?.columnApi?.applyColumnState({ state: sortedTableState });

    if (event.api.getColumnDef('rank')) {
      handleRankValue(event);
    }
  }, []);

  const handleRowDataUpdated = useCallback((event: RowDataUpdatedEvent) => {
    if (event.api.getColumnDef('rank')) {
      handleRankValue(event);
    }
  }, []);

  useEffect(() => {
    if (agGridRef.current && tableGridOption.rowData) {
      agGridRef.current.api?.setRowData(tableGridOption.rowData);
    }

    if (agGridRef.current && tableGridOption.pinnedBottomRowData) {
      agGridRef.current.api?.setPinnedBottomRowData(tableGridOption.pinnedBottomRowData);
    }
  }, [tableGridOption.pinnedBottomRowData, tableGridOption.rowData]);

  const classNames = useMemo(
    () => cn('ag-theme-players-stats', { 'ag-theme-players-stats--with-colors': withColors }),
    [withColors],
  );

  return (
    <div className={classNames}>
      <BaseTableGrid
        ref={agGridRef}
        onSortChanged={handleSortChanged}
        gridOptions={tableGridOption}
        onRowDataUpdated={handleRowDataUpdated}
      />
    </div>
  );
});
