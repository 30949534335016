import { memo, useCallback, useMemo } from 'react';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './board-simple-item.module.less';

interface IBoardSimpleItemProps {
  avatarUrl: Maybe<string>;
  firstname: string;
  lastname: string;
  slug: string;
  followingsCount: number;
  onClick: (slug: string, label?: string) => void;
}

export const BoardSimpleItem = memo((props: IBoardSimpleItemProps) => {
  const { avatarUrl, firstname, lastname, slug, followingsCount, onClick } = props;

  const fullPlayerName = useMemo(() => `${firstname} ${lastname}`, [firstname, lastname]);

  const handleClick = useCallback(() => {
    onClick(slug, fullPlayerName);
  }, [slug, fullPlayerName, onClick]);

  return (
    <div role="presentation" className={styles.BoardSimpleItem} onClick={handleClick}>
      <Avatar src={avatarUrl} size={AvatarSize.M} username={fullPlayerName} />
      <div className={styles.BoardSimpleItem__Body}>
        <span className={styles.BoardSimpleItem__Name}>{fullPlayerName}</span>
        <span className={styles.BoardSimpleItem__Followings}>{followingsCount} followers</span>
      </div>
    </div>
  );
});
