import { FC, memo, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { IconFont, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import { IContextMenuItem } from '../interfaces/context-menu-item.interface';

import styles from './context-menu-item.module.less';

export interface IContextMenuItemProps {
  item: IContextMenuItem;
  selectedItem?: string;
  onItemClick: (barAction: BarActionType) => void;
}

export const ContextMenuItem: FC<IContextMenuItemProps> = memo((props: IContextMenuItemProps) => {
  const { item, selectedItem, onItemClick } = props;

  const itemClassNames = useMemo(
    () =>
      cn(styles.ContextMenuItem, {
        [styles['ContextMenuItem--active']]: item.isActive,
        [styles['ContextMenuItem--disabled']]: item.isDisabled,
        [styles['ContextMenuItem--delete']]: item.isDelete,
        [styles['ContextMenuItem--selected']]:
          item.actionType.type === BarAction.Click &&
          item.actionType.payload &&
          item.actionType.payload === selectedItem,
      }),
    [item.actionType, item.isDelete, item.isDisabled, selectedItem, item.isActive],
  );

  const textClassNames = useMemo(
    () =>
      cn(styles.Text, {
        [styles['Text--delete']]: item.isDelete,
      }),
    [item.isDelete],
  );

  const handleItemClick = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      if (!item.isDisabled) {
        onItemClick(item.actionType);
      }
    },
    [onItemClick, item.actionType, item.isDisabled],
  );

  return (
    <li>
      <button disabled={item.isDisabled} className={itemClassNames} onClick={handleItemClick}>
        <div>
          <IconFont name={item?.isActive ? item.iconActive! : item.icon} size={IconFontSize.Big} />
        </div>
        <span className={textClassNames}>{item.text}</span>
      </button>
    </li>
  );
});
