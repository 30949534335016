import { FC } from 'react';

import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';

import { ContactUsContainer } from 'containers/contact-us/contact-us.container';
import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';

import { useLayoutEntity } from 'hooks/use-layout-entity';

import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const ContactUsRoute: FC = () => {
  useLayoutEntity({ type: LayoutEntity.PageTitle, value: 'Contact Us' });

  return (
    <PageWrapperContainer>
      <Row layout={RowLayout.Regular}>
        <ContactUsContainer />
      </Row>
    </PageWrapperContainer>
  );
};
