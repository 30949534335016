import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { SeasonType } from 'services/seasons/enums/season-type.enum';

import { IGameDetailedRecord } from 'stores/scoreboard/interfaces/game-detailed-record.interface';
import { GameQuarter } from 'stores/scoreboard/types/game-quarter.type';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { GameDetailsTabs } from 'components/game/game.config';

import { ScoreboardTableLinks } from '../scoreboard-table-links/scoreboard-table-links.component';

import { VerticalRowsTableHeader } from './vertical-rows-table-header/vertical-rows-table-header.component';
import { VerticalRowsTableRow } from './vertical-rows-table-row/vertical-rows-table-row.component';

import styles from './vertical-rows-table.module.less';

interface IVerticalRowsTableProps {
  currentQuarter: GameQuarter;
  total: Maybe<number>;
  status: GameStatus;
  home: IGameDetailedRecord;
  visitors: IGameDetailedRecord;
  clock: Maybe<string>;
  seasonType: SeasonType;
  onPlayerClick: (slug: string) => void;
  onTeamClick: (teamId: number) => void;
  onGameClick: (tabQuery: Maybe<GameDetailsTabs>) => void;
}

export const VerticalRowsTable = memo((props: IVerticalRowsTableProps) => {
  const { currentQuarter, status, home, visitors, clock, seasonType, total, onGameClick } = props;

  const isFinished = status === GameStatus.Finished;
  const isLive = status === GameStatus.Live;
  const isHalftime = status === GameStatus.Halftime;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const classNames = useMemo<string>(
    () =>
      cn(styles.VerticalRowsTable, {
        [styles['VerticalRowsTable--live']]: isLive,
        [styles['VerticalRowsTable--halftime']]: isHalftime,
      }),
    [isLive, isHalftime],
  );

  const gameStatusOrTime = useMemo(() => {
    if (isLive) {
      if (!currentQuarter || !clock) {
        return 'Live';
      }

      if (currentQuarter === 5) {
        return `OT - ${clock}`;
      }

      if (currentQuarter > 5) {
        return `${currentQuarter - 4}OT - ${clock}`;
      }

      return `Q${currentQuarter} - ${clock}`;
    }

    if (isHalftime) {
      return 'Halftime';
    }

    return 'Final';
  }, [isLive, isHalftime, currentQuarter, clock]);

  const handleGameClick = useCallback(() => {
    onGameClick(null);
  }, [onGameClick]);

  return (
    <div className={classNames} role="table">
      <div className={styles.VerticalRowsTable__Main}>
        {isDesktopPlus && (
          <VerticalRowsTableHeader total={total} gameStatusOrTime={gameStatusOrTime} />
        )}
        <div
          className={styles.VerticalRowsTable__Rows}
          onClick={handleGameClick}
          onKeyDown={handleGameClick}
          tabIndex={0}
          role="button"
        >
          <VerticalRowsTableRow
            currentQuarter={currentQuarter}
            isHome={false}
            status={status}
            scores={visitors.scores}
            team={visitors.team}
            topPlayer={visitors.topPlayer}
            seasonType={seasonType}
            onPlayerClick={props.onPlayerClick}
            onTeamClick={props.onTeamClick}
          />
          <VerticalRowsTableRow
            currentQuarter={currentQuarter}
            isHome
            status={status}
            scores={home.scores}
            team={home.team}
            topPlayer={home.topPlayer}
            seasonType={seasonType}
            onPlayerClick={props.onPlayerClick}
            onTeamClick={props.onTeamClick}
          />
        </div>
      </div>
      <div className={styles.VerticalRowsTable__Links}>
        <ScoreboardTableLinks
          gameStatusOrTime={gameStatusOrTime}
          isFinished={isFinished}
          isLive={isLive}
          onLinkClick={props.onGameClick}
        />
      </div>
    </div>
  );
});
