import { memo, useCallback } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './opponent-cell.module.less';

interface IOpponentCellProps extends ICellRendererParams {
  onTeamClick: (teamId: string) => void;
}

export const OpponentCell = memo((props: IOpponentCellProps) => {
  const { value, onTeamClick } = props;

  const handleTeamClick = useCallback(() => {
    if (value) {
      onTeamClick(value.id);
    }
  }, [onTeamClick, value]);

  if (value) {
    const { code, logo } = value;

    return (
      <button className={styles.Opponent} onClick={handleTeamClick}>
        <Avatar size={AvatarSize.XS} username={code} src={logo} />
        <span className={styles.Opponent__Code}>{code}</span>
      </button>
    );
  }

  return null;
});
