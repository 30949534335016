import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { GameDetailsTabs } from 'components/game/game.config';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './scoreboard-table-links.module.less';

interface IScoreboardTableLinksProps {
  gameStatusOrTime: string;
  isFinished: boolean;
  isLive: boolean;
  onLinkClick: (tabQuery: Maybe<GameDetailsTabs>) => void;
}

export const ScoreboardTableLinks = memo((props: IScoreboardTableLinksProps) => {
  const { gameStatusOrTime, isFinished, isLive, onLinkClick } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const statusClassNames = useMemo(
    () =>
      cn(styles.ScoreboardTableLinks__Item, styles.ScoreboardTableLinks__Status, {
        [styles['ScoreboardTableLinks__Status--live']]: isLive,
      }),
    [isLive],
  );

  const handleLinkClick = useCallback(
    (tabQuery: Maybe<GameDetailsTabs>) => () => {
      onLinkClick(tabQuery);
    },
    [onLinkClick],
  );

  const firstItemMobile = useMemo(() => {
    if (isFinished) {
      return (
        <li className={styles.ScoreboardTableLinks__Item}>
          <Button
            theme={ButtonTheme.TextSecondary}
            size={ButtonSize.Small}
            onClick={handleLinkClick(
              isDesktopPlus ? GameDetailsTabs.PLAYS : GameDetailsTabs.PLAYERS,
            )}
          >
            {isDesktopPlus ? 'Plays' : 'Recap'}
          </Button>
        </li>
      );
    }

    return (
      <li className={styles.ScoreboardTableLinks__Item}>
        <Button
          theme={ButtonTheme.TextSecondary}
          size={ButtonSize.Small}
          onClick={handleLinkClick(null)}
        >
          Arena
        </Button>
      </li>
    );
  }, [handleLinkClick, isDesktopPlus, isFinished]);

  return (
    <ul className={styles.ScoreboardTableLinks}>
      {!isDesktopPlus && <li className={statusClassNames}>{gameStatusOrTime}</li>}
      {firstItemMobile}
      {isFinished && isDesktopPlus && (
        <li className={styles.ScoreboardTableLinks__Item}>
          <Button
            theme={ButtonTheme.TextSecondary}
            size={ButtonSize.Small}
            onClick={handleLinkClick(GameDetailsTabs.PLAYERS)}
          >
            Players
          </Button>
        </li>
      )}
      {isFinished && isDesktopPlus && (
        <li className={styles.ScoreboardTableLinks__Item}>
          <Button
            theme={ButtonTheme.TextSecondary}
            size={ButtonSize.Small}
            onClick={handleLinkClick(GameDetailsTabs.TEAMS)}
          >
            Teams
          </Button>
        </li>
      )}
    </ul>
  );
});
