import { FC, useCallback, useEffect, useState } from 'react';

import { IBookmarkUser } from 'stores/bookmarks/interfaces/bookmarks.interface';
import { BookmarksFilterType } from 'stores/bookmarks/types/bookmarks.type';

import { SORT_BY_SECTION_CONFIG } from 'components/bookmarks/bookmarks.config';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import {
  ICheckboxItem,
  MultipleChooseCheckboxes,
} from 'components/ui/form-fields/multiple-choose-checkboxes/multiple-choose-checkboxes.component';
import { RadioButton } from 'components/ui/form-fields/radio-button/radio-button.component';
import { AvatarWithNameOption } from 'components/ui/form-fields/search-select/avatar-with-name-option/avatar-with-name-option';
import { CustomDropdownIndicator } from 'components/ui/form-fields/search-select/custom-dropdown-indicator/custom-dropdown-indicator.component';
import { CustomMultiValue } from 'components/ui/form-fields/search-select/custom-multi-value/custom-multi-value.component';
import { CustomMultiValueContainer } from 'components/ui/form-fields/search-select/custom-multi-value-container/custom-multi-value-container.component';
import { CustomMultiValueRemove } from 'components/ui/form-fields/search-select/custom-multi-value-remove/custom-multi-value-remove.component';
import { SearchSelect } from 'components/ui/form-fields/search-select/search-select.component';

import styles from './bookmarks-filter.module.less';

export interface IBookmarksProps {
  users: Array<IBookmarkUser>;
  onResetButtonClick: () => void;
  onApplyButtonClick: (
    sortOrder: string,
    bookmarksTypes: BookmarksFilterType,
    selectedUsers: Array<IBookmarkUser>,
  ) => void;
  sortBy: string;
  types: Array<ICheckboxItem>;
  selectedBookmarksUsers: Array<IBookmarkUser>;
  setIsFilterOpen: (isOpen: boolean) => void;
}

export const BookmarksFilter: FC<IBookmarksProps> = (props) => {
  const { onApplyButtonClick, setIsFilterOpen } = props;

  const [sortBy, setSortBy] = useState(props.sortBy);
  const [types, setTypes] = useState(props.types);
  const [selectedBookmarksUsers, setSelectedBookmarksUsers] = useState(
    props.selectedBookmarksUsers,
  );
  const [userFilterText, setUserFilterText] = useState<string>('');

  useEffect(() => {
    setSortBy(props.sortBy);
    setTypes(props.types);
    setSelectedBookmarksUsers(props.selectedBookmarksUsers);
  }, [props.sortBy, props.types, props.selectedBookmarksUsers]);

  const handleSetUserFilterText = useCallback(
    (value: string) => {
      setUserFilterText(value);
    },
    [setUserFilterText],
  );

  const handleSetSortBy = useCallback((value: string) => {
    setSortBy(value);
  }, []);

  const handleSetTypes = useCallback((value: Array<ICheckboxItem>) => {
    setTypes(value);
  }, []);

  const handleSetSelectedBookmarksUsers = useCallback((value: Array<IBookmarkUser>) => {
    setSelectedBookmarksUsers(value);
  }, []);

  const handleApplyButtonClick = useCallback(() => {
    onApplyButtonClick(sortBy, types, selectedBookmarksUsers);
    setIsFilterOpen(false);
  }, [sortBy, types, selectedBookmarksUsers, onApplyButtonClick, setIsFilterOpen]);

  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.Wrapper__Filters}>
          <div className={styles.Wrapper__Search}>
            <SearchSelect
              options={props.users}
              title="By users"
              placeholder="Select"
              selectedItems={selectedBookmarksUsers}
              onSetSelectedItems={handleSetSelectedBookmarksUsers}
              defaultMenuIsOpen={false}
              value={userFilterText}
              onChange={handleSetUserFilterText}
              noOptionsText="No users found"
              id="filter_bookmarks_users"
              name="filter_bookmarks_users"
              OptionComponent={AvatarWithNameOption}
              MultiValueComponent={CustomMultiValue}
              MultiValueContainerComponent={CustomMultiValueContainer}
              MultiValueRemoveComponent={CustomMultiValueRemove}
              DropdownIndicatorComponent={CustomDropdownIndicator}
            />
          </div>
          <RadioButton
            id="filter_bookmarks_order"
            items={SORT_BY_SECTION_CONFIG}
            name="filter_bookmarks_order"
            value={sortBy}
            title="Sort by"
            onChange={handleSetSortBy}
          />
          <div className={styles.Types}>
            <MultipleChooseCheckboxes
              selectedItems={types}
              onSelectClick={handleSetTypes}
              title="Type"
              id="filter_bookmarks_types"
              name="filter_bookmarks_users"
            />
          </div>
        </div>
      </div>
      <div className={styles.Buttons}>
        <div className={styles.Button}>
          <Button
            fluid
            theme={ButtonTheme.Secondary}
            size={ButtonSize.SmallSecondary}
            onClick={props.onResetButtonClick}
          >
            Reset
          </Button>
        </div>
        <div className={styles.Button}>
          <Button
            fluid
            theme={ButtonTheme.Primary}
            size={ButtonSize.SmallSecondary}
            onClick={handleApplyButtonClick}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  );
};
