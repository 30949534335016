import { useEffect } from 'react';
import { App } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';

export const useKeyboardClose = (isNativeApp: boolean) => {
  useEffect(() => {
    if (isNativeApp) {
      App.addListener('pause', () => {
        Keyboard.hide();
      });
    }
  }, [isNativeApp]);
};
