import { useMemo } from 'react';
import cn from 'classnames';

import styles from './loader.module.less';

export enum LoaderTheme {
  Blue = 'blue',
  Default = 'default',
}

export enum LoaderSizeEnum {
  M, // 32x32
  S, // 24x24
  XS, // 16x16
}

interface ILoaderProps {
  isShow: boolean;
  isFeedRefresh?: boolean;
  isLocal?: boolean;
  isShiftLeft?: boolean;
  withBackground?: boolean;
  size?: LoaderSizeEnum;
  theme?: LoaderTheme;
}

export const Loader = (props: ILoaderProps) => {
  const {
    isShow,
    isLocal = false,
    isShiftLeft = false,
    isFeedRefresh = false,
    withBackground = false,
    size = LoaderSizeEnum.M,
    theme = LoaderTheme.Default,
  } = props;

  const wrapperClasses = useMemo(
    () =>
      cn(styles.Wrapper, {
        [styles['Wrapper--with-background']]: withBackground,
        [styles['Wrapper--local-loader']]: isLocal,
        [styles['Wrapper--shift-left']]: isShiftLeft,
        [styles['Wrapper--feed-refresh']]: isFeedRefresh,
        [styles['Wrapper--show']]: isShow,
      }),
    [withBackground, isLocal, isShiftLeft, isFeedRefresh, isShow],
  );

  const loaderClasses = useMemo(
    () =>
      cn(styles.Wrapper__Loader, {
        [styles['Wrapper__Loader--size-m']]: size === LoaderSizeEnum.M,
        [styles['Wrapper__Loader--size-s']]: size === LoaderSizeEnum.S,
        [styles['Wrapper__Loader--size-xs']]: size === LoaderSizeEnum.XS,
        [styles['Wrapper__Loader--theme-default']]: theme === LoaderTheme.Default,
        [styles['Wrapper__Loader--theme-blue']]: theme === LoaderTheme.Blue,
      }),
    [size, theme],
  );

  if (!isShow && !isFeedRefresh) {
    return null;
  }

  return (
    <div className={wrapperClasses}>
      <div className={loaderClasses} />
    </div>
  );
};
