import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';

import * as path from 'routes/paths.constants';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './not-found.module.less';

export const NotFound: FC = () => {
  const navigate = useNavigate();
  const handleBackToHome = useCallback(() => {
    navigate(path.HOME);
  }, [navigate]);

  return (
    <div className={styles.Wrapper}>
      <h2 className={styles.Wrapper__Heading}>404</h2>
      <p className={styles.Wrapper__Message}>Sorry, the page was not found</p>
      <Button theme={ButtonTheme.Primary} size={ButtonSize.Small} onClick={handleBackToHome}>
        Go to Homepage
      </Button>
    </div>
  );
};
