import { convertRGBToHEX } from 'helpers/string/rbg-to-hex.utils';

import { IGameInShare } from '../interfaces/share-data.interface';
import { IGameInShareResponse } from '../interfaces/share-response.interface';

export const gameInShareAdapter = (gameInShareResponse: IGameInShareResponse): IGameInShare => {
  const {
    id,
    status,
    clock,
    clock_formatted: clockFormatted,
    current_period: currentPeriod,
    home_result: home,
    visitors_result: visitors,
  } = gameInShareResponse;

  return {
    id,
    status,
    clock,
    clockFormatted,
    currentPeriod,
    homeResult: {
      points: home.points,
      teamInfo: {
        name: home.team_info.name,
        nickname: home.team_info.nickname,
        code: home.team_info.code,
        smallLogoUrl: home.team_info.small_logo_url,
        mediumLogoUrl: home.team_info.medium_logo_url,
        color: convertRGBToHEX(home.team_info.primary_color),
      },
    },
    visitorsResult: {
      points: visitors.points,
      teamInfo: {
        name: visitors.team_info.name,
        nickname: visitors.team_info.nickname,
        code: visitors.team_info.code,
        smallLogoUrl: visitors.team_info.small_logo_url,
        mediumLogoUrl: visitors.team_info.medium_logo_url,
        color: convertRGBToHEX(visitors.team_info.primary_color),
      },
    },
  };
};
