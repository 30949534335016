import { memo } from 'react';

import { BarActionType } from 'components/bars/bar-action.type';

import { IItemLineGroup } from './interfaces/item-line-group.interface';
import { ItemLine, ItemLineTheme } from './item-line/item-line.component';

interface ItemLineGroupProps {
  items: IItemLineGroup[];
  theme?: ItemLineTheme;
  onItemClick: (barAction: BarActionType) => void;
}

export const ItemLineList = memo((props: ItemLineGroupProps) => {
  const { items, theme = ItemLineTheme.Settings } = props;

  return (
    <ul>
      {items.map(({ id, item }) => (
        <ItemLine
          key={id}
          item={item}
          theme={theme}
          active={item.active}
          onClick={props.onItemClick}
        />
      ))}
    </ul>
  );
});
