import { FC } from 'react';

import { BookmarksContainer } from 'containers/bookmarks/bookmarks.container';

import { BaseSidePanel } from 'components/side-panels/base-side-panel/base-side-panel.component';

interface IBookmarkSidePanelProps {
  isOpen: boolean;
}

export const BookmarkSidePanel: FC<IBookmarkSidePanelProps> = (props) => {
  const { isOpen } = props;

  return (
    <BaseSidePanel isOpen={isOpen} isFullScreen>
      {isOpen ? <BookmarksContainer isForSidePanel /> : null}
    </BaseSidePanel>
  );
};
