import { forwardRef, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { IGame } from 'services/statistics/interface/game.interface';

import { ARENA_GAME_ITEM_FORMAT } from 'configs/date.config';
import { ENCODED_DASH_SYMBOL } from 'configs/encoded-symbols.config';

import { Avatar, AvatarSize, IconAvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './arena-game-item.module.less';

export interface IArenaGameItemProps {
  game: IGame;
  selected: boolean;
  homeIsWinning: boolean;
  isEqualPoints: boolean;
  onGameClick: () => void;
}

export const ArenaGameItem = forwardRef<HTMLDivElement, IArenaGameItemProps>((props, ref) => {
  const { game, selected, homeIsWinning, isEqualPoints } = props;

  const { teams } = game;

  const itemHeader = useMemo(() => {
    if (game.status === GameStatus.Scheduled) {
      return format(new Date(game.dateStart), 'p');
    }

    if (game.status === GameStatus.Finished) {
      return 'Final';
    }

    if (game.status === GameStatus.Halftime) {
      return 'Halftime';
    }

    if (game.status === GameStatus.Live) {
      return `${game.formattedPeriod} ${game.clockFormatted}`;
    }

    return format(new Date(game.dateStart), ARENA_GAME_ITEM_FORMAT);
  }, [game]);

  const homePointsOrCode = useMemo(() => {
    if (game.status === GameStatus.Scheduled) {
      return teams.home.code;
    }

    return teams.home.score.points;
  }, [game.status, teams.home.code, teams.home.score.points]);

  const visitorPointsOrCode = useMemo(() => {
    if (game.status === GameStatus.Scheduled) {
      return teams.visitors.code;
    }

    return teams.visitors.score.points;
  }, [game.status, teams.visitors.score.points, teams.visitors.code]);

  const arenaGameItemClasses = useMemo(
    () => cn(styles.ArenaGameItem, { [styles['ArenaGameItem--selected']]: selected }),
    [selected],
  );

  const arenaGameItemHeaderClasses = useMemo(
    () =>
      cn(styles.ArenaGameItem__Header, {
        [styles['ArenaGameItem__Header--live']]: game.status === GameStatus.Live,
        [styles['ArenaGameItem__Header--halftime']]: game.status === GameStatus.Halftime,
      }),
    [game.status],
  );

  const getArenaGameItemPointsClasses = useCallback(
    (isWinning: boolean) =>
      cn(styles.ArenaGameItem__Points, {
        [styles['ArenaGameItem__Points--highlighted']]:
          game.status !== GameStatus.Scheduled && isWinning,
      }),
    [game.status],
  );

  return (
    <div role="presentation" ref={ref} className={arenaGameItemClasses} onClick={props.onGameClick}>
      <div className={arenaGameItemHeaderClasses}>{itemHeader}</div>
      <div className={styles.ArenaGameItem__Teams}>
        <Avatar
          size={AvatarSize.XS}
          iconSize={IconAvatarSize.S}
          username={teams.visitors.name}
          src={teams.visitors.smallLogoUrl}
        />
        <div className={getArenaGameItemPointsClasses(!homeIsWinning && !isEqualPoints)}>
          {visitorPointsOrCode}
        </div>
        <div className={styles.ArenaGameItem__Separator}>{ENCODED_DASH_SYMBOL}</div>
        <div className={getArenaGameItemPointsClasses(homeIsWinning)}>{homePointsOrCode}</div>
        <Avatar
          size={AvatarSize.XS}
          iconSize={IconAvatarSize.S}
          username={teams.home.name}
          src={teams.home.smallLogoUrl}
        />
      </div>
    </div>
  );
});
