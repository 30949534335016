import { FC, useCallback, useState } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { IoStore } from 'stores/io/io.store';

import { TYPES } from 'configs/di-types.config';
import { CodeGesture } from 'configs/gestures.config';
import { LOGGER_HOT_KEY_MODIFIERS } from 'configs/logger.config';

import { useCustomTouch } from 'hooks/use-custom-touch';
import { useHotKey } from 'hooks/use-hot-key';

import { Logger } from 'components/logger/logger.component';

export const LoggerContainer: FC = observer(() => {
  const ioStore = useInjection<IoStore>(TYPES.IoStore);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleLogger = useCallback(() => {
    setIsVisible((prevIsVisible) => !prevIsVisible);
  }, []);

  const handleClearLogger = useCallback(() => {
    ioStore.clearLogs();
  }, [ioStore]);

  const handleCopyAllLogs = useCallback(() => {
    ioStore.copyAllLogs();
  }, [ioStore]);

  useHotKey(true, 'KeyK', LOGGER_HOT_KEY_MODIFIERS, handleClearLogger);
  useHotKey(true, 'KeyL', LOGGER_HOT_KEY_MODIFIERS, toggleLogger);

  useCustomTouch(CodeGesture.ToggleLogger, toggleLogger);
  useCustomTouch(CodeGesture.ClearLogger, handleClearLogger);

  if (!isVisible) {
    return null;
  }

  return (
    <Logger
      entries={ioStore.logEntries}
      onClearLogger={handleClearLogger}
      onCopyAllLogs={handleCopyAllLogs}
    />
  );
});
