import { memo } from 'react';

import styles from './scoreboard.module.less';

interface IScoreboardProps {
  isFinishedGame: boolean;
  gameStatus: string;
  homeTeamScore: number;
  visitorsTeamScore: number;
}

export const Scoreboard = memo((props: IScoreboardProps) => {
  const { isFinishedGame, gameStatus, homeTeamScore, visitorsTeamScore } = props;

  return (
    <div className={styles.Scoreboard}>
      <div className={styles.Scoreboard__Scrore}>
        {visitorsTeamScore} <span className={styles.Scoreboard__Separator}>-</span> {homeTeamScore}
      </div>
      <div className={styles.Scoreboard__Status}>
        {!isFinishedGame && <i className={styles.Scoreboard__Marker} />} {gameStatus}
      </div>
    </div>
  );
});
