import { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { IFollow } from 'stores/follow/interfaces/follow.interface';
import { ITeamsBySubsciption } from 'stores/teams/interfaces/teams-by-subscription.interface';

import { LEFT_SIDEBAR_ELEMENT } from 'configs/controls.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { BarActionType } from 'components/bars/bar-action.type';
import { SidebarCommunitiesList } from 'components/sidebars/sidebar-communities-list/sidebar-communities-list.component';
import { SidebarFollowingItem } from 'components/sidebars/sidebar-following-item/sidebar-following-item.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import { SidebarContactUs } from '../sidebar-contact-us/sidebar-contact-us.component';

import styles from './sidebar-drawer.module.less';

export interface ISidebarMobileProps {
  isAuthorized: boolean;
  isPulledRefresher: boolean;
  activePath: string;
  teamId: Maybe<number>;
  teams: ITeamsBySubsciption;
  teamName?: Maybe<string>;
  onClick: (action: BarActionType) => void;
  onCloseLeftSidePanel: () => void;
  followingPlayers: IFollow[];
}

export const SidebarDrawer: FC<ISidebarMobileProps> = memo((props: ISidebarMobileProps) => {
  const {
    onCloseLeftSidePanel,
    isAuthorized,
    isPulledRefresher,
    activePath,
    teamName,
    teams,
    teamId,
    followingPlayers,
  } = props;

  const navigate = useNavigate();

  const sidebarClassNames = useMemo(
    () =>
      cn(styles.SidebarDrawer, {
        [styles['SidebarDrawer--freezed']]: isPulledRefresher,
      }),
    [isPulledRefresher],
  );

  const handlePlayerClick = useCallback(
    (slug: string) => {
      onCloseLeftSidePanel();
      navigate(
        getPath(paths.PLAYER_PROFILE, {
          [paths.PLAYER_SLUG_PARAM]: slug,
        }),
      );
    },
    [navigate, onCloseLeftSidePanel],
  );

  const handleEditClick = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.ExploreEditFavorites,
        team: teamName || 'null',
      },
    });

    onCloseLeftSidePanel();
    navigate(paths.FOLLOWING);
  }, [teamName, navigate, onCloseLeftSidePanel]);

  return (
    <nav className={sidebarClassNames} id={LEFT_SIDEBAR_ELEMENT}>
      <div className={styles.SidebarDrawer__Content}>
        {!followingPlayers.length && !teams.joined.length && isAuthorized && (
          <div className={styles.SidebarDrawer__FollowButton}>
            <Button theme={ButtonTheme.Primary} size={ButtonSize.Small} onClick={handleEditClick}>
              Follow Teams & Players
            </Button>
          </div>
        )}
        {!!followingPlayers.length && (
          <>
            <div className={styles.SidebarDrawer__Navigation}>
              <h3
                className={styles.SidebarDrawer__Header}
              >{`Following Players (${followingPlayers.length})`}</h3>
              <Button theme={ButtonTheme.Text} size={ButtonSize.Big} onClick={handleEditClick}>
                Edit favorites
              </Button>
            </div>
            <div className={styles.SidebarDrawer__PlayersList}>
              {followingPlayers.map((item) => (
                <SidebarFollowingItem
                  key={item.id}
                  imageSrc={item.thumbnailUrl}
                  name={item.label}
                  onClick={handlePlayerClick}
                  slug={item.slug}
                />
              ))}
            </div>
          </>
        )}
        {!!teams.joined.length && (
          <>
            <div className={styles.SidebarDrawer__Navigation}>
              <h3 className={styles.SidebarDrawer__Header}>
                {`Following Teams (${teams.joined.length})`}
              </h3>
              {!followingPlayers.length && (
                <Button theme={ButtonTheme.Text} size={ButtonSize.Big} onClick={handleEditClick}>
                  Edit favorites
                </Button>
              )}
            </div>
            <div className={styles.SidebarDrawer__List}>
              <SidebarCommunitiesList
                isMobile
                isAuthorized={isAuthorized}
                activePath={activePath}
                teams={teams.joined}
                onClick={props.onClick}
                teamId={teamId}
              />
            </div>
          </>
        )}
        {!!teams.explore.length && (
          <>
            <h3 className={styles.SidebarDrawer__Header}>Explore</h3>
            <div className={styles.SidebarDrawer__List}>
              <SidebarCommunitiesList
                teamId={teamId}
                isMobile
                isNoJoined
                isAuthorized={isAuthorized}
                activePath={activePath}
                teams={teams.explore}
                onClick={props.onClick}
              />
            </div>
          </>
        )}
        {!isAuthorized && (
          <>
            <span className={styles.SidebarDrawer__Separator} />
            <div className={styles.SidebarDrawer__ContactUs}>
              <SidebarContactUs onClick={props.onClick} />
            </div>
          </>
        )}
      </div>
    </nav>
  );
});
