export enum StorageField {
  SignUpWithEmailPreserved = 'SignUpWithEmailPreserved',
  CountdownTimeSignUp = 'CountdownTimeSignUp',
  CountdownTimeForgotPassword = 'CountdownTimeForgotPassword',
  Session = 'Session',
  ForgotPasswordPreserved = 'ForgotPasswordPreserved',
  LastTeamId = 'LastTeamId',
  DeeplinkBanner = 'DeeplinkBanner',
  AppVersionState = 'AppVersionState',
  Followings = 'Followings',
  FirstTimeIntroShown = 'FirstTimeIntroShown',
  WelcomePlayerProfileShown = 'WelcomePlayerProfileShown',
  WelcomeFeedShown = 'WelcomeFeedShown',
  WelcomeArenaShown = 'WelcomeArenaShown',
  WelcomeTeamShown = 'WelcomeTeamShown',
  WelcomeRankingsShown = 'WelcomeRankingsShown',
  CurrentAppVersion = 'CurrentAppVersion',
  IsAppVersionChangeHandled = 'IsAppVersionChangeHandled',
}
