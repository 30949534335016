import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';

import { StatsFilter, StatsMode } from 'stores/global-stats/enums/global-stats.enums';
import { DEFAULT_GLOBAL_STATS_PLAYER_FILTERS } from 'stores/global-stats/global-stats.config';
import { GlobalStatsStore } from 'stores/global-stats/global-stats.store';
import { IGlobalStatsPlayersFilters } from 'stores/global-stats/interfaces/global-stats-players-filters.interface';
import { LayoutStore } from 'stores/layout/layout.store';
import { SeasonsStore } from 'stores/seasons/seasons.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { capitalizeFirstLetter } from 'helpers/string/capitalize-first-letter.util';
import * as paths from 'routes/paths.constants';

import { useGaScreenCustomView } from 'hooks/use-ga-screen-custom-view';

import { PAGE_PARAM } from 'components/base-table/tables.configs';
import { StatsHeader } from 'components/stats/stats-header/stats-header.component';

export const StatsHeaderContainer = observer(() => {
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const globalStatsStore = useInjection<GlobalStatsStore>(TYPES.GlobalStatsStore);
  const seasonsStore = useInjection<SeasonsStore>(TYPES.SeasonsStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { googleAnalyticsHomeFeed } = useGaScreenCustomView();

  const handleSeasonIdChange = useCallback(
    (seasonId: string) => {
      globalStatsStore.setSeasonId(seasonId);
      searchParams.delete(PAGE_PARAM);
      setSearchParams(searchParams, { replace: true });
    },
    [globalStatsStore, searchParams, setSearchParams],
  );

  const handlePlayersFiltersChange = useCallback(
    (filters: IGlobalStatsPlayersFilters) => {
      globalStatsStore.setPlayersFilters(filters);
      searchParams.delete(PAGE_PARAM);
      setSearchParams(searchParams, { replace: true });
    },
    [globalStatsStore, searchParams, setSearchParams],
  );

  const handleSwitcherClick = useCallback(
    (option: string) => {
      const searchValue = option === 'teams' ? 'players' : 'teams';
      const newPath = location.pathname.replace(searchValue, option);

      navigate(newPath);
    },
    [location.pathname, navigate],
  );

  const handleTitleButtonClick = useCallback(() => {
    const newPath = location.pathname.replace('/all', '');
    navigate(newPath);
  }, [location.pathname, navigate]);

  const handleResetButtonClick = useCallback(() => {
    globalStatsStore.setPlayersFilters(DEFAULT_GLOBAL_STATS_PLAYER_FILTERS);
    searchParams.delete(PAGE_PARAM);
    setSearchParams(searchParams, { replace: true });
  }, [globalStatsStore, searchParams, setSearchParams]);

  const title = useMemo(() => {
    if (location.pathname.includes(paths.ALL_PART)) {
      return `All ${capitalizeFirstLetter(globalStatsStore.mode)} Stats`;
    }

    return 'Top Leaders';
  }, [globalStatsStore.mode, location.pathname]);

  const isPlayersAllRoute = useMemo(
    () => location.pathname === paths.HOME_STATS_PLAYERS_ALL,
    [location.pathname],
  );

  useEffect(() => {
    if (location.pathname.includes(paths.TEAMS_PART)) {
      globalStatsStore.setMode(StatsMode.TEAMS);
    } else {
      globalStatsStore.setMode(StatsMode.PLAYERS);
    }

    if (location.pathname.includes(paths.ALL_PART)) {
      globalStatsStore.setFilter(StatsFilter.ALL_STATS);
    } else {
      globalStatsStore.setFilter(StatsFilter.LEADERS);
    }
  }, [globalStatsStore, location.pathname]);

  useEffect(() => {
    return () => {
      globalStatsStore.resetFilters();
    };
  }, [globalStatsStore]);

  useEffect(() => {
    googleAnalyticsHomeFeed(ScreenNamesEnum.HomeStats);
  }, [googleAnalyticsHomeFeed]);

  if (!globalStatsStore.seasonId) {
    return null;
  }

  return (
    <StatsHeader
      isPlayersAllRoute={isPlayersAllRoute}
      title={title}
      withTitleButton={globalStatsStore.filter === StatsFilter.ALL_STATS}
      activeSwitcherOption={globalStatsStore.mode}
      seasonId={globalStatsStore.seasonId}
      seasonsOptions={seasonsStore.seasonsSelectOptions}
      playersFilters={globalStatsStore.playersFilters}
      teamsOptions={teamsStatsStore.getTeamsSelectOptions('All Teams')}
      onSeasonIdChange={handleSeasonIdChange}
      onSwitcherClick={handleSwitcherClick}
      onTitleButtonClick={handleTitleButtonClick}
      onPlayersFiltersChange={handlePlayersFiltersChange}
      setDisabledScroll={layoutStore.setDisabledScroll}
      onResetClick={handleResetButtonClick}
    />
  );
});
