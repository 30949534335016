import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import styles from './timeouts.module.less';

interface ITimeoutsProps {
  total: number;
  used: number;
  reverseDirection: boolean;
}

export const Timeouts: FC<ITimeoutsProps> = memo((props: ITimeoutsProps) => {
  const { total, used, reverseDirection } = props;

  const rowClassNames = useMemo(
    () =>
      cn(styles.Row, {
        [styles['Row--reverse']]: reverseDirection,
      }),
    [reverseDirection],
  );

  return (
    <div className={rowClassNames}>
      {[...Array(total)].map((_, index) => (
        <div
          // because index value is real ket in this case
          /* eslint-disable react/no-array-index-key */
          key={index}
          className={styles.Dot}
          style={{
            opacity: index < used ? 1 : 0.3,
          }}
        />
      ))}
    </div>
  );
});
