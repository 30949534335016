import { FC, memo, useCallback, useMemo } from 'react';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Avatar, AvatarSize } from '../avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from '../button/button.component';
import { ColumnCharts, IColumnChartItem } from '../column-charts/column-charts.component';

import styles from './stats-leaders-card.module.less';

interface IStatsLeadersCardProps {
  leaderByLong: string;
  leaderByAbbreviation: string;
  leaderField: string;
  items: IColumnChartItem[];
  sortDirection?: string;
  onShowAllLeaders: (sortParam: string, direction: string) => void;
  onRowClick: (id: string) => void;
}

export const StatsLeadersCard: FC<IStatsLeadersCardProps> = memo(
  (props: IStatsLeadersCardProps) => {
    const {
      leaderByLong,
      leaderByAbbreviation,
      leaderField,
      items,
      sortDirection = 'desc',
      onShowAllLeaders,
      onRowClick,
    } = props;

    const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

    const sortedItems = useMemo(
      () =>
        items
          .slice()
          .sort((currentItem, nextItem) =>
            sortDirection === 'desc'
              ? nextItem.value - currentItem.value
              : currentItem.value - nextItem.value,
          ),
      [items, sortDirection],
    );

    const handleShowAllLeaders = useCallback(() => {
      onShowAllLeaders(leaderField, sortDirection);
    }, [leaderField, sortDirection, onShowAllLeaders]);

    const handleRowClick = useCallback(
      (id: string) => () => {
        onRowClick(id);
      },
      [onRowClick],
    );

    if (!sortedItems.length) {
      return null;
    }

    return (
      <div className={styles.StatsLeadersCard}>
        <div className={styles.StatsLeadersCard__Header}>
          <div>{leaderByLong}</div>
          <div>
            {isDesktopPlus ? (
              leaderByAbbreviation
            ) : (
              <Button
                onClick={handleShowAllLeaders}
                theme={ButtonTheme.Text}
                size={ButtonSize.Small}
              >
                See More
              </Button>
            )}
          </div>
        </div>

        <div className={styles.StatsLeadersCard__Main}>
          <div className={styles.StatsLeadersCard__Chart}>
            <ColumnCharts sortedItems={sortedItems} />
          </div>
          <ol className={styles.StatsLeadersCard__List}>
            {sortedItems.map((item) => (
              <li key={item.name} className={styles.StatsLeadersCard__Item}>
                <button
                  className={styles.StatsLeadersCard__ItemButton}
                  onClick={handleRowClick(item.id)}
                >
                  <div className={styles.StatsLeadersCard__AvatarAndName}>
                    <Avatar size={AvatarSize.S} username={item.name} src={item.avatarUrl} />
                    <div>{item.name}</div>
                  </div>
                  <div className={styles.StatsLeadersCard__ItemValue}>{item.value}</div>
                </button>
              </li>
            ))}
          </ol>
        </div>

        {isDesktopPlus && (
          <div className={styles.StatsLeadersCard__Button}>
            <Button
              fluid
              onClick={handleShowAllLeaders}
              theme={ButtonTheme.Secondary}
              size={ButtonSize.SmallSecondary}
            >
              See More
            </Button>
          </div>
        )}
      </div>
    );
  },
);
