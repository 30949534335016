import { useCallback, useRef, useState } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ISearchPlayerItem, ISearchTeamItem } from 'stores/search/interfaces/search.interface';
import { SearchScreen, SearchStore } from 'stores/search/search.store';

import { GlobalSearchInputContainer } from 'containers/global-search-input/global-search-input.container';

import { TYPES } from 'configs/di-types.config';

import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { useSearchItemClick } from 'hooks/use-search-item-click';

import { SearchFilters } from 'components/global-search/global-search.config';
import { RecentSearch } from 'components/global-search/recent-search/recent-search.component';
import { SuggestionList } from 'components/global-search/suggestion-list/suggestion-list.component';

import styles from './global-search-header-desktop.module.less';

export const GlobalSearchHeaderDesktopContainer = observer(() => {
  const searchStore = useInjection<SearchStore>(TYPES.SearchStore);

  const wrapperRef = useRef<Maybe<HTMLDivElement>>(null);

  const handleSearchItemClick = useSearchItemClick();

  const [isShowList, setIsShowList] = useState(false);

  const handleFocus = useCallback(() => {
    if (searchStore.query.searchPhrase.length > 1) {
      searchStore.setScreen(SearchScreen.Suggestion);
    } else {
      searchStore.setScreen(SearchScreen.RecentHistory);
    }

    searchStore.setFilter(SearchFilters.ALL);
    setIsShowList(true);
  }, [searchStore]);

  const handleBlur = useCallback(() => {
    setIsShowList(false);
  }, []);

  const handleRecentItemClick = useCallback(
    (text: string) => {
      searchStore.setFetching(true);
      searchStore.setScreen(SearchScreen.SearchResults);
      searchStore.setQuery({ searchPhrase: text });
    },
    [searchStore],
  );

  const handleDeleteRecentItemClick = useCallback(
    (id: number) => {
      searchStore.deleteRecentItem(id.toString());
    },
    [searchStore],
  );

  const handleItemClick = useCallback(
    (options: ISearchTeamItem | ISearchPlayerItem) => {
      handleBlur();
      handleSearchItemClick(options);
    },
    [handleBlur, handleSearchItemClick],
  );

  useOnClickOutside(wrapperRef, handleBlur);

  return (
    <div ref={wrapperRef} className={styles.GlobalSearchHeaderDesktop}>
      <GlobalSearchInputContainer isEnableSearchButton={false} onFocusCallback={handleFocus} />
      {isShowList && searchStore.screen === SearchScreen.RecentHistory && (
        <RecentSearch
          items={searchStore.recentSearch}
          onItemClick={handleRecentItemClick}
          onDeleteItem={handleDeleteRecentItemClick}
        />
      )}
      {isShowList && searchStore.screen === SearchScreen.Suggestion && (
        <SuggestionList
          fetched={searchStore.fetched}
          loading={searchStore.fetching}
          searchValue={searchStore.query.searchPhrase}
          items={searchStore.preparedSearchItems}
          forHeader
          onItemClick={handleItemClick}
        />
      )}
    </div>
  );
});
