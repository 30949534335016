import { memo, useMemo } from 'react';

import { COMMUNITY_HEADER_INFO_GROUPS } from 'configs/context-menu-groups.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { BarActionType } from 'components/bars/bar-action.type';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { ContextMenuTooltip } from 'components/ui/context-menu-tooltip/context-menu-tooltip.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './community-header.module.less';

interface ICommunityHeaderProps {
  isJoined: Maybe<boolean>;
  processing: boolean;
  avatarSrc: Maybe<string>;
  name: string;
  teamTextColor: Maybe<string>;
  onButtonClick: () => void;
  onContextItemClick: (barAction: BarActionType) => void;
}

export const CommunityHeader = memo((props: ICommunityHeaderProps) => {
  const { isJoined, processing, avatarSrc, name, teamTextColor } = props;

  const textColor = useMemo(() => {
    if (teamTextColor) {
      return {
        color: teamTextColor,
      };
    }

    return {};
  }, [teamTextColor]);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  return (
    <div className={styles.CommunityHeader}>
      <div className={styles.CommunityHeader__Avatar}>
        <Avatar src={avatarSrc} size={AvatarSize.S} username={name} />
      </div>
      <span style={textColor} className={styles.CommunityHeader__Name}>
        {name}
      </span>
      <div className={styles.CommunityHeader__Button}>
        {!isJoined && (
          <Button
            disabled={processing}
            size={ButtonSize.Small}
            theme={ButtonTheme.Primary}
            onClick={props.onButtonClick}
          >
            Follow
          </Button>
        )}
        {isDesktopPlus && isJoined && (
          <ContextMenuTooltip
            groups={COMMUNITY_HEADER_INFO_GROUPS}
            onItemClick={props.onContextItemClick}
            toggleOnClick
            tooltipProps={{ tooltipOffset: 8, placement: 'bottom-end' }}
          >
            <IconButton
              iconName={IconFontName.More}
              theme={IconButtonTheme.Secondary}
              customColor={teamTextColor}
            />
          </ContextMenuTooltip>
        )}
      </div>
    </div>
  );
});
