import { memo, useMemo } from 'react';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import styles from './game-header.module.less';

interface IGameHeaderProps {
  gameStatus: GameStatus;
  time: string;
  quarter: string;
}

export const GameHeader = memo((props: IGameHeaderProps) => {
  const { time, quarter, gameStatus } = props;

  const isLive = useMemo(
    () => gameStatus === GameStatus.Halftime || gameStatus === GameStatus.Live,
    [gameStatus],
  );

  return (
    <div className={styles.GameHeader}>
      {gameStatus !== GameStatus.Finished && (
        <div className={styles.GameHeader__Time}>
          {isLive && <span>{quarter}</span>}
          <span>{time}</span>
        </div>
      )}
      {gameStatus === GameStatus.Finished && <div className={styles.GameHeader__Status}>Final</div>}
      {isLive && <span className={styles.GameHeader__Live}>live</span>}
    </div>
  );
});
