import { memo, useMemo } from 'react';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { ContextMenu } from 'components/ui/context-menu/context-menu.component';
import { IContextMenuGroup } from 'components/ui/context-menu/interfaces/context-menu-group.interface';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './comment-context-tooltip.module.less';

interface ICommentContextTooltipProps {
  onItemClick: (barAction: BarActionType) => void;
  isBookmarked: boolean;
  isCurrentUserComment: boolean;
}

export const CommentContextTooltip = memo((props: ICommentContextTooltipProps) => {
  const { isBookmarked, isCurrentUserComment } = props;

  const groups: IContextMenuGroup[] = useMemo(() => {
    const result: IContextMenuGroup[] = [
      {
        id: 1,
        group: [
          {
            icon: IconFontName.Bookmark,
            text: 'Bookmark',
            actionType: { type: BarAction.Click, payload: 'bookmark' },
            isDisabled: false,
            isActive: isBookmarked,
            iconActive: IconFontName.BookmarkFilled,
          },
          {
            icon: IconFontName.Share,
            text: 'Share',
            actionType: { type: BarAction.Click, payload: 'share' },
            isDisabled: false,
          },
        ],
      },
    ];

    if (!isCurrentUserComment) {
      result[0].group.push({
        icon: IconFontName.Report,
        text: 'Report Comment',
        actionType: { type: BarAction.Click, payload: 'report' },
        isDisabled: false,
      });
    }

    if (!isCurrentUserComment) {
      result[0].group.push({
        icon: IconFontName.ProfileBlocked,
        text: 'Report User',
        actionType: { type: BarAction.Click, payload: 'user-report' },
        isDisabled: false,
      });
    }

    if (isCurrentUserComment) {
      result[0].group.push({
        icon: IconFontName.Remove,
        text: 'Delete',
        actionType: { type: BarAction.Click, payload: 'delete' },
        isDisabled: false,
        isActive: false,
      });
    }
    return result;
  }, [isBookmarked, isCurrentUserComment]);

  return (
    <div className={styles.CommentContextTooltip}>
      <ContextMenu onItemClick={props.onItemClick} groups={groups} />
    </div>
  );
});
