import { memo } from 'react';

import { ITeam } from 'stores/teams/interfaces/team.interface';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './author-card-more.module.less';

type UserTeamType = Pick<ITeam, 'teamId' | 'thumbnailUrl' | 'name'>;

interface IFriend {
  src?: string;
  username: string;
}

interface IUserTeams {
  common?: UserTeamType[];
  other?: UserTeamType[];
}

export interface IAuthorCardMoreProps {
  userAbout?: string;
  userMutualFriends?: IFriend[];
  userTeams?: IUserTeams;
}

export const AuthorCardMore = memo((props: IAuthorCardMoreProps) => {
  const { userAbout, userMutualFriends, userTeams } = props;

  return (
    <div className={styles.AuthorCardMore}>
      {userAbout && (
        <>
          <div className={styles.AuthorCardMore__Heading}>About me</div>
          <div className={styles.AuthorCardMore__About}>{userAbout}</div>
        </>
      )}
      {userMutualFriends && (
        <>
          <div className={styles.AuthorCardMore__Heading}>Mutual Friends</div>
          <div className={styles.AuthorCardMore__Friends}>
            {userMutualFriends.map(({ src: friendSrc, username: friendUsername }) => (
              <Avatar
                key={`${friendSrc}_${friendUsername}`}
                username={friendUsername}
                size={AvatarSize.S}
                src={friendSrc}
              />
            ))}
          </div>
        </>
      )}
      {userTeams && (
        <>
          <div className={styles.AuthorCardMore__Heading}>Teams</div>
          <div className={styles.AuthorCardMore__Communities}>
            {userTeams.common && (
              <div className={styles['AuthorCardMore__Communities--common']}>
                {userTeams.common.map((team) => (
                  <div>
                    <img key={team.teamId} src={team.thumbnailUrl || ''} alt={team.name} />
                  </div>
                ))}
              </div>
            )}
            {userTeams.other && (
              <div className={styles['AuthorCardMore__Communities--other']}>
                {userTeams.other.map((team) => (
                  <img key={team.teamId} src={team.thumbnailUrl || ''} alt={team.name} />
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
});
