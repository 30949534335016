import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import { ENCODED_DASH_SYMBOL } from 'configs/encoded-symbols.config';

import styles from './game-scores.module.less';

export interface IGamesScoresProps {
  visitorsPoints: string;
  status: GameStatus;
  homePoints: string;
  homeIsWinning: boolean;
  formattedPeriod: string;
  clockFormatted: string;
}

export const GamesScores: FC<IGamesScoresProps> = memo((props: IGamesScoresProps) => {
  const { status, visitorsPoints, homePoints, formattedPeriod, homeIsWinning, clockFormatted } =
    props;

  const visitorsClasses = useMemo(
    () =>
      cn(styles.Score__Team, {
        [styles['Score__Team--winning']]: !homeIsWinning && status !== GameStatus.Scheduled,
        [styles['Score__Team--win']]: !homeIsWinning && status === GameStatus.Finished,
      }),
    [homeIsWinning, status],
  );

  const homeClasses = useMemo(
    () =>
      cn(styles.Score__Team, {
        [styles['Score__Team--winning']]: homeIsWinning && status !== GameStatus.Scheduled,
        [styles['Score__Team--win']]: homeIsWinning && status === GameStatus.Finished,
      }),
    [homeIsWinning, status],
  );

  const quarterText = useMemo(() => {
    if (status === GameStatus.Halftime) {
      return 'Halftime';
    }

    if (status === GameStatus.Finished) {
      return 'Final';
    }

    if (status === GameStatus.Scheduled) {
      return '';
    }

    return `${formattedPeriod} ${clockFormatted}`;
  }, [formattedPeriod, status, clockFormatted]);

  return (
    <div className={styles.Scores}>
      <div className={styles.Score}>
        <span className={visitorsClasses}>{visitorsPoints}</span>
        <span className={styles.Score__Separator}>{ENCODED_DASH_SYMBOL}</span>
        <span className={homeClasses}>{homePoints}</span>
      </div>
      <div className={styles.Time}>
        <span>{quarterText}</span>
      </div>
    </div>
  );
});
