import { ColDef } from 'ag-grid-community';

export const RANK_COL_DEF: ColDef = {
  field: 'rank',
  headerName: 'RK',
  headerTooltip: 'Rank',
  pinned: 'left',
  maxWidth: 44,
};

export const PAGE_PARAM = 'page';
