import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionClick } from 'components/bars/bar-action.type';
import { ITab } from 'components/ui/tabs/tabs.component';

export enum SearchFilters {
  ALL = 'All',
  TEAM = 'Team',
  PLAYER = 'Player',
}

export const SEARCH_TABS: ITab<BarActionClick<SearchFilters>>[] = [
  {
    id: SearchFilters.ALL,
    title: 'All',
    action: {
      type: BarAction.Click,
      payload: SearchFilters.ALL,
    },
  },
  {
    id: SearchFilters.TEAM,
    title: 'Teams',
    action: {
      type: BarAction.Click,
      payload: SearchFilters.TEAM,
    },
  },
  {
    id: SearchFilters.PLAYER,
    title: 'Players',
    action: {
      type: BarAction.Click,
      payload: SearchFilters.PLAYER,
    },
  },
];
