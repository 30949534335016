import { memo, ReactNode, useCallback } from 'react';
import cn from 'classnames';

import { IGlossaryEntity } from './interfaces/glossary-entity.interface';

import styles from './glossary.module.less';

interface IGlossaryProps {
  entities: IGlossaryEntity[];
  title?: string;
}

export const Glossary = memo((props: IGlossaryProps) => {
  const { entities, title } = props;

  const itemClassNames = useCallback(
    (leftNode: ReactNode) =>
      cn(styles.Glossary__Item, {
        [styles['Glossary__Item--icon']]: typeof leftNode !== 'string',
      }),
    [],
  );

  return (
    <div className={styles.Glossary}>
      {title && <div className={styles.Glossary__Title}>{title}</div>}
      <div className={styles.Glossary__Content}>
        {entities.map((entity) => (
          <div key={entity.description} className={itemClassNames(entity.leftNode)}>
            <span className={styles.GlossaryItem__Left}>{entity.leftNode}</span>
            {entity.description}
          </div>
        ))}
      </div>
    </div>
  );
});
