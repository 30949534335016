import { injectable } from 'inversify';
import { action, computed, makeObservable, observable, reaction } from 'mobx';

import CrashlyticsService from 'services/crashlytics/crashlytics.service';

import {
  ShowErrorCallback,
  ShowErrorType,
} from 'stores/notifications/interfaces/notifications.interface';

@injectable()
export class NotificationsStore {
  private ERROR_NOTIFICATION_DELAY = 7000;

  public errors: INotificationMessage[] = [];

  public successes: INotificationMessage[] = [];

  public showError: ShowErrorType = null;

  constructor() {
    makeObservable(this, {
      errors: observable,
      successes: observable,

      firstError: computed,

      showError: observable,
      setErrors: action.bound,
      setSuccesses: action.bound,
      shiftError: action.bound,

      setErrorMethod: action.bound,
    });

    reaction(() => this.errors, this.showErrorReactions);
    reaction(() => this.successes, this.showSuccessReactions);
  }

  public get firstError(): Maybe<INotificationMessage> {
    return this.errors.length ? this.errors[0] : null;
  }

  private prepareMessageNotifications(
    notifications: INotificationMessage[],
    delay: number = this.ERROR_NOTIFICATION_DELAY,
  ): INotificationMessage[] {
    if (delay) {
      const preparedNotifications: INotificationMessage[] = [];
      notifications.forEach((item) => {
        const notification = {
          ...item,
          delay,
        };
        preparedNotifications.push(notification);
      });
      return preparedNotifications;
    }

    return notifications;
  }

  public setErrors(
    notifications: INotificationMessage[],
    delay: number = this.ERROR_NOTIFICATION_DELAY,
  ): void {
    this.errors = this.prepareMessageNotifications(notifications, delay);
  }

  public setSuccesses(
    notifications: INotificationMessage[],
    delay: number = this.ERROR_NOTIFICATION_DELAY,
  ): void {
    this.successes = this.prepareMessageNotifications(notifications, delay);
  }

  private showErrorReactions = () => {
    this.errors.forEach((error) => {
      if (this.showError) {
        const message =
          typeof error.message === 'string' ? error.message : error.message.constraint;

        this.showError('Error', message, error.delay);

        CrashlyticsService.recordException(message);
      }
    });
  };

  private showSuccessReactions = () => {
    this.successes.forEach((success) => {
      if (this.showError) {
        const message =
          typeof success.message === 'string' ? success.message : success.message.constraint;

        this.showError('Success', message, success.delay);
      }
    });
  };

  public reset() {
    this.setErrors([]);
    this.setSuccesses([]);
  }

  public resetErrors(): void {
    this.setErrors([]);
    this.setSuccesses([]);
  }

  public setErrorMethod(callback: ShowErrorCallback): void {
    this.showError = callback;
  }

  public shiftError(): void {
    const nextErrors = this.errors.slice(1);

    setTimeout(() => {
      this.setErrors([...nextErrors]);
    }, 0);

    this.setErrors([]);
  }
}
