export function arrayFilterUniqueByKey<T>(arr: T[], key: keyof T): T[] {
  const seen = new Set<T[keyof T]>();
  return arr.filter((obj) => {
    const value = obj[key];
    if (!seen.has(value)) {
      seen.add(value);
      return true;
    }
    return false;
  });
}
