import { StatsPropertyAbbreviationsType } from 'helpers/get-stats-property-abbreviation-by-key.util';

export const STATS_ABBRS: StatsPropertyAbbreviationsType[] = [
  'points',
  'rebounds',
  'assists',
  'blocks',
  'steals',
  'minutes',
  'plusMinus',
  'tsp',
  'fgm',
  'fga',
  'fgp',
  'tpm',
  'tpa',
  'tpp',
  'ftm',
  'fta',
  'ftp',
  'offReb',
  'defReb',
  'turnovers',
  'personalFouls',
  'fantasyPoints',
];

export const GAME_ROW_COMPACT_HEIGHT = 4.6;
export const GAME_ROW_HEIGHT = 6.8;
