import { getPollOptions } from 'stores/posts/adapters/poll-adapter.util';

import { SharedType } from '../enums/share-type.enum';
import { ShareData } from '../types/share-data.type';
import { ShareResponse } from '../types/share-response.type';

import { shareAttachmentsAdapter } from './attachments-count-adapter.util';
import { gameInShareAdapter } from './game-in-share-adapter.util';

export const shareAdapter = (shareResponse: ShareResponse): ShareData => {
  const { type, count, link } = shareResponse;

  if (type === SharedType.Game) {
    return {
      type,
      count,
      link,
      game: gameInShareAdapter(shareResponse.game),
    };
  }

  if (type === SharedType.PlayersRanking) {
    return {
      type,
      count,
      link,
    };
  }

  if (type === SharedType.PlayersCategoryRanking) {
    return {
      type,
      count,
      link,
    };
  }

  if (type === SharedType.PlayerPerformance) {
    return {
      type,
      count,
      link,
    };
  }

  return {
    type,
    count,
    link,
    title: shareResponse.title,
    content: shareResponse.content,
    attachmentCounts: shareAttachmentsAdapter(shareResponse.attachment_counts),
    pollOptions: shareResponse.poll_options
      ? getPollOptions(shareResponse.poll_options)
      : undefined,
  };
};
