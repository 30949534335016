import { useEffect } from 'react';
import { TPlatformName } from 'types/platform-name.type';

export const usePlatformNameClass = (platformName: TPlatformName): void => {
  useEffect(() => {
    if (platformName === 'android') {
      document.body.classList.add('android-platform');
    }

    if (platformName === 'ios') {
      document.body.classList.add('ios-platform');
    }
  }, [platformName]);
};
