import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE, DEFAULT_VALIDATION_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { SubmitSection } from 'components/forms/submit-section/submit-section.component';
import { TextInput } from 'components/ui/form-fields/text-input/text-input.component';
import { userNameSchema, realNameSchema } from 'validation/schemas/name.schema';
import { useResponsive } from 'hooks/use-responsive';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import formsStyles from '../forms.module.less';

const editProfileFormSchema = z
  .object({
    username: userNameSchema,
    realname: realNameSchema,
  })
  .strict();

export type EditProfileFormDataType = z.infer<typeof editProfileFormSchema>;

export type EditProfileFormFieldsType = keyof EditProfileFormDataType;

interface IEditProfileFormProps extends IForm<EditProfileFormDataType> {
  focusField?: Maybe<EditProfileFormFieldsType>;
}

export const EditProfileForm: FC<IEditProfileFormProps> = (props) => {
  const { submitText, initialValues, focusField, onSubmit } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const { control, formState, handleSubmit, setFocus } = useForm({
    defaultValues: initialValues,
    mode: DEFAULT_VALIDATION_MODE,
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(editProfileFormSchema),
  });

  const { isValid, errors } = formState;

  const isSubmitSectionVisible = useMemo(
    () => isDesktopPlus || formState.isDirty,
    [formState.isDirty, isDesktopPlus],
  );

  useEffect(() => {
    if (focusField) {
      setFocus(focusField);
    }
  }, [focusField, setFocus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={formsStyles.FormContent}>
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.username?.message}
              id="username"
              label="Username"
              placeholder="Username"
            />
          )}
        />
        <Controller
          name="realname"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.realname?.message}
              id="realname"
              label="Real Name"
              placeholder="Real Name"
            />
          )}
        />
      </div>
      {isSubmitSectionVisible && (
        <SubmitSection
          disabled={!formState.isDirty}
          isFormValid={isValid}
          buttonText={submitText}
        />
      )}
    </Form>
  );
};
