import { FC } from 'react';
import { Outlet } from 'react-router';
import { StatsHeaderContainer } from 'containers/stats/stats-header.container';

import { Row, RowLayout } from 'components/ui/grid/row/row.component';
import { Column } from 'components/ui/grid/column/column.component';
import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';

export const HomeStatsRoute: FC = () => {
  return (
    <PageWrapperContainer>
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <StatsHeaderContainer />
          <Outlet />
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
