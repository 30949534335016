import { FC, memo, useCallback } from 'react';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './auth-buttons.module.less';

interface IAuthButtonsProps {
  onSignIn: () => void;
  onSignUp: () => void;
}

export const AuthButtons: FC<IAuthButtonsProps> = memo((props: IAuthButtonsProps) => {
  const { onSignIn, onSignUp } = props;

  const handleSignIn = useCallback(() => {
    onSignIn();
  }, [onSignIn]);

  const handleSignUp = useCallback(() => {
    onSignUp();
  }, [onSignUp]);

  return (
    <div className={styles.AuthButtons}>
      <Button onClick={handleSignIn} size={ButtonSize.Small} theme={ButtonTheme.Secondary}>
        Sign In
      </Button>
      <Button onClick={handleSignUp} size={ButtonSize.Small} theme={ButtonTheme.Primary}>
        Sign Up
      </Button>
    </div>
  );
});
