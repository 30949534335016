import { FC, memo, useCallback, useRef } from 'react';

import {
  ConvertImageItemToAttachmentType,
  IUploadImageData,
} from 'services/application/interfaces/upload-image.interface';

import { useMainProvider } from 'hooks/use-main-provider';

import { PhotoCameraFieldWithBackendProcessing } from 'components/forms/photo-camera-field-with-backend-processing/photo-camera-field-with-backend-processing.component';
import { FileInput } from 'components/ui/form-fields/file-input/file-input.component';
import { IHookFormInput } from 'components/ui/form-fields/hook-form-input.interface';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export interface IPostImageInputProps extends IHookFormInput {
  accept?: string;
  isDisabled: boolean;
  handleError?: (error: string) => void;
  onImageLoad: (fileData: IUploadImageData[]) => void;
  setLoadingImages: (value: string[]) => void;
  convertImageItemToAttachment: ConvertImageItemToAttachmentType;
}

export const PostImageInput: FC<IPostImageInputProps> = memo((props: IPostImageInputProps) => {
  const { handleError, id, name, accept, isDisabled } = props;

  const inputClickHandlerRef = useRef<Maybe<() => void>>();

  const { isNativeApp } = useMainProvider();

  const handleConnectRef = useCallback((handler?: () => void) => {
    if (handler) {
      inputClickHandlerRef.current = handler;
    }
  }, []);

  const handlePostImageClick = useCallback(() => {
    if (!isDisabled && inputClickHandlerRef.current) {
      inputClickHandlerRef.current();
    }
  }, [isDisabled]);

  return (
    <div role="button" tabIndex={0} onClick={handlePostImageClick} onKeyDown={handlePostImageClick}>
      <IconButton
        theme={IconButtonTheme.Secondary}
        iconName={IconFontName.Image}
        disabled={isDisabled}
      />
      <PhotoCameraFieldWithBackendProcessing
        enabledFunction={isNativeApp}
        setLoadingImages={props.setLoadingImages}
        convertImageItemToAttachment={props.convertImageItemToAttachment}
      >
        <FileInput
          convertImageItemToAttachment={props.convertImageItemToAttachment}
          onBlur={props.onBlur}
          onChange={props.onImageLoad}
          onError={handleError}
          onAddFilesSet={handleConnectRef}
          id={id}
          name={name}
          accept={accept}
          multiple
          setLoadingImages={props.setLoadingImages}
        />
      </PhotoCameraFieldWithBackendProcessing>
    </div>
  );
});
