import { CapacitorHttp, HttpHeaders, HttpOptions, HttpResponse } from '@capacitor/core';
import { v4 } from 'uuid';

import { HttpOptionsType } from 'services/http/interfaces/http-options.interface';
import { parseResponseError } from 'services/http/utils/parse-response-errors.util';

export async function transport<T = undefined, V = undefined>(
  options: HttpOptionsType<V>,
): Promise<IResponse<T>> {
  const refinedHeaders: HttpHeaders = options.headers || {};

  if (options.body && options.isMultipart) {
    const boundary = v4();

    refinedHeaders['Content-Type'] = `multipart/form-data; boundary=${boundary}`;
  }

  if (options.body && !options.isMultipart) {
    refinedHeaders['Content-Type'] = 'application/json';
  }

  const endpoint = `${Object.entries(options.routeParams || {}).reduce(
    (result, [key, value]) => result.replaceAll(`:${key}`, value),
    options.url,
  )}${options.params ? `?${options.params.toString()}` : ''}`;

  const httpOptions: HttpOptions = {
    url: endpoint,
    headers: refinedHeaders,
    data: options.isMultipart ? options.body : JSON.stringify(options.body),
    method: options.method || 'POST',
  };

  if (options.body && options.isMultipart) {
    httpOptions.dataType = 'formData';
  }

  let responseCode: Maybe<number> = null;

  try {
    const response: HttpResponse = await CapacitorHttp.request(httpOptions);

    responseCode = response.status;

    const success: boolean = !!responseCode && /^2\d{2}$/.test(String(responseCode));

    if (success) {
      return {
        success: true,
        code: responseCode,
        data: response.data,
      };
    }

    return {
      success: false,
      code: responseCode,
      errors: parseResponseError(response.data),
    };
  } catch (e: unknown) {
    const errors = parseResponseError(e);

    return {
      success: false,
      errors,
      code: responseCode,
    };
  }
}
