import { PlayerRankingsCategoryEnum } from 'services/player-rankings/interfaces/player-rankings-response.interface';

import { StatsPropertyAbbreviationsType } from 'helpers/get-stats-property-abbreviation-by-key.util';

const fullStatsList: StatsPropertyAbbreviationsType[] = [
  'minutes',
  'points',
  'rebounds',
  'assists',
  'blocks',
  'steals',
  'plusMinus',
  'tsp',
  'fgm',
  'fga',
  'fgp',
  'tpm',
  'tpa',
  'tpp',
  'ftm',
  'fta',
  'ftp',
  'offReb',
  'defReb',
  'turnovers',
  'personalFouls',
  'fantasyPoints',
];

const shortStatsList: StatsPropertyAbbreviationsType[] = [
  'fantasyPoints',
  'minutes',
  'points',
  'rebounds',
  'assists',
  'blocks',
  'steals',
  'turnovers',
  'fantasyPoints',
];

interface IRankingConfigItem {
  key: PlayerRankingsCategoryEnum;
  label: string;
  statsList: StatsPropertyAbbreviationsType[];
}

export const RANKING_CATEGORIES: IRankingConfigItem[] = [
  {
    key: PlayerRankingsCategoryEnum.performers,
    label: 'Top Performers',
    statsList: shortStatsList,
  },
  {
    key: PlayerRankingsCategoryEnum.points,
    label: 'Points',
    statsList: [PlayerRankingsCategoryEnum.points, ...fullStatsList],
  },
  {
    key: PlayerRankingsCategoryEnum.rebounds,
    label: 'Rebounds',
    statsList: [PlayerRankingsCategoryEnum.rebounds, ...fullStatsList],
  },
  {
    key: PlayerRankingsCategoryEnum.assists,
    label: 'Assists',
    statsList: [PlayerRankingsCategoryEnum.assists, ...fullStatsList],
  },
  {
    key: PlayerRankingsCategoryEnum.steals,
    label: 'Steals',
    statsList: [PlayerRankingsCategoryEnum.steals, ...fullStatsList],
  },
  {
    key: PlayerRankingsCategoryEnum.blocks,
    label: 'Blocks',
    statsList: [PlayerRankingsCategoryEnum.blocks, ...fullStatsList],
  },
];

export const NO_RANKINGS_TITLE = 'Service currently not available';
export const NO_RANKINGS_MESSAGE = 'Try it later';
export const TOP_PERFORMERS_HINT_MESSAGE =
  'Top Performers are calculated based on 6 statistical categories and converting them into Fantasy Points. Digits uses the industry standard for Fantasy Points.';
export const TOP_PERFORMERS_HINT_ITEMS = [
  {
    title: 'Points',
    description: '= 1.0 fantasy point',
  },
  {
    title: 'Rebounds',
    description: '= 1.2 fantasy points',
  },
  {
    title: 'Assists',
    description: '= 1.5 fantasy points',
  },
  {
    title: 'Steals',
    description: '= 3.0 fantasy points',
  },
  {
    title: 'Blocks',
    description: '= 3.0 fantasy points',
  },
  {
    title: 'Turnovers',
    description: '= -1.0 fantasy points',
  },
];
