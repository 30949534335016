export const base64ToFile = (
  base64Data: string,
  contentType: string = 'jpeg',
  filename: string = 'default',
) => {
  const type = `image/${contentType}`;
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type });

  return new File([blob], filename, { type });
};
