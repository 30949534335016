import { FC, SyntheticEvent, useCallback } from 'react';
import { useNavigate } from 'react-router';

import { PolicyNavigationTrigger } from 'containers/privacy-policy/enums/policy-navigation-trigger.enum';

import * as paths from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';

import { SingleCheckbox } from 'components/ui/form-fields/single-checkbox/single-checkbox.component';

import styles from './confirm-email-checkbox.module.less';

interface IConfirmEmailCheckboxProps {
  id: string;
  selected: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

export const ConfirmEmailCheckbox: FC<IConfirmEmailCheckboxProps> = (props) => {
  const { onChange, ...checkboxProps } = props;

  const navigate = useNavigate();

  const { isNativeApp } = useMainProvider();

  const handleTermOfUseClick = useCallback(
    (event: SyntheticEvent) => {
      if (isNativeApp) {
        event.preventDefault();
        navigate(paths.TERMS_OF_USE, {
          state: {
            trigger: PolicyNavigationTrigger.Auth,
          },
        });
      }
    },
    [isNativeApp, navigate],
  );

  const handlePrivacyPolicyClick = useCallback(
    (event: SyntheticEvent) => {
      if (isNativeApp) {
        event.preventDefault();
        navigate(paths.PRIVACY_POLICY, {
          state: {
            trigger: PolicyNavigationTrigger.Auth,
          },
        });
      }
    },
    [isNativeApp, navigate],
  );

  const handleUserAgreementClick = useCallback(
    (event: SyntheticEvent) => {
      if (isNativeApp) {
        event.preventDefault();
        navigate(paths.USER_AGREEMENT, {
          state: {
            trigger: PolicyNavigationTrigger.Auth,
          },
        });
      }
    },
    [isNativeApp, navigate],
  );

  const handleChange = useCallback(
    (value: string) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  return (
    <div className={styles.ConfirmEmailCheckbox}>
      <SingleCheckbox onChange={handleChange} {...checkboxProps} />
      <div className={styles.ConfirmEmailCheckbox__Label}>
        By signing up, you agree to the
        <a
          href={paths.TERMS_OF_USE}
          target="_blank"
          rel="noreferrer"
          onClick={handleTermOfUseClick}
          className={styles.ConfirmEmailCheckbox__Link}
        >
          Terms of Use,
        </a>
        <a
          href={paths.PRIVACY_POLICY}
          target="_blank"
          rel="noreferrer"
          onClick={handlePrivacyPolicyClick}
          className={styles.ConfirmEmailCheckbox__Link}
        >
          Privacy Policy
        </a>
        and the
        <a
          href={paths.USER_AGREEMENT}
          target="_blank"
          rel="noreferrer"
          onClick={handleUserAgreementClick}
          className={styles.ConfirmEmailCheckbox__Link}
        >
          End User License Agreement
        </a>
      </div>
    </div>
  );
};
