import { Clipboard } from '@capacitor/clipboard';
import format from 'date-fns/format';
import { injectable } from 'inversify';
import { inject } from 'inversify/lib/annotation/inject';
import { networkLogger } from 'loggers/network.logger';
import { action, autorun, makeObservable, observable, reaction } from 'mobx';
import io, { Socket } from 'socket.io-client';
import { v4 } from 'uuid';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';

import { TYPES } from 'configs/di-types.config';
import { IO_HOST_URL } from 'configs/environment.config';
import { wait } from 'helpers/wait.util';

import { IoLogColor } from './enums/io-log-color.enum';
import { IoMessage } from './enums/io-message.enum';
import { IIoLogEntry } from './interfaces/io-log-entry.interace';

@injectable()
export class IoStore {
  private readonly SOCKET_RECONNECT_TIMEOUT_MS = 5 * 1000; // 5 seconds

  private readonly applicationStore: ApplicationStore;

  private readonly authStore: AuthStore;

  public socket: Socket;

  public isConnected: Maybe<boolean>;

  public logEntries: IIoLogEntry[];

  constructor(
    @inject(TYPES.AuthStore) authStore: AuthStore,
    @inject(TYPES.ApplicationStore) applicationStore: ApplicationStore,
  ) {
    this.applicationStore = applicationStore;

    this.authStore = authStore;

    this.isConnected = null;

    this.logEntries = [];

    makeObservable(this, {
      socket: observable,
      isConnected: observable,
      logEntries: observable,

      addLog: action.bound,
      clearLogs: action.bound,
      setIsConnected: action.bound,
      handleCreateSocket: action.bound,
    });

    this.socket = io(IO_HOST_URL, {
      autoConnect: false,
      reconnection: false,
      transports: ['websocket'],
    });

    reaction(() => this.authStore.isAuthorised, this.handleCreateSocket);

    autorun(() => this.handleCreateSocket());
  }

  public handleCreateSocket = async () => {
    this.socket.disconnect();

    const token = this.authStore.session?.access_token;

    if (this.authStore.isAuthorised && token) {
      this.socket = io(IO_HOST_URL, {
        autoConnect: false,
        reconnection: false,
        transports: ['websocket'],
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      this.socket = io(IO_HOST_URL, {
        autoConnect: false,
        reconnection: false,
        transports: ['websocket'],
      });
    }

    this.connect();

    this.setupListeners();
  };

  private handleConnectionClose = async () => {
    networkLogger.debug({ msg: 'Handle connection close' });

    await wait(this.SOCKET_RECONNECT_TIMEOUT_MS);

    this.connect();
  };

  private handleConnectError = (error: Error) => {
    networkLogger.error({ msg: 'Connect error due to', error });
  };

  private handleConnectionMessage = () => {
    this.setIsConnected(true);
  };

  private handleDisconnectMessage = () => {
    this.setIsConnected(false);
  };

  private handleSocketError = async () => {
    networkLogger.info({ msg: 'Handle Socket.io connection error' });

    await wait(this.SOCKET_RECONNECT_TIMEOUT_MS);

    this.connect();
  };

  private connect = () => {
    networkLogger.info({ msg: 'Connect to Socket.io endpoint', endpoint: IO_HOST_URL });

    this.socket.connect();

    this.socket.io.open(async (error: unknown) => {
      networkLogger.error({ msg: 'Connection to Socket.io endpoint is broken', error });

      await wait(this.SOCKET_RECONNECT_TIMEOUT_MS);

      this.connect();
    });
  };

  private setupListeners() {
    this.socket.onAny((message: string, payload: unknown) => {
      this.addLog({
        isOut: false,
        color: IoLogColor.Green,
        name: message,
        value: JSON.stringify(payload),
      });
    });

    this.socket.on(IoMessage.Connect, this.handleConnectionMessage);

    this.socket.on(IoMessage.Disconnect, this.handleDisconnectMessage);

    this.socket.on(IoMessage.ConnectError, this.handleConnectError);

    this.socket.io.on(IoMessage.ReconnectError, this.handleSocketError);

    this.socket.io.on(IoMessage.Error, this.handleSocketError);

    this.socket.io.on(IoMessage.Close, this.handleConnectionClose);

    this.emit<{ deviceId: string }>(IoMessage.Register, { deviceId: this.applicationStore.appId });
  }

  public addLog(logEntryInput: Pick<IIoLogEntry, 'isOut' | 'color' | 'name' | 'value'>) {
    this.logEntries = [
      ...this.logEntries,
      {
        ...logEntryInput,
        id: v4(),
        time: format(new Date(), 'HH:mm:ss'),
      },
    ];
  }

  public async copyAllLogs() {
    const content = JSON.stringify(this.logEntries);

    await Clipboard.write({
      string: content,
    });
  }

  public clearLogs() {
    this.logEntries = [];
  }

  public emit<T = undefined, V = undefined>(
    message: IoMessage,
    payload?: T,
    callBack?: (callBackPayload: V) => void,
  ) {
    this.addLog({
      isOut: true,
      color: IoLogColor.Red,
      name: message,
      value: JSON.stringify(payload),
    });

    this.socket.emit(message, payload, callBack);
  }

  public setIsConnected(isConnected: Maybe<boolean>) {
    networkLogger.debug({ msg: 'Set isConnected', isConnected });

    this.isConnected = isConnected;
  }
}
