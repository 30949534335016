import { inject, injectable } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { GameChatService } from 'services/game-chat/game-chat.service';
import {
  GameChatEntityResponseType,
  IGameChatEventResponse,
  IGameChatEventsResponse,
  IGameChatMessageVoteDataPayload,
} from 'services/game-chat/interfaces/game-chat-messages.interface';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { GameStore } from 'stores/game/game.store';
import { findAndReplaceGameChatItem } from 'stores/game/helpers/games-chat-item.helpers';
import { gameChatEventAdapter } from 'stores/game-events/adapters/game-chat-event-adapter.util';
import {
  IGameChatEvent,
  IGameChatEventsAdapter,
} from 'stores/game-events/interfaces/game-chat-event.interface';
import { IoStore } from 'stores/io/io.store';
import { prepareReactions } from 'stores/posts/adapters/publication-adapter.util';

import { TYPES } from 'configs/di-types.config';

export const COMMENTS_PAGINATION_LIMIT = 5;

@injectable()
export class GameEventsStore extends AdvancedEntriesStore<
  IGameChatEvent,
  IGameChatEventResponse,
  IGameChatEventsResponse
> {
  private readonly gameChatMessagesService: GameChatService;

  private readonly gameStore: GameStore;

  private readonly ioStore: IoStore;

  public lastJoinedRoomId: Maybe<number>;

  constructor(
    @inject(TYPES.GameStore) gameStore: GameStore,
    @inject(TYPES.IoStore) ioStore: IoStore,
    @inject(TYPES.GameChatService) gameChatMessagesService: GameChatService,
  ) {
    super(COMMENTS_PAGINATION_LIMIT);

    this.gameStore = gameStore;

    this.ioStore = ioStore;

    this.gameChatMessagesService = gameChatMessagesService;

    this.lastJoinedRoomId = null;

    makeObservable(this, {
      lastJoinedRoomId: observable,

      retrieveGameEvents: action.bound,
      setLastJoinedRoomId: action.bound,
      leaveCurrentRoom: action.bound,
      handleNewChatEvent: action.bound,
    });

    reaction(() => [this.gameStore.id, this.ioStore.socket], this.retrieveGameEvents);
  }

  public async reconnectSocketConnection() {
    await this.ioStore.handleCreateSocket();
  }

  public async refresh() {
    await super.refresh();
    await this.retrieveGameEvents();
  }

  public async retrieveGameEvents(): Promise<void> {
    if (this.lastJoinedRoomId) {
      this.leaveCurrentRoom();
    }

    if (this.gameStore.id) {
      await this.initialiseGameChatEvents(this.gameStore.id);

      this.setLastJoinedRoomId(this.gameStore.id);
      const roomName = `games:id:${this.gameStore.id}:chat`;
      this.ioStore.socket.emit('join', { room: roomName });
      this.ioStore.socket.on(roomName, this.handleNewChatEvent);
    } else {
      this.reset();
    }
  }

  public leaveCurrentRoom() {
    const roomName = `games:id:${this.lastJoinedRoomId}:chat`;
    this.ioStore.socket.emit('leave', { room: roomName });
  }

  public setLastJoinedRoomId(id: Maybe<number>) {
    this.lastJoinedRoomId = id;
  }

  async initialiseGameChatEvents(gameId: Maybe<number>) {
    this.reset();
    await super.initialise();
    if (gameId) {
      await this.fetchNextGameChatEvents(gameId);
    }
  }

  public async fetchNextGameChatEvents(gamedId: number): Promise<void> {
    await this.retrieveNext(
      this.gameChatMessagesService.fetchAllGameEvents(this.pagination, gamedId, 'event'),
      <IGameChatEventsAdapter>gameChatEventAdapter,
    );
  }

  public async handleNewChatEvent(message: string): Promise<void> {
    const parsedEvent: GameChatEntityResponseType = JSON.parse(message);

    if (parsedEvent.item_type === 'game_chat_event' && parsedEvent.game_id === this.gameStore.id) {
      const newItem = gameChatEventAdapter(parsedEvent);

      if (newItem) {
        this.setEntries([newItem, ...this.entries]);
      }
    }
  }

  public async gameChatEventVoteUp(payload: IGameChatMessageVoteDataPayload): Promise<void> {
    const response = await this.gameChatMessagesService.gameChatMessageVoteUp(payload);

    if (response.success) {
      const [currentComment] = this.entries.filter((item) => item.uuid === payload.messageId);

      if (response.success && currentComment) {
        currentComment.votesTotal = response.data.votes_total;
        currentComment.reactionsTotal = prepareReactions(response.data.reactions_total);
        const newComments = findAndReplaceGameChatItem(
          this.entries,
          payload.messageId,
          currentComment,
        );
        this.setEntries(newComments);
      }
    } else {
      this.setErrors(response.errors);
    }
  }

  public async gameChatEventVoteDown(payload: IGameChatMessageVoteDataPayload): Promise<void> {
    const response = await this.gameChatMessagesService.gameChatMessageVoteDown(payload);

    if (response.success) {
      const [currentComment] = this.entries.filter((item) => item.uuid === payload.messageId);

      if (currentComment) {
        currentComment.votesTotal = response.data.votes_total;
        currentComment.reactionsTotal = prepareReactions(response.data.reactions_total);
        const newComments = findAndReplaceGameChatItem(
          this.entries,
          payload.messageId,
          currentComment,
        );
        this.setEntries(newComments);
      }
    } else {
      this.setErrors(response.errors);
    }
  }
}
