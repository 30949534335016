import { ITeamResponse } from 'services/teams/interfaces/team-response.interface';

import { formatBigNumbers } from 'helpers/format/format-big-numbers.utils';
import { formatPastDate } from 'helpers/format/format-past-date.util';

import { ITeam } from '../interfaces/team.interface';

export function teamAdapter(teamResponse: ITeamResponse): ITeam {
  const { followings_count: followers, is_followed: joined, data } = teamResponse;

  const { created_at: dateAdded, small_logo_url: thumbnailUrl, name, team_id: teamId, type } = data;

  return {
    description: '',
    name,
    thumbnailUrl,
    teamId,
    type,
    dateAdded: formatPastDate(dateAdded),
    followers: formatBigNumbers(followers),
    joined,
    marketingName: getLastWord(name),
  };
}

const getLastWord = (fullName: string): string => {
  if (!fullName || !fullName.length) {
    return '';
  }

  const words = fullName.split(' ');

  return words[words.length - 1];
};
