import { memo, useMemo } from 'react';
import YouTube from 'react-youtube';

import styles from './youtube-player.module.less';

interface IYouTubePlayerProps {
  sourceUrl: string;
}

export const YouTubePlayer = memo((props: IYouTubePlayerProps) => {
  const { sourceUrl } = props;

  const videoId = useMemo(() => {
    const urlParams = new URLSearchParams(new URL(sourceUrl).search);

    return urlParams.get('v');
  }, [sourceUrl]);

  if (!videoId) {
    return null;
  }

  return (
    <div className={styles.YoutubePlayer}>
      <YouTube videoId={videoId} opts={{ width: '100%' }} />
    </div>
  );
});
