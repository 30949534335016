import { FC, SyntheticEvent, useCallback, useContext } from 'react';
import { IGif } from '@giphy/js-types';
import { Grid, SearchBar, SearchContext } from '@giphy/react-components';

import styles from './custom-gifs.module.less';

export interface ICustomGifsProps {
  onGifSelect: (gif: string) => void;
}

const NoResults = <div className={styles.NoResults}>No Results</div>;

export const CustomGifs: FC<ICustomGifsProps> = (props) => {
  const { fetchGifs, searchKey } = useContext(SearchContext);
  const { onGifSelect } = props;

  const handleOnGifSelect = useCallback(
    (gif: IGif, event: SyntheticEvent) => {
      event.preventDefault();
      onGifSelect(String(gif.id));
    },
    [onGifSelect],
  );

  return (
    <div className={styles.Suggestion}>
      <SearchBar className={styles.Search} autoFocus />
      <div className={styles.AllGifs}>
        <Grid
          noResultsMessage={NoResults}
          hideAttribution
          key={searchKey}
          columns={3}
          width={298}
          fetchGifs={fetchGifs}
          onGifClick={handleOnGifSelect}
        />
      </div>
    </div>
  );
};
