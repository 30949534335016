import * as paths from 'routes/paths.constants';

import { IHeaderTab } from 'components/header/header-tabs/header-tabs.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const AUTHORIZED_HEADER_TABS: IHeaderTab[] = [
  { slug: 'bookmarked', iconFontName: IconFontName.Bookmark, isActive: false },
  // TODO add when functionality is available

  // { slug: 'messages', iconFontName: IconFontName.Chat, isActive: false },
  // { slug: 'notification', iconFontName: IconFontName.Notifications, isActive: false },
  // { slug: 'wallet', iconFontName: IconFontName.Wallet, isActive: false },];
];

export const ENABLED_SCROLL_PATHNAMES = [
  paths.PROFILE,
  paths.TERMS_OF_USE,
  paths.PRIVACY_POLICY,
  paths.USER_AGREEMENT,
];
