import { useEffect, useState } from 'react';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';

export const useKeyboardTrigger = (isAvailable: boolean = false) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const handleKeyboardWillShow = ({ keyboardHeight }: KeyboardInfo) => {
      setIsKeyboardOpen(true);
      document.body.classList.add('is-keyboard-open');
      document.documentElement.style.setProperty('--keyboard-height', `${keyboardHeight / 10}rem`);
    };

    const handleKeyboardWillHide = () => {
      setIsKeyboardOpen(false);
      document.body.classList.remove('is-keyboard-open');
      document.documentElement.style.setProperty('--keyboard-height', '0rem');
    };

    if (isAvailable) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
      Keyboard.addListener('keyboardWillShow', handleKeyboardWillShow);
      Keyboard.addListener('keyboardWillHide', handleKeyboardWillHide);
    }
  }, [isAvailable]);

  return { isKeyboardOpen };
};
