import { FC } from 'react';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { PlayerRankingsDetailsContainer } from 'containers/player-rankings-details/player-rankings-details.container';

import { useEnableRefresher } from 'hooks/use-enable-refresher';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const PlayerRankingsDetailsRoute: FC = () => {
  useEnableRefresher();

  return (
    <PageWrapperContainer>
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <PlayerRankingsDetailsContainer />
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
