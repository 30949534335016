import { FC } from 'react';

import { PerformanceTab } from 'components/performance-tabs/performance-tab/performance-tab.component';

import styles from './performance-tabs.module.less';

export interface IPerformanceTabsProps {
  tabs: IPeriodTab[];
  setCurrentTab: (value: number) => void;
}

export interface IPeriodTab {
  isActive: boolean;
  isDisable: boolean;
  label: string;
  id: number;
}

export const PerformanceTabs: FC<IPerformanceTabsProps> = (props) => {
  const { tabs, setCurrentTab } = props;

  return (
    <div className={styles.Tabs}>
      {tabs.map((item) => {
        return <PerformanceTab setCurrentTab={setCurrentTab} tab={item} key={item.id} />;
      })}
    </div>
  );
};
