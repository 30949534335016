import { FC } from 'react';

import styles from './splash-screen.module.less';

export const SplashScreen: FC = () => {
  return (
    <div className={styles.Container}>
      <svg
        className={styles.SplashScreen}
        viewBox="0 0 96 111"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Btn Decor" opacity="0.1">
          <path
            className={styles.SplashScreen__Gradient}
            id="Subtract"
            opacity="0.4"
            d="M41.994 2.97224C45.7098 0.823993 50.2902 0.823992 54.006 2.97224L90.006 23.7847C93.7154 25.9292 96 29.8889 96 34.1736V75.8264C96 80.1111 93.7154 84.0708 90.006 86.2153L54.006 107.028C50.2902 109.176 45.7098 109.176 41.994 107.028L5.99397 86.2153C2.28455 84.0708 0 80.1111 0 75.8264V34.1736C0 29.8889 2.28455 25.9292 5.99396 23.7847L41.994 2.97224Z"
            fill="url(#paint0_linear_102_54)"
          />
        </g>
        <path
          id="Polygon 3"
          opacity="0.02"
          d="M41.994 3.47224C45.7098 1.324 50.2902 1.324 54.006 3.47224L90.006 24.2847C93.7154 26.4292 96 30.3889 96 34.6736V76.3264C96 80.6111 93.7154 84.5708 90.006 86.7153L54.006 107.528C50.2902 109.676 45.7098 109.676 41.994 107.528L5.99397 86.7153C2.28455 84.5708 0 80.6111 0 76.3264V34.6736C0 30.3889 2.28455 26.4292 5.99396 24.2847L41.994 3.47224Z"
          fill="#F7FAFA"
        />
        <path
          id="Polygon 5"
          d="M41.9077 10.5901C45.6671 8.37473 50.3329 8.37473 54.0923 10.5901L84.0923 28.2687C87.753 30.4259 90 34.3581 90 38.6071V72.8929C90 77.1419 87.753 81.0741 84.0923 83.2313L54.0923 100.91C50.3329 103.125 45.6671 103.125 41.9077 100.91L11.9077 83.2313C8.24699 81.0741 6 77.1419 6 72.8929V38.6071C6 34.3581 8.24699 30.4259 11.9077 28.2687L41.9077 10.5901Z"
          fill="#121315"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.2087 38.7955C38.4947 37.4496 39.4302 35.8 40.9076 35.8L57.5279 35.8C58.2212 35.8 58.8618 36.1849 59.2085 36.8097L68.74 53.9902C69.0867 54.6151 69.0867 55.3849 68.74 56.0097L59.2085 73.1902C58.8618 73.8151 58.2212 74.2 57.5279 74.2H40.7056C39.2117 74.2 38.2781 72.5171 39.025 71.1707L42.5475 64.8214C42.8942 64.1966 43.5348 63.8116 44.2281 63.8116L51.7645 63.8116C52.4578 63.8116 53.0985 63.4267 53.4451 62.8019L57.2133 56.0097C57.56 55.3849 57.56 54.6151 57.2133 53.9902L53.4451 47.1981C53.0985 46.5732 52.4578 46.1883 51.7645 46.1883H44.276C43.5694 46.1883 42.9187 45.7886 42.5772 45.1449L39.2087 38.7955Z"
          fill="#282C30"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.6523 62.1057C31.3992 63.4521 33.2666 63.4521 34.0135 62.1057L37.4086 55.9861C37.7456 55.3786 37.7556 54.6325 37.4348 54.0155L34.1443 47.6853C33.4212 46.2942 31.5117 46.2689 30.7547 47.6403L27.2579 53.9755C26.9133 54.5997 26.9141 55.3676 27.26 55.9911L30.6523 62.1057Z"
          fill="#282C30"
        />
        <defs>
          <linearGradient
            id="paint0_linear_102_54"
            x1="72.5"
            y1="14"
            x2="19.0864"
            y2="93.3661"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.161458" stopColor="white" stopOpacity="0" />
            <stop offset="0.494792" stopColor="white" />
            <stop offset="0.833333" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
